import { makeStyles } from '@mui/styles';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  img: {
    display: 'block',
    margin: `${theme.spacing(8)} auto`,
    width: 280,
    height: 'auto',
  },
}));

const StepGraphic = ({ fileName }) => {
  const classes = useStyles();

  return <img alt="Step complete" className={classes.img} src={`/img/signup/${fileName}.svg`} />;
};

export default StepGraphic;
