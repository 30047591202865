import { createSlice } from '@reduxjs/toolkit';
import {
  asyncInitialState as initialState,
  createAsyncAction,
  createAsyncReducerHandlers,
} from '../utils/async';
import {
  fetchPrinters as fetchPrintersApi,
  fetchAvailablePrinters as fetchAvailablePrintersApi,
  updatePrinter as updatePrinterApi,
  fetchPrinter as fetchPrinterApi,
  deletePrinter as deletePrinterApi,
} from '../../api/printers';

export const fetchPrinters = createAsyncAction('fetch-printers', fetchPrintersApi);
export const fetchAvailablePrinters = createAsyncAction(
  'fetch-available-printers',
  fetchAvailablePrintersApi,
);
export const updatePrinter = createAsyncAction('update-printer', updatePrinterApi);
export const fetchPrinter = createAsyncAction('get-printer', fetchPrinterApi);
export const deletePrinter = createAsyncAction('delete-printer', deletePrinterApi);

const printersSlice = createSlice({
  name: 'printers',
  initialState,
  reducers: {
    clearPrinters(state) {
      delete state.data;
    },
  },
  extraReducers: createAsyncReducerHandlers(fetchPrinters),
});

const printerSlice = createSlice({
  name: 'printer',
  initialState,
  reducers: {
    clearPrinter(state) {
      delete state.data;
    },
  },
  extraReducers: {
    ...createAsyncReducerHandlers(updatePrinter),
    ...createAsyncReducerHandlers(fetchPrinter),
    ...createAsyncReducerHandlers(deletePrinter),
  },
});

const availablePrintersSlice = createSlice({
  name: 'availablePrinters',
  initialState,
  reducers: {},
  extraReducers: createAsyncReducerHandlers(fetchAvailablePrinters),
});
export const { clearPrinters } = printersSlice.actions;
export const { clearPrinter } = printerSlice.actions;

export const { reducer: printers } = printersSlice;
export const { reducer: printer } = printerSlice;
export const { reducer: availablePrinters } = availablePrintersSlice;
