import React from 'react';
import { Paper, Typography, Box, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  MdLocalShipping,
  MdRateReview,
  MdRemoveShoppingCart,
  MdThumbUp,
  MdTimer,
} from 'react-icons/md';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
    height: '100%',
  },
  cardTitle: {
    ...theme.customFonts.mediumBold,
  },
  contentContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  metricIconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  metricIconBox: {
    width: 84,
    height: 84,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.sessionsScorePalette.red,
    borderRadius: theme.shape.borderRadius,
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(1),
    },
  },
  metricIconBoxCompliant: {
    backgroundColor: theme.sessionsScorePalette.compliantGreen,
  },
  metricIcon: {
    color: theme.palette.common.white,
    fontSize: '3.5rem',
  },
  metricTitle: {
    ...theme.customFonts.largeBold,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  metricAction: {
    ...theme.customFonts.large,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
}));

const MetricIcon = ({ metric, className }) => {
  switch (metric) {
    case 'missing_items':
      return <MdRemoveShoppingCart className={className} />;
    case 'open_rate':
      return <MdTimer className={className} />;
    case 'supply_chain':
      return <MdLocalShipping className={className} />;
    case 'new_ratings':
      return <MdRateReview className={className} />;
    default:
      return <MdThumbUp className={className} />;
  }
};

const getActionTitle = (metric) => {
  switch (metric) {
    case 'missing_items':
      return 'Missing items reduced your cashback';
    case 'open_rate':
      return 'Not being open enough reduced your cashback';
    case 'supply_chain':
      return 'You were not supply chain compliant';
    case 'new_ratings':
      return 'Low ratings reduced your cashback';
    default:
      return 'Well done! You have achieve maximum cashback';
  }
};

const ImprovementAction = ({ metric, text }) => {
  const classes = useStyles();

  return (
    <Paper className={classes.container}>
      <Typography component="h2" className={classes.cardTitle}>
        How you can improve
      </Typography>
      <Grid container spacing={2} className={classes.contentContainer}>
        <Grid className={classes.metricIconContainer} sm={12} md={3} item>
          <Box
            className={`${classes.metricIconBox} ${
              metric === 'none' || null ? classes.metricIconBoxCompliant : ''
            }`}
          >
            <MetricIcon metric={metric} className={classes.metricIcon} />
          </Box>
        </Grid>
        <Grid sm={12} md={9} item sx={{ display: 'flex', alignItems: 'center' }}>
          <Box>
            <Typography component="h2" className={classes.metricTitle}>
              {getActionTitle(metric)}
            </Typography>
            <Typography component="p" className={classes.metricAction}>
              {text}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ImprovementAction;
