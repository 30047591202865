import { makeStyles } from '@mui/styles';
import React from 'react';

import { ReactComponent as BannerBackground } from '../../../assets/images/banner_background.svg';
import { ReactComponent as BannerForeground } from '../../../assets/images/banner_foreground.svg';

const useStyles = makeStyles((theme) => ({
  banner: {
    position: 'relative',
    marginTop: `-${theme.spacing(2.5)}`,
    left: theme.spacing(1),
  },
  foreground: {
    position: 'absolute',
    top: 0,
    marginTop: theme.spacing(1.25),
    left: theme.spacing(1),
  },
}));

const SessionsBanner = () => {
  const classes = useStyles();

  return (
    <div className={classes.banner}>
      <BannerBackground />
      <BannerForeground className={classes.foreground} />
    </div>
  );
};

export default SessionsBanner;
