import React from 'react';
import * as Yup from 'yup';
import { Card, CardContent, FormControlLabel, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Field, Form, Formik } from 'formik';
import { Switch, TextField } from 'formik-mui';
import { useDispatch } from 'react-redux';
import PageHeader from '../../components/PageHeader';
import BackArrow from '../../components/BackArrow';
import Page from '../../components/Page';
import { getCustomerState } from '../../store/customer/selectors';
import { fetchCustomer, updateCustomer } from '../../store/customer';
import useEndpoint from '../../hooks/useEndpoint';
import UniversalSave from '../../components/UniversalSave';
import { useNotifications } from '../../shared/contexts/Notifications/useNotifications';
import { getErrorMessage } from '../../shared/utils/errors';
import CustomWarningBanner from '../../components/CustomWarningBanner';

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.text.primary,
    ...theme.customFonts.largeBold,
    marginBottom: '1rem',
  },
}));

const customerSchema = Yup.object().shape({
  address: Yup.object().shape({
    city: Yup.string().nullable().optional(),
    country: Yup.string().nullable().optional(),
    line1: Yup.string().nullable().optional(),
    line2: Yup.string().nullable().optional(),
    postal_code: Yup.string().nullable().optional(),
    state: Yup.string().nullable().optional(),
  }),
  email: Yup.string().email('Must be a valid email').required('Please enter an email'),
  phone: Yup.string()
    .min(3, 'Too Short!')
    .matches(
      // eslint-disable-next-line max-len
      /^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?#(\d{4}|\d{3}))?$/,
      'Invalid mobile number.',
    )
    .required('Please enter a valid phone number'),
  vatNumber: Yup.string().optional(),
  enabled: Yup.boolean().optional(),
});

const CustomerSettings = () => {
  const classes = useStyles();
  const { loading, data: customer, error } = useEndpoint(getCustomerState, fetchCustomer()) || {};
  const { showSuccessNotification, showErrorNotification } = useNotifications();
  const dispatch = useDispatch();

  const { address, email, name, phone, vatNumber, enabled } = customer || {};
  const initialValues = {
    address: {
      city: address?.city || '',
      country: address?.country || '',
      line1: address?.line1 || '',
      line2: address?.line2 || '',
      postal_code: address?.postal_code || '',
      state: address?.state || '',
    },
    email,
    name,
    phone,
    vatNumber,
    enabled: enabled || false,
  };

  const handleOnSubmit = async (values) => {
    try {
      let transformedValues = values;
      if (values.vatNumber === '') {
        transformedValues = { ...values, vatNumber: 'NONE' };
      }
      await dispatch(updateCustomer(transformedValues));
      showSuccessNotification('Successfully updated customer details');
    } catch (caughtError) {
      showErrorNotification(getErrorMessage(caughtError));
    }
  };

  return (
    <Page loading={loading} error={error}>
      <PageHeader>
        <BackArrow text="Admin Area Settings" to="/admin" />
        <p className={classes.title}>Stripe customer settings</p>
      </PageHeader>
      {!customer?.enabled && (
        <>
          <CustomWarningBanner
            title="This stripe customer is currently inactive"
            titleFont="smallBold"
            titleColor="greyDarker"
            iconColor="#FFB800"
            backgroundColor="warningYellow"
          />
          <br />
        </>
      )}
      {customer && (
        <>
          <Card className={classes.card}>
            <Formik
              initialValues={initialValues}
              enableReinitialize
              validationSchema={customerSchema}
            >
              {({ values, resetForm, errors, dirty, isValid }) => (
                <>
                  <UniversalSave
                    isValid={isValid}
                    dirty={dirty}
                    onSave={() => {
                      handleOnSubmit(values);
                    }}
                    onDiscard={resetForm}
                    errors={errors}
                  />
                  <Form>
                    <CardContent className={classes.container}>
                      <Typography className={classes.subtitle}>General details</Typography>
                      {!enabled && (
                        <FormControlLabel
                          control={
                            <Field
                              className={classes.field}
                              color="primary"
                              name="enabled"
                              component={Switch}
                              checked={values.enabled}
                              type="checkbox"
                            />
                          }
                          label="Inactive Stripe customer"
                        />
                      )}
                      <Field
                        className={classes.field}
                        name="name"
                        label="Name"
                        component={TextField}
                        variant="outlined"
                        margin="normal"
                        type="text"
                        fullWidth
                      />
                      <Field
                        className={classes.field}
                        name="email"
                        label="Email"
                        component={TextField}
                        variant="outlined"
                        margin="normal"
                        type="text"
                        fullWidth
                      />
                      <Field
                        className={classes.field}
                        name="phone"
                        label="Phone"
                        component={TextField}
                        variant="outlined"
                        margin="normal"
                        type="tel"
                        fullWidth
                      />
                      <Field
                        className={classes.field}
                        name="vatNumber"
                        label="VAT"
                        component={TextField}
                        disabled
                        variant="outlined"
                        margin="normal"
                        type="text"
                        fullWidth
                      />
                      <Typography className={classes.subtitle}>Address</Typography>
                      <Field
                        className={classes.field}
                        name="address.line1"
                        label="line 1"
                        component={TextField}
                        variant="outlined"
                        margin="normal"
                        type="text"
                        fullWidth
                      />
                      <Field
                        className={classes.field}
                        name="address.line2"
                        label="line 2"
                        component={TextField}
                        variant="outlined"
                        fullWidth
                      />
                      <Field
                        className={classes.field}
                        name="address.city"
                        label="City"
                        component={TextField}
                        variant="outlined"
                        margin="normal"
                        fullWidth
                      />
                      <Field
                        className={classes.field}
                        name="address.state"
                        label="State"
                        component={TextField}
                        variant="outlined"
                        margin="normal"
                        fullWidth
                      />
                      <Field
                        className={classes.field}
                        name="address.postal_code"
                        label="Post code"
                        component={TextField}
                        variant="outlined"
                        margin="normal"
                        fullWidth
                      />
                    </CardContent>
                  </Form>
                </>
              )}
            </Formik>
          </Card>
        </>
      )}
    </Page>
  );
};

export default CustomerSettings;
