import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import { IconButton, Tooltip } from '@mui/material';
import { MdSchedule } from 'react-icons/md';

import { getBuilderMenuSchedule } from '../../../../store/brands/builderMenu/builderMenuItems/selectors';
import { getOpeningHoursFromServedTimes } from '../../../../store/utils/servingTimes';
import {
  updateBuilderMenuItems,
  updateBuilderMenuSchedule,
} from '../../../../store/brands/builderMenu/builderMenuItems';

import { getErrorMessage } from '../../../../shared/utils/errors';
import { useNotifications } from '../../../../shared/contexts/Notifications/useNotifications';

import MenuGroupScheduleForm from '../../../MenuGroupScheduleForm';
import CustomDialog from '../../../CustomDialog';

const ScheduleMenuGroupButton = ({ groupId, groupName, isReadonly }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const days = useSelector((state) => getBuilderMenuSchedule(state, groupId));
  const { showErrorNotification, showSuccessNotification } = useNotifications();
  const dispatch = useDispatch();

  const handleEdit = () => {
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const handleOnSubmit = async (values) => {
    try {
      const localDays = getOpeningHoursFromServedTimes(values.days);
      // localDays being undefined is valid - it means there is no schedule.
      const valid = !localDays || !localDays.find((dayString) => dayString.includes('INVALID'));
      if (!valid) throw new Error('Error: Schedule contains an invalid date');
      await dispatch(updateBuilderMenuSchedule({ groupId, days: localDays }));
      await dispatch(updateBuilderMenuItems());
      handleCloseDialog();
      showSuccessNotification(`${groupName} schedule has been updated successfully`);
    } catch (error) {
      showErrorNotification(getErrorMessage(error));
    }
  };

  return (
    <>
      <Tooltip title={`${groupName} section schedule`} aria-label={`${groupName} section schedule`}>
        <IconButton onClick={handleEdit} disabled={isReadonly} size="large">
          <MdSchedule style={{ fontSize: 20 }} />
        </IconButton>
      </Tooltip>
      <CustomDialog
        title={`Sessions Serve times for ${groupName}`}
        isDialogOpen={isDialogOpen}
        handleCloseDialog={handleCloseDialog}
        // This fixes the propagation to its parent's <ListemItem />, issue #302.
        onClick={(event) => event.stopPropagation()}
      >
        <MenuGroupScheduleForm
          initialValues={{ days }}
          onSubmit={handleOnSubmit}
          onCancel={handleCloseDialog}
        />
      </CustomDialog>
    </>
  );
};

ScheduleMenuGroupButton.propTypes = {
  groupId: PropTypes.string.isRequired,
  groupName: PropTypes.string.isRequired,
};

export default ScheduleMenuGroupButton;
