import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import {
  Collapse,
  Grid,
  List,
  ListItemButton,
  ListItemIcon,
  Typography,
  Tooltip,
} from '@mui/material';

import {
  Image,
  ImageNotSupported,
  ExpandLess as LessIcon,
  ExpandMore as MoreIcon,
} from '@mui/icons-material';
import { MdSchedule as ScheduleIcon } from 'react-icons/md';

import Droppable from '../../../DragAndDrop/Droppable';
import Draggable from '../../../DragAndDrop/Draggable';
import MenuGroupItem from '../MenuGroupItem';
import AutoComplete from '../../../AutoComplete';
import EditMenuGroupButton from '../EditMenuGroupButton';
import ScheduleMenuGroupButton from '../ScheduleMenuGroupButton';
import InlineChip from '../../../InlineChip';
import MenuGroupColor from './MenuGroupColor';
import useRoles from '../../../../hooks/useRoles';
import {
  getBuilderHasSchedule,
  getBuilderMenuGroup,
} from '../../../../store/brands/builderMenu/builderMenuItems/selectors';
import { getBrandsMenuItems } from '../../../../store/brands/brandsMenuItems/selectors';

const useStyles = makeStyles((theme) => ({
  groupHeadingContainer: {
    marginTop: theme.spacing(2),
  },
  groupHeadingSelected: {},
  groupHeadingRoot: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    overflow: 'hidden',
    '$groupHeadingSelected &': {
      backgroundColor: 'inherit',
    },
    '&:hover': {
      backgroundColor: theme.palette.grey[200],
    },
  },
  groupHeadingWithSecondary: { paddingRight: '140px' },
  menuItems: {
    padding: '16px 16px 16px 62px',
  },
  menuItem: { paddingLeft: '0' },
  groupPriorityLabel: {
    backgroundColor: theme.palette.grey[200],
    height: '24px',
  },
  groupName: {
    fontSize: '1rem',
    textTransform: 'capitalize',
  },
  groupImageIcon: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    color: '#0000008a',
  },
}));

const MenuGroup = ({ menuName, groupIndex, groupId, onAddItem, expandAll, isReadonly }) => {
  const classes = useStyles();
  const { isRoleAtLeastManager } = useRoles();
  const { push } = useHistory();

  const menuGroup = useSelector((state) => getBuilderMenuGroup(state, groupId));
  const menuItemsForBrand = useSelector(getBrandsMenuItems);
  const hasSchedule = useSelector((state) => getBuilderHasSchedule(state, groupId));

  const [isGroupCollapsed, setIsGroupCollapsed] = useState(true);
  const {
    groupName,
    groupItems,
    groupColor,
    available = true,
    description,
    imgUploadUrl,
    groupImageUrl,
  } = menuGroup;
  const handleHeaderClick = () => {
    setIsGroupCollapsed(!isGroupCollapsed);
  };

  const handleAddMenuItem = (itemId, itemName) => {
    if (!itemId) {
      push(
        `/brand-menu-management/items/add?menuName=${menuName}&groupId=${groupId}&itemName=${itemName}`,
      );
    } else {
      onAddItem({ groupId, itemId });
    }
  };

  useEffect(() => {
    setIsGroupCollapsed(!expandAll);
  }, [expandAll]);

  return (
    <>
      <List disablePadding>
        <ListItemButton
          disableGutters
          classes={{
            container: clsx(
              classes.groupHeadingContainer,
              !isGroupCollapsed && classes.groupHeadingSelected,
            ),
            root: classes.groupHeadingRoot,
            secondaryAction: classes.groupHeadingWithSecondary,
          }}
          disableRipple
          onClick={handleHeaderClick}
        >
          <ListItemIcon>{isGroupCollapsed ? <MoreIcon /> : <LessIcon />}</ListItemIcon>
          <Grid container>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item xs={6} sm="auto">
                <Typography noWrap className={classes.groupName}>
                  {groupName}
                </Typography>
              </Grid>
              {isRoleAtLeastManager() && (
                <Grid item xs="auto" display="flex" alignItems="center">
                  <EditMenuGroupButton
                    menuId={menuName}
                    groupColor={groupColor}
                    groupName={groupName}
                    groupId={groupId}
                    available={available != null ? available : true}
                    groupImgUploadUrl={imgUploadUrl}
                    groupImageUrl={groupImageUrl}
                    description={description}
                    isReadonly={isReadonly}
                  />
                  <ScheduleMenuGroupButton
                    groupName={groupName}
                    groupId={groupId}
                    isReadonly={isReadonly}
                  />
                </Grid>
              )}
              <Grid container alignItems="center" spacing={1}>
                {hasSchedule && (
                  <Grid item>
                    <InlineChip label="Scheduled" icon={<ScheduleIcon />} />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
          {groupImageUrl ? (
            <Tooltip title="Section image found" aria-label="Section image found">
              <Image fontSize="small" className={classes.groupImageIcon} />
            </Tooltip>
          ) : (
            <Tooltip title="No section image found" aria-label="No section image found">
              <ImageNotSupported fontSize="small" className={classes.groupImageIcon} />
            </Tooltip>
          )}
          <MenuGroupColor color={groupColor} />
        </ListItemButton>
      </List>
      <Collapse className={classes.menuItems} in={!isGroupCollapsed} timeout="auto" unmountOnExit>
        {description && (
          <Typography variant="h4" paragraph>
            {description}
          </Typography>
        )}
        {groupItems && (
          <Droppable droppableId={`droppable_${groupId}`} type={`${groupId}`}>
            <List disablePadding>
              {groupItems.map((groupItem, index) => (
                <Draggable
                  isDragDisabled={isReadonly || !isRoleAtLeastManager()}
                  className={classes.menuItem}
                  key={`group_item_${groupId}_${groupIndex}_${groupItem}`}
                  draggableId={`group_item_${groupId}_${groupIndex}_${groupItem}`}
                  index={index}
                >
                  <MenuGroupItem
                    groupId={groupId}
                    itemId={groupItem}
                    itemIndex={index}
                    isReadonly={isReadonly}
                  />
                </Draggable>
              ))}
            </List>
          </Droppable>
        )}
        {!isReadonly && (
          <AutoComplete
            checkIfItemIsOnMenu={() => true}
            key={groupItems ? groupItems.length : 0}
            label="+ Add Item"
            options={menuItemsForBrand}
            optionLabelKey="itemName"
            optionValueKey="itemId"
            onSelect={handleAddMenuItem}
            groupBy={menuItemsForBrand}
          />
        )}
      </Collapse>
    </>
  );
};

MenuGroup.propTypes = {
  menuName: PropTypes.string.isRequired,
  groupIndex: PropTypes.number.isRequired,
  groupId: PropTypes.string.isRequired,
  onAddItem: PropTypes.func.isRequired,
};

export default MenuGroup;
