import { geocodeByAddress } from 'react-places-autocomplete';
import get from 'lodash/get';
import includes from 'lodash/includes';
import moment from 'moment-timezone';

export const getGooglePlaceIdFromAddress = async (address) => {
  const geoData = await geocodeByAddress(address);
  return get(geoData, '0.place_id');
};

export const getPlaceDetailsFromPlaceId = async (placeId) => {
  const service = new window.google.maps.places.PlacesService(document.createElement('div'));
  const { OK } = window.google.maps.GeocoderStatus;

  return new Promise((resolve, reject) => {
    service.getDetails({ placeId }, (result, status) => {
      if (!status === OK) {
        reject(status);
      }

      resolve(result);
    });
  });
};

const isOpen = (time) => time !== 'Closed';
export const isOpen24Hours = (time) => includes(time, '24 hours');
const openingTimeToMoment = (time) => moment(time, 'h:mm a');
const momentTimeToOpening = (time) => time.format('h:mm A');
const brokenFormatDay = {
  day: '---',
  isClosed: true,
};

const parseOpeningTime = (time) => {
  if (!time || !isOpen(time) || isOpen24Hours(time)) {
    return null;
  }

  const matchTime = /^(.+)\s[–-]\s(.+)$/;
  const matches = time.match(matchTime);

  if (!matches) {
    return null;
  }

  const [, open, close] = matches;

  return {
    open: openingTimeToMoment(open),
    close: openingTimeToMoment(close),
  };
};

const parseOpeningDay = (opening) => {
  const matchDayAndTime = /^(.+):\s(.+)$/;
  const matches = opening.match(matchDayAndTime);

  if (!matches) {
    return brokenFormatDay;
  }

  const [, day, time] = matches;

  return {
    day,
    time: parseOpeningTime(time),
    isOpen: isOpen(time),
    isOpen24Hours: isOpen24Hours(time),
  };
};

export const parseGooglePlaceOpenings = (openings) => openings.map(parseOpeningDay);

const getOpeningTime = (opening) => {
  const { isOpen: localIsOpen, time } = opening;

  if (!localIsOpen) {
    return 'Closed';
  }

  if (time.open.isSame(time.close)) {
    return 'Open 24 hours';
  }

  // if (time.open.isAfter(time.close)) {
  //   throw new Error("Opening time is after closing time!");
  // }

  return `${momentTimeToOpening(time.open)} - ${momentTimeToOpening(time.close)}`;
};

export const convertToGooglePlaceOpenings = (openings) =>
  openings.map((opening) => `${opening.day}: ${getOpeningTime(opening)}`);
