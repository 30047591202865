import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Page from '../components/Page';
import shouldLoad from '../shared/utils/shouldLoad';
import { fetchVenueSettings } from '../store/venues';
import { getVenuesSettingsState } from '../store/venues/selectors';

/**
 * Wraps components with settings (or a specific setting).
 * All pages should use this to deal with venue settings.
 * @param {Component} Component - A React Component
 * @param {string | string[]} settingName - The name of a setting (or array of names) you require.
 *  If missing, all settings will be exposed.
 * @example
 * withSettings(VoucherSettings, 'DEFAULT_VOUCHER_EXPIRY');
 * @example
 * withSettings(VoucherSettings, ['DEFAULT_VOUCHER_EXPIRY', 'RATINGS_ENABLED']);
 */

const withSettings = (Component, settingName) => (props) => {
  const dispatch = useDispatch();
  const venueSettingsState = useSelector(getVenuesSettingsState);
  const {
    loading: venueSettingsLoading,
    data: venueSettings,
    error: venueSettingsError,
  } = venueSettingsState;

  const namedSettings = useCallback(() => {
    if (Array.isArray(settingName)) {
      const settingsObject = {};
      settingName.forEach((name) => {
        const setting = venueSettings ? venueSettings?.find((s) => s.settingName === name) : '';
        if (setting) {
          settingsObject[setting.settingName] = setting;
        }
      });
      return settingsObject;
    }
    const specifiedSetting = venueSettings
      ? venueSettings?.find((setting) => setting.settingName === settingName)
      : '';
    return specifiedSetting;
  }, [venueSettings]);

  useEffect(() => {
    if (shouldLoad(venueSettingsState)) dispatch(fetchVenueSettings());
  }, [dispatch, venueSettingsState]);

  return !venueSettings || (settingName && !namedSettings) ? (
    <Page loading={venueSettingsLoading} error={venueSettingsError} />
  ) : (
    <Component
      {...props}
      setting={settingName ? namedSettings() : venueSettings}
      venueLoading={venueSettingsLoading}
      venueError={venueSettingsError}
    />
  );
};

export default withSettings;
