import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardActions, CardContent, Button, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CustomDialog from '../CustomDialog';

const useStyles = makeStyles(() => ({
  heading: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

const AddDebitDialog = ({ handleCloseDialog, handleDialogOpen, submitDialog, amount, reason }) => {
  const classes = useStyles();

  const handleSubmit = () => {
    submitDialog(amount, reason);
  };
  return (
    <CustomDialog
      title="Add debit"
      isDialogOpen={handleDialogOpen}
      handleCloseDialog={handleCloseDialog}
    >
      <Card className={classes.card}>
        <CardContent>
          <Typography>{`Setting up a debit for £${amount} because: ${reason}`}</Typography>
        </CardContent>
        <CardActions className={classes.cardActions}>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={handleSubmit} variant="contained" color="primary">
            Add new debit
          </Button>
        </CardActions>
      </Card>
    </CustomDialog>
  );
};

AddDebitDialog.propTypes = {
  handleCloseDialog: PropTypes.func.isRequired,
  handleDialogOpen: PropTypes.bool.isRequired,
  submitDialog: PropTypes.func.isRequired,
  amount: PropTypes.number.isRequired,
  reason: PropTypes.string.isRequired,
};

export default AddDebitDialog;
