import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  Box,
  Typography,
  Card,
  IconButton,
  Chip,
  TextField,
  Grid,
  Tooltip,
  Paper,
  InputAdornment,
} from '@mui/material';
import { MdDelete } from 'react-icons/md';
import { LoyaltyOutlined, Search } from '@mui/icons-material';
import copy from 'clipboard-copy';
import Autocomplete from '@mui/material/Autocomplete';

import { pick } from 'lodash';
import { makeStyles } from '@mui/styles';
import { useHistory, useParams } from 'react-router';
import OrderableTable from '../../../components/OrderableTable';
import TableButton from '../../../components/TableButton/index';
import { getBrandsState } from '../../../store/brands/brands/selectors';
import { fetchBrands } from '../../../store/brands/brands';
import {
  fetchBrandsIngredients,
  updateBrandsIngredient,
  deleteBrandsIngredient,
} from '../../../store/brands/brandsIngredients';
import { getBrandsIngredientsState } from '../../../store/brands/brandsIngredients/selectors';

import Page from '../../../components/Page';
import withVenue from '../../../hoc/withVenue';
import { useNotifications } from '../../../shared/contexts/Notifications/useNotifications';
import { getErrorMessage } from '../../../shared/utils/errors';
import shouldLoad from '../../../shared/utils/shouldLoad';
import CustomDialog from '../../../components/CustomDialog';
import BrandsIngredientForm from '../../../components/Brands/BrandsIngredientForm';
import { filterTableData } from '../../../shared/utils/filterData';
import useSearch from '../../../hooks/useSearch';

const useStyles = makeStyles((theme) => ({
  box: {
    borderRadius: theme.spacing(0.5),
    padding: '6px 12px 0 0',
  },
  searchBar: {
    display: 'flex',
    borderRadius: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      width: '15rem',
    },
    '& .MuiInputLabel-outlined': {
      color: '#5A7296',
      top: '-7px',
    },
    '& .MuiInputBase-input': {
      padding: '8.5px 14px',
    },
  },
  searchBox: {
    padding: theme.spacing(0.75, 0),
  },
  dropdownWidths: {
    width: 'auto',
    minWidth: '127px',
    '& .MuiInputBase-root': {
      display: 'inline-flex',
      flexWrap: 'nowrap',
      borderRadius: '2px',
    },
  },
  paragraph: {
    paddingTop: '20px',
    marginBottom: 0,
  },
}));

const BrandsIngredients = () => {
  const localStorageBrandsFilter = JSON.parse(localStorage.getItem('brandsFilter'));
  const [brandsFilter, setBrandsFilter] = useState(localStorageBrandsFilter || []);
  const classes = useStyles();
  const dispatch = useDispatch();
  const ingredientsState = useSelector(getBrandsIngredientsState);
  const brandsState = useSelector(getBrandsState);
  const { data: brands } = brandsState;
  const history = useHistory();
  const { itemId: ingredientParamId } = useParams() || {};
  const { loading, data, error } = ingredientsState;
  const [valueData, setValueData] = useState(data);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [ingredientData, setIngredientData] = useState(null);
  const [formAction, setFormAction] = useState('');
  const { showErrorNotification, showSuccessNotification } = useNotifications();
  const searchKeys = useMemo(() => ['ingredientName', 'label'], []);
  const threshold = 0.2;

  const { searchResults, searchError, handleSearch, filteredItems, setFilteredItems } = useSearch(
    data,
    searchKeys,
    threshold,
    valueData,
  );

  const handleEdit = useCallback(
    (ingredient) => {
      // When calling this function, a check is first made to see if the ingredient is an id, not an object
      if (ingredientParamId && data) {
        const ingrToUpdate = data.find((obj) => obj.ingredientId === ingredientParamId);
        setFormAction('update');
        setIngredientData(ingrToUpdate);
        setIsDialogOpen(true);
        return;
      }
      setFormAction('update');
      setIngredientData(ingredient);
      setIsDialogOpen(true);
    },
    [data, ingredientParamId],
  );

  const handleDelete = useCallback(
    async (ingredientId) => {
      try {
        await dispatch(deleteBrandsIngredient(ingredientId));
        await dispatch(fetchBrandsIngredients());
        showSuccessNotification('Ingredient has been deleted successfully');
      } catch (err) {
        showErrorNotification(getErrorMessage(err));
      }
    },
    [dispatch, showErrorNotification, showSuccessNotification],
  );

  const valueFormatter = useCallback(
    ({ value, valueName, row }) => {
      switch (valueName) {
        case 'ingredientName':
          return row.readonly ? (
            <p>
              {value} <Chip label="Read-only" />
            </p>
          ) : (
            <TableButton
              onClick={() => {
                handleEdit(row);
              }}
            >
              {row.ingredientName}
            </TableButton>
          );
        case 'delete':
          return (
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Tooltip title="Copy ingredient ID" disableInteractive>
                <IconButton
                  size="small"
                  onClick={() => {
                    copy(row.ingredientId);
                  }}
                  sx={{ margin: '0 5px' }}
                >
                  <LoyaltyOutlined sx={{ width: 18, height: 18 }} />
                </IconButton>
              </Tooltip>
              <IconButton
                edge="end"
                size="small"
                onClick={() => handleDelete(row.ingredientId)}
                disabled={row?.readonly}
              >
                <MdDelete />
              </IconButton>
            </Box>
          );
        default:
          return value;
      }
    },
    [handleDelete, handleEdit],
  );

  const newData = useCallback(() => {
    const pickedData = [];
    if (filteredItems) {
      filteredItems.forEach((item) => {
        // eslint-disable-next-line no-param-reassign
        item = {
          ...item,
          delete: 'delete',
        };
        pickedData.push(
          pick(item, [
            'ingredientName',
            'label',
            'brandName',
            'type',
            'ingredientId',
            'delete',
            'isAlcoholic',
            'readonly',
          ]),
        );
      });
    }
    return pickedData;
  }, [filteredItems]);

  const handleOnSubmit = async (values) => {
    try {
      const { ingredientId, ingredientName } = ingredientData;
      await dispatch(updateBrandsIngredient({ ingredientId, values }));
      if (ingredientParamId) {
        history.goBack();
        await dispatch(fetchBrandsIngredients());
        showSuccessNotification(`${ingredientName} has been updated successfully`);
        return;
      }
      await dispatch(fetchBrandsIngredients());
      showSuccessNotification(`${ingredientName} has been updated successfully`);
      setIsDialogOpen(false);
    } catch (newError) {
      showErrorNotification(getErrorMessage(newError));
    }
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    if (ingredientParamId) {
      history.goBack();
    }
  };

  useEffect(() => {
    if (shouldLoad(ingredientsState)) dispatch(fetchBrandsIngredients());
    if (shouldLoad(brandsState)) dispatch(fetchBrands());
    setValueData(data);
    setFilteredItems(filterTableData(brandsFilter, searchResults || data));
  }, [
    brandsFilter,
    brandsState,
    data,
    dispatch,
    ingredientsState,
    searchResults,
    setFilteredItems,
  ]);

  // To check if param for ingredient id is present in URL
  useEffect(() => {
    if (ingredientParamId) {
      handleEdit(ingredientParamId);
    }
  }, [handleEdit, ingredientParamId]);

  const handleBrandsFilter = (_e, value) => {
    setBrandsFilter(value);
    localStorage.setItem('brandsFilter', JSON.stringify(value));
    setFilteredItems(filterTableData(value, searchResults));
  };

  return (
    <Page loading={loading} error={error} fullWidth>
      <Paper sx={{ borderRadius: 4, mt: 4 }}>
        <Grid sx={{ padding: '16px 16px 0 16px' }}>
          <Grid container>
            <Grid item xs={12} className={classes.searchBox}>
              <TextField
                helperText={searchError}
                className={classes.searchBar}
                id="outlined-basic"
                label="Search"
                onChange={handleSearch}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Search color="disabled" />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Box>
              <aside>
                <CustomDialog
                  isDialogOpen={isDialogOpen}
                  handleCloseDialog={handleCloseDialog}
                  title="Update Ingredient"
                >
                  <BrandsIngredientForm
                    formAction={formAction}
                    ingredientData={ingredientData}
                    onSubmit={handleOnSubmit}
                    onCancel={handleCloseDialog}
                  />
                </CustomDialog>
              </aside>
            </Box>
          </Grid>

          {brands && brandsFilter && (
            <Grid container sx={{ flexWrap: 'nowrap', overflowY: 'auto' }}>
              <Grid item className={classes.box}>
                <Autocomplete
                  fullWidth
                  multiple
                  limitTags={4}
                  id="brands-autocomplete"
                  options={brands}
                  getOptionLabel={(option) => option.name}
                  defaultValue={
                    brandsFilter.length > 0
                      ? brands.filter((brand) =>
                          brandsFilter.some((filter) => filter.brandId === brand.brandId),
                        )
                      : []
                  }
                  onChange={handleBrandsFilter}
                  disableCloseOnSelect
                  className={classes.dropdownWidths}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Brands"
                      className={classes.dropdownWidths}
                    />
                  )}
                />
              </Grid>
            </Grid>
          )}
          <Typography paragraph className={classes.paragraph}>
            Ingredients appear as options on products (e.g. mixers for spirits, flavours for ice
            cream)
          </Typography>
        </Grid>

        {filteredItems === null && (
          <Box style={{ paddingTop: 14 }}>
            <Typography align="center" variant="h2" color="textSecondary">
              No brand selected, please select at least brand to view ingredients
            </Typography>
          </Box>
        )}
        {filteredItems && filteredItems.length === 0 && (
          <Box style={{ paddingTop: 14 }}>
            <Typography align="center" variant="h2" color="textSecondary">
              No ingredients found
            </Typography>
          </Box>
        )}
        {data && (
          <>
            {valueData && data && (
              <Card>
                <OrderableTable
                  tableData={[...newData()]}
                  titles={['INTERNAL NAME', 'EXTERNAL LABEL', 'BRAND', 'TYPE', '']}
                  keys={['ingredientId']}
                  excludeFields={['ingredientId', 'isAlcoholic']}
                  disableColumnTitles={['']}
                  valueFormatter={valueFormatter}
                />
              </Card>
            )}
          </>
        )}
      </Paper>
    </Page>
  );
};

export default withVenue(BrandsIngredients);
