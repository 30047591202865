import { getVenues, barePost, patch, get, post, del } from './httpRequest';
import { getIdToken } from '../cognito/sessionTokens';

export const fetchVenues = async () => {
  const response = await getVenues();
  const validVenues = response.data.reduce(
    (acc, venue) => (venue?.venueId !== 'undefined' ? [...acc, venue] : acc),
    [],
  );
  return validVenues;
};

export const fetchVenue = async () => {
  const response = await get(``);
  return response.data;
};

export const fetchVenueSettings = async () => {
  const response = await get(`/settings`);
  return response.data;
};

export const fetchVenuePriorities = async () => {
  const response = await get(`/priorities`);

  return response.data;
};

export const updateVenuePriorities = async (data) => {
  const response = await patch(`/priorities`, {
    data,
  });

  return response.data;
};

export const createVenuePriority = async (data) => {
  const response = await post(`/priorities`, {
    data,
  });

  return response.data;
};

export const updateVenuePriority = async (priorityId, data) => {
  const response = await patch(`/priorities/${priorityId}`, {
    data,
  });

  return response.data;
};

export const deleteVenuePriority = async (priorityId) => {
  const response = await del(`/priorities/${priorityId}`);

  return response.data;
};

export const updateVenueSettings = async (data) => {
  const { settingName, settingValue, isDeliveryOn, isEatinOn } = data;

  const kioskSettings = {};
  if (isDeliveryOn !== undefined) {
    kioskSettings.isDeliveryOn = isDeliveryOn;
  }
  if (isEatinOn !== undefined) {
    kioskSettings.isEatinOn = isEatinOn;
  }

  const payload = {
    value: settingValue,
    ...(Object.keys(kioskSettings).length && { kioskSettings }),
  };

  const response = await patch(`/settings/${settingName}`, {
    data: payload,
  });
  return response.data;
};

export const updateSeveralSettings = async (values) => {
  const response = await patch('/settings', { data: values });
  return response.data;
};

export const printVenueQrcode = async (qrCodeId, isHiRes) => {
  const sessionVenueId = localStorage.getItem('venue_id');
  const idToken = getIdToken();
  const url = `${process.env.REACT_APP_API_URI}/venues/${sessionVenueId}/qrcodes/${qrCodeId}?hires=${isHiRes}`;
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      Authorization: idToken,
      Accept: 'application/pdf',
    },
  });
  const blob = await response.blob();
  return blob;
};

export const createVenue = async (data) => {
  const response = await barePost(`/venues`, { data });
  return response.data;
};

export const updateVenue = async (data) => {
  const response = await patch(``, { data });
  return response.data;
};

export const fetchVenueAdditionalCharges = async () => {
  const response = await get(`/settings/ADDITIONAL_CHARGES`);
  return response.data;
};

export const updateVenueAdditionalCharges = async ({ values }) => {
  const response = await patch(`/settings/ADDITIONAL_CHARGES`, { data: values });
  return response.data;
};

export const fetchVenueHideGMVSettings = async () => {
  const response = await get(`/settings/HIDE_GMV_REPORTS`);
  return response.data;
};

export const deleteApicbase = async () => {
  await del(`/apicbase`);
};

export const createApicbase = async () => {
  await post(`/apicbase`);
};
