import React, { useEffect, useState } from 'react';
import { Button, Card, CardActions, CardContent } from '@mui/material';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { updateVenue } from '../../store/venues';
import CustomDialog from '../CustomDialog';
import withVenue from '../../hoc/withVenue';

const useStyles = makeStyles(() => ({
  cardActions: {
    justifyContent: 'flex-end',
  },
}));

const ActiveVenueWatcher = ({ venue }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [isInactiveDialogOpen, setIsInactiveDialogOpen] = useState(false);

  useEffect(() => {
    if (!venue) return;
    if (venue.accountStatus === 'INACTIVE') {
      setIsInactiveDialogOpen(true);
    } else {
      setIsInactiveDialogOpen(false);
    }
  }, [venue]);

  const handleReactivate = async () => {
    await dispatch(updateVenue({ accountStatus: 'ACTIVE' }));

    setIsInactiveDialogOpen(false);
  };

  return (
    <CustomDialog title="Venue Inactive" isDialogOpen={isInactiveDialogOpen} canClose={false}>
      <Card>
        <CardContent>
          <strong>{venue.name}</strong> has not been active in a while so has been set to an
          inactive state. To reactivate this venue, please click the button below.
        </CardContent>
        <CardActions className={classes.cardActions}>
          <Button variant="contained" color="primary" onClick={handleReactivate}>
            Reactivate
          </Button>
        </CardActions>
      </Card>
    </CustomDialog>
  );
};
export default withVenue(ActiveVenueWatcher);
