import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Card, Typography, IconButton, CircularProgress } from '@mui/material';

import { makeStyles } from '@mui/styles';

import { MdClose } from 'react-icons/md';
import withVenue from '../../../hoc/withVenue';
import PageHeader from '../../PageHeader';
import {
  getBrandsVenueAssociationsForVariation,
  getBrandsVenueAssociationsState,
} from '../../../store/brands/brandsVenueAssociations/selectors';
import {
  clearBrandsVenuesAssociations,
  fetchBrandsVenuesAssociations,
  publishAllBrandsVenueAssociationsMenu,
  pushAllBrandsVenuesAssociations,
  pushBrandsVenuesAssociations,
  removeBrandsVenuesAssociation,
} from '../../../store/brands/brandsVenueAssociations';
import shouldLoad from '../../../shared/utils/shouldLoad';
import VenueTable from './VenueTable';
import { getVenues } from '../../../store/venues/selectors';
import AddAssociationForm from './AddAssociationForm';
import { useNotifications } from '../../../shared/contexts/Notifications/useNotifications';
import { getErrorMessage } from '../../../shared/utils/errors';
import { clearBrandsMenus } from '../../../store/brands/brandsMenus';

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    padding: theme.spacing(2),
    overflowY: 'scroll',
  },
  dialogHeader: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

const VenueAssociation = ({
  handleCloseDialog,
  brandId,
  title,
  isBrandLevel = false,
  brandMenuId,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [batchUpdateAlert, setBatchUpdateAlert] = useState(null);

  const brandsVenueAssociationsState = useSelector(getBrandsVenueAssociationsState);
  const brandsVenueAssociations = useSelector(getBrandsVenueAssociationsForVariation);
  const { error: brandsVenueAssociationError, loading } = brandsVenueAssociationsState;
  const venues = useSelector(getVenues);
  const { showErrorNotification, showSuccessNotification } = useNotifications();

  const handleVenueAssociation = async (values, isUpdateAction = false) => {
    const { menuName } = values;
    let submitValues = values;

    if (isUpdateAction) submitValues = { venueId: values.venueId };

    try {
      const variationId = brandMenuId || menuName;
      await dispatch(pushBrandsVenuesAssociations(variationId, submitValues));
      showSuccessNotification('Venue association added successfully');
    } catch (e) {
      showErrorNotification(getErrorMessage(e));
      dispatch(fetchBrandsVenuesAssociations(brandId, brandMenuId));
    }
  };

  const handleAllVenuesAssociation = async () => {
    try {
      await dispatch(pushAllBrandsVenuesAssociations(brandMenuId));
      setBatchUpdateAlert('Working on it - that might take a minute!');
    } catch (e) {
      showErrorNotification(getErrorMessage(e));
    }

    await dispatch(fetchBrandsVenuesAssociations(brandId, brandMenuId));
  };

  const handlePublishAllVenues = async () => {
    try {
      await dispatch(publishAllBrandsVenueAssociationsMenu(brandMenuId));
      setBatchUpdateAlert('Working on it - that might take a minute!');
    } catch (e) {
      showErrorNotification(getErrorMessage(e));
    }
  };

  const handleRemoveAssociation = async (venueId, variationId) => {
    try {
      await dispatch(removeBrandsVenuesAssociation({ variationId, venueId }));
      showSuccessNotification('Venue association removed successfully');
      dispatch(fetchBrandsVenuesAssociations(brandId, brandMenuId));
    } catch (e) {
      showErrorNotification(getErrorMessage(e));
    }
  };

  useEffect(() => {
    if (shouldLoad(brandsVenueAssociationsState))
      dispatch(fetchBrandsVenuesAssociations(brandId, brandMenuId));
  }, [dispatch, brandId, brandsVenueAssociationsState, brandMenuId]);

  useEffect(() => {
    if (batchUpdateAlert !== null) {
      const timer = setTimeout(() => {
        setBatchUpdateAlert(null);
      }, 60000); // 60 seconds

      return () => clearTimeout(timer); // Cleanup the timer if batchUpdateAlert changes
    }
    return undefined;
  }, [batchUpdateAlert]);

  return (
    <Card className={classes.cardContainer}>
      <PageHeader fullWidth className={classes.dialogHeader}>
        <Typography variant="h1" gutterBottom>
          {title}
        </Typography>
        <IconButton onClick={handleCloseDialog} size="small">
          <MdClose />
        </IconButton>
      </PageHeader>
      {brandsVenueAssociationError && (
        <Typography variant="body1" color="error">
          Error fetching venue associations
        </Typography>
      )}
      {!brandsVenueAssociationError ? (
        <VenueTable
          loading={loading}
          variationId={brandMenuId}
          brandsVenueAssociations={brandsVenueAssociations}
          handleVenueAssociation={handleVenueAssociation}
          handleAllVenuesAssociation={handleAllVenuesAssociation}
          handleRemoveAssociation={handleRemoveAssociation}
          brandId={brandId}
          batchUpdateAlert={batchUpdateAlert}
          handlePublishAllVenues={handlePublishAllVenues}
        />
      ) : (
        <CircularProgress />
      )}
      {!isBrandLevel && (
        <AddAssociationForm venues={venues} handleSubmit={handleVenueAssociation} />
      )}
    </Card>
  );
};

export default withVenue(VenueAssociation, null, [clearBrandsVenuesAssociations, clearBrandsMenus]);
