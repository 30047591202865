import { createSelector } from '@reduxjs/toolkit';
import { ascend, prop, sortWith } from 'ramda';

const printerSort = sortWith([ascend(prop('printerId'))]);
export const getPrintersState = (state) => state.printers;
export const getAvailablePrintersState = (state) => state.availablePrinters;

export const getPrinterState = (state) => state.printer;

export const getPrinters = createSelector(
  getPrintersState,
  (printersState) => printersState.data && printerSort(printersState.data),
);

export const getPrintersObj = createSelector(getPrintersState, (printers) => {
  if (!printers.data) return null;
  return printers.data.reduce((acc, printer) => {
    const { printerId, name, iccid, destination, imei } = printer;
    return { [printerId]: { printerId, name, destination, iccid, imei }, ...acc };
  }, {});
});
