export default [
  {
    categoryName: 'Vegetarian',
    categoryId: '10',
    type: 'FOOD',
    venueId: '1',
  },
  {
    categoryName: 'Fish & Seafood',
    categoryId: '11',
    type: 'FOOD',
    venueId: '1',
  },
  {
    categoryName: 'Bar snacks',
    categoryId: '12',
    type: 'DRINK',
    venueId: '1',
  },
  {
    categoryName: 'Meat and Poultry',
    categoryId: '13',
    type: 'DRINK',
    venueId: '1',
  },
  {
    categoryName: 'Desserts',
    categoryId: '14',
    type: 'FOOD',
    venueId: '1',
  },
  {
    categoryName: 'Sandwiches',
    categoryId: '15',
    type: 'FOOD',
    venueId: '1',
  },
  {
    categoryName: 'Beer',
    categoryId: '2',
    type: 'DRINK',
    venueId: '1',
  },
  {
    categoryName: 'White Wines',
    categoryId: '3',
    type: 'DRINK',
    venueId: '1',
  },
  {
    categoryName: 'Red Wines',
    categoryId: '4',
    type: 'DRINK',
    venueId: '1',
  },
  {
    categoryName: 'Spirits',
    categoryId: '5',
    type: 'DRINK',
    venueId: '1',
  },
  {
    categoryName: 'Cocktails',
    categoryId: '6',
    type: 'DRINK',
    venueId: '1',
  },
  {
    categoryName: 'Soft Drinks',
    categoryId: '7',
    type: 'DRINK',
    venueId: '1',
  },
  {
    categoryName: 'Wine',
    categoryId: '8',
    type: 'DRINK',
    venueId: '1',
  },
  {
    categoryName: 'Miscellaneous alcohol',
    categoryId: '9',
    type: 'DRINK',
    venueId: '1',
  },
];
