const menuDeliveryTypes = {
  DELIVERY: {
    value: 'DELIVERY',
    text: 'Delivery',
  },
  DELIVERY_INHOUSE: {
    value: 'DELIVERY_INHOUSE',
    text: 'Delivery - Managed by you',
  },
};

export default menuDeliveryTypes;
