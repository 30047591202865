import React from 'react';
import { useSelector } from 'react-redux';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import Paper from '@mui/material/Paper';
import { Box, Button, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment from 'moment-timezone';

import { getExternalPayoutsState } from '../../store/payouts-external/selectors';
import TableSkeletonLoader from '../TableSkeletonLoader';
import TablePaginationActions from '../TablePaginationActions';

const useStyles = makeStyles((theme) => ({
  type: {
    textTransform: 'capitalize',
    color: theme.palette.grey[600],
  },
}));

const ExternalPayoutsTable = ({ pagination, handleOpenPayoutEditDialogue }) => {
  const { page, handleChangePage, rowsPerPage } = pagination;
  const { loading, data: payouts } = useSelector(getExternalPayoutsState);
  const { docs } = payouts || {};
  const hasPayouts = !!payouts?.docs.length;
  const payoutsTotal = payouts?.total;
  const classes = useStyles();

  if (!hasPayouts) {
    return <Typography>Sorry, no transactions to be shown for your current filters.</Typography>;
  }

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Amount</TableCell>
            <TableCell>Reference</TableCell>
            <TableCell>Date</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        {loading && <TableSkeletonLoader rows={10} cells={3} cellsSize={['small']} />}
        {!loading && hasPayouts && (
          <>
            <TableBody>
              {docs.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((payout) => {
                const { type, recordId, reference, amount, day } = payout;
                const finalType = type.toLowerCase().replace(/_/g, ' ');
                const displayAmount = amount.toLocaleString();
                const date = moment(day).local().format('DD/MM/YY');

                return (
                  <TableRow key={recordId}>
                    <TableCell>
                      <Grid>
                        <Box>£{displayAmount}</Box>
                        <Box className={classes.type}>{finalType}</Box>
                      </Grid>
                    </TableCell>
                    <TableCell>{reference}</TableCell>
                    <TableCell>{date}</TableCell>
                    <TableCell>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => handleOpenPayoutEditDialogue(payout)}
                      >
                        Edit
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </>
        )}
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[]}
              count={payoutsTotal}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

export default ExternalPayoutsTable;
