import React from 'react';
import CustomDialog from '../../components/CustomDialog';
import AddDiscount from './AddDiscount';

const DiscountDialogue = ({ discount, isOpen, setIsOpen, onSubmit }) => {
  const title = discount ? 'Edit Discount' : 'Add a new discount';
  const handleCloseCategoryDialog = () => {
    setIsOpen(false);
  };
  return (
    <CustomDialog title={title} isDialogOpen={isOpen} handleCloseDialog={handleCloseCategoryDialog}>
      <AddDiscount
        discount={discount}
        modalSubmit={onSubmit}
        modalClose={handleCloseCategoryDialog}
      />
    </CustomDialog>
  );
};
export default DiscountDialogue;
