import React from 'react';
import withVenue from '../../hoc/withVenue';
import DebitsSection from './DebitsSection';
import PayoutsSection from './PayoutsSection';

const Payouts = () => (
  <>
    <PayoutsSection />
    <br />
    <DebitsSection />
  </>
);

export default withVenue(Payouts);
