import { createSelector } from '@reduxjs/toolkit';
import { ascend, prop, sortWith } from 'ramda';

const ingredientSort = sortWith([ascend(prop('type')), ascend(prop('ingredientName'))]);
export const getBrandsIngredientsState = (state) => state.brandsIngredients;

export const getBrandsIngredientState = (state) => state.brandsIngredient;

export const getIngredients = createSelector(
  getBrandsIngredientsState,
  (ingredientsState) => ingredientsState.data && ingredientSort(ingredientsState.data),
);

export const getBrandsIngredientsObj = createSelector(getBrandsIngredientsState, (ingredients) => {
  if (!ingredients.data) return null;
  return ingredients.data.reduce((acc, ingredient) => {
    const { ingredientId, ingredientName, type, available, label } = ingredient;
    return { [ingredientId]: { ingredientId, ingredientName, type, available, label }, ...acc };
  }, {});
});
