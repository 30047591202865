const signupSteps = [
  {
    position: 1,
    path: '',
    mainStep: true,
  },
  {
    position: 1.25,
    path: 'venue-details-1',
  },
  {
    position: 1.75,
    path: 'venue-details-2',
  },
  {
    position: 2,
    path: 'venue-details-3',
    mainStep: true,
  },
  {
    position: 2.5,
    path: 'company-details-1',
  },
  {
    position: 3,
    path: 'company-details-2',
    mainStep: true,
  },
  {
    position: 3.5,
    path: 'business-details-1',
  },
  {
    position: 3.75,
    path: 'business-details-2',
  },
  {
    position: 4,
    path: 'business-details-3',
    mainStep: true,
  },
  {
    position: 4,
    path: 'review',
  },
  {
    position: 4,
    path: 'complete',
  },
];

export default signupSteps;
