import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { makeStyles } from '@mui/styles';
import { Box, Button } from '@mui/material';
import { MdAdd } from 'react-icons/md';
import OptionForm from '../../../components/OptionForm/index';

import withVenue from '../../../hoc/withVenue';
import { useNotifications } from '../../../shared/contexts/Notifications/useNotifications';
import CustomDialog from '../../../components/CustomDialog';
import { getErrorMessage } from '../../../shared/utils/errors';
import useRoles from '../../../hooks/useRoles';
import { createBrandsOption, fetchBrandsOptions } from '../../../store/brands/brandsOptions';

const useStyles = makeStyles((theme) => ({
  button: {
    textWrap: 'nowrap',
    paddingLeft: '10px',
    marginLeft: 'auto',
    [theme.breakpoints.up('xl')]: {
      marginLeft: 'unset',
    },
  },
}));

const CreateSizeButton = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [optionData, setOptionData] = useState(null);
  const [formAction, setFormAction] = useState('');
  const { showErrorNotification, showSuccessNotification } = useNotifications();
  const { isRoleAtLeastManager } = useRoles();

  const handleOnSubmit = async (values) => {
    try {
      await dispatch(createBrandsOption(values));
      showSuccessNotification('Size has been added successfully');
      setIsDialogOpen(false);
      dispatch(fetchBrandsOptions());
    } catch (e) {
      showErrorNotification(getErrorMessage(e));
      setIsDialogOpen(false);
    }
  };

  const handleOpenDialog = () => {
    setOptionData(null);
    setFormAction('create');
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const ProductSizeOptionForm = () => (
    <OptionForm
      formAction={formAction}
      optionData={optionData}
      onSubmit={handleOnSubmit}
      onCancel={handleCloseDialog}
    />
  );

  return (
    <>
      <Box className={classes.button}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleOpenDialog}
          disabled={!isRoleAtLeastManager()}
          startIcon={<MdAdd />}
        >
          New Size
        </Button>
      </Box>
      <Box>
        <aside>
          <CustomDialog
            isDialogOpen={isDialogOpen}
            handleCloseDialog={handleCloseDialog}
            title="Create new product size"
          >
            <ProductSizeOptionForm />
          </CustomDialog>
        </aside>
      </Box>
    </>
  );
};

export default withVenue(CreateSizeButton);
