import React from 'react';
import useStripeStatusBanner from '../../../hooks/useStripeStatusBanner';
import StripePaymentDetails from './StripePaymentDetails';

const StripeAccountContainer = ({ redirect }) => {
  const { banner, accountDetails } = useStripeStatusBanner();

  if (!accountDetails) {
    return null;
  }

  return (
    <StripePaymentDetails accountDetails={accountDetails} redirect={redirect} banner={banner} />
  );
};

export default StripeAccountContainer;
