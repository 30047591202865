import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';

import { fetchTakeawayOrders } from '../../store/orders';
import { getOrdersTakeawayState } from '../../store/orders/selectors';

import Page from '../../components/Page';
import OrdersLiveTable from '../../components/OrdersLiveTable';
import { enrichStation } from '../../shared/utils/openingTimesUtils';
import withVenue from '../../hoc/withVenue';
import useESPicker from '../../hooks/useESPicker';
import ESPicker from '../../components/ESPicker';
import DownloadReport from '../../components/DownloadReport';
import PageHeader from '../../components/PageHeader';

const useStyles = makeStyles((theme) => ({
  heading: {
    marginBottom: '20px',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  title: {
    marginRight: '20px',
    width: '50%',
    flexGrow: 1,
  },
  form: {
    width: '100%',
    flexGrow: 2,
  },
  formElement: {
    paddingRight: theme.spacing(2),
  },
}));

const OrdersTakeaway = ({ venue }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { data, error } = useSelector(getOrdersTakeawayState);

  const esPicker = useESPicker({
    useOrder: false,
    useSearch: false,
    useOptional: true,
  });
  const { selectedDates, dateRange, active, reportingType } = esPicker;
  const [downloadPath, setDownloadPath] = useState(null);

  const enrichedStation = enrichStation(venue);

  useEffect(() => {
    const { from, to } = selectedDates;
    const params = active
      ? {
          from,
          to,
          dateRange,
          reportingType,
        }
      : {};
    dispatch(fetchTakeawayOrders(params));
    const timer = setInterval(() => dispatch(fetchTakeawayOrders(params)), 30000);
    return () => {
      clearInterval(timer);
    };
  }, [dateRange, dispatch, selectedDates, active, reportingType]);

  useEffect(() => {
    const { from, to } = selectedDates;
    const params = dateRange ? `from=${from}&to=${to}` : '';
    const useParams = active ? params : '';
    setDownloadPath(`/orders/takeaway/export?${useParams}`);
  }, [dateRange, selectedDates, active]);

  return (
    <>
      <PageHeader fullWidth>
        <div className={classes.heading}>
          <Typography noWrap className={classes.title} variant="h2" component="h1">
            Takeaway Orders
          </Typography>
          <ESPicker esPicker={esPicker}>
            <DownloadReport
              path={downloadPath}
              type="takeawayOrders"
              title="Export"
              fullWidth={false}
              includeVouchers="exclude"
            />
          </ESPicker>
        </div>
      </PageHeader>
      <Page error={error} fullWidth>
        {data && <OrdersLiveTable isOpen={enrichedStation.isOpen} orders={data} />}
      </Page>
    </>
  );
};

export default withVenue(OrdersTakeaway);
