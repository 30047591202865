import { PedalBikeRounded, PhoneAndroidRounded, QrCode2Rounded } from '@mui/icons-material';
import React from 'react';
import { ReactComponent as KioskIcon } from '../../assets/images/kiosk_icon.svg';

export default {
  WEB: {
    value: 'WEB',
    label: 'QR',
    icon: <QrCode2Rounded />,
    iconColor: '#FFD5CC',
  },
  EPOS: {
    value: 'EPOS',
    label: 'EPOS',
    icon: <PhoneAndroidRounded />,
    iconColor: '#FFF3B8',
  },
  DELIVERY: {
    value: 'DELIVERY',
    label: 'Delivery',
    icon: <PedalBikeRounded />,
    iconColor: '#D4FED1',
  },
  DELIVERY_INHOUSE: {
    value: 'DELIVERY_INHOUSE',
    label: 'Delivery',
    icon: <PedalBikeRounded />,
    iconColor: '#D4FED1',
  },
  KIOSK: {
    value: 'KIOSK',
    label: 'Kiosk',
    icon: <KioskIcon />,
    iconColor: '#E3CDFF',
  },
};
