import { ListItemButton, ListItemText } from '@mui/material';
import { withStyles } from '@mui/styles';

export const AdminItem = withStyles({
  root: {
    background: '#333333',
    borderRadius: 0,
    border: 0,
    color: 'white',
    height: 48,
    boxShadow:
      '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
    '&:hover': {
      background: '#222222',
    },
  },
})(ListItemButton);

export const AdminItemText = withStyles({
  primary: {
    color: 'white',
  },
})(ListItemText);
