import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import { TextField, CheckboxWithLabel, Checkbox } from 'formik-mui';

import { makeStyles, useTheme } from '@mui/styles';
import {
  Card,
  CardContent,
  MenuItem,
  FormControlLabel,
  FormGroup,
  Typography,
  Box,
  FormControl,
  Checkbox as MuiCheckbox,
  Alert,
} from '@mui/material';

import { fetchBrandsOptions } from '../../../store/brands/brandsOptions';
import { getBrandsOptionsState } from '../../../store/brands/brandsOptions/selectors';
import { getBrandsCategoriesState } from '../../../store/brands/brandsCategories/selectors';
import { fetchDietaryRequirements } from '../../../store/dietaryRequirements';
import { getDietaryRequirementsState } from '../../../store/dietaryRequirements/selectors';
import { getBrandsState } from '../../../store/brands/brands/selectors';
import { fetchBrands } from '../../../store/brands/brands';
import PhotoUploader from '../../PhotoUploader';
import UniversalSave from '../../UniversalSave';
import Page from '../../Page';
import CustomDialog from '../../CustomDialog';
import BrandsOptions from './BrandsOptions';
import AddBrandsCategory from '../../../pages/Brands/BrandsCategories/AddBrandsCategory';
import { fetchBrandsCategories } from '../../../store/brands/brandsCategories';

const checkList = (list, array) => {
  const matches = [];
  list.forEach((item) => {
    if (array.includes(item)) {
      matches.push(item);
    }
  });
  return matches;
};

const getNameFromRequirementId = (id, dietaryRequirements) => {
  const returnName = dietaryRequirements.find((requirement) => requirement.requirementId === id);

  return returnName.name;
};

const hasConflict = (requirement, array) => array.includes(requirement) && array.length > 1;

const getAllergenConflictMessage = (matches, value, dietaryRequirements, title) => {
  if (!value || value.length === 0) return [false];
  const matchingList = checkList(matches, value);
  const matchingNames = matchingList
    .slice(1)
    .map((item) => getNameFromRequirementId(item, dietaryRequirements))
    .join(', ');
  const conflict = hasConflict(matches[0], matchingList);
  const message = `You cannot use the dietary preference ${title} and the allergens ‘${matchingNames}’.`;
  return [conflict, message];
};

// eslint-disable-next-line func-names
const dietaryConflicts = function (errorMessage, dietaryRequirements) {
  // eslint-disable-next-line func-names
  return this.test(`test-dietary-conflicts`, errorMessage, function (value) {
    const { path, createError } = this;
    const [dairyConflict, dairyMessage] = getAllergenConflictMessage(
      ['34', '8'],
      value,
      dietaryRequirements,
      'Dairy free',
    );
    const [veganConflict, veganMessage] = getAllergenConflictMessage(
      ['32', '33', '9', '5', '4', '3', '8'],
      value,
      dietaryRequirements,
      'Vegan',
    );
    const [vegetarianConflict, vegetarianMessage] = getAllergenConflictMessage(
      ['33', '9', '5', '3'],
      value,
      dietaryRequirements,
      'Vegetarian',
    );
    const [glutenConflict, glutenMessage] = getAllergenConflictMessage(
      ['35', '6', '29', '30', '26', '23', '21', '27'],
      value,
      dietaryRequirements,
      'Gluten free',
    );

    const message =
      (dairyConflict && dairyMessage) ||
      (veganConflict && veganMessage) ||
      (vegetarianConflict && vegetarianMessage) ||
      (glutenConflict && glutenMessage);

    return (
      (!dairyConflict && !veganConflict && !vegetarianConflict && !glutenConflict) ||
      createError({ path, message })
    );
  });
};

Yup.addMethod(Yup.array, 'dietaryConflicts', dietaryConflicts);

const imageApprovalDescriptionMap = {
  yes: null,
  pending: {
    title: 'Pending',
    content: 'Images will be reviewed within 24hrs.',
  },
  no: {
    title: 'Failed',
    content:
      'Your image was not approved, please try uploading a new one or select one from our gallery.',
  },
};

const useStyles = makeStyles((theme) => ({
  availableSection: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingBottom: '15px',
    marginBottom: '15px',
    borderBottom: '1px solid #f0f4f8',
  },
  labelText: {
    color: '#757575',
    marginLeft: '-10px',
  },
  section: {
    paddingBottom: '15px',
    marginBottom: '15px',
    borderBottom: '1px solid #f0f4f8',
  },
  cardActions: {
    justifyContent: 'flex-end',
  },
  dietIcon: {
    width: 20,
    height: 20,
    marginRight: 10,
  },
  title: { marginBottom: '15px' },
  formDescription: {
    ...theme.customFonts.small,
  },
  imgPreview: {
    width: 200,
  },
  error: {
    color: 'red !important',
  },
  discountOptions: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  discountOptionsPrices: {
    display: 'flex',
    gap: theme.spacing(1),
  },
  discountOriginalPrice: {
    textDecoration: 'line-through',
    color: '#647F99',
  },
}));

const BrandsMenuItemForm = ({
  initialValues,
  onSubmit,
  onReorderOptions,
  readOnlyFields,
  refreshItem,
  mealDealError,
  setMealDealError,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const formikRef = useRef();
  const mealDealRef = useRef();
  const dietaryRequirementsState = useSelector(getDietaryRequirementsState);
  const optionsState = useSelector(getBrandsOptionsState);
  const categoriesState = useSelector(getBrandsCategoriesState);
  const brandsState = useSelector(getBrandsState);

  const { data: brands } = brandsState;
  const { data: dietaryRequirements } = dietaryRequirementsState;
  const { data: options } = optionsState;
  const { data: categories } = categoriesState;

  const loading = dietaryRequirementsState.loading || categoriesState.loading;
  const error = dietaryRequirementsState.error || optionsState.error || categoriesState.error;
  const [newCategory, setNewCategory] = useState(null);
  const [isCategoryDialogOpen, setIsCategoryDialogOpen] = useState(false);
  const [useNameAsLabel, setUseNameAsLabel] = useState(
    initialValues.label === initialValues.itemName,
  );

  const theme = useTheme();

  useEffect(() => {
    dispatch(fetchDietaryRequirements());
    dispatch(fetchBrandsOptions());
    dispatch(fetchBrandsCategories());
    dispatch(fetchBrands());
  }, [dispatch]);

  useEffect(() => {
    if (
      !isCategoryDialogOpen &&
      newCategory?.categoryName &&
      categories.findIndex((e) => e.categoryName === newCategory?.categoryName) !== -1
    ) {
      if (formikRef.current) {
        formikRef.current.setFieldValue('category', newCategory?.categoryName);
        formikRef.current.setFieldValue('type', newCategory?.type);
      }
    }
  }, [newCategory, categories, isCategoryDialogOpen, initialValues]);

  const handleCloseCategoryDialog = (newCat) => {
    dispatch(fetchBrandsCategories());
    setNewCategory(newCat);
    setIsCategoryDialogOpen(false);
  };

  const getCategoryType = (value) =>
    categories.find((category) => category.categoryName === value).type;

  const handleUseNameAsLabelCheck = () => {
    setUseNameAsLabel(!useNameAsLabel);
    if (!useNameAsLabel) {
      formikRef.current.setFieldValue('label', formikRef.current.values.itemName);
    }
  };

  const MenuItemSchema = Yup.object().shape({
    itemName: Yup.string().min(3, 'Too Short!').required('Please enter a name'),
    label: Yup.string().min(3, 'Too Short!').required('Please enter a label'),
    category: Yup.string().required('Please choose a category'),
    brandName: Yup.string().required('Please choose a brand'),
    isAlcoholic: Yup.boolean().required(),
    isMealDeal: Yup.boolean(),
    inhouseOnly: Yup.boolean(),
    dietaryRequirements: Yup.array()
      .of(Yup.string())
      .dietaryConflicts('Conflicts with other dietary requirements', dietaryRequirements),
    vatRate: Yup.number().min(0).max(25).required('Please enter a vat rate'),
    itemOptions: Yup.array()
      .of(
        Yup.object().shape({
          optionName: Yup.string().required('Please choose a size'),
          optionPrice: Yup.number()
            .min(0, 'Price must be greater than 0')
            .typeError('Price must be a number')
            .required('Please choose a price for this size'),
          optionCalories: Yup.number()
            .min(0, 'Calories must be greater than or equal to 0')
            .notRequired()
            .default(undefined),
        }),
      )
      .min(1, 'Please choose at least one size'),
  });

  useEffect(() => {
    if (mealDealError) {
      mealDealRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [mealDealError]);

  return (
    <Page loading={loading} error={error} fullWidth>
      <Card className={classes.card}>
        <Formik
          innerRef={formikRef}
          enableReinitialize
          initialValues={initialValues}
          onSubmit={onSubmit}
          onReset={() => {
            setUseNameAsLabel(initialValues.label === initialValues.itemName);
            setMealDealError(null);
          }}
          readOnlyFields={readOnlyFields}
          validationSchema={MenuItemSchema}
        >
          {({
            values,
            dirty,
            isValid,
            errors,
            resetForm,
            setFieldValue,
            handleChange,
            setFieldTouched,
          }) => {
            const imageApprovalDescription =
              imageApprovalDescriptionMap[values.imageApprovalStatus];

            if (
              categories &&
              values.category &&
              !categories.find((e) => e.categoryName === values.category) &&
              formikRef.current
            ) {
              setFieldTouched('category', true);
              formikRef.current.setFieldValue('category', '');
            }

            return (
              <>
                <UniversalSave
                  isValid={isValid}
                  errors={errors}
                  dirty={dirty}
                  onSave={() => onSubmit(values)}
                  onDiscard={resetForm}
                />
                <Form>
                  <CardContent>
                    <section>
                      <FormGroup className={classes.section}>
                        <FormControlLabel
                          control={
                            <Field
                              component={Checkbox}
                              color="primary"
                              type="checkbox"
                              name="isAlcoholic"
                              disabled={initialValues.readonly}
                            />
                          }
                          label="Alcoholic"
                        />
                      </FormGroup>
                    </section>
                    {brands && (
                      <FormGroup>
                        <Field
                          component={TextField}
                          select
                          fullWidth
                          name="brandName"
                          label="Select Brand"
                          variant="outlined"
                          disabled={initialValues.readonly || readOnlyFields?.includes('brandName')}
                          margin="normal"
                          required
                          onChange={({ target }) => {
                            const brand = brands.find((b) => b.name === target.value);
                            setFieldValue('brandName', brand.name);
                            setFieldValue('brandId', brand.brandId);
                          }}
                        >
                          {brands.map((brand) => (
                            <MenuItem value={brand.name} key={brand.brandId}>
                              {brand.name}
                            </MenuItem>
                          ))}
                        </Field>
                      </FormGroup>
                    )}
                    {categories && (
                      <section>
                        <FormGroup className={classes.section}>
                          <Field
                            component={TextField}
                            select
                            fullWidth
                            name="category"
                            label="Select Category"
                            variant="outlined"
                            disabled={
                              initialValues.readonly || readOnlyFields?.includes('category')
                            }
                            margin="normal"
                            required
                            onChange={({ target }) => {
                              if (target.value === 'create') {
                                setIsCategoryDialogOpen(true);
                                return;
                              }
                              const categoryType = getCategoryType(target.value);
                              const suggestedVatRate = categoryType === 'FOOD' ? 20 : 20;

                              setFieldValue('category', target.value);
                              setFieldValue('type', categoryType);
                              setFieldValue('vatRate', suggestedVatRate);
                            }}
                          >
                            {categories.map((category) => (
                              <MenuItem value={category.categoryName} key={category.categoryId}>
                                {category.categoryName}
                              </MenuItem>
                            ))}
                            <MenuItem value="create" key="New Category">
                              + New Category
                            </MenuItem>
                          </Field>
                        </FormGroup>
                      </section>
                    )}
                    <FormGroup className={classes.section}>
                      <Field
                        component={TextField}
                        name="itemName"
                        label="Internal Name"
                        variant="outlined"
                        fullWidth
                        disabled={initialValues.readonly || readOnlyFields?.includes('itemName')}
                        required
                        margin="normal"
                        onChange={({ target }) => {
                          if (useNameAsLabel) {
                            setFieldValue('label', target.value);
                          }
                          setFieldValue('itemName', target.value);
                        }}
                      />
                      <Box>
                        <FormControlLabel
                          control={<MuiCheckbox name="copyProductNameToLabel" color="primary" />}
                          checked={useNameAsLabel}
                          onChange={handleUseNameAsLabelCheck}
                          label="Use Name as Label"
                        />
                        <Field
                          component={TextField}
                          name="label"
                          label="External Label"
                          variant="outlined"
                          fullWidth
                          required
                          disabled={
                            initialValues.readonly ||
                            readOnlyFields?.includes('label') ||
                            useNameAsLabel
                          }
                          margin="normal"
                          value={values.label}
                        />
                      </Box>
                      <Field
                        component={TextField}
                        name="description"
                        label="Description"
                        variant="outlined"
                        fullWidth
                        multiline
                        disabled={initialValues.readonly || readOnlyFields?.includes('description')}
                        margin="normal"
                      />
                      <Field
                        component={TextField}
                        name="vatRate"
                        label="Vat Rate (%)"
                        variant="outlined"
                        type="number"
                        InputProps={{ inputProps: { min: 0, max: 25, step: '0.1' } }}
                        disabled={initialValues.readonly || readOnlyFields?.includes('vatRate')}
                        margin="normal"
                      />
                    </FormGroup>
                    <FormGroup className={classes.section}>
                      <Box display="flex" justifyContent="space-between">
                        <div>
                          <Typography className={classes.title} variant="h4">
                            Product photo
                          </Typography>
                          <PhotoUploader
                            disabled={initialValues.readonly}
                            hasImage={values.hasImage}
                            imgUrl={values.imgUrl}
                            originalImgUrl={values.originalImgUrl}
                            imgUploadUrl={values.imgUploadUrl}
                            imgUploadUrlOriginal={values.imgOriginalUploadUrl}
                            refresh={refreshItem}
                            itemId={values.itemId}
                            imageApprovalStatus={values.imageApprovalStatus}
                            isBrands
                          />
                          {imageApprovalDescription && (
                            <Box my={2}>
                              <Typography>
                                <strong>Upload status</strong>: {imageApprovalDescription.title}
                              </Typography>
                              <Typography>{imageApprovalDescription.content}</Typography>
                            </Box>
                          )}
                        </div>
                      </Box>
                    </FormGroup>
                    <FormGroup className={classes.section}>
                      <FormControlLabel
                        control={
                          <Field
                            component={Checkbox}
                            color="primary"
                            type="checkbox"
                            name="isMealDeal"
                            disabled={initialValues.readonly}
                          />
                        }
                        label="Meal Deal"
                        ref={mealDealRef}
                      />
                      <Typography className={classes.formDescription}>
                        This means the item name “{values.itemName}” will never be printed on a
                        ticket - only the modifier components will print, in their respective menu
                        sections
                      </Typography>
                      {mealDealError && <Alert severity="error">{mealDealError}</Alert>}
                    </FormGroup>
                    <FormGroup className={classes.section}>
                      <FormControlLabel
                        control={
                          <Field
                            component={Checkbox}
                            color="primary"
                            type="checkbox"
                            name="inhouseOnly"
                            disabled={initialValues.readonly}
                          />
                        }
                        label="Inhouse Only"
                      />
                      <Typography className={classes.formDescription}>
                        This means the item will only be available for inhouse menus, and will not
                        appear on delivery platforms
                      </Typography>
                    </FormGroup>
                    <FormGroup className={classes.section} sx={{ borderBottom: 'none !important' }}>
                      <Typography className={classes.title} variant="h4">
                        Dietary Preferences
                      </Typography>
                      <FormControl
                        disabled={initialValues.readonly}
                        component="fieldset"
                        type="checkbox"
                        color="primary"
                        style={{
                          justifyContent: 'flex-start',
                          flexFlow: 'row wrap',
                          width: '100%',
                        }}
                      >
                        {dietaryRequirements &&
                          dietaryRequirements.length > 0 &&
                          dietaryRequirements.map(
                            ({ name, type, requirementId, abbreviation }) =>
                              type === 'requirement' && (
                                <div style={{ minWidth: '200px' }} key={requirementId}>
                                  <Field
                                    type="checkbox"
                                    component={CheckboxWithLabel}
                                    name="dietaryRequirements"
                                    value={requirementId}
                                    onChange={(e) => {
                                      setFieldTouched('dietaryRequirements', true);
                                      handleChange(e);
                                    }}
                                    Label={{
                                      label: `${name}${abbreviation && `(${abbreviation})`}`,
                                    }}
                                  />
                                </div>
                              ),
                          )}
                      </FormControl>
                    </FormGroup>
                    <FormGroup className={classes.section}>
                      <Typography className={classes.title} variant="h4">
                        Contains Allergens
                      </Typography>
                      <FormControl
                        disabled={initialValues.readonly}
                        component="fieldset"
                        type="checkbox"
                        color="primary"
                        style={{
                          justifyContent: 'flex-start',
                          flexFlow: 'row wrap',
                          width: '100%',
                        }}
                      >
                        {dietaryRequirements &&
                          dietaryRequirements.map(
                            ({ name, type, requirementId }) =>
                              type === 'allergen' && (
                                <div style={{ minWidth: '200px' }} key={requirementId}>
                                  <Field
                                    disabled={initialValues.readonly}
                                    type="checkbox"
                                    component={CheckboxWithLabel}
                                    name="dietaryRequirements"
                                    value={requirementId}
                                    Label={{ label: name }}
                                    onChange={(e) => {
                                      setFieldTouched('dietaryRequirements', true);
                                      handleChange(e);
                                    }}
                                  />
                                </div>
                              ),
                          )}
                      </FormControl>
                    </FormGroup>
                    <div style={{ color: theme.palette.error.main }}>
                      <ErrorMessage name="dietaryRequirements" component="div" />
                    </div>

                    <br />
                    {options && (
                      <BrandsOptions
                        readonly={initialValues.readonly}
                        setFieldValue={setFieldValue}
                        itemOptions={values.itemOptions}
                        allowReordering={
                          !initialValues.readonly && !dirty && values.itemOptions.length > 1
                        }
                        onReorder={onReorderOptions}
                      />
                    )}
                  </CardContent>
                </Form>
              </>
            );
          }}
        </Formik>
      </Card>
      <CustomDialog
        title="Add a new category"
        isDialogOpen={isCategoryDialogOpen}
        handleCloseDialog={handleCloseCategoryDialog}
      >
        <AddBrandsCategory
          modalSubmit={handleCloseCategoryDialog}
          modalClose={handleCloseCategoryDialog}
        />
      </CustomDialog>
    </Page>
  );
};

export default BrandsMenuItemForm;
