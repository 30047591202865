import { createSelector } from '@reduxjs/toolkit';
import { ascend, prop, sortWith } from 'ramda';

const modifierSort = sortWith([ascend(prop('type')), ascend(prop('modifierName'))]);
export const getBrandsModifiersState = (state) => state.brandsModifiers;

export const getBrandsModifierState = (state) => state.brandsModifier;

export const getModifiers = createSelector(
  getBrandsModifiersState,
  (brandsModifiersState) => brandsModifiersState.data && modifierSort(brandsModifiersState.data),
);

export const getModifiersObj = createSelector(getBrandsModifiersState, (modifiers) => {
  if (!modifiers.data) return null;
  return modifiers.data.reduce((acc, modifier) => {
    const { modifierId, modifierName, type, label } = modifier;
    return { [modifierId]: { modifierId, modifierName, type, label }, ...acc };
  }, {});
});
