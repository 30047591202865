import { createSlice } from '@reduxjs/toolkit';

import {
  createAsyncAction,
  asyncInitialState as initialState,
  createAsyncReducerHandlers,
} from '../../../utils/async';

import {
  fetchBrandsMenuBuilderMenusApi,
  fetchBrandsMenuBuilderMenuApi,
  patchBrandsMenuBuilderMenuApi,
} from '../../../../api/brands/builderMenu/builderMenus';

export const fetchBuilderMenus = createAsyncAction(
  'fetch-brands-menu-builder-menus',
  fetchBrandsMenuBuilderMenusApi,
);

export const fetchBuilderMenu = createAsyncAction(
  'fetch-brands-menu-builder-menu',
  fetchBrandsMenuBuilderMenuApi,
);

export const patchBuilderMenu = createAsyncAction(
  'patch-brands-menu-builder-menu',
  patchBrandsMenuBuilderMenuApi,
);

const builderMenusSlice = createSlice({
  name: 'builder-menus',
  initialState,
  reducers: {
    clearBuilderMenus(state) {
      delete state.data;
    },
  },
  extraReducers: {
    ...createAsyncReducerHandlers(fetchBuilderMenus),
  },
});

const builderMenuSlice = createSlice({
  name: 'builder-menu',
  initialState,
  reducers: {
    clearBuilderMenu(state) {
      delete state.data;
    },
  },
  extraReducers: {
    ...createAsyncReducerHandlers(fetchBuilderMenu),
    ...createAsyncReducerHandlers(patchBuilderMenu),
  },
});

export const { clearBuilderMenus } = builderMenusSlice.actions;
export const { clearBuilderMenu } = builderMenuSlice.actions;

export const { reducer: builderMenus } = builderMenusSlice;
export const { reducer: builderMenu } = builderMenuSlice;
