import React from 'react';

import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';

import Page from '../../components/Page';
import withVenue from '../../hoc/withVenue';
import AccessLockedCard from './AccessLockedCard';

const useStyles = makeStyles(() => ({
  heading: {
    marginBottom: '20px',
    display: 'flex',
  },
  title: {
    fontFamily: 'Sen',
    flexGrow: 1,
  },
}));

export const LOCKTYPES = { ROLE: 'role', STANDARD: 'standard', DELIVERY_ONLY: 'DELIVERY_ONLY' };

const Locked = ({ title }) => {
  const classes = useStyles();

  return (
    <Page>
      <header className={classes.heading}>
        <Typography className={classes.title} variant="h1" component="h1">
          {title}
        </Typography>
      </header>
      <AccessLockedCard />
    </Page>
  );
};

export default withVenue(Locked);
