import { createSlice } from '@reduxjs/toolkit';
import {
  asyncInitialState as initialState,
  createAsyncAction,
  createAsyncReducerHandlers,
} from '../utils/async';
import {
  fetchPersonalisation as fetchPersonalisationApi,
  updatePersonalisation as updatePersonalisationApi,
  fetchBannersGallery as fetchBannersGalleryApi,
} from '../../api/personalisation';

export const fetchPersonalisation = createAsyncAction(
  'fetch-personalisation',
  fetchPersonalisationApi,
);

export const updatePersonalisation = createAsyncAction(
  'update-personalisation',
  updatePersonalisationApi,
);

export const fetchBannersGallery = createAsyncAction(
  'fetch-bannersGallery',
  fetchBannersGalleryApi,
);

const personalisationSlice = createSlice({
  name: 'personalisation',
  initialState,
  reducers: {
    clearPersonalisation(state) {
      delete state.data;
    },
  },
  extraReducers: {
    ...createAsyncReducerHandlers(fetchPersonalisation),
    ...createAsyncReducerHandlers(updatePersonalisation),
  },
});
const bannerGallerySlice = createSlice({
  name: 'bannerGallery',
  initialState,
  reducers: {
    clearBannerGallery(state) {
      delete state.data;
    },
  },
  extraReducers: {
    ...createAsyncReducerHandlers(fetchBannersGallery),
  },
});
export const { clearPersonalisation } = personalisationSlice.actions;
export const { clearBannerGallery } = bannerGallerySlice.actions;

export const { reducer: personalisation } = personalisationSlice;
export const { reducer: bannerGallery } = bannerGallerySlice;
