import React from 'react';

import { makeStyles } from '@mui/styles';
import { Card, CardContent, Grid, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getReportOverviewState } from '../../store/reports/selectors';

const useStyles = makeStyles(() => ({
  title: {
    fontFamily: 'Sen',
    fontWeight: 'bold',
    flexGrow: 1,
    marginBottom: '1rem',
  },
  revenue: {
    fontSize: '2rem',
  },
  reportTable: {
    display: 'flex',
  },
  reportItem: {
    flexGrow: 1,
  },
  itemAmount: {
    fontSize: '1.5rem',
    marginBottom: '0.5rem',
  },
  itemTitle: {
    fontSize: '0.8rem',
    marginBottom: '1.5rem',
  },
  itemTitle2: {
    fontSize: '0.8rem',
    marginBottom: '0.75rem',
  },
}));

const ReportsCard = () => {
  const classes = useStyles();
  const { loading, data: analytics, error } = useSelector(getReportOverviewState);
  const { current } = analytics || {};

  const totalRevenue = current?.income.total;
  const totalNet = current?.netSales.total;
  const orders = current?.orders.total;

  return (
    !loading &&
    !error && (
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Typography className={classes.title} variant="h4">
              Todays report
            </Typography>
            <Typography variant="h4">
              £<span className={classes.revenue}>{totalRevenue}</span>
            </Typography>
            <Typography className={classes.itemTitle} variant="body2">
              GMV
            </Typography>
            <div className={classes.reportTable}>
              <div className={classes.reportItem}>
                <Typography className={classes.itemAmount} variant="h4">
                  £{totalNet}
                </Typography>
                <Typography className={classes.itemTitle2} variant="body2">
                  Net revenue
                </Typography>
              </div>
              <div className={classes.reportItem}>
                <Typography className={classes.itemAmount} variant="h4">
                  {orders}
                </Typography>
                <Typography className={classes.itemTitle2} variant="body2">
                  No. orders
                </Typography>
              </div>
            </div>
            <Link to="/reports/overview">Go to reports</Link>
          </CardContent>
        </Card>
      </Grid>
    )
  );
};

export default ReportsCard;
