import { getBare, postBare } from './httpRequest';

export const fetchImagesToReview = async () => {
  const response = await getBare('items/images/review');
  return response.data;
};

export const postImagesToReview = async (data) => {
  const response = await postBare(`items/images/review`, { data });
  return response.data;
};
