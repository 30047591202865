import React, { forwardRef } from 'react';
import { Card, CardActions, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ErrorButton from '../../components/Overrides/ErrorButton';
import CustomDialog from '../../components/CustomDialog';

const useStyles = makeStyles(() => ({
  heading: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

const DeleteAreaDialog = forwardRef(
  ({ handleCloseDialog, dialogIsOpen, submitDialog, areaName = '' }, ref) => {
    const classes = useStyles();

    const handleSubmit = (event) => {
      event.stopPropagation();
      handleCloseDialog();
      submitDialog(areaName);
    };
    return (
      <CustomDialog
        title={`Delete Area ${areaName}?`}
        isDialogOpen={dialogIsOpen}
        handleCloseDialog={handleCloseDialog}
        ref={ref}
      >
        <Card className={classes.card}>
          <CardActions className={classes.cardActions}>
            <Button onClick={handleCloseDialog}>Cancel</Button>
            <ErrorButton onClick={handleSubmit} variant="outlined">
              Delete
            </ErrorButton>
          </CardActions>
        </Card>
      </CustomDialog>
    );
  },
);

export default DeleteAreaDialog;
