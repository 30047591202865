export default [
  {
    itemPrices: [4.25, 3],
    isAlcoholic: true,
    available: true,
    itemId: '1',
    itemName: 'Blasting Tundra',
    itemOptions: ['Pint', 'Half pint'],
    venueId: '1',
    category: 'Beer',
    description: 'An agonizing, stabbing pain, then unconsciousness',
    type: 'DRINK',
  },
  {
    itemPrices: [5, 3],
    isAlcoholic: true,
    available: true,
    itemId: '10',
    itemName: 'Spicy Enigma',
    itemOptions: ['Double', 'Single'],
    venueId: '1',
    category: 'Spirits',
    description: "Take a power nap, maybe two since you've worked hard",
    type: 'DRINK',
  },
  {
    itemPrices: [6],
    isAlcoholic: false,
    available: true,
    itemId: '11',
    itemName: 'Ocean Cassia',
    itemOptions: ['Glass'],
    venueId: '1',
    category: 'Soft Drinks',
    description:
      'A good amount is needed for the potion to work, so make sure you drink enough, but not too much',
    type: 'DRINK',
  },
  {
    itemPrices: [6.99, 3.6],
    isAlcoholic: true,
    available: true,
    itemId: '12',
    itemName: 'Mystic Horror',
    itemOptions: ['Pint', 'Half pint'],
    venueId: '1',
    category: 'Beer',
    description: 'A good gulp is invigorating, but it tastes awful so maybe mix it with some honey',
    type: 'DRINK',
  },
  {
    itemPrices: [65],
    isAlcoholic: true,
    available: true,
    itemId: '13',
    itemName: 'Le Domaine Éclatant',
    itemOptions: ['Bottle'],
    venueId: '1',
    category: 'White Wines',
    description: 'Mixing it in a milkshake could work, but be wary of brain freeze',
    type: 'DRINK',
  },
  {
    itemPrices: [17.86],
    isAlcoholic: true,
    available: true,
    itemId: '14',
    itemName: 'Le Champ Des Avalanches',
    itemOptions: ['Serving'],
    venueId: '1',
    category: 'Wine',
    description: 'Too sweet and not very classy',
    type: 'DRINK',
  },
  {
    itemPrices: [3.56, 6],
    isAlcoholic: true,
    available: true,
    itemId: '15',
    itemName: 'Cosmic Petal',
    itemOptions: ['Half pint', 'Bottle'],
    venueId: '1',
    category: 'Beer',
    description: "Used on bugs it's very effective.",
    type: 'DRINK',
  },
  {
    itemPrices: [85, 0.06],
    isAlcoholic: false,
    available: true,
    itemId: '16',
    itemName: 'Marrow',
    itemOptions: ['Sharing platter', 'Serving'],
    venueId: '1',
    category: 'Vegetarian',
    description: 'Gum pain and pelvic pain often start out of nowhere within a week',
    type: 'FOOD',
  },
  {
    itemPrices: [5],
    isAlcoholic: false,
    available: true,
    itemId: '17',
    itemName: 'Butter Platter With Assorted Watermelon & Frightened Water',
    itemOptions: ['Sharing platter'],
    venueId: '1',
    category: 'Bar snacks',
    description: 'Usually either grey, dark brown or red or a combination of these colours',
    type: 'DRINK',
  },
  {
    itemPrices: [12],
    isAlcoholic: false,
    available: true,
    itemId: '18',
    itemName: 'Late-Summer Rice & Scraped Arugula Pie',
    itemOptions: ['Portion'],
    venueId: '1',
    category: 'Vegetarian',
    description: 'Served with assorted lime waffles',
    type: 'FOOD',
  },
  {
    itemPrices: [5],
    isAlcoholic: false,
    available: true,
    itemId: '19',
    itemName: 'Vinegar Extract With Invigorated Bison',
    itemOptions: ['Serving'],
    venueId: '1',
    category: 'Meat and Poultry',
    description: 'Covered in broken pottery, small bones and other remains',
    type: 'DRINK',
  },
  {
    itemPrices: [12, 4, 3],
    isAlcoholic: true,
    available: true,
    itemId: '2',
    itemName: 'Pays Du Poivin',
    itemOptions: ['Bottle', '250ml', '175ml'],
    venueId: '1',
    category: 'Red Wines',
    description: 'Head feels light, everything around you spinning',
    type: 'DRINK',
  },
  {
    itemPrices: [42],
    isAlcoholic: false,
    available: true,
    itemId: '20',
    itemName: 'Miniature Seaweed & Fingerling Lamb Spread ',
    itemOptions: ['Portion'],
    venueId: '1',
    category: 'Meat and Poultry',
    description: 'With cases of mining equipment, wasted and absorbed by time itself',
    type: 'DRINK',
  },
  {
    itemPrices: [3.25],
    isAlcoholic: false,
    available: true,
    itemId: '3',
    itemName: 'Ice Cream',
    itemOptions: ['Serving'],
    venueId: '1',
    category: 'Desserts',
    description: 'Frozen cream with a choice of flavours',
    type: 'FOOD',
  },
  {
    itemPrices: [16.99],
    isAlcoholic: false,
    available: true,
    itemId: '4',
    itemName: 'Iron Fennel & Lemon Candy',
    itemOptions: ['Serving'],
    venueId: '1',
    category: 'Desserts',
    description: 'Eat it to fuel strength',
    type: 'FOOD',
  },
  {
    itemPrices: [7.65],
    isAlcoholic: false,
    available: true,
    itemId: '5',
    itemName: 'Sheltered Chorizo & Market Shell Bean Panini',
    itemOptions: ['Serving'],
    venueId: '1',
    category: 'Sandwiches',
    description: 'Simply swallow the pain and deal with it later',
    type: 'FOOD',
  },
  {
    itemPrices: [1.58],
    isAlcoholic: false,
    available: false,
    itemId: '6',
    itemName: 'Vimto',
    itemOptions: ['Glass'],
    venueId: '1',
    category: 'Soft Drinks',
    description: 'Anagram of vitmo',
    type: 'DRINK',
  },
  {
    itemPrices: [1.65],
    isAlcoholic: false,
    available: true,
    itemId: '7',
    itemName: 'Irn-bru',
    itemOptions: ['Glass'],
    venueId: '1',
    category: 'Soft Drinks',
    description: 'Made in Scotland from girders',
    type: 'DRINK',
  },
  {
    itemPrices: [2.5, 5],
    isAlcoholic: true,
    available: true,
    itemId: '8',
    itemName: 'Melon Temper',
    itemOptions: ['Single', 'Double'],
    venueId: '1',
    category: 'Spirits',
    description: 'Leaves you hazy, confused and weak at the knees',
    type: 'DRINK',
  },
  {
    itemPrices: [6.5, 3.55],
    isAlcoholic: true,
    available: true,
    itemId: '9',
    itemName: 'Oaken Ticker',
    itemOptions: ['Double', 'Single'],
    venueId: '1',
    category: 'Spirits',
    description: 'Cold shivers shoot through your body ',
    type: 'DRINK',
  },
];
