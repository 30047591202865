const ratingsFilters = [
  {
    label: 'Newest',
    value: '',
    icon: null,
  },
  {
    label: 'Great',
    value: 5,
    icon: '😍',
  },
  {
    label: 'Good',
    value: 4,
    icon: '🙂',
  },
  {
    label: 'Okay',
    value: 3,
    icon: '😐',
  },
  {
    label: 'Bad',
    value: 2,
    icon: '😕',
  },
  {
    label: 'Terrible',
    value: 1,
    icon: '😫',
  },
];

export default ratingsFilters;
