export default [
  {
    isAlcoholic: false,
    available: true,
    venueId: '1',
    ingredientId: '1',
    ingredientName: 'Cola',
    type: 'DRINK',
  },
  {
    isAlcoholic: false,
    available: true,
    venueId: '1',
    ingredientId: '10',
    ingredientName: 'Vanilla',
    type: 'FOOD',
  },
  {
    isAlcoholic: false,
    available: true,
    venueId: '1',
    ingredientId: '11',
    ingredientName: 'Strawberry',
    type: 'FOOD',
  },
  {
    isAlcoholic: false,
    available: true,
    venueId: '1',
    ingredientId: '12',
    ingredientName: 'Banana',
    type: 'FOOD',
  },
  {
    isAlcoholic: false,
    available: true,
    venueId: '1',
    ingredientId: '13',
    ingredientName: 'Kelpie Mash',
    type: 'FOOD',
  },
  {
    isAlcoholic: false,
    available: true,
    venueId: '1',
    ingredientId: '14',
    ingredientName: 'Boiled Cabbage',
    type: 'FOOD',
  },
  {
    isAlcoholic: false,
    available: true,
    venueId: '1',
    ingredientId: '2',
    ingredientName: 'Orange Juice',
    type: 'DRINK',
  },
  {
    isAlcoholic: false,
    available: true,
    venueId: '1',
    ingredientId: '3',
    ingredientName: 'Lemonade',
    type: 'DRINK',
  },
  {
    isAlcoholic: false,
    available: true,
    venueId: '1',
    ingredientId: '4',
    ingredientName: 'Sparkling Water',
    type: 'DRINK',
  },
  {
    isAlcoholic: true,
    available: false,
    venueId: '1',
    ingredientId: '5',
    ingredientName: 'Ginger Ale',
    type: 'DRINK',
  },
  {
    isAlcoholic: false,
    available: true,
    venueId: '1',
    ingredientId: '6',
    ingredientName: 'Skinny Fries',
    type: 'FOOD',
  },
  {
    isAlcoholic: false,
    available: true,
    venueId: '1',
    ingredientId: '7',
    ingredientName: 'Cheddar Mash',
    type: 'FOOD',
  },
  {
    isAlcoholic: false,
    available: true,
    venueId: '1',
    ingredientId: '8',
    ingredientName: 'Jacket Potato',
    type: 'FOOD',
  },
  {
    isAlcoholic: false,
    available: true,
    venueId: '1',
    ingredientId: '9',
    ingredientName: 'Chocolate',
    type: 'FOOD',
  },
];
