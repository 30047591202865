import { createSlice } from '@reduxjs/toolkit';
import {
  asyncInitialState as initialState,
  createAsyncAction,
  createAsyncReducerHandlers,
} from '../utils/async';
import {
  fetchQrcodes as fetchQrcodesApi,
  fetchAvailableQrcodes as fetchAvailableQrcodesApi,
  updateQrcode as updateQrcodeApi,
  deleteQrcode as deleteQrcodeApi,
  uploadBatchQrCodes as uploadBatchQrCodesApi,
} from '../../api/qrcodes';

export const fetchQrcodes = createAsyncAction('fetch-qrcodes', fetchQrcodesApi);
export const fetchAvailableQrcodes = createAsyncAction(
  'fetch-available-qrcodes',
  fetchAvailableQrcodesApi,
);
export const updateQrcode = createAsyncAction('update-qrcode', updateQrcodeApi);
export const deleteQrcode = createAsyncAction('delete-qrcode', deleteQrcodeApi);
export const uploadBatchQrCodes = createAsyncAction('upload-qrcodes', uploadBatchQrCodesApi);

const qrcodesSlice = createSlice({
  name: 'qrcodes',
  initialState,
  reducers: {
    clearQrCodes(state) {
      delete state.data;
    },
  },
  extraReducers: createAsyncReducerHandlers(fetchQrcodes),
});

const availableQrcodesSlice = createSlice({
  name: 'availableQrcodes',
  initialState,
  reducers: {
    clearAvailableQrCodes(state) {
      delete state.data;
    },
  },
  extraReducers: createAsyncReducerHandlers(fetchAvailableQrcodes),
});

const qrcodeSlice = createSlice({
  name: 'qrcode',
  initialState,
  reducers: {
    clearQrCode(state) {
      delete state.data;
    },
  },
  extraReducers: createAsyncReducerHandlers(updateQrcode),
});
export const { clearQrCodes } = qrcodesSlice.actions;
export const { clearAvailableQrCodes } = availableQrcodesSlice.actions;
export const { clearQrCode } = qrcodeSlice.actions;

export const { reducer: availableQrcodes } = availableQrcodesSlice;
export const { reducer: qrcodes } = qrcodesSlice;
export const { reducer: qrcode } = qrcodeSlice;
