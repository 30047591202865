import React from 'react';
import { Box } from '@mui/material';
import { withStyles } from 'tss-react/mui';

const MenuGroupColor = ({ color }) => {
  const CustomBox = withStyles(Box, (theme) => ({
    root: {
      backgroundColor: color,
      height: '100%',
      width: '30px',
      flexShrink: 0,
      marginLeft: theme.spacing(2),
      position: 'absolute',
      right: 0,
    },
  }));

  return <CustomBox />;
};

export default MenuGroupColor;
