import React from 'react';
import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';
import Page from '../../components/Page';
import PageHeader from '../../components/PageHeader';
import TabContainer from '../../components/TabContainer';
import InvoiceHistory from '../../components/InvoiceHistory';
import BackArrow from '../../components/BackArrow';
// import EmailInvoices from '../../components/EmailInvoices';

const useStyles = makeStyles((theme) => ({
  title: {
    ...theme.customFonts.largeBold,
    marginBottom: '16px',
  },
}));

const Invoices = () => {
  const classes = useStyles();

  return (
    <>
      <PageHeader>
        <BackArrow text="Billing" path="/billing" />
        <Typography className={classes.title}>Invoices</Typography>
      </PageHeader>
      <Page>
        <TabContainer titles={['History']} defaultTab="History" path="/invoices/">
          {/* <TabContainer titles={['History', 'Email invoices']} defaultTab="History" path="/invoices/"> */}
          <InvoiceHistory />
          {/* <EmailInvoices /> */}
        </TabContainer>
      </Page>
    </>
  );
};

export default Invoices;
