import React from 'react';
import { makeStyles } from '@mui/styles';
import { Button, Card, CardActions, CardContent, Divider, Typography } from '@mui/material';
import useEndpoint from '../../hooks/useEndpoint';
import { getSmartUpsellGroupInitiatingItemsState } from '../../store/upsellGroups/selectors';
import { fetchSmartUpsellGroupInitiatingItems } from '../../store/upsellGroups';
import ButtonLink from '../ButtonLink';

const useStyles = makeStyles((theme) => ({
  title: {
    ...theme.customFonts.largeBold,
    marginBottom: '24px',
  },
  img: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingRight: '1rem',
    '& .material-symbols-outlined': {
      fontSize: '48px',
      color: theme.customPalette.greyDark,
    },
    [theme.breakpoints.down('lg')]: {
      marginBottom: '0.5rem',
    },
  },
  card: {
    borderRadius: '8px',
    marginBottom: '64px',
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    '& img': {
      marginRight: '12px',
    },
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
    [theme.breakpoints.down('lg')]: {
      '& img': {
        display: 'none',
      },
    },
  },
  cardContentHidden: {
    justifyContent: 'center',
    alignContent: 'center',
    flexDirection: 'column',
    ...theme.customFonts.medium,
    '& div': {
      justifyContent: 'center',
      textAlign: 'center',
    },
    '& div .material-symbols-outlined': {
      color: theme.customPalette.cheeryBlue,
    },
  },
  hiddenSubtitle: {
    ...theme.customFonts.medium,
    color: theme.customPalette.greyDark,
  },
  cardTitle: {
    ...theme.customFonts.mediumBold,
    color: theme.customPalette.greyDarker,
    marginBottom: '4px',
  },
  cardDescription: {
    ...theme.customFonts.small,
    color: theme.customPalette.greyDarker,
  },
  itemsTitle: {
    ...theme.customFonts.smallBold,
  },
  items: {
    maxWidth: '75%',
    [theme.breakpoints.up('md')]: {
      maxWidth: '90%',
    },
  },
  cardActions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    ...theme.customFonts.medium,
    color: theme.customPalette.greyDarker,
    backgroundColor: '#fff',
  },
  cardButton: {
    borderRadius: '8px',
    borderColor: theme.customPalette.grey,
  },

  createUpsellButton: {
    borderRadius: '8px',
    marginBottom: '8px',
  },
  itemsList: {
    maxWidth: '100%',
  },
}));

const SmartUpsellGroups = ({ handleEdit }) => {
  const classes = useStyles();
  const [showItems, setShowItems] = React.useState(false);

  const { data: smartUpsellGroupInitiatingItems } = useEndpoint(
    getSmartUpsellGroupInitiatingItemsState,
    fetchSmartUpsellGroupInitiatingItems({ upsellGroupId: '2' }),
  );

  return (
    <Card className={classes.card}>
      {process.env.REACT_APP_HIDE_SMART_UPSELLS === 'true' ? (
        <CardContent className={`${classes.cardContent} ${classes.cardContentHidden}`}>
          <div className={classes.img}>
            <span className="material-symbols-outlined">tips_and_updates</span>
          </div>
          <div>
            Upsells are about to get a lot <strong>smarter...</strong>
          </div>
          <div className={classes.hiddenSubtitle}>
            Let customer data do the thinking. Coming soon.
          </div>
        </CardContent>
      ) : (
        <>
          <CardContent className={classes.cardContent}>
            <div className={classes.img}>
              <span className="material-symbols-outlined">tips_and_updates</span>
            </div>
            <div>
              <Typography className={classes.cardTitle}>Smart upsells</Typography>

              <Typography className={classes.cardDescription}>
                Smart upsells uses customer buying trends to auto suggest desirable items based on
                whats in their cart
              </Typography>
            </div>
          </CardContent>
          <Divider style={{ background: '#F0F4F8' }} />
          <CardActions className={classes.cardActions}>
            {smartUpsellGroupInitiatingItems && smartUpsellGroupInitiatingItems.length > 0 && (
              <div className={classes.items}>
                <Typography className={`${classes.cardTitle} ${classes.itemsTitle}`}>
                  <b>Applies to {smartUpsellGroupInitiatingItems.length} items</b>
                </Typography>
                <Typography
                  noWrap={!showItems}
                  className={`${classes.cardDescription} ${classes.itemsList}`}
                >
                  {smartUpsellGroupInitiatingItems
                    ?.map((item) => (
                      <span
                        key={item.itemName}
                        style={{ textDecoration: item.available ? 'none' : 'line-through' }}
                      >
                        {item.itemName}
                      </span>
                    ))
                    .reduce((prev, curr) => [prev, ', ', curr])}
                </Typography>
                <ButtonLink handleOnClick={() => setShowItems(!showItems)}>
                  {showItems ? 'Hide' : 'See All'}
                </ButtonLink>
              </div>
            )}
            <Button className={classes.cardButton} variant="outlined" onClick={() => handleEdit()}>
              {smartUpsellGroupInitiatingItems && smartUpsellGroupInitiatingItems.length > 0
                ? 'Edit'
                : 'Add to items'}
            </Button>
          </CardActions>
        </>
      )}
    </Card>
  );
};

export default SmartUpsellGroups;
