import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { TextField, Switch } from 'formik-mui';
import {
  Button,
  DialogContent,
  DialogActions,
  FormControlLabel,
  Box,
  MenuItem,
} from '@mui/material';

import ColorPalettePicker from '../ColorPalettePicker';

const MenuGroupForm = ({ formAction, initialValues, onSubmit, onCancel, venuePriorities }) => {
  const { enabled, priorities } = venuePriorities;
  const submitButtonLabel = formAction === 'create' ? 'Create' : 'Update';

  const MenuGroupSchema = Yup.object().shape({
    groupName: Yup.string().min(3, 'Too Short!').required('Please enter a name'),
    priorityId: Yup.string().optional().nullable(),
    groupColor: Yup.string().optional(),
  });

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={MenuGroupSchema}>
      {({ values, dirty }) => (
        <Form>
          <DialogContent>
            <FormControlLabel
              control={
                <Field component={Switch} color="primary" type="checkbox" name="available" />
              }
              label="Available"
            />
            <Field
              component={TextField}
              name="groupName"
              label="Section Name"
              variant="outlined"
              fullWidth
              required
              margin="normal"
            />
            <Field
              component={TextField}
              name="description"
              label="Description"
              variant="outlined"
              fullWidth
              margin="normal"
            />
            {enabled && (
              <Field
                component={TextField}
                select
                name="priorityId"
                label="Courses"
                variant="outlined"
                fullWidth
                required
                margin="normal"
              >
                {priorities.map(({ name, priorityId }) => (
                  <MenuItem value={priorityId} key={priorityId}>
                    {name}
                  </MenuItem>
                ))}
                <MenuItem value={null}>None (other)</MenuItem>
              </Field>
            )}

            <Box mt={2}>
              <ColorPalettePicker name="groupColor" value={values.groupColor} />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={onCancel}>Cancel</Button>
            <Button type="submit" variant="contained" color="primary" disabled={!dirty}>
              {submitButtonLabel}
            </Button>
          </DialogActions>
        </Form>
      )}
    </Formik>
  );
};

MenuGroupForm.defaultProps = {
  formAction: 'update',
};

MenuGroupForm.propTypes = {
  formAction: PropTypes.oneOf(['create', 'update']),
  // TODO: We should have interfaces or classes for this rule
  // eslint-disable-next-line react/forbid-prop-types
  initialValues: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default MenuGroupForm;
