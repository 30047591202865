import React from 'react';

import { useParams } from 'react-router';
import { Box, Typography } from '@mui/material';

import withVenue from '../../hoc/withVenue';
import TabContainer from '../../components/TabContainer';
import PageHeader from '../../components/PageHeader';
import MenuItems from '../MenuItems';
import Ingredients from '../Ingredients';
import ItemPhotos from '../ItemPhotos';
import Modifiers from '../Modifiers';
import Categories from '../Categories';
import Options from '../Options';
import NewItemButton from './NewItemButton';
import NewIngredientButton from './NewIngredientButton';
import NewModifierButton from './NewModifierButton';
import NewSizeButton from './NewSizeButton';
import NewCategoryButton from './NewCategoryButton';

const ItemTabs = () => {
  const { tabname } = useParams();

  return (
    <PageHeader fullWidth>
      <Box sx={{ display: 'flex' }}>
        <Typography style={{ flexGrow: 1 }} variant="h2" component="h1">
          Items
        </Typography>
        {tabname === 'menu-items' && <NewItemButton />}
        {tabname === 'ingredients' && <NewIngredientButton />}
        {tabname === 'modifiers' && <NewModifierButton />}
        {tabname === 'sizes' && <NewSizeButton />}
        {tabname === 'categories' && <NewCategoryButton />}
      </Box>
      <TabContainer
        titles={['Menu items', 'Ingredients', 'Modifiers', 'Sizes', 'Photos', 'Categories']}
        defaultTab={tabname}
        path="/items/"
      >
        <MenuItems />
        <Ingredients />
        <Modifiers />
        <Options />
        <ItemPhotos />
        <Categories />
      </TabContainer>
    </PageHeader>
  );
};

export default withVenue(ItemTabs);
