import React, { useState } from 'react';
import { Box, Button, Card, CardContent, Divider, Link, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { sentenceCase } from 'change-case';
import CostBreakdown from '../CostBreakdown';
import CustomWarningBanner from '../CustomWarningBanner';

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: '8px',
  },
  proHeader: {
    height: '126px',
    background: theme.customPalette.subscriptionPurple,
  },
  standardHeader: {
    height: '126px',
    background: theme.customPalette.subscriptionPink,
  },
  subscriptionType: {
    ...theme.customFonts.largeBold,
    color: theme.customPalette.white,
    marginLeft: '32px',
    paddingTop: '50px',
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  directDebitTitle: {
    ...theme.customFonts.mediumBold,
    color: theme.customPalette.greyDarker,
    marginBottom: '12px',
  },
  pendingWarning: {
    ...theme.customFonts.medium,
    color: theme.customPalette.greyDark,
  },
  sortCode: {
    ...theme.customFonts.small,
    color: theme.customPalette.greyDarker,
    marginBottom: '12px',
  },
  paymentSchedule: {
    ...theme.customFonts.small,
    color: theme.customPalette.greyDarker,
    marginBottom: '12px',
  },
  updateUrl: {
    ...theme.customFonts.small,
    color: theme.customPalette.trueBlue,
    textDecoration: 'underline',
  },
  divider: {
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      marginTop: '12px',
      marginBottom: '12px',
      borderStyle: 'dashed',
      borderWidth: '1px',
      borderColor: '#BCCCDC',
      backgroundClip: 'content-box',
    },
    display: 'none',
  },
  costBreakdownBtn: {
    '& .MuiButton-label': {
      ...theme.customFonts.small,
      color: theme.customPalette.trueBlue,
      textDecoration: 'underline',
    },
    padding: 0,
  },
  totalSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    [theme.breakpoints.up('sm')]: {
      alignItems: 'flex-end',
    },
  },
  inactiveWarning: {
    ...theme.customFonts.small,
    color: theme.customPalette.greyDarker,
  },
  totalAmount: {
    ...theme.customFonts.mediumBold,
  },
  monthlyText: {
    ...theme.customFonts.small,
  },
}));

const SubscriptionCard = ({ subscriptionData, directDebitUrl }) => {
  const classes = useStyles();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const {
    type: subscriptionType,
    monthlyFee,
    fixedCosts,
    variableCosts,
    hardware,
    total,
    nextPaymentDate,
    discount,
    subtotal,
  } = subscriptionData?.subscription || {};
  const { mandateStatus, sortCode, paymentMethodId } = subscriptionData?.customer || {};

  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const nextPayment = new Date(nextPaymentDate).toLocaleDateString('en-GB', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  });

  return (
    <Card>
      <Box className={subscriptionType === 'PRO' ? classes.proHeader : classes.standardHeader}>
        {!!subscriptionType && (
          <Typography className={classes.subscriptionType}>
            {sentenceCase(subscriptionType)}
          </Typography>
        )}
      </Box>
      <CardContent>
        <Box className={classes.content}>
          <Box>
            <Typography className={classes.directDebitTitle}>Direct debit</Typography>
            {mandateStatus === 'inactive' && (
              <CustomWarningBanner
                title="Something went wrong setting up your card details"
                titleFont="smallBold"
                titleColor="greyDarker"
                backgroundColor="warningYellow"
                iconColor="#FFB800"
              >
                <Typography className={classes.inactiveWarning}>
                  Please reach out to our customer success team and we will do our best to resolve
                  the problem.
                </Typography>
              </CustomWarningBanner>
            )}
            {!paymentMethodId && mandateStatus !== 'inactive' && (
              <Button
                variant="contained"
                color="primary"
                component={Link}
                href={directDebitUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                Add payment details
              </Button>
            )}
            {mandateStatus === 'pending' && (
              <Typography className={classes.pendingWarning}>
                Hold tight, we’re registering your payment details...
              </Typography>
            )}
            {mandateStatus === 'active' && !!subscriptionData?.subscription && (
              <Typography className={classes.paymentSchedule}>
                Your next payment will be collected on <b>{nextPayment}</b>
              </Typography>
            )}
            {!!sortCode && mandateStatus === 'active' && (
              <Typography className={classes.sortCode}>
                Sort code: <b>** ** {sortCode?.slice(4)}</b>
              </Typography>
            )}
            {!!directDebitUrl && mandateStatus === 'active' && (
              <Link className={classes.updateUrl} href={directDebitUrl} target="_blank">
                Update payment method
              </Link>
            )}
          </Box>
          <Divider className={classes.divider} />
          {!!total && mandateStatus === 'active' && (
            <Box className={classes.totalSection}>
              <Typography>
                <span className={classes.totalAmount}>£{total}</span>{' '}
                <span className={classes.monthlyText}>paid monthly</span>
              </Typography>
              <Button className={classes.costBreakdownBtn} onClick={handleOpenDialog}>
                Cost breakdown
              </Button>
              {mandateStatus === 'active' && (
                <CostBreakdown
                  isDialogOpen={isDialogOpen}
                  handleCloseDialog={handleCloseDialog}
                  fixedCosts={fixedCosts}
                  hardware={hardware}
                  monthlyFee={monthlyFee}
                  variableCosts={variableCosts}
                  total={total}
                  discount={discount}
                  subtotal={subtotal}
                  subscriptionType={subscriptionType}
                />
              )}
            </Box>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

export default SubscriptionCard;
