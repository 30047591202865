import React, { useMemo } from 'react';
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { getPromoCodes, getPromoCodesState } from '../../store/promoCodes/selectors';
import formatPromoCode from '../../pages/PromoCodes/formatPromoCode';
import TableCellStatus, { tableCellStatus } from '../TableCellStatus';
import TablePaginationActions from '../TablePaginationActions';
import TableSkeletonLoader from '../TableSkeletonLoader';
import TableButton from '../TableButton';

const PromoCodeStatus = {
  EXPIRED: <TableCellStatus status={tableCellStatus.IDLE} statusText="Expired" />,
  USED: <TableCellStatus status={tableCellStatus.STALL} statusText="All used" />,
  CANCELLED: <TableCellStatus status={tableCellStatus.FAIL} statusText="Cancelled" />,
  ACTIVE: <TableCellStatus status={tableCellStatus.SUCCESS} statusText="Active" />,
};

const PromoCodesTable = ({ pagination, handleEdit, handleDelete }) => {
  const { page, rowsPerPage, handleChangePage } = pagination;
  const promoCodes = useSelector(getPromoCodes);
  const { loading } = useSelector(getPromoCodesState);

  const displayCodes = promoCodes?.docs || promoCodes;
  const numberOfPromoCodes = promoCodes.total;
  const hasCodes = displayCodes.length > 0;

  const formattedPromoCodes = useMemo(() => displayCodes?.map(formatPromoCode), [displayCodes]);

  if (!promoCodes) return null;

  return (
    <>
      <TableContainer component={Paper}>
        <Table aria-label="Promo Codes" sx={{ minWidth: '650px' }}>
          <TableHead>
            <TableRow>
              <TableCell>Promo code</TableCell>
              <TableCell align="right" style={{ width: '8rem' }}>
                Status
              </TableCell>
              <TableCell align="right">Type</TableCell>
              <TableCell align="right">Label</TableCell>
              <TableCell align="right">Expiry</TableCell>
              <TableCell align="right">Usage</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          {loading && <TableSkeletonLoader rows={10} cells={8} />}
          {!loading && hasCodes && (
            <>
              <TableBody>
                {formattedPromoCodes
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map(({ promoCode, type, label, expiry, usage, status }, index) => (
                    <TableRow key={promoCode}>
                      <TableCell component="th" scope="row">
                        <TableButton
                          onClick={() => {
                            handleEdit(promoCodes.docs[index], promoCode);
                          }}
                        >
                          {promoCode}
                        </TableButton>
                      </TableCell>
                      <TableCell align="right">{PromoCodeStatus[status]}</TableCell>
                      <TableCell align="right">{type}</TableCell>
                      <TableCell align="right">{label}</TableCell>
                      <TableCell align="right">{expiry}</TableCell>
                      <TableCell align="right">{usage}</TableCell>
                      <TableCell align="right">
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            handleDelete(promoCode);
                          }}
                        >
                          Delete
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </>
          )}
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[]}
                count={numberOfPromoCodes}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  );
};

export default PromoCodesTable;
