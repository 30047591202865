import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

const formatToVenueTime = (time) => time && time.format('h:mmA');

const useStyles = makeStyles((theme) => ({
  header: {
    marginBottom: '10px',
  },
  innerText: {
    ...theme.customFonts.small,
  },
}));

const WeekTimeRangeDay = (props) => {
  const {
    validTime: { day, isOpen, time, isOpen24Hours },
    className,
  } = props;
  const classes = useStyles();
  const showOpeningAndClosingTime = isOpen && !isOpen24Hours;

  const firstLettersOfDay = (weekday) => weekday.slice(0, 3);

  return (
    <Grid container wrap="wrap" justifyContent="space-between" className={className}>
      <Grid item xs={4}>
        <Typography className={classes.innerText} variant="body2">
          {firstLettersOfDay(day)}
        </Typography>
      </Grid>
      {showOpeningAndClosingTime ? (
        <>
          <Grid item xs={6}>
            <Typography className={classes.innerText} variant="body2" align="right">
              {formatToVenueTime(time?.open)} -{formatToVenueTime(time?.close)}
            </Typography>
          </Grid>
        </>
      ) : (
        <Grid item xs={6}>
          {isOpen24Hours && (
            <Typography className={classes.innerText} variant="body2" align="right">
              Open 24 hours
            </Typography>
          )}
          {!isOpen && (
            <Typography className={classes.innerText} variant="body2" align="right">
              Closed
            </Typography>
          )}
        </Grid>
      )}
    </Grid>
  );
};

WeekTimeRangeDay.propTypes = {
  // should be moment type
  // eslint-disable-next-line react/forbid-prop-types
  validTime: PropTypes.object.isRequired,
  day: PropTypes.string,
  isOpen: PropTypes.bool,
  time: PropTypes.string,
  isOpen24Hours: PropTypes.bool,
};

WeekTimeRangeDay.defaultProps = {
  day: 'Unknown Day',
  isOpen: false,
  time: '',
  isOpen24Hours: false,
};

export default WeekTimeRangeDay;
