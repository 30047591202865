import { deleteBare, get, getBare, patchBare } from './httpRequest';

export const fetchDevices = async () => {
  const response = await get(`/devices`);
  return response.data;
};

export const fetchAvailableDevices = async () => {
  const response = await getBare(`devices`);
  return response.data;
};

export const fetchAllAvailableDevicesApi = async () => {
  const response = await getBare(`devices/all`);
  return response.data;
};

export const fetchAvailableDeviceCapabilities = async (type) => {
  const response = await getBare(`deviceCapabilities?type=${type}`);
  return response.data;
};

export const updateDevice = async (values) => {
  const venueId = localStorage.getItem('venue_id');
  const { deviceId, destination, tables, capabilities } = values;
  const response = await patchBare(`devices/${deviceId}`, {
    data: { destination, venueId, tables, capabilities },
  });

  return response.data;
};

export const deleteDevice = async (values) => {
  const { deviceId } = values;
  await deleteBare(`devices/${deviceId}`);
};

export const fetchDevice = async (values) => {
  const { deviceId } = values;
  const response = await get(`/devices/${deviceId}`);
  return response.data;
};
