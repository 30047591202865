import React from 'react';
import { Divider, ListItemButton, ListItemText, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { ChevronRight } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import useRoles from '../../hooks/useRoles';

const useStyles = makeStyles((theme) => ({
  listItem: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: '16px',
    paddingBottom: '16px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  title: {
    alignItems: 'center',
  },
  chevron: {
    color: theme.customPalette.trueBlue,
  },
}));

const SettingsListItem = ({
  title,
  subtitle,
  divider,
  to,
  children,
  typeDeliveryOnly,
  typeMultivendor,
}) => {
  const classes = useStyles();
  const { isAdmin } = useRoles();
  const shouldShowSetting = (!typeMultivendor && !typeDeliveryOnly) || isAdmin();

  return (
    shouldShowSetting && (
      <>
        <ListItemButton className={classes.listItem} component={Link} to={to}>
          <div className={classes.title}>
            <ListItemText primary={title} />
            <Typography variant="subtitle1" component="p">
              {subtitle}
            </Typography>
            {children}
          </div>
          <ChevronRight className={classes.chevron} />
        </ListItemButton>
        {divider && <Divider variant="middle" />}
      </>
    )
  );
};

export default SettingsListItem;
