/* eslint-disable func-names */
import * as Yup from 'yup';

const yupMethods = () => {
  Yup.addMethod(Yup.string, 'notEmpty', function () {
    return this.test('test-not-empty-string', 'Should not be an empty string', (value) =>
      value ? value.trim() !== '' : true,
    );
  });

  Yup.addMethod(Yup.number, 'lessThanItems', function () {
    return this.test(
      'test-not-longer-than-modifierItems',
      'Should not exceed the number of items (and their multiples)',
      function (value) {
        const cumulativeMax = this.parent.modifierItems.reduce(
          (acc, item) => acc + item.multiMax,
          0,
        );
        return cumulativeMax >= value;
      },
    );
  });

  Yup.addMethod(Yup.number, 'lessThanMaxSelections', function () {
    return this.test(
      "doesn't exceed max",
      'Maximum multiples cannot exceed the maximum number of selections',
      function (value) {
        return this.options.from[1].value.maxSelections >= value;
      },
    );
  });
};
export default yupMethods;
