import React, { useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { Form as FormikForm, FieldArray, Formik } from 'formik';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { fromOpeningTimesToFormValues, toOpeningTimesFromValues } from './mappers';
import OpeningTimeField from './OpeningTimeField';
import { useNotifications } from '../../../shared/contexts/Notifications/useNotifications';
import { getErrorMessage } from '../../../shared/utils/errors';
import OpeningTimesSchema from '../OpeningTimesSchema';
import CustomDialog from '../../CustomDialog';

const useStyles = makeStyles(() => ({
  dialogContent: {
    height: '400px',
    overflow: 'auto',
  },
}));

const EditWeekTimeRangeDialog = ({
  open,
  onClose,
  onEditWeekTimeRange,
  validTimes,
  subjectText = '',
}) => {
  const [isConfirmDialogOpen, setisConfirmDialogOpen] = useState(false);
  const { showErrorNotification } = useNotifications();
  const classes = useStyles();
  const formref = useRef();
  const finalSubjectText = subjectText.toLowerCase() || 'valid times';

  const initialValues = useMemo(() => {
    const init = fromOpeningTimesToFormValues(validTimes);
    return init;
  }, [validTimes]);

  const onSubmit = (values) => {
    try {
      const localOpenings = toOpeningTimesFromValues(values);
      onEditWeekTimeRange(localOpenings);
      setisConfirmDialogOpen(false);
    } catch (error) {
      showErrorNotification(getErrorMessage(error));
    }
  };

  const close = () => {
    setisConfirmDialogOpen(false);
    onClose();
  };

  const tryClose = () => {
    if (formref.current.dirty) {
      setisConfirmDialogOpen(true);
    } else {
      close();
    }
  };

  const save = () => {
    setisConfirmDialogOpen(false);
    formref.current.handleSubmit();
    onClose();
  };
  return (
    <>
      <CustomDialog
        title={`Edit ${finalSubjectText}`}
        isDialogOpen={open}
        handleCloseDialog={tryClose}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={OpeningTimesSchema}
          validateOnMount
          onSubmit={onSubmit}
          innerRef={formref}
        >
          {({ isValid, values }) => (
            <FormikForm noValidate>
              <DialogContent className={classes.dialogContent}>
                <FieldArray
                  name="openings"
                  render={() =>
                    values.openings.map((opening, index) => (
                      <OpeningTimeField
                        key={`${opening.day}`}
                        name={`openings.${index}`}
                        opening={opening}
                      />
                    ))
                  }
                />
              </DialogContent>
              <DialogActions>
                <Button variant="text" onClick={tryClose}>
                  Cancel
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  disabled={!isValid}
                  data-test-id="save-opening-hours"
                >
                  Save
                </Button>
              </DialogActions>
            </FormikForm>
          )}
        </Formik>
      </CustomDialog>
      <CustomDialog
        title="Are you sure?"
        isDialogOpen={isConfirmDialogOpen}
        handleCloseDialog={close}
      >
        <DialogContent dividers>
          <Typography gutterBottom component="div">
            Do you want to save your changes?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="text" autoFocus onClick={close}>
            Cancel
          </Button>
          <Button color="primary" variant="contained" onClick={save}>
            Save
          </Button>
        </DialogActions>
      </CustomDialog>
    </>
  );
};

EditWeekTimeRangeDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onEditWeekTimeRange: PropTypes.func.isRequired,
  validTimes: PropTypes.arrayOf(PropTypes.string).isRequired,
};
export default EditWeekTimeRangeDialog;
