import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@mui/material';
import { FiList } from 'react-icons/fi';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import withVenue from '../../hoc/withVenue';
import shouldLoad from '../../shared/utils/shouldLoad';
import { getTerminalDevicesState } from '../../store/terminals/selectors';
import {
  clearTerminalDevices,
  fetchTerminalDevices,
  deleteTerminalDevice,
} from '../../store/terminals';
import { useNotifications } from '../../shared/contexts/Notifications/useNotifications';
import { getErrorMessage } from '../../shared/utils/errors';
import useRoles from '../../hooks/useRoles';

const TerminalsTable = () => {
  const { showErrorNotification, showSuccessNotification } = useNotifications();
  const dispatch = useDispatch();
  const { isAdmin } = useRoles();
  const terminalsState = useSelector(getTerminalDevicesState);
  const { loading: terminalsLoading, data: terminals } = terminalsState;

  useEffect(() => {
    if (shouldLoad(terminalsState)) dispatch(fetchTerminalDevices());
  }, [dispatch, terminals, terminalsState]);

  if (!terminals) return null;

  const removeCurrentTerminalDevice = async (terminalId) => {
    try {
      await dispatch(deleteTerminalDevice(terminalId));
      dispatch(fetchTerminalDevices());
      showSuccessNotification('Terminal device has been removed successfully');
    } catch (err) {
      dispatch(fetchTerminalDevices());
      showErrorNotification(getErrorMessage(err));
    }
  };

  return (
    !terminalsLoading && (
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Terminal Id</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Provider</TableCell>
              <TableCell>Currency</TableCell>
              <TableCell>Status</TableCell>
              {isAdmin() && <TableCell> </TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {terminals.map(({ readerId, name, currency, status, provider }) => (
              <TableRow key={readerId}>
                <TableCell>{readerId}</TableCell>
                <TableCell>{name}</TableCell>
                <TableCell>{provider}</TableCell>
                <TableCell>{currency}</TableCell>
                <TableCell>{status}</TableCell>
                {isAdmin() && (
                  <TableCell align="right">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        removeCurrentTerminalDevice(readerId);
                      }}
                      startIcon={<FiList />}
                    >
                      Remove
                    </Button>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    )
  );
};

export default withVenue(TerminalsTable, null, [clearTerminalDevices]);
