import { Formik } from 'formik';
import React, { useContext } from 'react';
import isEmpty from 'lodash.isempty';

import SignupContext from '../SignupContext';
import StepQuestion from '../StepQuestion';
import StepButton from '../StepButton';
import { signupBusinessTypes, signupFields } from '../signupFields';
import validationSchema from './validationSchema';
import StepRadio from '../StepRadio';
import StepRadioGroup from '../StepRadioGroup';

const StepBusinessDetailsOne = () => {
  const { formValues } = useContext(SignupContext);
  const initialValues = {
    [signupFields.businessType]: formValues.businessType,
  };
  return (
    <>
      <StepQuestion question="What is the business structure?" />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnMount
        enableReinitialize
      >
        {({ errors, isValid }) => {
          const isFormValid = isValid && isEmpty(errors);

          return (
            <>
              <StepRadioGroup name={signupFields.businessType}>
                {signupBusinessTypes.map(({ value, label }) => (
                  <StepRadio value={value} label={label} key={value} />
                ))}
              </StepRadioGroup>

              <StepButton disabled={!isFormValid} />
            </>
          );
        }}
      </Formik>
    </>
  );
};

export default StepBusinessDetailsOne;
