import { Formik } from 'formik';
import React, { useContext } from 'react';
import isEmpty from 'lodash.isempty';

import SignupContext from '../SignupContext';
import StepQuestion from '../StepQuestion';
import StepButton from '../StepButton';
import { signupFields } from '../signupFields';
import validationSchema from './validationSchema';
import StepRadio from '../StepRadio';
import StepInput from '../StepInput';
import StepRadioGroup from '../StepRadioGroup';

const StepBusinessDetailsTwo = () => {
  const { formValues } = useContext(SignupContext);
  const initialValues = {
    [signupFields.isBusinessAddressSameAsAddress]: formValues.isBusinessAddressSameAsAddress,
    [signupFields.businessAddress]: formValues.businessAddress,
    [signupFields.businessCity]: formValues.businessCity,
    [signupFields.businessPostcode]: formValues.businessPostcode,
  };
  return (
    <>
      <StepQuestion question="Is your registered business address the same as your venue address?" />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnMount
        enableReinitialize
      >
        {({ values, errors, isValid }) => {
          const isFormValid = isValid && isEmpty(errors);
          const isBusinessAddressNeeded = values.isBusinessAddressSameAsAddress === 'no';

          return (
            <>
              <StepRadioGroup name={signupFields.isBusinessAddressSameAsAddress}>
                <StepRadio value="yes" label="Yes" />
                <StepRadio value="no" label="No" />
              </StepRadioGroup>
              {isBusinessAddressNeeded && (
                <>
                  <StepQuestion question="What is the registered business address?" />
                  <StepInput
                    name={signupFields.businessAddress}
                    placeholder="Building name or number"
                  />
                  <StepInput name={signupFields.businessCity} placeholder="City" />
                  <StepInput name={signupFields.businessPostcode} placeholder="Postcode" />
                </>
              )}

              <StepButton disabled={!isFormValid} />
            </>
          );
        }}
      </Formik>
    </>
  );
};

export default StepBusinessDetailsTwo;
