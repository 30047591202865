import React, { useState } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { ADJUSTMENT_TABLE_COLUMNS } from '../../../shared/constants/venueAdjustments';
import AdjustmentRow from './AdjustmentRow';

const useStyles = makeStyles((theme) => ({
  tableOverrides: {
    '& .MuiTableCell-head': {
      ...theme.customFonts.label,
      backgroundColor: theme.sessionsScorePalette.nonCompliantLightGrey,
    },
  },
  zeroResultsCell: {
    padding: theme.spacing(3),
    '& > p': {
      ...theme.customFonts.medium,
      textAlign: 'center',
    },
  },
  sortIcon: {
    width: 16,
    marginLeft: 2,
  },
  columnTitle: {
    ...theme.customFonts.label,
    textTransform: 'uppercase',
    whiteSpace: 'nowrap',
  },
  columnTitleWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
  },
}));

const AdjustmentsTable = ({ adjustments, isLoading }) => {
  const classes = useStyles();
  const [sortOrder, setSortOrder] = useState('asc');

  const handleSort = () => {
    const newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
    setSortOrder(newSortOrder);
  };

  const sortedAdjustments = adjustments.sort((a, b) => {
    const dateA = moment(a.startDate);
    const dateB = moment(b.startDate);
    if (sortOrder === 'asc') {
      return dateA - dateB;
    }
    return dateB - dateA;
  });

  return (
    <TableContainer sx={{ mt: 2 }}>
      <Table className={classes.tableOverrides} size="small">
        <TableHead className={classes.columnTitles}>
          <TableRow>
            {ADJUSTMENT_TABLE_COLUMNS.map((column) => (
              <TableCell
                key={column}
                align={
                  ['AMOUNT', 'LEFT TO PAY', 'START DATE', 'END DATE'].includes(column)
                    ? 'right'
                    : 'inherit'
                }
                onClick={column === 'START DATE' ? handleSort : undefined}
                style={{
                  cursor: column === 'START DATE' ? 'pointer' : 'default',
                }}
                className={classes.columnTitles}
              >
                <Box className={classes.columnTitleWrapper}>
                  <Typography className={classes.columnTitle}>{column}</Typography>
                  {column === 'START DATE' &&
                    (sortOrder === 'asc' ? (
                      <ArrowUpwardIcon className={classes.sortIcon} />
                    ) : (
                      <ArrowDownwardIcon className={classes.sortIcon} />
                    ))}
                </Box>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedAdjustments && sortedAdjustments.length > 0 ? (
            sortedAdjustments.map((adjustment) => (
              <AdjustmentRow key={adjustment.adjustmentId} adjustment={adjustment} />
            ))
          ) : (
            <TableCell
              colSpan={ADJUSTMENT_TABLE_COLUMNS.length}
              className={classes.zeroResultsCell}
            >
              {isLoading ? (
                <Typography variant="body1">Loading adjustments...</Typography>
              ) : (
                <Typography variant="body1">No adjustments</Typography>
              )}
            </TableCell>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AdjustmentsTable;
