import { createTheme } from '@mui/material';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import React from 'react';
import { useSelector } from 'react-redux';
import { getThemeState } from '../store/theme';
// import { getTheme } from '../store/theme';

const ThemeSwitcher = ({ theme, children }) => {
  const { theme: themeState } = useSelector(getThemeState);
  const whitePageTheme = {
    ...theme,
    ...{
      palette: {
        ...theme.palette,
        background: { default: '#FFFFFF' },
      },
    },
  };

  const currentTheme = themeState === 'white' ? whitePageTheme : theme;

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={createTheme(currentTheme)}>{children}</ThemeProvider>
    </StyledEngineProvider>
  );
};

export default ThemeSwitcher;
