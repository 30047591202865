import { CognitoAuth } from 'amazon-cognito-auth-js';
import { CognitoUserPool } from 'amazon-cognito-identity-js';
import cognitoConfig from '../api/helpers';

const { clientId, userPool, userPoolBaseUri, callbackUri, signoutUri, tokenScopes } = cognitoConfig;

export const createCognitoAuth = () => {
  const appWebDomain = userPoolBaseUri.replace('https://', '').replace('http://', '');
  const auth = new CognitoAuth({
    UserPoolId: userPool,
    ClientId: clientId,
    AppWebDomain: appWebDomain,
    TokenScopesArray: tokenScopes,
    RedirectUriSignIn: callbackUri,
    RedirectUriSignOut: signoutUri,
  });
  return auth;
};

const createCognitoUserPool = () =>
  new CognitoUserPool({
    UserPoolId: userPool,
    ClientId: clientId,
  });

const getCognitoUser = () => {
  const pool = createCognitoUserPool();
  return pool.getCurrentUser();
};

export const getCognitoSignInUri = () => {
  const signinUri = `${userPoolBaseUri}/login?response_type=code&client_id=${clientId}&redirect_uri=${callbackUri}`;
  return signinUri;
};

export const getCognitoSession = () =>
  new Promise((resolve, reject) => {
    const cognitoUser = getCognitoUser();

    if (!cognitoUser) {
      reject(new Error('Failure getting Cognito user'));
      return;
    }

    cognitoUser.getSession(async (err, result) => {
      if (err || !result) {
        reject(new Error(`Failure getting Cognito session: ${err}`));
        return;
      }

      const session = {
        accessToken: result.accessToken.jwtToken,
        idToken: result.idToken.jwtToken,
        venueId: result.idToken.payload?.['custom:venueId'],
        refreshToken: result.refreshToken.token,
        expiresAt: result.accessToken.payload.exp,
        username: cognitoUser.username,
        roles: cognitoUser.roles,
      };

      resolve(session);
    });
  });

export const signOutCognitoSession = () => {
  const auth = createCognitoAuth();
  auth.signOut();
};
