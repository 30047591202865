import { createSlice } from '@reduxjs/toolkit';
import {
  asyncInitialState as initialState,
  createAsyncAction,
  createAsyncReducerHandlers,
} from '../utils/async';
import fetchDeliveryMetricsApi from '../../api/reports/deliveryMetrics';

export const fetchDeliveryMetrics = createAsyncAction(
  'fetch-delivery-metrics',
  fetchDeliveryMetricsApi,
);

const deliveryMetricsSlice = createSlice({
  name: 'deliveryMetrics',
  initialState,
  reducers: {
    clearDeliveryMetrics(state) {
      delete state.data;
    },
  },
  extraReducers: createAsyncReducerHandlers(fetchDeliveryMetrics),
});

export const { clearDeliveryMetrics } = deliveryMetricsSlice.actions;
export const { reducer: deliveryMetrics } = deliveryMetricsSlice;
