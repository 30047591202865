import { createSlice } from '@reduxjs/toolkit';
import {
  asyncInitialState as initialState,
  createAsyncAction,
  createAsyncReducerHandlers,
} from '../utils/async';
import {
  fetchIntroTasks as fetchIntroTasksApi,
  updateIntroTask as updateIntroTaskApi,
  updateIntroTasks as updateIntroTasksApi,
} from '../../api/introTasks';

export const fetchIntroTasks = createAsyncAction('fetch-intro-tasks', fetchIntroTasksApi);

export const updateIntroTask = createAsyncAction('update-intro-taskApi', updateIntroTaskApi);

export const updateIntroTasks = createAsyncAction('update-intro-taskApi', updateIntroTasksApi);

const introTasksSlice = createSlice({
  name: 'introTasks',
  initialState,
  reducers: {
    clearTasks: (state) => {
      state.loading = initialState.loading;
      state.data = initialState.data;
      state.request = initialState.request;
      state.error = initialState.error;
    },
  },
  extraReducers: createAsyncReducerHandlers(fetchIntroTasks),
});

const introTaskSlice = createSlice({
  name: 'introTask',
  initialState,
  reducers: {},
  extraReducers: {
    ...createAsyncReducerHandlers(updateIntroTask),
    ...createAsyncReducerHandlers(updateIntroTasks),
  },
});

export const { clearTasks } = introTasksSlice.actions;

export const { reducer: introTasks } = introTasksSlice;
export const { reducer: introTask } = introTaskSlice;
