import { createSlice } from '@reduxjs/toolkit';
import {
  asyncInitialState as initialState,
  createAsyncAction,
  createAsyncReducerHandlers,
} from '../utils/async';
import {
  fetchAccounts as fetchAccountsApi,
  fetchAccount as fetchAccountApi,
  updateAccount as updateAccountApi,
  deleteAccount as deleteAccountApi,
  createAccount as createAccountApi,
} from '../../api/accounts';

export const fetchAccounts = createAsyncAction('fetch-accounts', fetchAccountsApi);
export const fetchAccount = createAsyncAction('fetch-account', fetchAccountApi);

export const updateAccount = createAsyncAction('update-account', updateAccountApi);

export const deleteAccount = createAsyncAction('delete-account', deleteAccountApi);

export const createAccount = createAsyncAction('create-account', createAccountApi);

const accountsSlice = createSlice({
  name: 'accounts',
  initialState,
  reducers: {},
  extraReducers: {
    ...createAsyncReducerHandlers(fetchAccounts),
  },
});
const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {},
  extraReducers: {
    ...createAsyncReducerHandlers(fetchAccount),
    ...createAsyncReducerHandlers(updateAccount),
    ...createAsyncReducerHandlers(deleteAccount),
    ...createAsyncReducerHandlers(createAccount),
  },
});

export const { reducer: accounts } = accountsSlice;
export const { reducer: account } = accountSlice;
