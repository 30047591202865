import { makeStyles } from '@mui/styles';
import React from 'react';

import getMainSteps from './getMainSteps';
import getNormalisedSingleStepPositionValue from './getNormalisedSingleStepPositionValue';
import getNormalisedStepPosition from './getNormalisedStepPosition';

import StepperCircleIndicator from './StepperCircleIndicator';

const useStyles = ({ activeStepPositionValue }) => {
  const stepperHeight = 10;
  const circleWidth = 20;

  return makeStyles((theme) => ({
    stepper: {
      width: '100%',
      maxWidth: 320,
      margin: theme.spacing(2),
      position: 'relative',
      height: stepperHeight,
      backgroundColor: theme.palette.grey[300],
      borderRadius: 8,
      borderRight: `${circleWidth / 2}px solid ${theme.palette.grey[300]}`,
    },
    stepperIndicator: {
      borderRadius: 8,
      margin: 'auto',
      top: 0,
      bottom: 0,
      position: 'absolute',
      height: stepperHeight,
      backgroundColor: theme.palette.secondary.main,
      transition: 'width 0.3s ease-in-out',
      width: `calc(${activeStepPositionValue}% + ${circleWidth / 2}px)`,
    },
  }));
};

const Stepper = ({ steps, activeStepIndex }) => {
  const mainSteps = getMainSteps(steps);
  const singleStepPositionValue = getNormalisedSingleStepPositionValue(mainSteps);
  const activeStepPositionValue = getNormalisedStepPosition(
    steps[activeStepIndex].position,
    singleStepPositionValue,
  );
  const activeStep = steps[activeStepIndex];
  const classes = useStyles({ activeStepPositionValue })();

  return (
    <div className={classes.stepper}>
      {mainSteps.map((step) => (
        <StepperCircleIndicator
          key={step.path}
          step={step}
          stepPositionValue={singleStepPositionValue}
          activeStep={activeStep}
        />
      ))}
      <div className={classes.stepperIndicator} />
    </div>
  );
};

export default Stepper;
