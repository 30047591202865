import React, { useCallback, useEffect, useRef, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { AppBar, Button, Toolbar } from '@mui/material';
import { useHistory } from 'react-router-dom';
import ConfirmDialog from './ConfirmDialog';
import cySelectors from '../../tests/cySelectors';
import { customPalette } from '../../theme/mui/colourPalettes';

const useStyles = makeStyles((theme) => ({
  appbar: {
    zIndex: theme.zIndex.drawer + 2,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  discardButton: {
    color: customPalette.white,
    borderColor: customPalette.white,
    '&:hover': {
      borderColor: customPalette.white,
    },
  },
  saveButton: {
    color: customPalette.midnight,
    backgroundColor: customPalette.white,
    '&:disabled': {
      backgroundColor: 'rgba(255, 255, 255, 0.5)',
    },
    '&:hover': {
      backgroundColor: customPalette.greyLightest,
      color: customPalette.midnight,
    },
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: theme.spacing(1),
  },
}));

const UniversalSave = ({ dirty, onSave, errors, onDiscard }) => {
  const classes = useStyles();
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [currentPath, setCurrentPath] = useState('');
  const [isError, setIsError] = useState(errors);
  const amountOfErrors = isError ? Object.keys(isError).length : 0;

  const history = useHistory();

  const unblockRef = useRef();

  const unblock = useCallback(() => unblockRef.current && unblockRef.current(), [unblockRef]);
  const block = useCallback(() => {
    unblock();
    unblockRef.current = history.block((prompt) => {
      setCurrentPath(prompt.pathname);
      setIsConfirmOpen(true);
      return '';
    });
  }, [history, unblock]);

  const handleCloseDialog = () => {
    setIsConfirmOpen(false);
    return block();
  };

  const leavePage = () => {
    unblock();
    onDiscard();
    history.push(currentPath);
  };

  const handleSave = () => {
    unblock();
    onSave();
  };

  const handleErrorText = () => {
    if (amountOfErrors > 1) {
      return `${amountOfErrors} fields are not correctly filled out`;
    }
    return `There is a field that is not correctly filled out`;
  };

  useEffect(() => {
    if (dirty) {
      block();
    } else {
      unblock();
    }
    return () => unblock();
  }, [dirty, block, unblock]);

  useEffect(() => {
    if (errors) {
      setIsError(errors);
    }
  }, [errors]);

  return dirty ? (
    <>
      <AppBar className={classes.appbar}>
        <Toolbar className={classes.toolbar}>
          {amountOfErrors > 0 ? <p>{handleErrorText()}</p> : <p>Unsaved Changes</p>}
          <div className={classes.buttons}>
            <Button
              variant="outlined"
              className={classes.discardButton}
              onClick={onDiscard}
              data-cy={cySelectors.UNIVERSAL_DISCARD}
            >
              Discard
            </Button>
            <Button
              variant="contained"
              className={classes.saveButton}
              onClick={handleSave}
              disabled={amountOfErrors > 0}
              data-cy={cySelectors.UNIVERSAL_SAVE}
            >
              Save
            </Button>
          </div>
        </Toolbar>
      </AppBar>
      <ConfirmDialog
        handleCloseDialog={handleCloseDialog}
        leavePage={leavePage}
        isOpen={isConfirmOpen}
      />
    </>
  ) : null;
};
export default UniversalSave;
