import { createSlice } from '@reduxjs/toolkit';
import {
  asyncInitialState as initialState,
  createAsyncAction,
  createAsyncReducerHandlers,
  fetchPageAsyncReducerHandler,
} from '../utils/async';
import { fetchReviews as fetchReviewsApi } from '../../api/reviews';

export const fetchReviews = createAsyncAction('fetch-reviews', fetchReviewsApi);
export const fetchReviewsPage = createAsyncAction('fetch-reviews-page', fetchReviewsApi);

const reviewsSlice = createSlice({
  name: 'reviews',
  initialState,
  reducers: {},
  extraReducers: {
    ...createAsyncReducerHandlers(fetchReviews),
    ...fetchPageAsyncReducerHandler(fetchReviewsPage),
  },
});

export const { reducer: reviews } = reviewsSlice;
