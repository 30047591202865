import React from 'react';
import { useSelector } from 'react-redux';
import { Form, Formik } from 'formik';

import { Card, Switch, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { makeStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import UniversalSave from '../UniversalSave';

import { getAllStaffState } from '../../store/staff/selectors';
import TableWarning from '../TableWarning';
import TableLink from '../TableLink';

const useStyles = makeStyles((theme) => ({
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
    padding: '0.5rem',
    '& a': {
      ...theme.customFonts.small,
    },
  },
  name: {
    color: theme.customPalette.greyDarker,
    marginBottom: 0,
  },
  description: {
    ...theme.customFonts.small,
    color: theme.customPalette.greyDark,
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '1rem',
  },
  rowPortion: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '1rem',
  },

  viewOrders: {
    ...theme.customFonts.small,
    color: theme.customPalette.greyDarker,
    textDecoration: 'underline',
    textAlign: 'right',
  },
  switch: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  tableCard: {
    padding: '1rem',
  },
  disabledHeader: {
    ...theme.customFonts.smallBold,
    color: theme.customPalette.greyDarker,
    borderBottom: `1px solid ${theme.customPalette.greyLight}`,
    marginTop: '2rem',
  },
  deactivatedTitle: {
    ...theme.customFonts.smallBold,
    color: theme.customPalette.greyDarker,
    borderBottom: `1px solid ${theme.customPalette.greyLight}`,
  },
  tableTitle: {
    ...theme.customFonts.small,
    color: theme.customPalette.greyDarker,
  },
  tableHeader: {
    backgroundColor: theme.customPalette.greyLight,
  },
  tableTitleSwitch: {
    width: '60px',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  table: {
    tableLayout: 'fixed',
  },
  tableIdColumn: {
    width: '40px',
  },
  tablePinColumn: {
    width: '70px',
  },
}));

const TotalTableCell = withStyles({
  root: {
    borderBottom: 'none',
  },
})(TableCell);

const StaffTable = ({ onSubmit }) => {
  const classes = useStyles();
  const { loading: staffLoading, data: allStaff } = useSelector(getAllStaffState);

  if (!allStaff) return null;
  const sortedStaff = [...allStaff].sort((a) => (a.enabled ? -1 : 1));
  const firstEnabledStaff = sortedStaff.findIndex((staff) => !staff.enabled);

  const findArrayIndex = (valueData, staffId) => {
    if (valueData && staffId) {
      const index = valueData.findIndex((obj) => obj.staffId === `${staffId}`);
      return index;
    }
    return null;
  };

  return (
    !staffLoading && (
      <Formik enableReinitialize initialValues={{ staff: allStaff }} onSubmit={onSubmit}>
        {({ setFieldValue, resetForm, values, dirty, errors }) => (
          <>
            <UniversalSave
              dirty={dirty}
              errors={errors}
              onSave={() => {
                onSubmit(values);
              }}
              onDiscard={resetForm}
            />
            <Form>
              <TableWarning />
              <Card className={classes.tableCard}>
                <Table size="small" className={classes.table}>
                  <TableHead>
                    <TableRow className={classes.tableHeader}>
                      <TableCell className={`${classes.tableTitle} ${classes.tableIdColumn}`}>
                        ID
                      </TableCell>
                      <TableCell className={classes.tableTitle}>NAME</TableCell>
                      <TableCell className={classes.tableTitle} align="right">
                        ROLE
                      </TableCell>
                      <TableCell
                        className={`${classes.tableTitle} ${classes.tablePinColumn}`}
                        align="right"
                      >
                        PIN
                      </TableCell>
                      <TableCell className={classes.tableTitleSwitch} align="right">
                        &nbsp;
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {allStaff && allStaff?.length > 0 && (
                      <>
                        {sortedStaff?.map(({ staffId, name, pin, description }, sortedIndex) => {
                          const index = findArrayIndex(values.staff, staffId);
                          return (
                            <React.Fragment key={staffId}>
                              {sortedIndex === firstEnabledStaff && (
                                <TableRow>
                                  <TableCell colSpan={5} className={classes.deactivatedTitle}>
                                    Deactivated members
                                  </TableCell>
                                </TableRow>
                              )}
                              <TableRow>
                                <TotalTableCell className={classes.tableIdColumn}>
                                  {staffId}
                                </TotalTableCell>
                                <TotalTableCell>
                                  <TableLink to={`/staff/edit/${staffId}`} novisited>
                                    {name}
                                  </TableLink>
                                </TotalTableCell>
                                <TotalTableCell align="right">{description}</TotalTableCell>
                                <TotalTableCell align="right" className={classes.tablePinColumn}>
                                  {pin}
                                </TotalTableCell>
                                <TotalTableCell align="right">
                                  <Switch
                                    color="primary"
                                    className={classes.switch}
                                    type="checkbox"
                                    checked={values.staff[index].enabled}
                                    onChange={() => {
                                      setFieldValue(
                                        `staff[${index}].enabled`,
                                        !values.staff[index].enabled,
                                      );
                                    }}
                                  />
                                </TotalTableCell>
                              </TableRow>
                            </React.Fragment>
                          );
                        })}
                      </>
                    )}
                  </TableBody>
                </Table>
              </Card>
            </Form>
          </>
        )}
      </Formik>
    )
  );
};

export default StaffTable;
