export default [
  {
    name: 'Deliveroo',
    key: 'ROO',
  },
  {
    name: 'Uber Eats',
    key: 'UBR',
  },
  {
    name: 'Just Eat',
    key: 'JET',
  },
];
