import React from 'react';

import { makeStyles } from '@mui/styles';
import { withStyles } from 'tss-react/mui';
import { Box, Button } from '@mui/material';

const ActionsContainer = withStyles(Box, (theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2),
  },
}));

const useStyles = makeStyles((theme) => ({
  prev: {
    ...theme.customFonts.small,
    marginRight: theme.spacing(2),
  },
  next: {
    ...theme.customFonts.small,
  },
}));

const ReportsTablePaginationActions = ({ count, page, rowsPerPage, onPageChange }) => {
  const classes = useStyles();
  const handleBackButtonClick = () => {
    onPageChange(page - 1);
  };

  const handleNextButtonClick = () => {
    onPageChange(page + 1);
  };

  return (
    <ActionsContainer display="flex" justifyContent="space-between">
      <Button
        ml={2}
        variant="outlined"
        size="small"
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
        className={classes.prev}
      >
        Prev
      </Button>
      <Button
        ml={2}
        variant="outlined"
        size="small"
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
        className={classes.next}
      >
        Next
      </Button>
    </ActionsContainer>
  );
};

export default ReportsTablePaginationActions;
