import React from 'react';
import { Card, CardActions, CardContent, Button } from '@mui/material';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  heading: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

const ConfirmDeleteReportEmailDialog = ({
  handleCloseDialog,
  submitDialog,
  temporaryReportEmail,
}) => {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardContent>About to delete the following report email: {temporaryReportEmail}</CardContent>
      <CardActions className={classes.cardActions}>
        <Button onClick={handleCloseDialog}>Cancel</Button>
        <Button
          onClick={() => submitDialog(temporaryReportEmail)}
          variant="contained"
          color="primary"
        >
          Delete email
        </Button>
      </CardActions>
    </Card>
  );
};

export default ConfirmDeleteReportEmailDialog;
