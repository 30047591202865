import React from 'react';
import PropTypes from 'prop-types';
import { Card } from '@mui/material';

import VenuePriorityDeleteForm from './VenuePriorityDeleteForm';
import CustomDialog from '../CustomDialog';

const VenueDeletePriorityDialog = ({ handleCloseDialog, dialogOpen, courseName, onSubmit }) => (
  <CustomDialog
    title={`Delete '${courseName}'?`}
    isDialogOpen={dialogOpen}
    handleCloseDialog={handleCloseDialog}
  >
    <Card>
      <VenuePriorityDeleteForm handleCloseDialog={handleCloseDialog} onSubmit={onSubmit} />
    </Card>
  </CustomDialog>
);

VenueDeletePriorityDialog.propTypes = {
  handleCloseDialog: PropTypes.func.isRequired,
  dialogOpen: PropTypes.bool.isRequired,
};

export default VenueDeletePriorityDialog;
