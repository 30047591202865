import React from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { IconButton, Tooltip } from '@mui/material';
import { MdDelete, MdEdit } from 'react-icons/md';

const TillsTable = ({ tills, handleEdit, handleDelete }) => {
  if (!tills || tills.length === 0) return null;

  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Device ID (first 6 digits)</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {tills.map(({ name, mobileDeviceId, tillId, defaultFloatAmount }) => (
            <TableRow key={`${name}-${mobileDeviceId}`}>
              <TableCell component="th" scope="row">
                {name}
              </TableCell>
              <TableCell>{mobileDeviceId.substr(0, 6)}&hellip;</TableCell>
              <TableCell align="right">
                <Tooltip title={`Edit ${name} section`} aria-label={`Edit ${name}`}>
                  <IconButton
                    onClick={() => handleEdit({ name, mobileDeviceId, tillId, defaultFloatAmount })}
                    size="large"
                  >
                    <MdEdit style={{ fontSize: 20 }} />
                  </IconButton>
                </Tooltip>
                <Tooltip title={`Delete ${name} section`} aria-label={`Delete ${name} section`}>
                  <IconButton onClick={() => handleDelete(tillId)} size="large">
                    <MdDelete style={{ fontSize: 20 }} />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TillsTable;
