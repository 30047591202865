import React from 'react';
import { Link } from 'react-router-dom';
import WarningCard from '../../components/CustomWarningCard';

export default function MenuWarning({ serviceStatus }) {
  const { isDrinksServiceOn, isFoodServiceOn, foodDelay, drinksDelay } = serviceStatus || {};
  /*
  1. Show that food service is off
  2. Show that drinks service is off
  3. Show food service is delayed by x minutes
  4. Show drinks service is delayed by x minutes
  5. Show that food service is off but drinks are delayed by x minutes
  6. Show that drinks service is off but food service is delayed by x minutes
  7. Show nothing if the above conditions are not met
  */

  const isVenueNotAcceptingOrders = () => {
    if (
      (!isFoodServiceOn && drinksDelay === 0) ||
      (!isDrinksServiceOn && foodDelay === 0) ||
      (!isFoodServiceOn && !isDrinksServiceOn)
    ) {
      return (
        <WarningCard>
          <div>
            <p>
              {!isFoodServiceOn && 'Food '}
              {!isFoodServiceOn && !isDrinksServiceOn && 'and '}
              {!isDrinksServiceOn && 'Drinks '}
              will not appear in the customers menus as the service has been stopped. You can change
              these settings <Link to="/settings/venue/service-status">here</Link>
            </p>
          </div>
        </WarningCard>
      );
    }
    return null;
  };

  const isVenueDelayingOrders = () => {
    if ((foodDelay > 0 && isDrinksServiceOn) || (drinksDelay > 0 && isFoodServiceOn)) {
      return (
        <WarningCard>
          <div>
            <p>
              {!!foodDelay && `Food is delayed by ${foodDelay} minutes `}
              {!!foodDelay && !!drinksDelay && 'and '}
              {!!drinksDelay && `Drinks are delayed by ${drinksDelay} minutes.`} This appears on the
              QR menu, to warn customers that the service will be delayed. You can change these
              settings <Link to="/settings/venue/service-status">here</Link>
            </p>
          </div>
        </WarningCard>
      );
    }
    return null;
  };

  const isVenueMixingAvailability = () => {
    if (foodDelay > 0 && !isDrinksServiceOn) {
      return (
        <WarningCard>
          <div>
            <p>
              Food is delayed by {foodDelay} minutes and Drinks service is not accepting orders so
              will not appear in the menu for customers. You can change these settings{' '}
              <Link to="/settings/venue/service-status">here</Link>
            </p>
          </div>
        </WarningCard>
      );
    }
    if (drinksDelay > 0 && !isFoodServiceOn) {
      return (
        <WarningCard>
          <div>
            <p>
              Drinks are delayed by {drinksDelay} minutes and Food service is not accepting orders
              so will not appear in the menu for customers. You can change these settings{' '}
              <Link to="/settings/venue/service-status">here</Link>
            </p>
          </div>
        </WarningCard>
      );
    }
    return null;
  };

  return isVenueNotAcceptingOrders() || isVenueDelayingOrders() || isVenueMixingAvailability();
}
