import React from 'react';
import { Box, Grid, Paper, Typography } from '@mui/material';

import { withStyles } from 'tss-react/mui';

const RatingsBoxContainer = withStyles(Paper, (theme) => ({
  root: {
    maxWidth: theme.spacing(48),
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const MainRatingHeading = withStyles(Typography, (theme) => ({
  root: {
    fontSize: theme.typography.pxToRem(48),
    fontWeight: 'bolder',
    lineHeight: '1',
    marginTop: theme.spacing(1),
    paddingRight: theme.spacing(0.5),
  },
}));

const RatingsBox = ({ averageRating, totalRatings, dateRangeLabel }) => {
  const finalAverageRating = averageRating ? averageRating.toFixed(1) : '0';
  const finalRatingsLabel = `rating${averageRating ? 's' : ''}`;

  return (
    <RatingsBoxContainer>
      <Typography variant="subtitle1" component="p">
        {dateRangeLabel}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <MainRatingHeading component="span">{finalAverageRating}</MainRatingHeading>
          <Typography variant="body2" component="span">
            out of 5
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Box display="flex" alignItems="end" justifyContent="end" height="100%">
            <Typography variant="body2" component="p" align="right">
              {totalRatings ? `${totalRatings} ${finalRatingsLabel}` : 'No ratings'}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </RatingsBoxContainer>
  );
};

export default RatingsBox;
