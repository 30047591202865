import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FiPlus } from 'react-icons/fi';

import { makeStyles } from '@mui/styles';
import { Box, Button, Typography } from '@mui/material';

import Page from '../../components/Page';
import withVenue from '../../hoc/withVenue';
import { getDiscountsState } from '../../store/itemDiscounts/selectors';
import { clearDiscounts, fetchDiscounts } from '../../store/itemDiscounts';
import DiscountsTable from './DiscountsTable';
import DiscountDialogue from './DiscountDialogue';
import shouldLoad from '../../shared/utils/shouldLoad';
import PageHeader from '../../components/PageHeader';

const useStyles = makeStyles(() => ({
  heading: {
    marginBottom: '20px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  title: {
    flexGrow: 1,
  },
}));

const Discounts = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const discountsState = useSelector(getDiscountsState);
  const { loading, data, error } = discountsState;
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const refresh = () => {
    dispatch(fetchDiscounts());
  };

  useEffect(() => {
    if (shouldLoad(discountsState)) dispatch(fetchDiscounts());
  }, [dispatch, discountsState]);

  return (
    <>
      <PageHeader>
        <Box className={classes.heading}>
          <Typography className={classes.title} variant="h2" component="h1">
            Discounts
          </Typography>
          <aside>
            <Button
              variant="contained"
              color="primary"
              startIcon={<FiPlus />}
              onClick={() => setIsDialogOpen(true)}
            >
              New Discount
            </Button>
          </aside>
        </Box>
      </PageHeader>
      <Page loading={loading} error={error}>
        {data && (
          <>
            <DiscountsTable />
            <DiscountDialogue
              isOpen={isDialogOpen}
              setIsOpen={setIsDialogOpen}
              onSubmit={refresh}
            />
          </>
        )}
      </Page>
    </>
  );
};

export default withVenue(Discounts, null, clearDiscounts);
