// eslint-disable-next-line max-len
// https://stackoverflow.com/questions/6832596/how-can-i-compare-software-version-number-using-javascript-only-numbers#answer-65687141

const isVersionAbove = (baseline, test) =>
  baseline.localeCompare(test, undefined, {
    numeric: true,
    sensitivity: 'base',
  }) > 0;

export default isVersionAbove;
