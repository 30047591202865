import { customPalette } from './colourPalettes';
import theme from '../default';

export const MuiTextField = {
  styleOverrides: {
    root: {
      backgroundColor: customPalette.white,
    },
  },
};

export const MuiButton = {
  styleOverrides: {
    root: {
      textTransform: 'none',
      fontWeight: 600,
      fontSize: '0.875rem',
      minHeight: 40,
    },
    text: {
      color: customPalette.midnight,
    },
    containedPrimary: {
      color: customPalette.white,
      backgroundColor: customPalette.midnight,
    },
  },
};

export const MuiAppBar = {
  styleOverrides: {
    root: {
      boxShadow: 'none',
    },
    colorPrimary: {
      backgroundColor: customPalette.white,
    },
  },
};

export const MuiDrawer = {
  styleOverrides: {
    paperAnchorDockedLeft: {
      borderRightColor: customPalette.greyLight,
    },
  },
};

export const MuiMenu = {
  styleOverrides: {
    paper: {
      borderRadius: theme.radii.md,
      boxShadow: theme.shadows.lg,
    },
  },
};

export const MuiMenuItem = {
  styleOverrides: {
    root: {
      fontSize: theme.fontSizes[2],
      borderRadius: theme.radii.md,
      margin: `0 ${theme.space[3]}`,
    },
  },
};

export const MuiListItem = {
  styleOverrides: {
    root: {
      borderRadius: theme.radii.md,
    },
    button: {
      '&$focusVisible': {
        backgroundColor: customPalette.greyLightest,
      },
      '&$selected, &$selected:hover': {
        backgroundColor: customPalette.greyLightest,
      },
      '&:hover': {
        backgroundColor: customPalette.greyLightest,
        '@media (hover: none)': {
          borderColor: 'transparent',
        },
      },
    },
  },
};

export const MuiListItemIcon = {
  styleOverrides: {
    root: {
      color: customPalette.midnight,
    },
  },
};
