import moment from 'moment-timezone';

export const rangeEnums = {
  TODAY: 'Today',
  YESTERDAY: 'Yesterday',
  LAST_7_DAYS: 'Last 7 days',
  LAST_FULL_WEEK: 'Last full week',
  LAST_4_WEEKS: 'Last 4 weeks',
  LAST_3_MONTHS: 'Last 3 months',
  LAST_12_MONTHS: 'Last 12 months',
  MONTH_TO_DATE: 'Month to date',
  QUARTER_TO_DATE: 'Quarter to date',
  YEAR_TO_DATE: 'Year to date',
  CUSTOM: 'Custom dates',
};

export const selectRanges = [
  {
    id: rangeEnums.TODAY,
    label: rangeEnums.TODAY,
    modifyPrevious: (date) => moment(date).add(1, 'days').toISOString(),
  },
  {
    id: rangeEnums.YESTERDAY,
    label: rangeEnums.YESTERDAY,
    modifyPrevious: (date) => moment(date).add(1, 'days').toISOString(),
  },
  {
    id: rangeEnums.LAST_FULL_WEEK,
    label: rangeEnums.LAST_FULL_WEEK,
    modifyPrevious: (date) => moment(date).startOf('week').add(7, 'days').toISOString(),
  },
  {
    id: rangeEnums.LAST_7_DAYS,
    label: rangeEnums.LAST_7_DAYS,
    modifyPrevious: (date) => moment(date).add(7, 'days').toISOString(),
  },
  {
    id: rangeEnums.LAST_4_WEEKS,
    label: rangeEnums.LAST_4_WEEKS,
    modifyPrevious: (date) => moment(date).add(4, 'weeks').toISOString(),
  },
  {
    id: rangeEnums.LAST_3_MONTHS,
    label: rangeEnums.LAST_3_MONTHS,
    modifyPrevious: (date) => moment(date).add(3, 'months').toISOString(),
  },
  {
    id: rangeEnums.LAST_12_MONTHS,
    label: rangeEnums.LAST_12_MONTHS,
    modifyPrevious: (date) => moment(date).add(12, 'months').toISOString(),
  },
  {
    id: rangeEnums.MONTH_TO_DATE,
    label: rangeEnums.MONTH_TO_DATE,
    // calculate this by days rather than months, otherwise multiple days can translate
    // to the same day, so two datapoints on the same day on the graph.
    modifyPrevious: (date) =>
      moment(date).add(moment(date).add(1, 'months').daysInMonth(), 'days').toISOString(),
  },
  {
    id: rangeEnums.QUARTER_TO_DATE,
    label: rangeEnums.QUARTER_TO_DATE,
    modifyPrevious: (date) => moment(date).add(1, 'quarters').toISOString(),
  },
  {
    id: rangeEnums.YEAR_TO_DATE,
    label: rangeEnums.YEAR_TO_DATE,
    modifyPrevious: (date) => moment(date).add(1, 'years').toISOString(),
  },
  {
    id: rangeEnums.CUSTOM,
    label: rangeEnums.CUSTOM,
    modifyPrevious: (date) => moment(date).add(1, 'years').toISOString(),
  },
];

export const dateRanges = {
  [rangeEnums.TODAY]: {
    label: rangeEnums.TODAY,
    from: () => moment().startOf('day').toISOString(),
    to: () => moment().add(1, 'day').startOf('day').toISOString(),
  },
  [rangeEnums.YESTERDAY]: {
    label: rangeEnums.YESTERDAY,
    from: () => moment().subtract(1, 'days').startOf('day').toISOString(),
    to: () => moment().startOf('day').toISOString(),
  },
  [rangeEnums.LAST_7_DAYS]: {
    label: rangeEnums.LAST_7_DAYS,
    from: () => moment().subtract(6, 'days').startOf('day').toISOString(),
    to: () => moment().add(1, 'day').startOf('day').toISOString(),
  },
  [rangeEnums.LAST_FULL_WEEK]: {
    label: rangeEnums.LAST_FULL_WEEK,
    from: () => moment().startOf('week').subtract(6, 'days').startOf('day').toISOString(),
    to: () => moment().startOf('week').add(1, 'day').startOf('day').toISOString(),
  },
  [rangeEnums.LAST_4_WEEKS]: {
    label: rangeEnums.LAST_4_WEEKS,
    from: () => moment().subtract(3, 'weeks').startOf('week').toISOString(),
    to: () =>
      moment().subtract(0, 'weeks').endOf('week').add(1, 'day').startOf('day').toISOString(),
  },
  [rangeEnums.LAST_3_MONTHS]: {
    label: rangeEnums.LAST_3_MONTHS,
    from: () => moment().subtract(2, 'months').startOf('month').toISOString(),
    to: () =>
      moment().subtract(0, 'months').endOf('month').add(1, 'day').startOf('day').toISOString(),
  },
  [rangeEnums.LAST_12_MONTHS]: {
    label: rangeEnums.LAST_12_MONTHS,
    from: () => moment().subtract(11, 'months').startOf('month').toISOString(),
    to: () =>
      moment().subtract(0, 'months').endOf('month').add(1, 'day').startOf('day').toISOString(),
  },
  [rangeEnums.MONTH_TO_DATE]: {
    label: rangeEnums.MONTH_TO_DATE,
    from: () => moment().startOf('month').toISOString(),
    to: () => moment().endOf('month').add(1, 'day').startOf('day').toISOString(),
  },
  [rangeEnums.QUARTER_TO_DATE]: {
    label: rangeEnums.QUARTER_TO_DATE,
    from: () => moment().startOf('quarter').toISOString(),
    to: () => moment().endOf('quarter').add(1, 'day').startOf('day').toISOString(),
  },
  [rangeEnums.YEAR_TO_DATE]: {
    label: rangeEnums.YEAR_TO_DATE,
    from: () => moment().startOf('year').toISOString(),
    to: () => moment().endOf('year').add(1, 'day').startOf('day').toISOString(),
  },
  [rangeEnums.CUSTOM]: {
    label: rangeEnums.CUSTOM,
    from: () => moment().toISOString(),
    to: () => moment().add(1, 'day').startOf('day').toISOString(),
  },
};

export const dateRangesPrevious = {
  [rangeEnums.TODAY]: {
    label: rangeEnums.TODAY,
    from: () => moment().subtract(7, 'days').startOf('day').toISOString(),
    to: () => moment().subtract(6, 'days').startOf('day').toISOString(),
  },
  [rangeEnums.YESTERDAY]: {
    label: rangeEnums.YESTERDAY,
    from: () => moment().subtract(8, 'days').startOf('day').toISOString(),
    to: () => moment().subtract(7, 'days').startOf('day').toISOString(),
  },
  [rangeEnums.LAST_7_DAYS]: {
    label: rangeEnums.LAST_7_DAYS,
    from: () => moment().subtract(13, 'days').startOf('day').toISOString(),
    to: () => moment().subtract(6, 'days').startOf('day').toISOString(),
  },
  [rangeEnums.LAST_FULL_WEEK]: {
    label: rangeEnums.LAST_FULL_WEEK,
    from: () => moment().startOf('week').subtract(13, 'days').startOf('day').toISOString(),
    to: () => moment().startOf('week').subtract(6, 'days').startOf('day').toISOString(),
  },
  [rangeEnums.LAST_4_WEEKS]: {
    label: rangeEnums.LAST_4_WEEKS,
    from: () => moment().subtract(8, 'weeks').startOf('week').toISOString(),
    to: () => moment().subtract(4, 'weeks').startOf('week').toISOString(),
  },
  [rangeEnums.LAST_3_MONTHS]: {
    label: rangeEnums.LAST_3_MONTHS,
    from: () => moment().subtract(5, 'months').startOf('month').toISOString(),
    to: () => moment().subtract(2, 'months').startOf('month').toISOString(),
  },
  [rangeEnums.LAST_12_MONTHS]: {
    label: rangeEnums.LAST_12_MONTHS,
    from: () => moment().subtract(23, 'months').startOf('month').toISOString(),
    to: () => moment().subtract(11, 'months').startOf('month').toISOString(),
  },
  [rangeEnums.MONTH_TO_DATE]: {
    label: rangeEnums.MONTH_TO_DATE,
    from: () => moment().subtract(1, 'months').startOf('month').toISOString(),
    to: () => moment().startOf('month').toISOString(),
  },
  [rangeEnums.QUARTER_TO_DATE]: {
    label: rangeEnums.QUARTER_TO_DATE,
    from: () => moment().subtract(1, 'quarters').startOf('quarter').toISOString(),
    to: () => moment().startOf('quarter').toISOString(),
  },
  [rangeEnums.YEAR_TO_DATE]: {
    label: rangeEnums.YEAR_TO_DATE,
    from: () => moment().subtract(1, 'years').startOf('year').toISOString(),
    to: () => moment().startOf('year').toISOString(),
  },
  [rangeEnums.CUSTOM]: {
    label: rangeEnums.CUSTOM,
    from: () => moment().startOf('day').toISOString(),
    to: () => moment().add(1, 'day').startOf('day').toISOString(),
  },
};

export const getPreviousPeriod = ({ from, to }) => {
  const diff = moment(to).diff(moment(from));

  const previousPeriod = moment(from).subtract(diff);
  return {
    from: moment(previousPeriod).subtract(1, 'millisecond').utc().toISOString(),
    to: moment(from).subtract(1, 'milliseconds').utc().toISOString(),
  };
};
