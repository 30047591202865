import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Grid, ListItemButton, Paper, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Link as RouterLink } from 'react-router-dom';
import { orderBy } from 'lodash';
import moment from 'moment';
import useEndpoint from '../../hooks/useEndpoint';

import Page from '../../components/Page';
import { customCampaignTypes } from '../../components/PromotionsListForm/campaignTypes';
import PromotionsSlider from '../../components/PromotionsSlider';
import PromotionsListForm from '../../components/PromotionsListForm';

import OfferDialog from './OfferDialog';
import NewBrandupOffer from './NewBrandupOffer';

import withVenue from '../../hoc/withVenue';

import { useNotifications } from '../../shared/contexts/Notifications/useNotifications';
import shouldLoad from '../../shared/utils/shouldLoad';
import { getErrorMessage } from '../../shared/utils/errors';

import { getPersonalisationState } from '../../store/personalisation/selectors';
import { fetchPersonalisation } from '../../store/personalisation';
import { clearCampaigns, fetchCampaigns, updateCampaigns } from '../../store/campaigns';
import { getCampaignsState } from '../../store/campaigns/selectors';
import { acceptCampaign } from '../../store/campaign';

import noPromotionsImg from './no-promotions.png';
import PageHeader from '../../components/PageHeader';

const useStyles = makeStyles((theme) => ({
  tableListHeading: {
    fontSize: 16,
  },
  tableListItem: {
    textDecoration: 'underline',
    color: theme.palette.secondary.main,
    '&:hover': {
      textDecoration: 'underline',
      backgroundColor: 'transparent',
    },
  },
  noPromotionsImg: {
    marginBottom: theme.spacing(4),
  },
}));

const Promotions = () => {
  const dispatch = useDispatch();
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [isOfferDialogOpen, setIsOfferDialogOpen] = useState(false);
  const { showErrorNotification, showSuccessNotification } = useNotifications();
  const {
    loading: campaignLoading,
    data: campaigns,
    error: campaignError,
  } = useEndpoint(getCampaignsState, fetchCampaigns());
  const personalisationState = useSelector(getPersonalisationState);
  const classes = useStyles();
  const {
    loading: personalisationLoading,
    data: personalisation,
    error: personalisationError,
  } = personalisationState;
  const brandColor = personalisation?.personalisation?.colour;
  const { availables = [], accepted = [], custom = [], expired = [] } = campaigns || {};
  const hasAvailables = !!availables?.length;
  const hasExpireds = !!expired?.length;
  const activeAcceptedCampaigns = accepted.filter(
    ({ active, status }) => active || status === 'JOIN' || status === 'ACTIVE',
  );
  const hasAcceptedCampaigns = !!accepted?.length;
  const customAndAcceptedCampaigns = [...custom, ...accepted];
  const hasCustomOrAcceptedCampaigns = !!customAndAcceptedCampaigns?.length;
  const brandupCampaigns = availables.filter(
    ({ campaignType }) =>
      !Object.values(customCampaignTypes)
        .map(({ value }) => value)
        .includes(campaignType),
  );
  const [latestBrandupCampaign] = orderBy(
    brandupCampaigns,
    ({ createdAt }) => moment(createdAt),
    'asc',
  );
  const promotionsListinitialValues = {
    campaigns: customAndAcceptedCampaigns,
  };
  const hasCampaigns = hasAvailables || hasAcceptedCampaigns || hasCustomOrAcceptedCampaigns;
  const offerInitialValues = {
    acceptTerms: true,
  };

  useEffect(() => {
    if (shouldLoad(personalisationState)) dispatch(fetchPersonalisation());
  }, [dispatch, personalisationState]);

  const handleOnSubmit = async (values) => {
    try {
      await dispatch(updateCampaigns(values));
      showSuccessNotification('Promotions updated successfully');
      await dispatch(fetchCampaigns());
    } catch (error) {
      showErrorNotification(getErrorMessage(error));
    }
  };

  const handleOpenOfferDialog = (campaign) => {
    setSelectedCampaign(campaign);
    setIsOfferDialogOpen(true);
  };

  const handleCloseOfferDialog = () => {
    setIsOfferDialogOpen(false);
  };

  const handleAcceptCampaign = async (campaignId) => {
    try {
      await dispatch(acceptCampaign(campaignId));
      await dispatch(fetchCampaigns());

      handleCloseOfferDialog();
      showSuccessNotification('Promotion accepted successfully');
    } catch (error) {
      showErrorNotification(getErrorMessage(error));
    }
  };

  return (
    <>
      <PageHeader>
        <Box component="header" display="flex" justifyContent="space-between" mb={3}>
          <Typography variant="h2" component="h1" gutterBottom>
            My promotions
          </Typography>
          <Button variant="contained" color="primary" component={RouterLink} to="/offers/add">
            New promotion
          </Button>
        </Box>
      </PageHeader>
      <Page
        loading={campaignLoading || personalisationLoading}
        error={campaignError || personalisationError}
      >
        {hasCampaigns ? (
          <>
            {hasAcceptedCampaigns && (
              <PromotionsSlider
                campaigns={activeAcceptedCampaigns}
                brandColor={brandColor}
                handleOpenOfferDialog={handleOpenOfferDialog}
              />
            )}

            <Grid container spacing={4}>
              <Grid item xs={12} lg={latestBrandupCampaign ? 8 : 12}>
                {hasAcceptedCampaigns && (
                  <Box component={Paper} p={2} mb={2}>
                    <Typography variant="h2" gutterBottom className={classes.tableListHeading}>
                      Promotions
                    </Typography>

                    {hasCustomOrAcceptedCampaigns && (
                      <PromotionsListForm
                        campaigns={customAndAcceptedCampaigns}
                        initialValues={promotionsListinitialValues}
                        handleOpenOfferDialog={handleOpenOfferDialog}
                        onSubmit={handleOnSubmit}
                      />
                    )}
                  </Box>
                )}

                {hasAvailables && (
                  <Box component={Paper} p={2} mb={2}>
                    <Typography variant="h2" gutterBottom className={classes.tableListHeading}>
                      Available
                    </Typography>
                    <Box display="flex" flexDirection="column" alignItems="start" mb={1} gap={8}>
                      {availables.map((campaign) => (
                        <ListItemButton
                          key={campaign.campaignId}
                          disableGutters
                          disableRipple
                          className={classes.tableListItem}
                          onClick={() => handleOpenOfferDialog(campaign)}
                        >
                          {campaign.campaignName}
                        </ListItemButton>
                      ))}
                    </Box>
                  </Box>
                )}
                {hasExpireds && (
                  <Box component={Paper} p={2} mb={2}>
                    <Typography variant="h2" gutterBottom className={classes.tableListHeading}>
                      Expired
                    </Typography>
                    <Box display="flex" flexDirection="column" alignItems="start" mb={1} gap={8}>
                      {expired.map((campaign) => (
                        <ListItemButton
                          key={campaign.campaignId}
                          disableGutters
                          disableRipple
                          className={classes.tableListItem}
                          onClick={() => handleOpenOfferDialog(campaign)}
                        >
                          {campaign.campaignName}
                        </ListItemButton>
                      ))}
                    </Box>
                  </Box>
                )}
              </Grid>
              {latestBrandupCampaign && (
                <Grid item xs="auto" lg={4}>
                  <NewBrandupOffer
                    brandupCampaign={latestBrandupCampaign}
                    handleOpenOfferDialog={handleOpenOfferDialog}
                  />
                </Grid>
              )}
            </Grid>
          </>
        ) : (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            my={8}
          >
            <img
              src={noPromotionsImg}
              alt="No promotions"
              width={269}
              height={269}
              className={classes.noPromotionsImg}
            />
            <Typography variant="h2" gutterBottom style={{ fontSize: 16 }}>
              Promote anything!
            </Typography>
            <Box maxWidth={400} textAlign="center">
              <Typography paragraph variant="body1">
                Flexible promotions gets eyes on specific menu items, deals, events or whatever you
                fancy, instantly on your QR menu.
              </Typography>
            </Box>
            <Button variant="contained" color="primary" component={RouterLink} to="/offers/add">
              Get started
            </Button>
          </Box>
        )}
        {selectedCampaign && (
          <OfferDialog
            campaign={selectedCampaign}
            isOpen={isOfferDialogOpen}
            handleClose={handleCloseOfferDialog}
            onSubmit={handleAcceptCampaign}
            initialValues={offerInitialValues}
          />
        )}
      </Page>
    </>
  );
};
export default withVenue(Promotions, null, clearCampaigns);
