import { createSelector } from '@reduxjs/toolkit';

export const getReportTipsByStaffState = (state) => state.reportTipsByStaff;
export const getReportSalesByStaffState = (state) => state.reportSalesByStaff;
export const getReportSalesOverviewState = (state) => state.reportSalesOverview;
export const getReportSalesByAreaState = (state) => state.reportSalesByArea;
export const getReportSalesByItemState = (state) => state.reportSalesByItem;
export const getReportSalesByTableState = (state) => state.reportSalesByTable;
export const getReportSalesByCategoryState = (state) => state.reportSalesByCategory;
export const getReportCustomersOverviewState = (state) => state.reportCustomersOverview;
export const getReportOverviewState = (state) => state.reportOverview;
export const getReportRepeatCustomersState = (state) => state.reportRepeatCustomers;
export const getReportTopCustomersState = (state) => state.reportTopCustomers;
export const getDeliveryMetricsState = (state) => state.deliveryMetrics;

export const getReportsSalesByStaff = createSelector(
  getReportSalesByStaffState,
  (state) => state.data,
);
export const getReportsTipsByStaff = createSelector(
  getReportTipsByStaffState,
  (state) => state.data,
);
export const getReportsSalesOverview = createSelector(
  getReportSalesOverviewState,
  (state) => state.data,
);
export const getReportsSalesByArea = createSelector(
  getReportSalesByAreaState,
  (state) => state.data,
);
export const getReportsSalesByItem = createSelector(
  getReportSalesByItemState,
  (state) => state.data,
);
export const getReportsSalesByTable = createSelector(
  getReportSalesByTableState,
  (state) => state.data,
);
export const getReportsSalesByCategory = createSelector(
  getReportSalesByTableState,
  (state) => state.data,
);

export const getReportCustomersOverview = createSelector(
  getReportCustomersOverviewState,
  (state) => state.data,
);
export const getReportOverview = createSelector(getReportOverviewState, (state) => state.data);
export const getReportRepeatCustomers = createSelector(
  getReportRepeatCustomersState,
  (state) => state.data,
);
export const getReportTopCustomers = createSelector(
  getReportTopCustomersState,
  (state) => state.data,
);

export const getDeliveryMetrics = createSelector(getDeliveryMetricsState, (state) => state.data);
