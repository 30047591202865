import { createSlice } from '@reduxjs/toolkit';
import {
  asyncInitialState as initialState,
  createAsyncAction,
  createAsyncReducerHandlers,
} from '../utils/async';
import {
  fetchAvailableTerminalDevices as fetchAvailableTerminalDevicesApi,
  fetchTerminalDevices as fetchTerminalDevicesApi,
  fetchTerminalDevice as fetchTerminalDeviceApi,
  updateTerminalDevice as updateTerminalDeviceApi,
  deleteTerminalDevice as deleteTerminalDeviceApi,
} from '../../api/terminals';

export const fetchAvailableTerminalDevices = createAsyncAction(
  'fetch-available-terminal-devices',
  fetchAvailableTerminalDevicesApi,
);
export const fetchTerminalDevices = createAsyncAction(
  'fetch-terminal-devices',
  fetchTerminalDevicesApi,
);
export const fetchTerminalDevice = createAsyncAction(
  'fetch-terminal-device',
  fetchTerminalDeviceApi,
);
export const updateTerminalDevice = createAsyncAction(
  'update-terminal-device',
  updateTerminalDeviceApi,
);
export const deleteTerminalDevice = createAsyncAction(
  'delete-terminal-device',
  deleteTerminalDeviceApi,
);

const availableTerminalDevicesSlice = createSlice({
  name: 'availableTerminalDevices',
  initialState,
  reducers: {
    clearAvailableTerminalDevices(state) {
      delete state.data;
    },
  },
  extraReducers: createAsyncReducerHandlers(fetchAvailableTerminalDevices),
});

const terminalDevicesSlice = createSlice({
  name: 'terminalDevices',
  initialState,
  reducers: {
    clearTerminalDevices(state) {
      delete state.data;
    },
  },
  extraReducers: createAsyncReducerHandlers(fetchTerminalDevices),
});

const terminalDeviceSlice = createSlice({
  name: 'terminalDevice',
  initialState,
  reducers: {
    clearTerminalDevice(state) {
      delete state.data;
    },
  },
  extraReducers: {
    ...createAsyncReducerHandlers(fetchTerminalDevice),
    ...createAsyncReducerHandlers(updateTerminalDevice),
    ...createAsyncReducerHandlers(deleteTerminalDevice),
  },
});

export const { clearTerminalDevice } = terminalDeviceSlice.actions;
export const { clearTerminalDevices } = terminalDevicesSlice.actions;
export const { clearAvailableTerminalDevices } = availableTerminalDevicesSlice.actions;

export const { reducer: availableTerminalDevices } = availableTerminalDevicesSlice;
export const { reducer: terminalDevices } = terminalDevicesSlice;
export const { reducer: terminalDevice } = terminalDeviceSlice;
