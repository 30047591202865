import React, { useContext } from 'react';
import isEmpty from 'lodash.isempty';
import { Box, Link, Typography } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { Formik } from 'formik';

import StepButton from '../StepButton';
import StepQuestion from '../StepQuestion';
import SignupContext from '../SignupContext';
import StepReviewField from '../StepReviewField';
import { signupFields } from '../signupFields';
import validationSchema from './validationSchema';
import StepSwitch from '../StepSwitch';

const StepFieldsWrapper = withStyles(Box, (theme) => ({
  root: {
    padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
    backgroundColor: theme.palette.primary.light,
    borderRadius: theme.spacing(1),
  },
}));

const StepVenueDetailsThree = () => {
  const {
    formValues,
    handleNextStep,
    handleSaveToLocalStorage,
    handleOnSubmit,
    handleNotifySlack,
    handleGAEventCompleteStep,
  } = useContext(SignupContext);
  const {
    address,
    postcode,
    city,
    country,
    phone,
    email,
    name,
    businessName,
    businessType,
    isBusinessAddressSameAsAddress,
    businessAddress,
    businessPostcode,
    businessCity,
  } = formValues;
  const initialValues = {
    [signupFields.termsOfService]: formValues.termsOfService,
  };
  const concatentedAddress = `${address}, ${city}, ${postcode}, ${country}`;
  const concatentedBusinessAddress = `${businessAddress}, ${businessCity}, ${businessPostcode}, ${country}`;
  const finalBusinessAddress =
    isBusinessAddressSameAsAddress === 'no' ? concatentedBusinessAddress : concatentedAddress;

  const handleSaveAndContinue = async (setSubmitting) => {
    setSubmitting(true);
    const isVenueCreated = await handleOnSubmit();
    const isProduction = process.env.REACT_APP_STAGE === 'production';

    if (isVenueCreated) {
      handleSaveToLocalStorage();
      handleNextStep();
      handleGAEventCompleteStep();

      if (isProduction) {
        handleNotifySlack();
      }
    }
    setSubmitting(false);
  };

  return (
    <>
      <StepQuestion
        question="Details review"
        description="Here's a review of the details you've provided:"
      />
      <StepFieldsWrapper>
        <StepReviewField value={name} label="Venue name" step={1} />
        <StepReviewField
          value={concatentedAddress}
          label="Venue address"
          step={2}
          key={concatentedAddress}
        />
        <StepReviewField value={businessName} label="Business name" step={1} />
        <StepReviewField value={email} label="Email" step={4} />
        <StepReviewField value={phone} label="Mobile number" step={4} />
        <StepReviewField value={businessType} label="Business structure" step={6} />
        <StepReviewField value={finalBusinessAddress} label="Business address" step={7} />
      </StepFieldsWrapper>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnMount
        enableReinitialize
      >
        {({ errors, isValid, isSubmitting, setSubmitting }) => {
          const isFormValid = isValid && isEmpty(errors) && !isSubmitting;

          return (
            <>
              <StepSwitch
                name={signupFields.termsOfService}
                label="Accept terms &amp; conditions"
              />
              <p>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://serve.sessions.co.uk/termsandconditions"
                >
                  terms &amp; conditions
                </a>
              </p>

              <StepButton
                text="Save and continue"
                disabled={!isFormValid}
                onClick={() => handleSaveAndContinue(setSubmitting)}
              />
            </>
          );
        }}
      </Formik>

      <Box mt={4} mb={10}>
        <Typography variant="body2" color="textSecondary">
          By clicking “Save and continue” I hereby confirm, as a signatory of the business, that the
          information provided is correct and true. I also confirm that neither I or any of the
          business’ decision-maker:
        </Typography>
        <ul>
          <li>
            have or have had a prominent public function in a state or international organisation
          </li>
          <li>
            are a family member of a person that has or has had a prominent public function in a
            state or international organisation: or
          </li>
          <li>
            are a close associate of a person that has or has had a prominent public function in a
            state or international organisation.
          </li>
        </ul>
        <Typography variant="body2" color="textSecondary">
          We will now perform a credit check on the company and in some cases an individual credit
          check on you. See our
          <Link href="https://serve.sessions.co.uk/privacypolicy" target="_blank">
            {` Privacy Policy `}
          </Link>
          for more information.
        </Typography>
      </Box>
    </>
  );
};

export default StepVenueDetailsThree;
