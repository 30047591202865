import { createSelector } from '@reduxjs/toolkit';
import { ascend, prop, sortWith } from 'ramda';

const deviceSort = sortWith([ascend(prop('deviceId'))]);
export const getDevicesState = (state) => state.devices;
export const getAvailableDevicesState = (state) => state.availableDevices;
export const getAvailableDeviceCapabilitiesState = (state) => state.availableDeviceCapabilities;

export const getDeviceState = (state) => state.device;

export const getDevices = createSelector(
  getDevicesState,
  (devicesState) => devicesState.data && deviceSort(devicesState.data),
);

export const getAvailableDeviceCapabilities = createSelector(
  getAvailableDeviceCapabilitiesState,
  (availableDeviceCapabilitiesState) => availableDeviceCapabilitiesState.data,
);

export const getDevicesObj = createSelector(getDevicesState, (devices) => {
  if (!devices.data) return null;
  return devices.data.reduce((acc, device) => {
    const { deviceId, name, iccid, destination, imei } = device;
    return { [deviceId]: { deviceId, name, destination, iccid, imei }, ...acc };
  }, {});
});
