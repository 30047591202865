import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { Box, Button } from '@mui/material';
import { MdAdd } from 'react-icons/md';

import { makeStyles } from '@mui/styles';
import {
  createBrandsIngredient,
  fetchBrandsIngredients,
} from '../../../store/brands/brandsIngredients';

import withVenue from '../../../hoc/withVenue';
import { useNotifications } from '../../../shared/contexts/Notifications/useNotifications';
import { getErrorMessage } from '../../../shared/utils/errors';
import CustomDialog from '../../../components/CustomDialog';
import useRoles from '../../../hooks/useRoles';
import BrandsIngredientForm from '../../../components/Brands/BrandsIngredientForm';

const useStyles = makeStyles((theme) => ({
  button: {
    textWrap: 'nowrap',
    paddingLeft: '10px',
    marginLeft: 'auto',
    [theme.breakpoints.up('xl')]: {
      marginLeft: 'unset',
    },
  },
}));

const CreateIngredientButton = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [ingredientData, setIngredientData] = useState(null);
  const [formAction, setFormAction] = useState('');
  const { showErrorNotification, showSuccessNotification } = useNotifications();
  const { isRoleAtLeastManager } = useRoles();

  const handleOnSubmit = async (values) => {
    try {
      await dispatch(createBrandsIngredient(values));
      await dispatch(fetchBrandsIngredients());
      showSuccessNotification('Ingredient has been added successfully');
      setIsDialogOpen(false);
    } catch (e) {
      showErrorNotification(getErrorMessage(e));
      setIsDialogOpen(false);
    }
  };

  const handleOpenDialog = () => {
    setIngredientData(null);
    setFormAction('create');
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  return (
    <>
      <Box>
        <aside>
          <CustomDialog
            isDialogOpen={isDialogOpen}
            handleCloseDialog={handleCloseDialog}
            title="Create Ingredient"
          >
            <BrandsIngredientForm
              formAction={formAction}
              ingredientData={ingredientData}
              onSubmit={handleOnSubmit}
              onCancel={handleCloseDialog}
            />
          </CustomDialog>
        </aside>
      </Box>
      <Box className={classes.button}>
        <Button
          variant="contained"
          color="primary"
          disabled={!isRoleAtLeastManager()}
          onClick={handleOpenDialog}
          startIcon={<MdAdd />}
        >
          Create Ingredient
        </Button>
      </Box>
    </>
  );
};

export default withVenue(CreateIngredientButton);
