const getFactors = (term) => {
  const matches = term.match(/(-?[0-9]+)-(-?[0-9]+)/);
  if (!matches) return { first: term };
  return { first: Number(matches[1]), last: Number(matches[2]) };
};

const expandTerm = (term) => {
  const factors = getFactors(term);
  if (!factors.last) return [factors.first];
  const range = [];
  for (let n = factors.first; n <= factors.last; n += 1) {
    range.push(n);
  }
  return range;
};

// create an array of numbers if there are numbers expressed in a list and ranges.
// e.g. 1,2,5-10
// if just a no numneric string with no commas then it should just return this string as the first in an array
export default (rangeExpr) => {
  let parsed = [];
  rangeExpr.split(',').forEach((t) => {
    const expanded = expandTerm(t);
    parsed = [...parsed, ...expanded];
  });
  return parsed;
};
