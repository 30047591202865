import React, { forwardRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import KebabMenu from '../../components/KebabMenu';
import { useNotifications } from '../../shared/contexts/Notifications/useNotifications';
import { getErrorMessage } from '../../shared/utils/errors';
import { deleteSalesArea, fetchSalesAreas, updateSalesArea } from '../../store/salesAreas';
import { getSalesAreasState } from '../../store/salesAreas/selectors';
import AreaNameDialog from './AreaNameDialog';
import DeleteAreaDialog from './DeleteAreaDialog';

const AreaMenu = forwardRef(({ salesAreaId }, ref) => {
  const { deleteRef, renameRef } = ref || {};
  const dispatch = useDispatch();
  const history = useHistory();

  const { data: salesAreas = [] } = useSelector(getSalesAreasState);
  const salesArea = salesAreas ? salesAreas?.find((area) => area.salesAreaId === salesAreaId) : {};
  const { salesAreaName } = salesArea || {};

  const { showErrorNotification, showSuccessNotification } = useNotifications();

  const [renameDialogIsOpen, setRenameDialogIsOpen] = useState(false);
  const handleOpenRenameDialog = () => setRenameDialogIsOpen(true);
  const handleCloseRenameDialog = () => {
    setRenameDialogIsOpen(false);
  };
  const [deleteDialogIsOpen, setDeleteDialogIsOpen] = useState(false);
  const handleOpenDeleteDialog = () => setDeleteDialogIsOpen(true);
  const handleCloseDeleteDialog = () => {
    setDeleteDialogIsOpen(false);
  };

  const [modifyingAreaId, setModifyingAreaId] = useState(null);

  const handleRename = (areaId) => {
    setModifyingAreaId(areaId);
    handleOpenRenameDialog();
  };

  const handleDelete = (areaId) => {
    setModifyingAreaId(areaId);
    handleOpenDeleteDialog();
  };
  const handleRenameClick = () => {
    handleRename(salesAreaId);
  };
  const handleDeleteClick = () => {
    handleDelete(salesAreaId);
  };

  const deleteArea = () => {
    const localDeleteArea = async () => {
      try {
        await dispatch(deleteSalesArea(modifyingAreaId));
        await dispatch(fetchSalesAreas());
        showSuccessNotification('Sales area has been deleted successfully');
        history.push('/sales-areas');
      } catch (localError) {
        showErrorNotification(`Error: ${getErrorMessage(localError)}`);
      }
    };
    localDeleteArea();
  };

  const renameArea = (areaName) => {
    const localRenameArea = async () => {
      try {
        await dispatch(
          updateSalesArea({
            salesAreaId: modifyingAreaId,
            values: { salesAreaName: areaName },
          }),
        );
        await dispatch(fetchSalesAreas());
        showSuccessNotification('Sales area has been renamed successfully');
      } catch (localError) {
        showErrorNotification(`Error: ${getErrorMessage(localError)}`);
      }
    };
    localRenameArea();
  };
  return (
    <div>
      <KebabMenu
        items={[
          {
            name: 'Rename',
            handler: () => {
              handleRenameClick();
            },
          },
          {
            name: 'Delete',
            handler: () => {
              handleDeleteClick();
            },
          },
        ]}
      />
      <AreaNameDialog
        ref={renameRef}
        handleCloseDialog={handleCloseRenameDialog}
        dialogIsOpen={renameDialogIsOpen}
        submitDialog={renameArea}
        areaName={salesAreaName}
        type="rename"
      />
      <DeleteAreaDialog
        ref={deleteRef}
        handleCloseDialog={handleCloseDeleteDialog}
        dialogIsOpen={deleteDialogIsOpen}
        submitDialog={deleteArea}
        areaName={salesAreaName}
      />
    </div>
  );
});

export default AreaMenu;
