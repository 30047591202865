import React from 'react';
import { useDispatch } from 'react-redux';
import {
  Card,
  CardActions,
  CardContent,
  Button,
  FormControlLabel,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Formik, Form, Field } from 'formik';
import { Switch, TextField } from 'formik-mui';
import moment from 'moment-timezone';
import * as Yup from 'yup';
import { DatePicker } from '@mui/x-date-pickers';

import withVenue from '../../hoc/withVenue';

import useRoles from '../../hooks/useRoles';

import { useNotifications } from '../../shared/contexts/Notifications/useNotifications';
import { getErrorMessage } from '../../shared/utils/errors';

import { createVoucher } from '../../store/vouchers';
import PhoneField from '../PhoneField';

const useStyles = makeStyles(() => ({
  form: {
    maxWidth: 600,
  },
}));

const VoucherForm = ({ handleCloseDialog }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { isAdmin, isSuperAdmin } = useRoles();
  const isAllowedToCreatePrepaidVouchers = isAdmin() || isSuperAdmin();
  const { showErrorNotification, showSuccessNotification } = useNotifications();
  const tomorrow = moment().add(1, 'days').startOf('day');

  const initialValues = {
    email: '',
    name: '',
    phone: '',
    countryCode: '+44',
    voucher: {
      quantity: 1,
      amount: 1,
      description: '',
      customExpiry: false,
      startDate: new Date(),
      endDate: tomorrow,
    },
    isPrepaid: false,
    isDeposit: false,
  };

  const VoucherSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
    email: Yup.string().email('Invalid email').required('Required'),
    phone: Yup.string().required('Required'),
    countryCode: Yup.string().required('Required'),
    voucher: Yup.object().shape({
      quantity: Yup.number().min(1, 'Minimum 1').required('Required'),
      amount: Yup.number().min(1, 'Minimum deposit amount is £1').required('Required'),
      description: Yup.string().optional(),
      customExpiry: Yup.boolean().optional(),
      startDate: Yup.date().when('customExpiry', {
        is: true,
        then: Yup.date().required('Required'),
      }),
      endDate: Yup.date().when('customExpiry', {
        is: true,
        then: Yup.date().required('Required'),
      }),
    }),
  });

  const onSubmit = async (values) => {
    try {
      const noTrailingZeroPhone = values.phone.replace(/^0+/, '');
      const finalPhone = `${values.countryCode}${noTrailingZeroPhone}`;
      const transformedPayload = {
        ...values,
        phone: finalPhone,
      };
      const hasNoCustomExpiry = !values.voucher.customExpiry;
      const hasNoDescription = !values.voucher.description;

      delete transformedPayload.countryCode;

      if (hasNoCustomExpiry) {
        delete transformedPayload.voucher.startDate;
        delete transformedPayload.voucher.endDate;
      }

      if (hasNoDescription) {
        delete transformedPayload.voucher.description;
      }
      await dispatch(createVoucher(transformedPayload));
      handleCloseDialog();
      showSuccessNotification('Voucher has been created successfully');
    } catch (error) {
      showErrorNotification(getErrorMessage(error));
    }
  };

  return (
    <Card className={classes.form}>
      <Formik
        onSubmit={onSubmit}
        validationSchema={VoucherSchema}
        initialValues={initialValues}
        validateOnMount={false}
      >
        {({ setFieldValue, values, setFieldTouched, dirty, isValid }) => {
          const hasCustomExpiry = values.voucher.customExpiry;
          const total = values.voucher.amount * values.voucher.quantity;
          const formattedTotal = total.toLocaleString('en-GB', {
            style: 'currency',
            currency: 'GBP',
          });

          return (
            <Form>
              <CardContent>
                <section>
                  <Field
                    component={TextField}
                    name="name"
                    label="Name"
                    variant="outlined"
                    fullWidth
                    required
                    margin="normal"
                  />
                  <Field
                    component={TextField}
                    name="email"
                    label="Email"
                    variant="outlined"
                    fullWidth
                    required
                    margin="normal"
                  />

                  <PhoneField />
                  <Field
                    component={TextField}
                    name="voucher.quantity"
                    label="Number of voucher codes required"
                    type="number"
                    variant="outlined"
                    fullWidth
                    required
                    margin="normal"
                    inputProps={{
                      min: 1,
                      step: '1',
                    }}
                  />
                  <Field
                    component={TextField}
                    name="voucher.amount"
                    label="Amount per voucher"
                    type="number"
                    variant="outlined"
                    fullWidth
                    required
                    margin="normal"
                    inputProps={{
                      min: 1,
                      step: '0.1',
                    }}
                  />
                  <Typography variant="body2" color="textSecondary">
                    Total deposit: <strong>{formattedTotal}</strong>
                  </Typography>
                  <Field
                    component={TextField}
                    name="voucher.description"
                    label="Additional notes"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                  />
                  {isAllowedToCreatePrepaidVouchers && (
                    <FormControlLabel
                      control={
                        <Field
                          name="isPrepaid"
                          component={Switch}
                          type="checkbox"
                          color="primary"
                        />
                      }
                      label="Prepaid"
                    />
                  )}
                  <FormControlLabel
                    control={
                      <Field name="isDeposit" component={Switch} type="checkbox" color="primary" />
                    }
                    label="Deposit"
                  />
                  <FormControlLabel
                    control={
                      <Field
                        name="voucher.customExpiry"
                        component={Switch}
                        type="checkbox"
                        color="primary"
                      />
                    }
                    label="Custom Expiry"
                  />
                  {hasCustomExpiry && (
                    <>
                      <Field
                        component={DatePicker}
                        timezone="system"
                        name="voucher.startDate"
                        label="Start Date"
                        variant="outlined"
                        format="DD/MM/YYYY"
                        fullWidth
                        required
                        margin="normal"
                        value={moment.utc(values.voucher.startDate)}
                        onChange={async (date) => {
                          await setFieldValue('voucher.startDate', date);
                          setFieldTouched('voucher.startDate', true);
                        }}
                      />
                      <Field
                        component={DatePicker}
                        name="voucher.endDate"
                        timezone="system"
                        label="End Date"
                        variant="outlined"
                        format="DD/MM/YYYY"
                        fullWidth
                        required
                        value={moment.utc(values.voucher.endDate)}
                        margin="normal"
                        onChange={async (date) => {
                          await setFieldValue('voucher.endDate', date);
                          setFieldTouched('voucher.endDate', true);
                        }}
                      />
                    </>
                  )}
                </section>
              </CardContent>
              <CardActions className={classes.cardActions}>
                <Button onClick={handleCloseDialog}>Cancel</Button>

                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={!(dirty && isValid)}
                >
                  Send invoice
                </Button>
              </CardActions>
            </Form>
          );
        }}
      </Formik>
    </Card>
  );
};

export default withVenue(VoucherForm);
