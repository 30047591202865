export const ADJUSTMENT_TYPES = [
  {
    value: 'TECH_FEE',
    label: 'Tech fee',
  },
  {
    value: 'ONBOARDING_FEE',
    label: 'Onboarding fee',
  },
  {
    value: 'PAYMENT_CORRECTION',
    label: 'Payment correction',
  },
  {
    value: 'MARKETING_ADS',
    label: 'Marketing ads',
  },
  {
    value: 'PACKAGING',
    label: 'Packaging',
  },
  {
    value: 'MYSTERY_SHOPPER',
    label: 'Mystery shopper',
  },
  {
    value: 'OTHER',
    label: 'Other',
  },
];

export const SCHEDULE_TYPES = [
  {
    value: 'ONE_OFF',
    label: 'One time',
  },
  {
    value: 'RECURRING',
    label: 'Recurring weekly',
  },
];

export const ADJUSTMENT_TABLE_COLUMNS = [
  'ADJUSTMENT',
  'AMOUNT',
  'TYPE',
  'OCCURENCE',
  'STATUS',
  'START DATE',
  'END DATE',
  'LEFT TO PAY',
  'NAME',
  'DESCRIPTION',
];
