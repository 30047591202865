const momentTZ = require('moment-timezone');

const getCloseTime = (string, am, timezone) => {
  const date = momentTZ.tz(`${string} ${am}`, `LT`, timezone);
  return am === 'AM' ? date.add(24, 'hours') : date;
};

const getOpenTime = (string, am, timezone) => momentTZ.tz(`${string} ${am}`, `LT`, timezone);

// eslint-disable-next-line import/prefer-default-export
export const enrichStation = (station, isSingle = false) => {
  const { openings: openingTimes, timezone } = station;

  // eslint-disable-next-line no-unused-vars
  const { openings, active, ..._station } = station;

  const now = momentTZ().tz(timezone);
  let isOpen24H = false;
  const day = now.format('dddd');
  let isOpen = false;
  if (openingTimes.length === 0) {
    return { ..._station, ...{ isOpen, isOpen24H } };
  }
  const today = openingTimes.find((open) => open.startsWith(day));
  const isClosed = today.includes('Closed');
  isOpen24H = today.includes('Open 24 hours');
  if (isClosed) {
    return { ..._station, ...{ isOpen: false, isOpen24H } };
  }
  if (isOpen24H) {
    isOpen = true;
    if (isSingle === true) {
      Object.assign(_station, {
        openTime: '00:00',
        closeTime: '00:00',
      });
    }
  } else {
    const hoursTxt = today.replace(`${day}: `, '').trim();
    const splitted = hoursTxt.split(' ');

    const openDayTime =
      splitted.length === 5
        ? getOpenTime(splitted[0], splitted[1], timezone)
        : getOpenTime(splitted[0], splitted[3], timezone);
    const closeDayTime =
      splitted.length === 5
        ? getCloseTime(splitted[3], splitted[4], timezone)
        : getCloseTime(splitted[2], splitted[3], timezone);

    const isOpenRaw = !isClosed && now.isAfter(openDayTime) && now.isBefore(closeDayTime);
    isOpen = !!isOpenRaw;

    if (isSingle === true) {
      Object.assign(_station, {
        openTime: openDayTime.format('HH:mm'),
        closeTime: closeDayTime.format('HH:mm'),
      });
    }
  }

  return { ..._station, ...{ isOpen, isOpen24H } };
};
