import React, { useState } from 'react';
import { Button, CircularProgress } from '@mui/material';
import DriveFolderUploadIcon from '@mui/icons-material/OpenInNew';
import { useSelector, useDispatch } from 'react-redux';
import { getIdToken } from '../../cognito/sessionTokens';
import { useNotifications } from '../../shared/contexts/Notifications/useNotifications';
import { getVenueState } from '../../store/venues/selectors';
import { fetchVenueMenu } from '../../store/venueMenus';
import { fetchBrandsVenuesAssociations } from '../../store/brands/brandsVenueAssociations';

const PushOtterMenu = ({
  otterStoreId,
  menuName,
  venueId,
  isVenueAssociation = false,
  brandId,
}) => {
  const dispatch = useDispatch();
  const { showErrorNotification, showSuccessNotification } = useNotifications();
  const { data: venue } = useSelector(getVenueState);
  const idToken = getIdToken();
  const [loading, setLoading] = useState(false);

  const apiUrl = process.env.REACT_APP_API_URI;
  let pushUrl = `${apiUrl}/venues/${venue.venueId}/menus/${menuName}/push`;

  if (isVenueAssociation && venueId) {
    pushUrl = `${apiUrl}/venues/${venueId}/menus/${menuName}/push`;
  }

  const handleClick = async () => {
    setLoading(true);
    const response = await fetch(pushUrl, {
      method: 'POST',
      headers: {
        Authorization: idToken,
        Accept: 'application/json',
      },
    })
      .then(async (res) => {
        setLoading(false);
        const resp = await res.json();
        if (res.status === 404) {
          showErrorNotification('Menu not found');
          return null;
        }
        if (res.status === 400 || !res.ok) {
          showErrorNotification(`Failed to push menu to Otter: ${resp.message}`);
          return null;
        }
        if (isVenueAssociation) {
          dispatch(fetchBrandsVenuesAssociations(brandId));
        } else {
          dispatch(fetchVenueMenu(menuName));
        }
        showSuccessNotification('Menu pushed to Otter. Check back for updates');
        return null;
      })
      .catch((err) => {
        setLoading(false);
        showErrorNotification(err.message);
      });
    return response;
  };
  return (
    <Button
      variant="contained"
      color="primary"
      component="label"
      id={otterStoreId}
      fullWidth
      onClick={handleClick}
      startIcon={
        loading ? <CircularProgress size={20} color="inherit" /> : <DriveFolderUploadIcon />
      }
    >
      Push Menu
    </Button>
  );
};
export default PushOtterMenu;
