import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const useStyles = makeStyles()((theme, { fontWeight, fontColor }) => ({
  customAccordion: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    '&::before': {
      display: 'none',
    },
  },
  customAccordionSummary: {
    '& .MuiAccordionSummary-content': {
      '& p': {
        fontWeight: fontWeight || 'bold',
        color: fontColor || 'black',
      },
    },
  },
}));

const StepAccordion = ({
  title,
  description,
  defaultExpanded,
  fontWeight = 'bold',
  fontColor = 'black',
  ...props
}) => {
  const { classes } = useStyles({ fontWeight, fontColor });

  return (
    <Accordion defaultExpanded={defaultExpanded} className={classes.customAccordion} {...props}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={title}
        id={title}
        className={classes.customAccordionSummary}
      >
        <Typography>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>{description}</Typography>
      </AccordionDetails>
    </Accordion>
  );
};

export default StepAccordion;
