import React from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { nanoid } from 'nanoid';
import { TextField } from 'formik-mui';
import { makeStyles } from '@mui/styles';
import {
  Card,
  CardContent,
  FormGroup,
  MenuItem,
  Autocomplete,
  TextField as MuiTextField,
} from '@mui/material';
import { useSelector } from 'react-redux';
import UniversalSave from '../UniversalSave';

import { getAvailableDevicesState } from '../../store/devices/selectors';

const useStyles = makeStyles(() => ({
  card: {
    borderRadius: '8px',
  },
}));

const destinationTypes = ['DRINKS', 'FOOD', 'ALL'];

const DeviceForm = ({ initialValues, onSubmit }) => {
  const classes = useStyles();
  const availableDevices = useSelector(getAvailableDevicesState);
  const { data } = availableDevices;

  const DeviceSchema = Yup.object().shape({
    deviceId: Yup.string().min(3, 'Too Short!').required('Please choose a device'),
    destination: Yup.string().min(3, 'Too Short!').required('Please choose a destination'),
  });

  return (
    <Card className={classes.card}>
      <Formik onSubmit={onSubmit} validationSchema={DeviceSchema} initialValues={initialValues}>
        {({ values, dirty, setFieldValue, isValid, resetForm, errors }) => (
          <>
            <UniversalSave
              isValid={isValid}
              errors={errors}
              dirty={dirty}
              onSave={() => onSubmit(values)}
              onDiscard={resetForm}
            />
            <Form>
              <CardContent>
                <section>
                  <FormGroup>
                    {data && (
                      <Autocomplete
                        fullWidth
                        options={data}
                        getOptionDisabled={(option) => option.name !== 'NONE'}
                        getOptionLabel={(option) =>
                          option.name === 'NONE'
                            ? option.deviceId
                            : `${option.deviceId} - ${option.name}`
                        }
                        renderInput={(params) => <MuiTextField {...params} label="Select Device" />}
                        groupBy={(option) =>
                          option.name !== 'NONE' ? 'Attached to Venue' : 'Available Devices'
                        }
                        onChange={(_, value) => {
                          setFieldValue('deviceId', value.deviceId);
                        }}
                      />
                    )}
                  </FormGroup>
                </section>
                <section>
                  <FormGroup>
                    <Field
                      component={TextField}
                      select
                      fullWidth
                      name="destination"
                      label="Select Destination"
                      variant="outlined"
                      margin="normal"
                      required
                      onChange={({ target }) => {
                        setFieldValue('destination', target.value);
                      }}
                    >
                      {destinationTypes.map((destination) => (
                        <MenuItem value={destination} key={nanoid()}>
                          {destination}
                        </MenuItem>
                      ))}
                    </Field>
                  </FormGroup>
                </section>
              </CardContent>
            </Form>
          </>
        )}
      </Formik>
    </Card>
  );
};

export default DeviceForm;
