import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router';

import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';
import { getVenueMenuItemsState } from '../../store/venueMenuItems/selectors';
import { clearMenuItems, createMenuItem } from '../../store/menuItems';
import {
  createMenuGroupItem,
  fetchVenueMenuItems,
  updateVenueMenuItems,
} from '../../store/venueMenuItems';

import { getErrorMessage } from '../../shared/utils/errors';
import { useNotifications } from '../../shared/contexts/Notifications/useNotifications';
import MenuItemForm from '../../components/MenuItemForm';
import Page from '../../components/Page';
import withVenue from '../../hoc/withVenue';
import shouldLoad from '../../shared/utils/shouldLoad';
import BackArrow from '../../components/BackArrow';

const useStyles = makeStyles(() => ({
  heading: {
    margin: '15px 0',
  },
}));

const AddMenuItem = ({ redirect }) => {
  const history = useHistory();
  const classes = useStyles();
  const { showErrorNotification, showSuccessNotification } = useNotifications();
  const dispatch = useDispatch();
  const venueMenuItemsState = useSelector(getVenueMenuItemsState);
  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();
  const itemName = query.get('itemName');
  const menuName = query.get('menuName');
  const groupId = query.get('groupId');

  const handleOnSubmit = async (values) => {
    try {
      const { itemId } = await dispatch(createMenuItem(values));

      if (menuName && groupId) {
        dispatch(createMenuGroupItem({ groupId, itemId }));
        await dispatch(updateVenueMenuItems());
      }

      showSuccessNotification('Product has been created successfully');
      if (itemId) {
        history.push(`/menu-items/${itemId}`);
      } else {
        redirect();
      }
    } catch (error) {
      showErrorNotification(getErrorMessage(error));
    }
  };

  useEffect(() => {
    if (shouldLoad(venueMenuItemsState) && menuName) dispatch(fetchVenueMenuItems(menuName));
  }, [dispatch, venueMenuItemsState, menuName]);

  return (
    <Page>
      <header>
        <BackArrow redirect={redirect} text="Items" />
        <Typography className={classes.heading} variant="h2" component="h1">
          Add Product
        </Typography>
      </header>
      <MenuItemForm
        formAction="create"
        initialValues={{
          available: true,
          category: '',
          isAlcoholic: false,
          type: 'DRINK',
          description: '',
          itemName: itemName || '',
          label: itemName || '',
          vatRate: 20,
          discountId: '',
          itemOptions: [{ optionName: '', optionPrice: '', optionCalories: undefined }],
        }}
        onSubmit={handleOnSubmit}
        readOnlyFields={['available']}
      />
    </Page>
  );
};

export default withVenue(AddMenuItem, '/items', clearMenuItems);
