import { createSelector } from '@reduxjs/toolkit';
import { ascend, prop, sortWith } from 'ramda';

const optionSort = sortWith([ascend(prop('type')), ascend(prop('optionName'))]);
export const getOptionsState = (state) => state.options;

export const getOptionState = (state) => state.option;

export const getOptions = createSelector(
  getOptionsState,
  (optionsState) => optionsState.data && optionSort(optionsState.data),
);

export const getOptionsObj = createSelector(getOptionsState, (options) => {
  if (!options.data) return null;
  return options.data.reduce((acc, option) => {
    const { optionId, optionName, type } = option;
    return { [optionId]: { optionId, optionName, type }, ...acc };
  }, {});
});
