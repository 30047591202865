import React from 'react';

import { useParams } from 'react-router';
import { Typography } from '@mui/material';
import withVenue from '../../hoc/withVenue';
import TabContainer from '../../components/TabContainer';
import Vouchers from '../Vouchers';
import VoucherOrders from '../Orders/VoucherOrders';
import Page from '../../components/Page';

const VoucherTabs = () => {
  const { tabname } = useParams();

  return (
    <Page fullWidth>
      <Typography style={{ flexGrow: 1 }} variant="h2" component="h1">
        Vouchers
      </Typography>
      <TabContainer
        titles={['All vouchers', 'Voucher history']}
        defaultTab={tabname}
        path="/vouchers/"
      >
        <Vouchers />
        <VoucherOrders />
      </TabContainer>
    </Page>
  );
};

export default withVenue(VoucherTabs);
