import React, { forwardRef } from 'react';
import { Card, CardActions, CardContent, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { TextField } from 'formik-mui';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import CustomDialog from '../../components/CustomDialog';

const useStyles = makeStyles(() => ({
  heading: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

const AreaNameDialog = forwardRef(
  ({ handleCloseDialog, dialogIsOpen, submitDialog, areaName = '', type }, ref) => {
    const classes = useStyles();
    const isRename = type === 'rename';

    const handleSubmit = (values) => {
      const { areaName: updatedAreaName } = values;
      handleCloseDialog();
      submitDialog(updatedAreaName);
    };
    const AreaNameSchema = Yup.object().shape({
      areaName: Yup.string().min(3).max(25).required(),
    });
    return (
      <CustomDialog
        title={isRename ? 'Rename Area' : 'Create Area'}
        isDialogOpen={dialogIsOpen}
        handleCloseDialog={handleCloseDialog}
        ref={ref}
      >
        <Card className={classes.card}>
          <Formik
            onSubmit={handleSubmit}
            validationSchema={AreaNameSchema}
            initialValues={{ areaName }}
          >
            {({ dirty }) => (
              <Form>
                <CardContent>
                  <Field
                    component={TextField}
                    fullWidth
                    name="areaName"
                    label="Area name - max 25 characters"
                    variant="outlined"
                    margin="normal"
                    required
                    InputProps={{ autoFocus: true }}
                  />
                </CardContent>
                <CardActions className={classes.cardActions}>
                  <Button onClick={handleCloseDialog}>Close</Button>
                  <Button type="submit" variant="contained" color="primary" disabled={!dirty}>
                    Save
                  </Button>
                </CardActions>
              </Form>
            )}
          </Formik>
        </Card>
      </CustomDialog>
    );
  },
);

export default AreaNameDialog;
