import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { Typography, Button, Grid, Box, Dialog } from '@mui/material';
import { KeyboardArrowUp, KeyboardArrowDown, Edit } from '@mui/icons-material';

import Page from '../../../components/Page';
import MenuGroups from '../../../components/Brands/builderMenu/MenuGroups';
import CreateMenuGroupButton from '../../../components/Brands/builderMenu/CreateMenuGroupButton';

import withVenue from '../../../hoc/withVenue';
import BackArrow from '../../../components/BackArrow';
import shouldLoad from '../../../shared/utils/shouldLoad';
import useRoles from '../../../hooks/useRoles';
import PageHeader from '../../../components/PageHeader';

import {
  clearBuilderMenu,
  clearBuilderMenus,
  fetchBuilderMenu,
  patchBuilderMenu,
} from '../../../store/brands/builderMenu/builderMenus';
import {
  fetchBuilderMenuItems,
  setBuilderMenuItems,
} from '../../../store/brands/builderMenu/builderMenuItems';
import { getBuilderMenuState } from '../../../store/brands/builderMenu/builderMenus/selectors';
import { clearBrandsMenuItems, fetchBrandsMenuItems } from '../../../store/brands/brandsMenuItems';
import { getBrandsMenuItemsState } from '../../../store/brands/brandsMenuItems/selectors';
import { getBuilderMenuItemsState } from '../../../store/brands/builderMenu/builderMenuItems/selectors';
import VenueAssociation from '../../../components/Brands/VenueAssociation';
import { clearBrandsVenuesAssociations } from '../../../store/brands/brandsVenueAssociations';
import EditDescription from './EditDescription';
import { useNotifications } from '../../../shared/contexts/Notifications/useNotifications';
import { getErrorMessage } from '../../../shared/utils/errors';

const useStyles = makeStyles((theme) => ({
  pageHeader: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  associateBtnWrapper: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column-reverse',
      '& > :first-child': {
        marginTop: theme.spacing(1),
      },
    },
  },
  builderControlHeading: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('lg')]: {
      display: 'flex',
    },
  },
  heading: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
    },
  },
  info: {
    marginRight: 30,
  },
  warning: {
    marginTop: 15,
    marginBottom: 15,
  },
  editDescriptionContainer: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  editDescriptionActions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(1),
  },
}));

const BrandsMenu = ({ redirect }) => {
  const [showVenueAssociationDialog, setShowVenueAssociationDialog] = useState(false);
  const [editDescription, setEditDescription] = useState(false);
  const { showErrorNotification, showSuccessNotification } = useNotifications();
  const { menuId } = useParams();
  const menuName = 'main';
  const { isAdmin, isRoleAtLeastManager } = useRoles();
  const permissionReadOnly = !isAdmin();
  const classes = useStyles();
  const dispatch = useDispatch();

  const builderMenuState = useSelector(getBuilderMenuState);
  const brandsMenuItemsState = useSelector(getBrandsMenuItemsState);
  const builderMenuItemsState = useSelector(getBuilderMenuItemsState);

  const {
    loading: builderMenuLoading,
    data: builderMenu,
    error: builderMenuError,
  } = builderMenuState;
  const { data: builderMenuItems, error: builderMenuItemsError } = builderMenuItemsState;
  const { data: brandsMenuItems, error: brandsMenuItemsError } = brandsMenuItemsState;

  const { variation, brandName, brandId, readonly, description, brandMenuId } = builderMenu || {};
  const isReadonly = readonly === true;
  const [expandAll, setExpandAll] = useState(false);

  useEffect(() => {
    if (shouldLoad(builderMenuState)) dispatch(fetchBuilderMenu(menuId));
    if (shouldLoad(brandsMenuItemsState)) dispatch(fetchBrandsMenuItems());
    if (shouldLoad(builderMenuItemsState)) dispatch(fetchBuilderMenuItems(menuId));
  }, [
    menuId,
    dispatch,
    readonly,
    builderMenuState,
    brandsMenuItemsState,
    builderMenuItemsState,
    brandId,
  ]);

  useEffect(() => {
    if (builderMenu) {
      dispatch(setBuilderMenuItems(builderMenu));
    }
  }, [dispatch, builderMenu]);

  const handleSave = async (descriptionValue) => {
    try {
      await dispatch(patchBuilderMenu(menuId, { description: descriptionValue }));

      setEditDescription(false);
      showSuccessNotification('Description has been updated successfully');
    } catch (error) {
      showErrorNotification(getErrorMessage(error));
    }
  };

  return (
    <>
      <PageHeader fullWidth className={classes.pageHeader}>
        <div className={classes.heading}>
          <BackArrow redirect={redirect} text="Menus" />
          {brandsMenuItems && (
            <>
              <Typography variant="h1" component="h1">
                {brandName} - {variation}
              </Typography>
              {editDescription ? (
                <EditDescription
                  classes={classes}
                  description={description}
                  handleCancelEdit={() => setEditDescription(false)}
                  handleSave={handleSave}
                />
              ) : (
                <>
                  <Typography variant="h2" component="h2">
                    {description}
                  </Typography>
                  <Button startIcon={<Edit />} onClick={() => setEditDescription(true)}>
                    Edit description
                  </Button>
                </>
              )}
            </>
          )}
        </div>
      </PageHeader>
      <Page
        fullWidth
        error={builderMenuItemsError || brandsMenuItemsError || builderMenuError}
        loading={builderMenuLoading}
      >
        {builderMenuItems && brandsMenuItems && (
          <>
            <Box component="header" className={classes.builderControlHeading}>
              <Grid container justifyContent="space-between" alignItems="flex-start">
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={expandAll ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                  onClick={() => setExpandAll(!expandAll)}
                  to="/menus/add"
                >
                  {expandAll ? 'Collapse All' : 'Expand All'}
                </Button>
                <div className={classes.associateBtnWrapper}>
                  <CreateMenuGroupButton
                    menuName={menuName}
                    disabled={isReadonly || !isRoleAtLeastManager() || permissionReadOnly}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setShowVenueAssociationDialog(true)}
                    style={{ marginLeft: 10 }}
                  >
                    Manage Associations
                  </Button>
                </div>
              </Grid>
            </Box>
            <MenuGroups expandAll={expandAll} isReadonly={isReadonly || permissionReadOnly} />
            {isAdmin() ||
              (!isReadonly && (
                <CreateMenuGroupButton
                  menuName={menuName}
                  disabled={!isRoleAtLeastManager() || permissionReadOnly}
                />
              ))}
          </>
        )}
      </Page>
      <Dialog
        open={showVenueAssociationDialog}
        onClose={() => setShowVenueAssociationDialog(false)}
        maxWidth="xl"
      >
        <VenueAssociation
          handleCloseDialog={() => setShowVenueAssociationDialog(false)}
          title={`Venues Associated with ${brandName} - ${variation}`}
          brandId={brandId}
          brandMenuId={brandMenuId}
        />
      </Dialog>
    </>
  );
};

export default withVenue(BrandsMenu, '/brand-menu-management/menus', [
  clearBuilderMenus,
  clearBuilderMenu,
  clearBrandsMenuItems,
  clearBrandsVenuesAssociations,
]);
