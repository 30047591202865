import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { Box, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import OptionForm from '../../components/OptionForm/index';
import { createOption, fetchOptions } from '../../store/options';

import withVenue from '../../hoc/withVenue';
import { useNotifications } from '../../shared/contexts/Notifications/useNotifications';
import CustomDialog from '../../components/CustomDialog';
import { getErrorMessage } from '../../shared/utils/errors';
import useRoles from '../../hooks/useRoles';

const useStyles = makeStyles(() => ({
  buttonClass: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginBottom: '10px',
  },
}));

const NewSizeButton = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [optionData, setOptionData] = useState(null);
  const [formAction, setFormAction] = useState('');
  const { showErrorNotification, showSuccessNotification } = useNotifications();
  const { isRoleAtLeastManager } = useRoles();

  const handleOnSubmit = async (values) => {
    try {
      await dispatch(createOption(values));
      showSuccessNotification('Size has been added successfully');
      setIsDialogOpen(false);
      dispatch(fetchOptions());
    } catch (e) {
      showErrorNotification(getErrorMessage(e));
      setIsDialogOpen(false);
    }
  };

  const handleOpenDialog = () => {
    setOptionData(null);
    setFormAction('create');
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const ProductSizeOptionForm = () => (
    <OptionForm
      formAction={formAction}
      optionData={optionData}
      onSubmit={handleOnSubmit}
      onCancel={handleCloseDialog}
    />
  );

  return (
    <>
      <Box className={classes.buttonClass}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleOpenDialog}
          disabled={!isRoleAtLeastManager()}
        >
          New Size
        </Button>
      </Box>
      <aside>
        <CustomDialog
          isDialogOpen={isDialogOpen}
          handleCloseDialog={handleCloseDialog}
          title="Create new product size"
        >
          <ProductSizeOptionForm />
        </CustomDialog>
      </aside>
    </>
  );
};

export default withVenue(NewSizeButton);
