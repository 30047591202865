import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Link, useHistory } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  Paper,
  Typography,
} from '@mui/material';
import moment from 'moment';
import { getTabState } from '../../../store/tabs/selectors';
import { fetchTab, refundTabPayment } from '../../../store/tabs';
import shouldLoad from '../../../shared/utils/shouldLoad';
import PageHeader from '../../../components/PageHeader';
import Page from '../../../components/Page';
import { useNotifications } from '../../../shared/contexts/Notifications/useNotifications';
import { getErrorMessage } from '../../../shared/utils/errors';

const useStyles = makeStyles(() => ({
  cardContent: {
    '& .MuiCardContent-root:last-child': {
      paddingBottom: 0,
    },
  },
}));

const TabPayment = () => {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { showSuccessNotification, showErrorNotification } = useNotifications();

  const { tabId, paymentId } = useParams();
  const { loading, data: tab, error } = useSelector(getTabState);

  const paymentIndex = tab?.orderPayments?.findIndex((payment) => payment.paymentId === paymentId);

  useEffect(() => {
    if (shouldLoad(getTabState)) {
      dispatch(fetchTab(tabId));
    }
  }, [tabId, dispatch]);

  const tabPayment = tab?.orderPayments[paymentIndex];

  const handleRefund = async () => {
    try {
      await dispatch(refundTabPayment(tabId, paymentId, tabPayment.amount));
      showSuccessNotification('Payment refunded successfully');
      history.push(`/tab/${tabId}`);
    } catch (e) {
      showErrorNotification(getErrorMessage(e));
    }
  };

  return (
    <>
      <PageHeader>
        {!loading && (
          <div className={classes.pageHeading}>
            <Breadcrumbs sx={{ mb: 1 }}>
              <Link to="/tabs">Tabs</Link>
              <Link to={`/tab/${tabId}`}>Tab {tab?.tabName}</Link>
              <Typography color="text.primary">Payment {paymentIndex + 1} </Typography>
            </Breadcrumbs>
            <Typography variant="h1">Payment {paymentIndex + 1}</Typography>
            {tabPayment.status !== 'REFUNDED' && (
              <Button onClick={() => handleRefund()} variant="contained" sx={{ mt: 2 }}>
                Refund
              </Button>
            )}
          </div>
        )}
      </PageHeader>
      <Page loading={loading} error={error}>
        <Grid container spacing={2} sx={{ mt: 3 }}>
          <Grid item xs={12} sm={6}>
            <Card component={Paper} className={classes.cardContent}>
              <CardContent sx={{ p: 0 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 2 }}>
                  <Typography>Name:</Typography>
                  <Typography>{`Tab ${tab?.tabName}`}</Typography>
                </Box>
                <Divider />
                <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 2 }}>
                  <Typography>Amount:</Typography>
                  <Typography>{`£${tabPayment?.amount}`}</Typography>
                </Box>
                <Divider />
                <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 2 }}>
                  <Typography>Staff name:</Typography>
                  <Typography>{tab?.staffName}</Typography>
                </Box>
                <Divider />
                <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 2 }}>
                  <Typography>Payment service:</Typography>
                  <Typography>{tabPayment?.service}</Typography>
                </Box>
                <Divider />
                <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 2 }}>
                  <Typography>Payment method:</Typography>
                  <Typography>{tabPayment?.service === 'STRIPE' ? 'CARD' : 'CASH'}</Typography>
                </Box>
                <Divider />

                <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 2 }}>
                  <Typography>Payment source:</Typography>
                  <Typography>
                    {tabPayment?.service === 'STRIPE' ? 'CARD_READER' : 'EXTERNAL'}
                  </Typography>
                </Box>
                <Divider />
                <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 2 }}>
                  <Typography>Status:</Typography>
                  <Typography>{tabPayment?.status}</Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Card component={Paper} className={classes.cardContent}>
              <CardContent sx={{ p: 0 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 2 }}>
                  <Typography>Date:</Typography>
                  <Typography>{moment(tabPayment?.updatedAt).format('Do MMM YYYY')}</Typography>
                </Box>
                <Divider />
                <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 2 }}>
                  <Typography>Time:</Typography>
                  <Typography>{moment(tabPayment?.updatedAt).format('HH:mm')}</Typography>
                </Box>
                <Divider />
                <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 2 }}>
                  <Typography>Table:</Typography>
                  <Typography>{tab?.tableName}</Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Page>
    </>
  );
};

export default TabPayment;
