// import { refreshSession as refreshCognitoSession } from './index';

export const setAuthenticationTokens = (session) => {
  localStorage.setItem('access_token', session.accessToken);
  localStorage.setItem('id_token', session.idToken);
  localStorage.setItem('refresh_token', session.refreshToken);
  localStorage.setItem('expires_at', session.expiresAt);
};

export const clearAuthenticationTokens = () => {
  localStorage.removeItem('access_token');
  localStorage.removeItem('id_token');
  localStorage.removeItem('venue_id');
  localStorage.removeItem('refresh_token');
  localStorage.removeItem('expires_at');
};

export const getIdToken = () => localStorage.getItem('id_token');

export const getRefreshToken = () => localStorage.getItem('refresh_token');

export const hasAuthenticationTokens = () => !!getIdToken() && !!getRefreshToken();

const getExpiresAt = () => {
  const rawTime = localStorage.getItem('expires_at');
  return new Date(rawTime * 1000).getTime();
};

export const getAccessToken = () => localStorage.getItem('access_token');

export const getVenuePrefix = () => `venues/${localStorage.getItem('venue_id')}`;

export const isSessionExpired = () => new Date().getTime() > getExpiresAt();
