import React, { useEffect } from 'react';
import {
  Box,
  Button,
  CardActions,
  Checkbox,
  DialogContent,
  FormControlLabel,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';

import { useDispatch, useSelector } from 'react-redux';
import PromotionItem from '../../components/PromotionItem';
import { getCampaignsState } from '../../store/campaigns/selectors';
import AutoComplete from '../../components/AutoComplete';
import { getMenuItemsState } from '../../store/menuItems/selectors';
import shouldLoad from '../../shared/utils/shouldLoad';
import { fetchMenuItems } from '../../store/menuItems';
import { updateBrandupCampaign } from '../../store/campaign';
import { useNotifications } from '../../shared/contexts/Notifications/useNotifications';
import { getErrorMessage } from '../../shared/utils/errors';
import { fetchCampaigns } from '../../store/campaigns';
import useRoles from '../../hooks/useRoles';
import CustomDialog from '../../components/CustomDialog';

const useStyles = makeStyles(() => ({
  closeButton: {
    position: 'absolute',
    right: '10px',
    top: '10px',
  },
}));

const OfferDialog = ({ campaign, isOpen, handleClose, onSubmit, initialValues }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    campaignName,
    campaignDescription,
    reward,
    redemptions,
    duration,
    campaignConditions,
    runningTimesLabel,
    campaignId,
    productIds,
  } = campaign;
  const { data: campaigns } = useSelector(getCampaignsState);
  const menuItemsState = useSelector(getMenuItemsState);
  const { data: menuItems } = menuItemsState;
  const { showErrorNotification, showSuccessNotification } = useNotifications();
  const { isAdmin } = useRoles();

  const isAccepted = campaigns?.accepted.includes(campaign);
  const isExpired = campaigns?.expired.includes(campaign);
  const isAvailable = campaigns?.availables.includes(campaign);

  const OfferSchema = Yup.object().shape({
    acceptTerms: Yup.bool()
      .required('The terms and conditions must be accepted.')
      .oneOf([true], 'The terms and conditions must be accepted.'),
  });

  const handleOnSubmit = () => {
    onSubmit(campaignId);
  };

  const ItemSchema = Yup.object().shape({
    productIds: Yup.array().of(Yup.string()).required('Required'),
  });

  const submitSelectProduct = async (values) => {
    try {
      await dispatch(updateBrandupCampaign(campaignId, { productIds: values.productIds }));
      showSuccessNotification('Offer has been updated successfully');
      await dispatch(fetchCampaigns());
      handleClose();
    } catch (error) {
      showErrorNotification(getErrorMessage(error));
    }
  };

  useEffect(() => {
    if (shouldLoad(menuItemsState)) {
      dispatch(fetchMenuItems());
    }
  }, [dispatch, menuItemsState]);

  return (
    <CustomDialog title={campaignName} isDialogOpen={isOpen} handleCloseDialog={handleClose}>
      <DialogContent dividers>
        <Box mb={2}>
          <PromotionItem item={campaign} />
        </Box>
        <Typography variant="body2" paragraph>
          {campaignDescription}
        </Typography>
        <Typography variant="body2" paragraph>
          <strong>Offer Reward:</strong> {reward}
        </Typography>
        <Typography variant="body2" paragraph>
          <strong>Offer Duration:</strong> {duration} days remaining
        </Typography>
        {redemptions !== undefined && (
          <Typography variant="body2" paragraph>
            <strong>Redemptions:</strong> {redemptions}
          </Typography>
        )}
        <Typography variant="body2" paragraph>
          <strong>Offer Conditions:</strong>
        </Typography>
        <ul>
          {campaignConditions.map((condition) => (
            <li key={condition}>{condition}</li>
          ))}
        </ul>
        <Typography variant="body2" paragraph>
          <strong>Should be visible for:</strong> {runningTimesLabel}
        </Typography>

        {isAdmin() && isAccepted && !!menuItems?.length && (
          <Formik
            initialValues={{ productIds: productIds?.[0] || '' }}
            onSubmit={submitSelectProduct}
            validationSchema={ItemSchema}
          >
            {({ dirty, setFieldValue, setFieldTouched, resetForm }) => (
              <Form>
                <section>
                  <AutoComplete
                    addUnknownOff
                    disabled={isExpired}
                    key={menuItems}
                    label="Select Item"
                    options={menuItems}
                    value={menuItems.find(({ itemId }) => itemId === productIds?.[0]) || ''}
                    optionLabelKey="itemName"
                    optionValueKey="itemId"
                    onInputChange={(e, value) => {
                      if (value === '') resetForm();
                    }}
                    onSelect={(itemId) => {
                      setFieldValue('productIds', [itemId]);
                      setFieldTouched('productIds');
                    }}
                  />
                  <br />
                  {!isExpired && (
                    <Button type="submit" variant="contained" color="primary" disabled={!dirty}>
                      Associate Product
                    </Button>
                  )}
                </section>
              </Form>
            )}
          </Formik>
        )}
      </DialogContent>
      <Formik
        enableReinitialize
        validationSchema={OfferSchema}
        onSubmit={handleOnSubmit}
        initialValues={initialValues}
        validateOnChange
      >
        {({ values, isValid, handleChange }) => (
          <Form>
            {isAvailable && (
              <DialogContent>
                <FormControlLabel
                  control={
                    <Field
                      component={Checkbox}
                      checked={values.acceptTerms}
                      id="acceptTerms"
                      name="acceptTerms"
                      color="primary"
                      onChange={handleChange}
                    />
                  }
                  label="I agree to the terms & conditions"
                >
                  <ErrorMessage name="acceptTerms" />
                </FormControlLabel>
              </DialogContent>
            )}
            <CardActions className={classes.cardActions}>
              <Button onClick={handleClose}>Cancel</Button>
              {isAvailable && (
                <Button type="submit" variant="contained" color="primary" disabled={!isValid}>
                  Activate
                </Button>
              )}
            </CardActions>
          </Form>
        )}
      </Formik>
    </CustomDialog>
  );
};
export default OfferDialog;
