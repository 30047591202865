import { createSlice } from '@reduxjs/toolkit';
import {
  asyncInitialState as initialState,
  createAsyncAction,
  createAsyncReducerHandlers,
} from '../utils/async';
import {
  fetchSettings as fetchSettingsApi,
  updateSettings as updateSettingsApi,
  deleteVenue as deleteVenueApi,
  forcePasswordReset as forcePasswordResetApi,
  deleteUser as deleteUserApi,
} from '../../api/admin';

export const fetchAdmin = createAsyncAction('fetch-admin', fetchSettingsApi);

export const updateAdmin = createAsyncAction('update-admin', updateSettingsApi);

export const deleteVenue = createAsyncAction('delete-venue', deleteVenueApi);

export const forcePasswordReset = createAsyncAction('force-password-reset', forcePasswordResetApi);

export const deleteUser = createAsyncAction('delete-user', deleteUserApi);

const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {},
  extraReducers: {
    ...createAsyncReducerHandlers(fetchAdmin),
    ...createAsyncReducerHandlers(updateAdmin),
  },
});

export const { reducer: admin } = adminSlice;
