import React from 'react';
import { Grid, Button, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import RemoveIcon from '@mui/icons-material/Remove';

import ColorPaletteColor from './ColorPaletteColor';
import colorPalette from './colorPalette';

const useStyles = makeStyles((theme) => ({
  colorNone: {
    height: `${theme.spacing(5)}`,
    width: `${theme.spacing(5)}`,
    minHeight: `${theme.spacing(5)}`,
    minWidth: `${theme.spacing(5)}`,
    border: `1px solid ${theme.palette.grey[300]}`,
    backgroundColor: theme.palette.grey[300],
    borderRadius: '100%',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: '#fff',
    boxShadow: `0 0 0 0 transparent`,
    transition: `box-shadow 0.3s`,
  },
  closeButton: {
    position: 'absolute',
    right: '10px',
    top: '10px',
  },
  colorSelected: {
    boxShadow: `0 0 3px 3px ${theme.palette.primary.main}`,
    borderColor: theme.palette.primary.main,
  },
}));

const ColorPalettePickerSelector = ({
  currentColor,
  handleSetColor,
  palette = colorPalette,
  isRemovable = true,
}) => {
  const classes = useStyles();
  const isTransparent = !currentColor;

  return (
    <Grid container spacing={2}>
      {isRemovable && (
        <Grid item>
          <Tooltip title="Remove color" placement="right">
            <Button
              className={`${classes.colorNone} ${isTransparent && classes.colorSelected}`}
              onClick={() => handleSetColor(undefined)}
            >
              <RemoveIcon />
            </Button>
          </Tooltip>
        </Grid>
      )}
      {palette.map((color) => {
        const isSelected = currentColor === color;

        return (
          <ColorPaletteColor
            key={color}
            color={color}
            isSelected={isSelected}
            handleSetColor={handleSetColor}
          />
        );
      })}
    </Grid>
  );
};

export default ColorPalettePickerSelector;
