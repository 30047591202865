import { Box, Card, CardContent, FormControl, Grid, RadioGroup, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { isEqual, pick } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { MdError } from 'react-icons/md';
import ESPicker from '../../components/ESPicker';
import { LozengeRadio, LozengeRadioLabel } from '../../components/LozengeRadio';
import OrderableTable from '../../components/OrderableTable';
import useESPicker from '../../hooks/useESPicker';
import OverViewNoTills from './OverViewNoTills';
import TableLink from '../../components/TableLink';

const useStyles = makeStyles((theme) => ({
  noData: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(2),
  },
  sectionHeader: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
  },
  bluetext: {
    color: theme.palette.secondary.main,
  },
  summaryCard: {
    maxWidth: '600px',
  },
  summaryText: {
    ...theme.customFonts.small,
    color: theme.customPalette.greyDarker,
    marginBottom: '24px',
  },
  summarySection: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  cardTitle: {
    ...theme.customFonts.smallBold,
    marginBottom: '17.5px',
    marginTop: '45px',
  },
  orderableTable: {
    maginTop: '24px',
  },
  flaggedAmount: {
    color: theme.customPalette.destructiveRed,
    textAlign: 'baseline',
  },
  summaryTitlesSection: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
    justifyContent: 'space-between',
    maxWidth: '600px',
  },
}));

const formatNumber = (number) => {
  const formattedValue = Math.abs(number).toFixed(2);
  const sign = number < 0 ? '-' : '+';

  return `${sign} £${formattedValue}`;
};

const OverView = ({ tills, onSubmit, floats, selectedTill, titles, setTabValue }) => {
  const classes = useStyles();
  const esPicker = useESPicker({ useOrder: false, useSearch: false });
  const {
    cashExpected,
    cashActual,
    cashUps,
    pettyCashTotal,
    sales,
    variance,
    varianceFlagAmount: varianceFlag,
  } = floats || {};
  const { selectedDates, reportingType } = esPicker;
  const [savedDates, setSavedDates] = useState(selectedDates);

  const handleSubmit = useCallback(
    (values) => {
      onSubmit({ ...values, ...selectedDates, reportingType });
    },
    [onSubmit, selectedDates, reportingType],
  );
  const selectTab = useCallback(() => {
    setTabValue(titles[1]);
  }, [setTabValue, titles]);

  const newData = useCallback(() => {
    const pickedData = [];
    if (cashUps) {
      cashUps.forEach((item) => {
        const itemToPick = { ...item, view: 'view', varianceFlagAmount: varianceFlag || '' };
        pickedData.push(
          pick(itemToPick, [
            'view',
            'createdAt',
            'staffName',
            'tillName',
            'variance',
            'floatId',
            'varianceFlagAmount',
          ]),
        );
      });
    }
    return pickedData;
  }, [cashUps, varianceFlag]);

  const valueFormatter = useCallback(
    ({ value, valueName, row }) => {
      switch (valueName) {
        case 'createdAt':
          return `${new Date(value).toLocaleDateString()} - ${new Date(value).toLocaleTimeString(
            [],
            {
              hour: '2-digit',
              minute: '2-digit',
            },
          )}`;
        case 'variance':
          if (value > row.varianceFlagAmount) {
            return (
              <span className={classes.flaggedAmount}>
                {' '}
                <MdError />
                {formatNumber(value)}
              </span>
            );
          }
          return `${formatNumber(value)}`;
        case 'view':
          return <TableLink to={`/tills/cashup/${row.floatId}`}>View</TableLink>;
        default:
          return value;
      }
    },
    [classes.flaggedAmount],
  );

  useEffect(() => {
    if (!isEqual(savedDates, selectedDates)) {
      setSavedDates(selectedDates);
      handleSubmit({ tillId: selectedTill, ...selectedDates });
    }
  }, [handleSubmit, savedDates, selectedDates, selectedTill]);

  return !tills || !tills.length ? (
    <OverViewNoTills handleClick={selectTab} />
  ) : (
    <>
      <ESPicker esPicker={esPicker} left />
      <FormControl component="fieldset">
        <p>Select a till</p>
        <RadioGroup
          aria-label="Tills"
          name="tillId"
          value={selectedTill}
          onChange={({ target }) => {
            const { value } = target;
            handleSubmit({ tillId: value });
          }}
        >
          <Grid container spacing={1} style={{ marginLeft: 5 }}>
            {tills.map(({ name, tillId }) => (
              <Grid item key={tillId}>
                <LozengeRadioLabel
                  selected={tillId === selectedTill}
                  value={tillId}
                  control={<LozengeRadio />}
                  label={name}
                />
              </Grid>
            ))}
          </Grid>
        </RadioGroup>
      </FormControl>
      <Box className={classes.summaryTitlesSection}>
        <Typography className={classes.cardTitle}>Summary</Typography>
      </Box>
      <Card className={classes.summaryCard}>
        <CardContent>
          <div className={classes.summarySection}>
            <Typography className={classes.summaryText}>Cash payments</Typography>
            <Typography className={classes.summaryText}>{formatNumber(sales)}</Typography>
          </div>
          <div className={classes.summarySection}>
            <Typography className={classes.summaryText}>Petty cash</Typography>
            <Typography className={classes.summaryText}>{formatNumber(pettyCashTotal)}</Typography>
          </div>
          <div className={classes.summarySection}>
            <Typography className={classes.summaryText}>Cash Expected</Typography>
            <Typography className={classes.summaryText}>{formatNumber(cashExpected)}</Typography>
          </div>
          <div className={classes.summarySection}>
            <Typography className={classes.summaryText}>Cash Actual</Typography>
            <Typography className={classes.summaryText}>{formatNumber(cashActual)}</Typography>
          </div>
          <div className={classes.summarySection}>
            <Typography className={classes.summaryText}>Variance</Typography>
            <Typography className={classes.summaryText}>{formatNumber(variance)}</Typography>
          </div>
        </CardContent>
      </Card>
      {cashUps?.length > 0 && (
        <>
          <Typography className={classes.cardTitle}>Cash ups</Typography>
          <Card>
            <OrderableTable
              tableData={[...newData()]}
              titles={['', 'DATE', 'MEMBER', 'TILL', 'VARIANCE']}
              keys={['']}
              excludeFields={['', 'floatId', 'varianceFlagAmount']}
              disableColumnTitles={['']}
              valueFormatter={valueFormatter}
            />
          </Card>
        </>
      )}
    </>
  );
};
export default OverView;
