import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { TextField } from 'formik-mui';
import {
  Card,
  CardActions,
  CardContent,
  Button,
  FormGroup,
  MenuItem,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import CustomDialog from '../../components/CustomDialog';

const useStyles = makeStyles((theme) => ({
  heading: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  panel: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
  },
}));

const AddTillSchema = Yup.object().shape({
  name: Yup.string().required('Please enter a till name or number'),
  defaultFloatAmount: Yup.number().min(0).required('Please enter amount'),
  mobileDeviceId: Yup.string().required('Please select a device'),
  varianceFlagAmount: Yup.number().optional(),
});

const TillDialog = ({ closeDialog, isOpen, submitDialog, mobiles, action, initialValues }) => {
  const classes = useStyles();

  return (
    <CustomDialog
      title={action === 'add' ? 'Add a Till' : 'Edit Till'}
      handleCloseDialog={closeDialog}
      isDialogOpen={isOpen}
    >
      <Card className={classes.card}>
        <Formik
          initialValues={initialValues}
          onSubmit={submitDialog}
          validationSchema={AddTillSchema}
          mode="onChange"
        >
          {({ dirty, isValid }) => (
            <Form>
              <CardContent>
                <FormGroup className={classes.section}>
                  <Typography variant="subtitle1">Till name (max 25 characters)</Typography>
                  <Field
                    component={TextField}
                    name="name"
                    placeholder="Till name"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    required
                  />
                  <Typography variant="subtitle1">
                    Float amount e.g. £150 The amount of cash in each till
                  </Typography>
                  <Field
                    component={TextField}
                    name="defaultFloatAmount"
                    type="number"
                    placeholder="Float amount"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    required
                  />
                  <Typography variant="subtitle1">
                    Add the iPad that is associated with the cash drawer
                  </Typography>
                  <Field
                    component={TextField}
                    name="mobileDeviceId"
                    placeholder="iPad"
                    variant="outlined"
                    select
                    fullWidth
                    margin="normal"
                    required
                  >
                    {mobiles &&
                      mobiles.map(({ name, mobileDeviceId }) => (
                        <MenuItem value={mobileDeviceId} key={mobileDeviceId}>
                          {name} ({mobileDeviceId.substr(0, 6)}&hellip;)
                        </MenuItem>
                      ))}
                  </Field>
                  <p>MUST ONLY SELECT AN IPAD</p>
                  <p className={classes.panel}>
                    On your ipad you can find the device ID by navigating to Settings / Device /
                    About device
                  </p>
                  <Typography variant="subtitle1">Flag variance if above this value:</Typography>
                  <Field
                    component={TextField}
                    name="varianceFlagAmount"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    type="number"
                  />
                </FormGroup>
              </CardContent>
              <CardActions className={classes.cardActions}>
                <Button onClick={closeDialog}>Cancel</Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={!(isValid && dirty)}
                >
                  Save
                </Button>
              </CardActions>
            </Form>
          )}
        </Formik>
      </Card>
    </CustomDialog>
  );
};

TillDialog.propTypes = {
  closeDialog: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  submitDialog: PropTypes.func.isRequired,
};

export default TillDialog;
