import { createSlice } from '@reduxjs/toolkit';
import {
  asyncInitialState as initialState,
  createAsyncAction,
  createAsyncReducerHandlers,
} from '../utils/async';
import {
  fetchTables as fetchTablesApi,
  fetchTable as fetchTableApi,
  createTable as createTableApi,
  updateTable as updateTableApi,
  updateTableAvailability as updateTableAvailabilityApi,
  deleteTable as deleteTableApi,
} from '../../api/tables';

export const fetchTables = createAsyncAction('fetch-tables', fetchTablesApi);

export const fetchTable = createAsyncAction('fetch-table', fetchTableApi);

export const deleteTable = createAsyncAction('delete-table', deleteTableApi);

export const createTable = createAsyncAction('create-table', createTableApi);

export const updateTable = createAsyncAction('update-table', updateTableApi);

export const updateTableAvailability = createAsyncAction(
  'update-table-availability',
  updateTableAvailabilityApi,
);

const tablesSlice = createSlice({
  name: 'tables',
  initialState,
  reducers: {
    clearTables(state) {
      delete state.data;
    },
  },
  extraReducers: {
    ...createAsyncReducerHandlers(fetchTables),
    ...createAsyncReducerHandlers(updateTableAvailability),
  },
});

const tableSlice = createSlice({
  name: 'table',
  initialState,
  reducers: {
    clearTable(state) {
      delete state.data;
    },
  },
  extraReducers: {
    ...createAsyncReducerHandlers(fetchTable),
    ...createAsyncReducerHandlers(createTable),
    ...createAsyncReducerHandlers(updateTable),
  },
});
export const { clearTables } = tablesSlice.actions;
export const { clearTable } = tableSlice.actions;

export const { reducer: tables } = tablesSlice;
export const { reducer: table } = tableSlice;
