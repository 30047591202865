import { createSlice } from '@reduxjs/toolkit';
import {
  asyncInitialState as initialState,
  createAsyncAction,
  createAsyncReducerHandlers,
  fetchPageAsyncReducerHandler,
} from '../utils/async';
import {
  fetchExternalPayouts as fetchExternalPayoutsApi,
  createExternalPayout as createExternalPayoutApi,
  updateExternalPayout as updateExternalPayoutApi,
} from '../../api/payouts-external';

export const fetchExternalPayouts = createAsyncAction(
  'fetch-external-payouts',
  fetchExternalPayoutsApi,
);

export const fetchExternalPayoutsPage = createAsyncAction(
  'fetch-external-payouts-page',
  fetchExternalPayoutsApi,
);

export const createExternalPayout = createAsyncAction(
  'create-external-payout',
  createExternalPayoutApi,
);

export const updateExternalPayout = createAsyncAction(
  'update-external-payout',
  updateExternalPayoutApi,
);

const externalPayoutsSlice = createSlice({
  name: 'payouts-external',
  initialState,
  reducers: {
    clearExternalPayouts(state) {
      delete state.data;
    },
  },
  extraReducers: {
    ...createAsyncReducerHandlers(fetchExternalPayouts),
    ...fetchPageAsyncReducerHandler(fetchExternalPayoutsPage),
  },
});

const externalPayoutSlice = createSlice({
  name: 'payout-external',
  initialState,
  reducers: {
    clearExternalPayout(state) {
      delete state.data;
    },
  },
  extraReducers: {
    ...createAsyncReducerHandlers(createExternalPayout),
    ...createAsyncReducerHandlers(updateExternalPayout),
  },
});
export const { clearExternalPayouts } = externalPayoutsSlice.actions;
export const { clearExternalPayout } = externalPayoutSlice.actions;

export const { reducer: externalPayouts } = externalPayoutsSlice;
export const { reducer: externalPayout } = externalPayoutSlice;
