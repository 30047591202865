import React from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { DateTimePicker } from '@mui/x-date-pickers';
import moment from 'moment';
// import { KeyboardDatePicker } from '@material-ui/pickers';

import { TextField } from 'formik-mui';
import { makeStyles } from '@mui/styles';
import { Card, CardActions, CardContent, FormGroup, Button, MenuItem } from '@mui/material';

import withVenue from '../../hoc/withVenue';
import payoutTypes from './payoutsType';

const useStyles = makeStyles(() => ({
  cardActions: {
    justifyContent: 'flex-end',
  },
  card: {
    overflowY: 'auto',
  },
  cardContainer: {
    padding: '10px',
  },
  subtitle: {
    color: '#647F99',
  },
}));

const PayoutForm = ({ initialValues, onSubmit, onCancel }) => {
  const classes = useStyles();
  const submitButtonLabel = 'Save';

  const PayoutsSchema = Yup.object().shape({
    amount: Yup.number().required('Please choose an amount'),
    reference: Yup.string().required('Please enter a reference'),
    type: Yup.string().oneOf(payoutTypes).required(),
    day: Yup.date().required('Please enter a date'),
  });

  const handleOnDateChange = (date, setFieldValue) => {
    setFieldValue('day', date);
  };

  return (
    <Card className={classes.card}>
      <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={PayoutsSchema}>
        {({ dirty, values, setFieldValue, isSubmitting, isValid }) => (
          <Form>
            <div className={classes.cardContainer}>
              <CardContent>
                <FormGroup className={classes.section}>
                  <Field
                    component={TextField}
                    type="number"
                    inputProps={{
                      min: 0,
                      step: '0.1',
                    }}
                    name="amount"
                    label="Transaction amount"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    required
                  />
                  <Field
                    component={TextField}
                    name="reference"
                    label="Add a reference to help you identify this transaction"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    required
                  />
                  <Field
                    component={TextField}
                    name="type"
                    label="Type"
                    variant="outlined"
                    fullWidth
                    required
                    margin="normal"
                    select
                  >
                    {payoutTypes.map((type) => (
                      <MenuItem key={type} value={type}>
                        {type}
                      </MenuItem>
                    ))}
                  </Field>
                  <Field
                    component={DateTimePicker}
                    timezone="system"
                    variant="inline"
                    format="DD/MM/YYYY"
                    margin="normal"
                    id="day"
                    label="Day"
                    name="day"
                    value={moment(values.day)}
                    onChange={(date) => handleOnDateChange(date, setFieldValue)}
                    KeyboardButtonProps={{
                      'aria-label': 'Change expiry date',
                    }}
                    autoOk
                    disableFuture
                  />
                </FormGroup>
              </CardContent>
            </div>
            <CardActions className={classes.cardActions}>
              <Button onClick={onCancel}>Cancel</Button>

              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={!dirty || isSubmitting || !isValid}
              >
                {submitButtonLabel}
              </Button>
            </CardActions>
          </Form>
        )}
      </Formik>
    </Card>
  );
};

export default withVenue(PayoutForm);
