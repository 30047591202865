import { get, post } from './httpRequest';

export const fetchStripeConnectAccount = async () => {
  const response = await get(`/stripe-connect/account`);
  return response.data;
};

export const createStripeConnectAccount = async () => {
  await post(`/stripe-connect/account`);
};
