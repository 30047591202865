import React, { useRef } from 'react';
import { Box, Card, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useHistory } from 'react-router-dom';
import AreaMenu from './AreaMenu';

const useStyles = makeStyles((theme) => ({
  areaCard: {
    padding: theme.spacing(2),
    cursor: 'pointer',
  },
  areaCardHeader: {
    display: 'flex',
    fontSize: '1.2rem',
    justifyContent: 'space-between',
  },
  menuIcon: {
    paddingTop: 0,
    padding: 0,
  },
  closed: {
    color: '#9FA2A4',
    marginRight: theme.spacing(1),
  },
  tables: {
    color: '#647F99',
  },
}));

const SalesAreasCard = ({ salesArea }) => {
  const { salesAreaName, salesAreaId, tables, enabled } = salesArea;
  const classes = useStyles();
  const history = useHistory();
  const renameRef = useRef();
  const deleteRef = useRef();
  const menuRef = useRef();

  const selectArea = (event) => {
    const { target } = event;
    const renameElement = renameRef.current;
    const deleteElement = deleteRef.current;
    const menuElement = menuRef.current;
    const isMenu =
      renameElement?.contains(target) ||
      deleteElement?.contains(target) ||
      menuElement?.contains(target);
    if (!isMenu) history.push(`/sales-area/${salesAreaId}`);
  };

  return (
    <Card className={classes.areaCard} onClick={selectArea}>
      <div className={classes.areaCardHeader}>
        {salesAreaName}
        <Box display="flex">
          {!enabled && (
            <Typography variant="body2" className={classes.closed}>
              Closed
            </Typography>
          )}
          <AreaMenu ref={{ renameRef, deleteRef, menuRef }} salesAreaId={salesAreaId} />
        </Box>
      </div>
      <p className={classes.tables}>{tables.length} tables</p>
    </Card>
  );
};

export default SalesAreasCard;
