import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material';

const DeleteAssociation = ({
  venueId,
  variationId,
  venueName,
  handleRemoveAssociation,
  showDialog,
  handleCloseDialog,
}) => (
  <Dialog open={showDialog}>
    <DialogTitle>
      <b>Are you sure?</b>
    </DialogTitle>
    <DialogContent>
      <DialogContentText>
        This will delete the brand menu associated with <b>{venueName}</b>.
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleCloseDialog} color="primary">
        Cancel
      </Button>
      <Button
        onClick={() => handleRemoveAssociation(venueId, variationId)}
        color="primary"
        variant="contained"
      >
        Confirm
      </Button>
    </DialogActions>
  </Dialog>
);

export default DeleteAssociation;
