import React from 'react';
import { Form, Formik } from 'formik';
import { Button, CardActions } from '@mui/material';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  button: {
    borderColor: theme.palette.error.main,
    backgroundColor: theme.palette.error.main,
  },
}));

const PromotionDeleteForm = ({ handleCloseDialog, onSubmit }) => {
  const classes = useStyles();

  return (
    <Formik onSubmit={onSubmit} initialValues={{}}>
      {() => (
        <Form>
          <CardActions>
            <Button variant="text" color="primary" onClick={handleCloseDialog}>
              Cancel
            </Button>

            <Button variant="contained" color="primary" type="submit" className={classes.button}>
              Delete
            </Button>
          </CardActions>
        </Form>
      )}
    </Formik>
  );
};

export default PromotionDeleteForm;
