import React from 'react';
import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';
import Page from '../../components/Page';
import PageHeader from '../../components/PageHeader';
import BackArrow from '../../components/BackArrow';
import PlatformInvoiceHistory from '../../components/PlatformInvoiceHistory';

const useStyles = makeStyles((theme) => ({
  title: {
    ...theme.customFonts.largeBold,
    marginBottom: '16px',
  },
}));

const PlatformInvoices = () => {
  const classes = useStyles();

  return (
    <>
      <PageHeader>
        <BackArrow text="Business" path="/settings/business" />
        <Typography className={classes.title}>Platform Invoices</Typography>
      </PageHeader>
      <Page>
        <PlatformInvoiceHistory />
      </Page>
    </>
  );
};

export default PlatformInvoices;
