import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment from 'moment-timezone';
import React from 'react';
import useESPicker from '../../hooks/useESPicker';
import formatNumber from '../../shared/utils/formatNumber';
import CollapsableDescription from '../CollapsableDescription';
import PercentageStatusLozenge from '../PercentageStatusLozenge';

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    color: theme.customPalette.greyDarker,
  },
  title: {
    ...theme.customFonts.medium,
  },
  lozenge: {
    padding: '2px 8px',
    marginLeft: theme.spacing(1),
  },
  leftBox: {
    textAlign: 'left',
    color: theme.customPalette.greyDarker,
    display: 'flex',
    flexDirection: 'column-reverse',
  },
  rightBox: {
    textAlign: 'right',
    color: theme.customPalette.greyDark,
    display: 'flex',
    flexDirection: 'column-reverse',
  },
  rightSize: {
    ...theme.customFonts.large,
    [theme.breakpoints.down('lg')]: {
      ...theme.customFonts.medium,
    },
    [theme.breakpoints.down('md')]: {
      ...theme.customFonts.large,
    },
  },
  leftSize: {
    ...theme.customFonts.xLarge,
    [theme.breakpoints.down('lg')]: {
      ...theme.customFonts.large,
    },
    [theme.breakpoints.down('md')]: {
      ...theme.customFonts.xLarge,
    },
  },
  dates: {
    ...theme.customFonts.xSmall,
    color: theme.customPalette.greyDark,
  },
  divider: {
    borderLeft: '1px solid #D9E2EC',
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    [theme.breakpoints.up('lg')]: {
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
    },
  },
}));

const formatDates = ({ from, to }) => {
  const format = moment(to).year() !== moment(from).year() ? 'DD MMM YYYY' : 'DD MMM';
  return `${moment(from).format(format)} - ${moment(to).format(format)}`;
};

const ReportsHeader = ({
  title,
  data,
  prefix = '',
  suffix = '',
  description,
  decimalPlaces = 0,
  negativePercentIsPositive = false,
}) => {
  const { current = 0, previous = 0 } = data || {};
  const classes = useStyles();
  const esPicker = useESPicker({ useOrder: false, useSearch: false, useUnits: true });
  const { selectedDates, previousSelectedDates } = esPicker;

  const theme = useTheme();
  const justify = useMediaQuery(theme.breakpoints.up('lg')) ? 'flex-start' : 'space-between';

  return (
    <>
      <div className={classes.header}>
        <Typography>{title}</Typography>
        <PercentageStatusLozenge
          current={current}
          previous={previous}
          negativePercentIsPositive={negativePercentIsPositive}
        />
      </div>

      <CollapsableDescription description={description} />
      {data && (
        <Box display="flex" justifyContent={justify}>
          <div className={classes.leftBox}>
            <Typography className={classes.dates}>{formatDates(selectedDates)}</Typography>
            <Typography className={classes.leftSize}>
              {prefix}
              {formatNumber(current, decimalPlaces)}
              {suffix}
            </Typography>
          </div>
          <div className={classes.divider} />
          <div className={classes.rightBox}>
            <Typography className={classes.dates}>{formatDates(previousSelectedDates)}</Typography>
            <Typography className={classes.rightSize}>
              {prefix}
              {formatNumber(previous, decimalPlaces)}
              {suffix}
            </Typography>
          </div>
        </Box>
      )}
    </>
  );
};
export default ReportsHeader;
