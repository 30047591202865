import React from 'react';
import {
  Card,
  CardContent,
  CardActions,
  Button,
  TextField,
  Grid,
  FormControl,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import useRoles from '../../../hooks/useRoles';
import ROLES from '../../../shared/constants/roles';
import CustomDialog from '../../../components/CustomDialog';
import PhoneField from '../../../components/PhoneField';

const roles = [ROLES.STAFF, ROLES.MANAGER, ROLES.OWNER];
const roleTitle = { [ROLES.STAFF]: 'Member', [ROLES.MANAGER]: 'Admin', [ROLES.OWNER]: 'Owner' };
const roleDscription = {
  [ROLES.STAFF]: 'View order history and tabs',
  [ROLES.MANAGER]: 'View Reports, perform refunds, menu edits, promotions and adjust opening hours',
  [ROLES.OWNER]: 'Full access to all features and settings',
};

const useStyles = makeStyles((theme) => ({
  heading: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  title: {
    flexGrow: 1,
  },
  mainContent: {
    padding: theme.spacing(3),
    paddingTop: 0,
    paddingBottom: 0,
  },
  bottomContent: {
    padding: theme.spacing(3),
    paddingTop: 0,
  },
  radioGroup: {
    marginTop: theme.spacing(1),
  },
  boldTitle: {
    marginBottom: theme.spacing(1),
  },
  legend: {
    marginBottom: theme.spacing(1),
  },
  legendTitle: {
    display: 'block',
    paddingTop: theme.spacing(1),
  },
  legendSubtitle: {
    display: 'block',
    color: '#647F99',
  },
  radio: {
    alignItems: 'flex-start',
  },
  card: {
    overflow: 'auto',
  },
  cardActions: {
    backgroundColor: '#F0F4F8',
  },
  info: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(1),
    backgroundColor: '#F0F4F8',
    borderRadius: '4px',
  },
  formControl: {
    marginBottom: theme.spacing(1),
  },
}));

const ManagerFormDialog = ({
  action,
  submitDialog,
  handleDialogOpen = false,
  handleCloseDialog,
  email,
  role,
  name,
  countryCode = '+44',
  phone,
  currentUser,
}) => {
  const classes = useStyles();
  const { isRoleAtLeast } = useRoles();
  const createMode = action === 'create';
  const title = createMode ? 'New user' : 'Edit info';
  const buttonTitle = createMode ? 'Send invite' : 'Save';
  const isCurrentUser = currentUser?.userId === email && !createMode;

  const NewManagerSchema = Yup.object().shape({
    email: Yup.string().email('Email must be valid').required('This field is required'),
    role: Yup.string(),
    name: Yup.string().nullable(true),
    phone: Yup.string(),
    countryCode: Yup.string(),
  });

  const EditManagerSchema = Yup.object().shape({
    email: Yup.string().required('This field is required'),
    role: Yup.string(),
    name: Yup.string().nullable(true),
    phone: Yup.string(),
    countryCode: Yup.string(),
  });

  return (
    <CustomDialog
      title={title}
      isDialogOpen={handleDialogOpen}
      handleCloseDialog={handleCloseDialog}
    >
      <Card className={classes.card}>
        <Formik
          initialValues={{ email, role, name, phone, countryCode }}
          validationSchema={createMode ? NewManagerSchema : EditManagerSchema}
          enableReinitialize
          validateOnMount
          onSubmit={submitDialog}
        >
          {({ errors, touched, handleBlur, handleChange, setFieldValue, isValid, values }) => (
            <Form>
              <CardContent className={classes.mainContent}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      helperText={errors.name && touched.name && errors.name}
                      FormHelperTextProps={{ className: classes.helperText }}
                      name="name"
                      fullWidth
                      size="small"
                      label="Manager Name"
                      variant="outlined"
                      placeholder="Name"
                      margin="normal"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.name}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      helperText={errors.email && touched.email && errors.email}
                      FormHelperTextProps={{ className: classes.helperText }}
                      name="email"
                      fullWidth
                      size="small"
                      label="Manager Email"
                      variant="outlined"
                      placeholder="manager@email.com"
                      required
                      disabled={!createMode && !!email}
                      margin="normal"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.email}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <PhoneField isSmall required={false} phoneLabel="Mobile Number" />
                  </Grid>
                </Grid>
              </CardContent>
              {!isCurrentUser && (
                <CardContent className={classes.bottomContent}>
                  <FormControl component="fieldset" className={classes.formControl}>
                    <FormLabel component="legend">
                      <p>
                        <strong>Select their level of access</strong>
                      </p>
                      <span className={classes.legendSubtitle}>
                        How much control do you want them to have?
                      </span>
                    </FormLabel>
                    <RadioGroup
                      name="role"
                      className={classes.radioGroup}
                      onChange={(event) => {
                        setFieldValue('role', event.currentTarget.value);
                      }}
                    >
                      {roles.map((menurole) => {
                        if (menurole === ROLES.OWNER && !isRoleAtLeast(ROLES.ADMIN)) return null;
                        return (
                          <FormControlLabel
                            className={classes.radio}
                            key={menurole}
                            checked={menurole === values.role}
                            value={menurole}
                            disabled={menurole === ROLES.MANAGER && !isRoleAtLeast(ROLES.OWNER)}
                            control={<Radio color="primary" />}
                            label={
                              <>
                                <span className={classes.legendTitle}>{roleTitle[menurole]}</span>
                                <span className={classes.legendSubtitle}>
                                  {roleDscription[menurole]}
                                </span>
                              </>
                            }
                          />
                        );
                      })}
                    </RadioGroup>
                  </FormControl>
                  {!isRoleAtLeast(ROLES.OWNER) && (
                    <div className={classes.info}>
                      <span className={classes.legendTitle}>Make someone a manager</span>
                      <span className={classes.legendSubtitle}>
                        Only Owners can upgrade a member to the role of Manager.
                      </span>
                    </div>
                  )}
                  <div className={classes.info}>
                    <span className={classes.legendTitle}>Make someone an owner</span>
                    <span className={classes.legendSubtitle}>
                      Owner status can only be granted or revoked by the Sessions Serve team.
                    </span>
                  </div>
                </CardContent>
              )}
              <CardActions className={classes.cardActions}>
                <Button onClick={handleCloseDialog}>Cancel</Button>
                <Button type="submit" disabled={!isValid} variant="contained" color="primary">
                  {buttonTitle}
                </Button>
              </CardActions>
            </Form>
          )}
        </Formik>
      </Card>
    </CustomDialog>
  );
};

export default ManagerFormDialog;
