import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { makeStyles } from '@mui/styles';
import { Box, Card, Typography } from '@mui/material';

import Page from '../../components/Page';

import { rangeEnums } from '../../shared/utils/dateRanges';
import withVenue from '../../hoc/withVenue';
import ESPicker from '../../components/ESPicker';
import useESPicker from '../../hooks/useESPicker';
import toUTC from '../../shared/utils/toUTC';
import { getReportSalesOverviewState } from '../../store/reports/selectors';
import { clearReportSalesOverview, fetchReportSalesOverview } from '../../store/reports';
import OrderableTable from '../../components/OrderableTable';
import DownloadReport from '../../components/DownloadReport';
import ReportsHeader from '../../components/ReportsHeader';
import BarChart from '../../components/Charts/BarChart';
import { getErrorMessage } from '../../shared/utils/errors';
import { useNotifications } from '../../shared/contexts/Notifications/useNotifications';
import PageHeader from '../../components/PageHeader';

const useStyles = makeStyles((theme) => ({
  heading: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  title: {
    flexGrow: 1,
  },
  stripeLink: {
    marginLeft: theme.spacing(3),
  },
  form: {
    width: '80%',
    flexGrow: 2,
  },
  formElement: {
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  chart: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
  },
}));

const ReportSalesOverview = ({ currentVenueId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const salesOverviewState = useSelector(getReportSalesOverviewState);
  const { loading, data, error } = salesOverviewState;
  const { showErrorNotification } = useNotifications();

  const [downloadPath, setDownloadPath] = React.useState(null);
  const esPicker = useESPicker({ useOrder: false, useSearch: false, useUnits: true });
  const { selectedDates, dateRange, units, reportingType } = esPicker;

  const loadData = useCallback(async () => {
    try {
      await dispatch(
        fetchReportSalesOverview({
          dateRange,
          from: dateRange === rangeEnums.CUSTOM && toUTC(selectedDates.from),
          to: dateRange === rangeEnums.CUSTOM && toUTC(selectedDates.to),
          unit: units,
          reportingType,
        }),
      );
    } catch (localError) {
      showErrorNotification(getErrorMessage(localError));
    }
  }, [dateRange, dispatch, selectedDates, showErrorNotification, units, reportingType]);

  useEffect(() => {
    loadData();
  }, [dispatch, loadData]);

  useEffect(() => {
    const { from, to } = selectedDates;
    const params = dateRange ? `gte=${from}&lte=${to}&unit=${units}` : '';
    setDownloadPath(`/reports/salesOverview/export?${params}`);
  }, [dateRange, selectedDates, units]);

  return (
    <>
      <PageHeader fullWidth>
        <Box className={classes.heading}>
          <Typography className={classes.title} variant="h2" component="h1">
            Sales Overview
          </Typography>
        </Box>
        <DownloadReport
          path={downloadPath}
          type="salesOverview"
          variant="text"
          title="Export"
          fullWidth={false}
          includeVouchers="exclude"
        />
        <ESPicker esPicker={esPicker} left />
      </PageHeader>
      <Page loading={loading} error={error} fullWidth>
        {currentVenueId && data && (
          <>
            <Card className={classes.chart}>
              <ReportsHeader
                title="Total Sales"
                prefix="£"
                data={{
                  current: data?.current.totalSales,
                  previous: data?.previous.totalSales,
                }}
              />
              <BarChart
                id="SalesOverview"
                x="date"
                y="sales"
                data={data.current.histogram}
                previousData={data.previous.histogram}
                unit={units}
              />
            </Card>
            <Card className={classes.card}>
              <OrderableTable
                showTotals
                tableData={data?.current?.histogram}
                zeroCheckTitle="ordersCount"
                rowTitle="date"
                titles={['DATE', 'ORDERS', 'SALES']}
              />
            </Card>
          </>
        )}
      </Page>
    </>
  );
};

export default withVenue(ReportSalesOverview, null, clearReportSalesOverview);
