import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Form, Formik } from 'formik';
import { Card, MenuItem, Typography, CardContent } from '@mui/material';
import { TextField } from 'formik-mui';
import { makeStyles } from '@mui/styles';
import { getServiceStatusState } from '../../../store/serviceStatus/selectors';
import { fetchServiceStatus, updateServiceStatus } from '../../../store/serviceStatus';
import { useNotifications } from '../../../shared/contexts/Notifications/useNotifications';
import Page from '../../../components/Page';
import { getErrorMessage } from '../../../shared/utils/errors';
import UniversalSave from '../../../components/UniversalSave';
import shouldLoad from '../../../shared/utils/shouldLoad';
import withVenue from '../../../hoc/withVenue';
import BackArrow from '../../../components/BackArrow';

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: '8px',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    '& > :nth-child(2)': {
      marginBottom: '10px',
    },
  },
  dropDown: {
    width: '100%',
    margin: 0,
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.customPalette.grey,
    },
    '& .MuiSelect-icon': {
      color: theme.customPalette.greyDark,
    },
  },
  title: {
    ...theme.customFonts.largeBold,
  },
  secondaryTitle: {
    ...theme.customFonts.small,
    color: theme.customPalette.greyDarker,
    marginBottom: '16px',
  },
  subtitle: {
    ...theme.customFonts.label,
    color: theme.customPalette.greyDarker,
  },
}));

const delayTimes = [10, 20, 30, 40, 50, 60];

const updateValues = (values) => {
  /*
  Whilst this is not strictly necessary as we are setting field values
  directly in JSX, it provides us safety and an interface to easily change
  the exit data
   */
  const updatedValues = {
    ...values,
    isFoodServiceOn: values.foodDelay !== 'Not accepting orders',
    isDrinksServiceOn: values.drinksDelay !== 'Not accepting orders',
    foodDelay: values.foodDelay === 'Not accepting orders' ? 0 : values.foodDelay,
    drinksDelay: values.drinksDelay === 'Not accepting orders' ? 0 : values.drinksDelay,
  };
  return updatedValues;
};

const reconstructInitialValues = (serviceStatus) => {
  const updatedValues = {
    ...serviceStatus,
  };
  if (serviceStatus.isFoodServiceOn === false) {
    updatedValues.foodDelay = 'Not accepting orders';
  }
  if (serviceStatus.isDrinksServiceOn === false) {
    updatedValues.drinksDelay = 'Not accepting orders';
  }
  return updatedValues;
};

const ServiceStatus = ({ redirect }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { showErrorNotification, showSuccessNotification } = useNotifications();
  const serviceStatusState = useSelector(getServiceStatusState);
  const {
    loading: serviceStatusLoading,
    data: serviceStatus,
    error: serviceStatusError,
  } = serviceStatusState;

  const handleOnSubmit = async (values) => {
    try {
      const sanitisedValues = updateValues(values);
      await dispatch(updateServiceStatus(sanitisedValues));
      showSuccessNotification('Successfully updated service status');
    } catch (error) {
      showErrorNotification(getErrorMessage(error));
    }
  };

  useEffect(() => {
    if (shouldLoad(serviceStatusState)) dispatch(fetchServiceStatus());
  }, [dispatch, serviceStatusState]);

  return (
    <Page loading={serviceStatusLoading} data={serviceStatus} error={serviceStatusError}>
      <Formik initialValues={serviceStatus ? reconstructInitialValues(serviceStatus) : {}}>
        {({ setFieldValue, values, resetForm, errors, dirty, isValid }) => (
          <>
            <UniversalSave
              isValid={isValid}
              dirty={dirty}
              onSave={() => {
                handleOnSubmit(values);
              }}
              onDiscard={resetForm}
              errors={errors}
            />
            <Form>
              {serviceStatus && (
                <>
                  <BackArrow redirect={redirect} text="Settings" />
                  <Typography className={classes.title}>Service status</Typography>
                  <Typography className={classes.secondaryTitle}>
                    Set a delay notification for orders
                  </Typography>
                  <Card className={classes.card}>
                    <CardContent className={classes.container}>
                      <Typography className={classes.subtitle}>Food order status</Typography>
                      <Field
                        variant="outlined"
                        fullwidth="true"
                        margin="normal"
                        select
                        component={TextField}
                        name="foodDelay"
                        value={values.foodDelay}
                        className={classes.dropDown}
                        InputProps={{
                          style: {
                            borderRadius: '8px',
                          },
                        }}
                      >
                        <MenuItem
                          value={0}
                          onClick={() => {
                            setFieldValue('foodDelay', 0);
                            setFieldValue('isFoodServiceOn', true);
                          }}
                        >
                          No delay
                        </MenuItem>
                        {delayTimes.map((delayTime) => (
                          <MenuItem
                            value={delayTime}
                            key={delayTime}
                            onClick={() => {
                              setFieldValue('foodDelay', delayTime);
                              setFieldValue('isFoodServiceOn', true);
                            }}
                          >
                            {delayTime} mins
                          </MenuItem>
                        ))}
                        <MenuItem
                          value="Not accepting orders"
                          onClick={() => {
                            setFieldValue('foodDelay', 0);
                            setFieldValue('isFoodServiceOn', false);
                          }}
                        >
                          Not accepting orders
                        </MenuItem>
                      </Field>
                      <Typography className={classes.subtitle}>Drinks order status</Typography>
                      <Field
                        variant="outlined"
                        fullwidth="true"
                        margin="normal"
                        select
                        component={TextField}
                        name="drinksDelay"
                        value={values.drinksDelay}
                        className={classes.dropDown}
                        InputProps={{
                          style: {
                            borderRadius: '8px',
                          },
                        }}
                      >
                        <MenuItem
                          value={0}
                          onClick={() => {
                            setFieldValue('drinksDelay', 0);
                            setFieldValue('isDrinksServiceOn', true);
                          }}
                        >
                          No delay
                        </MenuItem>
                        {delayTimes.map((delayTime) => (
                          <MenuItem
                            value={delayTime}
                            key={delayTime}
                            onClick={() => {
                              setFieldValue('drinksDelay', delayTime);
                              setFieldValue('isDrinksServiceOn', true);
                            }}
                          >
                            {delayTime} mins
                          </MenuItem>
                        ))}
                        <MenuItem
                          value="Not accepting orders"
                          onClick={() => {
                            setFieldValue('drinksDelay', 0);
                            setFieldValue('isDrinksServiceOn', false);
                          }}
                        >
                          Not accepting orders
                        </MenuItem>
                      </Field>
                    </CardContent>
                  </Card>
                </>
              )}
            </Form>
          </>
        )}
      </Formik>
    </Page>
  );
};

export default withVenue(ServiceStatus, '/settings');
