import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { Button } from '@mui/material';
import { FiPlus } from 'react-icons/fi';

import {
  createBuilderMenuGroup,
  updateBuilderMenuItems,
} from '../../../../store/brands/builderMenu/builderMenuItems';

import { getErrorMessage } from '../../../../shared/utils/errors';
import { useNotifications } from '../../../../shared/contexts/Notifications/useNotifications';

import MenuGroupForm from '../MenuGroupForm';
import CustomDialog from '../../../CustomDialog';

const CreateMenuGroupButton = ({ disabled }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { showErrorNotification, showSuccessNotification } = useNotifications();
  const dispatch = useDispatch();
  const initialValues = {
    groupColor: '',
    groupName: '',
    available: true,
    description: '',
  };

  const handleCreate = () => {
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const handleOnSubmit = async (values) => {
    try {
      const payload = { ...values };

      dispatch(createBuilderMenuGroup(payload));
      await dispatch(updateBuilderMenuItems());

      setIsDialogOpen(false);
      showSuccessNotification(`${payload.groupName} has been created successfully`);
    } catch (error) {
      showErrorNotification(getErrorMessage(error));
    }
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        disabled={disabled}
        startIcon={<FiPlus />}
        onClick={handleCreate}
      >
        Add Section
      </Button>
      <CustomDialog
        title="Add a new section to this menu"
        isDialogOpen={isDialogOpen}
        handleCloseDialog={handleCloseDialog}
      >
        <MenuGroupForm
          formAction="create"
          initialValues={initialValues}
          onSubmit={handleOnSubmit}
          onCancel={handleCloseDialog}
        />
      </CustomDialog>
    </>
  );
};

export default CreateMenuGroupButton;
