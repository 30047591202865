import { getBare, patchBare } from '../../httpRequest';

export const fetchBrandsMenuBuilderMenusApi = async () => {
  const response = await getBare(`brand/menus`);
  return response.data;
};

export const fetchBrandsMenuBuilderMenuApi = async (menuId) => {
  const response = await getBare(`brands/menus/${menuId}`);

  return response.data;
};

export const patchBrandsMenuBuilderMenuApi = async (menuId, data) => {
  const response = await patchBare(`brands/menus/${menuId}`, {
    data,
  });

  return response.data;
};
