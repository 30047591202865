import ROLES from './roles';

const DISPLAYROLES = {
  [ROLES.SUPERADMIN]: 'Sessions Super Admin',
  [ROLES.ADMIN]: 'Sessions Admin',
  [ROLES.OWNER]: 'Owner',
  [ROLES.MANAGER]: 'Admin',
  [ROLES.STAFF]: 'Member',
};
export default DISPLAYROLES;
