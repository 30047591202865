import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { useSelector } from 'react-redux';
import { Card, CardContent } from '@mui/material';
import { getMissingTablesState } from '../../store/tables/selectors';
import TableWarningMissing from './TableWarningMissing';
import TableWarningMultiple from './TableWarningMultiple';
import useRoles from '../../hooks/useRoles';

const useStyles = makeStyles((theme) => ({
  warningCard: {
    backgroundColor: '#FFB800',
    marginBottom: theme.spacing(5),
  },
  heading: {
    display: 'flex',
    margin: '15px 0',
  },
  title: {
    flexGrow: 1,
  },
  button: {
    marginLeft: '20px',
  },
}));

const TableWarning = ({ showDevicesLink }) => {
  const classes = useStyles();
  const { isAdmin } = useRoles();
  const {
    unassignedFoodTables,
    unassignedDrinksTables,
    unassignedAllTables,
    multipleFoods,
    multipleDrinks,
  } = useSelector(getMissingTablesState);
  const [missing, setMissing] = useState({ FOOD: [], DRINKS: [], ALL: [] });
  useEffect(() => {
    setMissing({
      FOOD: unassignedFoodTables,
      DRINKS: unassignedDrinksTables,
      ALL: unassignedAllTables,
      multipleFoods,
      multipleDrinks,
    });
  }, [
    unassignedFoodTables,
    unassignedDrinksTables,
    unassignedAllTables,
    multipleFoods,
    multipleDrinks,
  ]);

  const problemsMissing =
    missing.ALL.length !== 0 || missing.FOOD.length !== 0 || missing.DRINKS.length !== 0;

  const problemsMultiple = multipleFoods.length !== 0 || multipleDrinks.length !== 0;

  return (
    <>
      {(problemsMissing || problemsMultiple) && (
        <>
          {problemsMissing && (
            <Card className={classes.warningCard}>
              <CardContent className={classes.heading}>
                <TableWarningMissing
                  showDevicesLink={showDevicesLink}
                  missing={missing}
                  classes={classes}
                  isAdmin={isAdmin()}
                />
              </CardContent>
            </Card>
          )}
          {problemsMultiple && (
            <Card className={classes.warningCard}>
              <CardContent className={classes.heading}>
                <TableWarningMultiple
                  showDevicesLink={showDevicesLink}
                  missing={missing}
                  classes={classes}
                  isAdmin={isAdmin()}
                />
              </CardContent>
            </Card>
          )}
        </>
      )}
    </>
  );
};

export default TableWarning;
