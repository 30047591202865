import { pick } from 'ramda';
import { get, patch, post } from './httpRequest';

// function that will iterate over a values object and remove any values recursively that are empty strings

const removeEmptyStrings = (values) => {
  const result = {};
  Object.keys(values).forEach((key) => {
    if (typeof values[key] === 'object') {
      result[key] = removeEmptyStrings(values[key]);
    } else if (values[key] !== '') {
      result[key] = values[key];
    }
  });
  return result;
};

export const createCustomer = async (values) => {
  await post(`/stripe-customer`, {
    data: pick(['address', 'email', 'phone', 'vatNumber', 'name', 'enabled'], values),
  });
};

export const fetchCustomer = async () => {
  const response = await get(`/stripe-customer`);
  return response.data;
};

export const updateCustomer = async (values) => {
  const payload = removeEmptyStrings(
    pick(['address', 'email', 'phone', 'name', 'enabled'], values),
  );
  if (payload.vatNumber === '') delete payload.vatNumber;
  await patch(`/stripe-customer`, {
    data: payload,
  });
};
