import React, { useCallback, useEffect, useRef, useState } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Box,
  Button,
  Dialog,
  Paper,
  Typography,
  List,
  TextField,
  InputAdornment,
  CircularProgress,
} from '@mui/material';
import { Search } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import QRCode from 'qrcode.react';
import Fuse from 'fuse.js';

import { MuiColorInput, matchIsValidColor } from 'mui-color-input';
import InfiniteScroll from 'react-infinite-scroll-component';
import Page from '../../components/Page';
import UniversalSave from '../../components/UniversalSave';
import PhotoUploadDialog from '../../components/PhotoUploader/PhotoUploadDialog';
import PhotoUploadCropper from '../../components/PhotoUploader/PhotoUploadCropper';
import TabContainer from '../../components/TabContainer';

import withVenue from '../../hoc/withVenue';
import FontButton from './FontButton';

import {
  getBannerGalleryState,
  getPersonalisationState,
} from '../../store/personalisation/selectors';
import {
  clearBannerGallery,
  clearPersonalisation,
  fetchBannersGallery,
  fetchPersonalisation,
  updatePersonalisation,
} from '../../store/personalisation';

import blobToBase64 from '../../shared/utils/blobToBase64';
import isMobile from '../../shared/utils/isMobile';
import shouldLoad from '../../shared/utils/shouldLoad';
import { enrichStation } from '../../shared/utils/openingTimesUtils';
import PageHeader from '../../components/PageHeader';
import {
  calculateContrastRatio,
  fetchPaginatedFonts,
  generateGoogleFontsURL,
  loadFonts,
  removeFonts,
  fetchGoogleFonts,
} from './utils';
import CustomWarningBanner from '../../components/CustomWarningBanner';

const frameHeight = 384;
const frameScale = frameHeight / 720;

const useStyles = makeStyles((theme) => ({
  pageLayout: {
    display: 'flex',
    flex: '1 0 auto',
    flexDirection: 'column',
    height: '100%',
  },
  heading: {
    marginBottom: '0.75rem',
    display: 'flex',
  },
  title: {
    fontFamily: 'Sen',
    flexGrow: 1,
  },
  previewHolder: {
    gap: '1rem',
    overflowX: 'auto',
    marginLeft: theme.spacing(-3),
    marginRight: theme.spacing(-3),
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  iframeHolder: {
    '&:first-of-type': {
      marginLeft: theme.spacing(3),
    },
    '&:last-of-type': {
      marginRight: theme.spacing(3),
    },
  },
  iframeHidden: {
    visibility: 'hidden',
  },
  preview: {
    border: 'none',
    borderRadius: '1rem',
    pointerEvents: 'none',
    overflowX: 'none',
    backgroundColor: '#fdfdfd',
    transform: `scale(${frameScale})`,
    transformOrigin: 'top left',
    marginBottom: `calc(720px * (${frameScale} - 1))`,
    marginRight: `calc(360px * (${frameScale} - 1))`,
    [theme.breakpoints.up('lg')]: {
      transform: `scale(1)`,
      marginBottom: `0`,
      marginRight: `0`,
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row-reverse',

      justifyContent: 'flex-end',
    },
  },
  toolbar: {
    width: `calc(100% + ${theme.spacing(6)})`,

    margin: theme.spacing(-3),
    marginTop: '0.75rem',
    padding: theme.spacing(3),
    flexGrow: 1,
    [theme.breakpoints.up('lg')]: {
      maxWidth: '31rem',
      flexGrow: 1,
      margin: theme.spacing(3),
      marginTop: 0,
      marginLeft: 0,
    },
  },
  toolsHolder: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  column: {
    padding: '2rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  subtitle: {
    fontFamily: 'Sen',
    fontWeight: 'bold',
    marginBottom: '1rem',
    marginTop: '1rem',
  },
  tabPanel: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  previewImage: {
    width: '120px',
    minHeight: '60px',
    border: '#fff solid 1px',
    borderRadius: '4px',
    boxShadow: '0px 0px 0px 3px #000000',
  },
  noPhoto: {
    width: '120px',
    minHeight: '60px',
    border: '#888888 solid 1px',
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  preselectedPhoto: {
    width: '120px',
    borderRadius: '4px',
  },
  buttons: {
    gap: '10px',
    marginBottom: theme.spacing(1),
  },
  buttonNoPadding: {
    padding: 0,
  },
  searchInput: {
    marginBottom: '10px',
    width: '100%',
    borderRadius: '8px',
    height: '40px',
  },
  colourPicker: {
    '& .MuiColorInput-Button': {
      minWidth: '22px',
      minHeight: '22px',
    },
  },
}));

const Personalisation = ({ venue }) => {
  const { venueId } = venue;
  const { isOpen } = enrichStation(venue);

  const dispatch = useDispatch();
  const personalisationState = useSelector(getPersonalisationState);
  const bannersGalleryState = useSelector(getBannerGalleryState);
  const { loading, data, error } = personalisationState;
  const { data: banners } = bannersGalleryState;
  const { personalisation, bannerImgUploadUrl } = data || {};
  const { colour, font, bannerImgUrl } = personalisation || {};
  const classes = useStyles();
  const webappPath = venueId && `${process.env.REACT_APP_WEBAPP_URI}/${venueId}/menu-view`;
  const webappPath2 =
    venueId && `${process.env.REACT_APP_WEBAPP_URI}/menu/${venueId}?modalOpen=true&itemId=1`;
  const [availableFonts, setAvailableFonts] = useState([]);
  const [fonts, setFonts] = useState([]);
  const [currentFont, setCurrentFont] = useState();
  const [searchFonts, setSearchFonts] = useState([]);
  const [isSearch, setIsSearch] = useState(false);
  const [currentColor, setCurrentColor] = useState(null);
  const [clearPreview, setClearPreview] = useState(false);
  const [showUpload, setShowUpload] = useState(false);
  const [showCropper, setShowCropper] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [localFile, setLocalFile] = useState(null);
  const [croppedBlob, setCroppedBlob] = useState(null);
  const [localBannerUrl, setLocalBannerUrl] = useState(null);
  const [resetIframe, setResetIframe] = useState(0);
  const [tabValue, setTabValue] = useState('Color');
  const [modifiedPersonalisation, setModifiedPersonalisation] = useState(null);
  const [noResults, setNoResults] = useState();
  const [searchError, setSearchError] = useState();
  const [searchValue, setSearchValue] = useState();
  const [start, setStart] = useState(0);
  const [hasMore, setHasMore] = useState(true);

  const previewRefs = [useRef(null), useRef(null)];

  const [qrDialogOpen, setQrDialogOpen] = useState(false);

  const contrastRatio = currentColor && calculateContrastRatio(currentColor);

  const handleSearch = async (e) => {
    setSearchValue(e.target.value);
    if (e.target.value.length >= 3) {
      setIsSearch(true);
      setSearchError('');
      const googleFonts = await fetchGoogleFonts();
      const searchTargetValue = e.target.value;
      const fuse = new Fuse(googleFonts, {
        keys: ['family'],
        threshold: 0.3,
      });
      const result = fuse.search(searchTargetValue);
      const fontData = result.map((item) => item.item);
      setSearchFonts(fontData);
      const googleFontsURL = generateGoogleFontsURL(fontData);
      loadFonts(googleFontsURL);
      setNoResults(false);
      setHasMore(false);
      if (fontData.length === 0) {
        setNoResults(true);
      }
    } else {
      setIsSearch(false);
      setNoResults(false);
      setSearchError('Please enter at least 3 characters');
    }
  };

  const loadMoreFonts = async () => {
    const newFonts = await fetchPaginatedFonts(start + 20, start + 40);
    setStart(start + 20);
    setFonts([...fonts, ...newFonts]);

    const googleFontsURL = generateGoogleFontsURL(newFonts);
    loadFonts(googleFontsURL);

    if (newFonts.length === 0) {
      setHasMore(false);
    }
  };

  const onTabChange = (newTabTitle) => {
    setTabValue(newTabTitle);
  };

  const openExternalLink = (url) => {
    if (!isMobile) {
      setQrDialogOpen(true);
    } else {
      window.open(url, '_blank');
    }
  };

  const setColor = (col = '#333333') => {
    previewRefs.map((ref) => {
      ref.current.contentWindow.postMessage(
        {
          type: 'servedup',
          colour: col,
        },
        process.env.REACT_APP_WEBAPP_URI,
      );
      return null;
    });
    setModifiedPersonalisation({ ...modifiedPersonalisation, colour: col });
  };

  const setFont = (newFont = 'Sen') => {
    previewRefs.map((ref) => {
      ref.current.contentWindow.postMessage(
        {
          type: 'servedup',
          font: newFont,
        },
        process.env.REACT_APP_WEBAPP_URI,
      );
      return null;
    });
    setModifiedPersonalisation({ ...modifiedPersonalisation, font: newFont });
    setCurrentFont(newFont);
  };

  const setBannerBlob = async (blob) => {
    setCroppedBlob(blob);
    setClearPreview(false);
    const base64data = await blobToBase64(blob);
    previewRefs.map((ref) => {
      if (ref.current)
        ref.current.contentWindow.postMessage(
          {
            type: 'servedup',
            bannerImgUrl: base64data,
          },
          process.env.REACT_APP_WEBAPP_URI,
        );
      return null;
    });
    setShowCropper(false);
    setModifiedPersonalisation({ ...modifiedPersonalisation, hasBanner: true });
  };

  const setBannerUrl = async (url) => {
    setLocalBannerUrl(url);
    setClearPreview(false);
    previewRefs.map((ref) => {
      if (ref.current)
        ref.current.contentWindow.postMessage(
          {
            type: 'servedup',
            bannerImgUrl: `${url}?c=${Math.random()}`,
          },
          process.env.REACT_APP_WEBAPP_URI,
        );
      return null;
    });
    setModifiedPersonalisation({ ...modifiedPersonalisation, hasBanner: !!url });
  };

  const setBannerByName = (url, name) => {
    setBannerUrl(url);
    setModifiedPersonalisation({
      ...modifiedPersonalisation,
      galleryBannerImage: name,
    });
  };

  const nullifyBanner = () => {
    setBannerUrl(null);
    setLocalFile(null);
    setCroppedBlob(null);
    setLocalBannerUrl(null);
    setClearPreview(true);
    previewRefs.map((ref) => {
      if (ref.current)
        ref.current.contentWindow.postMessage(
          {
            type: 'servedup',
            bannerImgUrl: 'none',
          },
          process.env.REACT_APP_WEBAPP_URI,
        );
      return null;
    });
    setModifiedPersonalisation({ ...modifiedPersonalisation, hasBanner: false });
  };

  const handleSetColor = (newColor) => {
    setCurrentColor(newColor);
    setColor(newColor);
  };

  const handleCapture = async ({ target }) => {
    const file = target.files[0];
    setLocalFile(file);
    setShowCropper(true);
    // This enables the same photo to be selected more than once
    // eslint-disable-next-line no-param-reassign
    target.value = null;
  };

  const saveChanges = async () => {
    if (localFile) {
      setShowUpload(true);
    } else {
      await dispatch(updatePersonalisation({ values: modifiedPersonalisation }));
      await dispatch(fetchPersonalisation());
    }
    setFonts(availableFonts);
    setIsSearch(false);
    setSearchValue('');
  };

  const discardChanges = useCallback(() => {
    const strippedPersonalisation = { font, colour };
    setModifiedPersonalisation(strippedPersonalisation);
    setCurrentColor(colour);
    setLocalBannerUrl(null);
    setResetIframe(resetIframe + 1);
    setFonts(availableFonts);
    setIsSearch(false);
    setCurrentFont(font);
    setSearchValue('');
  }, [availableFonts, colour, font, resetIframe]);

  const handleSuccessfulUpload = async () => {
    await dispatch(updatePersonalisation({ values: modifiedPersonalisation }));
    await dispatch(fetchPersonalisation());
  };

  useEffect(() => {
    const strippedPersonalisation = { font, colour };
    setModifiedPersonalisation(strippedPersonalisation);
  }, [colour, font, personalisation]);

  useEffect(() => {
    if (shouldLoad(personalisationState)) dispatch(fetchPersonalisation());
    if (shouldLoad(bannersGalleryState)) dispatch(fetchBannersGallery());
  }, [dispatch, personalisationState, bannersGalleryState]);

  useEffect(() => {
    setCurrentColor(colour);
  }, [colour]);

  useEffect(() => {
    const {
      colour: modifiedColour,
      font: modifiedFont,
      hasBanner,
      galleryBannerImage,
    } = modifiedPersonalisation || {};
    if (
      colour !== modifiedColour ||
      font !== modifiedFont ||
      hasBanner !== undefined ||
      galleryBannerImage !== undefined
    ) {
      setIsDirty(true);
    } else {
      setIsDirty(false);
    }
  }, [colour, font, localFile, modifiedPersonalisation]);

  useEffect(() => {
    const loadAllFonts = async () => {
      const fontFamilies = await fetchPaginatedFonts();
      const googleFontsURL = font
        ? generateGoogleFontsURL(fontFamilies, font)
        : generateGoogleFontsURL(fontFamilies);
      loadFonts(googleFontsURL);
      setAvailableFonts(fontFamilies);
      setFonts(fontFamilies);
      setCurrentFont(font);
    };

    loadAllFonts();
  }, [font]);

  useEffect(
    () => () => {
      removeFonts();
    },
    [],
  );

  return (
    <>
      <PageHeader fullWidth>
        <div className={classes.heading}>
          <Typography className={classes.title} variant="h1" component="h1">
            Menu Design
          </Typography>
          <Button variant="outlined" color="primary" onClick={() => openExternalLink(webappPath)}>
            View QR menu
          </Button>
        </div>
      </PageHeader>
      <UniversalSave dirty={isDirty} onSave={saveChanges} onDiscard={discardChanges} />
      <Page loading={loading} error={error} fullWidth>
        <div className={classes.pageLayout}>
          <Box className={classes.content}>
            <Box display="flex" flexDirection="row" className={classes.previewHolder}>
              <div className={classes.iframeHolder}>
                <iframe
                  key={resetIframe}
                  src={webappPath}
                  ref={previewRefs[0]}
                  className={classes.preview}
                  title="App Customisation"
                  width="360px"
                  height="720px"
                  scrolling="no"
                />
              </div>
              <div
                className={`${classes.iframeHolder} ${
                  (tabValue === 'photo' || !isOpen) && classes.iframeHidden
                }`}
              >
                <iframe
                  key={resetIframe}
                  src={webappPath2}
                  ref={previewRefs[1]}
                  className={classes.preview}
                  title="App Customisation"
                  width="360px"
                  height="720px"
                />
              </div>
            </Box>
            <Paper className={classes.toolbar}>
              <TabContainer titles={['Color', 'Font', 'Banner Photo']} onChange={onTabChange}>
                <Box>
                  <Box sx={{ pb: 1 }}>
                    <p>Main colour</p>
                    <MuiColorInput
                      value={currentColor || ''}
                      onChange={(color) => {
                        handleSetColor(color);
                      }}
                      error={!matchIsValidColor(currentColor || '')}
                      format="hex"
                      sx={{ width: 1 }}
                      size="small"
                      isAlphaHidden
                      className={classes.colourPicker}
                    />
                  </Box>
                  {contrastRatio <= 4.5 && contrastRatio > 2 && (
                    <CustomWarningBanner
                      title="This colour is quite light and some people may struggle to read text."
                      titleFont="smallBold"
                      titleColor="#000000"
                      backgroundColor="#FFE87A"
                      iconColor="#000000"
                      borderRadius="8px"
                    />
                  )}
                  {contrastRatio <= 2 && (
                    <CustomWarningBanner
                      title="This colour is very light and many people may struggle to read and use your menu."
                      titleFont="smallBold"
                      titleColor="white"
                      backgroundColor="#CA1D2A"
                      iconColor="#FFFFFF"
                      borderRadius="8px"
                    />
                  )}
                </Box>
                <Box>
                  <Box sx={{ display: 'flex', pb: 2 }}>
                    <Typography>Selected font: </Typography>
                    <Typography sx={{ fontFamily: currentFont, ml: 1 }}>{currentFont}</Typography>
                  </Box>
                  <TextField
                    helperText={searchError}
                    className={classes.searchInput}
                    id="outlined"
                    label="Search"
                    value={searchValue}
                    variant="outlined"
                    onChange={handleSearch}
                    size="small"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Search />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Box sx={{ maxHeight: { xs: '450px', md: '100vh' } }}>
                    {noResults && <Typography>No results</Typography>}
                    {isSearch ? (
                      <InfiniteScroll
                        dataLength={searchFonts.length}
                        hasMore={hasMore}
                        height="500px"
                        loader={
                          <Box sx={{ my: 4, display: 'flex', justifyContent: 'center' }}>
                            <CircularProgress size={32} />
                          </Box>
                        }
                      >
                        <List>
                          {searchFonts.map((displayFont) => (
                            <FontButton
                              font={displayFont}
                              key={displayFont}
                              clickHandler={setFont}
                              currentFont={currentFont}
                            />
                          ))}
                        </List>
                      </InfiniteScroll>
                    ) : (
                      <InfiniteScroll
                        dataLength={fonts.length}
                        next={loadMoreFonts}
                        hasMore={hasMore}
                        height="500px"
                        loader={
                          <Box sx={{ my: 4, display: 'flex', justifyContent: 'center' }}>
                            <CircularProgress size={32} />
                          </Box>
                        }
                      >
                        <List>
                          {fonts.map((displayFont) => (
                            <FontButton
                              font={displayFont}
                              key={displayFont}
                              clickHandler={setFont}
                              currentFont={currentFont}
                            />
                          ))}
                        </List>
                      </InfiniteScroll>
                    )}
                  </Box>
                </Box>
                <>
                  <p>Custom Photo</p>
                  <p style={{ color: '#647F99' }}>Accepted files: JPG, JPEG, PNG.</p>
                  {(croppedBlob || (bannerImgUrl && !clearPreview)) && (
                    <p>
                      <img
                        className={classes.previewImage}
                        src={
                          croppedBlob
                            ? URL.createObjectURL(croppedBlob)
                            : `${localBannerUrl || bannerImgUrl}?c=${Math.random()}`
                        }
                        alt="banner"
                      />
                    </p>
                  )}
                  <br />
                  <Box display="flex" flexDirection="row" className={classes.buttons}>
                    <Button variant="outlined" color="primary" component="label">
                      Upload
                      <input
                        type="file"
                        hidden
                        onChange={handleCapture}
                        accept="image/jpeg, image/png"
                      />
                    </Button>
                  </Box>
                  <p>Choose from our gallery</p>
                  <Box
                    display="flex"
                    flexDirection="row"
                    flexWrap="wrap"
                    className={classes.buttons}
                  >
                    <Button
                      type="button"
                      onClick={nullifyBanner}
                      disableElevation
                      disableFocusRipple
                      disableRipple
                      className={classes.buttonNoPadding}
                    >
                      <span className={classes.noPhoto}>No Photo</span>
                    </Button>
                    {banners?.map(({ url, bannerName }) => (
                      <Button
                        key={bannerName}
                        type="button"
                        onClick={() => setBannerByName(url, bannerName)}
                        disableElevation
                        disableFocusRipple
                        disableRipple
                        className={classes.buttonNoPadding}
                      >
                        <img className={classes.preselectedPhoto} src={url} alt="banner" />
                      </Button>
                    ))}
                  </Box>
                </>
              </TabContainer>
            </Paper>
          </Box>
        </div>
        <Dialog onClose={() => setShowCropper(false)} open={showCropper} fullWidth maxWidth="md">
          <PhotoUploadCropper
            handleCloseDialog={() => setShowCropper(false)}
            file={localFile}
            handleUpload={setBannerBlob}
            aspectRatio={2}
            title="Product photo"
          />
        </Dialog>
        <PhotoUploadDialog
          saveLocalFile={showUpload}
          handleCloseDialog={() => setShowUpload(false)}
          imgUploadUrl={bannerImgUploadUrl}
          localFile={croppedBlob}
          handleSuccessfulUpload={handleSuccessfulUpload}
          isOpen={false}
        />
      </Page>
      <Dialog onClose={() => setQrDialogOpen(false)} open={qrDialogOpen}>
        <Box className={classes.column}>
          <QRCode value={webappPath} size={200} />
          <Typography variant="h4" className={classes.subtitle}>
            Scan your QR code with your phone to make an order
          </Typography>
          <Button variant="outlined" color="primary" onClick={() => setQrDialogOpen(false)}>
            Close
          </Button>
        </Box>
      </Dialog>
    </>
  );
};

export default withVenue(Personalisation, null, [clearPersonalisation, clearBannerGallery]);
