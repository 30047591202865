import { createSelector } from '@reduxjs/toolkit';
import { ascend, prop, sortWith, path } from 'ramda';
import { getBrandsIngredientsState } from '../brandsIngredients/selectors';
import { getModifiers } from '../brandsModifiers/selectors';
import { getBuilderMenuBrandId } from '../builderMenu/builderMenus/selectors';

const menuItemSort = sortWith([
  ascend(prop('type')),
  ascend(prop('category')),
  ascend(prop('itemName')),
]);

export const getBrandsMenuItemsState = (state) => state.brandsMenuItems;
export const getBrandsMenuItemState = (state) => state.brandsMenuItem;

const getMenuItemId = (_, menuItemId) => menuItemId;

export const getBrandsMenuItems = createSelector(
  getBrandsMenuItemsState,
  (brandsMenuItemsState) => brandsMenuItemsState.data && menuItemSort(brandsMenuItemsState.data),
);

export const getBrandsMenuItem = createSelector(
  getBrandsMenuItems,
  getMenuItemId,
  (menuItems, menuItemId) =>
    menuItems && menuItems.find((menuItem) => menuItem.itemId === menuItemId),
);

const getItemType = createSelector(
  getBrandsMenuItemState,
  (menuItem) => menuItem && menuItem.data.type,
);

const getItemBrandId = createSelector(
  getBrandsMenuItemState,
  (menuItem) => menuItem && menuItem.data.brandId,
);

const getAttribute = (_, attribute) => attribute;

export const getBrandsAttributeValues = createSelector(
  getBrandsMenuItemState,
  getAttribute,
  (menuItem, attribute) => path(['data', attribute], menuItem) || [],
);

export const getBrandsIngredientsForItemType = createSelector(
  getItemType,
  getBrandsIngredientsState,
  getItemBrandId,
  (itemType, ingredients, brandId) => {
    if (!itemType || !ingredients.data) return null;
    return ingredients.data.reduce((acc, ingredient) => {
      const { ingredientId, type: ingredientType } = ingredient;
      if (ingredientType === itemType && ingredient.brandId === brandId) {
        acc.push(ingredientId);
      }
      return acc;
    }, []);
  },
);

export const getBrandsModifiersForBrand = createSelector(
  getModifiers,
  getItemBrandId,
  (modifiers, brandId) => {
    if (!modifiers) return null;
    return modifiers.reduce((acc, modifier) => {
      const { modifierId } = modifier;
      if (modifier.brandId === brandId) {
        acc.push(modifierId);
      }
      return acc;
    }, []);
  },
);

export const getBrandsMenuItemsForBrand = createSelector(
  getBrandsMenuItems,
  getBuilderMenuBrandId,
  (menuItems, brandId) => {
    if (!menuItems) return null;
    return menuItems.reduce((acc, menuItem) => {
      if (menuItem.brandId === brandId) {
        acc.push(menuItem);
      }
      return acc;
    }, []);
  },
);
