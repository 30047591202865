import React, { useEffect, useState } from 'react';
import { Typography, Box, Select, MenuItem, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';

import PageHeader from '../../components/PageHeader';
import Page from '../../components/Page';
import getSessionsScoreState from '../../store/sessionsScore/selectors';
import shouldLoad from '../../shared/utils/shouldLoad';
import { clearSessionsScore, fetchSessionsScore } from '../../store/sessionsScore';
import withVenue from '../../hoc/withVenue';
import ImprovementAction from './Scores/ImprovementAction';
import YourCashback from './Scores/YourCashback';
import CashbackBreakdown from './Scores/CashbackBreakdown';
import InfoTooltip from './Scores/InfoTooltip';

const useStyles = makeStyles((theme) => ({
  pageHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  datePeriodSelect: {
    backgroundColor: theme.palette.common.white,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  gridItem: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  gridRow: {
    marginTop: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      marginTop: 'unset',
    },
  },
  errorContainer: {
    marginTop: theme.spacing(4),
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
}));

const timePeriods = {
  thisFortnight: 'THIS_FORTNIGHT',
  lastFortnight: 'LAST_FORTNIGHT',
};

const DataMissing = () => {
  const classes = useStyles();

  return (
    <Box className={classes.errorContainer}>
      <Typography variant="h2" component="h2">
        Error
      </Typography>
      <Typography variant="body1" component="p">
        There was an error loading the Sessions Score.
      </Typography>
    </Box>
  );
};

const SessionsScore = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [datePeriod, setDatePeriod] = useState(timePeriods.lastFortnight);
  const [scoreData, setScoreData] = useState(null);
  const sessionsScoreState = useSelector(getSessionsScoreState);
  const { loading, data, error } = sessionsScoreState;
  const thisFortnightAvailable = !!data?.find(
    (score) => score.timePeriod === timePeriods.thisFortnight,
  );

  useEffect(() => {
    if (shouldLoad(sessionsScoreState)) {
      dispatch(fetchSessionsScore());
    }
  }, [dispatch, sessionsScoreState]);

  useEffect(() => {
    setScoreData(data?.find((score) => score.timePeriod === datePeriod) || null);
  }, [data, datePeriod]);

  return (
    <>
      <PageHeader fullWidth className={classes.pageHeader}>
        <Box>
          <Typography variant="h1" component="h1">
            Sessions Score
          </Typography>
          <Typography variant="body1" component="p">
            The Sessions Score helps you understand how you could be making more money on your
            delivery orders!
          </Typography>
        </Box>
        <Select
          value={datePeriod}
          onChange={(e) => setDatePeriod(e.target.value)}
          variant="outlined"
          className={classes.datePeriodSelect}
        >
          {thisFortnightAvailable ? (
            <MenuItem value={timePeriods.thisFortnight}>This Fortnight</MenuItem>
          ) : (
            <InfoTooltip text="Check back tomorrow">
              <span>
                <MenuItem value={timePeriods.thisFortnight} disabled={!thisFortnightAvailable}>
                  This Fortnight
                </MenuItem>
              </span>
            </InfoTooltip>
          )}
          <MenuItem value={timePeriods.lastFortnight}>Last Fortnight</MenuItem>
        </Select>
      </PageHeader>
      <Page loading={loading} fullWidth>
        {error || (!loading && !scoreData) || (!thisFortnightAvailable && !scoreData) ? (
          <DataMissing />
        ) : (
          <>
            <Grid container spacing={2}>
              <Grid item md={12} className={classes.gridItem}>
                <ImprovementAction metric={scoreData?.actionMetric} text={scoreData?.actionText} />
              </Grid>
            </Grid>
            <Grid container spacing={2} className={classes.gridRow}>
              <Grid item xs={12} md={8} className={classes.gridItem}>
                <CashbackBreakdown scoreData={scoreData} />
              </Grid>
              <Grid item xs={12} md={4} className={classes.gridItem}>
                <YourCashback scoreData={scoreData} />
              </Grid>
            </Grid>
          </>
        )}
      </Page>
    </>
  );
};

export default withVenue(SessionsScore, null, [clearSessionsScore]);
