import { createSlice } from '@reduxjs/toolkit';
import {
  asyncInitialState as initialState,
  createAsyncAction,
  createAsyncReducerHandlers,
} from '../utils/async';
import {
  fetchModifiers as fetchModifiersApi,
  fetchModifier as fetchModifierApi,
  createModifier as createModifierApi,
  updateModifier as updateModifierApi,
  deleteModifier as deleteModifierApi,
} from '../../api/modifiers';

export const fetchModifiers = createAsyncAction('fetch-modifiers', fetchModifiersApi);

export const fetchModifier = createAsyncAction('fetch-modifier', fetchModifierApi);

export const deleteModifier = createAsyncAction('delete-modifier', deleteModifierApi);

export const createModifier = createAsyncAction('create-modifier', createModifierApi);

export const updateModifier = createAsyncAction('update-modifier', updateModifierApi);

const modifiersSlice = createSlice({
  name: 'modifiers',
  initialState,
  reducers: {
    clearModifiers(state) {
      delete state.data;
    },
  },
  extraReducers: {
    ...createAsyncReducerHandlers(fetchModifiers),
    ...createAsyncReducerHandlers(deleteModifier),
  },
});

const modifierSlice = createSlice({
  name: 'modifier',
  initialState,
  reducers: {
    clearModifier(state) {
      delete state.data;
    },
  },
  extraReducers: {
    ...createAsyncReducerHandlers(fetchModifier),
    ...createAsyncReducerHandlers(createModifier),
    ...createAsyncReducerHandlers(updateModifier),
  },
});
export const { clearModifier } = modifierSlice.actions;
export const { clearModifiers } = modifiersSlice.actions;

export const { reducer: modifiers } = modifiersSlice;
export const { reducer: modifier } = modifierSlice;
