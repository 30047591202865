import React from 'react';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import formatNumber from '../../shared/utils/formatNumber';
import PercentageStatusLozenge from '../PercentageStatusLozenge';
import deliveryPlatforms from '../../shared/constants/deliveryPlatforms';

const useStyles = makeStyles((theme) => ({
  row: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  title: {
    ...theme.customFonts.medium,
    color: theme.customPalette.greyDarker,
    flex: '1',
  },
  total: {
    ...theme.customFonts.medium,
    color: theme.customPalette.greyDarker,
  },
  divider: {
    borderTop: '1px solid #D9E2EC',
    margin: theme.spacing(2, 0),
  },
}));

const ReportsPlatformBreakdown = ({
  data,
  field,
  negativePercentIsPositive = false,
  suffix = '',
  platforms = deliveryPlatforms,
}) => {
  const classes = useStyles();

  const displayData = platforms.map(({ name, key }) => ({
    title: name,
    total: data.current[field][key],
    current: data.current[field][key],
    previous: data.previous[field][key],
  }));

  return (
    <>
      <div className={classes.divider} />
      {displayData.map(({ title, total, current, previous }) => (
        <div key={title} className={classes.row}>
          <Typography className={classes.title}>{title}</Typography>
          {total ? (
            <>
              <Typography className={classes.total}>
                {formatNumber(total)}
                {suffix}
              </Typography>
              <PercentageStatusLozenge
                current={current}
                previous={previous}
                negativePercentIsPositive={negativePercentIsPositive}
              />
            </>
          ) : (
            <Typography className={classes.total}>No data for time period</Typography>
          )}
        </div>
      ))}
    </>
  );
};

export default ReportsPlatformBreakdown;
