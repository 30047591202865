import { createTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import customFonts from './customFonts';
import { customPalette, sessionsScorePalette } from './colourPalettes';
import {
  MuiButton,
  MuiTextField,
  MuiAppBar,
  MuiDrawer,
  MuiMenu,
  MuiMenuItem,
  MuiListItem,
  MuiListItemIcon,
} from './componentOverrides';

const theme = createTheme({
  customFonts,
  customPalette,
  sessionsScorePalette,
  palette: {
    mode: 'light',
    primary: {
      main: '#333333',
      light: '#dbe2eb',
      dark: '#223344',
    },
    secondary: {
      main: '#1870ed',
      light: '#dbe2eb',
      dark: '#223344',
    },
    background: {
      default: '#f0f4f8',
      paper: '#ffffff',
    },
    text: {
      primary: '#333333',
      secondary: '#384e66',
    },
    ok: {
      main: '#00FF00',
    },
    error: {
      main: '#BC3034',
    },
  },
  typography: {
    fontFamily: ['Sen', 'sans-serif', 'Arial'].join(','),
    fontWeightBold: 600,
    h1: {
      fontSize: '1.5rem',
      lineHeight: 1.5,
      fontWeight: 600,
    },
    h2: {
      fontSize: '1.25rem',
      lineHeight: 1.5,
      fontWeight: 600,
    },
    h3: {
      fontSize: '0.7rem',
      lineHeight: 1.5,
      fontWeight: 600,
    },
    h4: {
      fontSize: '1rem',
      lineHeight: 1.5,
      fontWeight: 600,
    },
    subtitle1: {
      fontSize: '0.75rem',
      color: '#878E95',
    },
    subtitle2: {
      fontSize: '1rem',
    },
    body2: {
      fontSize: '1rem',
    },
  },
  components: {
    MuiAppBar,
    MuiDrawer,
    MuiMenu,
    MuiMenuItem,
    MuiListItem,
    MuiListItemIcon,
    MuiButton,
    MuiTextField,
  },
});

export default theme;

export const customFontsClasses = makeStyles(() => customFonts);
export const customPaletteClasses = makeStyles(() => customPalette);
