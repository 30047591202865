import { getBare, postBare } from '../httpRequest';

export const fetchBrandsMenusListApi = async () => {
  const response = await getBare('brands/menus/list');
  return response.data;
};

export const fetchBrandsMenusApi = async () => {
  const response = await getBare('brands/menus');
  return response.data;
};

export const createBrandsMenuApi = async (menu) => {
  const response = await postBare('brands/menus', {
    data: menu,
  });

  return response.data;
};
