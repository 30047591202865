import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Card,
  CardContent,
  FormGroup,
  Button,
  Typography,
  FormControlLabel,
  Box,
  TextField as MuiTextField,
  MenuItem,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Formik, Form, Field, FieldArray } from 'formik';
import { Switch, TextField } from 'formik-mui';
import * as Yup from 'yup';
import { useNotifications } from '../../shared/contexts/Notifications/useNotifications';
import { getErrorMessage } from '../../shared/utils/errors';
import { fetchAvailableDeviceCapabilities } from '../../store/devices';
import { getAvailableDeviceCapabilitiesState } from '../../store/devices/selectors';
import { updateMobileDevice } from '../../store/mobiles';
import { getTerminalDevicesState } from '../../store/terminals/selectors';
import useRoles from '../../hooks/useRoles';
import useEndPoint from '../../hooks/useEndpoint';
import UniversalSave from '../UniversalSave';
import { fetchTables } from '../../store/tables';
import { getTablesState } from '../../store/tables/selectors';
import isVersionAbove from '../../shared/utils/isVersionAbove';

const useStyles = makeStyles(() => ({
  heading: {
    margin: '15px 0',
  },
  card: {
    marginTop: '20px',
    marginBottom: '20px',
  },
}));

const EditMobileDeviceForm = ({
  redirect,
  deviceData: {
    mobileDeviceId,
    name,
    enabled,
    capabilities,
    description,
    readerSerialNumber,
    readerId,
    reader,
    kandjiId,
    appVersion,
    serialId,
    settings,
    terminalId,
    printerId,
  },
  printerData,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { isAdmin } = useRoles();
  const { showErrorNotification, showSuccessNotification } = useNotifications();
  const { data: deviceCapabilities } = useSelector(getAvailableDeviceCapabilitiesState);
  const { data: terminalDevices } = useSelector(getTerminalDevicesState);
  const { data: tables } = useEndPoint(getTablesState, fetchTables()) || {};
  const printerIds = printerData?.map(({ printerId: id }) => id);

  const capabilityArray =
    deviceCapabilities &&
    deviceCapabilities.reduce((obj, cap) => ({ ...obj, [cap]: capabilities.includes(cap) }), {});
  const initialValues = {
    mobileDeviceId,
    name,
    enabled,
    terminalId: terminalId || '',
    capabilityArray,
    description: description || '',
    printerId: printerId || 'NONE',
    kandjiId,
    appVersion,
    serialId,
    settings: {
      acceptOrdersSetting: settings?.acceptOrdersSetting,
      cashPaymentsEnabled: settings?.cashPaymentsEnabled,
      serviceChargeOptionalTicked: settings?.serviceChargeOptionalTicked || false,
      orderAutoLockEnabled: settings?.orderAutoLockEnabled,
      autoLockTime: settings?.autoLockTime,
      defaultTable: settings?.defaultTable,
      groupSizes: settings?.groupSizes || false,
    },
  };

  const isValidVersion = isVersionAbove(appVersion, '1.4.71');

  const hasReader = readerId && readerId !== 'NONE';

  const onSubmit = async (updatedDevice) => {
    const newCapabilities = Object.entries(updatedDevice.capabilityArray).reduce(
      (newCapabilityList, capability) =>
        capability[1] ? [...newCapabilityList, capability[0]] : newCapabilityList,
      [],
    );
    let transformedUpdatedDevice = {
      ...updatedDevice,
      capabilities: newCapabilities,
    };
    try {
      if (transformedUpdatedDevice.terminalId === '') {
        transformedUpdatedDevice = { ...transformedUpdatedDevice, terminalId: null };
      }
      if (transformedUpdatedDevice.description === '') {
        delete transformedUpdatedDevice.description;
      }
      await dispatch(updateMobileDevice(transformedUpdatedDevice));
      showSuccessNotification('Device has been updated successfully');
    } catch (localError) {
      showErrorNotification(getErrorMessage(localError));
    }
    redirect();
  };

  const EditMobileDeviceSchema = Yup.object().shape({
    mobileDeviceId: Yup.string().oneOf([mobileDeviceId]).required(),
    description: Yup.string(),
    name: Yup.string().required(),
    settings: Yup.object().shape({
      acceptOrdersSetting: Yup.string().oneOf([
        'device_can_accept',
        'auto_accept',
        'device_cannot_accept',
      ]),
      cashPaymentsEnabled: Yup.boolean().optional(),
      serviceChargeOptionalTicked: Yup.boolean().required(),
      orderAutoLockEnabled: Yup.boolean().optional(),
      autoLockTime: Yup.number().optional(),
      defaultTable: Yup.string().optional(),
      groupSizes: Yup.boolean().required(),
    }),
    terminalId: Yup.string()
      .min(3, 'Too Short!')
      .optional('Please choose a Terminal')
      .nullable(true),
    printerId: Yup.string().optional().nullable(true),
  });

  useEffect(() => {
    dispatch(fetchAvailableDeviceCapabilities('EPOS'));
  }, [dispatch]);

  return (
    <>
      <Card>
        <Formik
          enableReinitialize
          onSubmit={onSubmit}
          validationSchema={EditMobileDeviceSchema}
          initialValues={initialValues}
        >
          {({ setFieldValue, values, dirty, isValid, resetForm, errors }) => (
            <>
              <UniversalSave
                isValid={isValid}
                errors={errors}
                dirty={dirty}
                onSave={() => onSubmit(values)}
                onDiscard={() => {
                  resetForm({ values: initialValues });
                }}
              />
              <Form>
                <CardContent>
                  <section>
                    <Field
                      component={TextField}
                      name="mobileDeviceId"
                      label="Device Id"
                      variant="outlined"
                      fullWidth
                      required
                      disabled
                      margin="normal"
                    />
                    <Field
                      component={TextField}
                      name="name"
                      label="Name"
                      variant="outlined"
                      fullWidth
                      required
                      disabled
                      margin="normal"
                    />
                    <Field
                      component={TextField}
                      name="description"
                      label="Description"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                    />
                    {terminalDevices?.length !== 0 && (
                      <Field
                        select
                        component={TextField}
                        name="terminalId"
                        label="Add a default Terminal Device"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                      >
                        <MenuItem value="">None</MenuItem>
                        {terminalDevices?.map(({ readerId: tId, name: terminalName }) => (
                          <MenuItem value={tId} key={tId}>
                            {`${terminalName} (${tId})`}
                          </MenuItem>
                        ))}
                      </Field>
                    )}
                    {terminalId && (
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        onClick={() => setFieldValue('terminalId', '')}
                      >
                        Remove Default Terminal
                      </Button>
                    )}
                  </section>
                  <section>
                    <FormGroup className={classes.section}>
                      <Typography className={classes.heading} variant="h4" component="h4">
                        Device Capabilities
                      </Typography>
                      <FieldArray
                        render={() =>
                          values.capabilityArray && (
                            <Box display="flex" flexDirection="row" flexWrap="wrap">
                              {Object.entries(values.capabilityArray).map((val) => (
                                <FormControlLabel
                                  key={val[0]}
                                  control={
                                    <Field
                                      component={Switch}
                                      color="primary"
                                      type="checkbox"
                                      name={`capabilityArray.${val[0]}`}
                                      checked={val[1]}
                                      value={val[1]}
                                      onChange={(_event, newValue) => {
                                        setFieldValue(`capabilityArray.${val[0]}`, newValue);
                                      }}
                                    />
                                  }
                                  label={val[0]}
                                />
                              ))}
                            </Box>
                          )
                        }
                      />
                    </FormGroup>
                  </section>
                  {printerIds?.length !== 0 && (
                    <section>
                      <Field
                        component={TextField}
                        name="printerId"
                        label="Printer & Cash Drawer"
                        variant="outlined"
                        select
                        fullWidth
                        disabled={!isValidVersion}
                        required
                        margin="normal"
                      >
                        <MenuItem value="NONE">None</MenuItem>
                        {printerIds?.map((id) => (
                          <MenuItem value={id} key={id}>
                            {id}
                          </MenuItem>
                        ))}
                      </Field>
                    </section>
                  )}
                  {isAdmin() && (
                    <section>
                      {kandjiId && (
                        <>
                          <Field
                            component={TextField}
                            name="kandjiId"
                            label="Kandji Id"
                            variant="outlined"
                            fullWidth
                            required
                            disabled
                            margin="normal"
                          />
                          <Field
                            component={TextField}
                            name="serialId"
                            label="Serial Id"
                            variant="outlined"
                            fullWidth
                            required
                            disabled
                            margin="normal"
                          />
                          <Field
                            component={TextField}
                            name="appVersion"
                            label="App Version"
                            variant="outlined"
                            fullWidth
                            required
                            disabled
                            margin="normal"
                          />
                          <Field
                            component={TextField}
                            name="settings.orderAutoLockEnabled"
                            label="Order auto lock enabled"
                            variant="outlined"
                            select
                            fullWidth
                            disabled={!isValidVersion}
                            required
                            margin="normal"
                          >
                            <MenuItem key="orderLockEnabled" value>
                              Enabled
                            </MenuItem>
                            <MenuItem key="orderLockDisableddisabled" value={false}>
                              Disabled
                            </MenuItem>
                          </Field>
                          <Field
                            component={TextField}
                            select
                            name="settings.cashPaymentsEnabled"
                            label="Cash payments enabled"
                            variant="outlined"
                            fullWidth
                            disabled={!isValidVersion}
                            required
                            margin="normal"
                          >
                            <MenuItem key="cashPaymentEnabled" value>
                              Enabled
                            </MenuItem>
                            <MenuItem key="cashPaymentDisabled" value={false}>
                              Disabled
                            </MenuItem>
                          </Field>
                          <Field
                            component={TextField}
                            name="settings.acceptOrdersSetting"
                            label="Accept orders setting"
                            variant="outlined"
                            select
                            fullWidth
                            disabled={!isValidVersion}
                            required
                            margin="normal"
                          >
                            <MenuItem key="deviceCanAccept" value="device_can_accept">
                              Device can accept
                            </MenuItem>
                            <MenuItem key="autoAccept" value="auto_accept">
                              Auto accept
                            </MenuItem>
                            <MenuItem key="devieCannotAccept" value="device_cannot_accept">
                              Device cannot accept
                            </MenuItem>
                          </Field>

                          <Field
                            component={TextField}
                            name="settings.serviceChargeOptionalTicked"
                            label="Service charge optional ticked"
                            variant="outlined"
                            select
                            fullWidth
                            disabled={!isValidVersion}
                            required
                            margin="normal"
                          >
                            <MenuItem key="serviceChargeOptionalEnabled" value>
                              Enabled
                            </MenuItem>
                            <MenuItem key="serviceChargeOptionalDisabled" value={false}>
                              Disabled
                            </MenuItem>
                          </Field>
                          <Field
                            component={TextField}
                            name="settings.autoLockTime"
                            label="Auto lock time"
                            variant="outlined"
                            fullWidth
                            disabled={!isValidVersion}
                            required
                            select
                            type="number"
                            margin="normal"
                          >
                            <MenuItem key="10Seconds" value={10}>
                              10 seconds
                            </MenuItem>
                            <MenuItem key="30Seconds" value={30}>
                              30 seconds
                            </MenuItem>
                            <MenuItem key="1Minute" value={60}>
                              1 minute
                            </MenuItem>
                            <MenuItem key="2Minutes" value={120}>
                              2 minutes
                            </MenuItem>
                            <MenuItem key="3Minutes" value={180}>
                              3 minutes
                            </MenuItem>
                            <MenuItem key="Never" value={0}>
                              Never
                            </MenuItem>
                          </Field>
                          <Field
                            component={TextField}
                            name="settings.defaultTable"
                            label="Default table"
                            variant="outlined"
                            select
                            fullWidth
                            disabled={!isValidVersion}
                            required
                            margin="normal"
                          >
                            <MenuItem value="NONE">None</MenuItem>
                            {tables?.map(({ tableName }) => (
                              <MenuItem value={tableName} key={tableName}>
                                {tableName}
                              </MenuItem>
                            ))}
                          </Field>
                          <Field
                            component={TextField}
                            name="settings.groupSizes"
                            label="Group sizes together"
                            variant="outlined"
                            select
                            fullWidth
                            disabled={!isValidVersion}
                            required
                            margin="normal"
                          >
                            <MenuItem key="groupSizesEnabled" value>
                              Enabled
                            </MenuItem>
                            <MenuItem key="groupSizesDisabled" value={false}>
                              Disabled
                            </MenuItem>
                          </Field>
                        </>
                      )}
                    </section>
                  )}
                </CardContent>
              </Form>
            </>
          )}
        </Formik>
      </Card>
      {hasReader && (
        <>
          <Box component="header" display="flex" justifyContent="space-between" mb={2} mt={2}>
            <Typography variant="h2" component="h1">
              Stripe Reader
            </Typography>
          </Box>
          <Card>
            <CardContent>
              <section>
                <MuiTextField
                  variant="outlined"
                  fullWidth
                  disabled
                  margin="normal"
                  value={readerId}
                  label="Reader ID"
                />
                <MuiTextField
                  variant="outlined"
                  fullWidth
                  disabled
                  margin="normal"
                  value={reader.status}
                  label="Status"
                />
                <MuiTextField
                  variant="outlined"
                  fullWidth
                  disabled
                  margin="normal"
                  value={reader.location}
                  label="Location"
                />
                <MuiTextField
                  variant="outlined"
                  fullWidth
                  disabled
                  margin="normal"
                  value={readerSerialNumber}
                  label="Serial Number"
                />
                <MuiTextField
                  variant="outlined"
                  fullWidth
                  disabled
                  margin="normal"
                  value={reader.updatedAt}
                  label="Updated at"
                />
              </section>
            </CardContent>
          </Card>
        </>
      )}
    </>
  );
};

export default EditMobileDeviceForm;
