import React from 'react';
import { useSelector } from 'react-redux';

import { Box, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { makeStyles } from '@mui/styles';

import { isDeliveryOnly } from '../../store/venues/selectors';
import useRoles from '../../hooks/useRoles';
import withVenue from '../../hoc/withVenue';

const useStyles = makeStyles(() => ({
  button: {
    textWrap: 'nowrap',
    paddingLeft: '10px',
  },
}));

const NewItemButton = () => {
  const classes = useStyles();
  const { isRoleAtLeastManager, isAdmin } = useRoles();
  const typeDeliveryOnly = useSelector(isDeliveryOnly);
  const permissionReadOnly = !isAdmin() && typeDeliveryOnly;

  return (
    <Box className={classes.button}>
      <Button
        variant="contained"
        color="primary"
        component={Link}
        disabled={!isRoleAtLeastManager() || permissionReadOnly}
        to="/menu-items/add"
      >
        New Item
      </Button>
    </Box>
  );
};

export default withVenue(NewItemButton);
