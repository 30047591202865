import { createSlice } from '@reduxjs/toolkit';
import {
  asyncInitialState as initialState,
  createAsyncAction,
  createAsyncReducerHandlers,
} from '../utils/async';
import { fetchFloats as fetchFloatsApi } from '../../api/floats';

export const fetchFloats = createAsyncAction('fetch-floats', fetchFloatsApi);

const floatsSlice = createSlice({
  name: 'floats',
  initialState,
  reducers: {
    clearFloats(state) {
      delete state.data;
    },
  },
  extraReducers: {
    ...createAsyncReducerHandlers(fetchFloats),
  },
});
export const { clearFloats } = floatsSlice.actions;

export const { reducer: floats } = floatsSlice;
