import { get, post } from './httpRequest';

// eslint-disable-next-line import/prefer-default-export
export const fetchDebits = async () => {
  const response = await get(`/debits`);
  return response.data;
};

export const createDebit = async (amount, reason) => {
  await post(`/debits`, {
    data: { amount, reason },
  });
};
