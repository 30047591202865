import { get, post, patch, del } from './httpRequest';

export const fetchTills = async () => {
  const response = await get('/tills');
  return response.data;
};

export const fetchTill = async (tillId) => {
  const response = await get(`/tills/${tillId}`);
  return response.data;
};

export const createTill = async (data) => {
  const response = await post('/tills', { data });

  return response.data;
};

export const updateTill = async (values) => {
  const { tillId, ...data } = values;
  await patch(`/tills/${tillId}`, {
    data,
  });
};

export const deleteTill = async (tillId) => {
  const response = await del(`/tills/${tillId}`);
  return response.data;
};
