import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { TableFooter, TablePagination, Typography } from '@mui/material';
import moment from 'moment-timezone';

import TableCellStatus, { tableCellStatus } from '../TableCellStatus';
import TableSkeletonLoader from '../TableSkeletonLoader';
import TablePaginationActions from '../TablePaginationActions';
import TableLink from '../TableLink';

const TabsTable = ({ tabs, loading, pagination }) => {
  const { page, handleChangePage, rowsPerPage } = pagination;

  const diplayTabs = tabs?.docs || tabs;
  const tabsNumber = tabs?.total;
  const hasTabs = diplayTabs.length > 0;

  const PayoutStatus = {
    SETTLED: <TableCellStatus status={tableCellStatus.SUCCESS} statusText="Settled" />,
    OPEN: <TableCellStatus status={tableCellStatus.IDLE} statusText="Open" />,
  };

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography variant="h3">Name</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="h3">Table</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="h3">Staff name</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="h3">Status</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="h3">Date</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="h3">Time</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="h3">Amount</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        {loading && <TableSkeletonLoader rows={10} cells={3} cellsSize={['small']} />}
        {!loading && hasTabs && (
          <>
            <TableBody>
              {diplayTabs.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((tab) => {
                const amount = tab.total.toFixed(2).toLocaleString();
                const { status, orderId, tabName, createdAt, tableName, staffName } = tab;
                const date = moment(createdAt).local().format('DD/MM/YY');
                const time = moment(createdAt).local().format('HH:mm');

                return (
                  <TableRow key={orderId}>
                    <TableCell>
                      <TableLink to={`/tab/${tab.tabId}`}>{tabName || 'no name given'}</TableLink>
                    </TableCell>
                    <TableCell>{tableName}</TableCell>
                    {staffName && <TableCell>{staffName}</TableCell>}
                    <TableCell>{PayoutStatus[status]}</TableCell>
                    <TableCell>{date}</TableCell>
                    <TableCell>{time}</TableCell>
                    <TableCell>£{amount}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </>
        )}
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[]}
              count={tabsNumber}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

export default TabsTable;
