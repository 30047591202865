import React from 'react';
import { FormControlLabel, Radio } from '@mui/material';

import { withStyles } from 'tss-react/mui';

const LozengeRadioInput = withStyles(Radio, (theme) => ({
  root: {
    fontSize: theme.typography.pxToRem(14),
    padding: 0,
  },
}));

export const LozengeRadioLabel = withStyles(FormControlLabel, (theme) => ({
  root: {
    borderWidth: theme.typography.pxToRem(1),
    borderColor: theme.palette.primary.main,
    borderStyle: 'solid',
    padding: `${theme.spacing(0.5)} ${theme.spacing(1.5)}`,
    borderRadius: theme.spacing(5),
    transition: 'all 0.3s ease-in-out',
    backgroundColor: ({ selected }) => (selected ? theme.palette.primary.main : 'transparent'),
    color: ({ selected }) => (selected ? theme.palette.common.white : theme.palette.primary.main),
    '&:hover, &:active, &:focus': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
  },
}));

export const LozengeRadio = ({ icon, ...props }) => (
  <LozengeRadioInput disableRipple color="default" icon={<span>{icon}</span>} {...props} />
);
