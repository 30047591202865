import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Button } from '@mui/material';

import { FiList } from 'react-icons/fi';

import { RiWifiOffLine, RiWifiLine } from 'react-icons/ri';
import { isEqual, orderBy } from 'lodash';
import { getDevicesState } from '../../store/devices/selectors';
import TransferList from '../TransferList';
import { getMissingTablesState, getTablesState } from '../../store/tables/selectors';

import { clearTables, fetchTables } from '../../store/tables';
import { fetchDevices, updateDevice, deleteDevice, clearDevices } from '../../store/devices';
import { useNotifications } from '../../shared/contexts/Notifications/useNotifications';
import { getErrorMessage } from '../../shared/utils/errors';
import TableWarning from '../TableWarning';
import TableLink from '../TableLink';
import withVenue from '../../hoc/withVenue';
import shouldLoad from '../../shared/utils/shouldLoad';
import useRoles from '../../hooks/useRoles';
import CustomDialog from '../CustomDialog';

const useStyles = makeStyles(() => ({
  dialog: {
    backgroundColor: 'cyan',
  },
}));

const DevicesTable = () => {
  const classes = useStyles();
  const { showErrorNotification, showSuccessNotification } = useNotifications();
  const dispatch = useDispatch();
  const { isAdmin } = useRoles();

  const tablesState = useSelector(getTablesState);
  const { loading: tablesLoading, data: tablesData } = tablesState;
  const { unassignedFoodTables, unassignedDrinksTables, unassignedAllTables } =
    useSelector(getMissingTablesState);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [currentDevice, setCurrentDevice] = useState(null);
  const [missing, setMissing] = useState({ FOOD: [], DRINKS: [], ALL: [] });
  const devicesState = useSelector(getDevicesState);
  const { loading: devicesLoading, data: devices } = devicesState;

  useEffect(() => {
    if (shouldLoad(tablesState)) dispatch(fetchTables());
    if (shouldLoad(devicesState)) dispatch(fetchDevices());
  }, [dispatch, tablesState, devicesState]);

  useEffect(() => {
    setMissing({
      FOOD: unassignedFoodTables,
      DRINKS: unassignedDrinksTables,
      ALL: unassignedAllTables,
    });
  }, [unassignedFoodTables, unassignedDrinksTables, unassignedAllTables]);

  if (!devices) return null;

  const handleCloseDialog = async () => {
    setIsDialogOpen(false);
  };

  const handleAssignment = async (device, newTables) => {
    handleCloseDialog();
    try {
      const updatedDevice = {
        ...device,
        tables: isEqual(newTables.sort(), tablesData.map((table) => table.tableName).sort())
          ? []
          : orderBy(newTables, Number),
      };
      await dispatch(updateDevice(updatedDevice));
      showSuccessNotification('Device has been updated successfully');
      dispatch(fetchTables());
      dispatch(fetchDevices());
    } catch (localError) {
      showErrorNotification(getErrorMessage(localError));
    }
  };

  const removeCurrentDevice = async (device) => {
    const { deviceId } = device;
    try {
      await dispatch(deleteDevice({ deviceId }));
      dispatch(fetchDevices());
      showSuccessNotification('Device has been removed successfully');
    } catch (err) {
      dispatch(fetchDevices());
      showErrorNotification(getErrorMessage(err));
    }
  };

  return (
    (!tablesLoading || !devicesLoading) && (
      <>
        <TableWarning />
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Device Id</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Destination</TableCell>
                <TableCell>Iccid</TableCell>
                <TableCell>Imei</TableCell>
                <TableCell>Connected</TableCell>
                <TableCell>Version</TableCell>
                <TableCell>Last Update</TableCell>
                <TableCell>Tables</TableCell>
                {isAdmin() && <TableCell> </TableCell>}
                {isAdmin() && <TableCell> </TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {devices.map((device) => (
                <TableRow key={device.deviceId}>
                  {isAdmin() ? (
                    <TableCell component="th" scope="row">
                      <TableLink to={`/devices/edit/${device.deviceId}`}>
                        {device.deviceId}
                      </TableLink>
                    </TableCell>
                  ) : (
                    <TableCell>{device.deviceId}</TableCell>
                  )}
                  <TableCell>{device.name}</TableCell>
                  <TableCell>{device.destination}</TableCell>
                  <TableCell>{device.iccid}</TableCell>
                  <TableCell>{device.imei}</TableCell>
                  <TableCell>
                    {device.connected === true ? <RiWifiLine /> : <RiWifiOffLine />}
                  </TableCell>
                  <TableCell>{device.version}</TableCell>
                  <TableCell>{device.lastUpdate}</TableCell>
                  <TableCell>
                    {device.tables?.length === 0 ? 'ALL' : device.tables?.join(', ')}
                  </TableCell>

                  {isAdmin() && (
                    <TableCell>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          setIsDialogOpen(true);
                          setCurrentDevice(device);
                        }}
                        startIcon={<FiList />}
                      >
                        Assign
                      </Button>
                    </TableCell>
                  )}
                  {isAdmin() && (
                    <TableCell>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          removeCurrentDevice(device);
                        }}
                        startIcon={<FiList />}
                      >
                        Remove
                      </Button>
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <CustomDialog
          title="Assign Tables to device"
          handleCloseDialog={handleCloseDialog}
          isDialogOpen={isDialogOpen}
        >
          <TransferList
            assigned={
              currentDevice?.tables.length === 0
                ? tablesData.map((table) => table.tableName)
                : currentDevice?.tables
            }
            unassigned={
              currentDevice?.tables.length === 0 ? [] : missing[currentDevice?.destination]
            }
            label="Table"
            className={classes.dialog}
            onTransfer={(updated) => handleAssignment(currentDevice, updated)}
            onClose={handleCloseDialog}
          />
        </CustomDialog>
      </>
    )
  );
};

export default withVenue(DevicesTable, null, [clearTables, clearDevices]);
