import React, { useMemo } from 'react';
import moment from 'moment-timezone';
import { Card, Link } from '@mui/material';
import { pick } from 'lodash';
import useEndpoint from '../../hooks/useEndpoint';
import { getInvoiceState } from '../../store/billing/selectors';
import { fetchInvoices } from '../../store/billing';
import OrderableTable from '../OrderableTable';
import InlineChip from '../InlineChip';

const valueFormatter = ({ value, valueName }) => {
  switch (valueName) {
    case 'createdAt':
      return moment(value).format('DD/MM/YYYY');
    case 'status':
      if (value === 'PAID')
        return <InlineChip label="Pending" backgroundColor="#F1E3FE" fontColor="#8C1DF1" />;
      if (value === 'CONFIRMED')
        return <InlineChip label="Paid" backgroundColor="#E3F8E5" fontColor="#32B842" />;
      if (value === 'FINALIZED')
        return <InlineChip label="Outstanding" backgroundColor="#F0F4F8" fontColor="#647F99" />;
      if (value === 'FAILED')
        return <InlineChip label="Payment failed" backgroundColor="#FFEDEB" fontColor="#D2222D" />;
      return <InlineChip label={value} />;
    case 'hostedInvoiceUrl':
      return (
        <Link
          style={{ textDecoration: 'underline' }}
          href={value}
          target="_blank"
          rel="noopener noreferrer"
        >
          View
        </Link>
      );
    case 'invoicePdf':
      return (
        <Link style={{ textDecoration: 'underline' }} href={value}>
          Download
        </Link>
      );

    case 'invoiceId':
      return <span>{value}</span>;
    default:
      return value;
  }
};
const InvoiceHistory = () => {
  const { data: invoices } = useEndpoint(getInvoiceState, fetchInvoices());

  const invoiceDataPicked = useMemo(() => {
    const pickedData = [];
    if (invoices) {
      invoices.forEach((item) => {
        pickedData.push(
          pick(item, [
            'createdAt',
            'invoiceId',
            'amountDue',
            'status',
            'hostedInvoiceUrl',
            'invoicePdf',
          ]),
        );
      });
    }
    return pickedData;
  }, [invoices]);

  return (
    <Card>
      {invoices && (
        <OrderableTable
          tableData={invoiceDataPicked}
          titles={['DATE', 'INVOICE NUMBER', 'TOTAL', 'STATUS', '', ' ']}
          disableColumnTitles={['INVOICE NUMBER', '', ' ']}
          valueFormatter={valueFormatter}
        />
      )}
    </Card>
  );
};

export default InvoiceHistory;
