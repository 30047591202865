import React from 'react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  pageStyling: {
    maxWidth: '1052px',
    margin: '0 auto',
  },
}));

const PageHeader = ({ children, fullWidth, className }) => {
  const classes = useStyles();

  return (
    <header className={fullWidth ? className : `${classes.pageStyling} ${className}`}>
      {children}
    </header>
  );
};

export default PageHeader;
