import React from 'react';
import CustomDialog from '../CustomDialog';
import AddPayout from './AddPayout';

const ExternalPayoutsDialogue = ({ payout, isOpen, setIsOpen, onSubmit }) => {
  const title = payout ? 'Edit Transaction' : 'Add Transaction';

  const handleCloseCategoryDialog = () => {
    setIsOpen(false);
  };

  return (
    <CustomDialog title={title} isDialogOpen={isOpen} handleCloseDialog={handleCloseCategoryDialog}>
      <AddPayout payout={payout} modalClose={handleCloseCategoryDialog} onSubmit={onSubmit} />
    </CustomDialog>
  );
};
export default ExternalPayoutsDialogue;
