import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { FiPlus } from 'react-icons/fi';

import { withStyles } from 'tss-react/mui';
import Page from '../../components/Page';
import PageHeader from '../../components/PageHeader';
import withVenue from '../../hoc/withVenue';
import PromoCodesTable from '../../components/PromoCodesTable';
import {
  clearPromoCodes,
  createPromoCode,
  deletePromoCode,
  fetchPromoCodes,
  fetchPromoCodesPage,
  updatePromoCode,
} from '../../store/promoCodes';
import { getPromoCodesState } from '../../store/promoCodes/selectors';
import usePagination from '../../hooks/usePagination';
import useESPicker from '../../hooks/useESPicker';
import ESPicker from '../../components/ESPicker';
import { rangeEnums } from '../../shared/utils/dateRanges';
import PromoCodeForm from '../../components/PromoCodeForm';
import { getErrorMessage } from '../../shared/utils/errors';
import { useNotifications } from '../../shared/contexts/Notifications/useNotifications';
import CustomDialog from '../../components/CustomDialog';

const Header = withStyles(Box, () => ({
  root: {
    display: 'flex',
    margin: '15px 0',
  },
}));

const Title = withStyles(Typography, () => ({
  root: {
    flexGrow: 1,
  },
}));

const AddPromoButton = withStyles(Button, () => ({
  root: {
    marginLeft: '20px',
  },
}));

const PromoCodes = ({ venue }) => {
  const dispatch = useDispatch();
  const { loading, data, error } = useSelector(getPromoCodesState);

  const pagination = usePagination();
  const esPicker = useESPicker({ useDate: false, useOrder: false, reportingType: false });
  const { searchTerm, sortOrder } = esPicker;
  const { first, requestNewPage, rowsPerPage, resetPagination } = pagination;

  const { showErrorNotification, showSuccessNotification } = useNotifications();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isPromoCodeData, setIsPromoCodeData] = useState(null);
  const [promoCodeUpdateReference, setPromoCodeUpdateReference] = useState(null);
  const [formAction, setFormAction] = useState(null);
  const timeFrame = { daily: 'DAILY', weekly: 'WEEKLY', monthly: 'MONTHLY' };

  const handleCreate = () => {
    setIsPromoCodeData(null);
    setFormAction('create');
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    setIsPromoCodeData(null);
  };

  const handleEdit = (promoCodeData, promoCode) => {
    setFormAction('update');
    setIsPromoCodeData(promoCodeData);
    setPromoCodeUpdateReference(promoCode);
    setIsDialogOpen(true);
  };

  const handleDelete = async (promoCode) => {
    try {
      await dispatch(deletePromoCode(promoCode));
      showSuccessNotification(`Promo code ${promoCode} has been deleted successfully`);
    } catch (e) {
      showErrorNotification('An error occurred while deleting your promocode');
    }
    await dispatch(
      fetchPromoCodes({
        first: 0,
        size: rowsPerPage,
        sortOrder,
        searchTerm,
        dateRange: rangeEnums.LAST_12_MONTHS,
      }),
    );
  };

  const onSubmit = async (payload) => {
    if (formAction === 'create') {
      try {
        const { expiresAt, validTimes, type, amount } = payload;
        const isPercentageDiscount = type === 'PERCENTAGE_DISCOUNT';
        const finalAmount = isPercentageDiscount && amount > 100 ? 100 : amount;
        const hasValidTimes = !!validTimes.length;

        const transformedPayload = {
          ...payload,
          type,
          amount: finalAmount,
          expiresAt,
          validTimes,
        };
        if (!hasValidTimes) {
          delete transformedPayload.validTimes;
        }
        if (transformedPayload.hasLimit === false) {
          delete transformedPayload.limitAmount;
          delete transformedPayload.limitTimeFrame;
        }
        delete transformedPayload.usageCount;
        await dispatch(createPromoCode(transformedPayload));
        showSuccessNotification('Promo code has been created successfully');
      } catch (e) {
        showErrorNotification(getErrorMessage(e));
      }
    }
    if (formAction === 'update') {
      try {
        await dispatch(updatePromoCode(promoCodeUpdateReference, payload));
        showSuccessNotification('Promo code has been updated successfully');
      } catch {
        showErrorNotification('An error occurred while updating your promocode');
      }
    }

    // TODO: interim solution to cope with ES sync.
    const maxTimeout = 3000;
    const minTimeout = 1000;
    const randomisedTimeout = Math.floor(Math.random() * (maxTimeout - minTimeout)) + minTimeout;

    await new Promise((resolve) => setTimeout(resolve, randomisedTimeout));
    dispatch(
      fetchPromoCodes({
        first: 0,
        size: rowsPerPage,
        sortOrder,
        searchTerm,
        dateRange: rangeEnums.LAST_12_MONTHS,
      }),
    );
    setIsDialogOpen(false);
  };

  const handleFetchPage = useCallback(() => {
    dispatch(
      fetchPromoCodesPage({
        first,
        size: rowsPerPage,
        sortOrder,
        searchTerm,
        dateRange: rangeEnums.LAST_12_MONTHS,
      }),
    );
  }, [dispatch, first, rowsPerPage, searchTerm, sortOrder]);

  useEffect(() => {
    resetPagination();
    dispatch(
      fetchPromoCodes({
        first: 0,
        size: rowsPerPage,
        sortOrder,
        searchTerm,
        dateRange: rangeEnums.LAST_12_MONTHS,
      }),
    );
  }, [dispatch, resetPagination, rowsPerPage, searchTerm, sortOrder]);

  useEffect(() => {
    if (requestNewPage) {
      handleFetchPage();
    }
  }, [requestNewPage, handleFetchPage]);

  return (
    <>
      <PageHeader fullWidth>
        <Header>
          <Title variant="h2" component="h1">
            Promo Codes
          </Title>
          <ESPicker esPicker={esPicker}>
            <AddPromoButton
              variant="contained"
              color="primary"
              startIcon={<FiPlus />}
              onClick={handleCreate}
            >
              Add Promo code
            </AddPromoButton>
          </ESPicker>
        </Header>
      </PageHeader>
      <Page loading={loading} error={error} fullWidth>
        {data && (
          <>
            <PromoCodesTable
              pagination={pagination}
              handleEdit={handleEdit}
              handleDelete={handleDelete}
            />
          </>
        )}
        <CustomDialog
          title={formAction === 'update' ? 'Edit promo code' : 'Add a new promo code'}
          isDialogOpen={isDialogOpen}
          handleCloseDialog={handleCloseDialog}
        >
          <PromoCodeForm
            formAction={formAction}
            onSubmit={onSubmit}
            promoCodeData={isPromoCodeData}
            venue={venue}
            timeFrame={timeFrame}
            onEdit={handleEdit}
            onCancel={handleCloseDialog}
            isDialogOpen={isDialogOpen}
          />
        </CustomDialog>
      </Page>
    </>
  );
};

export default withVenue(PromoCodes, null, clearPromoCodes);
