// eslint-disable-next-line import/no-extraneous-dependencies
import { createServer, Model, Serializer } from 'miragejs';
import fixtures from './fixtures';

export default ({ environment = 'development' } = {}) =>
  createServer({
    fixtures,
    environment,

    serializers: {
      application: Serializer.extend({ root: false, embed: true }),
    },

    models: {
      category: Model,
      ingredient: Model,
      itemOption: Model,
      item: Model,
      menu: Model,
      searchOrder: Model,
      order: Model,
      table: Model,
      venue: Model,
    },

    seeds(server) {
      server.loadFixtures();
    },

    routes() {
      let newId = 1000;
      this.urlPrefix = process.env.REACT_APP_API_URI;

      this.get('/venues/:venueId/categories', (schema, request) =>
        schema.categories.where({ venueId: request.params.venueId }),
      );
      this.get('/venues/:venueId/categories/:categoryId', (schema, request) =>
        schema.categories.findBy({
          venueId: request.params.venueId,
          categoryId: request.params.categoryId,
        }),
      );

      this.get('/venues/:venueId/ingredients', (schema, request) =>
        schema.ingredients.where({ venueId: request.params.venueId }),
      );
      this.get('/venues/:venueId/ingredients/:ingredientId', (schema, request) =>
        schema.ingredients.findBy({
          venueId: request.params.venueId,
          ingredientId: request.params.ingredientId,
        }),
      );
      this.post('/venues/:venueId/ingredients', (schema, request) => {
        const attrs = JSON.parse(request.requestBody);
        attrs.venueId = request.params.venueId;
        attrs.ingredientId = (newId += 1).toString();

        return schema.ingredients.create(attrs);
      });
      this.patch('/venues/:venueId/ingredients/:ingredientId', (schema, request) => {
        const attrs = JSON.parse(request.requestBody);

        return schema.ingredients
          .findBy({
            venueId: request.params.venueId,
            ingredientId: request.params.ingredientId,
          })
          .update(attrs);
      });

      this.get('/venues/:venueId/itemOptions', (schema, request) =>
        schema.itemOptions.where({ venueId: request.params.venueId }),
      );
      this.get('/venues/:venueId/itemOptions/:optionId', (schema, request) =>
        schema.itemOptions.findBy({
          venueId: request.params.venueId,
          optionId: request.params.optionId,
        }),
      );

      this.get('/venues/:venueId/items', (schema, request) =>
        schema.items.where({ venueId: request.params.venueId }),
      );
      this.get('/venues/:venueId/items/:itemId', (schema, request) =>
        schema.items.findBy({
          venueId: request.params.venueId,
          itemId: request.params.itemId,
        }),
      );

      this.post('/venues/:venueId/items', (schema, request) => {
        const attrs = JSON.parse(request.requestBody);
        attrs.available = true;
        attrs.attributes = [];
        attrs.itemId = (newId += 1).toString();
        attrs.venueId = request.params.venueId;
        attrs.hidden = false;

        return schema.items.create(attrs);
      });

      this.patch('/venues/:venueId/items/:itemId', (schema, request) => {
        const attrs = JSON.parse(request.requestBody);

        return schema.items
          .findBy({
            venueId: request.params.venueId,
            itemId: request.params.itemId,
          })
          .update(attrs);
      });

      this.get('/venues/:venueId/menus', (schema, request) =>
        schema.menus.where({ venueId: request.params.venueId }),
      );
      this.get('/venues/:venueId/menus/:menuName', (schema, request) =>
        schema.menus.findBy({
          venueId: request.params.venueId,
          menuName: request.params.menuName,
        }),
      );
      this.post('/venues/:venueId/menus', (schema, request) => {
        const attrs = JSON.parse(request.requestBody);
        attrs.venueId = request.params.venueId;

        return schema.menus.create(attrs);
      });

      this.patch('/venues/:venueId/menus/:menuName', (schema, request) => {
        const attrs = JSON.parse(request.requestBody);
        return schema.menus
          .findBy({
            venueId: request.params.venueId,
            menuName: request.params.menuName,
          })
          .update(attrs);
      });

      this.get('/venues/:venueId/orders', (schema) => schema.searchOrders.first());
      this.get('/venues/:venueId/orders/:orderId', (schema, request) =>
        schema.orders.findBy({
          venueId: request.params.venueId,
          orderId: request.params.orderId,
        }),
      );

      this.get('/venues/:venueId/tables', (schema, request) =>
        schema.tables.where({ venueId: request.params.venueId }),
      );
      this.get('/venues/:venueId/tables/:tableName', (schema, request) =>
        schema.tables.findBy({
          venueId: request.params.venueId,
          tableName: request.params.tableName,
        }),
      );

      this.get('venues');
      this.get('/venues/:venueId', (schema, request) =>
        schema.venues.findBy({
          venueId: request.params.venueId,
        }),
      );
      this.passthrough('https://devauth.theup.co/oauth2/token');
      this.passthrough('https://devauth.theup.co/');
      this.passthrough('https://cognito-idp.eu-west-2.amazonaws.com');
    },
  });
