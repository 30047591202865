import { get, post, patch, del } from './httpRequest';

export const fetchSalesAreas = async () => {
  const response = await get(`/salesAreas`);
  return response.data;
};

export const fetchSalesArea = async (salesAreaId) => {
  const response = await get(`/salesAreas/${salesAreaId}`);
  return response.data;
};

export const deleteSalesArea = async (salesAreaId) => {
  const response = await del(`/salesAreas/${salesAreaId}`);
  return response.data;
};

export const createSalesArea = async (salesArea) => {
  const response = await post(`/salesAreas`, {
    data: salesArea,
  });

  return response.data;
};

export const updateSalesArea = async ({ salesAreaId, values }) => {
  const response = await patch(`/salesAreas/${salesAreaId}`, {
    data: { ...values },
  });
  return response.data;
};
