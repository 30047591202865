import axios from 'axios';
import { mergeDeepLeft } from 'ramda';

import {
  getVenuePrefix,
  getIdToken,
  isSessionExpired,
  hasAuthenticationTokens,
} from '../cognito/sessionTokens';

let store;
let refreshSession;

export const setStore = (s) => {
  store = s;
};
export const setRefreshSession = (r) => {
  refreshSession = r;
};

const buildApiUrl = (endpoint) => `${process.env.REACT_APP_API_URI}/${endpoint}`;

const withAuthorisationHandler = (client) => async (endpoint, options) => {
  if (hasAuthenticationTokens() && isSessionExpired()) {
    await store.dispatch(refreshSession());
  }

  return client(endpoint, options);
};

const buildOptions = (options = {}) => {
  const idToken = getIdToken();

  if (!idToken) {
    return options;
  }

  return mergeDeepLeft(
    {
      headers: {
        Authorization: idToken,
      },
    },
    options,
  );
};

const buildApiClient = (method) => (endpoint, options) =>
  axios({
    method,
    url: buildApiUrl(getVenuePrefix() + endpoint),
    ...buildOptions(options),
  });

const buildBareApiClient = (method) => (endpoint, options) =>
  axios({
    method,
    url: buildApiUrl(endpoint),
    ...buildOptions(options),
  });

const buildSimpleApiClient = (method) => (endpoint, data, options) =>
  axios({
    method,
    url: endpoint,
    options,
    data,
  });

const buildApiClientWithoutAuthorisation = (method) => (endpoint, options) =>
  axios({
    method,
    url: `${process.env.REACT_APP_API_URI}${endpoint}`,
    ...buildOptions(options),
  });

const buildApiClientVenues = (method) => (endpoint, options) =>
  axios({
    method,
    url: buildApiUrl('venues'),
    ...buildOptions(options),
  });

export const getVenues = withAuthorisationHandler(buildApiClientVenues('get'));
export const barePost = withAuthorisationHandler(buildApiClientVenues('post'));

export const getBare = withAuthorisationHandler(buildBareApiClient('get'));
export const postBare = withAuthorisationHandler(buildBareApiClient('post'));
export const patchBare = withAuthorisationHandler(buildBareApiClient('patch'));
export const deleteBare = withAuthorisationHandler(buildBareApiClient('delete'));

export const get = withAuthorisationHandler(buildApiClient('get'));
export const post = withAuthorisationHandler(buildApiClient('post'));
export const put = withAuthorisationHandler(buildApiClient('put'));
export const patch = withAuthorisationHandler(buildApiClient('patch'));
export const del = withAuthorisationHandler(buildApiClient('delete'));

export const getWithoutAuthorisation = buildApiClient('get');
export const putSimple = buildSimpleApiClient('put');
export const postWithoutAuthorisation = buildApiClientWithoutAuthorisation('post');
