export const filterTableData = (brandsFilter, data) => {
  if (!data) return null;
  if (!brandsFilter || brandsFilter.length === 0) {
    return data;
  }
  const brandsToDisplay = brandsFilter.map((brand) => brand.brandId);
  const filtered = data.filter((item) => brandsToDisplay.includes(item.brandId));
  return filtered;
};

/**
/**
 * Filters data based on brand and item filters.
 * @param {Array} filters - Array of item filter objects containing item properties.
 * @param {Array} data - Array of data objects to be filtered.
 * @returns {Array|null} Filtered data based on the brand and item filters, or the original data 
 * if no filters are applied.
 */
export const filterDataItems = (filters, data) => {
  if (!data || data.length === 0) {
    return data || [];
  }

  const filterObj = filters && filters.length > 0 && filters[0] ? filters[0] : {};

  const { types, categories, available, itemPrices } = filterObj;

  if (
    (!types || types.length === 0) &&
    (!categories || categories.length === 0) &&
    (!available || available.length === 0) &&
    (!itemPrices || itemPrices.length === 0)
  ) {
    return data;
  }

  return data?.filter((item) => {
    const matchesType =
      !types ||
      types.length === 0 ||
      types.map((type) => type.toLowerCase()).includes(item.type.toLowerCase());

    const matchesCategory =
      !categories || categories.length === 0 || categories.includes(item.category);

    const availabilityString = item.available ? 'Available' : 'Unavailable';

    const matchesAvailability =
      !available || available.length === 0 || available.includes(availabilityString);

    const itemMinPrice = item.itemPrices && Math.min(...item.itemPrices);

    const itemMaxPrice = item.itemPrices && Math.max(...item.itemPrices);

    const matchesPrice =
      !itemPrices ||
      (itemMinPrice !== undefined &&
        itemMaxPrice !== undefined &&
        itemMaxPrice >= itemPrices[0] &&
        itemMinPrice <= itemPrices[1]);

    return matchesType && matchesCategory && matchesAvailability && matchesPrice;
  });
};

/**
 * Filters initialData based on the searchTargetValue using Fuse.js.
 * @param {string} searchTargetValue - The search term to filter the data.
 * @param {Array} initialData - Array of data objects to be filtered.
 * @param {Object} Fuse - The Fuse.js instance to perform the search.
 * @param {Array} searchKeys - Array of keys to be searched within each data object.
 * @param {number} threshold - The threshold value for the Fuse.js search algorithm.
 * @returns {Array} Filtered data based on the search term or the original data if no search term is provided.
 */
export const filterSearchTerm = (searchTargetValue, initialData, Fuse, searchKeys, threshold) => {
  if (searchTargetValue && initialData) {
    const fuse = new Fuse(initialData, {
      threshold,
      keys: searchKeys,
    });
    const result = fuse.search(searchTargetValue);
    const filtered = result.map((item) => item.item);

    return filtered;
  }

  return initialData;
};
