// https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/digest#examples
async function digestMessage(message) {
  const msgUint8 = new TextEncoder().encode(message); // encode as (utf-8) Uint8Array
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8); // hash the message
  const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array
  const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join(''); // convert bytes to hex string
  return hashHex;
}

const updateGtmDataLayer = ({ userId, venueId }) => {
  if (!userId) {
    return;
  }
  const hashedUserId = digestMessage(userId);

  const { dataLayer } = window;

  if (!dataLayer.userId) {
    dataLayer.push({
      userId: hashedUserId,
    });
  } else {
    dataLayer.userId = hashedUserId;
  }

  if (!dataLayer.venueId) {
    dataLayer.push({
      venueId,
    });
  } else {
    dataLayer.venueId = venueId;
  }
};

export default updateGtmDataLayer;
