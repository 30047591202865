import { Card } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { makeStyles } from '@mui/styles';
import OrderableTable from '../../../components/OrderableTable';
import Page from '../../../components/Page';
import shouldLoad from '../../../shared/utils/shouldLoad';
import { clearPayments, fetchPayments } from '../../../store/payments';
import { getPaymentsState } from '../../../store/payments/selectors';
import rearrangeDataOrder from '../../../shared/utils/rearrangeDataOrder';
import TableCellStatus, { tableCellStatus } from '../../../components/TableCellStatus';
import withVenue from '../../../hoc/withVenue';

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: '8px',
  },
  title: {
    ...theme.customFonts.largeBold,
    display: 'flex',
    justifyContent: 'center',
  },
}));

const PaymentStatus = {
  COMPLETED: <TableCellStatus status={tableCellStatus.SUCCESS} statusText="Completed" />,
  IN_PROGRESS: <TableCellStatus status={tableCellStatus.IDLE} statusText="In progress" />,
  IN_TRANSIT: <TableCellStatus status={tableCellStatus.IDLE} statusText="In transit" />,
  NOT_STARTED: <TableCellStatus status={tableCellStatus.STALL} statusText="Not started" />,
  FAILED: <TableCellStatus status={tableCellStatus.FAIL} statusText="Failed" />,
  RETURNED: <TableCellStatus status={tableCellStatus.IDLE} statusText="Returned" />,
};

const valueFormatter = ({ value, valueName }) => {
  switch (valueName) {
    case 'date':
      return moment(value).local()?.format('DD/MM/YY');
    case 'amount':
      return `£${value}`;
    case 'status':
      return PaymentStatus[value];
    default:
      return value;
  }
};

const Payments = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const paymentState = useSelector(getPaymentsState);
  const { loading, data: payments, error: paymentsError } = paymentState;

  useEffect(() => {
    if (shouldLoad(paymentState)) dispatch(fetchPayments());
  }, [dispatch, paymentState]);

  const isPayoutsLoading = loading && !payments;

  return (
    <Page loading={isPayoutsLoading} error={paymentsError}>
      {payments && payments.length !== 0 ? (
        <Card className={classes.card}>
          <OrderableTable
            tableData={rearrangeDataOrder(payments, [
              'date',
              'amount',
              'reference',
              'status',
              'description',
            ])}
            valueFormatter={valueFormatter}
            titles={['DATE', 'AMOUNT', 'REFERENCE', 'STATUS', 'DESCRIPTION']}
          />
        </Card>
      ) : (
        <h1 className={classes.title}>No payments to show</h1>
      )}
    </Page>
  );
};

export default withVenue(Payments, '/settings/payments', clearPayments);
