import React from 'react';
import { Card, CardContent, Typography, MenuItem } from '@mui/material';
import * as Yup from 'yup';
import { Form, Formik, Field } from 'formik';
import { TextField } from 'formik-mui';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@mui/styles';
import UniversalSave from '../../../components/UniversalSave';
import withVenue from '../../../hoc/withVenue';
import { useNotifications } from '../../../shared/contexts/Notifications/useNotifications';
import { getErrorMessage } from '../../../shared/utils/errors';
import { fetchVenue, updateVenue, updateVenueSettings } from '../../../store/venues';
import withSettings from '../../../hoc/withSettings';
import BackArrow from '../../../components/BackArrow';
import Page from '../../../components/Page';
import PageHeader from '../../../components/PageHeader';

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: '8px',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    '& > :nth-child(2), & > :nth-child(4)': {
      marginBottom: '10px',
    },
  },
  field: {
    width: '100%',
    margin: 0,
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.customPalette.grey,
    },
    '& .MuiSelect-icon': {
      color: theme.customPalette.greyDark,
    },
  },
  title: {
    ...theme.customFonts.largeBold,
    marginBottom: '16px',
  },
  subtitle: {
    ...theme.customFonts.label,
    color: theme.customPalette.greyDarker,
  },
}));

const serviceChargeSchema = Yup.object().shape({
  serviceCharge: Yup.string()
    .oneOf(['OPTIONAL', 'MANDATORY', 'DISABLED'])
    .required('Please choose if service charge is optional or mandatory'),
  serviceRate: Yup.number().required('Please choose a service charge rate (percentage)'),
  serviceChargeLabel: Yup.string().min(3).required('Please use at least 3 characters'),
});

const ServiceChargeSettings = ({ venue, setting, redirect }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { showErrorNotification, showSuccessNotification } = useNotifications();
  const { serviceCharge: initialServiceCharge, serviceRate } = venue;

  const initialValues = {
    serviceCharge: initialServiceCharge || '',
    serviceRate,
    serviceChargeLabel: setting?.value || '',
  };
  const handleOnSubmit = async (values) => {
    try {
      if (values.serviceChargeLabel !== undefined)
        await dispatch(
          updateVenueSettings({
            settingName: 'SERVICE_CHARGE_LABEL',
            settingValue: values.serviceChargeLabel,
          }),
        );
      await dispatch(
        updateVenue({
          serviceCharge: values.serviceCharge,
          serviceRate: values.serviceRate,
        }),
      );
      showSuccessNotification('Successfully updated service charge settings');
    } catch (error) {
      showErrorNotification(getErrorMessage(error));
      dispatch(fetchVenue());
    }
  };

  return (
    <>
      <PageHeader>
        <BackArrow redirect={redirect} text="Settings" />
        <Typography className={classes.title}>Service charge</Typography>
      </PageHeader>
      <Page>
        <Formik
          initialValues={initialValues}
          enableReinitialize
          validationSchema={serviceChargeSchema}
        >
          {({ setFieldValue, values, resetForm, errors, dirty, isValid }) => (
            <>
              <UniversalSave
                isValid={isValid}
                dirty={dirty}
                onSave={() => {
                  handleOnSubmit(values);
                }}
                onDiscard={resetForm}
                errors={errors}
              />
              <Form>
                <Card className={classes.card}>
                  <CardContent className={classes.container}>
                    <Typography className={classes.subtitle}>Service charge</Typography>
                    <Field
                      className={classes.field}
                      name="serviceCharge"
                      select
                      component={TextField}
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      onChange={({ target }) => {
                        setFieldValue('serviceCharge', target.value);
                      }}
                    >
                      <MenuItem value="OPTIONAL" key="OPTIONAL">
                        OPTIONAL
                      </MenuItem>
                      <MenuItem value="MANDATORY" key="MANDATORY">
                        MANDATORY
                      </MenuItem>
                      <MenuItem value="DISABLED" key="DISABLED">
                        DISABLED
                      </MenuItem>
                    </Field>
                    {values.serviceCharge === 'MANDATORY' && (
                      <>
                        <Typography className={classes.subtitle}>
                          Please note that making service charge mandatory may have tax implications
                          for your staff. For more information, please consult your tax advisor.
                        </Typography>
                        <br />
                      </>
                    )}
                    <Typography className={classes.subtitle}>Rate %</Typography>
                    <Field
                      className={classes.field}
                      name="serviceRate"
                      variant="outlined"
                      value={values.serviceRate}
                      component={TextField}
                      type="number"
                    />
                    <Typography className={classes.subtitle}>
                      Service charge label (appears at checkout)
                    </Typography>
                    <Field
                      className={classes.field}
                      name="serviceChargeLabel"
                      variant="outlined"
                      value={values.serviceChargeLabel}
                      component={TextField}
                    />
                  </CardContent>
                </Card>
              </Form>
            </>
          )}
        </Formik>
      </Page>
    </>
  );
};

const serviceChargeWithVenue = withVenue(ServiceChargeSettings, '/settings');
export default withSettings(serviceChargeWithVenue, 'SERVICE_CHARGE_LABEL');
