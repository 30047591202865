export const convertTimeTo24Hour = (time12h) => {
  const [time, modifier] = time12h.split(' ');
  const splitTime = time.split(':');
  let [hours] = splitTime;
  const [, minutes] = splitTime;

  if (modifier && hours === '12') hours = 0;

  hours = !modifier || modifier === 'AM' ? parseInt(hours, 10) : parseInt(hours, 10) + 12;

  return { hours, minutes };
};

export const convertTimeTo12Hour = (time) => {
  const [hour, minute] = time.split(':');
  const newHour = hour > 12 ? hour - 12 : hour;
  const meridian = hour >= 12 ? 'PM' : 'AM';
  return `${newHour}:${minute} ${meridian}`;
};

export const convertTimeToUtc = (time) => {
  const newTime = convertTimeTo24Hour(time);
  const date = new Date(2020, 11, 24, newTime.hours, newTime.minutes);
  return date.toISOString();
};
