import { createSlice } from '@reduxjs/toolkit';
import {
  asyncInitialState as initialState,
  createAsyncAction,
  createAsyncReducerHandlers,
} from '../utils/async';
import fetchReportSalesByItemApi from '../../api/reports/salesByItem';

export const fetchReportSalesByItem = createAsyncAction(
  'fetch-sales-item',
  fetchReportSalesByItemApi,
);

const reportSalesByItemSlice = createSlice({
  name: 'sales-item',
  initialState,
  reducers: {
    clearReportSalesByItem(state) {
      delete state.data;
    },
  },
  extraReducers: createAsyncReducerHandlers(fetchReportSalesByItem),
});

export const { clearReportSalesByItem } = reportSalesByItemSlice.actions;

export const { reducer: reportSalesByItem } = reportSalesByItemSlice;
