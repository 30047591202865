import React from 'react';
import { makeStyles } from '@mui/styles';
import theme from '../../theme/default';
import customFonts from '../../theme/mui/customFonts';

const useStyles = makeStyles(() => ({
  root: ({ statusColors }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '103px',
    height: '28px',
    overflow: 'hidden',
    borderRadius: '24px',
    padding: '4px, 14px, 4px, 14px',
    color: statusColors.color,
    backgroundColor: statusColors.backgroundColor,
    ...customFonts.xSmall,
  }),
}));

const tableCellStatus = {
  SUCCESS: 'SUCCESS',
  IDLE: 'IDLE',
  STALL: 'STALL',
  FAIL: 'FAIL',
};

const TableCellStatus = ({ status, statusText }) => {
  const getStatusColors = (statusLabel) => {
    switch (statusLabel) {
      case 'SUCCESS':
        return {
          color: theme.colors.green[5],
          backgroundColor: theme.colors.green[0],
        };
      case 'IDLE':
        return {
          color: theme.colors.grey[5],
          backgroundColor: theme.colors.grey[0],
        };
      case 'STALL':
        return {
          color: theme.colors.grey[5],
          backgroundColor: theme.colors.grey[2],
        };
      case 'FAIL':
        return {
          color: theme.colors.red[5],
          backgroundColor: theme.colors.red[0],
        };
      default:
        return {
          color: theme.colors.grey[5],
          backgroundColor: theme.colors.grey[0],
        };
    }
  };

  const formattedStatusText = statusText || status[0].toUpperCase() + status.toLowerCase().slice(1);
  const statusColors = getStatusColors(status);
  const classes = useStyles({ statusColors });

  return <span className={classes.root}>{formattedStatusText}</span>;
};

export { TableCellStatus as default, tableCellStatus };
