import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';

import { ReactComponent as ArrowRightIcon } from '../PromotionsSlider/arrow-right-up.svg';

const useStyles = makeStyles((theme) => ({
  sliderContainer: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    width: 288,
    height: `144px !important`,
    borderRadius: 8,
    overflow: 'hidden',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    cursor: 'pointer',
    '&:after': {
      content: ({ hasText }) => (hasText ? '""' : 'none'),
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 50%,rgba(0,0,0, 0.7) 100%)',
    },
    '& *': {
      zIndex: 1,
    },
  },
  button: ({ brandColor }) => ({
    color: '#FFF',
    backgroundColor: 'rgba(17,24,39,.6)',
    position: 'absolute',
    top: 0,
    right: 0,
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
    fontSize: '.875rem',
    minHeight: 0,
    padding: '5px .75rem',
    borderRadius: 20,
    '& svg': {
      fill: brandColor || '#000',
      width: 12,
    },
  }),
  title: {
    color: '#fff',
    fontSize: '1.125rem',
    fontWeight: 600,
    lineHeight: 1.5,
    padding: theme.spacing(0, 1),
  },
  subTitle: {
    color: '#fff',
    fontSize: '.875rem',
    fontWeight: 600,
    lineHeight: 1.5,
    padding: theme.spacing(0, 1, 1),
  },
  smallPrint: {
    color: '#647F99',
    fontSize: 14,
    marginTop: theme.spacing(0.5),
  },
}));

const PromotionItem = ({ item, brandColor }) => {
  const {
    campaignName,
    campaignWebTitle,
    campaignWebDescription,
    campaignBanner,
    campaignWebButtonText,
    campaignBannerLink,
  } = item;
  const hasText = !!(campaignWebTitle || campaignWebDescription);
  const classes = useStyles({ brandColor, hasText });

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        title={campaignName}
        className={classes.sliderContainer}
        style={{
          backgroundImage: `url(${campaignBanner})`,
          border: campaignBanner ? 'none' : '1px solid #E5E5E5',
        }}
      >
        {campaignWebButtonText && (
          <div className={classes.button}>
            {campaignWebButtonText}{' '}
            {campaignBannerLink && <ArrowRightIcon style={{ marginLeft: 2 }} />}
          </div>
        )}

        <Typography className={classes.title} noWrap>
          {campaignWebTitle}
        </Typography>
        <Typography className={classes.subTitle} noWrap>
          {campaignWebDescription}
        </Typography>
      </Box>
      <Typography className={classes.smallPrint} noWrap>
        {campaignName}
      </Typography>
    </>
  );
};

export default PromotionItem;
