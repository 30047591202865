import React, { useEffect, useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  FormControl,
  FormControlLabel,
  FormGroup,
  Radio,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Formik, Form, Field, FieldArray } from 'formik';
import { Checkbox, TextField } from 'formik-mui';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import UniversalSave from '../UniversalSave';
import { getAvailableDeviceCapabilitiesState } from '../../store/devices/selectors';
import { fetchAvailableDeviceCapabilities } from '../../store/devices';
import TableLink from '../TableLink';
import CustomDialog from '../CustomDialog';
import CopyStaffForm from './CopyStaffForm';
import useEndpoint from '../../hooks/useEndpoint';
import { fetchAllStaff } from '../../store/staff';
import { getAllStaffState } from '../../store/staff/selectors';

const useStyles = makeStyles((theme) => ({
  heading: {
    margin: '15px 0',
  },
  card: {
    marginTop: '20px',
    marginBottom: '20px',
  },
  helperText: {
    ...theme.customFonts.small,
    color: theme.customPalette.greyDark,
    marginLeft: '35px',
    marginTop: '-10px',
  },
  indentedHelper: {
    marginLeft: '100px',
  },
  indented: {
    marginLeft: '55px',
  },
  small: {
    ...theme.customFonts.small,
    '& .MuiFormControlLabel-label': {
      ...theme.customFonts.small,
    },
  },
  copy: {
    marginTop: '8px',
    marginBottom: '8px',
  },
}));

const reorderObjectKeys = (obj, keys) => {
  const result = {};
  keys.forEach((key) => {
    result[key] = obj[key] || false;
  });
  return result;
};

const capabilityOrder = [
  'EPOS',
  'ACCOUNTS',
  'EPOS_PROMO',
  'ORDERS',
  'REFUND',
  'STOCKS',
  'REPORTS',
  'CASH_RECORD',
  'TAB_MGMT',
  'VENUE_MGMT',
  'SERVER_PAYMENTS',
  'TAP_TO_PAY',
  'KIOSK',
];

const indented = ['ACCOUNTS', 'EPOS_PROMO', 'REFUND'];

const labels = {
  ORDERS: 'Orders history',
  REPORTS: 'Reports & payouts',
  STOCKS: 'Stock management',
  EPOS: 'EPOS: Take orders',
  VENUE_MGMT: 'Venue management',
  CASH_RECORD: 'Cash record',
  REFUND: 'Refunds',
  TAB_MGMT: 'Tab Management',
  SERVER_PAYMENTS: 'Server payments',
  TAP_TO_PAY: 'Tap to Pay',
  KIOSK: 'Kiosk Management',
  ACCOUNTS: 'Order to account',
  EPOS_PROMO: 'Show Promo codes',
};
const helperText = {
  ORDERS: 'View a history of orders, accept orders, make refunds & print receipts',
  REPORTS: 'View reports & payouts in settings',
  STOCKS: 'Quickly mark items in & out of stock',
  EPOS: 'Take orders, payments & access EPOS related settings',
  VENUE_MGMT: 'Overview of your tables, areas & tabs',
  CASH_RECORD: 'Perform a cash reconciliation',
  REFUND: 'Process refunds on orders',
  TAB_MGMT: 'Edit item quantities & delete tabs',
  SERVER_PAYMENTS: 'Enable the use of Payments Pro card reader',
  TAP_TO_PAY: 'Enable the use of Tap to Pay (iPhone Only)',
  KIOSK: 'Enable Kiosk management',
  ACCOUNTS: 'Send orders to custom accounts, e.g. Deliveroo or Staff Lunches',
  EPOS_PROMO: 'Quickly tap and apply promo codes from the discounts screen',
};

const checkVisibility = (val, values) => {
  if (val[0] === 'ACCOUNTS' && !values.capabilityArray.EPOS) {
    return false;
  }
  if (val[0] === 'EPOS_PROMO' && !values.capabilityArray.EPOS) {
    return false;
  }
  if (val[0] === 'REFUND' && !values.capabilityArray.ORDERS) {
    return false;
  }

  return true;
};

const EditStaffForm = ({ staffData, onSubmit }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { data: staff } = useEndpoint(getAllStaffState, fetchAllStaff()) || {};
  const { data: deviceCapabilities } = useSelector(getAvailableDeviceCapabilitiesState);

  const {
    staffId,
    name,
    enabled,
    pin,
    description,
    capabilities = [],
    isStaffCapabilitiesOn = false,
  } = staffData || {};

  const copyableStaff = staff?.filter((s) => s.capabilities?.length > 0 && s.staffId !== staffId);

  const isAdd = !staffId;
  const isName = name || '';
  const isStaffId = staffId || '';
  const isEnabled = enabled || false;
  const isPin = pin || '';
  const isDescription = description || '';
  const capabilityArray = deviceCapabilities
    ? deviceCapabilities.reduce((obj, cap) => ({ ...obj, [cap]: capabilities.includes(cap) }), {})
    : {};
  const [showCopyStaff, setShowCopyStaff] = useState(false);

  const initialValues = {
    staffId: isStaffId,
    name: isName,
    enabled: isEnabled,
    pin: isPin,
    description: isDescription,
    isStaffCapabilitiesOn,
    capabilityArray: reorderObjectKeys(capabilityArray, capabilityOrder),
  };

  const EditStaffSchema = Yup.object().shape({
    staffId: isAdd ? Yup.string() : Yup.string().oneOf([staffId]).required(),
    name: Yup.string().required(),
    description: Yup.string().min(1).required(),
    isStaffCapabilitiesOn: Yup.boolean(),
    pin: Yup.string().matches(/^\d+$/, 'Must be numeric').min(4).max(4).required(),
  });

  useEffect(() => {
    dispatch(fetchAvailableDeviceCapabilities('EPOS'));
  }, [dispatch]);

  const showCopyStaffDialog = () => {
    setShowCopyStaff(true);
  };
  const handleCloseDialog = () => {
    setShowCopyStaff(false);
  };

  return (
    <>
      <Card>
        <Formik
          enableReinitialize
          onSubmit={onSubmit}
          validationSchema={EditStaffSchema}
          initialValues={initialValues}
        >
          {({ setFieldValue, values, dirty, isValid, errors, resetForm }) => (
            <>
              <UniversalSave
                isValid={isValid}
                errors={errors}
                dirty={dirty}
                onSave={() => onSubmit(values)}
                onDiscard={resetForm}
              />
              <Form>
                <CardContent>
                  <section>
                    <Field
                      component={TextField}
                      name="name"
                      label="Name"
                      variant="outlined"
                      fullWidth
                      required
                      margin="normal"
                    />
                    <Field
                      component={TextField}
                      name="pin"
                      label="PIN (4 digits)"
                      variant="outlined"
                      fullWidth
                      required
                      disabled={!isAdd}
                      margin="normal"
                    />
                    <Field
                      component={TextField}
                      name="description"
                      label="Role E.g. Bar staff (Max 30 characters)"
                      variant="outlined"
                      required
                      fullWidth
                      margin="normal"
                    />
                  </section>

                  <section>
                    <FormGroup className={classes.section}>
                      <Typography className={classes.heading} variant="h4" component="h4">
                        Type of permission
                      </Typography>

                      <section>
                        <FormControl component="fieldset">
                          <FormControlLabel
                            className={classes.small}
                            control={
                              <Field
                                component={Radio}
                                value="true"
                                checked={!values.isStaffCapabilitiesOn}
                                id="isStaffCapabilitiesOn"
                                color="primary"
                              />
                            }
                            onClick={() => {
                              setFieldValue('isStaffCapabilitiesOn', false);
                            }}
                            label="Use the device permissions"
                          />
                          <FormControlLabel
                            className={classes.small}
                            control={
                              <Field
                                component={Radio}
                                value="false"
                                checked={values.isStaffCapabilitiesOn}
                                id="isStaffCapabilitiesOn"
                                color="primary"
                              />
                            }
                            onClick={() => {
                              setFieldValue('isStaffCapabilitiesOn', true);
                            }}
                            label="Apply custom permissions"
                          />
                        </FormControl>
                      </section>

                      {copyableStaff?.length > 0 && values.isStaffCapabilitiesOn && (
                        <TableLink
                          onClick={showCopyStaffDialog}
                          className={`${classes.small} ${classes.copy}`}
                        >
                          Copy an existing members permissions
                        </TableLink>
                      )}
                      {values.isStaffCapabilitiesOn && (
                        <Typography className={classes.heading} variant="h4" component="h4">
                          Permissions
                        </Typography>
                      )}
                      <FieldArray
                        render={() =>
                          values.capabilityArray &&
                          values.isStaffCapabilitiesOn && (
                            <Box display="flex" flexDirection="column" flexWrap="wrap">
                              {Object.entries(values.capabilityArray).map(
                                (val) =>
                                  labels[val[0]] &&
                                  checkVisibility(val, values) && (
                                    <div key={val[0]}>
                                      <FormControlLabel
                                        className={`${
                                          indented.indexOf(val[0]) >= 0 ? classes.indented : ''
                                        } ${classes.small}`}
                                        control={
                                          <Field
                                            component={Checkbox}
                                            color="primary"
                                            type="checkbox"
                                            name={`capabilityArray.${val[0]}`}
                                            checked={val[1]}
                                            value={val[1]}
                                            onChange={(_event, newValue) => {
                                              setFieldValue(`capabilityArray.${val[0]}`, newValue);
                                            }}
                                          />
                                        }
                                        label={labels[val[0]]}
                                      />
                                      <div
                                        className={`${classes.helperText} ${
                                          indented.indexOf(val[0]) >= 0 && classes.indentedHelper
                                        }`}
                                      >
                                        {helperText[val[0]]}
                                      </div>
                                    </div>
                                  ),
                              )}
                            </Box>
                          )
                        }
                      />
                    </FormGroup>
                  </section>
                </CardContent>
              </Form>
              <CustomDialog
                title="Copy an existing members permissions"
                isDialogOpen={showCopyStaff}
                handleCloseDialog={handleCloseDialog}
              >
                <CopyStaffForm
                  staffData={staffData}
                  cancel={handleCloseDialog}
                  staff={copyableStaff}
                  deviceCapabilities={deviceCapabilities}
                  setFieldValue={setFieldValue}
                />
              </CustomDialog>
            </>
          )}
        </Formik>
      </Card>
    </>
  );
};

export default EditStaffForm;
