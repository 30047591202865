export const payoutTypeEnums = {
  ALL_TYPES: 'All types',
  IN_HOUSE: 'In-house',
  DELIVERY: 'Delivery',
};

export const payoutTypes = [
  payoutTypeEnums.ALL_TYPES,
  payoutTypeEnums.IN_HOUSE,
  payoutTypeEnums.DELIVERY,
];
