import React from 'react';
import {
  Card,
  CardContent,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Typography,
} from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { Switch, TextField } from 'formik-mui';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { FiCopy } from 'react-icons/fi';
import copy from 'clipboard-copy';
import UniversalSave from '../../../components/UniversalSave';
import withVenue from '../../../hoc/withVenue';
import { useNotifications } from '../../../shared/contexts/Notifications/useNotifications';
import { getErrorMessage } from '../../../shared/utils/errors';
import { updateVenue, fetchVenue, updateVenueSettings } from '../../../store/venues';
import withSettings from '../../../hoc/withSettings';
import BackArrow from '../../../components/BackArrow';
import PageHeader from '../../../components/PageHeader';
import Page from '../../../components/Page';

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: '8px',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    '& > :nth-child(3), & > :nth-child(3)': {
      marginBottom: '10px',
    },
  },
  field: {
    width: '100%',
    margin: 0,
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.customPalette.grey,
    },
    '& .MuiSelect-icon': {
      color: theme.customPalette.greyDark,
    },
  },
  title: {
    ...theme.customFonts.largeBold,
    marginBottom: '16px',
  },
  subtitle: {
    ...theme.customFonts.label,
    color: theme.customPalette.greyDarker,
  },
}));

const VoucherSettings = ({ venue, setting, redirect }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { showErrorNotification, showSuccessNotification } = useNotifications();
  const voucherPath = venue && `${process.env.REACT_APP_WEBAPP_URI}/vouchers/${venue.venueId}`;
  const { vouchersEnabled } = venue;
  const { value } = setting;
  const initialValues = {
    vouchersEnabled,
    defaultVoucherExpiry: value,
    voucherPath,
  };
  const handleOnSubmit = async (values) => {
    try {
      if (values.defaultVoucherExpiry !== undefined)
        await dispatch(
          updateVenueSettings({
            settingName: 'DEFAULT_VOUCHER_EXPIRY',
            settingValue: values.defaultVoucherExpiry,
          }),
        );
      dispatch(updateVenue({ vouchersEnabled: values.vouchersEnabled }));
      showSuccessNotification('Successfully updated voucher settings');
    } catch (error) {
      showErrorNotification(getErrorMessage(error));
      dispatch(fetchVenue());
    }
  };

  return (
    <>
      <PageHeader>
        <BackArrow redirect={redirect} text="Settings" />
        <Typography className={classes.title}>Vouchers</Typography>
      </PageHeader>
      <Page>
        <Formik initialValues={initialValues} enableReinitialize>
          {({ setFieldValue, values, resetForm, errors, dirty, isValid }) => (
            <>
              <UniversalSave
                isValid={isValid}
                dirty={dirty}
                onSave={() => {
                  handleOnSubmit(values);
                }}
                onDiscard={resetForm}
                errors={errors}
              />
              <Form>
                <Card className={classes.card}>
                  <CardContent className={classes.container}>
                    <FormControlLabel
                      checked={values.vouchersEnabled}
                      onChange={() => {
                        setFieldValue('vouchersEnabled', !values.vouchersEnabled);
                      }}
                      label={values.vouchersEnabled ? 'Enabled' : 'Disabled'}
                      control={
                        <Field
                          name="vouchersEnabled"
                          component={Switch}
                          color="primary"
                          type="checkbox"
                          onChange={() => {
                            setFieldValue('vouchersEnabled', !values.vouchersEnabled);
                          }}
                        />
                      }
                    />
                    <Typography className={classes.subtitle}>
                      Default voucher expiry (months)
                    </Typography>
                    <Field
                      className={classes.field}
                      name="defaultVoucherExpiry"
                      variant="outlined"
                      value={values.defaultVoucherExpiry}
                      component={TextField}
                    />
                    <Typography className={classes.subtitle}>Venue voucher purchase URL</Typography>
                    <Field
                      className={classes.field}
                      component={TextField}
                      name="voucherPath"
                      variant="outlined"
                      fullWidth
                      required
                      disabled
                      margin="normal"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => {
                                copy(voucherPath);
                              }}
                              size="large"
                            >
                              <FiCopy />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </CardContent>
                </Card>
              </Form>
            </>
          )}
        </Formik>
      </Page>
    </>
  );
};

const voucherSettingsWithVenue = withVenue(VoucherSettings, '/settings');
export default withSettings(voucherSettingsWithVenue, 'DEFAULT_VOUCHER_EXPIRY');
