import React from 'react';
import { makeStyles } from '@mui/styles';
import { Card, CardContent, Icon } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';

const useStyles = makeStyles((theme) => ({
  yellowCard: {
    color: '#333333',
    backgroundColor: '#FFF7E3',
    marginBottom: theme.spacing(3),
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  icon: {
    marginRight: '12px',
  },
}));

const WarningCard = ({ children, borderRadius }) => {
  const classes = useStyles();

  return (
    <Card className={classes.yellowCard} style={{ borderRadius: borderRadius || '8px' }}>
      <CardContent className={classes.header}>
        <Icon className={classes.icon}>
          <ErrorIcon style={{ color: '#FFB800' }} />
        </Icon>
        {children}
      </CardContent>
    </Card>
  );
};

export default WarningCard;
