import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Button, CardContent, Typography } from '@mui/material';
import { Field, Form, Formik, useFormikContext } from 'formik';
import { TextField } from 'formik-mui';
import { useDispatch } from 'react-redux';
import { pick } from 'lodash';
import CustomDialog from '../CustomDialog';
import { deleteUpsellGroup, fetchUpsellGroups } from '../../store/upsellGroups';
import { useNotifications } from '../../shared/contexts/Notifications/useNotifications';
import { getErrorMessage } from '../../shared/utils/errors';
import { customPalette } from '../../theme/mui/colourPalettes';
import customFonts from '../../theme/mui/customFonts';

const useStyles = makeStyles(() => ({
  title: {
    ...customFonts.largeBold,
    marginBottom: '24px',
  },
  card: {
    borderRadius: '8px',
  },
  cardActions: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  delete: {
    backgroundColor: customPalette.destructiveRed,
    color: 'white',
  },
  rename: {
    backgroundColor: customPalette.midnight,
    color: 'white',
  },
}));

const FormObserver = ({ setFieldValue, setFormData }) => {
  const { values, dirty } = useFormikContext();
  useEffect(() => {
    setFormData(values);
  }, [values, dirty, setFieldValue, setFormData]);
  return null;
};

const CustomUpsellOptions = ({
  formAction,
  name,
  handleCloseDialog,
  isDialogOpen,
  upsellGroup,
  handleUpdateCustomUpsellGroup,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { showSuccessNotification, showErrorNotification } = useNotifications();
  const [formData, setFormData] = useState();
  const upsellGroupData = pick(upsellGroup, ['type', 'suggestedItemIds', 'enabled']);
  const { upsellGroupId } = upsellGroup;
  const handleDeleteUpsellGroup = async () => {
    try {
      await dispatch(deleteUpsellGroup(upsellGroup.upsellGroupId));
      showSuccessNotification(`${name} deleted successfully`);
      handleCloseDialog();
      dispatch(fetchUpsellGroups());
    } catch (error) {
      showErrorNotification(getErrorMessage(error));
    }
  };

  return (
    <CustomDialog
      isDialogOpen={isDialogOpen}
      handleCloseDialog={handleCloseDialog}
      title={formAction === 'rename' ? 'Rename upsell' : 'Delete upsell?'}
      actions={
        <>
          <Button variant="outlined" onClick={handleCloseDialog}>
            Cancel
          </Button>
          <Button
            variant="contained"
            type="submit"
            className={formAction === 'rename' ? classes.rename : classes.delete}
            onClick={
              formAction === 'rename'
                ? () =>
                    handleUpdateCustomUpsellGroup(upsellGroupId, {
                      ...upsellGroupData,
                      name: formData.name,
                    })
                : handleDeleteUpsellGroup
            }
          >
            {formAction === 'rename' ? 'Save' : 'Delete upsell'}
          </Button>
        </>
      }
    >
      <CardContent className={classes.card}>
        {formAction && formAction === 'rename' && (
          <Formik initialValues={{ name }} onSubmit={handleUpdateCustomUpsellGroup}>
            {({ setFieldValue }) => (
              <Form>
                <FormObserver setFormData={setFormData} setFieldValue={setFieldValue} />
                <Field
                  name="name"
                  component={TextField}
                  size="small"
                  margin="normal"
                  variant="outlined"
                  fullWidth
                />
              </Form>
            )}
          </Formik>
        )}
        {formAction && formAction === 'delete' && (
          <Typography>
            If you wish to use this upsell again in the future you may want to pause it instead.
          </Typography>
        )}
      </CardContent>
    </CustomDialog>
  );
};

export default CustomUpsellOptions;
