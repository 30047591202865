import React from 'react';

export default () => (
  <div
    style={{
      display: 'flex',
      height: '80vh',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      whiteSpace: 'pre-line',
    }}
  >
    <video src={process.env.REACT_APP_LOADING_ANIMATION} loop muted autoPlay />
  </div>
);
