/**
 * Converts pounds to pence.
 * @param {number} pounds - The amount in pounds to be converted.
 * @returns {number} - The equivalent amount in pence.
 */
export const convertPoundsToPence = (pounds) => Math.round(pounds * 100);

/**
 * Converts pence to pounds.
 * @param {number} pence - The amount in pence to be converted.
 * @returns {string} - The equivalent amount in pounds, formatted to two decimal places.
 */
export const convertPenceToPounds = (pence) => {
  const pounds = pence / 100;
  return pounds.toFixed(2);
};
