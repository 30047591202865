import React from 'react';
import { Button, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useHistory } from 'react-router';
import { ReactComponent as ChevronLeftComponent } from './customChevronLeft.svg';

const useStyles = makeStyles((theme) => ({
  button: {
    padding: '0.25rem',
    paddingBottom: '0.25rem',
    paddingLeft: 0,
    paddingRight: 0,
  },
  title: {
    color: theme.customPalette.trueBlue,
    ...theme.customFonts.medium,
  },
}));

const BackArrow = ({ to, redirect, text = 'Back' }) => {
  const history = useHistory();
  const classes = useStyles();
  const changeUrl = () => {
    if (to) {
      return history.push(to);
    }
    if (redirect) {
      return redirect();
    }
    return history.goBack();
  };

  return (
    <Button variant="text" className={classes.button} onClick={() => changeUrl()}>
      <ChevronLeftComponent />
      <Typography className={classes.title}>{text}</Typography>
    </Button>
  );
};

export default BackArrow;
