import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { Box, Button, Card, CardContent, Container, Grid, Typography } from '@mui/material';
import { setFirstLogin } from '../../store/signup/firstLogin';
import { getCognitoSignInUri } from '../../cognito/utils';

const useStyles = makeStyles((theme) => ({
  heading: {
    marginBottom: '20px',
    display: 'flex',
  },
  title: {
    flexGrow: 1,
  },
  root: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  login: {
    width: '60%',
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
}));

const Onboarding = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    setFirstLogin(true);
  }, [dispatch]);

  return (
    <Container className={classes.root}>
      <Card className={classes.login}>
        <CardContent>
          <Grid container>
            <Grid item xs={12} className={classes.border}>
              <Box className={classes.titleBox}>
                <Typography className={classes.title} variant="h6" align="center">
                  Signup Completed
                </Typography>
                <Typography className={classes.subtitle} variant="subtitle1" align="center">
                  Please check our email to complete your account
                </Typography>
              </Box>
              <Box m={2} display="flex" alignItems="flex-end" justifyContent="center">
                <Button
                  color="primary"
                  align="center"
                  size="small"
                  variant="contained"
                  href={getCognitoSignInUri()}
                >
                  Log in
                </Button>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Container>
  );
};

export default Onboarding;
