/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Field } from 'formik';
import { Grid, Typography } from '@mui/material';
import { Switch } from 'formik-mui';
import TimeSegmentControl from './TimeSegmentControl';

const DeliveryHoursDay = ({ index, setFieldValue, values, handleCopyHours, hoursAreUpdating }) => {
  const { day, openingHours, closed } = values;

  const handleNewSegment = () => {
    setFieldValue(`[${index}].openingHours`, [
      ...openingHours,
      { fromHour: null, fromMinute: null, toHour: null, toMinute: null, nextDay: false },
    ]);
  };

  const handleRemoveSegment = (segmentIndex) => {
    const newOpeningHours = openingHours.filter((_segment, i) => i !== segmentIndex);
    setFieldValue(`[${index}].openingHours`, newOpeningHours);
  };

  return (
    <Grid container item xs={12} spacing={2}>
      <Grid item xs={2}>
        <Typography variant="body2" style={{ fontWeight: 'bold', paddingTop: 10 }}>
          {day}
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <Field
          component={Switch}
          type="checkbox"
          name="closed"
          checked={!closed}
          onChange={() => {
            setFieldValue(`[${index}].closed`, !closed);
            setFieldValue(`[${index}].openingHours`, [
              { fromHour: null, fromMinute: null, toHour: null, toMinute: null, nextDay: false },
            ]);
          }}
          disabled={hoursAreUpdating}
        />
        Open
      </Grid>
      <Grid item xs={7}>
        {openingHours.map((openingSegment, segmentIndex) => (
          <TimeSegmentControl
            key={`${day}-${segmentIndex}`}
            previousSegment={openingHours[segmentIndex - 1] || null}
            segmentHours={openingSegment}
            segmentIndex={segmentIndex}
            dayIndex={index}
            setFieldValue={setFieldValue}
            disabled={closed || hoursAreUpdating}
            handleNewSegment={() => handleNewSegment()}
            handleRemoveSegment={() => handleRemoveSegment(segmentIndex)}
            handleCopyHours={handleCopyHours}
          />
        ))}
      </Grid>
    </Grid>
  );
};

export default DeliveryHoursDay;
