import { pick } from 'ramda';
import { del, get, patch, post } from './httpRequest';

export const createAccount = async (data) => {
  await post(`/accounts`, { data });
};

export const fetchAccounts = async () => {
  const response = await get(`/accounts`);
  return response.data;
};

export const fetchAccount = async (accountId) => {
  const response = await get(`/accounts/${accountId}`);
  return response.data;
};

export const updateAccount = async (accountId, values) => {
  await patch(`/accounts/${accountId}`, {
    data: pick(['name', 'description', 'enabled'], values),
  });
};

export const deleteAccount = async (accountId) => {
  await del(`/accounts/${accountId}`);
};
