import pctDecrease from './pctDecrease';
import pctIncrease from './pctIncrease';

const lozengeLogic = (current, previous) => {
  const percentageIncrease = pctIncrease(current, previous);
  const percentageDecrease = pctDecrease(current, previous);

  const isChange = percentageIncrease !== 0;
  const isChangePositive = percentageIncrease > 0;
  const changeCopy = isChangePositive ? ` +${percentageIncrease}%` : ` -${percentageDecrease}%`;
  const showLozenge = isChange && percentageIncrease !== null;
  const change = showLozenge ? changeCopy : '';

  return { change, isChangePositive, showLozenge };
};

export default lozengeLogic;
