import { makeStyles } from '@mui/styles';
import React from 'react';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  link: {
    textDecoration: 'underline',
    cursor: 'pointer',
    color: '#1870ED',
    '&:hover': {
      textDecoration: 'none',
    },
    '&:active': {
      color: '#0E55BA',
    },
    '&:visited': {
      color: (props) => (props.novisited ? '#1870ED' : '#333333'),
    },
  },
}));

const TableLink = ({ children, ...props }) => {
  // eslint-disable-next-line no-unused-vars
  const { to, onClick, className = '', novisited = false } = props;
  const classes = useStyles(props);

  return to ? (
    <Link to={to || ''} className={classes.link}>
      {children}
    </Link>
  ) : (
    <div
      role="button"
      tabIndex={0}
      onKeyDown={onClick}
      className={`${classes.link} ${className}`}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

export default TableLink;
