import { postWithoutAuthorisation } from './httpRequest';

export const sanitiseCreateSignupApiPayload = (payload) => {
  const { hubspotCompanyId, isBusinessAddressSameAsAddress } = payload;
  const hasHubspotCompanyId = hubspotCompanyId;
  const isBusinessAddressNeeded = isBusinessAddressSameAsAddress === 'no';
  const sanitisedPayload = {
    ...payload,
    termsOfService: true,
  };

  delete sanitisedPayload.recaptcha;
  delete sanitisedPayload.isBusinessAddressSameAsAddress;

  if (!hasHubspotCompanyId) {
    delete sanitisedPayload.hubspotCompanyId;
  }

  if (!isBusinessAddressNeeded) {
    delete sanitisedPayload.businessAddress;
    delete sanitisedPayload.businessCity;
    delete sanitisedPayload.businessPostcode;
  }

  return sanitisedPayload;
};

export const createSignupApi = async (data) => {
  const sanitisedData = sanitiseCreateSignupApiPayload(data);

  const response = await postWithoutAuthorisation(`/signup`, {
    data: sanitisedData,
  });

  return response.data;
};
