import React, { useState } from 'react';

import { makeStyles } from '@mui/styles';
import { Button, Card, CardContent, Grid, Link, Typography } from '@mui/material';

import UpgradeImage from './upgrade.png';
import UpgradeDialog from '../../components/UpgradeDialog';

const useStyles = makeStyles(() => ({
  subtitle: {
    fontFamily: 'Sen',
    fontWeight: 'bold',
    marginBottom: '0.5rem',
  },
  body: {
    marginBottom: '1rem',
    fontSize: '0.8rem',
    textAlign: 'center',
  },
  image: {
    display: 'flex',
    justifyContent: 'center',
  },
  column: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  button: {
    marginBottom: '1rem',
  },
  buttons: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-around',
    gap: '1rem',
  },
}));

const MeetCard = () => {
  const classes = useStyles();

  const [dialogOpen, setDialogOpen] = useState(false);

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };
  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  return (
    <>
      <Grid item xs={12}>
        <Card>
          <CardContent className={classes.image}>
            <img src={UpgradeImage} alt="Locked" />
          </CardContent>
          <CardContent className={classes.column}>
            <Typography variant="subtitle2" className={classes.subtitle}>
              Meet Sessions Serve
            </Typography>
            <Typography variant="body2" color="primary" className={classes.body}>
              Process more orders with our all-in-one EPOS solution
            </Typography>
            <div className={classes.buttons}>
              <Button
                variant="outlined"
                component={Link}
                href="https://serve.sessions.co.uk/"
                target="_blank"
                color="primary"
                className={classes.button}
              >
                Learn more
              </Button>
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={handleOpenDialog}
              >
                Request a call
              </Button>
            </div>
          </CardContent>
        </Card>
      </Grid>
      <UpgradeDialog open={dialogOpen} onClose={handleCloseDialog} />
    </>
  );
};

export default MeetCard;
