import { createSlice } from '@reduxjs/toolkit';
import {
  asyncInitialState as initialState,
  createAsyncAction,
  createAsyncReducerHandlers,
} from '../utils/async';
import {
  fetchPayments as fetchPaymentsApi,
  sendPayment as sendPaymentsApi,
} from '../../api/payments';

export const fetchPayments = createAsyncAction('fetch-payments', fetchPaymentsApi);
export const sendPayments = createAsyncAction('send-payments', sendPaymentsApi);

const paymentsSlice = createSlice({
  name: 'payments',
  initialState,
  reducers: {
    clearPayments(state) {
      delete state.data;
    },
  },
  extraReducers: {
    ...createAsyncReducerHandlers(fetchPayments),
    ...createAsyncReducerHandlers(sendPayments),
  },
});
export const { clearPayments } = paymentsSlice.actions;

export const { reducer: payments } = paymentsSlice;
