import moment from 'moment-timezone';

const promoCodeStatus = {
  EXPIRED: 'EXPIRED',
  USED: 'USED',
  CANCELLED: 'CANCELLED',
  ACTIVE: 'ACTIVE',
};

const formatPromoCode = (payload) => {
  const { promoCode, type, label, expiresAt, usageCount, maxUsageCount } = payload;
  const isExpired = moment(expiresAt).isBefore();
  const isMaxUsed = usageCount >= maxUsageCount;
  const isCancelled = false;

  const getStatus = () => {
    switch (true) {
      case isExpired:
        return promoCodeStatus.EXPIRED;
      case isMaxUsed:
        return promoCodeStatus.USED;
      case isCancelled:
        return promoCodeStatus.CANCELLED;

      default:
        return promoCodeStatus.ACTIVE;
    }
  };

  return {
    promoCode,
    type,
    label,
    expiry: moment(expiresAt).format('HH:mm - DD/MM/YYYY'),
    usage: `${usageCount}/${maxUsageCount}`,
    status: getStatus(),
  };
};

export default formatPromoCode;
