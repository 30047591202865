import { rangeEnums } from '../utils/dateRanges';

export const UNITS = {
  MINUTES: 'm',
  HOURS: 'h',
  DAYS: 'd',
  WEEKS: 'w',
  MONTHS: 'M',
  YEARS: 'y',
};

export const REPORTING_TYPES = {
  OPERATIONAL: 'OPERATIONAL',
  TRANSACTIONAL: 'TRANSACTIONAL',
  UTC: 'UTC',
};
export const DISPLAY_REPORTING_TYPES = {
  OPERATIONAL: 'Operational (5am to 5am)',
  TRANSACTIONAL: 'Transactional (Local Time)',
  UTC: 'Transactional (UTC)',
};

export const RANGEUNITS = {
  [rangeEnums.TODAY]: {
    HOURS: 'h',
  },
  [rangeEnums.YESTERDAY]: {
    HOURS: 'h',
  },
  [rangeEnums.LAST_7_DAYS]: {
    DAYS: 'd',
    HOURS: 'h',
  },
  [rangeEnums.LAST_FULL_WEEK]: {
    DAYS: 'd',
    HOURS: 'h',
  },
  [rangeEnums.LAST_4_WEEKS]: {
    WEEKS: 'w',
    DAYS: 'd',
  },
  [rangeEnums.LAST_3_MONTHS]: {
    MONTHS: 'M',
    WEEKS: 'w',
    DAYS: 'd',
  },
  [rangeEnums.LAST_12_MONTHS]: {
    MONTHS: 'M',
    WEEKS: 'w',
  },
  [rangeEnums.MONTH_TO_DATE]: {
    WEEKS: 'w',
    DAYS: 'd',
  },
  [rangeEnums.QUARTER_TO_DATE]: {
    MONTHS: 'M',
    WEEKS: 'w',
    DAYS: 'd',
  },
  [rangeEnums.YEAR_TO_DATE]: {
    MONTHS: 'M',
    WEEKS: 'w',
  },
  [rangeEnums.CUSTOM]: {
    YEARS: 'y',
    MONTHS: 'M',
    WEEKS: 'w',
    DAYS: 'd',
    HOURS: 'h',
    MINUTES: 'm',
  },
};

export const DISPLAYUNITS = {
  m: 'Minutes',
  h: 'Hours',
  d: 'Days',
  w: 'Weeks',
  M: 'Months',
  y: 'Years',
};
