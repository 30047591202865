import React, { useState } from 'react';
import { Box, Button, IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Delete } from '@mui/icons-material';
import { useDispatch } from 'react-redux';

import PhotoUploader from '../../../components/PhotoUploader';
import { deleteBrandCarouselImage } from '../../../store/imageGallery';
import { useNotifications } from '../../../shared/contexts/Notifications/useNotifications';
import { getErrorMessage } from '../../../shared/utils/errors';
import { fetchBrand } from '../../../store/brands/brand';
import ConfirmRemoveDialog from '../../../components/ConfirmRemoveDialog';

const useStyles = makeStyles((theme) => ({
  carouselImageContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const CarouselUploader = ({ brandId, brandCarouselImageUrls, brandCarouselImageUploadUrls }) => {
  const { showErrorNotification, showSuccessNotification } = useNotifications();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [activeImageCount, setActiveImageCount] = useState(brandCarouselImageUrls.length);
  const [confirmDeleteDialogData, setConfirmDeleteDialogData] = useState({
    imageId: null,
    showDialog: false,
  });

  const handleDeleteImage = async ({ imageId }) => {
    try {
      await dispatch(deleteBrandCarouselImage({ brandId, imageName: imageId }));
      showSuccessNotification(`Carousel image ${imageId} deleted successfully`);
      setConfirmDeleteDialogData({ ...confirmDeleteDialogData, showDialog: false });
      dispatch(fetchBrand(brandId));
    } catch (error) {
      showErrorNotification(getErrorMessage(error));
    }
  };

  return (
    <>
      {[...Array(activeImageCount)].map((_, index) => (
        <Box className={classes.carouselImageContainer} key={brandCarouselImageUploadUrls[index]}>
          <PhotoUploader
            imgUploadUrl={brandCarouselImageUploadUrls[index]}
            imgUrl={brandCarouselImageUrls[index]}
            hasImage={!!brandCarouselImageUrls[index]}
            brandId={brandId}
            isKioskCarouselImage
            small
          />
          <IconButton
            onClick={() => {
              if (!brandCarouselImageUrls[index]) {
                setActiveImageCount(activeImageCount - 1);
              } else {
                setConfirmDeleteDialogData({
                  imageId: brandCarouselImageUrls[index].split('/').pop(),
                  showDialog: true,
                });
              }
            }}
            sx={{ ml: 1 }}
          >
            <Delete />
          </IconButton>
        </Box>
      ))}
      <Box>
        <Button
          onClick={() => setActiveImageCount(activeImageCount + 1)}
          disabled={activeImageCount >= 5}
          variant="outlined"
          sx={{ mt: 1 }}
        >
          Add Image
        </Button>
      </Box>
      <ConfirmRemoveDialog
        title={`This will remove the carousel image ${confirmDeleteDialogData.imageId}`}
        handleClose={() =>
          setConfirmDeleteDialogData({ ...confirmDeleteDialogData, showDialog: false })
        }
        handleConfirm={() => {
          handleDeleteImage({ imageId: confirmDeleteDialogData.imageId });
        }}
        showDialog={confirmDeleteDialogData.showDialog}
      />
    </>
  );
};

export default CarouselUploader;
