import { createSlice } from '@reduxjs/toolkit';
import {
  asyncInitialState as initialState,
  createAsyncAction,
  createAsyncReducerHandlers,
} from '../utils/async';

import { fetchDeliveryStatements as fetchDeliveryStatementsApi } from '../../api/deliveryStatements';

export const fetchDeliveryStatements = createAsyncAction(
  'fetch-delivery-statements',
  fetchDeliveryStatementsApi,
);

const deliveryStatementsSlice = createSlice({
  name: 'deliveryStatements',
  initialState,
  reducers: {
    clearDeliveryStatementsSlice(state) {
      delete state.data;
    },
  },
  extraReducers: {
    ...createAsyncReducerHandlers(fetchDeliveryStatements),
  },
});

export const { clearDeliveryStatementsSlice } = deliveryStatementsSlice.actions;

export const { reducer: deliveryStatements } = deliveryStatementsSlice;
