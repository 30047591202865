import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import formatNumber from '../../shared/utils/formatNumber';
import PercentageStatusLozenge from '../PercentageStatusLozenge';

const useStyles = makeStyles((theme) => ({
  row: {
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  title: {
    ...theme.customFonts.small,
    color: theme.customPalette.greyDarker,
    flex: '1',
  },
  total: {
    ...theme.customFonts.small,
    color: theme.customPalette.greyDarker,
  },
  divider: {
    borderTop: '1px solid #D9E2EC',
    margin: theme.spacing(2, 0),
  },
}));

const ReportsVouchersIssued = ({ data, prefix = '£' }) => {
  const classes = useStyles();
  const titles = [
    {
      title: 'Vouchers Sold',
      dataField: 'sold',
    },
    {
      title: 'External Vouchers Created',
      dataField: 'external',
    },
  ];

  const displayData = titles.map(({ title, dataField }) => ({
    title,
    total: data.current.voucherOrders[dataField],
    current: data.current.voucherOrders[dataField],
    previous: data.previous.voucherOrders[dataField],
  }));

  return (
    <>
      <div className={classes.divider} />
      {displayData.map(({ title, total, current, previous }) =>
        total ? (
          <Box key={title} display="flex" className={classes.row}>
            <Typography className={classes.title}>{title}</Typography>
            <Typography className={classes.total}>
              {prefix}
              {formatNumber(total, 2)}
            </Typography>
            <PercentageStatusLozenge current={current} previous={previous} />
          </Box>
        ) : null,
      )}
    </>
  );
};
export default ReportsVouchersIssued;
