import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { makeStyles } from '@mui/styles';
import { Typography, Button, CircularProgress } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { clearOrders, fetchOrders, fetchOrdersPage, sendPolarisOrders } from '../../store/orders';
import { getOrders, getOrdersState, getPolarisOrdersState } from '../../store/orders/selectors';
import { getVenueState } from '../../store/venues/selectors';
import { useNotifications } from '../../shared/contexts/Notifications/useNotifications';

import Page from '../../components/Page';
import PageHeader from '../../components/PageHeader';
import OrdersTable from '../../components/OrdersTable';
import { rangeEnums } from '../../shared/utils/dateRanges';
import DownloadReport from '../../components/DownloadReport';
import withVenue from '../../hoc/withVenue';
import usePagination from '../../hooks/usePagination';
import ESPicker from '../../components/ESPicker';
import useESPicker from '../../hooks/useESPicker';
import shouldLoad from '../../shared/utils/shouldLoad';

const useStyles = makeStyles(() => ({
  heading: {
    marginBottom: '20px',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  title: {
    marginRight: '20px',
    width: '50%',
    flexGrow: 1,
  },
}));

const Orders = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const ordersState = useSelector(getOrdersState);
  const venueState = useSelector(getVenueState);
  const { loading: polarisLoading } = useSelector(getPolarisOrdersState);
  const { showSuccessNotification } = useNotifications();

  const {
    data: { venueId },
  } = venueState;
  const { loading, error } = ordersState;
  const raworders = useSelector(getOrders) || [];
  const orders = {
    docs: raworders.docs || [],
    total: raworders.total || 0,
  };

  const pagination = usePagination();

  const esPicker = useESPicker();
  const { selectedDates, searchTerm, dateRange, sortOrder, reportingType } = esPicker;
  const { first, requestNewPage, rowsPerPage, resetPagination } = pagination;

  const handleSendPolarisOrders = async () => {
    await dispatch(sendPolarisOrders({ venueId })); // this doesn't wait, not sure what I'm doing wrong
    showSuccessNotification('Orders sent to Polaris');
  };

  const handleFetchOrdersPage = useCallback(() => {
    dispatch(
      fetchOrdersPage({
        first,
        size: rowsPerPage,
        sortOrder,
        searchTerm,
        dateRange,
        from: dateRange === rangeEnums.CUSTOM && selectedDates.from,
        to: dateRange === rangeEnums.CUSTOM && selectedDates.to,
        reportingType,
      }),
    );
  }, [
    first,
    sortOrder,
    dateRange,
    dispatch,
    searchTerm,
    selectedDates,
    rowsPerPage,
    reportingType,
  ]);

  const getData = useCallback(() => {
    resetPagination();
    dispatch(
      fetchOrders({
        first: 0,
        size: rowsPerPage,
        sortOrder,
        searchTerm,
        dateRange,
        from: dateRange === rangeEnums.CUSTOM && selectedDates.from,
        to: dateRange === rangeEnums.CUSTOM && selectedDates.to,
        reportingType,
      }),
    );
  }, [
    dispatch,
    sortOrder,
    searchTerm,
    dateRange,
    selectedDates,
    rowsPerPage,
    reportingType,
    resetPagination,
  ]);

  useEffect(() => {
    if (shouldLoad(ordersState)) getData();
  }, [getData, ordersState]);

  useEffect(() => {
    dispatch(clearOrders());
    getData();
  }, [
    sortOrder,
    searchTerm,
    dateRange,
    selectedDates,
    rowsPerPage,
    resetPagination,
    getData,
    dispatch,
  ]);

  useEffect(() => {
    if (requestNewPage) {
      handleFetchOrdersPage();
    }
  }, [requestNewPage, handleFetchOrdersPage]);

  const isOrdersLoading = loading && !orders;

  return (
    <>
      <PageHeader fullWidth>
        <div className={classes.heading}>
          <Typography className={classes.title} variant="h2" component="h1">
            Order History
          </Typography>
          <ESPicker esPicker={esPicker}>
            <DownloadReport type="breakdown" includeVouchers="exclude" />
            {venueId && venueId === 'block-8c563f01-f85d-49c8-92a3-3f77ff5ae523' && (
              <Button
                style={{ whiteSpace: 'nowrap', padding: '0 60px' }}
                variant="contained"
                color="primary"
                startIcon={
                  polarisLoading ? (
                    <CircularProgress size="1.2rem" style={{ color: '#E1E9F2' }} />
                  ) : (
                    <SendIcon />
                  )
                }
                onClick={() => handleSendPolarisOrders()}
              >
                Send Polaris Orders
              </Button>
            )}
          </ESPicker>
        </div>
      </PageHeader>
      <Page loading={isOrdersLoading} error={error} fullWidth>
        {orders && (
          <>
            <OrdersTable pagination={pagination} orders={orders} />
          </>
        )}
      </Page>
    </>
  );
};

export default withVenue(Orders, null, clearOrders);
