import { createSlice } from '@reduxjs/toolkit';
import {
  asyncInitialState as initialState,
  createAsyncAction,
  createAsyncReducerHandlers,
} from '../utils/async';
import {
  fetchMobiles as fetchMobileDevicesApi,
  updateMobileDevice as updateMobileDeviceApi,
  updateMobileDevicesAvailability as updateMobileDevicesAvailabilityApi,
  fetchMobileDevice as fetchMobileDeviceApi,
  deleteMobileDevice as deleteMobileDeviceApi,
  fetchOnboardingDeviceKey as fetchOnboardingDeviceKeyApi,
} from '../../api/mobiles';

export const fetchMobileDevices = createAsyncAction('fetch-mobile-devices', fetchMobileDevicesApi);
export const updateMobileDevice = createAsyncAction('update-mobile-device', updateMobileDeviceApi);
export const updateMobileDevicesAvailability = createAsyncAction(
  'update-mobile-devices-availability',
  updateMobileDevicesAvailabilityApi,
);
export const fetchMobileDevice = createAsyncAction('fetch-mobile-device', fetchMobileDeviceApi);
export const deleteMobileDevice = createAsyncAction('delete-mobile-device', deleteMobileDeviceApi);

export const fetchOnboardingDeviceKey = createAsyncAction(
  'fetch-onboarding-device-key',
  fetchOnboardingDeviceKeyApi,
);

const onboardingDeviceKeySlice = createSlice({
  name: 'onboardingDeviceKey',
  initialState,
  // reducers: {
  //   clearMobileDevices(state) {
  //     delete state.data;
  //   },
  // },
  extraReducers: createAsyncReducerHandlers(fetchOnboardingDeviceKey),
});

const mobileDevicesSlice = createSlice({
  name: 'mobileDevices',
  initialState,
  reducers: {
    clearMobileDevices(state) {
      delete state.data;
    },
  },
  extraReducers: createAsyncReducerHandlers(fetchMobileDevices),
});

const mobileDeviceSlice = createSlice({
  name: 'mobileDevice',
  initialState,
  reducers: {
    clearMobileDevice(state) {
      delete state.data;
    },
  },
  extraReducers: {
    ...createAsyncReducerHandlers(updateMobileDevice),
    ...createAsyncReducerHandlers(fetchMobileDevice),
    ...createAsyncReducerHandlers(deleteMobileDevice),
  },
});
export const { clearMobileDevice } = mobileDeviceSlice.actions;
export const { clearMobileDevices } = mobileDevicesSlice.actions;

export const { reducer: mobileDevices } = mobileDevicesSlice;
export const { reducer: mobileDevice } = mobileDeviceSlice;

export const { reducer: onboardingDeviceKey } = onboardingDeviceKeySlice;
