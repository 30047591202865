import { createSlice } from '@reduxjs/toolkit';
import {
  createAsyncAction,
  asyncInitialState as initialState,
  createAsyncReducerHandlers,
} from '../utils/async';
import {
  fetchVenueMenus as fetchVenueMenusApi,
  fetchVenueMenu as fetchVenueMenuApi,
  createVenueMenu as createVenueMenuApi,
  importVenueMenu as importVenueMenuApi,
  updateVenueMenu as updateVenueMenuApi,
  deleteVenueMenu as deleteVenueMenuApi,
  duplicateVenueMenu as duplicateVenueMenuApi,
  publishVenueMenu as publishVenueMenuApi,
  fetchMenuStatus as fetchMenuStatusApi,
} from '../../api/venueMenu';

export const fetchVenueMenus = createAsyncAction('fetch-venue-menus', fetchVenueMenusApi);

export const fetchVenueMenu = createAsyncAction('fetch-venue-menu', fetchVenueMenuApi);

export const publishVenueMenu = createAsyncAction('publish-venue-menu', publishVenueMenuApi);

export const fetchVenueMenuStatus = createAsyncAction(
  'fetch-venue-menu-publish-status',
  fetchMenuStatusApi,
);

export const importVenueMenu = createAsyncAction('import-venue-menu', importVenueMenuApi);

export const createVenueMenu = createAsyncAction('create-venue-menu', createVenueMenuApi);

export const updateVenueMenu = createAsyncAction('update-venue-menu', updateVenueMenuApi);

export const deleteVenueMenu = createAsyncAction('delete-venue-menu', deleteVenueMenuApi);

export const duplicateVenueMenu = createAsyncAction('duplicate-venue-menus', duplicateVenueMenuApi);

const venueMenusSlice = createSlice({
  name: 'venue-menus',
  initialState,
  reducers: {
    clearVenueMenus(state) {
      delete state.data;
    },
  },
  extraReducers: createAsyncReducerHandlers(fetchVenueMenus),
});

const venueMenuSlice = createSlice({
  name: 'venue-menu',
  initialState,
  reducers: {
    clearVenueMenu(state) {
      delete state.data;
    },
  },
  extraReducers: {
    ...createAsyncReducerHandlers(fetchVenueMenu),
    ...createAsyncReducerHandlers(createVenueMenu),
    ...createAsyncReducerHandlers(importVenueMenu),
    ...createAsyncReducerHandlers(updateVenueMenu),
    ...createAsyncReducerHandlers(deleteVenueMenu),
  },
});

const venueStatusSlice = createSlice({
  name: 'venue-menu-status',
  initialState,
  reducers: {
    clearVenueMenuStatus(state) {
      delete state.data;
    },
  },
  extraReducers: createAsyncReducerHandlers(fetchVenueMenuStatus),
});

export const { clearVenueMenus } = venueMenusSlice.actions;
export const { clearVenueMenu } = venueMenuSlice.actions;
export const { clearVenueMenuStatus } = venueStatusSlice.actions;
export const { reducer: venueMenus } = venueMenusSlice;
export const { reducer: venueMenu } = venueMenuSlice;
export const { reducer: venueMenuStatus } = venueStatusSlice;
