import { createAction, createSlice } from '@reduxjs/toolkit';
import {
  createAsyncAction,
  asyncInitialState as initialState,
  createAsyncReducerHandlers,
} from '../utils/async';
import {
  fetchMenuItems as fetchMenuItemsApi,
  fetchMenuItem as fetchMenuItemApi,
  createMenuItem as createMenuItemApi,
  updateMenuItem as updateMenuItemApi,
  updateMenuItemsAvailability as updateMenuItemsAvailabilityApi,
  deleteMenuItem as deleteMenuItemApi,
} from '../../api/menuItems';

export const fetchMenuItems = createAsyncAction('fetch-menu-items', fetchMenuItemsApi);

export const fetchMenuItem = createAsyncAction('fetch-menu-item', fetchMenuItemApi);

export const deleteMenuItem = createAsyncAction('delete-menu-item', deleteMenuItemApi);

export const createMenuItem = createAsyncAction('create-menu-item', createMenuItemApi);

const assumeMenuItem = createAction('assume-menu-item');

export const updateMenuItem = createAsyncAction(
  'update-menu-item',
  async ({ menuItemId, values }, dispatch) => {
    dispatch(assumeMenuItem(values));
    await updateMenuItemApi({ menuItemId, values });
  },
);

export const updateMenuItemsAvailability = createAsyncAction(
  'update-menu-items-availability',
  async ({ values }) => {
    await updateMenuItemsAvailabilityApi({ values });
  },
);

const menuItemsSlice = createSlice({
  name: 'menuItems',
  initialState,
  reducers: {
    clearMenuItems(state) {
      delete state.data;
    },
  },
  extraReducers: {
    ...createAsyncReducerHandlers(fetchMenuItems),
    ...createAsyncReducerHandlers(updateMenuItemsAvailability),
  },
});

const menuItemSlice = createSlice({
  name: 'menuItem',
  initialState,
  reducers: {
    clearMenuItem(state) {
      delete state.data;
    },
  },
  extraReducers: {
    ...createAsyncReducerHandlers(fetchMenuItem),

    [assumeMenuItem]: (state, action) => {
      if (state.data) {
        // eslint-disable-next-line no-param-reassign
        state.data = {
          ...state.data,
          ...action.payload,
        };
      }
    },
  },
});
export const { clearMenuItems } = menuItemsSlice.actions;
export const { clearMenuItem } = menuItemSlice.actions;

export const { reducer: menuItems } = menuItemsSlice;
export const { reducer: menuItem } = menuItemSlice;
