import React from 'react';

import { makeStyles } from '@mui/styles';
import { Card, CardContent, Typography, Link as MuiLink, Grid } from '@mui/material';
import {
  ContactSupportOutlined,
  LightbulbOutlined,
  TakeoutDiningOutlined,
  VisibilityOffOutlined,
  UTurnLeftOutlined,
} from '@mui/icons-material';
import { useIntercom } from 'react-use-intercom';
import Grocery from '../../assets/images/grocery.svg';
// import { useSelector } from 'react-redux';
// import { isBrandUp } from '../../store/venues/selectors';

const useStyles = makeStyles(() => ({
  title: {
    fontFamily: 'Sen',
    fontWeight: 'bold',
    flexGrow: 1,
    marginBottom: '1rem',
  },
  link: {
    display: 'flex',
    gap: '16px',
    '&:visited': {
      color: '#333333',
    },
  },
}));

const QuickActionsCard = ({ type }) => {
  // const isBrandup = useSelector(isBrandUp);
  const classes = useStyles();
  const { show } = useIntercom();

  return (
    <Grid item xs={12}>
      <Card>
        <CardContent>
          <Typography className={classes.title} variant="h4">
            Quick actions
          </Typography>
          {type === 'DUET' && (
            <>
              <p>
                <MuiLink
                  href="https://store.sessions.co.uk/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.link}
                >
                  <TakeoutDiningOutlined />
                  Order packaging
                </MuiLink>
              </p>
              <p>
                <MuiLink
                  href="https://www.bidfood.co.uk/login/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.link}
                >
                  <img src={Grocery} alt="Grocery icon" style={{ paddingLeft: '4px' }} />
                  Order from Bidfood
                </MuiLink>
              </p>
            </>
          )}
          <p>
            <MuiLink href="/orders-history" className={classes.link}>
              <UTurnLeftOutlined sx={{ transform: 'rotate(90deg)' }} />
              Refund an order
            </MuiLink>
          </p>
          <p>
            <MuiLink href="/items" className={classes.link}>
              <VisibilityOffOutlined />
              Mark an item as out of stock
            </MuiLink>
          </p>
          <p>
            <MuiLink
              href="https://hub.sessions.co.uk/knowledge/faqs-tips"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.link}
            >
              <LightbulbOutlined />
              FAQ&apos;s
            </MuiLink>
          </p>
          {/**
           <p>
            <MuiLink
              underline="always"
              href={
                isBrandup
                  ? 'https://servedup.co/brandup-help'
                  : 'https://servedup.co/faq?hsLang=en-gb'
              }
              className={classes.link}
              target="_blank"
            >
              FAQ’s
            </MuiLink>
          </p>
          * */}
          <p>
            <MuiLink underline="always" href="#" onClick={show} className={classes.link}>
              <ContactSupportOutlined />
              Need help? Message us!
            </MuiLink>
          </p>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default QuickActionsCard;
