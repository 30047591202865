import React from 'react';
import { Box, IconButton, List, ListItemButton, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { DragDropContext, Draggable } from 'react-beautiful-dnd';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { MdDelete, MdEdit } from 'react-icons/md';

import Droppable from '../DragAndDrop/Droppable';

const useStyles = makeStyles((theme) => ({
  priorityItemWrapper: {
    marginBottom: theme.spacing(1),
    height: theme.spacing(5.5),
  },
  priorityItem: {
    height: '100%',
  },
  priorityItemDisabled: {
    paddingLeft: theme.spacing(5.5),
  },
  priorityItemBox: {
    width: theme.spacing(5.5),
    height: '100%',
    border: `1px solid ${theme.palette.grey[200]}`,
    borderRadius: theme.spacing(0.5),
    marginRight: theme.spacing(1),
  },
}));

const PrioritiesListOrderBox = ({ order }) => {
  const classes = useStyles();

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      className={classes.priorityItemBox}
    >
      {order}
    </Box>
  );
};

const PrioritiesListActions = ({ priority, handleOpenEditDialog, handleOpenDeleteDialog }) => {
  const { name } = priority;

  return (
    <Box display="flex" mr={0} ml="auto">
      <Tooltip title={`Edit ${name}`} aria-label={`Edit ${name}`}>
        <IconButton onClick={() => handleOpenEditDialog(priority)} size="large">
          <MdEdit style={{ fontSize: 20 }} />
        </IconButton>
      </Tooltip>
      <Tooltip title={`Delete ${name}`} aria-label={`Delete ${name}`}>
        <IconButton onClick={() => handleOpenDeleteDialog(priority)} size="large">
          <MdDelete style={{ fontSize: 20 }} />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

const PrioritiesList = ({
  priorities,
  handlePrioritiesReorder,
  setFieldValue,
  handleOpenEditDialog,
  handleOpenDeleteDialog,
}) => {
  const classes = useStyles();

  return (
    <List>
      <Box display="flex" alignItems="start" className={classes.priorityItemWrapper}>
        <PrioritiesListOrderBox order={0} />

        <ListItemButton
          selected
          disabled
          className={`${classes.priorityItem} ${classes.priorityItemDisabled}`}
        >
          <Typography>Drinks</Typography>
        </ListItemButton>
      </Box>
      <DragDropContext
        onDragEnd={(event) => handlePrioritiesReorder(event, priorities, setFieldValue)}
      >
        <Droppable droppableId="droppable" type="droppable_menu_groups">
          <>
            {priorities.map((priority, index) => {
              const { name, priorityId } = priority;

              return (
                <Draggable key={priorityId} draggableId={priorityId} index={index}>
                  {(provided, _snapshot) => (
                    <Box
                      display="flex"
                      alignItems="start"
                      className={classes.priorityItemWrapper}
                      {...provided.draggableProps}
                      ref={provided.innerRef}
                      style={provided.draggableProps.style}
                    >
                      <PrioritiesListOrderBox order={index + 1} />
                      <ListItemButton selected className={classes.priorityItem}>
                        <Box
                          display="flex"
                          justifyContent="center"
                          {...provided.dragHandleProps}
                          mr={0.5}
                        >
                          <DragIndicatorIcon style={{ fontSize: '25' }} />
                        </Box>
                        <Typography>{name}</Typography>
                        <PrioritiesListActions
                          priority={priority}
                          handleOpenEditDialog={handleOpenEditDialog}
                          handleOpenDeleteDialog={handleOpenDeleteDialog}
                        />
                      </ListItemButton>
                    </Box>
                  )}
                </Draggable>
              );
            })}
          </>
        </Droppable>
      </DragDropContext>
      <Box display="flex" alignItems="start" className={classes.priorityItemWrapper}>
        <PrioritiesListOrderBox order={priorities.length + 1} />
        <ListItemButton
          selected
          disabled
          className={`${classes.priorityItem} ${classes.priorityItemDisabled}`}
        >
          <Typography>Other</Typography>
        </ListItemButton>
      </Box>
    </List>
  );
};

export default PrioritiesList;
