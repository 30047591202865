import React, { useEffect, useState } from 'react';
import { sortBy, prop } from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import {
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  Typography,
  Box,
  Button,
} from '@mui/material';

import { makeStyles } from '@mui/styles';

import CustomDialog from '../../../components/CustomDialog';
import withVenue from '../../../hoc/withVenue';
import shouldLoad from '../../../shared/utils/shouldLoad';
import {
  getBrandsMenusListState,
  getBrandsMenusState,
} from '../../../store/brands/brandsMenus/selectors';
import {
  fetchBrandsMenusList,
  fetchBrandsMenus,
  clearBrandsMenus,
  clearBrandsMenusList,
} from '../../../store/brands/brandsMenus';
import { fetchBrands } from '../../../store/brands/brands';
import { getBrandsState } from '../../../store/brands/brands/selectors';

import Page from '../../../components/Page';
import BrandsMenuTableRow from './BrandsMenuTableRow';
import CreateBrandsMenu from './CreateBrandsMenu';
import { clearBrandsVenuesAssociations } from '../../../store/brands/brandsVenueAssociations';

const useStyles = makeStyles((theme) => ({
  table: {
    overflowY: 'auto',
    marginTop: theme.spacing(2),
    '& .MuiTableCell-root:last-child': {
      paddingRight: '16px',
    },
  },
  tableHead: {
    backgroundColor: theme.customPalette.midnight,
    '& .MuiTableCell-head': {
      color: theme.customPalette.white,
      fontWeight: 'bold',
      textTransform: 'uppercase',
    },
  },
}));

const tableHeaders = ['Brand', 'Menu Variations', 'Description', 'Venues', 'Last Updated'];

function BrandsMenuManagement() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const brandsMenusListState = useSelector(getBrandsMenusListState);
  const brandsState = useSelector(getBrandsState);
  const brandsMenusState = useSelector(getBrandsMenusState);
  const [isCreateBrandMenuDialogOpen, setCreateBrandMenuDialogOpen] = useState(false);

  const {
    data: brandsMenusList,
    error: brandsMenusListError,
    loading: brandsMenusListLoading,
  } = brandsMenusListState;

  const handleCloseDialog = (dialogName) => {
    if (dialogName === 'createBrandMenu') setCreateBrandMenuDialogOpen(false);
  };

  useEffect(() => {
    if (shouldLoad(brandsState)) dispatch(fetchBrands());
    if (shouldLoad(brandsMenusState)) dispatch(fetchBrandsMenus());
    if (shouldLoad(brandsMenusListState)) dispatch(fetchBrandsMenusList());
  }, [dispatch, brandsState, brandsMenusState, brandsMenusListState]);

  const sortedBrands = (brands) => sortBy(prop('brandName'), brands);

  return (
    <Page error={brandsMenusListError} loading={brandsMenusListLoading}>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h2">Brand Menu Management</Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setCreateBrandMenuDialogOpen(true)}
        >
          Create Menu
        </Button>
      </Box>
      {brandsMenusList && (
        <TableContainer component={Paper} className={classes.table}>
          <Table size="small">
            <TableHead className={classes.tableHead}>
              <TableRow key="table-headers">
                {tableHeaders.map((header, index) => (
                  <TableCell align={index === 0 ? 'left' : 'right'} key={header}>
                    <Typography variant="subtitle2">
                      <strong>{header}</strong>
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedBrands(brandsMenusList).map((brand) => (
                <BrandsMenuTableRow brand={brand} key={brand.brandId} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <CustomDialog
        isDialogOpen={isCreateBrandMenuDialogOpen}
        handleCloseDialog={() => {
          handleCloseDialog('createBrandMenu');
        }}
        title="Create Menu"
      >
        <CreateBrandsMenu handleCloseDialog={() => handleCloseDialog('createBrandMenu')} />
      </CustomDialog>
    </Page>
  );
}

export default withVenue(BrandsMenuManagement, null, [
  clearBrandsVenuesAssociations,
  clearBrandsMenus,
  clearBrandsMenusList,
]);
