import categories from './categories';
import ingredients from './ingredients';
import itemOptions from './itemOptions';
import items from './items';
import menus from './menus';
import tables from './tables';
import orders, { searchOrders } from './orders';
import venues from './venues';

export default {
  categories,
  ingredients,
  itemOptions,
  items,
  menus,
  orders,
  searchOrders,
  tables,
  venues,
};
