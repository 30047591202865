import React, { useCallback, useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Card, CardContent, Divider, Typography } from '@mui/material';
import { pick } from 'lodash';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import Page from '../../components/Page';
import PageHeader from '../../components/PageHeader';
import BackArrow from '../../components/BackArrow';
import OrderableTable from '../../components/OrderableTable';
import useEndpoint from '../../hooks/useEndpoint';
import { getCashupHistoryState, getCashupState } from '../../store/cashups/selectors';
import {
  cashupHistory,
  clearCashup,
  clearCashupHistory,
  fetchCashup,
  fetchCashupHistory,
} from '../../store/cashups';
import CustomDialog from '../../components/CustomDialog';
import TableButton from '../../components/TableButton';
import withVenue from '../../hoc/withVenue';
import shouldLoad from '../../shared/utils/shouldLoad';

const useStyles = makeStyles((theme) => ({
  pageTitle: {
    ...theme.customFonts.mediumBold,
  },
  card: {
    marginTop: '24px',
    maxWidth: '500px',
  },
  cashupTitleBox: {
    maxHeight: '123px',
  },
  titlesSplit: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'baseline',
  },
  cardHeading: {
    ...theme.customFonts.largeBold,
  },
  cardSubheading: {
    ...theme.customFonts.medium,
    color: theme.customPalette.greyDarker,
  },
  cardSmallHeading: {
    ...theme.customFonts.small,
    color: theme.customPalette.greyDarker,
  },
  gridTitles: {
    ...theme.customFonts.mediumBold,
  },
  gridCells: {
    ...theme.customFonts.medium,
    color: theme.customPalette.greyDarker,
    marginTop: '4px',
    marginBottom: '4px',
  },
  gridVarianceRow: {
    ...theme.customFonts.largeBold,
  },
  orderableTableTitle: {
    ...theme.customFonts.mediumBold,
    marginBottom: '12px',
    marginTop: '24px',
  },
}));

const formatNumber = (number) => {
  const formattedValue = Math.abs(number).toFixed(2);
  const sign = number < 0 ? '-' : '+';

  return `${sign} £${formattedValue}`;
};

const CashUpDetails = () => {
  const classes = useStyles();
  const [noteModal, setNoteModal] = useState(false);
  const [note, setNote] = useState('');
  const { cashupId } = useParams();
  const { data: cashupData } = useEndpoint(getCashupState, fetchCashup(cashupId));
  const dispatch = useDispatch();

  const {
    cashActual,
    cashExpected,
    variance,
    cashDrops,
    pettyCashTotal,
    floatId,
    staffName,
    sales,
    day,
    cashCounted,
    tillName,
    defaultFloatAmount,
    queryFrom,
  } = cashupData || {};

  const { data: cashupHistoryData } = useSelector(getCashupHistoryState);

  const handleCloseNoteModal = () => {
    setNoteModal(false);
  };

  const handleOpenNoteModal = (value) => {
    setNoteModal(true);
    setNote(value);
  };

  const newData = useCallback(() => {
    const pickedData = [];
    if (cashupHistory) {
      cashupHistoryData?.forEach((item) => {
        const itemToPick = { ...item, date: item.time, note: item?.note };
        pickedData.push(pick(itemToPick, ['date', 'time', 'user', 'type', 'note', 'amount']));
      });
    }
    return pickedData;
  }, [cashupHistoryData]);

  const valueFormatter = useCallback(({ value, valueName }) => {
    switch (valueName) {
      case 'date':
        return new Date(value).toLocaleDateString();
      case 'time':
        return new Date(value).toLocaleTimeString([], {
          hour: '2-digit',
          minute: '2-digit',
        });
      case 'amount':
        return `£${value}`;
      case 'note':
        if (value && value.length > 0) {
          return (
            <TableButton variant="text" onClick={() => handleOpenNoteModal(value)}>
              {value.slice(0, 10)}
            </TableButton>
          );
        }
        return 'No note';
      default:
        return value;
    }
  }, []);

  useEffect(() => {
    if (shouldLoad(getCashupHistoryState)) {
      dispatch(fetchCashupHistory(cashupId, queryFrom));
    }
  }, [cashupId, dispatch, queryFrom]);

  return (
    <>
      <PageHeader>
        <BackArrow to="/tills" text="Tills" />
        <Typography className={classes.pageTitle}>Cash up details</Typography>
      </PageHeader>
      <Page>
        <Card className={classes.card}>
          <CardContent>
            <Box className={classes.cashupTitleBox}>
              <Box className={classes.titlesSplit}>
                <Typography className={classes.cardHeading}>{staffName}</Typography>
                <Typography className={classes.cardSubheading}>{tillName}</Typography>
              </Box>
              <Typography className={classes.cardSubheading}>{day}</Typography>
              <Typography className={classes.cardSmallHeading}>#{floatId}</Typography>
            </Box>
            <Divider />
            <Box className={classes.titlesSplit} style={{ marginTop: '24px' }}>
              <Typography className={classes.gridTitles}>Transaction Type</Typography>
              <Typography className={classes.gridTitles}>Total</Typography>
            </Box>
            <Box className={classes.titlesSplit}>
              <Typography className={classes.gridCells}>Cash payments</Typography>
              <Typography className={classes.gridCells}>{formatNumber(sales)}</Typography>
            </Box>
            <Box className={classes.titlesSplit}>
              <Typography className={classes.gridCells}>Petty cash</Typography>
              <Typography className={classes.gridCells}>{formatNumber(pettyCashTotal)}</Typography>
            </Box>
            {cashDrops && (
              <Box className={classes.titlesSplit}>
                <Typography className={classes.gridCells}>Cash drops</Typography>
                <Typography className={classes.gridCells}>{formatNumber(cashDrops)}</Typography>
              </Box>
            )}
            <Box className={classes.titlesSplit} style={{ marginTop: '24px' }}>
              <Typography className={classes.gridCells}>Cash counted</Typography>
              <Typography className={classes.gridCells}>{formatNumber(cashCounted)}</Typography>
            </Box>
            <Box className={classes.titlesSplit}>
              <Typography className={classes.gridCells}>Float</Typography>
              <Typography className={classes.gridCells}>
                {formatNumber(defaultFloatAmount)}
              </Typography>
            </Box>
            <Box className={classes.titlesSplit}>
              <Typography className={classes.gridCells}>Cash Actual</Typography>
              <Typography className={classes.gridCells}>{formatNumber(cashActual)}</Typography>
            </Box>
            <Box className={classes.titlesSplit}>
              <Typography className={classes.gridCells}>Cash Expected</Typography>
              <Typography className={classes.gridCells}>{formatNumber(cashExpected)}</Typography>
            </Box>
            <Box className={classes.titlesSplit}>
              <Typography className={classes.gridVarianceRow}>Variance</Typography>
              <Typography className={classes.gridVarianceRow}>{formatNumber(variance)}</Typography>
            </Box>
            {/* <Box style={{ marginTop: '24px' }}>
              <Typography className={classes.cardSmallHeading}>Note:</Typography>
              <Typography className={classes.cardSubheading}>
                Some kind of note can go here if added on the EPOS
              </Typography>
            </Box> */}
          </CardContent>
        </Card>
        <Typography className={classes.orderableTableTitle}>History</Typography>
        <Card style={{ marginTop: '24px' }}>
          <OrderableTable
            tableData={[...newData()]}
            titles={['DATE', 'TIME', 'USER', 'TRANSACTION', 'NOTE', 'AMOUNT']}
            keys={['']}
            excludeFields={['']}
            disableColumnTitles={['']}
            valueFormatter={valueFormatter}
          />
          <CustomDialog
            isDialogOpen={noteModal}
            title="Note"
            handleCloseDialog={handleCloseNoteModal}
          >
            <CardContent>
              <p>{note}</p>
            </CardContent>
          </CustomDialog>
        </Card>
      </Page>
    </>
  );
};

export default withVenue(CashUpDetails, '/tills', [clearCashup, clearCashupHistory]);
