import { getBare, patch, del, putSimple, patchBare, deleteBare } from './httpRequest';

export const fetchImageGallery = async () => {
  const response = await getBare('items/images');
  return response.data;
};

export const deleteImageGallery = async ({ itemId }) => {
  const response = await del(`/items/${itemId}/image`);
  return response.data;
};

export const deleteBrandImageGalleryApi = async ({ itemId }) => {
  const response = await deleteBare(`brands/items/${itemId}/image`);
  return response.data;
};

export const deleteBrandCategoryImageApi = async ({ itemId, menuId }) => {
  const response = await deleteBare(`brands/menus/${menuId}/groups/${itemId}/image`);
  return response.data;
};

export const deleteBrandLogoApi = async ({ brandId }) => {
  const response = await deleteBare(`brands/${brandId}/logo`);
  return response.data;
};

export const deleteBrandCarouselImageApi = async ({ brandId, imageName }) => {
  const response = await deleteBare(`brands/${brandId}/carousel/${imageName}`);
  return response.data;
};

export const associateGalleryImage = async ({ itemId, categoryName, productName, imageName }) => {
  const response = await patch(`/items/${itemId}/image`, {
    data: { categoryName, productName, imageName },
  });
  return response.data;
};

export const associateBrandsGalleryImage = async ({
  itemId,
  categoryName,
  productName,
  imageName,
}) => {
  const response = await patchBare(`brands/items/${itemId}/image`, {
    data: { categoryName, productName, imageName },
  });
  return response.data;
};

export const uploadPhoto = async (imgUploadUrl, image) => {
  const response = await putSimple(imgUploadUrl, image, {
    headers: {
      'Content-Type': 'image/jpeg',
    },
  });
  return response.data;
};
