import { createSlice } from '@reduxjs/toolkit';
import {
  asyncInitialState as initialState,
  createAsyncAction,
  createAsyncReducerHandlers,
} from '../utils/async';
import {
  fetchCashup as fetchCashupApi,
  fetchCashupHistory as fetchCashupHistoryApi,
} from '../../api/cashups';

export const fetchCashup = createAsyncAction('fetch-cashup', fetchCashupApi);
export const fetchCashupHistory = createAsyncAction('create-cashup-history', fetchCashupHistoryApi);

const cashupSlice = createSlice({
  name: 'cashup',
  initialState,
  reducers: {
    clearCashup(state) {
      delete state.data;
    },
  },
  extraReducers: {
    ...createAsyncReducerHandlers(fetchCashup),
  },
});

const cashupHistorySlice = createSlice({
  name: 'cashupHistory',
  initialState,
  reducers: {
    clearCashupHistory(state) {
      delete state.data;
    },
  },
  extraReducers: {
    ...createAsyncReducerHandlers(fetchCashupHistory),
  },
});
export const { clearCashupHistory } = cashupHistorySlice.actions;
export const { clearCashup } = cashupSlice.actions;

export const { reducer: cashup } = cashupSlice;
export const { reducer: cashupHistory } = cashupHistorySlice;
