import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { getDeviceState } from '../../store/devices/selectors';
import { clearDevice, fetchDevice } from '../../store/devices';
import EditDeviceForm from '../../components/EditDeviceForm';
import Page from '../../components/Page';
import withVenue from '../../hoc/withVenue';
import shouldLoad from '../../shared/utils/shouldLoad';
import BackArrow from '../../components/BackArrow';

const useStyles = makeStyles(() => ({
  heading: {
    margin: '15px 0',
  },
}));

const EditDevice = ({ redirect }) => {
  const { deviceId } = useParams();
  const dispatch = useDispatch();
  const classes = useStyles();
  const deviceState = useSelector(getDeviceState);
  const { data, loading, error } = deviceState;
  const [isDirty, setIsDirty] = useState(false);

  useEffect(() => {
    const refreshInterval = setInterval(() => {
      if (!isDirty) dispatch(fetchDevice({ deviceId }));
    }, 60000);
    return () => {
      clearInterval(refreshInterval);
    };
  }, [dispatch, deviceId, isDirty]);

  useEffect(() => {
    if (shouldLoad(deviceState)) dispatch(fetchDevice({ deviceId }));
  }, [dispatch, deviceId, deviceState]);

  return (
    <Page loading={loading} error={error}>
      {data && (
        <>
          <header>
            <BackArrow redirect={redirect} text="Devices" />
            <Typography className={classes.heading} variant="h2" component="h1">
              Edit Device
            </Typography>
          </header>
          <EditDeviceForm deviceData={data} setIsDirty={setIsDirty} />
        </>
      )}
    </Page>
  );
};

export default withVenue(EditDevice, '/devices', clearDevice);
