/* eslint-disable no-param-reassign */
import { createAction } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/browser';
import { serializeError } from 'serialize-error';

export const REQUEST_SUPERCEDED = 'REQUEST_SUPERCEDED';

export const asyncInitialState = {
  loading: false,
  data: null,
  request: null,
  error: null,
};

export const createAsyncAction = (name, thunk) => {
  const pending = createAction(`${name}:pending`);
  const fulfilled = createAction(`${name}:fulfilled`);
  const rejected = createAction(`${name}:rejected`);

  const actionCreator = (...args) => async (dispatch, getState) => {
    try {
      dispatch(pending(args, ...args));
      const data = await thunk(...args, dispatch, getState);
      dispatch(fulfilled(data, ...args));

      return data;
    } catch (err) {
      if (err.message === REQUEST_SUPERCEDED) {
        dispatch(pending(args, ...args));
        return null;
      }
      Sentry.captureException(err);
      dispatch(rejected(serializeError(err), ...args));
      throw err;
    }
  };

  actionCreator.pending = pending;
  actionCreator.fulfilled = fulfilled;
  actionCreator.rejected = rejected;

  return actionCreator;
};

export const fetchPageAsyncReducerHandler = (actionCreator) => ({
  [actionCreator.pending]: (state, action) => {
    state.loading = true;
    state.request = action.payload;
    state.error = null;
  },
  [actionCreator.fulfilled]: (state, action) => {
    state.data.docs = [...state.data.docs, ...action.payload.docs];
    state.loading = false;
  },
  [actionCreator.rejected]: (state, action) => {
    state.loading = false;
    state.error = action.payload;
    state.data = null;
  },
});

export const createAsyncReducerHandlers = (actionCreator) => ({
  [actionCreator.pending]: (state, action) => {
    state.loading = true;
    state.request = action.payload;
    state.error = null;
  },
  [actionCreator.fulfilled]: (state, action) => {
    state.loading = false;
    state.data = action.payload;
  },
  [actionCreator.rejected]: (state, action) => {
    state.loading = false;
    state.error = action.payload;
    state.data = null;
  },
});
