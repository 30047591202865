import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Fuse from 'fuse.js';
import { useHistory } from 'react-router-dom';

import { makeStyles } from '@mui/styles';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import { ListItemButton, TextField } from '@mui/material';

import { without } from 'ramda';
import { union } from 'lodash';
import { getModifiers } from '../../../store/brands/brandsModifiers/selectors';
import {
  getBrandsAttributeValues,
  getBrandsModifiersForBrand,
} from '../../../store/brands/brandsMenuItems/selectors';

const useStyles = makeStyles(() => ({
  card: { marginBottom: '20px' },
  cardContent: {
    height: '400px',
    overflow: 'auto',
  },
  cardActions: {
    justifyContent: 'flex-end',
  },
  listItemText: {
    paddingTop: '6px',
  },
  searchInput: {
    marginBottom: '10px',
  },
}));

const AddModifiers = ({ onSave }) => {
  const classes = useStyles();
  const picked = useSelector((state) => getBrandsAttributeValues(state, 'modifiers'));

  const modifiers = useSelector(getModifiers);
  const available = useSelector(getBrandsModifiersForBrand);
  const availableWithoutPicked = picked ? without(picked, available) : available;
  const { goBack } = useHistory();
  const [checked, setChecked] = useState(picked);
  const [availableModifiers, setAvailableModifiers] = useState(availableWithoutPicked);
  const [searchError, setSearchError] = useState('');

  const handleToggle = (value) => () => {
    const modifierId = value;
    const currentIndex = checked.indexOf(modifierId);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(modifierId);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  const handleSave = () => {
    const returnval = union(picked, checked);
    onSave(returnval);
  };

  const handleSearch = (e) => {
    if (e.target.value.length >= 3) {
      setSearchError('');
      const searchValue = e.target.value;
      const fuse = new Fuse(modifiers, {
        keys: ['label'],
        threshold: 0.3,
      });
      const searchResult = fuse.search(searchValue);
      const searchResultIDs = searchResult.map((mod) => mod.item.modifierId);
      const availableSearchWithoutPicked = without(picked, searchResultIDs);
      setAvailableModifiers(availableSearchWithoutPicked);
    } else {
      setAvailableModifiers(availableWithoutPicked);
      setSearchError('Please enter at least 3 characters');
    }
  };

  return (
    <>
      <TextField
        className={classes.searchInput}
        label="Search Modifiers"
        variant="outlined"
        size="small"
        helperText={searchError}
        onChange={handleSearch}
      />
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <List dense role="list">
            {availableModifiers.map((mid) => (
              <ListItemButton key={mid} onClick={handleToggle(mid)}>
                <ListItemIcon>
                  <Checkbox checked={checked.includes(mid)} tabIndex={-1} disableRipple />
                  <ListItemText
                    className={classes.listItemText}
                    primary={modifiers.find((mod) => mod.modifierId === mid).modifierName}
                  />
                </ListItemIcon>
              </ListItemButton>
            ))}
            <ListItem />
          </List>
        </CardContent>
        <CardActions className={classes.cardActions}>
          <Button onClick={goBack}>Cancel</Button>
          <Button type="submit" variant="contained" color="primary" onClick={handleSave}>
            Add
          </Button>
        </CardActions>
      </Card>
    </>
  );
};

AddModifiers.propTypes = {
  onSave: PropTypes.func.isRequired,
};

export default AddModifiers;
