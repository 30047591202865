import { Button } from '@mui/material';
import { withStyles } from '@mui/styles';

export default withStyles(
  () => ({
    root: {
      background: '#BC3034',
      '&:hover': {
        color: '#BC3034',
        background: 'white',
      },
    },
    outlined: {
      background: 'white',
      color: '#BC3034',
      borderColor: '#BC3034',
      '&:hover': {
        color: '#fff',
        background: '#BC3034',
        borderColor: '#BC3034',
      },
    },
  }),
  { withTheme: true },
)(Button);
