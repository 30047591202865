import React from 'react';
import { Box, FormControlLabel, List, ListItem, Typography } from '@mui/material';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { DragDropContext, Draggable } from 'react-beautiful-dnd';
import { Field } from 'formik';
import { Switch } from 'formik-mui';

import Droppable from '../DragAndDrop/Droppable';

import { customCampaignTypes } from '../PromotionsListForm/campaignTypes';
import TableLink from '../TableLink';
import ButtonLink from '../ButtonLink';

const PromotionsList = ({
  campaigns,
  handleCampaignsReorder,
  setFieldValue,
  handleOpenOfferDialog,
}) => (
  <List>
    <DragDropContext onDragEnd={(event) => handleCampaignsReorder(event, setFieldValue, campaigns)}>
      <Droppable droppableId="droppable" type="droppable_menu_groups">
        <>
          {campaigns.map((campaign, index) => {
            const { campaignName, campaignId, active, campaignType } = campaign;
            const isBrandupCampaign = !Object.values(customCampaignTypes)
              .map(({ value }) => value)
              .includes(campaignType);

            return (
              <Draggable key={campaignId} draggableId={campaignId} index={index}>
                {(provided, _snapshot) => (
                  <Box
                    display="flex"
                    alignItems="start"
                    mb={1}
                    {...provided.draggableProps}
                    ref={provided.innerRef}
                    style={provided.draggableProps.style}
                  >
                    <ListItem disableGutters>
                      <Box display="flex" justifyContent="space-between" width="100%">
                        <Box
                          display="flex"
                          alignItems="center"
                          {...provided.dragHandleProps}
                          mr={0.5}
                        >
                          <DragIndicatorIcon style={{ fontSize: '25px', marginRight: '16px' }} />
                          {!isBrandupCampaign ? (
                            <TableLink to={`/offers/${campaignId}`}>{campaignName}</TableLink>
                          ) : (
                            <Box>
                              <ButtonLink handleOnClick={() => handleOpenOfferDialog(campaign)}>
                                {campaignName}
                              </ButtonLink>
                              <Typography style={{ fontSize: '16px', color: '#647F99' }}>
                                Brandup Offer
                              </Typography>
                            </Box>
                          )}
                        </Box>
                        {!isBrandupCampaign && (
                          <FormControlLabel
                            control={
                              <Field
                                component={Switch}
                                color="primary"
                                type="checkbox"
                                name={`campaigns[${index}].active`}
                                checked={active}
                                value={active}
                                onChange={(_event, newValue) => {
                                  setFieldValue(`campaigns[${index}].active`, newValue);
                                }}
                              />
                            }
                          />
                        )}
                      </Box>
                    </ListItem>
                  </Box>
                )}
              </Draggable>
            );
          })}
        </>
      </Droppable>
    </DragDropContext>
  </List>
);

export default PromotionsList;
