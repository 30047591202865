import { pick } from 'ramda';
import { get, post } from './httpRequest';

export const fetchPayments = async () => {
  const response = await get(`/payments`);
  return response.data;
};

export const sendPayment = async (values) => {
  const trimmedValues = {
    ...pick(['amount', 'reference', 'description'], values),
  };

  await post(`/payments`, {
    data: trimmedValues,
  });
};
