import { createSlice } from '@reduxjs/toolkit';
import { nanoid } from 'nanoid';
import { move } from 'ramda';

import {
  createAsyncAction,
  asyncInitialState as initialState,
  createAsyncReducerHandlers,
} from '../../../utils/async';
import { denormalizeMenuItems, normalizeMenuItems } from '../../../utils/normalize';
import sanitiseCreateMenuGroupsPayload from '../../../venueMenuItems/sanitiseCreateMenuGroupsPayload';
import sanitiseUpdateMenuGroupsPayload from '../../../venueMenuItems/sanitiseUpdateMenuGroupsPayload';
import { fetchBrandsMenuBuilderMenuApi } from '../../../../api/brands/builderMenu/builderMenus';
import { updateBuilderMenuItemsApi } from '../../../../api/brands/builderMenu/builderMenuItems';

export const fetchBuilderMenuItems = createAsyncAction(
  'fetch-brands-menu-builder-menu-items',
  async (menuId) => {
    const response = await fetchBrandsMenuBuilderMenuApi(menuId);
    return normalizeMenuItems(response);
  },
);

export const updateBuilderMenuItems = createAsyncAction(
  'update-builder-menu-items',
  async (_, getState) => {
    const builderMenuItems = getState().builderMenuItems.data;

    const response = await updateBuilderMenuItemsApi(
      builderMenuItems.name,
      denormalizeMenuItems(builderMenuItems),
    );
    return normalizeMenuItems(response);
  },
);

const builderMenuItemsSlice = createSlice({
  name: 'builder-menu-items',
  initialState,
  reducers: {
    clearBuilderMenuItems(state) {
      delete state.data;
    },
    setBuilderMenuItems: (state, { payload }) => {
      state.data = normalizeMenuItems(payload);
    },
    createBuilderMenuGroup(state, { payload }) {
      if (state.data) {
        const groupId = nanoid();
        const sanitisedPayload = sanitiseCreateMenuGroupsPayload(payload);

        state.data.menuGroups[groupId] = {
          ...sanitisedPayload,
          groupItems: [],
        };
        state.data.menuGroupsList.push(groupId);
      }
    },
    updateBuilderMenuGroup(state, { payload }) {
      if (state.data) {
        const currentMenuGroup = state.data.menuGroups[payload.groupId];
        const sanitisedPayload = sanitiseUpdateMenuGroupsPayload(payload, currentMenuGroup);

        state.data.menuGroups[payload.groupId] = sanitisedPayload;
      }
    },
    deleteBuilderMenuGroup(state, { payload }) {
      if (state.data) {
        const delIndex = state.data.menuGroupsList.findIndex((elem) => elem === payload.groupId);
        state.data.menuGroupsList.splice(delIndex, 1);
      }
    },
    moveBuilderMenuGroup(state, { payload }) {
      if (state.data) {
        state.data.menuGroupsList = move(payload.from, payload.to, state.data.menuGroupsList);
      }
    },
    createBuilderMenuGroupItem(state, { payload }) {
      if (state.data) {
        state.data.menuGroups[payload.groupId].groupItems.push(payload.itemId);
      }
    },
    deleteBuilderMenuGroupItem(state, { payload }) {
      if (state.data) {
        state.data.menuGroups[payload.groupId].groupItems.splice(payload.itemIndex, 1);
      }
    },
    updateBuilderMenuGroupItems(state, { payload }) {
      if (state.data) {
        state.data.menuGroups[payload.groupId].groupItems = payload.items;
      }
    },
    moveBuilderMenuGroupItem(state, { payload }) {
      if (state.data) {
        state.data.menuGroups[payload.groupId].groupItems = move(
          payload.from,
          payload.to,
          state.data.menuGroups[payload.groupId].groupItems,
        );
      }
    },
    updateBuilderMenuSchedule(state, { payload }) {
      if (state.data) {
        state.data.schedules[payload.groupId] = payload.days;
      }
    },
  },
  extraReducers: {
    ...createAsyncReducerHandlers(fetchBuilderMenuItems),
    ...createAsyncReducerHandlers(updateBuilderMenuItems),
  },
});

export const {
  clearBuilderMenuItems,
  setBuilderMenuItems,
  createBuilderMenuGroup,
  updateBuilderMenuGroup,
  deleteBuilderMenuGroup,
  moveBuilderMenuGroup,
  createBuilderMenuGroupItem,
  deleteBuilderMenuGroupItem,
  updateBuilderMenuGroupItems,
  moveBuilderMenuGroupItem,
  updateBuilderMenuSchedule,
} = builderMenuItemsSlice.actions;

export const { reducer: builderMenuItems } = builderMenuItemsSlice;
