import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Fuse from 'fuse.js';
import { filterSearchTerm } from '../shared/utils/filterData';

/**
 * Custom hook to handle search functionality with optional filtering based on stored session and local storage filters.
 * @param {Array} data - The original array of data objects.
 * @param {Array} searchKeys - Array of keys to be searched within each data object.
 * @param {number} threshold - The threshold value for the Fuse.js search algorithm.
 * @param {Array} valueData - Optional array of data objects to be used for filtering.
 * @returns {Object} - Object containing search results, search error, filtered items, and functions to handle search
 * and set filtered items.
 */

const useSearch = (data, searchKeys, threshold, valueData, fetchDataStore = null) => {
  const dispatch = useDispatch();
  const [filteredItems, setFilteredItems] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchError, setSearchError] = useState('');

  const resetSearchState = (error = '', term = '', results = data) => {
    setSearchError(error);
    setSearchTerm(term);
    setSearchResults(results);
  };

  const handleSearch = (e) => {
    if (e.target.value.length >= 3) {
      setSearchError('');
      setSearchTerm(e.target.value);
      const searchData = valueData || data;
      const filtered = filterSearchTerm(e.target.value, searchData, Fuse, searchKeys, threshold);
      setSearchResults(filtered);
      setFilteredItems(filtered);
    } else if (e.target.value.length === 2 && fetchDataStore) {
      // This is if a table has an update applied eg making an item available
      // The data needs to be fetched again to ensure it is up to date
      dispatch(fetchDataStore());
      resetSearchState('Please enter at least 3 characters');
    } else if (e.target.value.length > 0) {
      resetSearchState('Please enter at least 3 characters');
    } else {
      resetSearchState();
    }
  };

  useEffect(() => {
    setSearchResults(filterSearchTerm(searchTerm, data, Fuse, searchKeys, threshold));
  }, [data, searchKeys, searchTerm, threshold]);

  return {
    searchResults,
    searchError,
    handleSearch,
    filteredItems,
    setFilteredItems,
  };
};

export default useSearch;
