import React, { useContext } from 'react';
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import StepQuestion from '../StepQuestion';
import SignupContext from '../SignupContext';

const useStyles = makeStyles({
  bold: {
    fontWeight: 'bold',
  },

  imgclass: {
    height: 'auto',
    width: '2rem',
    display: 'block',
    marginBottom: '2rem',
  },
});

const StepComplete = () => {
  const classes = useStyles();
  const { formValues } = useContext(SignupContext);
  const { email } = formValues;

  return (
    <>
      <img alt="logo" className={classes.imgclass} src="/img/signup/justlogo.svg" />
      <StepQuestion
        question="Check your email"
        description="Please activate your account with the temporary password we just sent you."
      />
      <Typography variant="h2" component="h1" gutterBottom>
        {email}
      </Typography>
      <Box mt={4}>
        <Typography variant="body2" color="primary" className={classes.bold}>
          Didn&apos;t get an email?
        </Typography>
        <Typography variant="body2" color="primary">
          If you don’t see an email from us within a few minutes, a few things could have happened:
          <ul>
            <li>The email is in your spam folder.</li>
            <li>The email address you entered had a mistake or typo.</li>
            <li>You accidentally gave us another email address.</li>
            <li>
              We can’t deliver the email to this address (usually because of corporate firewalls or
              filtering).
            </li>
          </ul>
        </Typography>
      </Box>
    </>
  );
};

export default StepComplete;
