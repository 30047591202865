import { createSlice } from '@reduxjs/toolkit';
import {
  asyncInitialState as initialState,
  createAsyncAction,
  createAsyncReducerHandlers,
} from '../utils/async';
import {
  fetchCampaigns as fetchCampaignsApi,
  updateCampaigns as updateCampaignsApi,
} from '../../api/campaigns';

export const fetchCampaigns = createAsyncAction('fetch-campaigns', fetchCampaignsApi);
export const updateCampaigns = createAsyncAction('update-campaigns', updateCampaignsApi);

const campaignsSlice = createSlice({
  name: 'campaigns',
  initialState,
  reducers: {
    clearCampaigns(state) {
      delete state.data;
    },
  },
  extraReducers: {
    ...createAsyncReducerHandlers(fetchCampaigns),
    ...createAsyncReducerHandlers(updateCampaigns),
  },
});

export const { reducer: campaigns } = campaignsSlice;
export const { clearCampaigns } = campaignsSlice.actions;
