const customCampaignTypes = {
  CUSTOM: {
    value: 'CUSTOM',
    text: 'No link',
  },
  CUSTOM_ITEM: {
    value: 'CUSTOM_ITEM',
    text: 'An item',
  },
  CUSTOM_EXTERNAL: {
    value: 'CUSTOM_EXTERNAL',
    text: 'A website URL',
  },
};

const campaignTypes = {
  'BANNER-ITEM-PROMO-SYSTEM': {
    value: 'BANNER-ITEM-PROMO-SYSTEM',
    text: null,
  },
};

export { campaignTypes, customCampaignTypes };
