import { createSlice } from '@reduxjs/toolkit';
import {
  asyncInitialState as initialState,
  createAsyncAction,
  createAsyncReducerHandlers,
} from '../utils/async';
import fetchReportsSalesByCategoryApi from '../../api/reports/salesByCategory';

export const fetchReportSalesByCategory = createAsyncAction(
  'fetch-sales-areas',
  fetchReportsSalesByCategoryApi,
);

const reportSalesByCategorySlice = createSlice({
  name: 'sales-areas',
  initialState,
  reducers: {
    clearReportSalesByCategory(state) {
      delete state.data;
    },
  },
  extraReducers: createAsyncReducerHandlers(fetchReportSalesByCategory),
});

export const { clearReportSalesByCategory } = reportSalesByCategorySlice.actions;

export const { reducer: reportSalesByCategory } = reportSalesByCategorySlice;
