import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';

import Page from '../../components/Page';

import { getImagesReviewState } from '../../store/imagesReview/selectors';
import { fetchImagesReview } from '../../store/imagesReview';
import ImagesTable from '../../components/ImagesTable';

const useStyles = makeStyles(() => ({
  heading: {
    marginBottom: '20px',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  title: {
    marginRight: '20px',
    width: '50%',
    flexGrow: 1,
  },
}));

const Images = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { loading, data, error } = useSelector(getImagesReviewState);
  useEffect(() => {
    dispatch(fetchImagesReview());
  }, [dispatch]);

  return (
    <Page loading={loading} error={error}>
      {data && (
        <>
          <header className={classes.heading}>
            <Typography noWrap className={classes.title} variant="h2" component="h1">
              Images
            </Typography>
          </header>
          {data && <ImagesTable />}
        </>
      )}
    </Page>
  );
};

export default Images;
