import { sortOrderEnums } from '../shared/utils/sortOrder';
import { get, patch } from './httpRequest';

const fetchCardPayouts = async ({ from = 0, size = 50, gte, lte, order = sortOrderEnums.DESC }) => {
  const dateFrom = gte || new Date('12 April 2021').toISOString();
  const dateTo = lte || new Date().toISOString(); // Start of Payouts integration
  const params = `gte=${dateFrom}&lte=${dateTo}&order=${order}&from=${from}&size=${size}`;
  const response = await get(`/combinedPayouts?${params}`);

  return response.data;
};

const retryPayout = async ({ day, payoutId, isStripeConnect }) =>
  isStripeConnect ? get(`/payouts/${payoutId}/retry`) : patch(`/payouts/${day}`);

const fetchBankHolidayDates = async (state = 'England') => {
  const endpoint = 'https://www.gov.uk/bank-holidays.json';
  const response = await fetch(endpoint);
  const data = await response.json();
  const stateJSONName = {
    England: 'england-and-wales',
    'Northern Ireland': 'northern-ireland',
    Scotland: 'scotland',
    'United Kingdom': 'england-and-wales',
    Wales: 'england-and-wales',
  };

  const regionKey = stateJSONName[state] || 'england-and-wales';

  return data[regionKey]?.events.map(({ date }) => date) || [];
};

export { fetchCardPayouts, retryPayout, fetchBankHolidayDates };
