import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { makeStyles } from '@mui/styles';
import { Button, Card, CardActions, CardContent, Typography } from '@mui/material';

import { acceptLiveOrders, clearLiveOrders, fetchLiveOrders } from '../../store/orders';
import { getOrdersLiveState } from '../../store/orders/selectors';

import Page from '../../components/Page';
import OrdersLiveTable from '../../components/OrdersLiveTable';
import { enrichStation } from '../../shared/utils/openingTimesUtils';
import withVenue from '../../hoc/withVenue';
import useRoles from '../../hooks/useRoles';
import { getErrorMessage } from '../../shared/utils/errors';
import { useNotifications } from '../../shared/contexts/Notifications/useNotifications';
import CustomDialog from '../../components/CustomDialog';
import PageHeader from '../../components/PageHeader';

const useStyles = makeStyles((theme) => ({
  heading: {
    marginBottom: '20px',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  title: {
    marginRight: '20px',
    width: '50%',
    flexGrow: 1,
  },
  form: {
    width: '100%',
    flexGrow: 2,
  },
  formElement: {
    paddingRight: theme.spacing(2),
  },
  dialog: {
    display: 'flex',
    justifyContent: 'flex-col',
  },
  closeButton: {
    position: 'absolute',
    right: '10px',
    top: '10px',
  },
}));

const OrdersLive = ({ venue }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { data, error } = useSelector(getOrdersLiveState);
  const { showErrorNotification, showSuccessNotification } = useNotifications();
  const { isAdmin } = useRoles();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const enrichedStation = enrichStation(venue);

  const handleOpenDialog = useCallback(() => {
    setIsDialogOpen(true);
  }, []);

  const handleCloseDialog = useCallback(() => {
    setIsDialogOpen(false);
  }, []);

  const handleAcceptOrders = async () => {
    try {
      await dispatch(acceptLiveOrders());
      showSuccessNotification('Orders accepted successfully');
    } catch (e) {
      showErrorNotification(getErrorMessage(e));
    }
  };

  useEffect(() => {
    dispatch(fetchLiveOrders());
    const timer = setInterval(() => dispatch(fetchLiveOrders()), 5000);
    return () => {
      clearInterval(timer);
    };
  }, [dispatch]);

  return (
    <>
      <PageHeader fullWidth>
        <div className={classes.heading}>
          <Typography noWrap className={classes.title} variant="h2" component="h1">
            Live Orders
          </Typography>
          {isAdmin() && data && (
            <>
              <Button
                variant="contained"
                color="primary"
                disabled={data.length === 0}
                onClick={handleOpenDialog}
              >
                Accept all
              </Button>
              <CustomDialog
                title="Accept all live orders?"
                isDialogOpen={isDialogOpen}
                handleCloseDialog={handleCloseDialog}
              >
                <Card className={classes.card}>
                  <CardContent>
                    Accepting all orders will send an order confirmation email to customers (if
                    their contact details were provided). This will not result in any tickets being
                    printed.
                  </CardContent>
                  <CardActions className={classes.cardActions}>
                    <Button onClick={handleCloseDialog}>Cancel</Button>
                    <Button
                      onClick={() => {
                        handleAcceptOrders();
                        handleCloseDialog();
                      }}
                      variant="contained"
                      color="primary"
                    >
                      Accept all live orders
                    </Button>
                  </CardActions>
                </Card>
              </CustomDialog>
            </>
          )}
        </div>
      </PageHeader>
      <Page error={error} fullWidth>
        {data && <OrdersLiveTable isOpen={enrichedStation.isOpen} orders={data} />}
      </Page>
    </>
  );
};

export default withVenue(OrdersLive, null, clearLiveOrders);
