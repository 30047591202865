import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

const PageLoader = ({ copy, small }) => (
  <div
    style={{
      display: 'flex',
      height: small ? '10vh' : '80vh',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      whiteSpace: 'pre-line',
    }}
  >
    {copy && <p style={{ textAlign: 'center' }}>{copy}</p>}
    <CircularProgress style={{ color: small && '#E1E9F2' }} />
  </div>
);

export default PageLoader;
