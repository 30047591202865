import { normalize, denormalize, schema } from 'normalizr';
import { map, mapObjIndexed, omit } from 'ramda';

const menuGroupSchema = new schema.Entity('menuGroups', {}, { idAttribute: 'groupId' });

export const normalizeMenuItems = ({ menuName, brandMenuId, items = [], importUploadUrl }) => {
  const { entities, result } = normalize(items, [menuGroupSchema]);
  const schedules = entities.menuGroups && map((section) => section.schedule, entities.menuGroups);

  const menuGroups =
    entities.menuGroups &&
    map((section) => omit(['schedule', 'groupId'], section), entities.menuGroups);

  return {
    name: menuName || brandMenuId,
    menuGroups: menuGroups || {},
    menuGroupsList: result,
    schedules: schedules || [],
    importUploadUrl: importUploadUrl || '',
  };
};

export const denormalizeMenuItems = ({ menuGroupsList, menuGroups, schedules }) => {
  const reunitedMenuGroups = mapObjIndexed(
    (menuGroup, key) => ({
      ...menuGroup,
      groupId: key,
      schedule: schedules[key],
    }),
    menuGroups,
  );

  return denormalize(menuGroupsList, [menuGroupSchema], {
    menuGroups: reunitedMenuGroups,
  });
};
