import axios from 'axios';

const fetchGoogleFontsData = async () => {
  const response = await axios.get(
    // eslint-disable-next-line max-len
    `https://www.googleapis.com/webfonts/v1/webfonts?key=${process.env.REACT_APP_GOOGLE_FONTS_API_KEY}&sort=popularity&capability=WOFF2`,
  );

  const filteredItems = response.data.items.filter((font) => !font.family.startsWith('Material'));

  return filteredItems;
};

export default fetchGoogleFontsData;
