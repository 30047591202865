import React from 'react';
import PropTypes from 'prop-types';

import { Card, CardActions, CardContent, Button } from '@mui/material';
import CustomDialog from '../CustomDialog';

const ChangeVenueNameDialog = ({
  handleCloseDialog,
  handleDialogOpen,
  submitDialog,
  initialValues,
  newValues,
}) => (
  <CustomDialog
    title="Are you sure?"
    isDialogOpen={handleDialogOpen}
    handleCloseDialog={handleCloseDialog}
  >
    <Card>
      <CardContent>
        Did you mean to change the name? <br />
        <br />
        <b>{initialValues?.name}</b>
        <br />
        <br /> is going to be renamed to
        <br />
        <br />
        <b>{newValues?.name}</b>
        <br />
      </CardContent>
      <CardActions>
        <Button onClick={handleCloseDialog}>Cancel</Button>
        <Button onClick={() => submitDialog(newValues)} variant="contained" color="primary">
          Change Name
        </Button>
      </CardActions>
    </Card>
  </CustomDialog>
);

ChangeVenueNameDialog.propTypes = {
  handleCloseDialog: PropTypes.func.isRequired,
  handleDialogOpen: PropTypes.bool.isRequired,
  submitDialog: PropTypes.func.isRequired,
};

export default ChangeVenueNameDialog;
