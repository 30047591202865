import moment from 'moment-timezone';
import { includes, has } from 'ramda';
import { convertTimeToUtc } from '../../shared/utils/dateTime';

const defaultServingTimes = {
  start: convertTimeToUtc('11:30'),
  end: convertTimeToUtc('23:30'),
};
const isAvailable = (time) => time.toLowerCase() !== 'closed';
const isSameAsOpeningTimes = (time) => includes('24 hours', time);

const parseServingTime = (time) => {
  if (!time || !isAvailable(time) || isSameAsOpeningTimes(time)) return defaultServingTimes;
  const matchTime = /^(.+)\s[–-]\s(.+)$/;
  const matches = time.match(matchTime);
  if (!matches) return defaultServingTimes;
  const [, open, close] = matches;
  return { start: convertTimeToUtc(open), end: convertTimeToUtc(close) };
};

const parseServingDay = (opening) => {
  const matchDayAndTime = /^(.+):\s(.+)$/;
  const matches = opening.match(matchDayAndTime);
  const [, day, time] = matches;
  return {
    [day]: {
      sameAsOpeningTimes: isSameAsOpeningTimes(time),
      available: isAvailable(time),
      ...parseServingTime(time),
    },
  };
};

export const getServedTimesFromOpeningHours = (schedule = []) => {
  const scheduleObject = schedule.reduce(
    (acc, day) => ({
      ...acc,
      ...parseServingDay(day),
    }),
    {},
  );

  return ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map((day) =>
    has('sameAsOpeningTimes', scheduleObject[day])
      ? {
          day,
          ...scheduleObject[day],
        }
      : {
          day,
          sameAsOpeningTimes: true,
          available: true,
          ...defaultServingTimes,
        },
  );
};

const getOpeningTimesText = (day) => {
  if (day.sameAsOpeningTimes || day.isOpen24H) return 'Open 24 hours';
  if (!day.available) return 'Closed';

  const start =
    typeof day.start === 'object'
      ? moment(day.start).format('h:mm a')
      : moment.parseZone(day.start).format('h:mm a');
  const end =
    typeof day.end === 'object'
      ? moment(day.end).format('h:mm a')
      : moment.parseZone(day.end).format('h:mm a');
  return `${start} - ${end}`.toUpperCase();
};

export const getOpeningHoursFromServedTimes = (schedule) => {
  const openingTimes = schedule.map((day) => `${day.day}: ${getOpeningTimesText(day)}`);
  return openingTimes.every((times) => isSameAsOpeningTimes(times)) ? undefined : openingTimes;
};

export const getServedDaysFromLocalDays = (schedule = []) =>
  schedule.map((localDay) => {
    const isOpen24H = localDay.includes('Open 24 hours');
    const [day] = localDay.split(':');

    return {
      day,
      isOpen24H,
    };
  });

export const localDaysAllWeekOpen = [
  'Monday: Open 24 hours',
  'Tuesday: Open 24 hours',
  'Wednesday: Open 24 hours',
  'Thursday: Open 24 hours',
  'Friday: Open 24 hours',
  'Saturday: Open 24 hours',
  'Sunday: Open 24 hours',
];
