import { useSelector } from 'react-redux';
import ROLES from '../shared/constants/roles';
import { getRoles } from '../store/session/selectors';
import { getVenue } from '../store/venues/selectors';

const useRoles = () => {
  const roles = useSelector(getRoles);
  const venue = useSelector(getVenue);
  const { role: venueRole } = venue || {};
  if (roles.indexOf(venueRole) === -1) roles.push(venueRole);

  const isRole = (role) => roles.includes(role);

  const isStaff = () => isRole(ROLES.STAFF);
  const isManager = () => isRole(ROLES.MANAGER);
  const isOwner = () => isRole(ROLES.OWNER);
  const isAdmin = () => isRole(ROLES.ADMIN);
  const isSuperAdmin = () => isRole(ROLES.SUPERADMIN);

  const isRoleAtLeast = (role, offset = 0) => {
    const rolesOrder = [ROLES.STAFF, ROLES.MANAGER, ROLES.OWNER, ROLES.ADMIN, ROLES.SUPERADMIN];
    const manager = isManager();
    const owner = isOwner();
    const admin = isAdmin();
    const superadmin = isSuperAdmin();
    const permissions = [
      true,
      manager || owner || admin || superadmin,
      owner || admin || superadmin,
      admin || superadmin,
      superadmin,
    ];
    const index = rolesOrder.indexOf(role) + offset;
    return permissions[index];
  };

  const isRoleAtLeastManager = () => isRoleAtLeast(ROLES.MANAGER);
  const isRoleAtleastOwner = () => isRoleAtLeast(ROLES.OWNER);

  const isRoleLessthanMe = (role) => isRoleAtLeast(role, 1);

  const highestRole = () => {
    if (isSuperAdmin()) {
      return ROLES.SUPERADMIN;
    }
    if (isAdmin()) {
      return ROLES.ADMIN;
    }
    if (isOwner()) {
      return ROLES.OWNER;
    }
    if (isManager()) {
      return ROLES.MANAGER;
    }
    return ROLES.STAFF;
  };

  return {
    roles,
    isStaff,
    isManager,
    isOwner,
    isAdmin,
    isSuperAdmin,
    isRoleAtLeast,
    isRoleAtLeastManager,
    isRoleAtleastOwner,
    isRoleLessthanMe,
    highestRole,
  };
};

export default useRoles;
