import React, { useCallback, useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import { Route, Switch, useHistory, useLocation } from 'react-router';
import { Box } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { useDispatch } from 'react-redux';

import StepIntro from './StepIntro';
import StepVenueDetailsOne from './StepVenueDetailsOne';
import StepVenueDetailsTwo from './StepVenueDetailsTwo';
import StepVenueDetailsThree from './StepVenueDetailsThree';
import StepBusinessDetailsOne from './StepBusinessDetailsOne';
import Stepper from '../../components/Stepper';
import signupSteps from './signupSteps';
import SignupContext from './SignupContext';
import { initialSignupFields } from './signupFields';
import StepBackButton from './StepBackButton';
import StepCompanyDetailsOne from './StepCompanyDetailsOne';
import StepCompanyDetailsTwo from './StepCompanyDetailsTwo';
import StepBusinessDetailsTwo from './StepBusinessDetailsTwo';
import StepBusinessDetailsThree from './StepBusinessDetailsThree';
import StepReview from './StepReview';
import StepComplete from './StepComplete';
import { useNotifications } from '../../shared/contexts/Notifications/useNotifications';
import { createSignup } from '../../store/signup';
import { getErrorMessage } from '../../shared/utils/errors';

const SignupPage = withStyles(Box, () => ({
  root: {
    display: 'flex',
    width: '100%',
    minHeight: '100vh',
  },
}));

const StepsWrapper = withStyles(Box, (theme) => ({
  root: {
    padding: theme.spacing(4),
    position: 'relative',
    width: '100%',
    paddingBottom: theme.spacing(16),
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(8),
    },
    [theme.breakpoints.up('md')]: {
      width: '40%',
      height: '100vh',
      overflow: 'auto',
      marginBottom: 0,
    },
  },
}));

const StepsNavigation = withStyles(Box, (theme) => ({
  root: {
    position: 'relative',
    marginBottom: theme.spacing(4),
    margin: '0',
    paddingLeft: theme.spacing(10),
    display: 'flex',
    justifyContent: 'center',
  },
}));

const BackgroundWrapper = withStyles(Box, (theme) => ({
  root: {
    width: '60%',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundImage: `url('/img/signup/signup-background.png')`,
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
}));

const BackgroundWrapperBrandUp = withStyles(Box, (theme) => ({
  root: {
    width: '60%',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundImage: `url('/img/signup/signup-background-brandup-corona.jpg')`,
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
}));

const Signup = ({ isBrandUp }) => {
  const path = isBrandUp ? '/signup/brandup' : '/signup';
  const [steps] = useState(signupSteps);
  const [activeStepIndex, setActiveStepIndex] = useState(0);
  const [formValues, setFormValues] = useState(initialSignupFields);
  const dispatch = useDispatch();
  const history = useHistory();
  const { showErrorNotification, showSuccessNotification } = useNotifications();
  const searchParam = useLocation().search;
  const query = useMemo(() => new URLSearchParams(searchParam), [searchParam]);
  const activeStep = steps[activeStepIndex];
  const isFirstStep = activeStepIndex === 0;
  const isLastStep = activeStepIndex === signupSteps.length - 1;
  const isBackBtnVisible = !isLastStep && !isFirstStep;

  const handleOnSubmit = useCallback(async () => {
    const payload = formValues;
    try {
      const response = await dispatch(createSignup(payload));

      showSuccessNotification('Your account has been created');

      return response;
    } catch (error) {
      showErrorNotification(getErrorMessage(error));

      return null;
    }
  }, [dispatch, formValues, showErrorNotification, showSuccessNotification]);

  const handleOnFieldChange = useCallback((fieldName, value) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [fieldName]: value,
    }));
  }, []);

  const handleNextStep = () => {
    const isFinalStep = activeStepIndex === steps.length - 1;

    if (!isFinalStep) {
      setActiveStepIndex(activeStepIndex + 1);
    }
  };

  const handlePreviousStep = useCallback(() => {
    if (!isFirstStep) {
      setActiveStepIndex(activeStepIndex - 1);
    }
  }, [activeStepIndex, isFirstStep]);

  const handleGoToStep = (stepIndex) => {
    setActiveStepIndex(stepIndex);
  };

  const handleStepChange = useCallback(() => {
    history.push(`${path}/${steps[activeStepIndex].path}`);
  }, [activeStepIndex, history, path, steps]);

  const handleSaveToLocalStorage = useCallback(() => {
    localStorage.setItem('signup', JSON.stringify(formValues));
  }, [formValues]);

  const handleCheckSignupDataLocalStorage = useCallback(() => {
    const signupData = JSON.parse(localStorage.getItem('signup'));

    return signupData;
  }, []);

  const handleClearSignupDataLocalStorage = useCallback(() => {
    localStorage.removeItem('signup');
  }, []);

  const handleSetInitialStepIfSignupDataAvailable = useCallback(() => {
    const signupData = handleCheckSignupDataLocalStorage();

    if (signupData) {
      setActiveStepIndex(1);
    }
  }, [handleCheckSignupDataLocalStorage]);

  const handleGAEventCompleteStep = () => {
    const label = window.location.pathname.replace('/', '');

    if (window.gtag) {
      window.gtag('event', 'Step completion', {
        event_category: 'Signup',
        event_label: label,
      });
    }
  };

  const handleNotifySlack = useCallback(async () => {
    try {
      const zapierPayload = {
        venueName: 'Testing venue name 2',
        postCode: 'se156fh',
        venueCity: 'London',
        email: 'test@test.com',
        hubspotCompanyLink: formValues.hubspotCompanyId
          ? `https://app.hubspot.com/contacts/8183225/company/${formValues.hubspotCompanyId}`
          : 'N/A',
      };
      const zapierAxiosInstance = axios.create({
        method: 'POST',
        baseURL: 'https://hooks.zapier.com/hooks/catch/4171118/o7oxdrc',
        transformRequest: [(data) => JSON.stringify(data)],
      });
      const response = await zapierAxiosInstance.request({ data: zapierPayload });

      return response;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);

      return null;
    }
  }, [formValues.hubspotCompanyId]);

  useEffect(() => {
    handleStepChange();
  }, [activeStepIndex, handleStepChange]);

  useEffect(() => {
    const signupData = handleCheckSignupDataLocalStorage();

    if (signupData) {
      setFormValues((prevFormValues) => ({ ...prevFormValues, ...signupData }));
    }
  }, [handleCheckSignupDataLocalStorage]);

  useEffect(() => {
    handleSetInitialStepIfSignupDataAvailable();
  }, [handleSetInitialStepIfSignupDataAvailable]);

  useEffect(() => {
    const hubspotCompanyId = query.get('hubspotCompanyId');

    if (hubspotCompanyId) {
      handleOnFieldChange('hubspotCompanyId', hubspotCompanyId);
    }
  }, [handleOnFieldChange, query]);

  return (
    <SignupContext.Provider
      value={{
        activeStep,
        formValues,
        handleOnFieldChange,
        handlePreviousStep,
        handleNextStep,
        handleGoToStep,
        handleSaveToLocalStorage,
        handleClearSignupDataLocalStorage,
        handleOnSubmit,
        handleGAEventCompleteStep,
        handleNotifySlack,
      }}
    >
      <SignupPage>
        <StepsWrapper>
          <StepsNavigation>
            {isBackBtnVisible && <StepBackButton />}
            <Stepper steps={steps} activeStepIndex={activeStepIndex} />
          </StepsNavigation>
          <Switch>
            <Route exact path={`${path}/${signupSteps[0].path}`} component={StepIntro} />
            <Route exact path={`${path}/${signupSteps[1].path}`} component={StepVenueDetailsOne} />
            <Route exact path={`${path}/${signupSteps[2].path}`} component={StepVenueDetailsTwo} />
            <Route
              exact
              path={`${path}/${signupSteps[3].path}`}
              component={StepVenueDetailsThree}
            />
            <Route
              exact
              path={`${path}/${signupSteps[4].path}`}
              component={StepCompanyDetailsOne}
            />
            <Route
              exact
              path={`${path}/${signupSteps[5].path}`}
              component={StepCompanyDetailsTwo}
            />
            <Route
              exact
              path={`${path}/${signupSteps[6].path}`}
              component={StepBusinessDetailsOne}
            />
            <Route
              exact
              path={`${path}/${signupSteps[7].path}`}
              component={StepBusinessDetailsTwo}
            />
            <Route
              exact
              path={`${path}/${signupSteps[8].path}`}
              component={StepBusinessDetailsThree}
            />
            <Route exact path={`${path}/${signupSteps[9].path}`} component={StepReview} />
            <Route exact path={`${path}/${signupSteps[10].path}`} component={StepComplete} />
          </Switch>
        </StepsWrapper>
        {isBrandUp ? <BackgroundWrapperBrandUp /> : <BackgroundWrapper />}
      </SignupPage>
    </SignupContext.Provider>
  );
};

export default Signup;
