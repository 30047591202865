export default [
  {
    startTime: '08:00',
    active: true,
    endTime: '23:59',
    offers: [],
    venueId: '1',
    items: [
      {
        available: true,
        groupName: 'While You Wait',
        groupItems: ['17', '16'],
        groupId: 'bKHnqMHo0WbqQ212jp9D2',
      },
      {
        available: true,
        description: 'Served on a woodchip plank ',
        groupName: 'The Main Event',
        groupItems: ['19', '16', '18'],
        groupId: 'VXh9P_LETbQZ-zIOAXkDl',
      },
      {
        available: true,
        groupName: 'Room for Pudding',
        groupItems: ['3', '4'],
        groupId: 'npMAc5YOMUhBwiM0rdVIO',
      },
      {
        available: true,
        description: "Midweek meals when you're in a bit of a rush",
        schedule: [
          'Monday: 12:00 PM - 2:30 PM',
          'Tuesday: 12:00 PM - 2:30 PM',
          'Wednesday: 12:00 PM - 2:30 PM',
          'Thursday: 12:00 PM - 2:30 PM',
          'Friday: 12:00 PM - 2:30 PM',
          'Saturday: Closed',
          'Sunday: Closed',
        ],
        groupName: 'Weekday Express Lunch ',
        groupItems: ['16'],
        groupId: 'ph_kivQaN7buG_2uRVU9j',
      },
      {
        available: true,
        description: 'Not available any other days',
        schedule: [
          'Monday: Closed',
          'Tuesday: Closed',
          'Wednesday: Closed',
          'Thursday: Closed',
          'Friday: Open 24 hours',
          'Saturday: Closed',
          'Sunday: Closed',
        ],
        groupName: "It's Fri-yay!!! ",
        groupItems: ['5', '18'],
        groupId: 'EFSUGVbT2lTOEaDe0iTS7',
      },
      {
        available: true,
        description: "The world's most popular libations",
        groupName: 'Beers, Wines and Spirits',
        groupItems: ['12', '14', '13', '15', '10'],
        groupId: 'hRt0WsY4OQC31XLtTblwA',
      },
      {
        available: true,
        description: 'A selection from small batch producers in the UK',
        groupName: 'Craft Ales and Spirits',
        groupItems: ['1', '9', '8'],
        groupId: 'fmQVSalJZ3HIOFQAY3oop',
      },
      {
        available: true,
        description: 'Soft drinks and mocktails',
        groupName: 'Refreshing whistle wetters for the designated driver',
        groupItems: ['11', '7', '6'],
        groupId: 'Q5SrBxogCtGEv44dmLokV',
      },
      {
        available: false,
        description: 'Enjoy the hot weather with these thirst quenchers',
        schedule: [
          'Monday: Closed',
          'Tuesday: Closed',
          'Wednesday: Closed',
          'Thursday: Closed',
          'Friday: Closed',
          'Saturday: Closed',
          'Sunday: Closed',
        ],
        groupName: 'Summer Punches',
        groupItems: ['1', '4'],
        groupId: 'Skt9T36pM3Z3IGcK1Z9Iw',
      },
    ],
    menuName: 'main',
  },
];
