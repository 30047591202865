import { get } from './httpRequest';

export const fetchCashup = async (cashupId) => {
  const response = await get(`/cashups/${cashupId}`);
  return response.data;
};

export const fetchCashupHistory = async (cashupId, queryFrom) => {
  if (queryFrom) {
    const response = await get(`/cashups/${cashupId}/history?from=${queryFrom}`);
    return response.data;
  }
  return [];
};
