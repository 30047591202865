import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { rem } from 'polished';
import { Field } from 'formik';
import { Switch } from 'formik-mui';
import TimePickerField from '../../TimePickerField/TimePickerField';

const useStyles = makeStyles((theme) => ({
  timeWrapper: {
    backgroundColor: theme.palette.grey[100],
    borderRadius: theme.shape.borderRadius,
  },

  title: {
    fontSize: rem(16),
    fontWeight: 500,
    color: theme.palette.grey[800],
  },
}));

const OpeningTimeField = (props) => {
  const { opening, name, openLabel = 'Open' } = props;
  const classes = useStyles();

  return (
    <Box mb={2} p={2} className={classes.timeWrapper}>
      <Grid container spacing={2} justifyContent="space-between">
        <Grid item xs={12} sm={2}>
          <Typography className={classes.title}>{opening.day.substr(0, 3)}</Typography>
        </Grid>
        <Grid item xs={12} sm={2}>
          <FormControlLabel
            control={
              <Field
                name={`${name}.isOpen`}
                component={Switch}
                type="checkbox"
                size="small"
                color="primary"
              />
            }
            label={openLabel}
          />
        </Grid>
        {opening.isOpen && (
          <>
            <Grid item sm={4} xs={12}>
              <Field
                name={`${name}.time.open`}
                component={TimePickerField}
                fullWidth
                label="Open"
                inputVariant="outlined"
                size="small"
                dataTestId="open-time-input"
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <Field
                name={`${name}.time.close`}
                component={TimePickerField}
                fullWidth
                label="Close"
                inputVariant="outlined"
                size="small"
                dataTestId="close-time-input"
              />
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
};

OpeningTimeField.propTypes = {
  // should be moment type
  // eslint-disable-next-line react/forbid-prop-types
  opening: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
};
export default OpeningTimeField;
