import React, { useState } from 'react';

import { makeStyles } from '@mui/styles';
import { Box, Button, Collapse, Grid, Typography, Link as MuiLink } from '@mui/material';
import { Link } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import { ReactComponent as Circle } from '../../assets/images/circle.svg';
import { ReactComponent as CircleTick } from '../../assets/images/circle-tick.svg';

const useStyles = makeStyles((theme) => ({
  subtitle: {
    fontFamily: 'Sen',
    fontWeight: 'bold',
    marginTop: '0',
  },
  description: {
    marginTop: '0',
  },
  task: {
    marginTop: '0.5rem',
    backgroundColor: theme.sessionsScorePalette.nonCompliantLightGrey,
    borderRadius: '8px',
    padding: '16px',
  },
}));

const Task = ({
  completed = false,
  title,
  description,
  buttonTitle,
  buttonLink,
  setItem,
  progressCount,
  setProgressCount,
  updateTaskLoading,
  handler,
}) => {
  const classes = useStyles();
  const [isGroupCollapsed, setIsGroupCollapsed] = useState(completed);
  const [isHovered, setIsHovered] = useState(false);

  const isLinkaFunction = typeof buttonLink === 'function';

  const handleClick = () => {
    setItem();
    setIsGroupCollapsed(!isGroupCollapsed);
    setProgressCount(isGroupCollapsed ? progressCount - 1 : progressCount + 1);
  };

  const isExternalLink = (url) => /^https?:\/\//.test(url);

  return updateTaskLoading && handler === 'FE' ? (
    <Grid container className={classes.task} sx={{ display: 'flex', justifyContent: 'center' }}>
      <Box sx={{ my: 4, display: 'flex', justifyContent: 'center' }}>
        <CircularProgress />
      </Box>
    </Grid>
  ) : (
    <>
      <Grid container className={classes.task}>
        <Grid item xs={2} sm={1}>
          {isGroupCollapsed ? (
            <CircleTick
              alt="Circle ticked icon"
              style={{ fill: '#8517B5', cursor: 'pointer', width: '24px', height: '24px' }}
              onClick={handleClick}
            />
          ) : (
            <Box
              sx={{
                width: '24px',
                height: '24px',
                cursor: 'pointer',
              }}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
              onClick={handleClick}
            >
              {isHovered ? (
                <CircleTick style={{ fill: '#D0CFDF', width: '100%', height: '100%' }} />
              ) : (
                <Circle />
              )}
            </Box>
          )}
        </Grid>
        <Grid item xs={10} sm={11}>
          <Typography
            className={classes.subtitle}
            variant="h4"
            sx={{ cursor: 'pointer' }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={handleClick}
          >
            {title}
          </Typography>
          <Collapse
            className={classes.menuItems}
            in={!isGroupCollapsed}
            timeout="auto"
            unmountOnExit
          >
            {description && (
              <MuiLink onClick={handleClick} sx={{ textDecoration: 'none' }}>
                <p
                  className={classes.description}
                  style={{ cursor: 'pointer' }}
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                >
                  {description}
                </p>
              </MuiLink>
            )}
            {buttonTitle && isLinkaFunction && (
              <Button variant="contained" color="primary" onClick={buttonLink}>
                {buttonTitle}
              </Button>
            )}
            {buttonTitle && !isLinkaFunction && (
              <Button
                variant="contained"
                color="primary"
                component={isExternalLink(buttonLink) ? 'a' : Link}
                to={isExternalLink(buttonLink) ? undefined : buttonLink}
                href={isExternalLink(buttonLink) ? buttonLink : undefined}
                target={isExternalLink(buttonLink) ? '_blank' : undefined}
                rel={isExternalLink(buttonLink) ? 'noopener noreferrer' : undefined}
              >
                {buttonTitle}
              </Button>
            )}
          </Collapse>
        </Grid>
      </Grid>
    </>
  );
};

export default Task;
