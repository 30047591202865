import * as Yup from 'yup';
import { isValid } from 'postcode';

import { signupCountries } from '../signupFields';

const validationSchema = Yup.object().shape({
  businessName: Yup.string().min(3, 'Too Short!').required('Please enter your business name'),
  address: Yup.string()
    .min(3, 'Too Short!')
    .required('Please enter a valid address including house number'),
  city: Yup.string().min(3, 'Too Short!').required('Please enter a valid city name'),
  country: Yup.string().oneOf(signupCountries).required('Please choose a country from the list'),
  postcode: Yup.string()
    .min(3, 'Too Short!')
    .test('validPostCode', 'Please enter a valid postcode', (value) => value && isValid(value))
    .required('Please enter a valid postcode'),
});

export default validationSchema;
