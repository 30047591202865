import { Formik } from 'formik';
import React, { useContext } from 'react';
import isEmpty from 'lodash.isempty';

import SignupContext from '../SignupContext';
import StepQuestion from '../StepQuestion';
import StepButton from '../StepButton';
import { signupFields } from '../signupFields';
import StepInput from '../StepInput';
import validationSchema from './validationSchema';

const StepCompanyDetailsOne = () => {
  const { formValues } = useContext(SignupContext);
  const initialValues = {
    [signupFields.email]: formValues.email,
    [signupFields.phone]: formValues.phone,
  };
  return (
    <>
      <StepQuestion question="What are your company's contact details?" />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnMount
        enableReinitialize
      >
        {({ errors, isValid }) => {
          const isFormValid = isValid && isEmpty(errors);

          return (
            <>
              <StepInput type="email" name={signupFields.email} placeholder="Email address" />
              <StepInput type="tel" name={signupFields.phone} placeholder="Mobile Number" />

              <StepButton disabled={!isFormValid} />
            </>
          );
        }}
      </Formik>
    </>
  );
};

export default StepCompanyDetailsOne;
