import { createSlice } from '@reduxjs/toolkit';
import {
  fetchKiosksApi,
  fetchKioskOnboardingKeyApi,
  updateKioskApi,
  deleteKioskApi,
} from '../../api/kiosks';
import {
  createAsyncAction,
  createAsyncReducerHandlers,
  asyncInitialState as initialState,
} from '../utils/async';

export const fetchKiosks = createAsyncAction('fetch-kiosks', fetchKiosksApi);
export const updateKiosk = createAsyncAction('update-kiosk', updateKioskApi);
export const deleteKiosk = createAsyncAction('delete-kiosk', deleteKioskApi);

const kioskSlice = createSlice({
  name: 'kiosks',
  initialState,
  reducers: {
    clearKiosks(state) {
      delete state.data;
    },
  },
  extraReducers: {
    ...createAsyncReducerHandlers(fetchKiosks),
    ...createAsyncReducerHandlers(updateKiosk),
    ...createAsyncReducerHandlers(deleteKiosk),
  },
});

export const { clearKiosks } = kioskSlice.actions;
export const { reducer: kiosks } = kioskSlice;

export const fetchKioskOnboardingKey = createAsyncAction(
  'fetch-kiosk-onboarding-key',
  fetchKioskOnboardingKeyApi,
);

const kioskOnboardingKeySlice = createSlice({
  name: 'kioskOnboardingKey',
  initialState,
  reducers: {
    clearKioskOnboardingKey(state) {
      delete state.data;
    },
  },
  extraReducers: {
    ...createAsyncReducerHandlers(fetchKioskOnboardingKey),
  },
});

export const { clearKioskOnboardingKey } = kioskOnboardingKeySlice.actions;
export const { reducer: kioskOnboardingKey } = kioskOnboardingKeySlice;
