import { makeStyles } from '@mui/styles';
import React from 'react';
import getNormalisedStepPosition from '../getNormalisedStepPosition';

const useStyles = ({ stepPosition, isActive }) => {
  const isLastStepPosition = stepPosition === 100;
  const isFirstStepPosition = stepPosition === 0;
  const circleWidth = 20;
  const circleHeight = 20;

  return makeStyles((theme) => ({
    circle: {
      margin: 'auto',
      marginLeft: isLastStepPosition || isFirstStepPosition ? `-${circleWidth / 2}px` : '0',
      top: 0,
      bottom: 0,
      left: isLastStepPosition
        ? `calc(${stepPosition}% + ${circleWidth / 2}px)`
        : `${stepPosition}%`,
      position: 'absolute',
      borderRadius: '50%',
      width: circleWidth,
      height: circleHeight,
      display: 'inline-block',
      transition: 'background-color 0.9s ease-in-out',
      backgroundColor: isActive ? theme.palette.secondary.main : theme.palette.grey[300],
    },
  }));
};

const StepperCircleIndicator = ({ step, stepPositionValue, activeStep }) => {
  const { position } = step;
  const stepPosition = getNormalisedStepPosition(position, stepPositionValue);
  const isActive = activeStep.position >= position;
  const classes = useStyles({ stepPosition, isActive })();

  return <span className={classes.circle} />;
};

export default StepperCircleIndicator;
