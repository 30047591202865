import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { makeStyles } from '@mui/styles';
import { Button, Typography } from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';

import Page from '../../components/Page';
import withVenue from '../../hoc/withVenue';
import { getMobileDevicesState, getOnboardingDeviceKeyState } from '../../store/mobiles/selectors';

import { getTerminalDevicesState } from '../../store/terminals/selectors';
import { fetchTerminalDevices } from '../../store/terminals';

import {
  clearMobileDevices,
  fetchMobileDevices,
  fetchOnboardingDeviceKey,
  updateMobileDevicesAvailability,
} from '../../store/mobiles';
import MobilesTable from '../../components/MobilesTable';
import shouldLoad from '../../shared/utils/shouldLoad';
import { getErrorMessage } from '../../shared/utils/errors';
import { useNotifications } from '../../shared/contexts/Notifications/useNotifications';
import PageHeader from '../../components/PageHeader';
import QrContainer from '../../components/QrContainer';

const useStyles = makeStyles((theme) => ({
  heading: {
    marginBottom: '20px',
    display: 'flex',
  },
  title: {
    flexGrow: 1,
  },
  storeAnchor: {
    display: 'inlineBlock',
    overflow: 'hidden',
    borderRadius: '13px',
  },
  storeImage: {
    borderRadius: '13px',
    display: 'block',
    marginRight: theme.spacing(2),
  },
  deviceKeyBtn: {
    marginBottom: theme.spacing(2),
  },
}));

const Mobiles = () => {
  const [showOnboardingDeviceKey, setShowOnboardingDeviceKey] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();
  const mobileDevicesState = useSelector(getMobileDevicesState);
  const terminalDevicesState = useSelector(getTerminalDevicesState);
  const onboardingDeviceKeyState = useSelector(getOnboardingDeviceKeyState);
  const { loading, data, error } = mobileDevicesState;
  const { data: onboardingDeviceKeyData } = onboardingDeviceKeyState;
  const { showErrorNotification, showSuccessNotification } = useNotifications();
  //   const googleLinkUrl = `https://play.google.com/store/apps/details?id=co.theup.upcovenue
  // &pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1`;

  const handleEnableSwitch = async (values) => {
    try {
      await dispatch(updateMobileDevicesAvailability({ values }));
      showSuccessNotification(`Mobile device availability has been successfully updated`);
      await dispatch(fetchMobileDevices());
    } catch (e) {
      await dispatch(fetchMobileDevices());
      showErrorNotification(getErrorMessage(e));
    }
  };

  useEffect(() => {
    if (shouldLoad(mobileDevicesState)) dispatch(fetchMobileDevices());
  }, [dispatch, mobileDevicesState]);

  useEffect(() => {
    if (shouldLoad(terminalDevicesState)) dispatch(fetchTerminalDevices());
  }, [dispatch, terminalDevicesState]);

  useEffect(() => {
    if (shouldLoad(onboardingDeviceKeyState)) dispatch(fetchOnboardingDeviceKey());
  }, [dispatch, onboardingDeviceKeyState]);

  return (
    <>
      <PageHeader fullWidth>
        <div className={classes.heading}>
          <Typography className={classes.title} variant="h2" component="h1">
            Mobile Devices
          </Typography>
          <aside>
            <a
              className={classes.storeAnchor}
              target="_blank"
              rel="noopener noreferrer"
              href="https://apps.apple.com/us/app/up-co-venue/id1539143722?itsct=apps_box&amp;itscg=30200"
            >
              <img
                alt="Download on the App Store"
                className={classes.storeImage}
                src="./apple-badge.svg"
              />
            </a>
          </aside>
          {/* <aside> */}
          {/*   <a className={classes.storeAnchor} href={googleLinkUrl}> */}
          {/*     <img */}
          {/*       alt="Get it on Google Play" */}
          {/*       className={classes.storeImage} */}
          {/*       src="./google-play-badge.svg" */}
          {/*     /> */}
          {/*   </a> */}
          {/* </aside> */}
        </div>
      </PageHeader>
      <Page loading={loading} error={error} fullWidth>
        {data && (
          <>
            <Typography paragraph>
              This page allows you to view the mobile devices (iOS) registered to your venue.
            </Typography>
            {onboardingDeviceKeyData && (
              <>
                <Button
                  variant="outlined"
                  onClick={() => setShowOnboardingDeviceKey(!showOnboardingDeviceKey)}
                  className={classes.deviceKeyBtn}
                  endIcon={showOnboardingDeviceKey ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                >
                  Onboard Device
                </Button>
                {showOnboardingDeviceKey && (
                  <QrContainer
                    title="Onboard Device"
                    value={onboardingDeviceKeyData.onboardingDeviceKey}
                  />
                )}
              </>
            )}
            <br />
            <MobilesTable onSubmit={handleEnableSwitch} />
          </>
        )}
      </Page>
    </>
  );
};

export default withVenue(Mobiles, null, clearMobileDevices);
