import React from 'react';
import { Paper, Typography, Box, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { MdInfoOutline } from 'react-icons/md';
import InfoTooltip from '../InfoTooltip';

const useStyles = makeStyles((theme) => {
  const styles = {
    container: {
      padding: theme.spacing(2),
      height: '100%',
    },
    cardTitle: {
      ...theme.customFonts.mediumBold,
    },
    cashbackBreakdownRow: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: theme.spacing(2),
      '& h2': {
        ...theme.customFonts.mediumBold,
        marginRight: theme.spacing(2),
        padding: theme.spacing(1),
      },
      [theme.breakpoints.down('lg')]: {
        padding: theme.spacing(1),
      },
    },
    cashbackRow: {
      backgroundColor: theme.customPalette.midnight,
      color: theme.customPalette.white,
      width: '100%',
      padding: theme.spacing(3),
      '& h2': {
        ...theme.customFonts.xLargeBold,
      },
      [theme.breakpoints.down('lg')]: {
        padding: theme.spacing(1),
      },
    },
    cashbackExplainer: {
      ...theme.customFonts.small,
      marginTop: theme.spacing(2),
      textAlign: 'center',
    },
    infoIcon: {
      verticalAlign: 'middle',
      fontSize: '1rem',
    },
    cashbackValues: {
      display: 'flex',
      justifyContent: 'space-around',
      width: 350,
    },
    cashbackValueContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: theme.spacing(1),
    },
    scoreValue: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& p': {
        ...theme.customFonts.xLargeBold,
      },
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(1),
      },
    },
    cashbackValueTitle: {
      ...theme.customFonts.small,
    },
    cashbackRowValueTitle: {
      ...theme.customFonts.large,
    },
    cashbackValueBox: {
      width: 40,
      height: 15,
      borderRadius: theme.shape.borderRadius,
      border: `1px solid ${theme.customPalette.midnight}`,
    },
    cashbackRowValueBox: {
      width: 40,
      height: 15,
      borderRadius: theme.shape.borderRadius,
      border: `1px solid ${theme.customPalette.white}`,
      background: theme.customPalette.white,
    },
    cashbackRowScoreValue: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& p': {
        ...theme.customFonts.xxLargeBold,
      },
    },
    dashedBorder: {
      borderLeft: `1px dashed ${theme.palette.text.primary}`,
      borderRight: `1px dashed ${theme.palette.text.primary}`,
      boxSizing: 'border-box',
      marginBottom: `-${theme.spacing(4)}`,
      position: 'relative',
      [theme.breakpoints.down('lg')]: {
        marginTop: 'unset',
        marginBottom: 'unset',
      },
    },
    dashedBorderTop: {
      borderTop: `1px dashed ${theme.palette.text.primary}`,
    },
    notCompliantValueBox: {
      backgroundColor: theme.sessionsScorePalette.nonCompliantLightGrey,
    },
    notCompliantValueBoxFilled: {
      backgroundColor: theme.sessionsScorePalette.nonCompliantDarkGrey,
    },
    notCompliantScoreText: {
      color: theme.sessionsScorePalette.nonCompliantDarkGrey,
    },
  };

  const colors = [
    theme.sessionsScorePalette.reallyRed,
    theme.sessionsScorePalette.red,
    theme.sessionsScorePalette.yellow,
    theme.sessionsScorePalette.green,
    theme.sessionsScorePalette.reallyGreen,
  ];

  colors.forEach((color, index) => {
    styles[`cashbackValueBoxFilled${index + 1}`] = { backgroundColor: color };
  });

  colors.forEach((color, index) => {
    styles[`scoreValueText${index + 1}`] = { color };
  });

  return styles;
});

const getValueBoxStyle = ({ scCompliant, index, score, classes }) => {
  if (scCompliant && index < score) {
    return `${classes[`cashbackValueBoxFilled${score}`]}`;
  }
  if (!scCompliant && index < score) {
    return classes.notCompliantValueBoxFilled;
  }

  if (!scCompliant && !(index < score)) {
    return classes.notCompliantValueBox;
  }
  return null;
};

const CashbackBreakdown = ({ scoreData }) => {
  const classes = useStyles();

  const {
    scales,
    newRatingsScore,
    openRateScore,
    missingItemsScore,
    commissionReductionScore,
    openRateValue,
    newRatingsValue,
    missingItemsValue,
    commissionReductionValue,
    scCompliant,
  } = scoreData;
  const { openRate, newRatings, missingItems, cashbackPercent } = scales;
  const minScore = Math.min(openRateScore, missingItemsScore, newRatingsScore);

  return (
    <Paper className={classes.container}>
      <Typography component="h2" className={classes.cardTitle}>
        How your cashback is calculated
      </Typography>
      <Box>
        <StandardCashbackRow
          title="Open Rate"
          scaleData={openRate}
          score={openRateScore}
          classes={classes}
          scoreValue={openRateValue}
          minScore={minScore}
          scCompliant={scCompliant}
          topDashedBorder
        />
        <StandardCashbackRow
          title="Missing Items"
          scaleData={missingItems}
          score={missingItemsScore}
          scoreValue={missingItemsValue}
          classes={classes}
          minScore={minScore}
          scCompliant={scCompliant}
        />
        <StandardCashbackRow
          title="New Ratings"
          scaleData={newRatings}
          score={newRatingsScore}
          scoreValue={newRatingsValue}
          classes={classes}
          minScore={minScore}
          scCompliant={scCompliant}
        />
        <Grid container className={`${classes.cashbackBreakdownRow} ${classes.cashbackRow}`}>
          <Grid item xs={12} lg={3}>
            <Typography component="h2">Cashback</Typography>
          </Grid>
          <Grid item xs={12} lg={6} className={classes.cashbackValues}>
            {cashbackPercent.ranges.map((value, index) => (
              <Box className={classes.cashbackValueContainer} key={value}>
                <Typography component="p" className={classes.cashbackRowValueTitle}>
                  {value}
                  {cashbackPercent.units}
                </Typography>
                <Box
                  key={value}
                  className={`${classes.cashbackRowValueBox} 
                  ${
                    scCompliant && index < commissionReductionScore
                      ? classes[`cashbackValueBoxFilled${commissionReductionScore}`]
                      : ''
                  }
                  ${
                    !scCompliant && index < commissionReductionScore
                      ? classes.notCompliantValueBoxFilled
                      : classes.notCompliantValueBox
                  }
                  `}
                />
              </Box>
            ))}
          </Grid>
          <Grid item xs={12} lg={2} className={classes.cashbackRowScoreValue}>
            <Typography component="p">{`${(commissionReductionValue * 100)?.toFixed(
              2,
            )}%`}</Typography>
          </Grid>
        </Grid>
      </Box>
      <Typography component="p" className={classes.cashbackExplainer}>
        Your cashback is based on the lowest of your other scores
      </Typography>
    </Paper>
  );
};

const StandardCashbackRow = ({
  title,
  scaleData,
  score,
  scoreValue,
  minScore,
  classes,
  topDashedBorder,
  scCompliant,
}) => {
  const { ranges, tooltip } = scaleData;
  let formattedScoreValue = scoreValue?.toFixed(1) || 0;
  let prefix = null;
  let suffix = null;
  if (scaleData?.units === '%') {
    suffix = '%';
    formattedScoreValue = `${(scoreValue * 100).toFixed(1)}${suffix}`;
  } else if (scaleData?.units === '£') {
    prefix = '£';
  }

  return (
    <Grid container className={classes.cashbackBreakdownRow}>
      <Grid item xs={12} lg={3}>
        <Typography component="h2">
          {title}{' '}
          <InfoTooltip text={tooltip}>
            <span>
              <MdInfoOutline className={classes.infoIcon} />
            </span>
          </InfoTooltip>
        </Typography>
      </Grid>
      <Grid item xs={12} lg={6} className={classes.cashbackValues}>
        {ranges.map((value, index) => (
          <Box
            className={`${classes.cashbackValueContainer} 
              ${index === minScore - 1 ? classes.dashedBorder : ''}
              ${index === minScore - 1 && topDashedBorder ? classes.dashedBorderTop : ''}`}
            key={value}
          >
            <Typography component="p" className={classes.cashbackValueTitle}>
              {prefix}
              {value}
              {suffix}
            </Typography>
            <Box
              key={value}
              className={`
                ${classes.cashbackValueBox} 
                ${getValueBoxStyle({ scCompliant, index, score, classes })}
              `}
            />
          </Box>
        ))}
      </Grid>
      <Grid item xs={12} lg={2} className={classes.scoreValue}>
        <Typography
          component="p"
          className={
            scCompliant ? classes[`scoreValueText${score}`] : classes.notCompliantScoreText
          }
        >
          {formattedScoreValue}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default CashbackBreakdown;
