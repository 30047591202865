import React from 'react';

import { makeStyles } from '@mui/styles';
import { Card, CardContent, Typography, Link as MuiLink, Grid } from '@mui/material';
import Warning from './warning.svg';
import withVenue from '../../hoc/withVenue';
import useRoles from '../../hooks/useRoles';

const useStyles = makeStyles(() => ({
  yellowCard: {
    color: '#333333',
    backgroundColor: '#FFF7E3',
  },
  title: {
    fontFamily: 'Sen',
    fontWeight: 'bold',
    flexGrow: 1,
    marginBottom: '1rem',
  },
}));

const VerifyCard = ({ venue }) => {
  const { hwStatus, bankDetails, isStripeEnabled, stripeAccountStatus } = venue;
  const hwUndefined = hwStatus === undefined;
  const classes = useStyles();
  const isPayoutMissing = hwStatus !== 'VERIFIED';
  const isBankDetailsMissing = bankDetails !== 'OK';
  const isMissing = hwUndefined ? false : isPayoutMissing || isBankDetailsMissing;
  const isDelivery = venue.accountType === 'DELIVERY_ONLY';
  const { isRoleAtleastOwner } = useRoles();
  const isOwner = isRoleAtleastOwner();
  const stripeCheck = isStripeEnabled && bankDetails !== 'OK' && stripeAccountStatus !== 'OK';

  const verifyCardContent = (
    <Grid item xs={12}>
      <Card className={classes.yellowCard}>
        <CardContent>
          <Grid container>
            <Grid item xs={1}>
              <img src={Warning} alt="Warning icon" />
            </Grid>
            <Grid item xs={11}>
              <Typography className={classes.title} variant="h4">
                Verify your payment method
              </Typography>
              <p>
                Before any revenue can land in your account, you must ensure your bank details are
                verified.
              </p>
              <p>
                <MuiLink
                  underline="always"
                  href={isOwner ? '/settings/business/payout-details' : '/settings/business'}
                >
                  Fill out your form
                </MuiLink>
              </p>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );

  const showVerifyCard = () => {
    switch (true) {
      case isDelivery === true:
        return <></>;
      case !isStripeEnabled && isMissing:
        return verifyCardContent;
      case stripeCheck:
        return verifyCardContent;
      default:
        return <></>;
    }
  };

  return showVerifyCard();
};

export default withVenue(VerifyCard);
