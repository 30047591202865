import React from 'react';
import { Formik, Form } from 'formik';
import { makeStyles } from '@mui/styles';
import { TextField, Box, Button } from '@mui/material';
import * as Yup from 'yup';

const useStyles = makeStyles((theme) => ({
  helperText: {
    color: theme.palette.error.main,
  },
}));

const AddDebitForm = ({ amount, reason, setAmount, setReason, setConfirmDialogOpen }) => {
  const classes = useStyles();

  const NewDebitSchema = Yup.object().shape({
    amount: Yup.number().min(1).required('Amount is required'),
    reason: Yup.string().required('Reason is required'),
  });

  const handleOpenDialog = ({ amount: newAmount, reason: newReason }) => {
    setAmount(newAmount);
    setReason(newReason);
    setConfirmDialogOpen(true);
  };

  return (
    <>
      <Formik
        initialValues={{ amount, reason }}
        validationSchema={NewDebitSchema}
        enableReinitialize
      >
        {({ errors, touched, handleBlur, handleChange, isValid, values }) => (
          <Form>
            <Box display="flex" justifyContent="space-between" alignItems="flex-start" ml={2}>
              <Box mr={2} m={-2} display="flex" justifyContent="flex-start">
                <Box mr={2}>
                  <TextField
                    helperText={errors.amount && touched.amount && errors.amount}
                    FormHelperTextProps={{ className: classes.helperText }}
                    type="number"
                    name="amount"
                    size="small"
                    label="Amount"
                    variant="outlined"
                    placeholder="0"
                    required
                    margin="normal"
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </Box>
                <Box>
                  <TextField
                    helperText={errors.amount && touched.amount && errors.amount}
                    FormHelperTextProps={{ className: classes.helperText }}
                    name="reason"
                    size="small"
                    label="Reason for charge"
                    variant="outlined"
                    placeholder=""
                    required
                    margin="normal"
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </Box>
              </Box>
              <Box>
                <Button
                  disabled={!isValid}
                  variant="contained"
                  color="primary"
                  onClick={() => handleOpenDialog(values)}
                >
                  Add debit
                </Button>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default AddDebitForm;
