import { createSlice } from '@reduxjs/toolkit';
import {
  createAsyncAction,
  asyncInitialState as initialState,
  createAsyncReducerHandlers,
} from '../../utils/async';
import { getBare, patchBare } from '../../../api/httpRequest';

const fetchBrandApi = async (id) => {
  const response = await getBare(`brands/${id}`);
  return response.data;
};

const updateBrandApi = async (id, data) => {
  const { primaryColorHex, onPrimaryColorHex, tileColorHex, onTileColorHex } = data;
  const kioskBrandSettings = {
    ...data,
    primaryColorHex: primaryColorHex.startsWith('#') ? primaryColorHex.slice(1) : primaryColorHex,
    onPrimaryColorHex: onPrimaryColorHex.startsWith('#')
      ? onPrimaryColorHex.slice(1)
      : onPrimaryColorHex,
    tileColorHex: tileColorHex.startsWith('#') ? tileColorHex.slice(1) : tileColorHex,
    onTileColorHex: onTileColorHex.startsWith('#') ? onTileColorHex.slice(1) : onTileColorHex,
  };

  delete kioskBrandSettings.tileColorControl;
  delete kioskBrandSettings.logoURL;
  delete kioskBrandSettings.carouselImageURLs;
  delete kioskBrandSettings.updatedAt;

  const response = await patchBare(`brands/${id}`, {
    data: {
      kioskBrandSettings,
    },
  });

  return response.data;
};

export const fetchBrand = createAsyncAction('fetch-brand', fetchBrandApi);

export const updateBrand = createAsyncAction('update-brand', updateBrandApi);

const brandSlice = createSlice({
  name: 'brand',
  initialState,
  reducers: {
    clearBrand(state) {
      delete state.data;
    },
  },
  extraReducers: {
    ...createAsyncReducerHandlers(fetchBrand),
    ...createAsyncReducerHandlers(updateBrand),
  },
});

export const { clearBrand } = brandSlice.actions;
export const { reducer: brand } = brandSlice;
