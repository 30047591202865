import { createSelector } from '@reduxjs/toolkit';
import { ascend, prop, sortWith } from 'ramda';

const deviceSort = sortWith([ascend(prop('mobileDeviceId'))]);
export const getMobileDevicesState = (state) => state.mobileDevices;
export const getMobileDeviceState = (state) => state.mobileDevice;

export const getOnboardingDeviceKeyState = (state) => state.onboardingDeviceKey;
export const getMobileDevices = createSelector(
  getMobileDevicesState,
  (mobileDevicesState) => mobileDevicesState.data && deviceSort(mobileDevicesState.data),
);

export const getMobileDevice = createSelector(
  getMobileDeviceState,
  (mobileDeviceState) => mobileDeviceState.data,
);
