import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { makeStyles } from '@mui/styles';
import { Box, Card, Typography } from '@mui/material';

import Page from '../../components/Page';

import { rangeEnums } from '../../shared/utils/dateRanges';
import withVenue from '../../hoc/withVenue';
import ESPicker from '../../components/ESPicker';
import useESPicker from '../../hooks/useESPicker';
import toUTC from '../../shared/utils/toUTC';
import { getReportSalesByItemState } from '../../store/reports/selectors';
import { clearReportSalesByItem, fetchReportSalesByItem } from '../../store/reports';
import OrderableTable from '../../components/OrderableTable';
import DownloadReport from '../../components/DownloadReport';
import PageHeader from '../../components/PageHeader';

const useStyles = makeStyles((theme) => ({
  heading: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  title: {
    flexGrow: 1,
  },
  stripeLink: {
    marginLeft: theme.spacing(3),
  },
  form: {
    width: '80%',
    flexGrow: 2,
  },
  formElement: {
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  chart: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(5),
  },
}));

const ReportSalesByItem = ({ currentVenueId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const salesItemsState = useSelector(getReportSalesByItemState);
  const { loading, data, error } = salesItemsState;

  const [downloadPath, setDownloadPath] = React.useState(null);
  const esPicker = useESPicker({ useOrder: false, useSearch: false });
  const { selectedDates, dateRange, reportingType } = esPicker;

  const loadData = useCallback(() => {
    dispatch(
      fetchReportSalesByItem({
        dateRange,
        from: dateRange === rangeEnums.CUSTOM && toUTC(selectedDates.from),
        to: dateRange === rangeEnums.CUSTOM && toUTC(selectedDates.to),
        reportingType,
      }),
    );
  }, [dateRange, dispatch, selectedDates, reportingType]);

  useEffect(() => {
    loadData();
  }, [dispatch, loadData]);

  useEffect(() => {
    const { from, to } = selectedDates;
    const params = dateRange ? `gte=${from}&lte=${to}` : '';
    setDownloadPath(`/reports/salesByItem/export?${params}`);
  }, [dateRange, selectedDates]);

  return (
    <>
      <PageHeader fullWidth>
        <Box className={classes.heading}>
          <Typography className={classes.title} variant="h2" component="h1">
            Sales by item
          </Typography>
        </Box>
        <DownloadReport
          path={downloadPath}
          type="salesByItem"
          variant="text"
          title="Export"
          fullWidth={false}
          includeVouchers="exclude"
        />
        <ESPicker esPicker={esPicker} left />
      </PageHeader>
      <Page loading={loading} error={error} fullWidth>
        {currentVenueId && data && (
          <Card>
            <OrderableTable
              showTotals
              tableData={data}
              zeroCheckTitle="orders"
              rowTitle="name"
              titles={['ITEM NAME', 'ORDERS', 'PRE-DISCOUNT SALES', 'GROSS SALES']}
              excludeFields={['itemId']}
              orderBy="GROSS SALES"
            />
          </Card>
        )}
      </Page>
    </>
  );
};

export default withVenue(ReportSalesByItem, null, clearReportSalesByItem);
