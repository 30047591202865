import React from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { TextField } from 'formik-mui';
import { Card, CardActions, CardContent, Button, FormGroup } from '@mui/material';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  heading: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

const AddReportEmailSchema = Yup.object().shape({
  reportEmail: Yup.string().email('Must be a valid email').required('Please enter a report email'),
});

const AddReportEmailDialog = ({ handleToggleDialog, submitDialog }) => {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <Formik
        initialValues={{ reportEmail: '' }}
        onSubmit={submitDialog}
        validationSchema={AddReportEmailSchema}
        mode="onChange"
      >
        {({ dirty, isValid }) => (
          <Form>
            <CardContent>
              <FormGroup className={classes.section}>
                <Field
                  component={TextField}
                  name="reportEmail"
                  label="Email"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  required
                />
              </FormGroup>
            </CardContent>
            <CardActions className={classes.cardActions}>
              <Button onClick={handleToggleDialog}>Cancel</Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={!(isValid && dirty)}
              >
                Add email
              </Button>
            </CardActions>
          </Form>
        )}
      </Formik>
    </Card>
  );
};

export default AddReportEmailDialog;
