import { useSelector } from 'react-redux';
import { isBrandUp, isDeliveryOnly, isMultivendor, isStandard } from '../../store/venues/selectors';
import useRoles from '../../hooks/useRoles';

const Requires = ({
  role,
  notbrandup,
  notstandard,
  notMultivendor,
  notDeliveryOnly,
  isOnlyDelivery,
  standard,
  children,
}) => {
  const { isRoleAtLeast, isAdmin } = useRoles();
  const isbrandup = useSelector(isBrandUp);
  const isstandard = useSelector(isStandard);
  const typeDeliveryOnly = useSelector(isDeliveryOnly);
  const typeMultivendor = useSelector(isMultivendor);
  const passesRole = role ? isRoleAtLeast(role) : true;
  const passesBrandup = notbrandup ? !isbrandup : true;
  const passesNotStandard = !isstandard;
  const passesIsStandard = isstandard;
  if (notstandard) {
    return passesRole && passesBrandup && passesNotStandard ? children : null;
  }
  if (standard) {
    return passesRole && passesBrandup && passesIsStandard ? children : null;
  }
  if (isOnlyDelivery && !typeDeliveryOnly) {
    return null;
  }
  if (typeDeliveryOnly && notDeliveryOnly && !isAdmin()) {
    return null;
  }
  if (typeMultivendor && notMultivendor && !isAdmin()) {
    return null;
  }
  return passesRole && passesBrandup ? children : null;
};

export default Requires;
