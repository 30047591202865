import { createSlice } from '@reduxjs/toolkit';
import {
  asyncInitialState as initialState,
  createAsyncAction,
  createAsyncReducerHandlers,
} from '../utils/async';
import {
  fetchSalesAreas as fetchSalesAreasApi,
  fetchSalesArea as fetchSalesAreaApi,
  createSalesArea as createSalesAreaApi,
  updateSalesArea as updateSalesAreaApi,
  deleteSalesArea as deleteSalesAreaApi,
} from '../../api/salesAreas';

export const fetchSalesAreas = createAsyncAction('fetch-salesAreas', fetchSalesAreasApi);

export const fetchSalesArea = createAsyncAction('fetch-salesArea', fetchSalesAreaApi);

export const deleteSalesArea = createAsyncAction('delete-salesArea', deleteSalesAreaApi);

export const createSalesArea = createAsyncAction('create-salesArea', createSalesAreaApi);

export const updateSalesArea = createAsyncAction('update-salesArea', updateSalesAreaApi);

const salesAreasSlice = createSlice({
  name: 'salesAreas',
  initialState,
  reducers: {
    clearSalesAreas(state) {
      delete state.data;
    },
  },
  extraReducers: {
    ...createAsyncReducerHandlers(fetchSalesAreas),
    ...createAsyncReducerHandlers(deleteSalesArea),
  },
});

const salesAreaSlice = createSlice({
  name: 'salesArea',
  initialState,
  reducers: {
    clearSalesArea(state) {
      delete state.data;
    },
  },
  extraReducers: {
    ...createAsyncReducerHandlers(fetchSalesArea),
    ...createAsyncReducerHandlers(createSalesArea),
    ...createAsyncReducerHandlers(updateSalesArea),
  },
});
export const { clearSalesAreas } = salesAreasSlice.actions;
export const { clearSalesArea } = salesAreaSlice.actions;

export const { reducer: salesAreas } = salesAreasSlice;
export const { reducer: salesArea } = salesAreaSlice;
