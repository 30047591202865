import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import formatNumber from '../../shared/utils/formatNumber';
import PercentageStatusLozenge from '../PercentageStatusLozenge';

const useStyles = makeStyles((theme) => ({
  row: {
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  title: {
    ...theme.customFonts.small,
    color: theme.customPalette.greyDarker,
    flex: '1',
  },
  total: {
    ...theme.customFonts.small,
    color: theme.customPalette.greyDarker,
  },
  divider: {
    borderTop: '1px solid #D9E2EC',
    margin: theme.spacing(2, 0),
  },
}));

const ReportsBreakdown = ({ data, field, prefix = '£' }) => {
  const classes = useStyles();
  const currSalesChannels = data.current[field].salesChannels || [];
  const prevSalesChannels = data.previous[field].salesChannels || [];

  const salesChannelsData =
    currSalesChannels &&
    prevSalesChannels &&
    currSalesChannels.map((channel) => ({
      title: channel.name,
      total: channel.total,
      current: channel.total,
      previous: prevSalesChannels.find((prevChannel) => prevChannel.name === channel.name)?.total,
    }));

  return (
    <>
      <div className={classes.divider} />
      {salesChannelsData &&
        salesChannelsData.map(({ title, total, current, previous }) =>
          total ? (
            <Box key={title} display="flex" className={classes.row}>
              <Typography className={classes.title}>{title}</Typography>
              <Typography className={classes.total}>
                {prefix}
                {formatNumber(total, 2)}
              </Typography>
              <PercentageStatusLozenge current={current} previous={previous} />
            </Box>
          ) : null,
        )}
    </>
  );
};
export default ReportsBreakdown;
