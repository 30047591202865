import React, { useEffect, useState } from 'react';
import { clone } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { FiPlus } from 'react-icons/fi';

import { makeStyles } from '@mui/styles';
import { Box, Button, Grid, Typography } from '@mui/material';

import { getErrorMessage } from '../../../shared/utils/errors';
import { useNotifications } from '../../../shared/contexts/Notifications/useNotifications';

import {
  fetchBrandsMenuItem,
  clearBrandsMenuItem,
  updateBrandsMenuItem,
} from '../../../store/brands/brandsMenuItems';
import { fetchBrandsModifiers, createBrandsModifier } from '../../../store/brands/brandsModifiers';
import { getBrandsMenuItemState } from '../../../store/brands/brandsMenuItems/selectors';
import { getBrandsModifiersState } from '../../../store/brands/brandsModifiers/selectors';

import Page from '../../../components/Page';
import withVenue from '../../../hoc/withVenue';
import shouldLoad from '../../../shared/utils/shouldLoad';
import CustomDialog from '../../../components/CustomDialog';
import BrandsAddModifiers from '../../../components/Brands/BrandsAddModifiers';
import BrandsModifierForm from '../../../components/Brands/BrandsModifierForm';

const useStyles = makeStyles(() => ({
  heading: {
    marginBottom: '20px',
  },
}));

const useQuery = () => new URLSearchParams(useLocation().search);

const attributes = {
  1: {
    prop: 'modifiers',
    title: 'Add to list of modifiers',
    description: 'e.g. list of optional pizza toppings or a list of mixers',
  },
};

const ModifierAttributes = () => {
  const { menuItemId } = useParams();
  const classes = useStyles();
  const history = useHistory();
  const { showErrorNotification, showSuccessNotification } = useNotifications();
  const dispatch = useDispatch();
  const query = useQuery();

  const attribute = query.get('attr') || '1';

  const menuItemState = useSelector(getBrandsMenuItemState);
  const modifiersState = useSelector(getBrandsModifiersState);
  const { loading: loadingItem, data: menuItem, error: errorItem } = menuItemState;
  const { loading: loadingModifiers, data: modifiers, error: errorModifiers } = modifiersState;
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleOnSave = async (values) => {
    if (values.length > 10) {
      showErrorNotification('No more than 4 modifiers per item');
      return;
    }
    try {
      await dispatch(
        updateBrandsMenuItem({
          menuItemId,
          values: { modifiers: values },
        }),
      );
      showSuccessNotification('Item has been updated successfully');
      history.push(`/brand-menu-management/item/${menuItemId}`);
    } catch (error) {
      showErrorNotification(getErrorMessage(error));
    }
  };

  const handleOnSubmit = async (values) => {
    const submitValues = clone(values);
    submitValues.modifierItems = submitValues.modifierItems.map((item) => ({
      itemId: item.itemId,
      itemPrice: item.itemPrice,
    }));
    try {
      await dispatch(createBrandsModifier(submitValues));
      showSuccessNotification('Modifier has been added successfully');
      setIsDialogOpen(false);
      dispatch(fetchBrandsModifiers());
    } catch (e) {
      showErrorNotification(getErrorMessage(e));
      setIsDialogOpen(false);
    }
  };

  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const ModifierOptionForm = () => (
    <BrandsModifierForm
      formAction="create"
      onSubmit={handleOnSubmit}
      onCancel={handleCloseDialog}
    />
  );

  const dialogTitle = 'Create new modifier';

  useEffect(() => {
    if (shouldLoad(modifiersState)) dispatch(fetchBrandsModifiers());
    if (shouldLoad(menuItemState)) dispatch(fetchBrandsMenuItem(menuItemId));
  }, [menuItemId, dispatch, modifiersState, menuItemState]);

  return (
    <Page loading={loadingItem || loadingModifiers} error={errorItem || errorModifiers}>
      {menuItem && modifiers && (
        <>
          <header>
            <Box className={classes.heading}>
              <Typography variant="h2" component="h1">
                {attributes[attribute].title}
              </Typography>
              <Typography paragraph>{attributes[attribute].description}</Typography>
            </Box>
          </header>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              {!!modifiers.length && <BrandsAddModifiers onSave={handleOnSave} />}
            </Grid>
          </Grid>
          <>
            <Typography style={{ marginBottom: '20px' }}>
              You must first create modifiers before they can be added here
            </Typography>
          </>
          <Button
            variant="contained"
            color="primary"
            startIcon={<FiPlus />}
            onClick={handleOpenDialog}
          >
            Create &amp; add Modifiers
          </Button>
          <CustomDialog
            isDialogOpen={isDialogOpen}
            handleCloseDialog={handleCloseDialog}
            title={dialogTitle}
          >
            <ModifierOptionForm />
          </CustomDialog>
        </>
      )}
    </Page>
  );
};

export default withVenue(ModifierAttributes, '/brand-menu-management/items', clearBrandsMenuItem);
