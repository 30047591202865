import React, { useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import * as Yup from 'yup';

import { TextField } from 'formik-mui';
import { makeStyles } from '@mui/styles';
import { Button, Card, CardContent, DialogActions, FormGroup, Grid, MenuItem } from '@mui/material';
import BrandsModifierOptions from '../BrandsModifierOptions';
import { fetchBrands } from '../../../store/brands/brands';
import { getBrandsState } from '../../../store/brands/brands/selectors';
import shouldLoad from '../../../shared/utils/shouldLoad';
import { fetchBrandsOptions } from '../../../store/brands/brandsOptions';
import { getBrandsOptionsState } from '../../../store/brands/brandsOptions/selectors';

const useStyles = makeStyles(() => ({
  cardActions: {
    justifyContent: 'flex-end',
  },
}));

const BrandsModifierForm = ({
  modifierData,
  onSubmit,
  onReorderOptions,
  formAction,
  onCancel,
  mealDealError,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const brandsState = useSelector(getBrandsState);
  const brandsOptionsState = useSelector(getBrandsOptionsState);
  const { data: brands } = brandsState;
  const submitButtonLabel = formAction === 'create' ? 'Create' : 'Update';

  useEffect(() => {
    if (shouldLoad(brandsState)) dispatch(fetchBrands());
    if (shouldLoad(brandsOptionsState)) dispatch(fetchBrandsOptions());
  }, [dispatch, brandsState, brandsOptionsState]);

  const {
    modifierName,
    label,
    brandName,
    modifierItems,
    minSelections,
    maxSelections,
    readonly = false,
  } = modifierData || {};

  const initialValues = {
    modifierName: modifierName || '',
    label: label || '',
    brandName: brandName || '',
    brandId: brands.find((b) => b.name === brandName)?.brandId || '',
    modifierItems:
      modifierItems?.map((modItem) => ({
        ...modItem,
        multiMax: modItem.multiMax || 1,
      })) || [],
    minSelections: minSelections || 0,
    maxSelections: maxSelections || 1,
  };

  const ModifierSchema = Yup.object().shape({
    modifierName: Yup.string().min(3, 'Too Short!').required('Please enter a name'),
    label: Yup.string().min(3, 'Too Short!').required('Please enter a label'),
    brandName: Yup.string().required('Please choose a brand'),
    maxSelections: Yup.number()
      .when(['minSelections'], {
        is: 0,
        then: Yup.number().min(1),
        otherwise: Yup.number().min(Yup.ref('minSelections')),
      })
      .lessThanItems(),

    minSelections: Yup.number().min(0).lessThanItems().required('Please enter a minimum'),
    modifierItems: Yup.array()
      .of(
        Yup.object().shape({
          itemId: Yup.string().required(),
          itemName: Yup.string(),
          itemPrice: Yup.number()
            .min(0, 'Price must be greater than 0')
            .typeError('Price must be a number')
            .required('Please choose a price for this ingredient'),
          multiMax: Yup.number().lessThanMaxSelections(),
        }),
      )
      .min(1, 'Please choose at least one ingredient'),
  });

  return (
    <Card className={classes.card}>
      <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={ModifierSchema}>
        {({ dirty, values, setFieldValue, isValid, resetForm }) => (
          <>
            <Form>
              <CardContent>
                <FormGroup>
                  <section>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        {brands && (
                          <FormGroup>
                            <Field
                              component={TextField}
                              select
                              fullWidth
                              name="brandName"
                              label="Select Brand"
                              variant="outlined"
                              disabled={readonly || formAction === 'update'}
                              margin="normal"
                              required
                              onChange={({ target }) => {
                                const brand = brands.find((b) => b.name === target.value);
                                setFieldValue('brandName', brand.name);
                                setFieldValue('brandId', brand.brandId);
                              }}
                            >
                              {brands.map((brand) => (
                                <MenuItem value={brand.name} key={brand.brandId}>
                                  {brand.name}
                                </MenuItem>
                              ))}
                            </Field>
                          </FormGroup>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Field
                          component={TextField}
                          name="modifierName"
                          label="Internal Name"
                          variant="outlined"
                          fullWidth
                          required
                          margin="normal"
                          disabled={readonly}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Field
                          component={TextField}
                          name="label"
                          label="External Label"
                          variant="outlined"
                          fullWidth
                          required
                          margin="normal"
                          disabled={readonly}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Field
                          component={TextField}
                          type="number"
                          name="minSelections"
                          label="Minimum number of selections"
                          variant="outlined"
                          fullWidth
                          required
                          margin="normal"
                          disabled={readonly}
                          inputProps={{ step: '1', min: 0 }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Field
                          component={TextField}
                          type="number"
                          name="maxSelections"
                          label="Maximum number of selections"
                          variant="outlined"
                          fullWidth
                          required
                          margin="normal"
                          disabled={readonly}
                          inputProps={{ step: '1', min: 1 }}
                        />
                      </Grid>
                    </Grid>
                  </section>
                </FormGroup>
                <FormGroup className={classes.section} disabled>
                  <BrandsModifierOptions
                    readonly={readonly || !values.brandId}
                    setFieldValue={setFieldValue}
                    itemOptions={values.modifierItems}
                    allowReordering={!readonly && values.modifierItems.length > 1}
                    onReorder={onReorderOptions}
                    brandId={values.brandId}
                    mealDealError={mealDealError}
                  />
                </FormGroup>
              </CardContent>
              <DialogActions className={classes.dialogActions}>
                <Button
                  onClick={() => {
                    resetForm();
                    onCancel();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={readonly || !isValid || !dirty}
                >
                  {submitButtonLabel}
                </Button>
              </DialogActions>
            </Form>
          </>
        )}
      </Formik>
    </Card>
  );
};

export default BrandsModifierForm;
