import { makeStyles } from '@mui/styles';
import React from 'react';

const useStyles = makeStyles(() => ({
  link: {
    textDecoration: 'underline',
    cursor: 'pointer',
    color: '#1870ED',
    '&:hover': {
      textDecoration: 'none',
    },
    '&:active': {
      color: '#0E55BA',
    },
    '&:visited': {
      color: '#333333',
    },
  },
}));

const TableButton = ({ children, onClick }) => {
  const classes = useStyles();
  return (
    <span role="button" tabIndex={0} className={classes.link} onClick={onClick} onKeyUp={onClick}>
      {children}
    </span>
  );
};

export default TableButton;
