import { createSlice } from '@reduxjs/toolkit';
import {
  asyncInitialState as initialState,
  createAsyncAction,
  createAsyncReducerHandlers,
} from '../utils/async';
import {
  fetchCustomer as fetchCustomerApi,
  updateCustomer as updateCustomerApi,
  createCustomer as createCustomerApi,
} from '../../api/customer';

export const fetchCustomer = createAsyncAction('fetch-customer', fetchCustomerApi);

export const updateCustomer = createAsyncAction('update-customer', updateCustomerApi);

export const createCustomer = createAsyncAction('create-customer', createCustomerApi);

const customerSlice = createSlice({
  name: 'customer',
  initialState,
  reducers: {},
  extraReducers: {
    ...createAsyncReducerHandlers(fetchCustomer),
    ...createAsyncReducerHandlers(updateCustomer),
    ...createAsyncReducerHandlers(createCustomer),
  },
});

export const { reducer: customer } = customerSlice;
