import { Button, DialogActions, MenuItem, Select } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  heading: {
    ...theme.customFonts.largeBold,
  },
  container: {
    padding: theme.spacing(2),
  },
}));

function CopyStaffForm({ cancel, staff, setFieldValue, deviceCapabilities, currentStaffId }) {
  const classes = useStyles();
  const [selectedStaff, setSelectedStaff] = React.useState(null);

  const copyableStaff = staff.filter(
    (s) => s.capabilities.length > 0 && s.staffId !== currentStaffId,
  );

  const handleChangeStaff = (event) => {
    setSelectedStaff(event.target.value);
  };

  const copyStaff = (selectedStaffId) => {
    if (!selectedStaffId) return;
    const staffToCopy = staff.find((s) => s.staffId === selectedStaffId);
    const newCapabilitiesArray = deviceCapabilities.reduce(
      (obj, cap) => ({ ...obj, [cap]: staffToCopy.capabilities.includes(cap) }),
      {},
    );
    setFieldValue('capabilityArray', newCapabilitiesArray);
  };

  const selectStaff = () => {
    copyStaff(selectedStaff || staff[0].staffId);
    cancel();
  };

  return (
    staff && (
      <div className={classes.container}>
        <Select
          variant="outlined"
          name="Staff member"
          size="small"
          value={selectedStaff || copyableStaff[0].staffId}
          onChange={handleChangeStaff}
          fullWidth
        >
          {copyableStaff?.map(
            ({ staffId, name, description, capabilities }) =>
              capabilities && (
                <MenuItem key={staffId} value={staffId}>
                  {name} · {description}
                </MenuItem>
              ),
          )}
        </Select>
        <DialogActions>
          <Button color="primary" variant="outlined" onClick={cancel}>
            Cancel
          </Button>
          <Button color="primary" variant="contained" onClick={selectStaff}>
            Apply
          </Button>
        </DialogActions>
      </div>
    )
  );
}

export default CopyStaffForm;
