import { createSlice } from '@reduxjs/toolkit';
import {
  createAsyncAction,
  asyncInitialState as initialState,
  createAsyncReducerHandlers,
} from '../utils/async';
import { get } from '../../api/httpRequest';

const fetchSessionsScoreApi = async () => {
  const response = await get('/reports/score');

  return response.data;
};

export const fetchSessionsScore = createAsyncAction('fetch-sessions-score', fetchSessionsScoreApi);

const sessionsScoreSlice = createSlice({
  name: 'sessionsScore',
  initialState,
  reducers: {
    clearSessionsScore(state) {
      delete state.data;
    },
  },
  extraReducers: {
    ...createAsyncReducerHandlers(fetchSessionsScore),
  },
});

export const { reducer: sessionsScore } = sessionsScoreSlice;
export const { clearSessionsScore } = sessionsScoreSlice.actions;
