import React from 'react';
import { Helmet } from 'react-helmet-async';

const AppHead = () => {
  const id = process.env.REACT_APP_BEAMER_PRODUCT_ID;

  window.beamer_config = {
    product_id: id,
    selector: '#beamerButton',
    button: false,
  };

  return (
    <>
      <Helmet>
        <script async>
          {`(function (w, d, s, l, i) {
        w[l] = w[l] || []; w[l].push({
          'gtm.start':
            new Date().getTime(), event: 'gtm.js'
        }); var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
            'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
      })(window, document, 'script', 'dataLayer', 'GTM-WZWGF5C4');`}
        </script>
        <script
          type="text/javascript"
          src="https://app.getbeamer.com/js/beamer-embed.js"
          defer="defer"
        />
        <link
          rel="stylesheet"
          // eslint-disable-next-line max-len
          href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200"
        />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,0,0"
        />
      </Helmet>
    </>
  );
};

export default AppHead;
