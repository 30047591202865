import { createSelector } from '@reduxjs/toolkit';
import { getBuilderMenuVariationId } from '../builderMenu/builderMenus/selectors';

export const getBrandsVenueAssociationsState = (state) => state.brandsVenueAssociations;

export const getBrandsVenueAssociationsForVariation = createSelector(
  getBrandsVenueAssociationsState,
  getBuilderMenuVariationId,
  (venueAssociations) => {
    const venues = [];

    if (!venueAssociations.data) return null;

    if (Array.isArray(venueAssociations.data)) {
      venueAssociations?.data?.forEach((association) => association.forEach((a) => venues.push(a)));
    }

    return venues;
  },
);
