import { createAction, createSlice } from '@reduxjs/toolkit';
import {
  asyncInitialState as initialState,
  createAsyncAction,
  createAsyncReducerHandlers,
} from '../utils/async';
import {
  fetchVenues as fetchVenuesApi,
  fetchVenue as fetchVenueApi,
  updateVenue as updateVenueApi,
  fetchVenueSettings as fetchVenueSettingsApi,
  updateVenueSettings as updateVenueSettingsApi,
  updateSeveralSettings as updateSeveralSettingsApi,
  printVenueQrcode as printVenueQrcodeApi,
  fetchVenuePriorities as fetchVenuePrioritiessApi,
  updateVenuePriorities as updateVenuePrioritiesApi,
  fetchVenueAdditionalCharges as fetchVenueAdditionalChargesApi,
  fetchVenueHideGMVSettings as fetchVenueHideGMVSettingsApi,
  updateVenueAdditionalCharges as updateVenueAdditionalChargesApi,
  createVenuePriority as createVenuePriorityApi,
  updateVenuePriority as updateVenuePriorityApi,
  deleteVenuePriority as deleteVenuePriorityApi,
} from '../../api/venues';

export const getActiveVenue = (venueList) => {
  if (!venueList || !venueList.length) return null;

  const sessionVenueId = localStorage.getItem('venue_id');

  return venueList.find((venue) => venue.venueId === sessionVenueId) || venueList[0];
};
export const fetchVenue = createAsyncAction('fetch-venue', fetchVenueApi);
export const printVenueQrcode = createAsyncAction('print-venue-qrcode', printVenueQrcodeApi);
export const updateVenueSettings = createAsyncAction(
  'fetch-venues-settings',
  updateVenueSettingsApi,
);

export const updateSeveralSettings = createAsyncAction(
  'update-venue-several-settings',
  updateSeveralSettingsApi,
);

export const fetchVenueAdditionalCharges = createAsyncAction(
  'fetch-venue-additional-charges',
  fetchVenueAdditionalChargesApi,
);

export const fetchVenueHideGMVSetting = createAsyncAction(
  'fetch-venue-hide-gmv-setting',
  fetchVenueHideGMVSettingsApi,
);

export const updateVenueAdditionalCharges = createAsyncAction(
  'update-venue-additional-charges',
  updateVenueAdditionalChargesApi,
);

export const setVenue = createAction('set-venue', (venue) => {
  const { venueId } = venue || {};
  if (venueId) localStorage.setItem('venue_id', venueId);
  return { payload: venue };
});

export const fetchVenues = createAsyncAction('fetch-venues', async (dispatch) => {
  const venues = await fetchVenuesApi();
  dispatch(setVenue(getActiveVenue(venues)));
  return venues;
});

export const fetchVenueSettings = createAsyncAction('fetch-venues-settings', async () => {
  const venueSettings = await fetchVenueSettingsApi();
  return venueSettings;
});

export const fetchVenuePriorities = createAsyncAction(
  'fetch-venues-priorities',
  fetchVenuePrioritiessApi,
);

export const updateVenue = createAsyncAction('update-venue', updateVenueApi);

export const updateVenuePriorities = createAsyncAction(
  'update-venues-priorities',
  updateVenuePrioritiesApi,
);

export const createVenuePriority = createAsyncAction(
  'create-venues-priority',
  createVenuePriorityApi,
);

export const updateVenuePriority = createAsyncAction(
  'update-venues-priority',
  updateVenuePriorityApi,
);

export const deleteVenuePriority = createAsyncAction(
  'delete-venues-priority',
  deleteVenuePriorityApi,
);

const venuesSlice = createSlice({
  name: 'venues',
  initialState,
  reducers: {},
  extraReducers: createAsyncReducerHandlers(fetchVenues),
});

const venuesSettingsSlice = createSlice({
  name: 'venueSettings',
  initialState,
  reducers: {},
  extraReducers: {
    ...createAsyncReducerHandlers(fetchVenueSettings),
    ...createAsyncReducerHandlers(updateVenueSettings),
    ...createAsyncReducerHandlers(updateSeveralSettings),
  },
});

const venuePrioritiesSlice = createSlice({
  name: 'venuePriorities',
  initialState,
  reducers: {
    clearVenuePriorities(state) {
      delete state.data;
    },
  },
  extraReducers: {
    ...createAsyncReducerHandlers(fetchVenuePriorities),
    ...createAsyncReducerHandlers(updateVenuePriorities),
  },
});

const venuePrioritySlice = createSlice({
  name: 'venuePriority',
  initialState,
  reducers: {
    clearVenuePriority(state) {
      delete state.data;
    },
  },
  extraReducers: {
    ...createAsyncReducerHandlers(createVenuePriority),
    ...createAsyncReducerHandlers(updateVenuePriority),
    ...createAsyncReducerHandlers(deleteVenuePriority),
  },
});

const venueAdditionalChargesSlice = createSlice({
  name: 'venueAdditionalCharges',
  initialState,
  reducers: {
    clearVenueAdditionalCharges(state) {
      delete state.data;
    },
  },
  extraReducers: {
    ...createAsyncReducerHandlers(fetchVenueAdditionalCharges),
    ...createAsyncReducerHandlers(updateVenueAdditionalCharges),
  },
});

const venueHideGMVSettingSlice = createSlice({
  name: 'venueHideGMVSetting',
  initialState,
  reducers: {
    clearVenueHideGMVSetting(state) {
      delete state.data;
    },
  },
  extraReducers: createAsyncReducerHandlers(fetchVenueHideGMVSetting),
});

const venueSlice = createSlice({
  name: 'venue',
  initialState,
  reducers: {
    clearVenue(state) {
      delete state.data;
    },
  },
  extraReducers: {
    [setVenue]: (state, action) => ({
      ...state,
      data: action.payload,
    }),
    ...createAsyncReducerHandlers(fetchVenue),
    ...createAsyncReducerHandlers(updateVenue),
  },
});

export const { clearVenuePriorities } = venuePrioritiesSlice.actions;
export const { clearVenuePriority } = venuePrioritySlice.actions;
export const { clearVenueAdditionalCharges } = venueAdditionalChargesSlice.actions;
export const { clearVenueHideGMVSetting } = venueHideGMVSettingSlice.actions;
export const { clearVenue } = venueSlice.actions;

export const { reducer: venueAdditionalCharges } = venueAdditionalChargesSlice;
export const { reducer: venueHideGMVSetting } = venueHideGMVSettingSlice;
export const { reducer: venueSettings } = venuesSettingsSlice;
export const { reducer: venuePriorities } = venuePrioritiesSlice;
export const { reducer: venuePriority } = venuePrioritySlice;
export const { reducer: venues } = venuesSlice;
export const { reducer: venue } = venueSlice;
