import React from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import { TextField } from 'formik-mui';
import { makeStyles } from '@mui/styles';
import { Button, Card, CardContent, DialogActions, FormGroup, MenuItem } from '@mui/material';

const useStyles = makeStyles(() => ({
  cardActions: {
    justifyContent: 'flex-end',
  },
}));

const CategoryForm = ({ formAction, onSubmit, onCancel, categoryData }) => {
  const classes = useStyles();
  const categoryTypes = ['DRINK', 'FOOD'];
  const submitButtonLabel = formAction === 'create' ? 'Create' : 'Update';

  const { categoryName, type } = categoryData || {};

  const initialValues = {
    categoryName: categoryName || '',
    type: type || 'DRINK',
  };

  const CategorySchema = Yup.object().shape({
    categoryName: Yup.string().min(3, 'Too Short!').required('Please enter a name'),
    type: Yup.string().required('Please choose a type'),
  });

  return (
    <Card className={classes.card}>
      <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={CategorySchema}>
        {({ dirty, resetForm }) => (
          <>
            <Form>
              <CardContent>
                <section>
                  <section>
                    <FormGroup className={classes.section}>
                      <Field
                        component={TextField}
                        name="type"
                        label="Type"
                        variant="outlined"
                        select
                        fullWidth
                        margin="normal"
                        disabled={formAction !== 'create'}
                        required
                      >
                        {categoryTypes.map((categoryType) => (
                          <MenuItem value={categoryType} key={categoryType}>
                            {categoryType}
                          </MenuItem>
                        ))}
                      </Field>
                    </FormGroup>
                  </section>
                </section>
                <FormGroup>
                  <Field
                    component={TextField}
                    name="categoryName"
                    label="Category Name"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    disabled={formAction !== 'create'}
                    required
                  />
                </FormGroup>
              </CardContent>
              <DialogActions className={classes.cardActions}>
                <Button
                  onClick={() => {
                    onCancel();
                    resetForm();
                  }}
                >
                  Cancel
                </Button>
                {formAction === 'create' ? (
                  <Button type="submit" variant="contained" color="primary" disabled={!dirty}>
                    {submitButtonLabel}
                  </Button>
                ) : null}
              </DialogActions>
            </Form>
          </>
        )}
      </Formik>
    </Card>
  );
};

export default CategoryForm;
