import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Button, Typography } from '@mui/material';

import TableSkeletonLoader from '../TableSkeletonLoader';
import { useNotifications } from '../../shared/contexts/Notifications/useNotifications';
import { getImagesReviewState } from '../../store/imagesReview/selectors';
import { getErrorMessage } from '../../shared/utils/errors';
import { fetchImagesReview, postImagesReview } from '../../store/imagesReview';

const ImagesTable = () => {
  const { showErrorNotification, showSuccessNotification } = useNotifications();
  const dispatch = useDispatch();
  const { loading, data: images } = useSelector(getImagesReviewState);
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const venueIdToHighlight = query.get('venueId');
  const itemIdToHighlight = query.get('itemId');
  const small = true;
  const w = small ? 112 : 224;
  const h = small ? 84 : 168;
  if (!images) return null;

  const handleApprove = async (data) => {
    try {
      await dispatch(postImagesReview(data));
      showSuccessNotification('Image reviewed');
      await dispatch(fetchImagesReview());
    } catch (err) {
      await dispatch(fetchImagesReview());
      showErrorNotification(getErrorMessage(err));
    }
  };
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell size="small">
              <Typography variant="h3">Venue</Typography>
            </TableCell>
            <TableCell size="small">
              <Typography variant="h3">Item Id</Typography>
            </TableCell>
            <TableCell size="small">
              <Typography variant="h3">Item Name</Typography>
            </TableCell>
            <TableCell size="small">
              <Typography variant="h3">Category</Typography>
            </TableCell>
            <TableCell size="small">
              <Typography variant="h3">Image</Typography>
            </TableCell>
            <TableCell />
            <TableCell />
          </TableRow>
        </TableHead>
        {loading && <TableSkeletonLoader rows={10} cells={3} cellsSize={['small']} />}
        {!loading && images && (
          <>
            <TableBody>
              {images.map((image) => {
                const { itemId, itemName, url, venueId, category, venueName } = image;
                const key = `${venueId}-${itemId}`;
                const isHighlighted =
                  venueId === venueIdToHighlight && itemId === itemIdToHighlight;
                return (
                  <TableRow
                    key={key}
                    style={isHighlighted ? { backgroundColor: '#BCCCDC' } : null}
                    ref={
                      isHighlighted ? (el) => el && el.scrollIntoView({ behavior: 'smooth' }) : null
                    }
                  >
                    <TableCell>{venueName}</TableCell>
                    <TableCell>{itemId}</TableCell>
                    <TableCell size="small">{itemName}</TableCell>
                    <TableCell size="small">{category}</TableCell>
                    <TableCell size="small">
                      <Link underline="none" href={url} target="_blank" rel="noopener noreferrer">
                        <img
                          src={`${url}?ck=${Math.round(Math.random() * 100000)}`}
                          alt="product"
                          width={w}
                          height={h}
                        />
                      </Link>
                    </TableCell>
                    <TableCell size="small">
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          handleApprove({ itemId, venueId, approved: true });
                        }}
                      >
                        Approve
                      </Button>
                    </TableCell>
                    <TableCell size="small">
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          handleApprove({ itemId, venueId, approved: false });
                        }}
                      >
                        Decline
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </>
        )}
      </Table>
    </TableContainer>
  );
};

export default ImagesTable;
