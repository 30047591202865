import { del, get, patch, post } from './httpRequest';

export const fetchCampaigns = async () => {
  const response = await get(`/campaigns`);
  const { data } = response;
  return data;
};

export const fetchCampaign = async (campaignId) => {
  const response = await get(`/custom-campaigns/${campaignId}`);
  const { data } = response;
  return data;
};

export const acceptCampaign = async (campaignId) => {
  await post(`/campaigns/${campaignId}`);
};

export const updateCampaign = async (campaignId, values) => {
  await patch(`/custom-campaigns/${campaignId}`, {
    data: values,
  });
};

export const updateBrandupCampaign = async (campaignId, values) => {
  await patch(`/campaigns/${campaignId}`, {
    data: values,
  });
};

export const updateCampaigns = async (values) => {
  await patch(`/campaigns`, {
    data: values,
  });
};

export const createCampaign = async (values) => {
  const filteredValues = Object.keys(values).reduce((acc, key) => {
    if (values[key] !== '') {
      // eslint-disable-next-line no-param-reassign
      acc[key] = values[key];
    }
    return acc;
  }, {});

  await post(`/custom-campaigns`, {
    data: filteredValues,
  });
};

export const deleteCampaign = async (campaignId) => {
  await del(`/custom-campaigns/${campaignId}`);
};
