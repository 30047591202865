import React from 'react';

import { useParams } from 'react-router';
import withVenue from '../../hoc/withVenue';
import TabContainer from '../../components/TabContainer';
import ReportCustomersOverview from './ReportCustomersOverview';
import ReportTopCustomers from './ReportTopCustomers';

const ReportCustomers = () => {
  const { tabname } = useParams();
  return (
    <TabContainer
      titles={['Overview', 'Top customers']}
      defaultTab={tabname}
      path="/reports/customers/"
    >
      <ReportCustomersOverview />
      <ReportTopCustomers />
    </TabContainer>
  );
};

export default withVenue(ReportCustomers);
