import React from 'react';
import { Box, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import TillsImage from './tills.png';

const useStyles = makeStyles((theme) => ({
  description: {
    marginBottom: theme.spacing(2),
    textAlign: 'center',
  },
  emptyContent: {
    gap: theme.spacing(2),
    '& *': {
      alignSelf: 'center',
    },
  },
}));
const OverViewNoTills = ({ handleClick }) => {
  const classes = useStyles();
  return (
    <>
      <Box
        display="flex"
        justifyContent="center"
        flexDirection="column"
        className={classes.emptyContent}
      >
        <img src={TillsImage} alt="Tills" />
        <p>
          <strong>Tills</strong>
        </p>
        <p className={classes.description}>
          Manage floats and keep track of all your cash transactions
        </p>
        <Box>
          <Button variant="contained" color="primary" fullWidth onClick={handleClick}>
            Get started
          </Button>
        </Box>
      </Box>
    </>
  );
};
export default OverViewNoTills;
