import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { authenticateWithCallback } from '../../store/session';

const Callback = () => {
  const dispatch = useDispatch();
  const callbackHref = window.location.href;

  useEffect(() => {
    dispatch(authenticateWithCallback({ callbackHref }));
  }, [dispatch, callbackHref]);

  return <p>Please wait</p>;
};

export default Callback;
