import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import Page from '../../components/Page';
import withVenue from '../../hoc/withVenue';
import { clearStaff, createStaff, fetchAllStaff, fetchStaff } from '../../store/staff';
import EditStaffForm from '../../components/EditStaffForm';
import { useNotifications } from '../../shared/contexts/Notifications/useNotifications';
import { getErrorMessage } from '../../shared/utils/errors';
import BackArrow from '../../components/BackArrow';

const useStyles = makeStyles(() => ({
  heading: {
    margin: '15px 0',
  },
}));

const AddStaff = ({ redirect }) => {
  const { staffId } = useParams();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { showErrorNotification, showSuccessNotification } = useNotifications();

  useEffect(() => {
    dispatch(fetchStaff({ staffId }));
  }, [dispatch, staffId]);

  const onSubmit = async (updatedStaff) => {
    const newCapabilities = Object.entries(updatedStaff.capabilityArray).reduce(
      (newCapabilityList, capability) =>
        capability[1] ? [...newCapabilityList, capability[0]] : newCapabilityList,
      [],
    );
    const transformedUpdatedStaff = {
      ...updatedStaff,
      capabilities: newCapabilities,
    };
    try {
      await dispatch(createStaff(transformedUpdatedStaff));
      showSuccessNotification('Staff member has been created successfully');
      await dispatch(fetchAllStaff());
      redirect();
    } catch (localError) {
      showErrorNotification(getErrorMessage(localError));
    }
  };

  return (
    <Page>
      <header>
        <BackArrow redirect={redirect} text="Staff" />
        <Typography className={classes.heading} variant="h2" component="h1">
          Add Staff
        </Typography>
      </header>
      <EditStaffForm onSubmit={onSubmit} onCancel={redirect} />
    </Page>
  );
};

export default withVenue(AddStaff, '/staff', clearStaff);
