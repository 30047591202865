import React, { useEffect, useState } from 'react';
import {
  Box,
  CardContent,
  Grid,
  Paper,
  Typography,
  InputAdornment,
  Divider,
  MenuItem,
  Alert,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { Field, Form, Formik } from 'formik';
import { TextField, Switch } from 'formik-mui';
import * as Yup from 'yup';
import { makeStyles } from '@mui/styles';
import { useSelector, useDispatch } from 'react-redux';
import Page from '../../components/Page';
import BackArrow from '../../components/BackArrow';
import UniversalSave from '../../components/UniversalSave';
import { fetchVenueFees, updateVenueFees } from '../../store/venueFees';
import { fetchBrands } from '../../store/brands/brands';
import { fetchBrandsForVenue } from '../../store/brands/venueBrands';
import getVenueBrandsState from '../../store/brands/venueBrands/selectors';
import shouldLoad from '../../shared/utils/shouldLoad';
import useVenueFees from '../../hooks/useVenueFees';
import { useNotifications } from '../../shared/contexts/Notifications/useNotifications';
import { getErrorMessage } from '../../shared/utils/errors';
import ResetCircle from '../../components/ResetCircle';
import useRoles from '../../hooks/useRoles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    textWrap: 'wrap',
    padding: '6px',
    ...theme.customFonts.xSmall,
  },
  title: {
    ...theme.customFonts.largeBold,
    marginBottom: '16px',
  },
  subtitle: {
    ...theme.customFonts.mediumBold,
  },
  cardTitle: {
    ...theme.customFonts.smallBold,
  },
  context: {
    ...theme.customFonts.small,
    color: '#5A7296',
  },
  divider: {
    margin: '16px 0',
  },
  cardContent: {
    padding: 0,
  },
  container: {
    padding: '4px 0',
    minWidth: '405px',
  },
  columnTitles: {
    width: '6rem',
    padding: '6px',
    ...theme.customFonts.small,
    [theme.breakpoints.up('lg')]: {
      width: '7rem',
    },
  },
  defaultFieldsTitle: {
    display: 'flex',
    alignItems: 'center',
    textWrap: 'wrap',
    backgroundColor: theme.sessionsScorePalette.nonCompliantLightGrey,
    padding: '6px',
    borderRadius: '8px 0 0 8px',
    ...theme.customFonts.xSmall,
  },
  defaultFields: {
    display: 'flex',
    alignItems: 'center',
    textWrap: 'wrap',
    backgroundColor: theme.sessionsScorePalette.nonCompliantLightGrey,
    padding: '6px',
    ...theme.customFonts.medium,
  },
  inputFields: {
    padding: '6px',
    width: '6rem',
    ...theme.customFonts.medium,
    [theme.breakpoints.up('lg')]: {
      width: '7rem',
    },
    '& input': {
      paddingLeft: '13px',
    },
  },
  fullWidthInputFields: {
    padding: '6px',
    ...theme.customFonts.medium,
    '& input': {
      paddingLeft: '13px',
    },
  },
  moneyInputFields: {
    padding: '6px',
    width: '6rem',
    ...theme.customFonts.medium,
    [theme.breakpoints.up('lg')]: {
      width: '7rem',
    },
    '& input': {
      paddingRight: 0,
    },
  },
  customInputFields: {
    padding: '6px',
    width: '6rem',
    ...theme.customFonts.medium,
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#181C43',
      },
    },
    [theme.breakpoints.up('lg')]: {
      width: '7rem',
    },
  },
  addIconWrapper: {
    padding: '16px 0',
  },
  addIcon: {
    color: '#5A7296',
    fontSize: 'large',
  },
  boxScroll: {
    overflowX: 'auto',
  },
}));

const VenueFees = () => {
  const { isSuperAdmin } = useRoles();
  const blockAdminEditing = !isSuperAdmin();
  const classes = useStyles();
  const dispatch = useDispatch();
  const venueBrandsState = useSelector(getVenueBrandsState);
  const {
    loading: venueBrandsLoading,
    data: venueBrandsData,
    error: venueBrandsError,
  } = venueBrandsState;
  const { showSuccessNotification, showErrorNotification } = useNotifications();
  const {
    brandsLoading,
    brandsError,
    venueFeesState,
    venueFeesLoading,
    venueFeesError,
    brandsState,
    initialFees,
    schemaFees,
  } = useVenueFees();

  const [venueOwnedItems, setVenueOwnedItems] = useState(false);
  const [franchiseMarketingFee, setFranchiseMarketingFee] = useState(false);

  const { active } = venueFeesState?.data?.inhouseSessionsVenuesFees || {};
  const { defaultFee } = venueFeesState?.data?.franchiseMarketingFee || {};

  const venueBrands = venueBrandsData?.filter((venueBrand) => 'brandId' in venueBrand);

  const handleOnSubmit = async (values) => {
    try {
      const venuePrefixes = venueBrands.map((venueBrand) => venueBrand.brandPrefix);
      await dispatch(updateVenueFees(values, venuePrefixes));
      showSuccessNotification(`Venue fees updated successfully`);
    } catch (error) {
      showErrorNotification(getErrorMessage(error));
    }
  };

  const initialValues = {
    ...initialFees?.reduce((acc, fee) => ({ ...acc, ...fee }), {}),
  };

  const VenueFeesSchema = Yup.object().shape({
    ...schemaFees?.reduce((acc, fee) => ({ ...acc, ...fee }), {}),
  });

  const displayResetCircle = (brandFieldValue, initialDefaultValue, setFieldValue) =>
    initialValues[brandFieldValue] !== initialValues[initialDefaultValue] && (
      <ResetCircle
        brandFieldValue={brandFieldValue}
        initialDefaultValue={initialValues[initialDefaultValue]}
        setFieldValue={setFieldValue}
        disabled={blockAdminEditing}
        altText="Reset fee"
        tooltipTitle="Reset to default fee"
      />
    );

  const getBrandFieldClassName = (name, defaultValue) =>
    initialValues[name] !== initialValues[defaultValue]
      ? classes.customInputFields
      : classes.inputFields;

  const updateDefaultValues = (
    e,
    handleChange,
    setFieldValue,
    brandFieldValue,
    initialDefaultValue,
  ) => {
    const newValue = Number(e.target.value);
    handleChange(e);

    return (
      initialValues[brandFieldValue] === initialValues[initialDefaultValue] &&
      setFieldValue(brandFieldValue, newValue)
    );
  };

  useEffect(() => {
    if (shouldLoad(venueFeesState)) dispatch(fetchVenueFees());
    if (shouldLoad(brandsState)) dispatch(fetchBrands());
    if (shouldLoad(venueBrandsState)) dispatch(fetchBrandsForVenue());
  }, [brandsState, dispatch, venueBrandsState, venueFeesState]);

  useEffect(() => {
    if (active !== undefined) {
      setVenueOwnedItems(active);
    }
    if (defaultFee > 0) {
      setFranchiseMarketingFee(true);
    }
  }, [active, defaultFee]);

  return (
    <Page
      fullWidth
      error={venueBrandsError || brandsError || venueFeesError}
      loading={venueBrandsLoading || brandsLoading || venueFeesLoading}
    >
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Box sx={{ width: { xs: 1, md: 0.75 } }}>
          <BackArrow text="Admin Area Settings" to="/admin" />
          <p className={classes.title}>Fees</p>
          {blockAdminEditing && (
            <Alert severity="info" sx={{ mb: 2 }}>
              Only SuperAdmins can make changes
            </Alert>
          )}
          <Formik
            initialValues={initialValues}
            enableReinitialize
            onSubmit={handleOnSubmit}
            validationSchema={VenueFeesSchema}
          >
            {({ values, isValid, errors, dirty, resetForm, setFieldValue, handleChange }) => (
              <>
                <UniversalSave
                  isValid={isValid}
                  errors={errors}
                  dirty={dirty}
                  onSave={() => handleOnSubmit(values)}
                  onDiscard={() => {
                    setVenueOwnedItems(active);
                    setFieldValue('inhouseVenueFeeActive', active);
                    resetForm();
                  }}
                />
                <Form>
                  <CardContent className={classes.cardContent}>
                    <Grid container>
                      <Grid item xs={12} md={4}>
                        <Typography className={classes.subtitle}>Payment</Typography>
                        <Typography className={classes.context}>
                          Amount taken per payment
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={8}>
                        <Paper sx={{ p: 2, borderRadius: 4 }}>
                          <Box className={classes.boxScroll}>
                            <Grid container className={classes.container}>
                              <Grid item xs={3} className={classes.columnTitles} />
                              <Grid item className={classes.columnTitles}>
                                Percent
                              </Grid>
                              <Grid item sx={{ padding: '0 9px' }} />
                              <Grid item xs={3} className={classes.columnTitles}>
                                Fixed
                              </Grid>
                            </Grid>
                            <Grid container className={classes.container}>
                              <Grid item xs={3} className={classes.root}>
                                Visa, Mastercard
                              </Grid>
                              <Grid item>
                                <Field
                                  className={classes.inputFields}
                                  name="transactionsCardPercent"
                                  component={TextField}
                                  size="small"
                                  variant="outlined"
                                  type="number"
                                  inputMode="numeric"
                                  InputProps={{
                                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                  }}
                                  disabled={blockAdminEditing}
                                  value={values.transactionsCardPercent}
                                />
                              </Grid>
                              <Grid item className={classes.addIconWrapper}>
                                <AddIcon className={classes.addIcon} />
                              </Grid>
                              <Grid item>
                                <Field
                                  className={classes.moneyInputFields}
                                  name="transactionsCardFixed"
                                  component={TextField}
                                  size="small"
                                  variant="outlined"
                                  type="number"
                                  inputMode="numeric"
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">£</InputAdornment>
                                    ),
                                  }}
                                  disabled={blockAdminEditing}
                                  value={values.transactionsCardFixed}
                                />
                              </Grid>
                            </Grid>
                            <Grid container className={classes.container}>
                              <Grid item xs={3} className={classes.root}>
                                Amex, International
                              </Grid>
                              <Grid item>
                                <Field
                                  className={classes.inputFields}
                                  name="transactionsAmexInternationalPercent"
                                  component={TextField}
                                  size="small"
                                  variant="outlined"
                                  type="number"
                                  inputMode="numeric"
                                  InputProps={{
                                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                  }}
                                  disabled={blockAdminEditing}
                                  value={values.transactionsAmexInternationalPercent}
                                />
                              </Grid>
                              <Grid item className={classes.addIconWrapper}>
                                <AddIcon className={classes.addIcon} />
                              </Grid>
                              <Grid item>
                                <Field
                                  className={classes.moneyInputFields}
                                  name="transactionsAmexInternationalFixed"
                                  component={TextField}
                                  size="small"
                                  variant="outlined"
                                  type="number"
                                  inputMode="numeric"
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">£</InputAdornment>
                                    ),
                                  }}
                                  disabled={blockAdminEditing}
                                  value={values.transactionsAmexInternationalFixed}
                                />
                              </Grid>
                            </Grid>
                            <Grid container className={classes.container}>
                              <Grid item xs={3} className={classes.root}>
                                Cash & external
                              </Grid>
                              <Grid item>
                                <Field
                                  className={classes.inputFields}
                                  name="transactionsCashAndExternalPercent"
                                  component={TextField}
                                  size="small"
                                  variant="outlined"
                                  type="number"
                                  inputMode="numeric"
                                  InputProps={{
                                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                  }}
                                  disabled={blockAdminEditing}
                                  value={values.transactionsCashAndExternalPercent}
                                />
                              </Grid>
                              <Grid item className={classes.addIconWrapper}>
                                <AddIcon className={classes.addIcon} />
                              </Grid>
                              <Grid item>
                                <Field
                                  className={classes.moneyInputFields}
                                  name="transactionsCashAndExternalFixed"
                                  component={TextField}
                                  size="small"
                                  variant="outlined"
                                  type="number"
                                  inputMode="numeric"
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">£</InputAdornment>
                                    ),
                                  }}
                                  disabled={blockAdminEditing}
                                  value={values.transactionsCashAndExternalFixed}
                                />
                              </Grid>
                            </Grid>
                          </Box>
                        </Paper>
                      </Grid>
                    </Grid>

                    <Divider role="presentation" className={classes.divider} />

                    <Grid container>
                      <Grid item xs={12} md={4}>
                        <Typography className={classes.subtitle}>
                          Franchise marketing fee
                        </Typography>
                        <Typography className={classes.context}>
                          Additional fee applied across all sales
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={8}>
                        <Paper sx={{ p: 2, borderRadius: 4 }}>
                          <Box className={classes.boxScroll}>
                            <Grid container className={classes.subtitle}>
                              <Grid item xs={12} sx={{ display: 'flex' }}>
                                <Field
                                  component={Switch}
                                  color="primary"
                                  type="checkbox"
                                  checked={franchiseMarketingFee}
                                  disabled={blockAdminEditing}
                                  onChange={() => {
                                    if (franchiseMarketingFee) {
                                      setFieldValue('franchiseMarketingItemFee', 0);
                                    }
                                    setFranchiseMarketingFee(!franchiseMarketingFee);
                                  }}
                                />
                              </Grid>
                            </Grid>

                            {franchiseMarketingFee && (
                              <>
                                <Grid container className={classes.container}>
                                  <Grid item xs={3} className={classes.columnTitles} />
                                </Grid>
                                <Grid container className={classes.container}>
                                  <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Field
                                      className={classes.inputFields}
                                      name="franchiseMarketingItemFee"
                                      component={TextField}
                                      size="small"
                                      variant="outlined"
                                      type="number"
                                      inputMode="numeric"
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment position="end">%</InputAdornment>
                                        ),
                                      }}
                                      disabled={blockAdminEditing}
                                      value={values.franchiseMarketingItemFee}
                                    />
                                    fee per item
                                  </Grid>
                                </Grid>
                                <Grid container className={classes.container}>
                                  <Grid item xs={12}>
                                    <Typography
                                      className={classes.cardTitle}
                                      sx={{ paddingLeft: '6px' }}
                                    >
                                      Applies to...
                                    </Typography>
                                    <Field
                                      className={classes.fullWidthInputFields}
                                      name="franchiseMarketingAllProducts"
                                      component={TextField}
                                      select
                                      size="small"
                                      variant="outlined"
                                      disabled={blockAdminEditing}
                                      value={values.franchiseMarketingAllProducts}
                                      fullWidth
                                    >
                                      <MenuItem disabled={blockAdminEditing} value={false}>
                                        Only Sessions branded items
                                      </MenuItem>
                                      <MenuItem value>All items</MenuItem>
                                    </Field>
                                  </Grid>
                                </Grid>
                              </>
                            )}
                          </Box>
                        </Paper>
                      </Grid>
                    </Grid>

                    <Divider role="presentation" className={classes.divider} />

                    <Grid container>
                      <Grid item xs={12} md={4}>
                        <Typography className={classes.subtitle}>In-house sales</Typography>
                        <Typography className={classes.context}>
                          Percentage fee per item sold via Epos, QR, QR Takeaway, App & Kiosk
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={8}>
                        <Paper sx={{ p: 2, borderRadius: 4 }}>
                          <Box className={classes.boxScroll}>
                            <Grid container className={classes.subtitle}>
                              <Grid
                                item
                                xs={10}
                                sx={{ display: 'flex', alignItems: 'center' }}
                                className={classes.cardTitle}
                              >
                                Non-Sessions items
                              </Grid>
                              <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'end' }}>
                                <Field
                                  name="inhouseVenueFeeActive"
                                  component={Switch}
                                  color="primary"
                                  type="checkbox"
                                  checked={venueOwnedItems}
                                  disabled={blockAdminEditing}
                                  onChange={() => {
                                    setVenueOwnedItems(!venueOwnedItems);
                                    setFieldValue('inhouseVenueFeeActive', !venueOwnedItems);
                                  }}
                                />
                              </Grid>
                            </Grid>

                            {venueOwnedItems && (
                              <>
                                <Grid container className={classes.container}>
                                  <Grid item xs={3} className={classes.columnTitles} />
                                  <Grid item className={classes.columnTitles}>
                                    Food
                                  </Grid>
                                  <Grid item className={classes.columnTitles}>
                                    Drink
                                  </Grid>
                                </Grid>
                                <Grid container className={classes.container}>
                                  <Grid item xs={3} className={classes.root}>
                                    Own items
                                  </Grid>
                                  <Grid item>
                                    <Field
                                      className={classes.inputFields}
                                      name="inhouseVenueFeeFood"
                                      component={TextField}
                                      size="small"
                                      variant="outlined"
                                      type="number"
                                      inputMode="numeric"
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment position="end">%</InputAdornment>
                                        ),
                                      }}
                                      disabled={blockAdminEditing}
                                      value={values.inhouseVenueFeeFood}
                                    />
                                  </Grid>
                                  <Grid item>
                                    <Field
                                      className={classes.inputFields}
                                      name="inhouseVenueFeeDrink"
                                      component={TextField}
                                      size="small"
                                      variant="outlined"
                                      type="number"
                                      inputMode="numeric"
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment position="end">%</InputAdornment>
                                        ),
                                      }}
                                      disabled={blockAdminEditing}
                                      value={values.inhouseVenueFeeDrink}
                                    />
                                  </Grid>
                                </Grid>
                              </>
                            )}
                            {venueBrands?.length > 0 && (
                              <>
                                <Divider role="presentation" className={classes.divider} />

                                <Grid container className={classes.cardTitle}>
                                  Sessions branded items
                                </Grid>
                                <Grid container className={classes.container}>
                                  <Grid item xs={3} className={classes.columnTitles} />
                                  <Grid item className={classes.columnTitles}>
                                    Food
                                  </Grid>
                                  <Grid item className={classes.columnTitles}>
                                    Drink
                                  </Grid>
                                </Grid>
                                <Grid container className={classes.container}>
                                  <Grid item xs={3} className={classes.defaultFieldsTitle}>
                                    Default fee
                                  </Grid>
                                  <Grid
                                    item
                                    className={classes.defaultFields}
                                    sx={{ borderRadius: '0' }}
                                  >
                                    <Field
                                      sx={{ width: { xs: '5.3rem', lg: '6.3rem' } }}
                                      name="inhouseBrandDefaultFood"
                                      component={TextField}
                                      size="small"
                                      variant="outlined"
                                      type="number"
                                      inputMode="numeric"
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment position="end">%</InputAdornment>
                                        ),
                                      }}
                                      onChange={(e) =>
                                        venueBrands.map((brand) =>
                                          updateDefaultValues(
                                            e,
                                            handleChange,
                                            setFieldValue,
                                            `inhouseBrand${brand.brandPrefix}Food`,
                                            'inhouseBrandDefaultFood',
                                          ),
                                        )
                                      }
                                      disabled={blockAdminEditing}
                                      value={values.inhouseBrandDefaultFood}
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    className={classes.defaultFields}
                                    sx={{ borderRadius: '0 8px 8px 0' }}
                                  >
                                    <Field
                                      sx={{ width: { xs: '5.3rem', lg: '6.3rem' } }}
                                      name="inhouseBrandDefaultDrink"
                                      component={TextField}
                                      size="small"
                                      variant="outlined"
                                      type="number"
                                      inputMode="numeric"
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment position="end">%</InputAdornment>
                                        ),
                                      }}
                                      onChange={(e) =>
                                        venueBrands.map((brand) =>
                                          updateDefaultValues(
                                            e,
                                            handleChange,
                                            setFieldValue,
                                            `inhouseBrand${brand.brandPrefix}Drink`,
                                            'inhouseBrandDefaultDrink',
                                          ),
                                        )
                                      }
                                      disabled={blockAdminEditing}
                                      value={values.inhouseBrandDefaultDrink}
                                    />
                                  </Grid>
                                </Grid>

                                {venueBrands.map((brand) => (
                                  <Grid container className={classes.container} key={brand.brandId}>
                                    <Grid item xs={3} className={classes.root}>
                                      {brand.brandName}
                                    </Grid>
                                    <Grid item>
                                      <Field
                                        className={getBrandFieldClassName(
                                          `inhouseBrand${brand.brandPrefix}Food`,
                                          'inhouseBrandDefaultFood',
                                        )}
                                        name={`inhouseBrand${brand.brandPrefix}Food`}
                                        component={TextField}
                                        size="small"
                                        variant="outlined"
                                        type="number"
                                        inputMode="numeric"
                                        InputProps={{
                                          endAdornment: (
                                            <InputAdornment position="end">%</InputAdornment>
                                          ),
                                        }}
                                        disabled={blockAdminEditing}
                                        value={values[`inhouseBrand${brand.brandPrefix}Food`]}
                                      />
                                      {displayResetCircle(
                                        `inhouseBrand${brand.brandPrefix}Food`,
                                        'inhouseBrandDefaultFood',
                                        setFieldValue,
                                      )}
                                    </Grid>
                                    <Grid item>
                                      <Field
                                        className={getBrandFieldClassName(
                                          `inhouseBrand${brand.brandPrefix}Drink`,
                                          'inhouseBrandDefaultDrink',
                                        )}
                                        name={`inhouseBrand${brand.brandPrefix}Drink`}
                                        component={TextField}
                                        size="small"
                                        variant="outlined"
                                        type="number"
                                        inputMode="numeric"
                                        InputProps={{
                                          endAdornment: (
                                            <InputAdornment position="end">%</InputAdornment>
                                          ),
                                        }}
                                        disabled={blockAdminEditing}
                                        value={values[`inhouseBrand${brand.brandPrefix}Drink`]}
                                      />
                                      {displayResetCircle(
                                        `inhouseBrand${brand.brandPrefix}Drink`,
                                        'inhouseBrandDefaultDrink',
                                        setFieldValue,
                                      )}
                                    </Grid>
                                  </Grid>
                                ))}
                              </>
                            )}
                          </Box>
                        </Paper>
                      </Grid>
                    </Grid>
                    {venueBrands?.length > 0 && (
                      <>
                        <Divider role="presentation" className={classes.divider} />

                        <Grid container>
                          <Grid item xs={12} md={4}>
                            <Typography className={classes.subtitle}>Delivery</Typography>
                            <Typography className={classes.context}>
                              When customers order through delivery apps
                            </Typography>
                          </Grid>
                          <Grid item xs={12} md={8}>
                            <Paper sx={{ p: 2, borderRadius: 4 }}>
                              <Box className={classes.boxScroll}>
                                <Grid container className={classes.container}>
                                  <Grid item xs={3} className={classes.columnTitles} />
                                  <Grid item className={classes.columnTitles}>
                                    Deliveroo
                                  </Grid>
                                  <Grid item className={classes.columnTitles}>
                                    Uber Eats
                                  </Grid>
                                  <Grid item className={classes.columnTitles}>
                                    Just Eat
                                  </Grid>
                                </Grid>
                                <Grid container className={classes.container}>
                                  <Grid item xs={3} className={classes.defaultFieldsTitle}>
                                    Default fee
                                  </Grid>
                                  <Grid item className={classes.defaultFields}>
                                    <Field
                                      sx={{ width: { xs: '5.3rem', lg: '6.3rem' } }}
                                      name="deliveryFeesDefaultDeliveroo"
                                      component={TextField}
                                      size="small"
                                      variant="outlined"
                                      type="number"
                                      inputMode="numeric"
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment position="end">%</InputAdornment>
                                        ),
                                      }}
                                      onChange={(e) =>
                                        venueBrands.map((brand) =>
                                          updateDefaultValues(
                                            e,
                                            handleChange,
                                            setFieldValue,
                                            `deliveryFees${brand.brandPrefix}Deliveroo`,
                                            'deliveryFeesDefaultDeliveroo',
                                          ),
                                        )
                                      }
                                      disabled={blockAdminEditing}
                                      value={values.deliveryFeesDefaultDeliveroo}
                                    />
                                  </Grid>
                                  <Grid item className={classes.defaultFields}>
                                    <Field
                                      sx={{ width: { xs: '5.3rem', lg: '6.3rem' } }}
                                      name="deliveryFeesDefaultUberEats"
                                      component={TextField}
                                      size="small"
                                      variant="outlined"
                                      type="number"
                                      inputMode="numeric"
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment position="end">%</InputAdornment>
                                        ),
                                      }}
                                      onChange={(e) =>
                                        venueBrands.map((brand) =>
                                          updateDefaultValues(
                                            e,
                                            handleChange,
                                            setFieldValue,
                                            `deliveryFees${brand.brandPrefix}UberEats`,
                                            'deliveryFeesDefaultUberEats',
                                          ),
                                        )
                                      }
                                      disabled={blockAdminEditing}
                                      value={values.deliveryFeesDefaultUberEats}
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    className={classes.defaultFields}
                                    sx={{ borderRadius: '0 8px 8px 0' }}
                                  >
                                    <Field
                                      sx={{ width: { xs: '5.3rem', lg: '6.3rem' } }}
                                      name="deliveryFeesDefaultJustEat"
                                      component={TextField}
                                      size="small"
                                      variant="outlined"
                                      type="number"
                                      inputMode="numeric"
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment position="end">%</InputAdornment>
                                        ),
                                      }}
                                      onChange={(e) =>
                                        venueBrands.map((brand) =>
                                          updateDefaultValues(
                                            e,
                                            handleChange,
                                            setFieldValue,
                                            `deliveryFees${brand.brandPrefix}JustEat`,
                                            'deliveryFeesDefaultJustEat',
                                          ),
                                        )
                                      }
                                      disabled={blockAdminEditing}
                                      value={values.deliveryFeesDefaultJustEat}
                                    />
                                  </Grid>
                                </Grid>

                                {venueBrands.map((brand) => (
                                  <Grid container className={classes.container} key={brand.brandId}>
                                    <Grid item xs={3} className={classes.root}>
                                      {brand.brandName}
                                    </Grid>
                                    <Grid item>
                                      <Field
                                        className={getBrandFieldClassName(
                                          `deliveryFees${brand.brandPrefix}Deliveroo`,
                                          'deliveryFeesDefaultDeliveroo',
                                        )}
                                        name={`deliveryFees${brand.brandPrefix}Deliveroo`}
                                        component={TextField}
                                        size="small"
                                        variant="outlined"
                                        type="number"
                                        inputMode="numeric"
                                        InputProps={{
                                          endAdornment: (
                                            <InputAdornment position="end">%</InputAdornment>
                                          ),
                                        }}
                                        disabled={blockAdminEditing}
                                        value={values[`deliveryFees${brand.brandPrefix}Deliveroo`]}
                                      />
                                      {displayResetCircle(
                                        `deliveryFees${brand.brandPrefix}Deliveroo`,
                                        'deliveryFeesDefaultDeliveroo',
                                        setFieldValue,
                                      )}
                                    </Grid>
                                    <Grid item>
                                      <Field
                                        className={getBrandFieldClassName(
                                          `deliveryFees${brand.brandPrefix}UberEats`,
                                          'deliveryFeesDefaultUberEats',
                                        )}
                                        name={`deliveryFees${brand.brandPrefix}UberEats`}
                                        component={TextField}
                                        size="small"
                                        variant="outlined"
                                        type="number"
                                        inputMode="numeric"
                                        InputProps={{
                                          endAdornment: (
                                            <InputAdornment position="end">%</InputAdornment>
                                          ),
                                        }}
                                        disabled={blockAdminEditing}
                                        value={values[`deliveryFees${brand.brandPrefix}UberEats`]}
                                      />
                                      {displayResetCircle(
                                        `deliveryFees${brand.brandPrefix}UberEats`,
                                        'deliveryFeesDefaultUberEats',
                                        setFieldValue,
                                      )}
                                    </Grid>
                                    <Grid item>
                                      <Field
                                        className={getBrandFieldClassName(
                                          `deliveryFees${brand.brandPrefix}JustEat`,
                                          'deliveryFeesDefaultJustEat',
                                        )}
                                        name={`deliveryFees${brand.brandPrefix}JustEat`}
                                        component={TextField}
                                        size="small"
                                        variant="outlined"
                                        type="number"
                                        inputMode="numeric"
                                        InputProps={{
                                          endAdornment: (
                                            <InputAdornment position="end">%</InputAdornment>
                                          ),
                                        }}
                                        disabled={blockAdminEditing}
                                        value={values[`deliveryFees${brand.brandPrefix}JustEat`]}
                                      />
                                      {displayResetCircle(
                                        `deliveryFees${brand.brandPrefix}JustEat`,
                                        'deliveryFeesDefaultJustEat',
                                        setFieldValue,
                                      )}
                                    </Grid>
                                  </Grid>
                                ))}
                              </Box>
                            </Paper>
                          </Grid>
                        </Grid>

                        <Divider role="presentation" className={classes.divider} />

                        <Grid container>
                          <Grid item xs={12} md={4}>
                            <Typography className={classes.subtitle}>Pickup</Typography>
                            <Typography className={classes.context}>
                              When customers pick up their order from the venue after ordering
                              through delivery apps.
                            </Typography>
                          </Grid>
                          <Grid item xs={12} md={8}>
                            <Paper sx={{ p: 2, borderRadius: 4 }}>
                              <Box className={classes.boxScroll}>
                                <Grid container className={classes.container}>
                                  <Grid item xs={3} className={classes.columnTitles} />
                                  <Grid item className={classes.columnTitles}>
                                    Deliveroo
                                  </Grid>
                                  <Grid item className={classes.columnTitles}>
                                    Uber Eats
                                  </Grid>
                                  <Grid item className={classes.columnTitles}>
                                    Just Eat
                                  </Grid>
                                </Grid>
                                <Grid container className={classes.container}>
                                  <Grid item xs={3} className={classes.defaultFieldsTitle}>
                                    Default fee
                                  </Grid>
                                  <Grid item className={classes.defaultFields}>
                                    <Field
                                      sx={{ width: { xs: '5.3rem', lg: '6.3rem' } }}
                                      name="deliveryPickupFeesDefaultDeliveroo"
                                      component={TextField}
                                      size="small"
                                      variant="outlined"
                                      type="number"
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment position="end">%</InputAdornment>
                                        ),
                                      }}
                                      onChange={(e) =>
                                        venueBrands.map((brand) =>
                                          updateDefaultValues(
                                            e,
                                            handleChange,
                                            setFieldValue,
                                            `deliveryPickupFees${brand.brandPrefix}Deliveroo`,
                                            'deliveryPickupFeesDefaultDeliveroo',
                                          ),
                                        )
                                      }
                                      disabled={blockAdminEditing}
                                      value={values.deliveryPickupFeesDefaultDeliveroo}
                                    />
                                  </Grid>
                                  <Grid item className={classes.defaultFields}>
                                    <Field
                                      sx={{ width: { xs: '5.3rem', lg: '6.3rem' } }}
                                      name="deliveryPickupFeesDefaultUberEats"
                                      component={TextField}
                                      size="small"
                                      variant="outlined"
                                      type="number"
                                      inputMode="numeric"
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment position="end">%</InputAdornment>
                                        ),
                                      }}
                                      onChange={(e) =>
                                        venueBrands.map((brand) =>
                                          updateDefaultValues(
                                            e,
                                            handleChange,
                                            setFieldValue,
                                            `deliveryPickupFees${brand.brandPrefix}UberEats`,
                                            'deliveryPickupFeesDefaultUberEats',
                                          ),
                                        )
                                      }
                                      disabled={blockAdminEditing}
                                      value={values.deliveryPickupFeesDefaultUberEats}
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    className={classes.defaultFields}
                                    sx={{ borderRadius: '0 8px 8px 0' }}
                                  >
                                    <Field
                                      sx={{ width: { xs: '5.3rem', lg: '6.3rem' } }}
                                      name="deliveryPickupFeesDefaultJustEat"
                                      component={TextField}
                                      size="small"
                                      variant="outlined"
                                      type="number"
                                      inputMode="numeric"
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment position="end">%</InputAdornment>
                                        ),
                                      }}
                                      onChange={(e) =>
                                        venueBrands.map((brand) =>
                                          updateDefaultValues(
                                            e,
                                            handleChange,
                                            setFieldValue,
                                            `deliveryPickupFees${brand.brandPrefix}JustEat`,
                                            'deliveryPickupFeesDefaultJustEat',
                                          ),
                                        )
                                      }
                                      disabled={blockAdminEditing}
                                      value={values.deliveryPickupFeesDefaultJustEat}
                                    />
                                  </Grid>
                                </Grid>

                                {venueBrands.map((brand) => (
                                  <Grid container className={classes.container} key={brand.brandId}>
                                    <Grid item xs={3} className={classes.root}>
                                      {brand.brandName}
                                    </Grid>
                                    <Grid item>
                                      <Field
                                        className={getBrandFieldClassName(
                                          `deliveryPickupFees${brand.brandPrefix}Deliveroo`,
                                          'deliveryPickupFeesDefaultDeliveroo',
                                        )}
                                        name={`deliveryPickupFees${brand.brandPrefix}Deliveroo`}
                                        component={TextField}
                                        size="small"
                                        variant="outlined"
                                        type="number"
                                        inputMode="numeric"
                                        InputProps={{
                                          endAdornment: (
                                            <InputAdornment position="end">%</InputAdornment>
                                          ),
                                        }}
                                        disabled={blockAdminEditing}
                                        value={
                                          values[`deliveryPickupFees${brand.brandPrefix}Deliveroo`]
                                        }
                                      />
                                      {displayResetCircle(
                                        `deliveryPickupFees${brand.brandPrefix}Deliveroo`,
                                        'deliveryPickupFeesDefaultDeliveroo',
                                        setFieldValue,
                                      )}
                                    </Grid>
                                    <Grid item>
                                      <Field
                                        className={getBrandFieldClassName(
                                          `deliveryPickupFees${brand.brandPrefix}UberEats`,
                                          'deliveryPickupFeesDefaultUberEats',
                                        )}
                                        name={`deliveryPickupFees${brand.brandPrefix}UberEats`}
                                        component={TextField}
                                        size="small"
                                        variant="outlined"
                                        type="number"
                                        inputMode="numeric"
                                        InputProps={{
                                          endAdornment: (
                                            <InputAdornment position="end">%</InputAdornment>
                                          ),
                                        }}
                                        disabled={blockAdminEditing}
                                        value={
                                          values[`deliveryPickupFees${brand.brandPrefix}UberEats`]
                                        }
                                      />
                                      {displayResetCircle(
                                        `deliveryPickupFees${brand.brandPrefix}UberEats`,
                                        'deliveryPickupFeesDefaultUberEats',
                                        setFieldValue,
                                      )}
                                    </Grid>
                                    <Grid item>
                                      <Field
                                        className={getBrandFieldClassName(
                                          `deliveryPickupFees${brand.brandPrefix}JustEat`,
                                          'deliveryPickupFeesDefaultJustEat',
                                        )}
                                        name={`deliveryPickupFees${brand.brandPrefix}JustEat`}
                                        component={TextField}
                                        size="small"
                                        variant="outlined"
                                        type="number"
                                        inputMode="numeric"
                                        InputProps={{
                                          endAdornment: (
                                            <InputAdornment position="end">%</InputAdornment>
                                          ),
                                        }}
                                        disabled={blockAdminEditing}
                                        value={
                                          values[`deliveryPickupFees${brand.brandPrefix}JustEat`]
                                        }
                                      />
                                      {displayResetCircle(
                                        `deliveryPickupFees${brand.brandPrefix}JustEat`,
                                        'deliveryPickupFeesDefaultJustEat',
                                        setFieldValue,
                                      )}
                                    </Grid>
                                  </Grid>
                                ))}
                              </Box>
                            </Paper>
                          </Grid>
                        </Grid>
                      </>
                    )}
                  </CardContent>
                </Form>
              </>
            )}
          </Formik>
        </Box>
      </Box>
    </Page>
  );
};

export default VenueFees;
