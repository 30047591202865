import React, { useEffect, useCallback, useState, useMemo } from 'react';
import { Box, Button, Card, CardContent, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useParams } from 'react-router';
import { MdRemove } from 'react-icons/md';
import { Field, Form, Formik } from 'formik';
import { useDispatch } from 'react-redux';
import PageHeader from '../../components/PageHeader';
import BackArrow from '../../components/BackArrow';
import Page from '../../components/Page';
import useEndpoint from '../../hooks/useEndpoint';
import {
  getUpsellGroupState,
  getUpsellGroupInitiatingItemsState,
} from '../../store/upsellGroups/selectors';
import {
  fetchUpsellGroup,
  fetchUpsellGroupInitiatingItems,
  updateUpsellGroup,
} from '../../store/upsellGroups';
import { getMenuItemsState } from '../../store/menuItems/selectors';
import { clearMenuItems, fetchMenuItems } from '../../store/menuItems';
import withVenue from '../../hoc/withVenue';
import UniversalSave from '../../components/UniversalSave';
import { getErrorMessage } from '../../shared/utils/errors';
import { useNotifications } from '../../shared/contexts/Notifications/useNotifications';
import EditCustomUpsellGroupSuggestedItems from '../../components/EditCustomUpsellGroupSuggestedItems';
import AddGroupItems from '../../components/AddGroupItems';
import isItemUnavailable from '../../shared/utils/isItemUnavailable';
import useItemsOnMenu from '../../hooks/useItemsOnMenu';
import { getVenuesSettingsState } from '../../store/venues/selectors';
import { fetchVenueSettings } from '../../store/venues';

const useStyles = makeStyles((theme) => ({
  title: {
    ...theme.customFonts.largeBold,
    marginBottom: '24px',
  },
  card: {
    borderRadius: '8px',
    marginBottom: '16px',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      gap: '16px',
      maxHeight: 'fit-content',
      margin: 'auto',
      marginTop: 0,
    },
  },
  container: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: '1rem',
    },
  },
  suggestionBox: {
    background: theme.customPalette.greyLightest,
    marginBottom: '4px',
    borderRadius: '4px',
    color: theme.customPalette.greyDarker,
    padding: '8px',
  },
  suggestionTitles: {
    ...theme.customFonts.small,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  errorHandle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  suggestedItemCardTitle: {
    ...theme.customFonts.smallBold,
    marginBottom: '8px',
  },
  noSuggestions: {
    ...theme.customFonts.xSmall,
    display: 'flex',
    justifyContent: 'center',
    marginTop: '16px',
  },
  editButton: {
    ...theme.customFonts.medium,
    marginTop: '4px',
    padding: '8px',
    '& .MuiButton-outlined': {
      border: `1px solid #000`,
      borderRadius: '8px',
    },
  },
  appliesToTitle: {
    ...theme.customFonts.smallBold,
    marginBottom: '16px',
  },
  initiatingItemsTitle: {
    ...theme.customFonts.medium,
  },
  initiatingItemsRow: {
    marginTop: '24px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  unavailableIcon: {
    width: '16px',
    height: '16px',
    marginLeft: '16px',
  },
  initiatingItemRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    marginRight: '8px',
  },
  brandItemSymbol: {
    width: '24px',
    height: '24px',
    marginLeft: theme.spacing(1),
  },
}));

const EditCustomUpsellGroup = ({ redirect }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { upsellGroupId } = useParams();
  const { showSuccessNotification, showErrorNotification } = useNotifications();
  const {
    loading: upsellGroupLoading,
    data: upsellGroup,
    error: upsellGroupError,
  } = useEndpoint(getUpsellGroupState, fetchUpsellGroup({ upsellGroupId }));

  const {
    loading: menuItemsLoading,
    data: menuItems,
    error: menuItemsError,
  } = useEndpoint(getMenuItemsState, fetchMenuItems()) || {};

  const {
    redispatch,
    loading: upsellGroupInitiatingItemsLoading,
    data: upsellGroupInitiatingItems,
    error: upsellGroupInitiatingItemsError,
  } = useEndpoint(
    getUpsellGroupInitiatingItemsState,
    fetchUpsellGroupInitiatingItems({ upsellGroupId }),
  ) || {};

  const upsellGroupInitiatingItemIds = upsellGroupInitiatingItems?.map((item) => item.itemId);
  const { suggestedItemIds, name, enabled } = upsellGroup || {};
  const [itemSuggestions, setItemSuggestions] = useState();
  const [loading, setLoading] = useState(true);
  const [itemsForDeletion, setItemsForDeletion] = useState([]);
  const [isSuggestedItemDialogOpen, setIsSuggestedItemDialogOpen] = useState(false);
  const { checkIfItemIsOnMenu } = useItemsOnMenu();
  const { data: venueSettings } = useEndpoint(getVenuesSettingsState, fetchVenueSettings()) || {};
  const isSessionsBrandsEnabled = !!venueSettings?.find(
    (setting) => setting.settingName === 'SESSIONS_BRAND_ENABLED' && setting.value === true,
  );
  const eligibleUpsellItems = useMemo(
    () => (isSessionsBrandsEnabled ? menuItems : menuItems?.filter((item) => !item.brandId)),
    [menuItems, isSessionsBrandsEnabled],
  );
  const sanitisedMenuItems = useMemo(
    () => eligibleUpsellItems?.filter((item) => !suggestedItemIds?.includes(item.itemId)),
    [eligibleUpsellItems, suggestedItemIds],
  );
  const [isAddInitiatingItemDialogOpen, setIsAddInitiatingItemDialogOpen] = useState(false);
  const isItemForDeletion = (item) => itemsForDeletion?.includes(item.itemId);

  const initialValues = useMemo(
    () => ({
      initiatingItems: upsellGroupInitiatingItems?.map((item) => item.itemId),
    }),
    [upsellGroupInitiatingItems],
  );

  const handleOpenSuggestedItemDialog = () => {
    setIsSuggestedItemDialogOpen(true);
  };

  const handleCloseSuggestedItemDialog = () => {
    setIsSuggestedItemDialogOpen(false);
  };

  const handleOpenAddInitiatingItemDialog = () => {
    setIsAddInitiatingItemDialogOpen(true);
  };

  const handleCloseAddInitiatingItemDialog = () => {
    setIsAddInitiatingItemDialogOpen(false);
  };

  const handleOnSave = async (values) => {
    const ids = values.filter((value) => value.checked).map((value) => value.itemId);
    try {
      await dispatch(
        updateUpsellGroup({
          upsellGroupId,
          data: {
            type: 'CUSTOM_UPSELLS',
            initiatingItems: ids,
            suggestedItemIds,
            name,
            enabled,
          },
        }),
      );
      redispatch();
      handleCloseAddInitiatingItemDialog();
      showSuccessNotification('Upsell initiating items updated successfully');
    } catch (error) {
      showErrorNotification(getErrorMessage(error));
    }
  };

  const handleDelete = (setFieldValue, index) => {
    setFieldValue(`initiatingItems.${[index]}`, '');
  };

  const handleOnSubmit = async (values) => {
    try {
      const transformedValues = values.initiatingItems.filter((value) => value !== '');
      await dispatch(
        updateUpsellGroup({
          upsellGroupId,
          data: {
            name,
            type: 'CUSTOM_UPSELLS',
            initiatingItems: transformedValues,
            enabled,
            suggestedItemIds,
          },
        }),
      );
      showSuccessNotification('Initiating item removed successfully');
      await dispatch(fetchUpsellGroup({ upsellGroupId }));
      await dispatch(fetchUpsellGroupInitiatingItems({ upsellGroupId }));
      await dispatch(fetchMenuItems());
      setItemsForDeletion(null);
    } catch (error) {
      showErrorNotification(getErrorMessage(error));
    }
  };

  const getSuggestedItems = useCallback(() => {
    if (suggestedItemIds && menuItems && menuItems.length > 0) {
      setItemSuggestions(menuItems.filter((item) => suggestedItemIds.includes(item.itemId)));
    }
    setLoading(false);
  }, [menuItems, suggestedItemIds]);

  const sanitisedSuggestedMenuItems = eligibleUpsellItems?.filter(
    (item) => !item.upsellGroupId || item.upsellGroupId !== upsellGroupId,
  );

  useEffect(() => {
    getSuggestedItems();
  }, [getSuggestedItems]);

  return (
    <>
      <Page
        loading={
          loading || menuItemsLoading || upsellGroupLoading || upsellGroupInitiatingItemsLoading
        }
        error={upsellGroupError || menuItemsError || upsellGroupInitiatingItemsError}
      >
        <PageHeader>
          <BackArrow text="Item upsells" redirect={redirect} />
          {name && <Typography className={classes.title}>{name}</Typography>}
        </PageHeader>
        <Formik initialValues={initialValues} enableReinitialize>
          {({ setFieldValue, values, isValid, dirty, errors, resetForm }) => (
            <>
              <UniversalSave
                isValid={isValid}
                dirty={dirty}
                errors={errors}
                onDiscard={() => {
                  resetForm();
                  setItemsForDeletion(null);
                }}
                onSave={() => handleOnSubmit(values)}
              />
              <Form>
                <Box className={classes.container}>
                  {upsellGroup ? (
                    <Card className={classes.card}>
                      <CardContent className={classes.cardContent}>
                        <Typography className={classes.suggestedItemCardTitle}>
                          You may also like
                        </Typography>
                        {itemSuggestions?.length > 0 ? (
                          itemSuggestions.map((suggestion) => (
                            <Box className={classes.suggestionBox} key={suggestion.itemId}>
                              {!isItemUnavailable(suggestion) &&
                                checkIfItemIsOnMenu(suggestion.itemId) && (
                                  <Typography className={classes.suggestionTitles}>
                                    <span style={{ display: 'flex', alignItems: 'center' }}>
                                      {suggestion.itemName}
                                      {suggestion.brandName && ` | ${suggestion.brandName}`}
                                      {suggestion.brandName && (
                                        <img
                                          src="/img/sessions_symbol.svg"
                                          className={classes.brandItemSymbol}
                                          alt="Sessions Brand Item"
                                        />
                                      )}
                                    </span>
                                  </Typography>
                                )}
                              {!checkIfItemIsOnMenu(suggestion.itemId) && (
                                <div className={classes.suggestionTitles}>
                                  <span style={{ textDecoration: 'line-through' }}>
                                    <span style={{ display: 'flex', alignItems: 'center' }}>
                                      {suggestion.itemName}
                                      {suggestion.brandName && ` | ${suggestion.brandName}`}
                                      {suggestion.brandName && (
                                        <img
                                          src="/img/sessions_symbol.svg"
                                          className={classes.brandItemSymbol}
                                          alt="Sessions Brand Item"
                                        />
                                      )}
                                    </span>
                                  </span>
                                  <img
                                    className={classes.unavailableIcon}
                                    src="/img/visibility/not-on-menu.svg"
                                    alt="Not on menu"
                                    title="Item is not on an active menu"
                                  />
                                </div>
                              )}
                              {checkIfItemIsOnMenu(suggestion.itemId) &&
                                isItemUnavailable(suggestion) && (
                                  <div className={classes.suggestionTitles}>
                                    <span
                                      style={{
                                        textDecoration: 'line-through',
                                      }}
                                    >
                                      <span style={{ display: 'flex', alignItems: 'center' }}>
                                        {suggestion.itemName}
                                        {suggestion.brandName && ` | ${suggestion.brandName}`}
                                        {suggestion.brandName && (
                                          <img
                                            src="/img/sessions_symbol.svg"
                                            className={classes.brandItemSymbol}
                                            alt="Sessions Brand Item"
                                          />
                                        )}
                                      </span>
                                    </span>
                                    <img
                                      src="/img/visibility/unavailable.svg"
                                      className={classes.unavailableIcon}
                                      alt="Unavailable"
                                      title="Item is unavailable"
                                    />{' '}
                                  </div>
                                )}
                            </Box>
                          ))
                        ) : (
                          <Typography className={classes.noSuggestions}>
                            Please add items to suggest
                          </Typography>
                        )}
                        <Button
                          className={classes.editButton}
                          variant="outlined"
                          onClick={handleOpenSuggestedItemDialog}
                        >
                          Edit
                        </Button>
                      </CardContent>
                    </Card>
                  ) : (
                    <Box className={classes.errorHandle}>
                      <Typography className={classes.title}>
                        Sorry the upsell you requested does not exist
                      </Typography>
                    </Box>
                  )}

                  <Card className={classes.card}>
                    <CardContent>
                      <Typography className={classes.appliesToTitle}>Applies to</Typography>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleOpenAddInitiatingItemDialog}
                      >
                        Add items
                      </Button>
                      <Box>
                        {upsellGroupInitiatingItems.map(
                          (initiatingItem, index) =>
                            !isItemForDeletion(initiatingItem) && (
                              <Field name={`initiatingItems${[index]}`} key={initiatingItem.itemId}>
                                {() => (
                                  <Box
                                    className={classes.initiatingItemsRow}
                                    key={initiatingItem.itemId}
                                  >
                                    <div className={classes.initiatingItemRow}>
                                      {!isItemUnavailable(initiatingItem) &&
                                        checkIfItemIsOnMenu(initiatingItem.itemId) && (
                                          <Typography className={classes.initiatingItemsTitle}>
                                            <span style={{ display: 'flex', alignItems: 'center' }}>
                                              {initiatingItem.itemName}
                                              {initiatingItem.brandName &&
                                                ` | ${initiatingItem.brandName}`}
                                              {initiatingItem.brandName && (
                                                <img
                                                  src="/img/sessions_symbol.svg"
                                                  className={classes.brandItemSymbol}
                                                  alt="Sessions Brand Item"
                                                />
                                              )}
                                            </span>
                                          </Typography>
                                        )}

                                      {!checkIfItemIsOnMenu(initiatingItem.itemId) && (
                                        <>
                                          <Typography
                                            className={classes.initiatingItemsTitle}
                                            style={{
                                              textDecoration: 'line-through',
                                            }}
                                          >
                                            <span style={{ display: 'flex', alignItems: 'center' }}>
                                              {initiatingItem.itemName}
                                              {initiatingItem.brandName &&
                                                ` | ${initiatingItem.brandName}`}
                                              {initiatingItem.brandName && (
                                                <img
                                                  src="/img/sessions_symbol.svg"
                                                  className={classes.brandItemSymbol}
                                                  alt="Sessions Brand Item"
                                                />
                                              )}
                                            </span>
                                          </Typography>
                                          <img
                                            className={classes.unavailableIcon}
                                            src="/img/visibility/not-on-menu.svg"
                                            alt="Not on menu"
                                            title="Item is not on an active menu"
                                          />
                                        </>
                                      )}
                                      {isItemUnavailable(initiatingItem) &&
                                        checkIfItemIsOnMenu(initiatingItem.itemId) && (
                                          <>
                                            <Typography
                                              className={classes.initiatingItemsTitle}
                                              style={{
                                                textDecoration: 'line-through',
                                              }}
                                            >
                                              <span
                                                style={{ display: 'flex', alignItems: 'center' }}
                                              >
                                                {initiatingItem.itemName}
                                                {initiatingItem.brandName &&
                                                  ` | ${initiatingItem.brandName}`}
                                                {initiatingItem.brandName && (
                                                  <img
                                                    src="/img/sessions_symbol.svg"
                                                    className={classes.brandItemSymbol}
                                                    alt="Sessions Brand Item"
                                                  />
                                                )}
                                              </span>
                                            </Typography>
                                            <img
                                              className={classes.unavailableIcon}
                                              src="/img/visibility/unavailable.svg"
                                              alt="Unavailable"
                                              title="Item is unavailable"
                                            />
                                          </>
                                        )}
                                    </div>
                                    <Button
                                      style={{ padding: '8px', minWidth: 0, minHeight: 0 }}
                                      variant="outlined"
                                      onClick={() => {
                                        handleDelete(setFieldValue, index, values);
                                        setItemsForDeletion(
                                          itemsForDeletion
                                            ? [initiatingItem.itemId, ...itemsForDeletion]
                                            : [initiatingItem.itemId],
                                        );
                                      }}
                                    >
                                      <MdRemove />
                                    </Button>
                                  </Box>
                                )}
                              </Field>
                            ),
                        )}
                      </Box>
                    </CardContent>
                  </Card>
                </Box>
              </Form>
            </>
          )}
        </Formik>
        {sanitisedMenuItems?.length > 0 && suggestedItemIds && !!upsellGroupId && (
          <AddGroupItems
            menuItems={sanitisedMenuItems}
            isDialogOpen={isAddInitiatingItemDialogOpen}
            handleCloseDialog={handleCloseAddInitiatingItemDialog}
            handleOnSave={handleOnSave}
            checkedItems={upsellGroupInitiatingItemIds}
          />
        )}

        {suggestedItemIds && (
          <EditCustomUpsellGroupSuggestedItems
            isDialogOpen={isSuggestedItemDialogOpen}
            handleCloseDialog={handleCloseSuggestedItemDialog}
            menuItems={sanitisedSuggestedMenuItems}
            preSelectedItems={suggestedItemIds}
            upsellGroupId={upsellGroupId}
            name={name}
            enabled={enabled}
          />
        )}
      </Page>
    </>
  );
};

export default withVenue(EditCustomUpsellGroup, '/upsells/item-upsells', [clearMenuItems]);
