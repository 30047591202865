import sendReportRequest from './sendReportRequest';

const fetchReportSalesByItem = async ({ dateRange, from, to, reportingType }) => {
  const reponseData = await sendReportRequest({
    url: '/reports/salesByItem',
    dateRange,
    from,
    to,
    reportingType,
  });

  return reponseData;
};

export default fetchReportSalesByItem;
