import React from 'react';
import PageLoader from '../PageLoader';
import PageError from '../PageError';

/**
 * Wraps components with loading and error handling.
 * @param {Object} props - Contains:
 *    - error (optional) - Error state from the store
 *    - loading (optional) - Loading state from the store
 *    - small (optional) - Determines size of loading spinner
 *    - children - JSX elements to be wrapped
 * @example
 * <FallbackProvider loading={loading} error={error} small>
 *   <List />
 * </FallbackProvider>
 */

const FallbackProvider = ({ loading, error, children, small }) => {
  if (error) {
    return <PageError error={error} />;
  }

  if (loading) {
    return <PageLoader small={small} />;
  }

  return children || null;
};

export default FallbackProvider;
