import { createSelector } from '@reduxjs/toolkit';

export const getBuilderMenusState = (state) => state.builderMenus;

export const getBuilderMenuState = (state) => state.builderMenu;

const getBuilderMenuFromMenuId = (_, menuId) => menuId;

export const getBuilderMenu = createSelector(getBuilderMenusState, getBuilderMenuFromMenuId);

export const getBuilderMenuBrandId = createSelector(
  getBuilderMenuState,
  (builderMenu) => builderMenu && builderMenu.data.brandId,
);

export const getBuilderMenuVariationId = createSelector(
  getBuilderMenuState,
  (builderMenu) => builderMenu && builderMenu?.data?.brandMenuId,
);
