import React from 'react';

import { makeStyles } from '@mui/styles';
import { Button, Card, CardContent, Grid, Typography } from '@mui/material';

import { Link } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  subtitle: {
    fontFamily: 'Sen',
    fontWeight: 'bold',
    marginBottom: '0.5rem',
  },
  body: {
    marginBottom: '1rem',
    fontSize: '0.8rem',
    textAlign: 'left',
  },
  image: {
    display: 'flex',
    justifyContent: 'center',
    padding: 0,
  },
  img: {
    height: '100%',
    width: '100%',
  },
  column: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'left',
    flexDirection: 'column',
  },
  button: {
    marginBottom: '1rem',
  },
  buttons: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
  },
}));

const OffersHighlightCard = ({ campaigns }) => {
  const classes = useStyles();
  const selectedCampaign =
    campaigns?.availables.find((campaign) => campaign.showInHome) || campaigns?.availables[0];
  const { campaignBanner, brand, campaignName } = selectedCampaign || {};
  if (campaignName) {
    return (
      <Grid item xs={12}>
        <Card>
          <CardContent className={classes.image}>
            <img className={classes.img} src={campaignBanner} alt={brand} />
          </CardContent>
          <CardContent className={classes.column}>
            <Typography variant="subtitle2" className={classes.subtitle}>
              {brand}
            </Typography>
            <Typography variant="body2" color="primary" className={classes.body}>
              {campaignName}
            </Typography>
            <div className={classes.buttons}>
              <Button variant="contained" color="primary" component={Link} to="/offers">
                View this promotion
              </Button>
            </div>
          </CardContent>
        </Card>
      </Grid>
    );
  }
  return null;
};

export default OffersHighlightCard;
