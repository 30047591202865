import { createCognitoAuth, getCognitoSession, signOutCognitoSession } from './utils';

const parseCognitoWebResponse = (href) =>
  new Promise((resolve, reject) => {
    const auth = createCognitoAuth();

    auth.userhandler = {
      onSuccess: (result) => {
        resolve(result);
      },
      onFailure: (err) => {
        reject(new Error(`Failure parsing Cognito web response: ${err}`));
      },
    };
    auth.parseCognitoWebResponse(href);
  });

export const refreshSession = async () => {
  const session = await getCognitoSession();
  return session;
};

export const startSessionFromCallback = async (callbackHref) => {
  await parseCognitoWebResponse(callbackHref);
  const session = await getCognitoSession();
  return session;
};

export const signOutSession = async () => {
  signOutCognitoSession();
};
