import React from 'react';
import { TablePagination } from '@mui/material';
import { withStyles } from 'tss-react/mui';

import TablePaginationActions from '../../components/TablePaginationActions';

const StyledTablePagination = withStyles(TablePagination, (theme) => ({
  root: {
    border: 0,
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
    '& .MuiBox-root': {
      marginRight: 0,
    },
  },
}));

const RatingsPagination = ({ total, page, rowsPerPage, handleChangePage }) => (
  <StyledTablePagination
    rowsPerPageOptions={[]}
    count={total}
    rowsPerPage={rowsPerPage}
    page={page}
    onPageChange={handleChangePage}
    ActionsComponent={TablePaginationActions}
    component="div"
  />
);

export default RatingsPagination;
