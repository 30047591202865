import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { rem } from 'polished';
import { makeStyles } from '@mui/styles';
import Hidden from '@mui/material/Hidden';
import Drawer from '@mui/material/Drawer';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import AppHeader from '../AppHeader';
import Navigation from '../Navigation';
import cySelectors from '../../tests/cySelectors';

export const navigationWidth = rem(260);
export const mobileNavigationWidth = '60%';

const useStyles = makeStyles((theme) => ({
  page: {
    display: 'flex',
    flex: '1 0 auto',
    minHeight: 'calc(100vh - 64px)',
    [theme.breakpoints.down('md')]: {
      minHeight: 'calc(100vh - 56px)',
    },
  },

  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    maxWidth: '100%',
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2),
    },
  },

  drawer: {
    flexShrink: 0,
    width: navigationWidth,

    [theme.breakpoints.down('md')]: {
      width: mobileNavigationWidth,
    },
  },

  drawerPaper: {
    width: navigationWidth,

    [theme.breakpoints.down('md')]: {
      width: mobileNavigationWidth,
    },
  },

  main: {
    flex: 1,
  },

  toolbar: theme.mixins.toolbar,
}));

const Layout = ({ children }) => {
  const classes = useStyles();
  const [isNavigationOpen, setIsNavigationOpen] = useState(false);

  const openNavigation = () => {
    setIsNavigationOpen(!isNavigationOpen);
  };

  const closeNavigation = (event) => {
    if (!event) {
      setIsNavigationOpen(false);
      return;
    }
    const { target } = event;
    // detect for selecting the navigation venue search and keep the navigation open
    // otherwise it closes on mobile before the user can select anything
    if (target?.getAttribute('aria-autocomplete') === 'list') {
      return;
    }
    setIsNavigationOpen(false);
  };

  return (
    <div className={classes.page} data-cy={cySelectors.AUTHENTICATED}>
      <AppHeader onNavigationOpen={openNavigation} authenticated />

      <Hidden mdDown>
        <Drawer
          className={classes.drawer}
          variant="permanent"
          classes={{ paper: classes.drawerPaper }}
        >
          <div className={classes.toolbar} />
          <Navigation />
        </Drawer>
      </Hidden>

      <Hidden mdUp>
        <SwipeableDrawer
          variant="temporary"
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          open={isNavigationOpen}
          className={classes.drawer}
          classes={{ paper: classes.drawerPaper }}
          onClose={closeNavigation}
          onOpen={openNavigation}
          role="presentation"
        >
          <Navigation isNavigationOpen={isNavigationOpen} navigationOpen={openNavigation} />
        </SwipeableDrawer>
      </Hidden>
      <main className={classes.content} data-cy={cySelectors.MAIN}>
        <div className={classes.toolbar} />
        {children}
      </main>
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.element,
};

Layout.defaultProps = {
  children: <></>,
};

export default Layout;
