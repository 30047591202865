import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { Button } from '@mui/material';
import { MdAdd } from 'react-icons/md';
import withVenue from '../../../hoc/withVenue';
import useRoles from '../../../hooks/useRoles';
import { isDeliveryOnly } from '../../../store/venues/selectors';

const CreateItemButton = () => {
  const { isRoleAtLeastManager, isAdmin } = useRoles();
  const typeDeliveryOnly = useSelector(isDeliveryOnly);
  const permissionReadOnly = !isAdmin() && typeDeliveryOnly;

  return (
    <Button
      variant="contained"
      color="primary"
      component={Link}
      disabled={!isRoleAtLeastManager() || permissionReadOnly}
      to="/brand-menu-management/items/add"
      startIcon={<MdAdd />}
    >
      Create Item
    </Button>
  );
};

export default withVenue(CreateItemButton);
