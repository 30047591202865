import { Box, Button, Paper, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import CollapsableDescription from '../../components/CollapsableDescription';

import PromotionItem from '../../components/PromotionItem';

const useStyles = makeStyles((theme) => ({
  newBrandupOffer: {
    [theme.breakpoints.down('lg')]: {
      maxWidth: 343,
    },
  },
  brandupHeading: {
    textTransform: 'uppercase',
    fontSize: 13,
    color: '#647F99',
  },
}));

const NewBrandupOffer = ({ brandupCampaign, handleOpenOfferDialog }) => {
  const classes = useStyles();

  const handleOpenDialog = () => {
    handleOpenOfferDialog(brandupCampaign);
  };

  return (
    <Box component={Paper} p={2} mb={2} className={classes.newBrandupOffer}>
      <Typography
        component="h2"
        variant="subtitle2"
        gutterBottom
        color="secondary"
        className={classes.brandupHeading}
      >
        New brandup offer
      </Typography>
      <Box display="flex" justifyContent="center" flexDirection="column" mb={2}>
        <PromotionItem item={brandupCampaign} />
      </Box>
      <Typography component="h3" gutterBottom>
        Let big brands pay you!
      </Typography>
      <Typography paragraph variant="subtitle2">
        BrandUp offers appear inside your promotional carousel
      </Typography>
      <CollapsableDescription
        title="What is a BrandUp offer?"
        // eslint-disable-next-line max-len
        description="Brands that stock their products at your venue are willing to pay you for promoting their items in the QR menu."
      />
      <Box display="flex" justifyContent="center" flexDirection="column" gap={16}>
        <Button variant="outlined" onClick={handleOpenDialog}>
          See this offer
        </Button>
      </Box>
    </Box>
  );
};

export default NewBrandupOffer;
