export default [
  {
    optionName: 'Serving',
    type: 'FOOD',
    venueId: '1',
    optionId: '10',
  },
  {
    optionName: 'Sharing platter',
    type: 'FOOD',
    venueId: '1',
    optionId: '11',
  },
  {
    optionName: 'Glass',
    type: 'DRINK',
    venueId: '1',
    optionId: '12',
  },
  {
    optionName: 'Pint',
    type: 'DRINK',
    venueId: '1',
    optionId: '2',
  },
  {
    optionName: 'Half pint',
    type: 'DRINK',
    venueId: '1',
    optionId: '3',
  },
  {
    optionName: '250ml',
    type: 'DRINK',
    venueId: '1',
    optionId: '4',
  },
  {
    optionName: '175ml',
    type: 'DRINK',
    venueId: '1',
    optionId: '5',
  },
  {
    optionName: 'Bottle',
    type: 'DRINK',
    venueId: '1',
    optionId: '6',
  },
  {
    optionName: 'Single',
    type: 'DRINK',
    venueId: '1',
    optionId: '7',
  },
  {
    optionName: 'Double',
    type: 'DRINK',
    venueId: '1',
    optionId: '8',
  },
  {
    optionName: 'Portion',
    type: 'FOOD',
    venueId: '1',
    optionId: '9',
  },
];
