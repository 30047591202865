import React from 'react';

import StepGraphic from '../StepGraphic';
import StepButton from '../StepButton';
import StepQuestion from '../StepQuestion';

const StepVenueDetailsThree = () => (
  <>
    <StepQuestion question="Venue details complete!" center />
    <StepGraphic fileName="venue-details-complete" />
    <StepButton />
  </>
);

export default StepVenueDetailsThree;
