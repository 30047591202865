import { useCallback } from 'react';
import { useIntercom } from 'react-use-intercom';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { setVenue } from '../store/venues';
import { getVenuesState } from '../store/venues/selectors';

const useSwitchVenue = () => {
  const dispatch = useDispatch();
  const { data: venues } = useSelector(getVenuesState);
  const { shutdown } = useIntercom();
  const location = useLocation();
  const history = useHistory();

  const switchVenue = useCallback(
    async (venueId) => {
      const queryParams = new URLSearchParams(location.search);
      const newVenue = venues?.find((ven) => ven.venueId === venueId);
      sessionStorage.removeItem('menuItemsFilter');
      if (newVenue) {
        shutdown();
        await dispatch(setVenue(newVenue));

        if (newVenue) {
          queryParams.delete('venueId');

          history.replace({
            pathname: location.pathname,
            search: queryParams.toString(),
          });
        }
      }
    },
    [dispatch, history, location.pathname, location.search, shutdown, venues],
  );

  return switchVenue;
};

export default useSwitchVenue;
