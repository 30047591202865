import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
import { makeStyles } from '@mui/styles';
import { Typography, AppBar, Toolbar, IconButton, Menu, MenuItem } from '@mui/material';
import { MdMenu } from 'react-icons/md';
import { FiCopy } from 'react-icons/fi';
import copy from 'clipboard-copy';

import { AccountCircleOutlined } from '@mui/icons-material';
import { logout } from '../../store/session';
import Logo from '../Logo';
import { getVenuesState, getVenueState, isBrandUp } from '../../store/venues/selectors';
import useRoles from '../../hooks/useRoles';
import { ReactComponent as Announcement } from './announcement.svg';
import useEndpoint from '../../hooks/useEndpoint';
import { getManagersState } from '../../store/managers/selectors';
import { fetchManagers } from '../../store/managers';
import StatusLozenge from '../StatusLozenge';
import DISPLAYROLES from '../../shared/constants/displayRoles';
import Banana from './banana.gif';

const isDev = process.env.REACT_APP_STAGE === 'development';
const isRouteSelected = (path) => window.location.pathname.startsWith(path);

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },

  menuButton: {
    width: '10%',

    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },

  logo: {
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  notauthenticatedlogo: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },

  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  },

  titleSize: {
    marginLeft: 5,
    ...theme.customFonts.mediumBold,
  },

  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    justifySelf: 'flex-end',
  },

  accountCircle: {
    color: theme.customPalette.greyDarker,
  },
  menuItem: {
    color: theme.customPalette.greyDarker,
  },
  userDetails: {
    padding: '0.75rem',
    paddingLeft: '1.75rem',
    paddingRight: '1.75rem',
    borderBottom: `1px solid ${theme.customPalette.greyLight}`,
    marginBottom: '0.5rem',
  },
  managerName: {
    color: theme.customPalette.greyDarker,
    ...theme.customFonts.smallBold,
    display: 'block',
  },
  userName: {
    color: theme.customPalette.greyDark,
    ...theme.customFonts.small,
  },
  managerRole: {
    marginTop: '0.5rem',
    display: 'block',
  },
  toolbar: {
    // backgroundColor: isDev ? theme.customPalette.midnight : theme.customPalette.white,
    backgroundColor: theme.customPalette.midnight,
    width: '100%',
    justifyContent: 'space-between',
  },
  toolbarContents: {
    // color: isDev ? theme.customPalette.white : theme.customPalette.midnight,
    color: theme.customPalette.white,
  },
  svgColor: {
    // backgroundColor: isDev ? theme.customPalette.grey : theme.customPalette.white,
    backgroundColor: theme.customPalette.grey,
  },
  toolbarLogo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: '1rem',
  },
  banana: {
    width: '40px',
    opacity: 0,
    transition: '2s all',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
    '&:hover': {
      opacity: 1,
      transitionDelay: '2s',
    },
  },
  devlozenge: {
    backgroundColor: '#DADAFF',
    color: theme.customPalette.midnight,
    fontWeight: '400',
  },
}));

const AppHeader = ({ onNavigationOpen, authenticated }) => {
  const classes = useStyles();
  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);
  const menuAnchor = useRef(null);
  const dispatch = useDispatch();

  const { data: venues } = useSelector(getVenuesState);
  const { data: venue } = useSelector(getVenueState);
  const { data: managers } = useEndpoint(getManagersState, fetchManagers());
  const { data: appState } = useSelector((state) => state.app.session);

  const { username } = appState || {};
  const { name: managerName } = managers?.find((m) => m.userId === username) || {};
  const {
    onDeliverect,
    name,
    accountType,
    accountStatus,
    email,
    venueId,
    phone,
    createdAt,
    role,
    intercomHash,
  } = venue || {};
  const { boot, shutdown } = useIntercom();
  const isBrandup = useSelector(isBrandUp);
  const { isAdmin } = useRoles();
  const isBrandMenuManager = isRouteSelected('/brand-menu-management');

  useEffect(() => {
    if (venue && venueId && intercomHash) {
      boot({
        name,
        created_at: (+new Date(createdAt)).toFixed(0),
        email,
        userId: venueId,
        userHash: intercomHash,
        phone,
      });
    }
  }, [venue, boot, name, createdAt, email, venueId, phone, intercomHash]);

  const openMenu = () => {
    setIsProfileMenuOpen(true);
  };

  const closeMenu = () => {
    setIsProfileMenuOpen(false);
  };

  const onLogoutClicked = () => {
    shutdown();
    closeMenu();
    dispatch(logout());
  };

  return (
    <AppBar position="fixed" className={classes.appBar}>
      {!authenticated ? (
        <Toolbar className={classes.toolbar}>
          <div className={classes.notauthenticatedlogo}>
            <Logo isBrandup={isBrandup} />
          </div>
        </Toolbar>
      ) : (
        <Toolbar className={classes.toolbar}>
          <div className={classes.menuButton}>
            <IconButton
              className={classes.toolbarContents}
              onClick={onNavigationOpen}
              edge="start"
              size="large"
            >
              {authenticated && <MdMenu />}
            </IconButton>
          </div>
          <div className={classes.toolbarLogo}>
            <div className={classes.logo}>
              <Logo extraClasses={classes.toolbarContents} />
            </div>
            {isDev && (
              <StatusLozenge status="inactive" text="DEV" thin className={classes.devlozenge} />
            )}
          </div>
          <div className={`${classes.title} ${classes.toolbarContents}`}>
            <Typography variant="h1" className={classes.titleSize} noWrap>
              {venue && !isBrandMenuManager && `${name} `}
              {isBrandMenuManager && 'Brand Menu Management'}
            </Typography>
            {isAdmin() && venue && !isBrandMenuManager && (
              <Typography variant="h2" className={classes.titleSize}>
                ({accountType}) ({accountStatus}){onDeliverect && ' (on deliverect)'}
                <IconButton
                  color="inherit"
                  size="small"
                  onClick={() => {
                    copy(venueId);
                  }}
                >
                  <FiCopy />
                </IconButton>
              </Typography>
            )}
          </div>
          {authenticated && (
            <div className={classes.actions}>
              {isDev && <img src={Banana} alt="banana" className={classes.banana} />}

              <div id="beamerButton">
                <IconButton className={classes.toolbarContents} size="large">
                  <Announcement />
                </IconButton>
              </div>
              <IconButton
                className={`${classes.accountCircle} ${classes.toolbarContents}`}
                ref={menuAnchor}
                onClick={openMenu}
                edge="end"
                size="large"
              >
                <AccountCircleOutlined />
              </IconButton>
              <Menu
                open={isProfileMenuOpen}
                anchorEl={menuAnchor.current}
                onClose={closeMenu}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                {username && (
                  <div className={classes.userDetails}>
                    {managerName && <span className={classes.managerName}>{managerName}</span>}
                    <span className={classes.userName}>{username}</span>
                    {role && (
                      <span className={classes.managerRole}>
                        <StatusLozenge status="inactive" text={DISPLAYROLES[role]} thin />
                      </span>
                    )}
                  </div>
                )}
                {venues && venues.length > 1 && (
                  <MenuItem onClick={closeMenu} component={Link} to="/venues">
                    <span className={classes.menuItem}>Your Venues</span>
                  </MenuItem>
                )}
                <MenuItem onClick={onLogoutClicked}>
                  <span className={classes.menuItem}>Logout</span>
                </MenuItem>
              </Menu>
            </div>
          )}
        </Toolbar>
      )}
    </AppBar>
  );
};

AppHeader.propTypes = {
  onNavigationOpen: PropTypes.func.isRequired,
  authenticated: PropTypes.bool,
};

AppHeader.defaultProps = {
  authenticated: false,
};

export default AppHeader;
