import { useState, useCallback } from 'react';
import { useSnackbar } from 'notistack';

export default () => {
  const { enqueueSnackbar } = useSnackbar();
  const [notifications, setNotifications] = useState([]);

  const addNotification = useCallback(
    (message) => {
      enqueueSnackbar(message, {
        variant: 'default',
      });
    },
    [enqueueSnackbar],
  );

  const showSuccessNotification = useCallback(
    (message) => {
      addNotification(message, 'success');
    },
    [addNotification],
  );

  const showErrorNotification = useCallback(
    (message) => {
      addNotification(message, 'error');
    },
    [addNotification],
  );

  const closeNotification = useCallback(
    (id) => {
      const notificationsWithoutSpecifiedOne = notifications.filter(
        (notification) => notification.id !== id,
      );

      setNotifications(notificationsWithoutSpecifiedOne);
    },
    [notifications],
  );

  return {
    notifications,
    closeNotification,
    showSuccessNotification,
    showErrorNotification,
  };
};
