import { decode } from 'jsonwebtoken-esm';

export const getApp = (state) => state.app;

export const getRolesFromToken = (token) => {
  if (!token) return [];
  const decoded = decode(token) || '';
  return decoded['cognito:groups'] || [];
};

export const getRoles = (state) => {
  const token = state?.app?.session?.data?.idToken || null;
  return getRolesFromToken(token);
};
export const getUserName = (state) => {
  const token = state?.app?.session?.data?.idToken || null;
  const decoded = decode(token) || '';
  return decoded['cognito:username'] || '';
};
