import { pick } from 'ramda';
import { getBare, deleteBare, postBare, patchBare } from '../httpRequest';

export const fetchBrandsModifiersApi = async () => {
  const response = await getBare(`brands/modifiers`);
  return response.data;
};

export const fetchBrandsModifierApi = async (modifierId) => {
  const response = await getBare(`brands/modifiers/${modifierId}`);
  return response.data;
};

export const deleteBrandsModifierApi = async (modifierId) => {
  const response = await deleteBare(`brands/modifiers/${modifierId}`);
  return response.data;
};

export const createBrandsModifierApi = async (modifier) => {
  const response = await postBare(`brands/modifiers`, {
    data: modifier,
  });
  return response.data;
};

export const updateBrandsModifierApi = async (modifierId, values) => {
  const trimmedValues = {
    ...pick(
      ['modifierId', 'modifierName', 'label', 'modifierItems', 'minSelections', 'maxSelections'],
      values,
    ),
  };

  await patchBare(`brands/modifiers/${modifierId}`, {
    data: trimmedValues,
  });
};
