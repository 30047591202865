import React from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Typography } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import customFonts from '../../theme/mui/customFonts';
import { customPalette } from '../../theme/mui/colourPalettes';

const useStyles = makeStyles(() => ({
  warningTitle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  warningContent: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
}));

const CustomWarningBanner = ({
  title,
  titleColor,
  titleFont,
  iconColor,
  backgroundColor,
  warning,
  warningFont,
  noIcon,
  customIcon,
  children,
  bottomSpace,
  borderRadius,
}) => {
  const classes = useStyles();

  return (
    <Box
      style={{
        background: customPalette[backgroundColor] || backgroundColor,
        borderRadius: borderRadius || '8px',
        display: 'flex',
        padding: '16px',
        marginBottom: bottomSpace ? '1rem' : '0px',
      }}
    >
      <Box className={classes.warningTitle}>
        {!noIcon && !customIcon && (
          <ErrorIcon
            style={{ color: iconColor, width: '16.67px', height: '16.67px', marginRight: '12px' }}
          />
        )}
        {customIcon && (
          <Box style={{ width: '16.67px', height: '16.67px', marginRight: '12px' }}>
            {customIcon}
          </Box>
        )}
      </Box>
      <Box className={classes.warningContent}>
        <Typography
          style={{
            color: customPalette[titleColor],
            ...customFonts[titleFont],
            marginBottom: '4px',
          }}
        >
          {title}
        </Typography>
        <Box style={{ color: customPalette[titleColor], ...customFonts[warningFont] }}>
          {children || warning}
        </Box>
      </Box>
    </Box>
  );
};

export default CustomWarningBanner;
