import { createSlice } from '@reduxjs/toolkit';
import {
  asyncInitialState as initialState,
  createAsyncAction,
  createAsyncReducerHandlers,
} from '../utils/async';
import {
  fetchCategories as fetchCategoriesApi,
  fetchCategory as fetchCategoryApi,
  createCategory as createCategoryApi,
  updateCategory as updateCategoryApi,
  deleteCategory as deleteCategoryApi,
} from '../../api/categories';

export const fetchCategories = createAsyncAction('fetch-categories', fetchCategoriesApi);

export const fetchCategory = createAsyncAction('fetch-category', fetchCategoryApi);

export const deleteCategory = createAsyncAction('delete-category', deleteCategoryApi);

export const createCategory = createAsyncAction('create-category', createCategoryApi);

export const updateCategory = createAsyncAction('update-category', updateCategoryApi);

const categoriesSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {},
  extraReducers: {
    ...createAsyncReducerHandlers(fetchCategories),
    ...createAsyncReducerHandlers(deleteCategory),
  },
});

const categorySlice = createSlice({
  name: 'category',
  initialState,
  reducers: {},
  extraReducers: {
    ...createAsyncReducerHandlers(fetchCategory),
    ...createAsyncReducerHandlers(createCategory),
    ...createAsyncReducerHandlers(updateCategory),
  },
});

export const { reducer: categories } = categoriesSlice;
export const { reducer: category } = categorySlice;
