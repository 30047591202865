import { createSelector } from '@reduxjs/toolkit';
import { getServedTimesFromOpeningHours } from '../../../utils/servingTimes';

export const getBuilderMenuItemsState = (state) => state.builderMenuItems;

export const getBuilderMenuName = createSelector(
  getBuilderMenuItemsState,
  (builderMenuItemsState) => builderMenuItemsState.data?.name,
);

export const getBuilderMenuGroupsList = createSelector(
  getBuilderMenuItemsState,
  (builderMenuItemsState) => builderMenuItemsState.data?.menuGroupsList,
);

export const getBuilderMenuGroups = createSelector(
  getBuilderMenuItemsState,
  (builderMenuItemsState) => builderMenuItemsState.data?.menuGroups,
);

const getBuilderMenuGroupId = (_, groupId) => groupId;

export const getBuilderMenuGroup = createSelector(
  getBuilderMenuItemsState,
  getBuilderMenuGroupId,
  (builderMenuItemsState, id) => builderMenuItemsState.data?.menuGroups[id],
);

export const getBuilderMenuSchedule = createSelector(
  getBuilderMenuItemsState,
  getBuilderMenuGroupId,
  (builderMenuItemsState, id) =>
    getServedTimesFromOpeningHours(builderMenuItemsState.data?.schedules[id]),
);

export const getBuilderHasSchedule = createSelector(
  getBuilderMenuItemsState,
  getBuilderMenuGroupId,
  (builderMenuItemsState, id) => !!builderMenuItemsState.data?.schedules[id],
);
