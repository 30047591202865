import fetchGoogleFontsData from '../../api/googleFonts';

/**
 * Colour picker functions
 * */
const hexToRGB = (hex) => {
  // Convert hex color to RGB
  const r = parseInt(hex.substring(1, 3), 16);
  const g = parseInt(hex.substring(3, 5), 16);
  const b = parseInt(hex.substring(5, 7), 16);
  return { r, g, b };
};

const calculateRelativeLuminance = (rgb) => {
  const { r, g, b } = rgb;
  const RsRGB = r / 255;
  const GsRGB = g / 255;
  const BsRGB = b / 255;

  const R = RsRGB <= 0.03928 ? RsRGB / 12.92 : ((RsRGB + 0.055) / 1.055) ** 2.4;
  const G = GsRGB <= 0.03928 ? GsRGB / 12.92 : ((GsRGB + 0.055) / 1.055) ** 2.4;
  const B = BsRGB <= 0.03928 ? BsRGB / 12.92 : ((BsRGB + 0.055) / 1.055) ** 2.4;

  return 0.2126 * R + 0.7152 * G + 0.0722 * B;
};

export const calculateContrastRatio = (color) => {
  // Calculate contrast ratio between color and white (#ffffff)
  const rgb = hexToRGB(color);
  // Relative luminance of white
  const L1 = 1.0;
  const L2 = calculateRelativeLuminance(rgb);

  return (L1 + 0.05) / (L2 + 0.05);
};

/**
 * Font picker functions
 * */
export const fetchGoogleFonts = async () => {
  const items = await fetchGoogleFontsData();
  return items.map((font) => font.family);
};

export const fetchPaginatedFonts = async (start = 0, limit = 20) => {
  const items = await fetchGoogleFontsData();
  return items.slice(start, limit).map((font) => font.family);
};

export const generateGoogleFontsURL = (fontFamilies, currentFont) => {
  const base = 'https://fonts.googleapis.com/css2?';
  const families = fontFamilies.map((font) => `family=${font.replace(/ /g, '+')}`).join('&');
  const currentFamily =
    currentFont && !fontFamilies.includes(currentFont) ? `&family=${currentFont}` : '';
  return `${base}${families}${currentFamily}&display=swap`;
};

export const loadFonts = (url) => {
  const linkId = 'dynamic-fonts-stylesheet';

  const link = document.createElement('link');
  link.id = linkId;
  link.href = url;
  link.rel = 'stylesheet';
  link.type = 'text/css';
  document.head.appendChild(link);
};

export const removeFonts = () => {
  const linkId = 'dynamic-fonts-stylesheet';
  const links = document.querySelectorAll(`#${linkId}`);

  links.forEach((link) => {
    document.head.removeChild(link);
  });
};
