import React from 'react';
import { Chip, TableCell, TableRow, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { ADJUSTMENT_TYPES, SCHEDULE_TYPES } from '../../../../shared/constants/venueAdjustments';

const useStyles = makeStyles((theme) => ({
  adjustmentType: {
    ...theme.customFonts.smallBold,
    textDecoration: 'none',
    color: '#000',
  },
  activeChip: {
    backgroundColor: '#87EB80',
    ...theme.customFonts.xSmall,
    color: '#000',
  },
  inactiveChip: {
    backgroundColor: '#E0E0EB',
    ...theme.customFonts.xSmall,
    color: '#000',
  },
  small: {
    ...theme.customFonts.small,
    color: '#000',
  },
}));

const truncateString = (str, num) => {
  if (str?.length > num) {
    return `${str.slice(0, num)}...`;
  }
  return str;
};

const AdjustmentRow = ({ adjustment }) => {
  const classes = useStyles();

  const formattedOption = ADJUSTMENT_TYPES.find((type) => type.value === adjustment.option)?.label;
  const formattedSchedule = SCHEDULE_TYPES.find(
    (type) => type.value === adjustment.schedule,
  )?.label;
  const formattedType = adjustment?.type?.toLowerCase().replace(/^\w/, (c) => c.toUpperCase());
  const formattedName = truncateString(adjustment.name, 20);
  const formattedDescription = truncateString(adjustment.description, 20);

  const getStatusChip = () => {
    switch (adjustment.status) {
      case 'ACTIVE':
        return <Chip label="Active" size="small" className={classes.activeChip} />;
      case 'COMPLETED':
        return <Chip label="Completed" size="small" className={classes.activeChip} />;
      case 'DEACTIVATED':
        return <Chip label="Deactivated" size="small" className={classes.inactiveChip} />;
      default:
        return null;
    }
  };

  const getEndDate = () => {
    if (adjustment.schedule === 'RECURRING' && adjustment.timesDue === -1) {
      return 'Never';
    }

    if (adjustment.schedule !== 'ONE_OFF') {
      const startDate = moment(adjustment.startDate);
      return startDate.add(adjustment.timesDue, 'weeks').format('DD MMM YY');
    }

    return '-';
  };

  return (
    <TableRow key={adjustment.adjustmentId}>
      <TableCell>
        <Link
          to={`/admin/delivery-adjustments/${adjustment.adjustmentId}`}
          className={classes.adjustmentType}
        >
          {formattedOption || '-'}
        </Link>
      </TableCell>
      <TableCell align="right">
        <Typography variant="body1" className={classes.small}>
          {adjustment.amount && `£${parseFloat(adjustment.amount).toFixed(2)}`}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body1" className={classes.small}>
          {formattedType || '-'}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body1" className={classes.small}>
          {formattedSchedule || '-'}
        </Typography>
      </TableCell>
      <TableCell>{getStatusChip()}</TableCell>
      <TableCell align="right">
        <Typography variant="body1" className={classes.small}>
          {moment(adjustment.startDate).format('DD MMM YY')}
        </Typography>
      </TableCell>
      <TableCell align="right">
        <Typography variant="body1" className={classes.small}>
          {getEndDate()}
        </Typography>
      </TableCell>
      <TableCell align="right">
        <Typography variant="body1" className={classes.small}>
          {adjustment.remainingAmount
            ? `£${parseFloat(adjustment.remainingAmount).toFixed(2)}`
            : '£0.00'}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body1" className={classes.small}>
          {formattedName || '-'}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body1" className={classes.small}>
          {formattedDescription || '-'}
        </Typography>
      </TableCell>
    </TableRow>
  );
};

export default AdjustmentRow;
