import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { FiPlus } from 'react-icons/fi';

import { makeStyles } from '@mui/styles';
import { Box, Button, Grid, Typography } from '@mui/material';

import { getErrorMessage } from '../../shared/utils/errors';
import { useNotifications } from '../../shared/contexts/Notifications/useNotifications';

import { clearMenuItem, fetchMenuItem, updateMenuItem } from '../../store/menuItems';
import { getMenuItemState } from '../../store/menuItems/selectors';
import { getIngredientsState } from '../../store/ingredients/selectors';

import Page from '../../components/Page';
import { createIngredient, fetchIngredients } from '../../store/ingredients';
import AddAttributes from '../../components/AddAttributes';
import withVenue from '../../hoc/withVenue';
import shouldLoad from '../../shared/utils/shouldLoad';
import CustomDialog from '../../components/CustomDialog';
import IngredientForm from '../../components/IngredientForm';

const useStyles = makeStyles(() => ({
  heading: {
    marginBottom: '20px',
  },
}));

const Attributes = () => {
  const history = useHistory();
  const { menuItemId } = useParams();
  const classes = useStyles();
  const { showErrorNotification, showSuccessNotification } = useNotifications();
  const dispatch = useDispatch();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const menuItemState = useSelector(getMenuItemState);
  const ingredientsState = useSelector(getIngredientsState);
  const { loading: loadingItem, data: menuItem, error: errorItem } = menuItemState;
  const {
    loading: loadingIngredients,
    data: ingredients,
    error: errorIngredients,
  } = ingredientsState;

  const handleOnSave = async (values) => {
    try {
      await dispatch(
        updateMenuItem({
          menuItemId,
          values: { itemIngredients: values },
        }),
      );
      showSuccessNotification('Item has been updated successfully');
      history.push(`/menu-items/${menuItemId}`);
    } catch (error) {
      showErrorNotification(getErrorMessage(error));
    }
  };

  const handleOnSubmit = async (values) => {
    try {
      await dispatch(createIngredient(values));
      showSuccessNotification('Ingredient has been created successfully');
      setIsDialogOpen(false);
      dispatch(fetchIngredients());
    } catch (e) {
      showErrorNotification(getErrorMessage(e));
      setIsDialogOpen(false);
    }
  };

  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const IngredientOptionForm = () => (
    <IngredientForm formAction="create" onSubmit={handleOnSubmit} onCancel={handleCloseDialog} />
  );

  const dialogTitle = 'Create new ingredient';

  useEffect(() => {
    if (shouldLoad(ingredientsState)) dispatch(fetchIngredients());
    if (shouldLoad(menuItemState)) dispatch(fetchMenuItem(menuItemId));
  }, [menuItemId, dispatch, ingredientsState, menuItemState]);

  return (
    <Page loading={loadingItem || loadingIngredients} error={errorItem || errorIngredients}>
      {menuItem && ingredients && (
        <>
          <header>
            <Box className={classes.heading}>
              <Typography variant="h2" component="h1">
                Add to list of Ingredients
              </Typography>
              <Typography paragraph>
                &apos;e.g. pizza toppings, cocktail ingredients. The customer will see this list if
                no item description is provided&apos;
              </Typography>
            </Box>
          </header>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              {!!ingredients.length && (
                <AddAttributes attribute="itemIngredients" onSave={handleOnSave} />
              )}
            </Grid>
          </Grid>
          <>
            <Typography style={{ marginBottom: '20px' }}>
              You must first create ingredients before they can be added here
            </Typography>
          </>
          <Button
            variant="contained"
            color="primary"
            startIcon={<FiPlus />}
            onClick={handleOpenDialog}
          >
            Create &amp; add ingredients
          </Button>
          <CustomDialog
            isDialogOpen={isDialogOpen}
            handleCloseDialog={handleCloseDialog}
            title={dialogTitle}
          >
            <IngredientOptionForm />
          </CustomDialog>
        </>
      )}
    </Page>
  );
};

export default withVenue(Attributes, '/items', clearMenuItem);
