import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { makeStyles } from '@mui/styles';
import { Box, Card, Typography } from '@mui/material';

import Page from '../../components/Page';

import { rangeEnums } from '../../shared/utils/dateRanges';
import withVenue from '../../hoc/withVenue';
import ESPicker from '../../components/ESPicker';
import useESPicker from '../../hooks/useESPicker';
import toUTC from '../../shared/utils/toUTC';
import { getReportSalesByStaffState } from '../../store/reports/selectors';
import { clearReportSalesByStaff, fetchReportSalesByStaff } from '../../store/reports';
import OrderableTable from '../../components/OrderableTable';
import DownloadReport from '../../components/DownloadReport';
import PageHeader from '../../components/PageHeader';

const useStyles = makeStyles((theme) => ({
  heading: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  title: {
    flexGrow: 1,
  },
  stripeLink: {
    marginLeft: theme.spacing(3),
  },
  form: {
    width: '80%',
    flexGrow: 2,
  },
  formElement: {
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}));

const ReportStaffPerformance = ({ currentVenueId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const salesByStaffState = useSelector(getReportSalesByStaffState);
  const { loading, data, error } = salesByStaffState;

  const esPicker = useESPicker({ useOrder: false, useSearch: false });
  const { selectedDates, dateRange, reportingType } = esPicker;
  const [downloadPath, setDownloadPath] = useState(null);

  const loadData = useCallback(() => {
    dispatch(
      fetchReportSalesByStaff({
        dateRange,
        from: dateRange === rangeEnums.CUSTOM && toUTC(selectedDates.from),
        to: dateRange === rangeEnums.CUSTOM && toUTC(selectedDates.to),
        reportingType,
      }),
    );
  }, [dateRange, dispatch, selectedDates, reportingType]);

  useEffect(() => {
    loadData();
  }, [dispatch, loadData]);

  useEffect(() => {
    const { from, to } = selectedDates;
    const params = dateRange ? `gte=${from}&lte=${to}` : '';
    setDownloadPath(`/reports/salesByStaffMember/export?${params}`);
  }, [dateRange, selectedDates]);

  return (
    <>
      <PageHeader fullWidth>
        <Box className={classes.heading}>
          <Typography className={classes.title} variant="h2" component="h1">
            Team Performance
          </Typography>
        </Box>
        <DownloadReport
          path={downloadPath}
          type="salesByStaffMember"
          variant="text"
          title="Export"
          fullWidth={false}
          includeVouchers="exclude"
        />
        <ESPicker esPicker={esPicker} left />
      </PageHeader>
      <Page loading={loading} error={error} fullWidth>
        {currentVenueId && data && (
          <>
            <Card>
              <OrderableTable
                tableData={data}
                zeroCheckTitle="orders"
                rowTitle="staffName"
                titles={['MEMBER', 'SALES', 'ORDERS', 'AV. SALE PER ORDER']}
              />
            </Card>
          </>
        )}
      </Page>
    </>
  );
};

export default withVenue(ReportStaffPerformance, null, clearReportSalesByStaff);
