/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Paper, Typography, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { MdAccountBalance } from 'react-icons/md';

const useStyles = makeStyles((theme) => {
  const styles = {
    container: {
      padding: theme.spacing(2),
      height: '100%',
    },
    cardTitle: {
      ...theme.customFonts.mediumBold,
    },
    cashbackTitle: {
      marginTop: theme.spacing(2),
      ...theme.customFonts.largeBold,
      textAlign: 'center',
    },
    cashbackAmount: {
      ...theme.customFonts.xxLargeBold,
      textAlign: 'center',
    },
    cashbackPotential: {
      ...theme.customFonts.large,
      textAlign: 'center',
    },
    cashbackValues: {
      display: 'flex',
      justifyContent: 'space-around',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(3),
    },
    cashbackValueContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    cashbackValueTitle: {
      ...theme.customFonts.small,
    },
    cashbackValueBox: {
      width: 40,
      height: 15,
      borderRadius: theme.shape.borderRadius,
      border: `1px solid ${theme.customPalette.midnight}`,
    },
    cashbackValueBoxFilled: {
      backgroundColor: theme.sessionsScorePalette.compliantGreen,
    },
    quarterCashbackContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      '& svg': {
        ...theme.customFonts.xxLargeBold,
        marginRight: theme.spacing(3),
      },
    },
    quarterPayout: {
      ...theme.customFonts.small,
      textAlign: 'center',
    },
    notCompliantValueBox: {
      backgroundColor: theme.sessionsScorePalette.nonCompliantLightGrey,
    },
    notCompliantValueBoxFilled: {
      backgroundColor: theme.sessionsScorePalette.nonCompliantDarkGrey,
    },
  };

  const colors = [
    theme.sessionsScorePalette.reallyRed,
    theme.sessionsScorePalette.red,
    theme.sessionsScorePalette.yellow,
    theme.sessionsScorePalette.green,
    theme.sessionsScorePalette.reallyGreen,
  ];

  colors.forEach((color, index) => {
    styles[`cashbackValueBoxFilled${index + 1}`] = { backgroundColor: color };
  });

  return styles;
});

const getValueBoxStyle = ({ scCompliant, value, bonusAmountIndex, bonusAmount, classes }) => {
  if (scCompliant && value < bonusAmount) {
    return `${classes[`cashbackValueBoxFilled${bonusAmountIndex}`]}`;
  }
  if (!scCompliant && value < bonusAmount) {
    return classes.notCompliantValueBoxFilled;
  }

  if (!scCompliant && !(value < bonusAmount)) {
    return classes.notCompliantValueBox;
  }
  return null;
};

const YourCashback = ({ scoreData }) => {
  const {
    timePeriod,
    bonusAmount,
    bonusPotential,
    quarterToDateCashbackTotal,
    scales,
    scCompliant,
  } = scoreData;
  const { cashbackValue } = scales;
  const classes = useStyles();
  const bonusAmountIndex = cashbackValue.ranges.findIndex((value) => bonusAmount <= value);

  return (
    <Paper className={classes.container}>
      <Typography variant="h2" component="h2" className={classes.cardTitle}>
        Your Cashback
      </Typography>
      <Box>
        <Typography component="h3" className={classes.cashbackTitle}>
          {timePeriod === 'THIS_FORTNIGHT'
            ? `This fortnight's cashback:`
            : `Last fortnight's cashback:`}
        </Typography>
        <Typography component="p" className={classes.cashbackAmount}>
          + £{bonusAmount?.toFixed(2)}
        </Typography>
        <Typography component="p" className={classes.cashbackPotential}>
          Out of a potential £{bonusPotential?.toFixed(2)}
        </Typography>
        <Box className={classes.cashbackValues}>
          {cashbackValue.ranges.map((value, index) => (
            <Box className={classes.cashbackValueContainer} key={`${timePeriod}-${value}-${index}`}>
              <Typography component="p" className={classes.cashbackValueTitle}>
                £{value}
              </Typography>
              <Box
                key={value}
                className={`${classes.cashbackValueBox} ${getValueBoxStyle({
                  scCompliant,
                  value,
                  bonusAmountIndex,
                  bonusAmount,
                  classes,
                })}`}
              />
            </Box>
          ))}
        </Box>
        <Typography component="h3" className={classes.cashbackTitle}>
          Total cashback for this quarter:
        </Typography>
        <Box className={classes.quarterCashbackContainer}>
          <MdAccountBalance />
          <Typography component="p" className={classes.cashbackAmount}>
            £{quarterToDateCashbackTotal?.toFixed(2)}
          </Typography>
        </Box>
        <Typography component="p" className={classes.quarterPayout}>
          Paid out at the end of the quarter
        </Typography>
      </Box>
    </Paper>
  );
};

export default YourCashback;
