import { createSlice } from '@reduxjs/toolkit';
import {
  asyncInitialState as initialState,
  createAsyncAction,
  createAsyncReducerHandlers,
  fetchPageAsyncReducerHandler,
} from '../utils/async';
import {
  fetchCardPayouts as fetchCardPayoutsApi,
  retryPayout as retryPayoutApi,
} from '../../api/payouts-card';

export const fetchCardPayouts = createAsyncAction('fetch-card-payouts', fetchCardPayoutsApi);
export const retryPayout = createAsyncAction('retry-payouts', retryPayoutApi);
export const fetchCardPayoutsPage = createAsyncAction(
  'fetch-card-payouts-page',
  fetchCardPayoutsApi,
);

const payoutsSlice = createSlice({
  name: 'payouts-card',
  initialState,
  reducers: {
    clearPayouts(state) {
      delete state.data;
    },
  },
  extraReducers: {
    ...createAsyncReducerHandlers(fetchCardPayouts),
    ...fetchPageAsyncReducerHandler(fetchCardPayoutsPage),
  },
});
export const { clearPayouts } = payoutsSlice.actions;

export const { reducer: cardPayouts } = payoutsSlice;
