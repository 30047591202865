import moment from 'moment-timezone';

export default (balance, enabled, endDate) => {
  const now = moment().local();
  const momentEndDate = moment(endDate).local();
  const isExpired = now.isAfter(momentEndDate);

  // if disabled always show DISABLED
  if (!enabled) return 'DISABLED';
  // if expired show EXPIRED or UNSETTLED
  if (isExpired) return balance === 0 ? 'EXPIRED' : 'UNSETTLED';
  // if not expired and balance zero then show SETTLED
  if (balance === 0) return 'SETTLED';
  // if not expired and balance not zero then show OPEN, voucher can be used.
  return 'OPEN';
};
