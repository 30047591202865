import { Button } from '@mui/material';
import { withStyles } from '@mui/styles';

export default withStyles(
  (theme) => ({
    root: {
      borderRadius: 8,
      fontSize: 13,
      fontWeight: '400',
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      background: '#333333',
      '&:hover': {
        color: '#333333',
        background: 'white',
      },
    },
    outlined: {
      background: 'white',
      color: '#333333',
      borderColor: '#333333',
      boxShadow:
        '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
      '&:hover': {
        color: '#fff',
        background: '#333333',
        borderColor: '#333333',
      },
    },
  }),
  { withTheme: true },
)(Button);
