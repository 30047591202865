import React, { useEffect } from 'react';

import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';

import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import Page from '../../components/Page';
import withVenue from '../../hoc/withVenue';
import Overview from './OverView';
import ManageTills from './ManageTills';
import { clearTills, createTill, deleteTill, fetchTills, updateTill } from '../../store/tills';
import { getTillsState } from '../../store/tills/selectors';
import { getMobileDevicesState } from '../../store/mobiles/selectors';
import { clearMobileDevices, fetchMobileDevices } from '../../store/mobiles';
import { useNotifications } from '../../shared/contexts/Notifications/useNotifications';
import { getErrorMessage } from '../../shared/utils/errors';
import { getFloatsState } from '../../store/floats/selectors';
import { clearFloats, fetchFloats } from '../../store/floats';
import TabContainer from '../../components/TabContainer';
import shouldLoad from '../../shared/utils/shouldLoad';
import PageHeader from '../../components/PageHeader';

const useStyles = makeStyles((theme) => ({
  page: {
    maxWidth: '1052px',
    margin: '0 auto',
  },
  heading: {
    marginBottom: '20px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  title: {
    fontFamily: 'Sen',
    flexGrow: 1,
  },
  areas: {
    gap: theme.spacing(2),
  },
  tabPanel: {
    paddingLeft: 0,
    paddingRight: 0,
  },
}));

const Tills = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [selectedTill, setSelectedTill] = React.useState(null);
  const { showErrorNotification, showSuccessNotification } = useNotifications();
  const { tabname } = useParams();

  const tillsState = useSelector(getTillsState);
  const mobileDevicesState = useSelector(getMobileDevicesState);
  const floatsState = useSelector(getFloatsState);
  const { loading: tillsLoading, data: tills, error: tillsError } = tillsState;
  const { loading: mobilesLoading, data: mobiles, error: mobilesError } = mobileDevicesState;
  const { loading: floatsLoading, data: floats, error: floatsError } = floatsState;

  const addTill = async (values) => {
    try {
      await dispatch(createTill(values));
      dispatch(fetchTills());
      showSuccessNotification('Till has been created successfully');
    } catch (localError) {
      showErrorNotification(`Error: ${getErrorMessage(localError)}`);
    }
  };
  const handleEditTill = async (values) => {
    try {
      await dispatch(updateTill(values));
      dispatch(fetchTills());
      showSuccessNotification('Till has been edited successfully');
    } catch (localError) {
      showErrorNotification(`Error: ${getErrorMessage(localError)}`);
    }
  };
  const handleDeleteTill = async (mobileDeviceId) => {
    try {
      await dispatch(deleteTill(mobileDeviceId));
      dispatch(fetchTills());
      showSuccessNotification('Till has been deleted successfully');
    } catch (localError) {
      showErrorNotification(`Error: ${getErrorMessage(localError)}`);
    }
  };

  const handleFetchFloats = async ({ tillId, from, to, reportingType }) => {
    if (!tillId) return;
    setSelectedTill(tillId);
    dispatch(fetchFloats({ tillId, from, to, reportingType }));
  };

  useEffect(() => {
    if (shouldLoad(tillsState)) dispatch(fetchTills());
    if (shouldLoad(mobileDevicesState)) dispatch(fetchMobileDevices());
  }, [dispatch, mobileDevicesState, tillsState]);

  useEffect(() => {
    const firstTill = tills && tills[0];
    if (firstTill) dispatch(fetchFloats({ tillId: firstTill.tillId }));
  }, [dispatch, tills]);

  useEffect(() => {
    if (!selectedTill && tills && tills.length > 0) {
      setSelectedTill(tills[0].tillId);
    }
  }, [selectedTill, tills]);

  return (
    <>
      <PageHeader>
        <div className={classes.heading}>
          <Typography className={classes.title} variant="h1" component="h1">
            Tills
          </Typography>
        </div>
      </PageHeader>
      <Page
        loading={tillsLoading || mobilesLoading || floatsLoading}
        error={tillsError || mobilesError || floatsError}
      >
        <div className={classes.page}>
          <TabContainer titles={['Overview', 'Manage Tills']} defaultTab={tabname} path="/tills/">
            <Overview
              tills={tills}
              floats={floats}
              onSubmit={handleFetchFloats}
              selectedTill={selectedTill}
            />
            <ManageTills
              tills={tills}
              addTill={addTill}
              mobiles={mobiles}
              editTill={handleEditTill}
              deleteTill={handleDeleteTill}
              floats={floats}
            />
          </TabContainer>
        </div>
      </Page>
    </>
  );
};

export default withVenue(Tills, null, [clearTills, clearMobileDevices, clearFloats]);
