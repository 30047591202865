import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { Box, Button } from '@mui/material';

import { makeStyles } from '@mui/styles';

import { createIngredient, fetchIngredients } from '../../store/ingredients';

import withVenue from '../../hoc/withVenue';
import { useNotifications } from '../../shared/contexts/Notifications/useNotifications';
import { getErrorMessage } from '../../shared/utils/errors';
import CustomDialog from '../../components/CustomDialog';
import IngredientForm from '../../components/IngredientForm';
import useRoles from '../../hooks/useRoles';

const useStyles = makeStyles((theme) => ({
  button: {
    textWrap: 'nowrap',
    paddingLeft: '10px',
    marginLeft: 'auto',
    [theme.breakpoints.up('xl')]: {
      marginLeft: 'unset',
    },
  },
}));

const NewIngredientButton = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [ingredientData, setIngredientData] = useState(null);
  const [formAction, setFormAction] = useState('');
  const { showErrorNotification, showSuccessNotification } = useNotifications();
  const { isRoleAtLeastManager } = useRoles();

  const handleOnSubmit = async (values) => {
    try {
      await dispatch(createIngredient(values));
      await dispatch(fetchIngredients());
      showSuccessNotification('Ingredient has been added successfully');
      setIsDialogOpen(false);
    } catch (e) {
      showErrorNotification(getErrorMessage(e));
      setIsDialogOpen(false);
    }
  };

  const handleOpenDialog = () => {
    setIngredientData(null);
    setFormAction('create');
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  return (
    <>
      <Box className={classes.button}>
        <Button
          variant="contained"
          color="primary"
          disabled={!isRoleAtLeastManager()}
          onClick={handleOpenDialog}
        >
          New Ingredient
        </Button>
      </Box>
      <Box>
        <aside>
          <CustomDialog
            isDialogOpen={isDialogOpen}
            handleCloseDialog={handleCloseDialog}
            title="Create new ingredient"
          >
            <IngredientForm
              formAction={formAction}
              ingredientData={ingredientData}
              onSubmit={handleOnSubmit}
              onCancel={handleCloseDialog}
            />
          </CustomDialog>
        </aside>
      </Box>
    </>
  );
};

export default withVenue(NewIngredientButton);
