import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { Droppable as DndDroppable } from 'react-beautiful-dnd';

const useStyles = makeStyles(() => ({
  root: {},
}));

const Droppable = ({ children, droppableId, type, isDropDisabled }) => {
  const classes = useStyles();

  return (
    <DndDroppable droppableId={droppableId} type={type} isDropDisabled={isDropDisabled}>
      {(provided) => (
        <div className={classes.root} ref={provided.innerRef}>
          {children}
          {provided.placeholder}
        </div>
      )}
    </DndDroppable>
  );
};

Droppable.propTypes = {
  children: PropTypes.element.isRequired,
  droppableId: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  isDropDisabled: PropTypes.bool,
};

Droppable.defaultProps = {
  isDropDisabled: false,
};

export default Droppable;
