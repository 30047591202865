import { createSelector } from '@reduxjs/toolkit';
import { getServedTimesFromOpeningHours } from '../utils/servingTimes';

export const getVenueMenuItemsState = (state) => state.venueMenuItems;

export const getVenueMenuName = createSelector(
  getVenueMenuItemsState,
  (venueMenuItemsState) => venueMenuItemsState.data?.name,
);

export const getMenuGroupsList = createSelector(
  getVenueMenuItemsState,
  (venueMenuItemsState) => venueMenuItemsState.data?.menuGroupsList,
);

export const getMenuGroups = createSelector(
  getVenueMenuItemsState,
  (venueMenuItemsState) => venueMenuItemsState.data?.menuGroups,
);

const getMenuGroupId = (_, groupId) => groupId;

export const getMenuGroup = createSelector(
  getVenueMenuItemsState,
  getMenuGroupId,
  (venueMenuItemsState, id) => venueMenuItemsState.data?.menuGroups[id],
);

export const getMenuSchedule = createSelector(
  getVenueMenuItemsState,
  getMenuGroupId,
  (venueMenuItemsState, id) =>
    getServedTimesFromOpeningHours(venueMenuItemsState.data?.schedules[id]),
);

export const getHasSchedule = createSelector(
  getVenueMenuItemsState,
  getMenuGroupId,
  (venueMenuItemsState, id) => !!venueMenuItemsState.data?.schedules[id],
);
