import { createSlice } from '@reduxjs/toolkit';
import {
  asyncInitialState as initialState,
  createAsyncAction,
  createAsyncReducerHandlers,
} from '../utils/async';
import {
  fetchOptions as fetchOptionsApi,
  fetchOption as fetchOptionApi,
  createOption as createOptionApi,
  updateOption as updateOptionApi,
} from '../../api/options';

export const fetchOptions = createAsyncAction('fetch-options', fetchOptionsApi);

export const fetchOption = createAsyncAction('fetch-option', fetchOptionApi);

export const createOption = createAsyncAction('create-option', createOptionApi);

export const updateOption = createAsyncAction('update-option', updateOptionApi);

const optionsSlice = createSlice({
  name: 'options',
  initialState,
  reducers: {
    clearOptions(state) {
      delete state.data;
    },
  },
  extraReducers: {
    ...createAsyncReducerHandlers(fetchOptions),
  },
});

const optionSlice = createSlice({
  name: 'option',
  initialState,
  reducers: {
    clearOption(state) {
      delete state.data;
    },
  },
  extraReducers: {
    ...createAsyncReducerHandlers(fetchOption),
    ...createAsyncReducerHandlers(createOption),
    ...createAsyncReducerHandlers(updateOption),
  },
});
export const { clearOptions } = optionsSlice.actions;
export const { clearOption } = optionSlice.actions;

export const { reducer: options } = optionsSlice;
export const { reducer: option } = optionSlice;
