import { createSlice } from '@reduxjs/toolkit';
import {
  asyncInitialState as initialState,
  createAsyncAction,
  createAsyncReducerHandlers,
} from '../../utils/async';
import {
  fetchBrandsModifiersApi,
  fetchBrandsModifierApi,
  createBrandsModifierApi,
  updateBrandsModifierApi,
  deleteBrandsModifierApi,
} from '../../../api/brands/brandsModifiers';

export const fetchBrandsModifiers = createAsyncAction(
  'fetch-brands-modifiers',
  fetchBrandsModifiersApi,
);

export const fetchBrandsModifier = createAsyncAction(
  'fetch-brands-modifier',
  fetchBrandsModifierApi,
);

export const deleteBrandsModifier = createAsyncAction(
  'delete-brands-modifier',
  deleteBrandsModifierApi,
);

export const createBrandsModifier = createAsyncAction(
  'create-brands-modifier',
  createBrandsModifierApi,
);

export const updateBrandsModifier = createAsyncAction(
  'update-brands-modifier',
  updateBrandsModifierApi,
);

const modifiersSlice = createSlice({
  name: 'brandsModifiers',
  initialState,
  reducers: {
    clearBrandsModifiers(state) {
      delete state.data;
    },
  },
  extraReducers: {
    ...createAsyncReducerHandlers(fetchBrandsModifiers),
    ...createAsyncReducerHandlers(deleteBrandsModifier),
  },
});

const modifierSlice = createSlice({
  name: 'brandsModifier',
  initialState,
  reducers: {
    clearBrandsModifier(state) {
      delete state.data;
    },
  },
  extraReducers: {
    ...createAsyncReducerHandlers(fetchBrandsModifier),
    ...createAsyncReducerHandlers(createBrandsModifier),
    ...createAsyncReducerHandlers(updateBrandsModifier),
  },
});
export const { clearBrandsModifier } = modifierSlice.actions;
export const { clearBrandsModifiers } = modifiersSlice.actions;

export const { reducer: brandsModifiers } = modifiersSlice;
export const { reducer: brandsModifier } = modifierSlice;
