import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import withVenue from '../../hoc/withVenue';
import shouldLoad from '../../shared/utils/shouldLoad';
import { clearVenueBrands, fetchBrandsForVenue } from '../../store/brands/venueBrands';
import getVenueBrandsState from '../../store/brands/venueBrands/selectors';
import ReportContents from './ReportContents';

const ReportDelivery = ({ currentVenueId }) => {
  const dispatch = useDispatch();
  const venueBrandsState = useSelector(getVenueBrandsState);
  const { data: brands } = venueBrandsState;

  useEffect(() => {
    if (shouldLoad(venueBrandsState)) dispatch(fetchBrandsForVenue());
  }, [dispatch, venueBrandsState]);

  return currentVenueId && brands && <ReportContents brands={brands} />;
};

export default withVenue(ReportDelivery, null, [clearVenueBrands]);
