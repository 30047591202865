import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'ramda';
import { Card, Grid, Typography } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';

import { parseGooglePlaceOpenings } from '../../shared/utils/googlePlaces';
import { useNotifications } from '../../shared/contexts/Notifications/useNotifications';
import EditWeekTimeRangeDialog from './EditWeekTimeRangeDialog/EditWeekTimeRangeDialog';
import WeekTimeRangeDay from './WeekTimeRangeDay';

const useStyles = makeStyles((theme) => ({
  header: {
    marginBottom: '10px',
  },
  innerText: {
    ...theme.customFonts.mediumBold,
  },
  editButton: {
    backgroundColor: theme.customPalette.greyLightest,
    borderColor: theme.customPalette.grey,
    ...theme.customFonts.medium,
    borderRadius: '8px',
  },
}));

const WeekTimeRangeSelector = ({
  validTimes: passedValidTimes,
  timezone,
  onSubmit,
  subjectText,
}) => {
  const classes = useStyles();
  const [validTimes, setValidTimes] = useState(passedValidTimes);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [parsedValidTimes, setParsedValidTimes] = useState(
    parseGooglePlaceOpenings(passedValidTimes),
  );
  const hasValidTimes = !isEmpty(validTimes);
  const { showErrorNotification, showSuccessNotification } = useNotifications();
  const finalSubjectText = subjectText || 'Valid times';

  const openEditDialog = () => {
    setIsEditDialogOpen(true);
  };

  const closeEditDialog = () => {
    setIsEditDialogOpen(false);
  };

  const editWeekTimeRange = async (editedOpenings) => {
    try {
      closeEditDialog();
      setValidTimes(editedOpenings);
      setParsedValidTimes(parseGooglePlaceOpenings(editedOpenings));
      onSubmit(editedOpenings);
      showSuccessNotification(`Successfully updated ${finalSubjectText}`);
    } catch (error) {
      showErrorNotification(error);
    }
  };

  return (
    <>
      <header className={classes.header}>
        <Typography variant="h2" component="h1">
          Opening Hours
        </Typography>
        <Typography variant="h3" component="h3">
          The system will only be available during the times shown.
        </Typography>
      </header>
      <Card className={classes.card}>
        {!hasValidTimes && (
          <CardContent>
            {finalSubjectText} have not been found. Please add them manually
          </CardContent>
        )}
        {hasValidTimes && (
          <CardContent>
            <Grid container>
              {parsedValidTimes.map((validTime) => (
                <WeekTimeRangeDay validTime={validTime} key={validTime.day} timezone={timezone} />
              ))}
            </Grid>
          </CardContent>
        )}
        <CardContent style={{ padding: '16px' }}>
          <Button
            size="small"
            variant="outlined"
            className={classes.editButton}
            onClick={openEditDialog}
          >
            Edit
          </Button>
        </CardContent>
        <EditWeekTimeRangeDialog
          open={isEditDialogOpen}
          onClose={closeEditDialog}
          onEditWeekTimeRange={editWeekTimeRange}
          validTimes={validTimes}
          subjectText={subjectText}
        />
      </Card>
    </>
  );
};

WeekTimeRangeSelector.propTypes = {
  // should be moment type
  // eslint-disable-next-line react/forbid-prop-types
  validTimes: PropTypes.array.isRequired,
  timezone: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
export default WeekTimeRangeSelector;
