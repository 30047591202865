import { createSlice } from '@reduxjs/toolkit';
import {
  asyncInitialState as initialState,
  createAsyncAction,
  createAsyncReducerHandlers,
  fetchPageAsyncReducerHandler,
} from '../utils/async';
import {
  fetchTakeawayOrders as fetchTakeawayOrdersApi,
  fetchLiveOrders as fetchLiveOrdersApi,
  fetchOrders as fetchOrdersApi,
  fetchOrder as fetchOrderApi,
  acceptOrder as acceptOrderApi,
  refundOrder as refundOrderApi,
  cancelOrder as cancelOrderApi,
  fetchOrderReceipt as fetchOrderReceiptApi,
  acceptLiveOrders as acceptLiveOrdersApi,
  sendPolarisOrders as sendPolarisOrdersApi,
} from '../../api/orders';

export const fetchTakeawayOrders = createAsyncAction(
  'fetch-takeaway-orders',
  fetchTakeawayOrdersApi,
);
export const fetchLiveOrders = createAsyncAction('fetch-live-orders', fetchLiveOrdersApi);
export const fetchOrders = createAsyncAction('fetch-orders', fetchOrdersApi);
export const fetchOrdersPage = createAsyncAction('fetch-orders-page', fetchOrdersApi);
export const fetchOrder = createAsyncAction('fetch-order', fetchOrderApi);
export const acceptOrder = createAsyncAction('accept-order', acceptOrderApi);
export const refundOrder = createAsyncAction('refund-order', refundOrderApi);
export const cancelOrder = createAsyncAction('cancel-order', cancelOrderApi);
export const sendPolarisOrders = createAsyncAction('send-polaris-orders', sendPolarisOrdersApi);
export const fetchOrderReceipt = createAsyncAction('fetch-order-receipt', fetchOrderReceiptApi);
export const acceptLiveOrders = createAsyncAction('accept-live-orders', acceptLiveOrdersApi);

const ordersSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    clearOrders(state) {
      delete state.data;
    },
  },
  extraReducers: {
    ...createAsyncReducerHandlers(fetchOrders),
    ...fetchPageAsyncReducerHandler(fetchOrdersPage),
  },
});
const polarisOrdersSlice = createSlice({
  name: 'polarisOrders',
  initialState,
  reducers: {},
  extraReducers: {
    ...createAsyncReducerHandlers(sendPolarisOrders),
  },
});

const ordersLiveSlice = createSlice({
  name: 'ordersLive',
  initialState,
  reducers: {
    clearLiveOrders(state) {
      delete state.data;
    },
  },
  extraReducers: createAsyncReducerHandlers(fetchLiveOrders),
});

const ordersTakeawaySlice = createSlice({
  name: 'ordersTakeaway',
  initialState,
  reducers: {
    cleartakeAwayOrders(state) {
      delete state.data;
    },
  },
  extraReducers: createAsyncReducerHandlers(fetchTakeawayOrders),
});

const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    clearOrder(state) {
      delete state.data;
    },
  },
  extraReducers: {
    ...createAsyncReducerHandlers(fetchOrder),
    ...createAsyncReducerHandlers(acceptOrder),
    ...createAsyncReducerHandlers(refundOrder),
    ...createAsyncReducerHandlers(cancelOrder),
  },
});
export const { clearOrders } = ordersSlice.actions;
export const { clearLiveOrders } = ordersLiveSlice.actions;
export const { cleartakeAwayOrders } = ordersTakeawaySlice.actions;
export const { clearOrder } = orderSlice.actions;

export const { reducer: orders } = ordersSlice;
export const { reducer: polarisOrders } = polarisOrdersSlice;
export const { reducer: ordersLive } = ordersLiveSlice;
export const { reducer: ordersTakeaway } = ordersTakeawaySlice;
export const { reducer: order } = orderSlice;
