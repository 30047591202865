import React from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import { TextField } from 'formik-mui';
import { Card, CardContent, FormGroup } from '@mui/material';
import UniversalSave from '../UniversalSave';

const TableForm = ({ formAction, initialValues, onSubmit }) => {
  const TableSchema = Yup.object().shape({
    tableName: Yup.string().required('Please enter a name'),
  });

  return (
    <Card>
      <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={TableSchema}>
        {({ values, dirty, isValid, resetForm, errors }) => (
          <>
            <UniversalSave
              isValid={isValid}
              errors={errors}
              dirty={dirty}
              onSave={() => onSubmit(values)}
              onDiscard={resetForm}
            />
            <Form>
              <CardContent>
                <FormGroup>
                  <Field
                    component={TextField}
                    name="tableName"
                    label="Table Name"
                    variant="outlined"
                    fullWidth
                    required
                    margin="normal"
                    disabled={formAction !== 'create'}
                  />
                </FormGroup>
                <FormGroup>
                  <Field
                    component={TextField}
                    name="description"
                    label="Description"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    disabled={formAction !== 'create'}
                  />
                </FormGroup>
              </CardContent>
            </Form>
          </>
        )}
      </Formik>
    </Card>
  );
};

export default TableForm;
