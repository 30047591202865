import { pick } from 'ramda';
import { get, post, patch } from './httpRequest';

export const fetchOptions = async () => {
  const response = await get(`/itemOptions`);
  return response.data;
};

export const fetchOption = async (optionId) => {
  const response = await get(`/itemOptions/${optionId}`);
  return response.data;
};

export const createOption = async (option) => {
  const response = await post(`/itemOptions`, {
    data: option,
  });

  return response.data;
};

export const updateOption = async ({ optionId, values }) => {
  const trimmedValues = {
    ...pick(['type', 'optionName'], values),
  };

  await patch(`/itemOptions/${optionId}`, {
    data: trimmedValues,
  });
};
