import { createSelector } from '@reduxjs/toolkit';
import { ascend, prop, sortWith } from 'ramda';

const ingredientSort = sortWith([ascend(prop('type')), ascend(prop('ingredientName'))]);
export const getIngredientsState = (state) => state.ingredients;

export const getIngredientState = (state) => state.ingredient;

export const getIngredients = createSelector(
  getIngredientsState,
  (ingredientsState) => ingredientsState.data && ingredientSort(ingredientsState.data),
);

export const getIngredientsObj = createSelector(getIngredientsState, (ingredients) => {
  if (!ingredients.data) return null;
  return ingredients.data.reduce((acc, ingredient) => {
    const { ingredientId, ingredientName, type, available, label } = ingredient;
    return { [ingredientId]: { ingredientId, ingredientName, type, available, label }, ...acc };
  }, {});
});
