import moment from 'moment';
import { UNITS } from '../constants/units';
import formatNumber from './formatNumber';

export default ({ value, valueName, units }) => {
  const formatValues = {
    [UNITS.MINUTES]: 'HH:mm (DD/MM)',
    [UNITS.HOURS]: 'HH:mm (DD/MM)',
    [UNITS.DAYS]: 'DD/MM/YY',
    [UNITS.WEEKS]: 'w',
    [UNITS.MONTHS]: 'MMM',
    [UNITS.YEARS]: 'YYYY',
  };
  switch (valueName) {
    case 'date':
      return moment(value).format(formatValues[units] || 'DD/MM/YY');
    case 'sales':
      return `£${formatNumber(value).toLocaleString()}`;
    case 'total':
      return `£${formatNumber(value).toLocaleString()}`;
    case 'Total':
      return `£${formatNumber(value).toLocaleString()}`;
    case 'discountedPrice':
      return `£${formatNumber(value).toLocaleString()}`;
    case 'averageSales':
      return `£${formatNumber(value).toLocaleString()}`;
    case 'tips':
      return `£${formatNumber(value).toLocaleString()}`;
    case 'serviceCharge':
      return `£${formatNumber(value).toLocaleString()}`;
    default:
      return typeof value === 'number' ? formatNumber(value, 0) : value;
  }
};
