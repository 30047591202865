import React from 'react';
import { Container, Card, CardContent, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { isNotFoundError, isForbidden } from '../../shared/utils/errors';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

const getErrorDetails = (error) => {
  const message = error?.response?.data?.message;
  if (isNotFoundError(error)) {
    return (
      <p>
        The page you requested is having difficulty connecting to the server{' '}
        {message && (
          <>
            <br />
            {`(${message})`}
          </>
        )}
      </p>
    );
  }

  if (isForbidden(error)) {
    return (
      <p>
        You do not have access to this page. You may need to sign out and then sign in again{' '}
        {message && (
          <>
            <br />
            {`(${message})`}
          </>
        )}
      </p>
    );
  }

  return (
    <p>
      Something is not right, Please refresh page{' '}
      {message && (
        <>
          <br />
          {`(${message})`}
        </>
      )}
    </p>
  );
};

const PageError = ({ error }) => {
  const classes = useStyles();
  return (
    <Container className={classes.root}>
      <Card>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            {getErrorDetails(error)}
          </Typography>
          <Typography variant="h6" gutterBottom>
            <a href="/">Home</a>
          </Typography>
        </CardContent>
      </Card>
    </Container>
  );
};

export default PageError;
