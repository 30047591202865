import { createSlice } from '@reduxjs/toolkit';
import {
  asyncInitialState as initialState,
  createAsyncAction,
  createAsyncReducerHandlers,
} from '../utils/async';

import {
  fetchImagesToReview as fetchImagesToReviewApi,
  postImagesToReview as postImagesToReviewApi,
// eslint-disable-next-line import/named
} from '../../api/imagesReview';

export const fetchImagesReview = createAsyncAction('fetch-images-review', fetchImagesToReviewApi);
export const postImagesReview = createAsyncAction('post-images-review', postImagesToReviewApi);

const imagesReviewSlice = createSlice({
  name: 'imagesReview',
  initialState,
  reducers: {},
  extraReducers: {
    ...createAsyncReducerHandlers(fetchImagesReview),
    ...createAsyncReducerHandlers(postImagesReview),
  },
});

export const { reducer: imagesReview } = imagesReviewSlice;
