import { omit } from 'ramda';
import { patchBare } from '../../httpRequest';

export const updateBuilderMenuItemsApi = async (menuId, items) => {
  const strippedItems = items.map((item) => ({
    ...omit(['imgUploadUrl'], item),
  }));

  const menuItems = await patchBare(`brands/menus/${menuId}`, {
    data: {
      items: strippedItems,
    },
  });
  return menuItems.data;
};

export default {};
