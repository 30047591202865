import { getBare, patchBare, deleteBare, get, post, del } from './httpRequest';

export const fetchSettings = async () => {
  const response = await getBare(`settings`);
  return response.data;
};

export const updateSettings = async ({ values }) => {
  await patchBare(`settings`, {
    data: values,
  });
};

export const forcePasswordReset = async ({ values }) => {
  await post(`/emails`, {
    data: values,
  });
};

export const deleteVenue = async (venueId) => {
  await deleteBare(`venues/${venueId}`);
};

export const fetchPaymentFees = async () => {
  await get(`/paymentFees`);
};

export const deleteUser = async (email) => {
  await del(`/users`, {
    data: {
      email,
    },
  });
};
