import React, { useState } from 'react';
import { Box, Divider, Link } from '@mui/material';
import { makeStyles } from '@mui/styles';
import QRCode from 'qrcode.react';
import { FiArrowUpRight } from 'react-icons/fi';
import isDesktop from '../../shared/utils/isDesktop';

const useStyles = makeStyles(() => ({
  qrImgContainer: {
    display: 'none',
  },
  viewMenuDiv: {
    marginTop: 'auto',
    flexDirection: 'column',
    position: 'sticky',
    width: '100%',
    bottom: '0',
    backgroundColor: '#fff',
    justifyContent: 'center',
    alignItems: 'center',
  },
  muiLink: {
    display: 'flex',
    alignItems: 'center',
    color: '#334E68',
  },
}));

const QrContainer = ({ title, value, isLink, isNavigation = false }) => {
  const classes = useStyles();
  const [showQRCode, setShowQRCode] = useState(!isNavigation);
  const [hoverTimeout, setHoverTimeout] = useState(null);

  const handleMouseEnter = () => {
    if (isNavigation) {
      const timeout = setTimeout(() => {
        setShowQRCode(true);
      }, 1000);
      setHoverTimeout(timeout);
    }
  };

  const handleMouseLeave = () => {
    if (isNavigation) {
      clearTimeout(hoverTimeout);
      setShowQRCode(false);
    }
  };

  return (
    <Box className={classes.viewMenuDiv}>
      <Divider />
      <Box p={2} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <QRCode
          value={value}
          size={125}
          className={isLink && classes.qrImgContainer}
          style={{
            display: showQRCode && isDesktop ? 'flex' : 'none',
            opacity: '0.8',
            paddingBottom: '8px',
          }}
        />
        {isLink && (
          <Link rel="noopener noreferrer" href={value} target="_blank" className={classes.muiLink}>
            {title}
            <FiArrowUpRight />
          </Link>
        )}
      </Box>
    </Box>
  );
};

export default QrContainer;
