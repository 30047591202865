import React from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Button, CardActions, CardContent } from '@mui/material';
import { TextField } from 'formik-mui';

const VenuePriorityEditForm = ({ initialValues, handleCloseDialog, onSubmit }) => {
  const finalInitialValues = initialValues || {
    name: '',
  };
  const formActionText = initialValues ? 'Update' : 'Create';
  const VenuePrioritySchema = Yup.object().shape({
    name: Yup.string().required(),
    priorityId: Yup.string().optional(),
  });

  return (
    <Formik
      initialValues={finalInitialValues}
      onSubmit={onSubmit}
      validationSchema={VenuePrioritySchema}
    >
      {() => (
        <Form>
          <CardContent>
            <Field
              component={TextField}
              fullWidth
              size="small"
              name="name"
              label="Course name"
              variant="outlined"
              margin="normal"
              required
            />
          </CardContent>
          <CardActions>
            <Button variant="text" color="primary" onClick={handleCloseDialog}>
              Cancel
            </Button>

            <Button variant="outlined" color="primary" type="submit">
              {formActionText} course
            </Button>
          </CardActions>
        </Form>
      )}
    </Formik>
  );
};

export default VenuePriorityEditForm;
