import React from 'react';
import * as Yup from 'yup';
import { Button, DialogActions, DialogContent } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-mui';
import { useDispatch } from 'react-redux';
import CustomDialog from '../../components/CustomDialog';
import useEndpoint from '../../hooks/useEndpoint';
import { getAccountsState, getAccountState } from '../../store/accounts/selectors';
import { createAccount, fetchAccounts, updateAccount } from '../../store/accounts';
import { useNotifications } from '../../shared/contexts/Notifications/useNotifications';
import { getErrorMessage } from '../../shared/utils/errors';

const NewAccountSchema = Yup.object().shape({
  name: Yup.string()
    .max(25, 'Name is too long, maximum 25 characters')
    .required('Name is required'),
  description: Yup.string().max(60, 'Description is too long, maximum 60 characters'),
});

function AccountDialog({ isAddDialog, isDialogOpen, handleCloseDialog, initialValues }) {
  const { loading: loadingAccount } = useEndpoint(getAccountState, () => {});
  const { redispatch } = useEndpoint(getAccountsState, fetchAccounts());
  const { showErrorNotification, showSuccessNotification } = useNotifications();
  const editingAccountId = initialValues?.accountId;

  const dispatch = useDispatch();

  const handleCreateAccount = async (values) => {
    try {
      await dispatch(createAccount(values));
      showSuccessNotification('Account has been created successfully');
    } catch (newError) {
      showErrorNotification(getErrorMessage(newError));
    }
    handleCloseDialog();
    redispatch();
  };

  const handleEditAccount = async (values) => {
    try {
      await dispatch(updateAccount(editingAccountId, values));
      showSuccessNotification('Account has been edited successfully');
    } catch (newError) {
      showErrorNotification(getErrorMessage(newError));
    }
    handleCloseDialog();
    redispatch();
  };

  const onSubmit = isAddDialog ? handleCreateAccount : handleEditAccount;

  return (
    <CustomDialog
      title={isAddDialog ? 'Add sales account' : 'Edit sales account'}
      isDialogOpen={isDialogOpen}
      handleCloseDialog={handleCloseDialog}
    >
      <Formik initialValues={initialValues} validationSchema={NewAccountSchema} onSubmit={onSubmit}>
        <Form>
          <DialogContent>
            <Field
              name="name"
              label="Name your sales account"
              component={TextField}
              size="small"
              margin="normal"
              variant="outlined"
              required
              fullWidth
            />
            <Field
              name="description"
              label="Description"
              component={TextField}
              size="small"
              margin="normal"
              variant="outlined"
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={handleCloseDialog} disabled={loadingAccount}>
              Cancel
            </Button>
            <Button variant="contained" color="primary" type="submit" disabled={loadingAccount}>
              {isAddDialog ? 'Add' : 'Save'}
            </Button>
          </DialogActions>
        </Form>
      </Formik>
    </CustomDialog>
  );
}

export default AccountDialog;
