import React, { useState } from 'react';
import { Box, Paper, CardContent, Typography, Button, Link, Chip } from '@mui/material';
import {
  PaymentsOutlined,
  PersonOutlineOutlined,
  CreditCardOutlined,
  LaunchOutlined,
} from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { ConnectComponentsProvider } from '@stripe/react-connect-js';
import { loadConnectAndInitialize } from '@stripe/connect-js';

import withVenue from '../../../hoc/withVenue';
import { clearVenue } from '../../../store/venues';
import BackArrow from '../../../components/BackArrow';
import { createStripeConnectAccount } from '../../../api/stripe';
import PageLoader from '../../../components/PageLoader';
import { ReactComponent as DetailsCard } from '../../../assets/images/details_card.svg';
import { useNotifications } from '../../../shared/contexts/Notifications/useNotifications';
import useRoles from '../../../hooks/useRoles';

const useStyles = makeStyles((theme) => ({
  textContainer: {
    display: 'flex',
    padding: '8px 0',
  },
  title: {
    ...theme.customFonts.largeBold,
    marginBottom: '16px',
  },
  text: {
    padding: '8px 0',
  },
  smallPrintText: {
    ...theme.customFonts.xSmall,
  },
  iconContainer: {
    margin: '0 8px',
  },
  button: {
    cursor: 'pointer',
    margin: '8px 0',
    borderRadius: 12,
  },
  disabledButton: {
    cursor: 'pointer',
    margin: '8px 0',
    borderRadius: 12,
    color: '#D0CFDF',
    backgroundColor: '#F2F2F7',
    boxShadow: 'none',
  },
  externalLink: {
    marginLeft: '8px',
  },
  bankDetailsTextWrapper: {
    display: 'flex',
    paddingTop: '8px',
  },
  bankDetailsText: {
    fontWeight: 'bold',
    marginLeft: '4px',
  },
}));

const StripePaymentDetails = ({ accountDetails, redirect, banner }) => {
  const { isOwner, isAdmin } = useRoles();
  const classes = useStyles();
  const [disabled, setDisabled] = useState(false);

  const {
    hasAcceptedTOS = false,
    kycLink = '',
    status = '',
    stripeClientSecret,
    stripeAccountDashboardLink,
    hasPendingPayouts,
    hasOutstandingRequirements,
    payoutsEnabled,
  } = accountDetails || {};
  const { accountNumber = '', sortCode = '' } = accountDetails?.bankAccount || {};

  const { showErrorNotification } = useNotifications();

  const handleRegisterWithStripe = async () => {
    try {
      if (!hasAcceptedTOS) await createStripeConnectAccount();
    } catch (err) {
      showErrorNotification(err);
    }
  };

  const [stripeConnectInstance] = useState(() =>
    loadConnectAndInitialize({
      publishableKey: process.env.REACT_APP_STRIPE_PK,
      fetchClientSecret: () => stripeClientSecret,
    }),
  );

  const editPayoutButton = (
    <Button
      href={kycLink}
      onClick={() => setDisabled(true)}
      variant="outlined"
      color={isOwner() ? 'primary' : 'inherit'}
      className={isOwner() ? classes.button : classes.disabledButton}
      sx={{ background: 'white' }}
      target="_blank"
      rel="noopener noreferrer"
      disabled={disabled || !isOwner()}
    >
      Edit payout details
      {isOwner() ? (
        <LaunchOutlined className={classes.externalLink} />
      ) : (
        <LaunchOutlined sx={{ color: '#D0CFDF' }} className={classes.externalLink} />
      )}
    </Button>
  );

  if (!stripeConnectInstance) return <PageLoader />;

  return (
    <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Box sx={{ width: { xs: 1, md: 0.75 } }}>
          <BackArrow redirect={redirect} text="Settings" />
          <Typography className={classes.title}>
            {isAdmin() ? (
              <Link target="_blank" underline="always" href={stripeAccountDashboardLink}>
                Payout details
              </Link>
            ) : (
              'Payout details'
            )}
          </Typography>
          <Typography sx={{ display: 'flex' }}>
            <PaymentsOutlined sx={{ mr: 1, width: '18px' }} />
            <Link underline="always" href="/payouts">
              See your payouts
            </Link>
          </Typography>
          {accountDetails ? (
            <>
              {/* <ConnectNotificationBanner /> */}
              <Paper sx={{ borderRadius: 4, mt: 2 }}>
                <CardContent sx={{ pb: '16px !important' }}>
                  {banner}
                  {(status === 'restricted' && !hasAcceptedTOS && hasPendingPayouts) ||
                  (status === 'restricted' && !hasAcceptedTOS) ? (
                    <>
                      <Box>
                        <Typography variant="body2" className={classes.title}>
                          Setup your payout account details
                        </Typography>

                        <Typography className={classes.text}>
                          Once completed you will be able to receive payouts into your account every
                          weekday.
                        </Typography>
                        <Typography className={classes.text}>
                          You will need to submit some details about your business
                        </Typography>

                        <Box className={classes.textContainer}>
                          <Box className={classes.iconContainer}>
                            <DetailsCard />
                          </Box>
                          <Typography>
                            Information about your business, and authorised representative(s) of
                            your business, will need to be verified to comply with the law. This may
                            require you to provide documents such as government-issued
                            identification e.g. driving license or passport.
                          </Typography>
                        </Box>

                        <Box className={classes.textContainer}>
                          <Box className={classes.iconContainer}>
                            <PersonOutlineOutlined />
                          </Box>
                          <Typography>
                            It&apos;s recommended that the person filling out the information is
                            either the owner of the store, or someone with a significant role in the
                            business, such as a director or executive.
                          </Typography>
                        </Box>

                        <Box className={classes.textContainer}>
                          <Box className={classes.iconContainer}>
                            <CreditCardOutlined />
                          </Box>
                          <Typography>
                            The company&apos;s bank details you want to receive payouts to.
                          </Typography>
                        </Box>

                        <Box>
                          <Typography className={classes.smallPrintText}>
                            By registering your account, you agree to our Services Agreement and the{' '}
                            <Link
                              target="_blank"
                              underline="always"
                              href="https://stripe.com/gb/legal/connect-account"
                            >
                              Stripe Connected Account Agreement.
                            </Link>
                          </Typography>
                        </Box>
                      </Box>

                      <Box>
                        <Button
                          href={kycLink}
                          variant="contained"
                          color={isOwner() ? 'primary' : 'inherit'}
                          className={isOwner() ? classes.button : classes.disabledButton}
                          onClick={handleRegisterWithStripe}
                          target="_blank"
                          rel="noopener noreferrer"
                          disabled={!isOwner()}
                        >
                          Setup payout account
                          {isOwner() ? (
                            <LaunchOutlined className={classes.externalLink} />
                          ) : (
                            <LaunchOutlined
                              sx={{ color: '#D0CFDF' }}
                              className={classes.externalLink}
                            />
                          )}
                        </Button>
                      </Box>
                    </>
                  ) : (
                    <>
                      {status === 'active' && !hasOutstandingRequirements && (
                        <Box sx={{ pb: 1 }}>
                          <Chip
                            label="Payout details verified"
                            color="primary"
                            size="small"
                            sx={{ backgroundColor: '#87EB80', color: '#000' }}
                          />
                        </Box>
                      )}
                      {status !== 'pending' && (
                        <>
                          <Box className={classes.bankDetailsTextWrapper}>
                            <Typography>Account number:</Typography>
                            <Typography className={classes.bankDetailsText}>
                              {accountNumber}
                            </Typography>
                          </Box>
                          <Box className={classes.bankDetailsTextWrapper}>
                            <Typography>Sort code: </Typography>
                            <Typography className={classes.bankDetailsText}>{sortCode}</Typography>
                          </Box>
                        </>
                      )}
                    </>
                  )}
                  {status === 'restricted' && hasAcceptedTOS && !payoutsEnabled && editPayoutButton}
                  {status !== 'restricted' && editPayoutButton}
                  {disabled ||
                    (!isOwner() && (
                      <Typography className={classes.smallPrintText}>
                        Only the Owner of the venue has permission to perform this action.
                      </Typography>
                    ))}
                </CardContent>
              </Paper>
            </>
          ) : (
            <PageLoader />
          )}
        </Box>
      </Box>
    </ConnectComponentsProvider>
  );
};

export default withVenue(StripePaymentDetails, '/settings/business', clearVenue);
