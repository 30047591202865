import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { DragDropContext } from 'react-beautiful-dnd';

import { makeStyles } from '@mui/styles';

import { useNotifications } from '../../shared/contexts/Notifications/useNotifications';
import { getErrorMessage } from '../../shared/utils/errors';
import {
  moveMenuGroup,
  createMenuGroupItem,
  moveMenuGroupItem,
  updateVenueMenuItems,
} from '../../store/venueMenuItems';
import { getMenuGroupsList, getVenueMenuName } from '../../store/venueMenuItems/selectors';

import Droppable from '../DragAndDrop/Droppable';
import Draggable from '../DragAndDrop/Draggable';
import MenuGroup from '../MenuGroup';
import { getVenuePrioritiesState } from '../../store/venues/selectors';
import useRoles from '../../hooks/useRoles';
import { fetchVenueMenus } from '../../store/venueMenus';
import useItemsOnMenu from '../../hooks/useItemsOnMenu';

const useStyles = makeStyles((theme) => ({
  group: {
    backgroundColor: theme.palette.grey[300],
    borderRadius: theme.shape.borderRadius,
    padding: '0',
    marginBottom: '10px',
    overflow: 'hidden',
  },
}));

const MenuGroups = ({ expandAll, isReadonly }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { isRoleAtLeastManager } = useRoles();
  const { showErrorNotification, showSuccessNotification } = useNotifications();
  const menuGroupsList = useSelector(getMenuGroupsList);
  const menuName = useSelector(getVenueMenuName);
  const { data: venuePriorities } = useSelector(getVenuePrioritiesState);
  const { checkIfItemIsOnMenu } = useItemsOnMenu();
  const onDragEnd = async ({ type, source, destination }) => {
    if (!destination) {
      return;
    }
    try {
      if (type === 'droppable_menu_groups') {
        dispatch(moveMenuGroup({ from: source.index, to: destination.index }));
      } else {
        dispatch(
          moveMenuGroupItem({
            from: source.index,
            to: destination.index,
            groupId: type,
          }),
        );
      }
      await dispatch(updateVenueMenuItems());
      await dispatch(fetchVenueMenus());
      showSuccessNotification('The menu order has been updated');
    } catch (error) {
      showErrorNotification(getErrorMessage(error));
    }
  };

  const handleAddMenuGroupItem = async ({ groupId, itemId }) => {
    try {
      await dispatch(createMenuGroupItem({ groupId, itemId }));
      await dispatch(updateVenueMenuItems());
      await dispatch(fetchVenueMenus());
      showSuccessNotification('The menu has been updated');
    } catch (error) {
      showErrorNotification(getErrorMessage(error));
    }
  };

  return (
    <article>
      {!!menuGroupsList.length && (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable" type="droppable_menu_groups">
            <>
              {menuGroupsList.map((menuGroup, index) => (
                <Draggable
                  isDragDisabled={isReadonly || !isRoleAtLeastManager()}
                  className={classes.group}
                  key={menuGroup}
                  draggableId={`${menuGroup}`}
                  index={index}
                >
                  <MenuGroup
                    menuName={menuName}
                    groupIndex={index}
                    groupId={menuGroup}
                    onAddItem={handleAddMenuGroupItem}
                    expandAll={expandAll}
                    venuePriorities={venuePriorities}
                    isReadonly={isReadonly}
                    checkIfItemIsOnMenu={checkIfItemIsOnMenu}
                  />
                </Draggable>
              ))}
            </>
          </Droppable>
        </DragDropContext>
      )}
    </article>
  );
};

export default MenuGroups;
