import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { makeStyles } from '@mui/styles';
import { Box, Card, Typography } from '@mui/material';

import Page from '../../components/Page';

import { rangeEnums } from '../../shared/utils/dateRanges';
import withVenue from '../../hoc/withVenue';
import ESPicker from '../../components/ESPicker';
import useESPicker from '../../hooks/useESPicker';
import toUTC from '../../shared/utils/toUTC';
import { getReportCustomersOverviewState } from '../../store/reports/selectors';
import { clearReportCustomersOverview, fetchReportCustomersOverview } from '../../store/reports';
import DownloadReport from '../../components/DownloadReport';
import ReportsHeader from '../../components/ReportsHeader';
import BarChart from '../../components/Charts/BarChart';
import OrderableTable from '../../components/OrderableTable';
import rearrangeDataOrder from '../../shared/utils/rearrangeDataOrder';
import PageHeader from '../../components/PageHeader';

const useStyles = makeStyles((theme) => ({
  heading: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  title: {
    flexGrow: 1,
  },
  stripeLink: {
    marginLeft: theme.spacing(3),
  },
  form: {
    width: '80%',
    flexGrow: 2,
  },
  formElement: {
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  chart: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
  },
}));

const ReportCustomersOverview = ({ currentVenueId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const customersOverviewState = useSelector(getReportCustomersOverviewState);
  const { loading, data, error } = customersOverviewState;

  const esPicker = useESPicker({ useOrder: false, useSearch: false, useUnits: true });
  const { selectedDates, dateRange, units, reportingType } = esPicker;
  const [downloadPath, setDownloadPath] = useState(null);

  const loadData = useCallback(() => {
    dispatch(
      fetchReportCustomersOverview({
        dateRange,
        from: dateRange === rangeEnums.CUSTOM && toUTC(selectedDates.from),
        to: dateRange === rangeEnums.CUSTOM && toUTC(selectedDates.to),
        unit: units,
        reportingType,
      }),
    );
  }, [dateRange, dispatch, selectedDates, units, reportingType]);

  useEffect(() => {
    loadData();
  }, [dispatch, loadData]);

  useEffect(() => {
    const { from, to } = selectedDates;
    const params = dateRange ? `gte=${from}&lte=${to}` : '';
    setDownloadPath(`/reports/customers/export?${params}`);
  }, [dateRange, selectedDates]);

  return (
    <>
      <PageHeader fullWidth>
        <Box className={classes.heading}>
          <Typography className={classes.title} variant="h2" component="h1">
            Customer Overview
          </Typography>
        </Box>
        <DownloadReport
          path={downloadPath}
          type="customersOverview"
          variant="text"
          title="Export"
          fullWidth={false}
          includeVouchers="exclude"
        />
      </PageHeader>
      <ESPicker esPicker={esPicker} left />
      <Page loading={loading} error={error} fullWidth>
        {currentVenueId && data && (
          <>
            <Card className={classes.chart}>
              <ReportsHeader
                title="Total customers"
                data={{
                  current: data?.current.totalCustomers,
                  previous: data?.previous.totalCustomers,
                }}
              />
              <BarChart
                id="customersOverview"
                x="date"
                y="customers"
                data={data.current.histogram}
                previousData={data.previous.histogram}
                unit={units}
              />
            </Card>
            <Card>
              <OrderableTable
                showTotals
                tableData={rearrangeDataOrder(data?.current?.histogram, ['date', 'customers'])}
                zeroCheckTitle="customers"
                rowTitle="date"
                titles={['DATE', 'CUSTOMERS']}
              />
            </Card>
          </>
        )}
      </Page>
    </>
  );
};

export default withVenue(ReportCustomersOverview, null, clearReportCustomersOverview);
