import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { Box, Button, Typography } from '@mui/material';
import { pick } from 'lodash';
import moment from 'moment';

import withVenue from '../../hoc/withVenue';

import Page from '../../components/Page';
import PageHeader from '../../components/PageHeader';
import PromotionForm from '../../components/PromotionForm';
import { customCampaignTypes } from '../../components/PromotionsListForm/campaignTypes';
import BackArrow from '../../components/BackArrow';
import PromotionItem from '../../components/PromotionItem';

import PromotionPhotoUpload from './PromotionPhotoUpload';

import {
  clearCampaign,
  updateCampaign,
  createCampaign,
  deleteCampaign,
  fetchCampaign,
} from '../../store/campaign';
import { getCampaignState } from '../../store/campaign/selectors';
import { getMenuItemsState } from '../../store/menuItems/selectors';
import { fetchMenuItems } from '../../store/menuItems';

import { useNotifications } from '../../shared/contexts/Notifications/useNotifications';
import { getErrorMessage } from '../../shared/utils/errors';
import toUTC from '../../shared/utils/toUTC';
import shouldLoad from '../../shared/utils/shouldLoad';
import CustomDialog from '../../components/CustomDialog';
import PromotionDeleteForm from '../../components/PromotionDeleteForm';
import {
  toOpeningTimesFromValues,
  fromOpeningTimesToFormValues,
} from '../../components/WeekTimeRangeSelector/EditWeekTimeRangeDialog/mappers';
import { clearCampaigns } from '../../store/campaigns';

const useStyles = makeStyles((theme) => ({
  campaignBanner: {
    width: 227,
    marginBottom: theme.spacing(2),
  },
  campaignBannerEmpty: {
    width: 227,
    height: 115,
    backgroundColor: '#D9E2EC',
    borderRadius: 8,
    marginBottom: theme.spacing(2),
  },

  card: {
    marginBottom: '20px',
  },
  button: {
    marginRight: theme.spacing(),
  },
  refundForm: {
    justifyContent: 'flex-end',
  },
}));

const Promotion = ({ redirect, venue }) => {
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [localFile, setLocalFile] = useState();
  const [image, setImage] = useState();
  const [isCropModalVisibile, setIsCropModalVisibile] = useState(false);
  const [formValues, setFormValues] = useState();
  const { campaignId } = useParams();
  const classes = useStyles();
  const dispatch = useDispatch();
  const formRef = useRef();
  const { showErrorNotification, showSuccessNotification } = useNotifications();
  const campaignState = useSelector(getCampaignState);
  const menuItemsState = useSelector(getMenuItemsState);
  const { loading: campaignLoading, error: campaignError, data: campaignData } = campaignState;
  const { loading: menuItemsLoading, error: menuItemsError, data: menuItemsData } = menuItemsState;
  const { campaignName } = campaignData || {};
  const { openings } = fromOpeningTimesToFormValues(campaignData?.schedule || venue.openings);
  const initialValues = {
    active: true,
    campaignName: '',
    campaignWebTitle: '',
    campaignWebDescription: '',
    campaignWebButtonText: '',
    schedule:
      (campaignData?.schedule && fromOpeningTimesToFormValues(campaignData?.schedule)) || openings,
    openings,
    startsAt: campaignData?.startAt || 'now',
    endsAt: campaignData?.expiresAt || 'never',
    startgroup:
      campaignData?.startAt === 'now' || campaignData?.startAt === undefined ? 'now' : 'startDate',
    endgroup:
      campaignData?.expiresAt === 'never' || campaignData?.expiresAt === undefined
        ? 'never'
        : 'endDate',
    schedulegroup: campaignData?.schedule?.length > 0 ? 'schedulePicker' : 'scheduleNull',
    campaignType: customCampaignTypes.CUSTOM.value,
    campaignBanner: '',
    productIds: [],
    ...campaignData,
  };

  const handleCloseDialog = () => {
    setIsCropModalVisibile(false);
  };

  const handleCloseDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
  };

  const handleOpenDeleteDialog = () => {
    setIsDeleteDialogOpen(true);
  };

  const handleSubmitCreate = async (values) => {
    try {
      const sanitisedBase64 = values.campaignBanner?.replace(/^data:image\/[a-z]+;base64,/, '');
      const payload = {
        ...values,
        campaignBanner: sanitisedBase64,
        schedule: values.openings ? toOpeningTimesFromValues(values) : null,
        startAt: toUTC(values.startsAt),
        expiresAt: toUTC(values.endsAt),
      };
      delete payload.openings;
      delete payload.startsAt;
      delete payload.endsAt;
      delete payload.startgroup;
      delete payload.endgroup;

      if (payload.startAt === 'now' || payload.startAt === null) {
        payload.startAt = '';
      }
      if (payload.expiresAt === 'never' || payload.expiresAt === null) {
        payload.expiresAt = '';
      }
      if (payload.schedule === null) delete payload.schedule;

      const { campaignBannerLink } = values;
      const hasProductIds = !!values.productIds.length;

      if (!hasProductIds) {
        delete payload.productIds;
      }

      delete payload.schedulegroup;

      if (!campaignBannerLink) {
        delete payload.campaignBannerLink;
      }
      await dispatch(createCampaign(payload));

      showSuccessNotification('Offer has been created successfully');

      redirect();
    } catch (error) {
      showErrorNotification(getErrorMessage(error));
    }
  };

  const handleSubmitUpdate = async (values) => {
    try {
      const isBase64 = values.campaignBanner?.includes('data:image');
      const sanitisedBase64 = values.campaignBanner?.replace(/^data:image\/[a-z]+;base64,/, '');
      const transformedValues = {
        ...values,
        schedule: values.schedulegroup !== 'scheduleNull' ? toOpeningTimesFromValues(values) : null,
        startAt: toUTC(values.startsAt),
        expiresAt: toUTC(values.endsAt),
      };
      const payload = {
        ...pick(transformedValues, [
          'active',
          'campaignName',
          'campaignWebTitle',
          'campaignWebDescription',
          'campaignWebButtonText',
          'campaignBannerLink',
          'campaignType',
          'productIds',
          'schedule',
          'startAt',
          'expiresAt',
        ]),
        campaignBanner: sanitisedBase64,
      };

      const { campaignBannerLink } = values;
      const hasProductIds = !!values.productIds.length;

      if (!isBase64) {
        delete payload.campaignBanner;
      }

      if (!hasProductIds) {
        delete payload.productIds;
      }

      delete payload.startgroup;

      if (!campaignBannerLink) {
        delete payload.campaignBannerLink;
      }

      if (payload.startAt === 'now' || payload.startAt === null) {
        payload.startAt = '';
      }
      if (payload.expiresAt === 'never' || payload.expiresAt === null) {
        payload.expiresAt = '';
      }

      if (payload.schedule === null) {
        delete payload.schedule;
      }
      await dispatch(updateCampaign(campaignId, payload));
      showSuccessNotification('Offer has been updated successfully');

      redirect();
    } catch (error) {
      showErrorNotification(getErrorMessage(error));
    }
  };

  const handleDelete = async () => {
    try {
      redirect();
      await dispatch(deleteCampaign(campaignId));

      showSuccessNotification('Offer has been deleted successfully');
    } catch (error) {
      showErrorNotification(getErrorMessage(error));
    }
  };

  const finalHandleSubmit = campaignId
    ? (values) => handleSubmitUpdate(values)
    : (values) => handleSubmitCreate(values);
  const { campaignName: dynamicCampaignName, createdAt } = formValues || {};
  const pageTitle = dynamicCampaignName;
  const formattedCreatedAt = moment(createdAt).format('DD/MM/YY');
  const finalTitle = campaignId ? pageTitle || campaignName : 'New offer';

  const handleCapture = async ({ target }) => {
    const file = target.files[0];

    setLocalFile(file);

    // This enables the same photo to be selected more than once
    // eslint-disable-next-line no-param-reassign
    target.value = null;
  };

  const handleReset = () => {
    setLocalFile(null);
    setImage(null);
  };

  const handleSetImage = (croppedImg) => {
    if (croppedImg) {
      const reader = new FileReader();

      reader.readAsDataURL(croppedImg);
      reader.onloadend = () => {
        const base64Img = reader.result;

        setImage(base64Img);
        formRef.current.setFieldValue('campaignBanner', base64Img);
      };

      handleCloseDialog();
    }
  };

  useEffect(() => {
    if (shouldLoad(campaignState) && campaignId) {
      dispatch(fetchCampaign(campaignId));
    }
  }, [dispatch, campaignState, campaignId]);

  useEffect(() => {
    if (shouldLoad(menuItemsState)) {
      dispatch(fetchMenuItems());
    }
  }, [dispatch, menuItemsState]);

  useEffect(() => {
    if (localFile) {
      setIsCropModalVisibile(true);
    }
  }, [localFile]);

  return (
    <>
      <PageHeader>
        <BackArrow redirect={redirect} text="Promotions" />
        <Typography variant="h2" component="h1">
          {finalTitle}
        </Typography>
      </PageHeader>
      <Page loading={campaignLoading || menuItemsLoading} error={campaignError || menuItemsError}>
        <Box className={classes.campaignBanner}>
          {formValues && (
            <PromotionItem
              item={{
                ...campaignData,
                ...formValues,
                campaignBanner: image || campaignData?.campaignBanner,
              }}
            />
          )}
        </Box>
        <PromotionPhotoUpload
          file={localFile}
          handleCapture={handleCapture}
          handleSetImage={handleSetImage}
          isCropModalVisibile={isCropModalVisibile}
          handleCloseDialog={handleCloseDialog}
          error={formRef.current?.errors.campaignBanner}
        />
        <PromotionForm
          initialValues={initialValues}
          campaignId={campaignId}
          formRef={formRef}
          menuItems={menuItemsData}
          handleReset={handleReset}
          setFormValues={setFormValues}
          handleOnSubmit={finalHandleSubmit}
        />
        <CustomDialog
          isDialogOpen={isDeleteDialogOpen}
          handleCloseDialog={handleCloseDialog}
          title={`Delete ${finalTitle}`}
        >
          <PromotionDeleteForm
            handleCloseDialog={handleCloseDeleteDialog}
            onSubmit={handleDelete}
          />
        </CustomDialog>
        <Box my={2}>
          <Typography variant="subtitle1" style={{ fontSize: 14 }}>
            Created {formattedCreatedAt}
          </Typography>
        </Box>
        {campaignId && (
          <Button variant="outlined" onClick={handleOpenDeleteDialog}>
            Delete offer
          </Button>
        )}
      </Page>
    </>
  );
};

export default withVenue(Promotion, '/offers', [clearCampaign, clearCampaigns]);
