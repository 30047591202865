import { isEqual } from 'lodash';
import usePrevious from './usePrevious';

const useHasChanged = (val) => {
  const prevVal = usePrevious(val);
  const hasChanged = prevVal && !isEqual(val, prevVal);
  return hasChanged;
};

export default useHasChanged;
