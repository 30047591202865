import React, { useEffect, useState } from 'react';
import { WarningAmberOutlined } from '@mui/icons-material';
import { Link } from '@mui/material';
import { ReactComponent as InfoIcon } from '../assets/images/info.svg';
import { ReactComponent as WarningIconWhite } from '../assets/images/warning-icon-white.svg';
import CustomWarningBanner from '../components/CustomWarningBanner';
import { useNotifications } from '../shared/contexts/Notifications/useNotifications';
import { fetchStripeConnectAccount } from '../api/stripe';

const useStripeStatusBanner = (paymentDetailsPage = true) => {
  const { showErrorNotification } = useNotifications();
  const [accountDetails, setAccountDetails] = useState(null);

  useEffect(() => {
    const getAccountDetails = async () => {
      try {
        const data = await fetchStripeConnectAccount();
        setAccountDetails(data);
      } catch (err) {
        if (err.response.data.errorCode !== 'STRIPE_ACCOUNT_NOT_FOUND')
          showErrorNotification(err.response.data);
      }
    };

    getAccountDetails();
  }, [showErrorNotification]);

  const {
    hasAcceptedTOS = false,
    hasOutstandingRequirements,
    dueDate,
    payoutsEnabled,
    status = '',
    hasPendingPayouts,
  } = accountDetails || {};

  const updateLink = !paymentDetailsPage ? (
    <Link underline="always" href="/settings/business/payout-details">
      Update payout details
    </Link>
  ) : null;

  let stripeStatusTitle;
  let stripeStatusContent;
  let statusIcon;
  let bannerColour;
  let titleColor;

  if (status === 'restricted' && !hasAcceptedTOS && hasPendingPayouts && !paymentDetailsPage) {
    stripeStatusTitle = 'Your payout account details need updating';
    stripeStatusContent = (
      <p>
        We have identified some missing or incorrect information in your payout account. We
        can&apos;t process your payouts until this has been amended.{' '}
        {!paymentDetailsPage ? (
          <Link
            sx={{ color: 'white', textDecoration: 'underline' }}
            underline="always"
            href="/settings/business/payout-details"
          >
            Update payout details
          </Link>
        ) : null}
      </p>
    );
    statusIcon = <WarningIconWhite />;
    bannerColour = '#CA1D2A';
    titleColor = 'white';
  } else if (status === 'restricted' && !hasAcceptedTOS) {
    // No banner to show
  } else if (status === 'pending' && paymentDetailsPage) {
    stripeStatusTitle = null;
    stripeStatusContent =
      'Hold tight, your payout details are being reviewed. We’ll email you when this is completed.';
    statusIcon = <InfoIcon />;
    bannerColour = '#E0E0EB';
    titleColor = 'black';
  } else if (status === 'active' && !hasOutstandingRequirements) {
    // No banner to show
  } else if (status === 'active' && hasOutstandingRequirements) {
    stripeStatusTitle = 'Your payout account details need updating';
    stripeStatusContent =
      dueDate === null ? (
        <p>
          We need some more information from you to make sure you can continue to receive payouts.{' '}
          {updateLink}
        </p>
      ) : (
        <p>
          We need some more information from you by the ${dueDate} to make sure you can continue to
          receive payouts. {updateLink}
        </p>
      );
    statusIcon = <WarningAmberOutlined />;
    bannerColour = '#FFE87A';
    titleColor = 'black';
  } else if (status === 'restricted' && hasAcceptedTOS && !payoutsEnabled) {
    stripeStatusTitle = 'Your payout account details need updating';
    stripeStatusContent = (
      <p>
        We have identified some missing or incorrect information in your payout account. We
        can&apos;t process your payouts until this has been amended.{' '}
        {!paymentDetailsPage ? (
          <Link
            sx={{ color: 'white', textDecoration: 'underline' }}
            underline="always"
            href="/settings/business/payout-details"
          >
            Update payout details
          </Link>
        ) : null}
      </p>
    );
    statusIcon = <WarningIconWhite />;
    bannerColour = '#CA1D2A';
    titleColor = 'white';
  }

  const banner =
    stripeStatusTitle || stripeStatusContent ? (
      <CustomWarningBanner
        title={stripeStatusTitle}
        titleFont="smallBold"
        titleColor={titleColor}
        warningFont="small"
        backgroundColor={bannerColour}
        customIcon={statusIcon}
      >
        {stripeStatusContent}
      </CustomWarningBanner>
    ) : null;

  return {
    banner,
    accountDetails,
  };
};

export default useStripeStatusBanner;
