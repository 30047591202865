import { get, patchBare, getBare, deleteBare } from './httpRequest';

export const fetchAvailableTerminalDevices = async () => {
  const response = await getBare(`terminals`);
  return response.data;
};

export const fetchTerminalDevices = async () => {
  const response = await get(`/terminals`);
  return response.data;
};

export const fetchTerminalDevice = async (values) => {
  const { terminalId } = values;
  const response = await get(`/terminals/${terminalId}`);
  return response.data;
};

export const updateTerminalDevice = async (values) => {
  const venueId = localStorage.getItem('venue_id');
  const { terminalId, name } = values;
  const response = await patchBare(`terminals/${terminalId}`, {
    data: { venueId, name },
  });
  return response.data;
};

export const deleteTerminalDevice = async (terminalId) => {
  await deleteBare(`terminals/${terminalId}`);
};
