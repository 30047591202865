import { createSlice } from '@reduxjs/toolkit';
import {
  asyncInitialState as initialState,
  createAsyncAction,
  createAsyncReducerHandlers,
} from '../utils/async';
import fetchReportSalesByTableApi from '../../api/reports/salesByTable';

export const fetchReportSalesByTable = createAsyncAction(
  'fetch-sales-by-table',
  fetchReportSalesByTableApi,
);

const reportSalesByTableSlice = createSlice({
  name: 'sales-by-table',
  initialState,
  reducers: {
    clearReportSalesByTable(state) {
      delete state.data;
    },
  },
  extraReducers: createAsyncReducerHandlers(fetchReportSalesByTable),
});

export const { clearReportSalesByTable } = reportSalesByTableSlice.actions;

export const { reducer: reportSalesByTable } = reportSalesByTableSlice;
