import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { Button, FormControlLabel, Switch } from '@mui/material';
import { getTables } from '../../store/tables/selectors';
import { useNotifications } from '../../shared/contexts/Notifications/useNotifications';
import { fetchTables, deleteTable } from '../../store/tables';
import { getErrorMessage } from '../../shared/utils/errors';
import TableLink from '../TableLink';
import { getVenueState } from '../../store/venues/selectors';
import { enrichStation } from '../../shared/utils/openingTimesUtils';
import VenueOpenDialog from '../VenueOpenDialog';
import useRoles from '../../hooks/useRoles';
import UniversalSave from '../UniversalSave';

const useStyles = makeStyles(() => ({
  button: {
    float: 'right',
  },
}));
const TablesTable = ({ onSubmit }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { isAdmin } = useRoles();
  const tables = useSelector(getTables);
  const { showErrorNotification, showSuccessNotification } = useNotifications();
  const { data: venue } = useSelector(getVenueState);
  const { isOpen } = enrichStation(venue);
  const [venueIsOpenDialogOpen, setVenueIsOpenDialogOpen] = useState(false);
  const handleVenueIsOpenDialogClose = () => setVenueIsOpenDialogOpen(false);

  if (!tables) return null;

  const handleDelete = async (tableName) => {
    if (isOpen) {
      setVenueIsOpenDialogOpen(true);
      return;
    }
    try {
      await dispatch(deleteTable(tableName));
      showSuccessNotification('Table has been deleted successfully');
      await dispatch(fetchTables());
    } catch (err) {
      await dispatch(fetchTables());
      showErrorNotification(getErrorMessage(err));
    }
  };

  return (
    <>
      <Formik initialValues={{ tables }}>
        {({ setFieldValue, resetForm, values, dirty, errors }) => (
          <>
            <UniversalSave
              dirty={dirty}
              errors={errors}
              onSave={() => {
                onSubmit(values);
              }}
              onDiscard={resetForm}
            />
            <Form>
              <TableContainer component={Paper}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>Description</TableCell>
                      <TableCell>Enabled</TableCell>
                      {isAdmin() && <TableCell />}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tables.map((table, index) => (
                      <TableRow key={table.tableName}>
                        <TableCell component="th" scope="row">
                          <TableLink to={`/tables/${table.tableName}`}>{table.tableName}</TableLink>
                        </TableCell>
                        <TableCell>{table.description}</TableCell>
                        <TableCell>
                          <FormControlLabel
                            control={
                              <Switch
                                color="primary"
                                type="checkbox"
                                checked={values.tables[index].enabled}
                                onChange={() => {
                                  setFieldValue(
                                    `tables[${index}].enabled`,
                                    !values.tables[`${index}`].enabled,
                                  );
                                }}
                              />
                            }
                            label={table.enabled === true ? 'Enabled' : 'Disabled'}
                          />
                        </TableCell>
                        <TableCell>
                          <Button
                            className={classes.button}
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              handleDelete(table.tableName);
                            }}
                          >
                            Delete
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Form>
          </>
        )}
      </Formik>
      <VenueOpenDialog
        handleCloseDialog={handleVenueIsOpenDialogClose}
        dialogOpen={venueIsOpenDialogOpen}
        message="Sorry, you can't delete tables while the venue is open"
      />
    </>
  );
};

export default TablesTable;
