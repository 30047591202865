import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { pick } from 'ramda';

import { makeStyles } from '@mui/styles';
import { Grid, Typography } from '@mui/material';

import { getErrorMessage } from '../../shared/utils/errors';
import { useNotifications } from '../../shared/contexts/Notifications/useNotifications';

import { clearTable, fetchTable, updateTable } from '../../store/tables';
import { getTableState } from '../../store/tables/selectors';

import Page from '../../components/Page';
import TableForm from '../../components/TableForm';
import withVenue from '../../hoc/withVenue';
import BackArrow from '../../components/BackArrow';

const useStyles = makeStyles((theme) => ({
  heading: {
    margin: '15px 0',
  },
  paper: {
    padding: theme.spacing(2),
    marginBottom: '10px',
  },
}));

const Table = ({ redirect }) => {
  const { tableName } = useParams();
  const classes = useStyles();
  const { showErrorNotification, showSuccessNotification } = useNotifications();
  const dispatch = useDispatch();
  const { loading, data: table, error } = useSelector(getTableState);

  useEffect(() => {
    dispatch(fetchTable(tableName));
  }, [tableName, dispatch]);

  const handleOnSubmit = async (values) => {
    try {
      await dispatch(updateTable({ tableName, values }));
      showSuccessNotification('Tables has been updated successfully');
      redirect();
    } catch (newError) {
      showErrorNotification(getErrorMessage(newError));
    }
  };

  return (
    <Page loading={loading} error={error}>
      {table && (
        <>
          <header>
            <BackArrow redirect={redirect} text="Tables" />
            <Typography className={classes.heading} variant="h2" component="h1">
              Tables: {table.tableName}
            </Typography>
          </header>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <TableForm
                initialValues={pick(['tableName', 'description'], table)}
                onSubmit={handleOnSubmit}
                onCancel={redirect}
              />
            </Grid>
          </Grid>
        </>
      )}
    </Page>
  );
};

export default withVenue(Table, '/tables', clearTable);
