import React from 'react';
import { useDispatch } from 'react-redux';

import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';
import { clearTable, createTable } from '../../store/tables';

import { getErrorMessage } from '../../shared/utils/errors';
import { useNotifications } from '../../shared/contexts/Notifications/useNotifications';
import TableForm from '../../components/TableForm';
import Page from '../../components/Page';
import rangeExpansion from '../../shared/utils/rangeExpansion';
import withVenue from '../../hoc/withVenue';
import BackArrow from '../../components/BackArrow';

const useStyles = makeStyles(() => ({
  heading: {
    margin: '15px 0',
  },
}));

const AddMultipleTables = ({ redirect }) => {
  const { showErrorNotification, showSuccessNotification } = useNotifications();
  const dispatch = useDispatch();

  const handleOnSubmit = async (values) => {
    const tableNames = rangeExpansion(values.tableName);
    tableNames.forEach(async (name) => {
      try {
        await dispatch(createTable({ tableName: String(name), description: values.description }));
        showSuccessNotification('Table has been added successfully');
        redirect();
      } catch (error) {
        showErrorNotification(getErrorMessage(error));
      }
    });
  };

  const classes = useStyles();

  return (
    <Page>
      <header>
        <BackArrow redirect={redirect} text="Tables" />
        <Typography className={classes.heading} variant="h2" component="h1">
          Add Table
        </Typography>
        <Typography className={classes.heading} variant="body2">
          Enter a range of table numbers such as 1-25 and the system will generate them
          automatically.
          <br />
          <br />
          Description is optional and not shown to customers
        </Typography>
      </header>
      <TableForm
        formAction="create"
        initialValues={{
          tableName: '',
          description: '',
        }}
        onSubmit={handleOnSubmit}
        onCancel={redirect}
      />
    </Page>
  );
};

export default withVenue(AddMultipleTables, '/tables', clearTable);
