import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment-timezone';

import { makeStyles } from '@mui/styles';
import { Card, Typography } from '@mui/material';
import { clearOrders, fetchOrders } from '../../store/orders';
import { getOrders, getOrdersState } from '../../store/orders/selectors';

import Page from '../../components/Page';
import PageHeader from '../../components/PageHeader';
import { rangeEnums } from '../../shared/utils/dateRanges';
import withVenue from '../../hoc/withVenue';
import ESPicker from '../../components/ESPicker';
import useESPicker from '../../hooks/useESPicker';
import shouldLoad from '../../shared/utils/shouldLoad';
import useEndpoint from '../../hooks/useEndpoint';
import { getAccountsState } from '../../store/accounts/selectors';
import { fetchAccounts } from '../../store/accounts';
import OrderableTable from '../../components/OrderableTable';
import rearrangeDataOrder from '../../shared/utils/rearrangeDataOrder';
import TableButton from '../../components/TableButton';
import BackArrow from '../../components/BackArrow';

const useStyles = makeStyles((theme) => ({
  heading: {
    marginBottom: '20px',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  title: {
    marginRight: '20px',
    width: '50%',
    flexGrow: 1,
  },
  totals: {
    display: 'flex',
    padding: theme.spacing(1),
    gap: theme.spacing(2),
  },
  total: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1),
    paddingBottom: 0,
  },
  totalNumber: {
    ...theme.customFonts.large,
    color: theme.customPalette.greyDarker,
  },
  totalTitle: {
    ...theme.customFonts.small,
    color: theme.customPalette.greyDark,
  },
  picker: {
    marginBottom: theme.spacing(2),
  },
}));

const AccountOrders = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const ordersState = useSelector(getOrdersState);

  const { loading, error } = ordersState;
  const raworders = useSelector(getOrders) || [];
  const orders = {
    docs: raworders.docs || [],
    total: raworders.total || 0,
  };

  const reducedOrders = orders.docs.map((order) => {
    const { staffName, orderDisplayId, total, name, acceptedAt, orderId } = order;
    return {
      staffName,
      orderDisplayId,
      total,
      orderId,
      name,
      date: acceptedAt,
      time: moment(acceptedAt).local()?.format('HH:mm'),
    };
  });

  const totalSales = orders.docs.reduce((acc, order) => acc + order.total, 0);
  const totalOrders = orders.docs.length;

  const { data: accounts } = useEndpoint(getAccountsState, fetchAccounts());

  const esPicker = useESPicker({ useOrder: false, accounts: accounts || [] });
  const { selectedDates, searchTerm, dateRange, sortOrder, accountId, reportingType } = esPicker;

  const getData = useCallback(() => {
    dispatch(
      fetchOrders({
        first: 0,
        size: 10000,
        sortOrder,
        searchTerm,
        dateRange,
        accountId,
        from: dateRange === rangeEnums.CUSTOM && selectedDates.from,
        to: dateRange === rangeEnums.CUSTOM && selectedDates.to,
        excludeStatus: 'VOUCHER_ORDER_ACCEPTED',
        reportingType,
      }),
    );
  }, [dispatch, sortOrder, searchTerm, dateRange, selectedDates, accountId, reportingType]);
  useEffect(() => {
    if (shouldLoad(ordersState)) getData();
  }, [getData, ordersState]);

  useEffect(() => {
    dispatch(clearOrders());
    getData();
  }, [sortOrder, searchTerm, dateRange, selectedDates, getData, dispatch]);

  const showOrder = (order) => {
    window.open(`/orders/${order}`, '_blank', 'noopener,noreferrer');
  };

  const valueFormatter = ({ value, valueName, row }) => {
    switch (valueName) {
      case 'date':
        return moment(value).local()?.format('DD/MM/YYYY');
      case 'orderDisplayId':
        return (
          <TableButton
            onClick={() => {
              showOrder(row.orderId);
            }}
          >
            {row.orderDisplayId}
          </TableButton>
        );
      case 'total':
        return `£${Number(value).toFixed(2)}`;
      default:
        return value;
    }
  };

  return (
    <>
      <PageHeader fullWidth>
        <BackArrow to="/accounts" text="Sales accounts" />
        <div className={classes.heading}>
          <Typography className={classes.title} variant="h2" component="h1">
            Orders
          </Typography>
        </div>
        <div className={classes.picker}>
          <ESPicker esPicker={esPicker} left />
        </div>
      </PageHeader>
      <Page loading={loading} error={error} fullWidth>
        {orders && (
          <Card>
            <div className={classes.totals}>
              <div className={classes.total}>
                <div className={classes.totalNumber}>£{totalSales.toFixed(2)}</div>
                <div className={classes.totalTitle}>Total sales</div>
              </div>
              <div className={classes.total}>
                <div className={classes.totalNumber}>{totalOrders}</div>
                <div className={classes.totalTitle}>Orders</div>
              </div>
            </div>
            <OrderableTable
              titles={['TOTAL', 'STAFF', 'ORDER ID', 'NAME', 'DATE', 'TIME']}
              tableData={rearrangeDataOrder(reducedOrders, [
                'total',
                'staffName',
                'orderDisplayId',
                'orderId',
                'name',
                'date',
                'time',
              ])}
              valueFormatter={valueFormatter}
              excludeFields={['orderId']}
            />
          </Card>
        )}
      </Page>
    </>
  );
};

export default withVenue(AccountOrders, null, clearOrders);
