import * as d3 from 'd3';
import './BarChart.css';
import moment from 'moment-timezone';

const getDateRange = (data) => {
  if (!data?.length || data.length === 0) return 'No Data';
  const fromdate = data[0].originalDate || data[0].date;
  const todate = data[data.length - 1].originalDate || data[data.length - 1].date;
  const format = moment(todate).year() !== moment(fromdate).year() ? 'DD MMM YYYY' : 'DD MMM';
  const from = moment(fromdate).local().format(format);
  const to = moment(todate).local().format(format);
  return `${from} - ${to}`;
};

d3.formatDefaultLocale({
  decimal: '.',
  thousands: ',',
  grouping: [3],
  currency: ['£', ''],
});

const mapPreviousDataToCurrent = (currentData, previousData, xField, yField) => {
  // We need the two datasets to have the same number of data points
  // so that we can map the previous data to the current data.
  // Sometimes they might be out by one or two data points, so we just push one or other of the data points
  // into the other set to make them the same length.
  // e.g. last 3 months in days can have a different number of days for each period.
  const currentDataMap = [...currentData];
  const previousDataMap = [...previousData];
  if (currentDataMap.length > previousDataMap.length) previousDataMap.push(currentDataMap[0]);
  if (currentDataMap.length < previousDataMap.length)
    currentDataMap.unshift(previousDataMap[previousDataMap.length - 1]);

  if (currentDataMap.length !== previousDataMap.length) return previousDataMap;
  return previousDataMap.map((prev, index) => ({
    ...prev,
    ...{
      originalDate: prev.date,
      date: currentDataMap[index].date,
      [`original${yField}`]: currentDataMap[index][yField],
    },
  }));
};
const shouldUseCurrencySymbol = (yField) => {
  switch (yField) {
    case 'revenue':
      return true;
    case 'sales':
      return true;
    default:
      return false;
  }
};

export default (inputdata, rawPreviousInputData, xField, yField, id, unit) => {
  const previousInputData = mapPreviousDataToCurrent(
    inputdata,
    rawPreviousInputData,
    xField,
    yField,
  );
  const selectedCurrentRange = getDateRange(inputdata);
  const selectedPreviousRange = getDateRange(previousInputData);
  const formatyAxisNumber = d3.format(shouldUseCurrencySymbol(yField) ? '$,' : '');
  const formatNumber = (inputNumber) => {
    const isRev = shouldUseCurrencySymbol(yField);
    const numberstring = inputNumber.toLocaleString(undefined, {
      minimumFractionDigits: isRev ? 2 : 0,
    });
    return `${isRev ? '£' : ''}${numberstring}`;
  };

  const hostDiv = document.querySelector(`#id-${id}`);
  d3.selectAll(`#id-${id} svg`).remove();

  const parseTime = d3.isoParse;
  const data = inputdata
    .map((d) => {
      const newRow = {
        [xField]: parseTime(moment(d[xField]).toISOString()),
        [yField]: d[yField],
      };
      return newRow;
    })
    .filter((d) => d[yField] !== 0);

  const previousData = previousInputData.map((d) => {
    const newRow = {
      originalDate: parseTime(moment(d.originalDate).toISOString()),
      [xField]: parseTime(moment(d[xField]).toISOString()),
      [yField]: d[yField],
      [`original${yField}`]: d[`original${yField}`],
    };
    return newRow;
  });

  const mixedData = [...data, ...previousData];

  const margin = { top: 30, right: 30, bottom: 85, left: 65 };

  const width = '100%';
  const height = 300 - margin.top - margin.bottom;
  const barWidth = 10;

  const xScale = d3
    .scaleTime()
    .domain(d3.extent(mixedData, (d) => d[xField]))
    .range([0, hostDiv.offsetWidth]);
  xScale.clamp(true);

  const yScale = d3
    .scaleLinear()
    .domain([0, d3.max(mixedData, (d) => d[yField]) || 5])
    .range([height, 0]);

  const previousLine = d3
    .line()
    .x((d) => xScale(d[xField]))
    .y((d) => yScale(d[yField]))
    .curve(d3.curveLinear);

  const line = d3
    .line()
    .x((d) => xScale(d[xField]))
    .y((d) => yScale(d[yField]))
    .curve(d3.curveLinear);

  const svg = d3
    .select(`#id-${id}`)
    .append('svg')
    .attr('width', width)
    .attr('height', height + margin.top + margin.bottom)
    .append('g')
    .attr('transform', `translate(${margin.left},${margin.top})`);

  svg
    .append('defs')
    .append('marker')
    .attr('id', 'dot')
    .attr('viewBox', [0, 0, 10, 10])
    .attr('refX', 5)
    .attr('refY', 5)
    .attr('markerWidth', 5)
    .attr('markerHeight', 5)
    .append('circle')
    .attr('cx', 5)
    .attr('cy', 5)
    .attr('r', 5)
    .style('fill', '#54e9fc');

  const xAxis = d3.axisBottom(xScale);
  const xAxisSVG = svg
    .append('g')
    .attr('class', 'x axis copy')
    .attr('transform', `translate(0,${height})`)
    .call(xAxis);

  const yAxis = d3.axisLeft(yScale).tickFormat(formatyAxisNumber);
  const yAxisSVG = svg.append('g').attr('class', 'y axis copy').call(yAxis);

  const prevbars = svg
    .selectAll('.prevbars')
    .data(previousData)
    .enter()
    .append('rect')
    .attr('class', 'prevbars')
    .attr('x', (d) => xScale(d[xField]) - barWidth)
    .attr('y', (d) => yScale(d[yField]))
    .attr('rx', barWidth / 2)
    .attr('ry', barWidth / 2)
    .attr('width', barWidth)
    .attr('height', (d) => height - yScale(d[yField]));

  const bars = svg
    .selectAll('.bars')
    .data(data)
    .enter()
    .append('rect')
    .attr('class', 'bars')
    .attr('x', (d) => xScale(d[xField]))
    .attr('y', (d) => yScale(d[yField]))
    .attr('rx', barWidth / 2)
    .attr('ry', barWidth / 2)
    .attr('width', barWidth)
    .attr('height', (d) => height - yScale(d[yField]));

  const previousLinePath = svg
    .append('path')
    .data([previousData])
    .attr('class', 'previousLine')
    .attr('d', previousLine)
    .attr('opacity', 0);

  const linePath = svg
    .append('path')
    .data([data])
    .attr('class', 'line')
    .attr('d', line)
    .attr('marker-end', 'url(#dot)')
    .attr('opacity', 0);

  const tooltip = d3.select('body').append('div').attr('class', 'tooltip').style('opacity', 0);
  tooltip.append('div').attr('class', 'count');
  tooltip.append('div').attr('class', 'prevcount');

  svg.on('mousemove', (event) => {
    const { pageX, pageY } = event;
    const { width: ttwidth, height: ttheight } = tooltip.node().getBoundingClientRect();
    tooltip.style('left', `${pageX - ttwidth / 2}px`);
    tooltip.style('top', `${pageY - ttheight - 10}px`);
  });

  const previousdots = svg
    .selectAll('.previousDot')
    .data(previousData)
    .enter()
    .append('circle')
    .attr('class', 'previousDot')
    .attr('cx', (d) => xScale(d[xField]))
    .attr('cy', (d) => yScale(d[yField]))
    .attr('r', 3)
    .attr('opacity', (d) => (d[yField] === 0 ? 0 : 1));

  const dots = svg
    .selectAll('.dot')
    .data(data)
    .enter()
    .append('circle')
    .attr('class', 'dot')
    .attr('cx', (d) => xScale(d[xField]))
    .attr('cy', (d) => yScale(d[yField]))
    .attr('r', 4)
    .attr('opacity', (d) => (d[yField] === 0 ? 0 : 1))
    .each((d, i) => {
      if (i === data.length - 1) {
        d3.select(this).attr('class', 'finaldot');
      }
    });

  const tooltipPrevDots = svg
    .selectAll('.tooltipdotprev')
    .data(previousData)
    .enter()
    .append('rect')
    .attr('class', 'tooltipdotprev')
    .attr('x', (d) => xScale(d[xField]) - barWidth)
    .attr('y', (d) => yScale(d[yField]))
    .attr('rx', barWidth / 2)
    .attr('ry', barWidth / 2)
    .attr('width', barWidth)
    .attr('height', (d) => height - yScale(d[yField]))
    .on('mouseover', (e, d) => {
      tooltip.transition().duration(200).style('opacity', 0.9);
      // tooltip.select('.label').html(`${moment(d.originalDate).local().format('DD MMM')}`);
      // const timeFormat = unit === 'h' ? 'ha, DD MMM' : 'DD MMM';

      const timeFormat = () => {
        switch (unit) {
          case 'm':
            return 'hh:mm, DD MMM';
          case 'h':
            return 'ha, DD MMM';
          case 'd':
            return 'DD MMM';
          case 'w':
            return 'DD MMM';
          case 'M':
            return 'MMM YY';
          case 'y':
            return 'YYYY';
          default:
            return 'DD MMM';
        }
      };
      tooltip.select('.count').html(`<div class='tooltip-row'>
          <div class='current-circle'>&#x2B24; </div>
          <div class='tooltip-row-line'>
            ${moment(d[xField]).local().format(timeFormat())}<br />${formatNumber(
        d[`original${yField}`],
      )}
          </div>
        </div>`);
      tooltip.select('.prevcount').html(
        `<div class='tooltip-row'>
          <div class='previous-circle'>&#x2B24; </div>
          <div class='tooltip-row-line'>
          ${moment(d.originalDate).local().format(timeFormat())}<br />${formatNumber(d[yField])}
          </div>
        </div>`,
      );
    })
    .on('mouseout', () => {
      tooltip.transition().duration(500).style('opacity', 0);
    });

  const prevx = -50;
  const prevy = 255;
  const currx = -50;
  const curry = 230;
  const legendLineLength = 30;
  const legendtextMargin = 50;

  svg
    .append('line')
    .attr('x1', prevx)
    .attr('y1', prevy)
    .attr('x2', prevx + legendLineLength)
    .attr('y2', prevy)
    .attr('stroke-linecap', 'round')
    .attr('class', 'previousLine');
  svg
    .append('line')
    .attr('x1', currx)
    .attr('y1', curry)
    .attr('x2', currx + legendLineLength)
    .attr('y2', curry)
    .attr('stroke-linecap', 'round')
    .attr('class', 'line');
  svg
    .append('text')
    .attr('x', prevx + legendtextMargin)
    .attr('y', prevy)
    .text(`${selectedPreviousRange}`)
    .attr('alignment-baseline', 'middle')
    .attr('class', 'copy copyText');
  svg
    .append('text')
    .attr('x', currx + legendtextMargin)
    .attr('y', curry)
    .text(`${selectedCurrentRange}`)
    .attr('alignment-baseline', 'middle')
    .attr('class', 'copy copyText');

  const resizeObserver = new ResizeObserver((entries) => {
    const cr = entries[0].contentRect;

    const newWidth = cr.width - margin.left - margin.right;
    const newHeight = 300 - margin.top - margin.bottom;
    const newBarWidth = Math.min(10, Math.max(1, newWidth / data.length / 10));

    if (newWidth < 100) return;

    const yticks = 5;
    const newXTicks = Math.round(newWidth / 100);
    const xticks = Math.max(Math.min(newXTicks, 16), 3);

    xScale.range([newBarWidth * 2, newWidth]);
    yScale.range([newHeight, 0]);

    line
      .x((d) => xScale(d[xField]))
      .y((d) => yScale(d[yField]))
      .curve(d3.curveLinear);

    linePath.attr('d', line);

    previousLinePath.attr('d', previousLine);

    bars
      .attr('x', (d) => xScale(d[xField]))
      .attr('y', (d) => yScale(d[yField]))
      .attr('rx', newBarWidth / 2)
      .attr('ry', newBarWidth / 2)
      .attr('width', newBarWidth)
      .attr('height', (d) => height - yScale(d[yField]));

    prevbars
      .attr('x', (d) => xScale(d[xField]) - newBarWidth)
      .attr('y', (d) => yScale(d[yField]))
      .attr('rx', newBarWidth / 2)
      .attr('ry', newBarWidth / 2)
      .attr('width', newBarWidth)
      .attr('height', (d) => height - yScale(d[yField]));

    dots.attr('cx', (d) => xScale(d[xField])).attr('cy', (d) => yScale(d[yField]));

    previousdots
      .attr('cx', (d) => xScale(d[xField]))
      .attr('cy', (d) => yScale(d[yField]))
      .attr('opacity', 0);

    tooltipPrevDots
      .attr('x', (d) => xScale(d[xField]) - barWidth)
      .attr('y', (d) => Math.min(yScale(d[yField]), yScale(d[`original${yField}`])))
      .attr('rx', barWidth / 2)
      .attr('ry', barWidth / 2)
      .attr('width', barWidth * 2)
      .attr('opacity', 0)
      .attr('height', (d) => height - Math.min(yScale(d[yField]), yScale(d[`original${yField}`])));

    const newxAxis = d3.axisBottom(xScale);
    newxAxis.ticks(xticks);

    xAxisSVG.attr('transform', `translate(0,${height})`).call(newxAxis);

    yAxisSVG.call(
      d3
        .axisLeft(yScale)
        .tickSize(-newWidth - margin.left + 10)
        .tickFormat(formatyAxisNumber)
        .ticks(yticks),
    );

    const updatesvg = d3.select(`#id-${id}`);
    updatesvg.attr('width', '100%').attr('height', newHeight);
  });
  resizeObserver.observe(hostDiv);
};
