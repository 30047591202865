export default [
  {
    tableName: '1',
    description: 'near the garden',
    venueId: '1',
  },
  {
    tableName: '2',
    description: 'by the fireplace',
    venueId: '1',
  },
  {
    tableName: '3',
    description: 'Opposite the fountain',
    venueId: '1',
  },
  {
    tableName: '4',
    venueId: '1',
  },
  {
    tableName: '5',
    venueId: '1',
  },
];
