/* eslint-disable max-len */
import React from 'react';
import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';

import Page from '../../components/Page';
import CreateCampaignForm from '../../components/CreateCampaignForm';

const useStyles = makeStyles(() => ({
  heading: {
    marginBottom: '20px',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  title: {
    marginRight: '20px',
    width: '50%',
    flexGrow: 1,
  },
}));

export default () => {
  const classes = useStyles();

  return (
    <Page>
      <header className={classes.heading}>
        <Typography noWrap className={classes.title} variant="h2" component="h1">
          Add Campaign
        </Typography>
      </header>
      <CreateCampaignForm
        initialValues={{
          brand: 'RED BULL',
          brandLogo:
            'https://assets.dev.sessions.systems/servedup/campaigns/REDBULL/3de5e74e-c56c-4ab7-ae6c-b3b108446435/logo.png',
          campaignBanner:
            'https://assets.dev.sessions.systems/servedup/campaigns/REDBULL/3de5e74e-c56c-4ab7-ae6c-b3b108446435/banner.png',
          campaignConditions: ['Expose a banner', 'Sell min 100 campaign products', 'have fun'],
          campaignDescription: `Red Bull is an energy drink sold by Red Bull GmbH, an Austrian company created in 1987.
            Red Bull has the highest market share of any energy drink in the world`,
          campaignId: '3de5e74e-c56c-4ab7-ae6c-b3b108446435',
          campaignLink: 'https://servedup.co/campaigns/redbull',
          campaignName: 'Red Bull Up',
          campaignType: 'BANNER',
          campaignWebDescription: 'No Red Bull, no wings',
          campaignWebTitle: 'Try Red Bull',
          confirmationImage:
            'https://assets.dev.sessions.systems/servedup/campaigns/REDBULL/3de5e74e-c56c-4ab7-ae6c-b3b108446435/success.png',
          createdAt: '2021-03-18T10:36:57.390Z',
          expiresAt: '2021-05-12T00:00:00.000Z',
          images: [
            'https://assets.dev.sessions.systems/servedup/campaigns/REDBULL/3de5e74e-c56c-4ab7-ae6c-b3b108446435/img.png',
            'https://assets.dev.sessions.systems/servedup/campaigns/REDBULL/3de5e74e-c56c-4ab7-ae6c-b3b108446435/img.png',
            'https://assets.dev.sessions.systems/servedup/campaigns/REDBULL/3de5e74e-c56c-4ab7-ae6c-b3b108446435/img.png',
          ],
          reward: '£150 worth of merchandise',
          runningTimes: [
            'Monday: 09:00 AM - 4:00 PM',
            'Tuesday: 09:00 AM - 4:00 PM',
            'Wednesday: 09:00 AM - 4:00 PM',
            'Thursday: 09:00 AM - 4:00 PM',
            'Friday: 09:00 AM - 4:00 PM',
            'Saturday: 10:00 AM - 4:00 PM',
            'Sunday: 10:00 AM - 4:00 PM',
          ],
          runningTimesLabel: 'Mo-Fri 9AM-7PM, 10AM-4PM Weekends',
          startAt: '2021-04-12T00:00:00.000Z',
          stockLink: 'https://servedup.co/campaigns/redbbull-stock',
          termsLink: 'https://servedup.co/campaigns/redbull-terms',
          updatedAt: '2021-03-18T10:36:57.390Z',
        }}
      />
    </Page>
  );
};
