import React from 'react';

import StatusLozenge, { statuscode } from '../StatusLozenge';

const statusTypes = {
  Active: statuscode.success,
  Hidden: statuscode.inactive,
};

const PromotionLozenge = ({ status }) => {
  const statusType = statusTypes[status];
  return <StatusLozenge status={statusType} text={status} />;
};
export default PromotionLozenge;
