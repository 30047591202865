import moment from 'moment-timezone';
import { isEmpty } from 'ramda';
import {
  parseGooglePlaceOpenings,
  convertToGooglePlaceOpenings,
} from '../../../shared/utils/googlePlaces';

export const defaultOpenTime = {
  open: moment({ hour: 11, minutes: 0 }),
  close: moment({ hour: 23, minutes: 0 }),
};

const open24HoursTime = {
  open: moment({ hour: 0, minutes: 0 }),
  close: moment({ hour: 0, minutes: 0 }),
};

const weekdays = moment.weekdays(true);

export const defaultInitialValues = {
  openings: weekdays.map((day) => ({
    day,
    time: defaultOpenTime,
    isOpen: true,
  })),
};

const parseTime = (opening) => {
  if (opening.isOpen24Hours) {
    return open24HoursTime;
  }

  if (!opening.time) {
    return defaultOpenTime;
  }

  return opening.time;
};

export const fromOpeningTimesToFormValues = (openings) => {
  if (isEmpty(openings) || !openings) {
    return defaultInitialValues;
  }

  const preparedOpenings = parseGooglePlaceOpenings(openings).map((opening) => ({
    ...opening,
    time: parseTime(opening),
  }));

  return {
    openings: preparedOpenings,
  };
};

export const toOpeningTimesFromValues = (values) => convertToGooglePlaceOpenings(values.openings);
