import { createSelector } from '@reduxjs/toolkit';
import menuTypes from '../../shared/constants/menuTypes';

export const getVenueMenusState = (state) => state.venueMenus;

export const getVenueMenuState = (state) => state.venueMenu;

export const getVenueMenuStatusState = (state) => state.venueMenuStatus;

const getVenueMenuFromMenuName = (_, menuName) => menuName;

export const getVenueMenuSchedule = createSelector(
  getVenueMenusState,
  getVenueMenuFromMenuName,
  (venueMenusState, menuName) =>
    venueMenusState.data.find((venueMenu) => venueMenu.menuName === menuName)?.schedule,
);

export const getVenueMenu = createSelector(
  getVenueMenusState,
  getVenueMenuFromMenuName,
  (venueMenusState, menuName) =>
    venueMenusState.data.find((venueMenu) => venueMenu.menuName === menuName),
);

export const getMenuCountsByType = createSelector(getVenueMenusState, (venueMenusState) => {
  const counts = {};

  Object.keys(menuTypes).forEach((key) => {
    counts[key] = 0;
  });

  venueMenusState.data.forEach((venueMenu) => {
    const { type } = venueMenu;
    counts[type] += 1;
  });

  return counts;
});
