/* eslint-disable max-len */
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useState } from 'react';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';

import { ReactComponent as ArrowRightIcon } from './arrow-right.svg';

const useStyles = makeStyles((theme) => ({
  slider: ({ width = 287 }) => ({
    width: `${width}px !important`,
  }),
  navigationButton: ({ direction }) => ({
    position: 'absolute',
    zIndex: 1,
    width: 40,
    height: 40,
    left: direction === 'left' ? 0 : 'auto',
    right: direction === 'right' ? 0 : 'auto',
    top: -21, // Smallprint offset
    bottom: 0,
    margin: 'auto',
    marginLeft: direction === 'left' ? '1rem' : 'auto',
    marginRight: direction === 'right' ? '1rem' : 'auto',
    borderRadius: '100%',
    backgroundColor: '#fff',
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#f5f5f5',
    },
    '& svg': {
      width: 12,
      transform: direction === 'left' ? 'rotate(180deg)' : 'rotate(0deg)',
    },
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  }),
}));

const NavigationButtons = ({ direction = 'left', navigationVisibility }) => {
  const classes = useStyles({ direction });
  const swiper = useSwiper();
  const isNextButton = direction === 'right';
  const isPrevButton = direction === 'left';
  const isNextButtonVisible = isNextButton && navigationVisibility.next;
  const isPrevButtonVisible = isPrevButton && navigationVisibility.prev;

  const handleClick = () => {
    if (direction === 'left') {
      swiper.slidePrev();
    } else {
      swiper.slideNext();
    }
  };

  if (isNextButtonVisible) {
    return (
      <button type="button" onClick={handleClick} className={classes.navigationButton} label="next">
        <ArrowRightIcon />
      </button>
    );
  }

  if (isPrevButtonVisible) {
    return (
      <button
        type="button"
        onClick={handleClick}
        className={classes.navigationButton}
        label="previous"
      >
        <ArrowRightIcon />
      </button>
    );
  }

  return null;
};

const Carousel = ({ items, ItemComponent, handleClick, width }) => {
  const classes = useStyles({ width });
  const [navigationVisibility, setNavigationVisibility] = useState({
    prev: false,
    next: false,
  });

  const handleToggleNavigationVisibility = ({ isBeginning, isEnd }) => {
    setNavigationVisibility({
      prev: !isBeginning,
      next: !isEnd,
    });
  };

  return (
    <Box sx={{ display: 'flex' }} mb={4}>
      <Box sx={{ flexGrow: '1', maxWidth: '100%', width: 0 }}>
        <Swiper
          spaceBetween={15}
          slidesPerView="auto"
          onAfterInit={handleToggleNavigationVisibility}
          onSlideChange={handleToggleNavigationVisibility}
          observer
          observeSlideChildren
          observeParents
        >
          {items.map((item) => (
            <SwiperSlide
              key={JSON.stringify(item)}
              className={classes.slider}
              onClick={() => handleClick(item)}
            >
              <ItemComponent item={item} />
            </SwiperSlide>
          ))}
          <NavigationButtons direction="left" navigationVisibility={navigationVisibility} />
          <NavigationButtons direction="right" navigationVisibility={navigationVisibility} />
        </Swiper>
      </Box>
    </Box>
  );
};

export default Carousel;
