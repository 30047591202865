import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  FormControlLabel,
  IconButton,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Delete, Shield } from '@mui/icons-material';
import { Formik } from 'formik';

import { getKiosksState } from '../../../store/kiosks/selectors';
import UniversalSave from '../../UniversalSave';
import withVenue from '../../../hoc/withVenue';
import { clearKiosks, deleteKiosk, fetchKiosks, updateKiosk } from '../../../store/kiosks';
import { createKioskReboot, createKioskMenuRefresh } from '../../../api/kiosks';
import useRoles from '../../../hooks/useRoles';
import ROLES from '../../../shared/constants/roles';
import { useNotifications } from '../../../shared/contexts/Notifications/useNotifications';
import { getErrorMessage } from '../../../shared/utils/errors';
import ConfirmRemoveDialog from '../../ConfirmRemoveDialog';

const useStyles = makeStyles((theme) => ({
  tableHeading: {
    '& th': {
      fontWeight: 'bold',
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
}));

const DeviceTable = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [confirmDeleteDialogData, setConfirmDeleteDialogData] = useState({
    kioskId: null,
    name: null,
    showDialog: false,
  });

  const { loading, data: kiosks } = useSelector(getKiosksState);
  const { isRoleAtLeastManager, isRoleAtLeast } = useRoles();
  const { showSuccessNotification, showErrorNotification } = useNotifications();

  const handleUpdateKiosks = async (values) => {
    const { kiosks: kioskValues } = values;

    await Promise.all(
      kioskValues.map(async (kiosk) => {
        const { locked, enabled, kioskId, name, kioskMode } = kiosk;
        const kioskData = kiosks.find((k) => k.kioskId === kioskId);
        const {
          locked: initialLocked,
          enabled: initialEnabled,
          kioskMode: initialKioskMode,
        } = kioskData;

        if (
          locked !== initialLocked ||
          enabled !== initialEnabled ||
          kioskMode !== initialKioskMode
        ) {
          const data = { locked, enabled, kioskMode };

          try {
            await dispatch(updateKiosk({ data, kioskId }));
            showSuccessNotification(`Kiosk - ${name} updated successfully`);
          } catch (error) {
            showErrorNotification(getErrorMessage(error));
          }
        }
      }),
    );
    await dispatch(fetchKiosks());
  };

  const handleDeleteKiosk = async () => {
    const { kioskId, name } = confirmDeleteDialogData;
    try {
      await dispatch(deleteKiosk({ kioskId }));
      showSuccessNotification(`Kiosk - ${name} deleted successfully`);
      await dispatch(fetchKiosks());
    } catch (error) {
      showErrorNotification(getErrorMessage(error));
      await dispatch(fetchKiosks());
    }
  };

  const handleKioskReboot = async (name, kioskId) => {
    try {
      await createKioskReboot({ kioskId });
      showSuccessNotification(`Kiosk - ${name} rebooted successfully`);
    } catch (error) {
      showErrorNotification(getErrorMessage(error));
    }
  };

  const handleKioskMenuRefresh = async (name, kioskId) => {
    try {
      await createKioskMenuRefresh({ kioskId });
      showSuccessNotification(`Kiosk - ${name} menu refreshed successfully`);
    } catch (error) {
      showErrorNotification(getErrorMessage(error));
    }
  };

  const kandjiIdExists = (id) => id && id.length > 10;

  return (
    !loading && (
      <>
        <Formik enableReinitialize initialValues={{ kiosks }}>
          {({ setFieldValue, resetForm, values, dirty, errors }) => (
            <>
              <UniversalSave
                dirty={dirty}
                errors={errors}
                onSave={() => {
                  handleUpdateKiosks(values);
                }}
                onDiscard={resetForm}
              />
              <TableContainer component={Paper}>
                <Table size="small">
                  <TableHead>
                    <TableRow className={classes.tableHeading}>
                      <TableCell />
                      <TableCell>Name</TableCell>
                      <TableCell>Device ID</TableCell>
                      <TableCell>Serial ID</TableCell>
                      <TableCell>App Version</TableCell>
                      <TableCell>Enabled</TableCell>
                      <TableCell>Locked</TableCell>
                      <TableCell>Reader</TableCell>
                      <TableCell>Refresh Menu</TableCell>
                      <TableCell>Reboot</TableCell>
                      <TableCell>Kiosk Mode</TableCell>
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {kiosks?.map((kiosk, index) => (
                      <TableRow key={kiosk.kioskId}>
                        <TableCell>{kandjiIdExists(kiosk.kandjiId) && <Shield />}</TableCell>
                        <TableCell>{kiosk.name}</TableCell>
                        <TableCell>{kiosk.kioskId}</TableCell>
                        <TableCell>{kiosk.serialId}</TableCell>
                        <TableCell>{kiosk.appVersion}</TableCell>
                        <TableCell>
                          <FormControlLabel
                            control={
                              <Switch
                                disabled={!isRoleAtLeastManager()}
                                checked={values.kiosks[index].enabled}
                                type="checkbox"
                                onChange={() =>
                                  setFieldValue(
                                    `kiosks[${index}].enabled`,
                                    !values.kiosks[index].enabled,
                                  )
                                }
                              />
                            }
                          />
                        </TableCell>
                        <TableCell>
                          <FormControlLabel
                            control={
                              <Switch
                                disabled={!isRoleAtLeastManager()}
                                checked={values.kiosks[index].locked}
                                type="checkbox"
                                onChange={() =>
                                  setFieldValue(
                                    `kiosks[${index}].locked`,
                                    !values.kiosks[index].locked,
                                  )
                                }
                              />
                            }
                          />
                        </TableCell>
                        <TableCell>{kiosk.readerId}</TableCell>
                        <TableCell>
                          <Button
                            disabled={!isRoleAtLeastManager()}
                            onClick={() => handleKioskMenuRefresh(kiosk.name, kiosk.kioskId)}
                            variant="contained"
                            color="primary"
                          >
                            Refresh
                          </Button>
                        </TableCell>
                        <TableCell>
                          {kandjiIdExists(kiosk.kandjiId) && (
                            <Button
                              disabled={!isRoleAtLeastManager()}
                              onClick={() => handleKioskReboot(kiosk.name, kiosk.kioskId)}
                              variant="contained"
                              color="primary"
                            >
                              Reboot
                            </Button>
                          )}
                        </TableCell>
                        <TableCell>
                          {kandjiIdExists(kiosk.kandjiId) && (
                            <FormControlLabel
                              control={
                                <Switch
                                  disabled={!isRoleAtLeastManager()}
                                  checked={values.kiosks[index].kioskMode}
                                  type="checkbox"
                                  onChange={() =>
                                    setFieldValue(
                                      `kiosks[${index}].kioskMode`,
                                      !values.kiosks[index].kioskMode,
                                    )
                                  }
                                />
                              }
                            />
                          )}
                        </TableCell>
                        <TableCell>
                          <IconButton
                            disabled={!isRoleAtLeast(ROLES.ADMIN)}
                            onClick={() =>
                              setConfirmDeleteDialogData({
                                kioskId: kiosk.kioskId,
                                name: kiosk.name,
                                showDialog: true,
                              })
                            }
                          >
                            <Delete />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        </Formik>
        <ConfirmRemoveDialog
          title={`This will unregister ${confirmDeleteDialogData.name} as a kiosk.`}
          handleClose={() =>
            setConfirmDeleteDialogData({ ...confirmDeleteDialogData, showDialog: false })
          }
          handleConfirm={() => handleDeleteKiosk()}
          showDialog={confirmDeleteDialogData.showDialog}
        />
      </>
    )
  );
};

export default withVenue(DeviceTable, null, clearKiosks);
