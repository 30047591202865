import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { clone } from 'lodash';

import { Box, Button } from '@mui/material';

import { makeStyles } from '@mui/styles';

import withVenue from '../../hoc/withVenue';
import { useNotifications } from '../../shared/contexts/Notifications/useNotifications';
import { getErrorMessage } from '../../shared/utils/errors';
import CustomDialog from '../../components/CustomDialog';
import { createModifier, fetchModifiers } from '../../store/modifiers';
import ModifierForm from '../../components/ModifierForm/index';
import useRoles from '../../hooks/useRoles';

const useStyles = makeStyles(() => ({
  buttonClass: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginBottom: '10px',
  },
}));

const NewModifierButton = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { showErrorNotification, showSuccessNotification } = useNotifications();
  const { isRoleAtLeastManager } = useRoles();

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [modifierData, setModifierData] = useState(null);
  const [formAction, setFormAction] = useState('');

  const handleOnSubmit = async (values) => {
    const submitValues = clone(values);
    submitValues.modifierItems = submitValues.modifierItems.map((item) => ({
      itemId: item.itemId,
      itemPrice: item.itemPrice,
      multiMax: item.multiMax,
    }));

    try {
      await dispatch(createModifier(submitValues));
      showSuccessNotification('Modifier has been added successfully');
      setIsDialogOpen(false);
      dispatch(fetchModifiers());
    } catch (e) {
      showErrorNotification(getErrorMessage(e));
      setIsDialogOpen(false);
    }
  };

  const handleOpenDialog = () => {
    setModifierData(null);
    setFormAction('create');
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  return (
    <>
      <Box className={classes.buttonClass}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleOpenDialog}
          disabled={!isRoleAtLeastManager()}
        >
          New Modifier
        </Button>
      </Box>
      <Box>
        <aside>
          <CustomDialog
            isDialogOpen={isDialogOpen}
            handleCloseDialog={handleCloseDialog}
            title="Create new modifier"
          >
            <ModifierForm
              formAction={formAction}
              modifierData={modifierData}
              onSubmit={handleOnSubmit}
              onCancel={handleCloseDialog}
            />
          </CustomDialog>
        </aside>
      </Box>
    </>
  );
};

export default withVenue(NewModifierButton);
