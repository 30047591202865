const docs = [
  {
    orderId: 'b816ecd0-054f-4974-95f8-87b9e16ae339',
    cart: [
      {
        unitPrice: 7.5,
        itemId: '110',
        itemName: 'Gran Credo Tempranillo',
        quantity: 1,
        isAlcoholic: true,
        price: 7.5,
        itemOption: '175ml',
        uniqItemId: '110|175ML',
        category: 'RED WINE',
        type: 'DRINK',
      },
    ],
    createdAt: '2020-10-26T16:45:37.589Z',
    total: 7.5,
    subtotal: 7.5,
    venueId: '1',
    totalDiscount: 0,
    updatedAt: '2020-10-26T16:45:58.763Z',
    tableName: '10',
    phone: '+447557575557',
    name: 'Pattie Morissette',
    stripeCustomer: 'cus_I6yEscMRsWIgZH',
    containsDrinks: true,
    containsFood: false,
    orderDisplayId: '20201026_2',
    purchasedAt: '2020-10-26T16:45:58.763Z',
  },
  {
    orderId: 'd362d5d1-2134-4953-98d7-88ae241775e8',
    cart: [
      {
        unitPrice: 5,
        itemId: '197',
        itemName: 'Pastar',
        quantity: 1,
        price: 5,
        itemOption: 'Can',
        uniqItemId: '197|CAN',
        category: 'PASTA',
        type: 'FOOD',
      },
    ],
    createdAt: '2020-10-23T16:06:04.943Z',
    total: 5,
    subtotal: 5,
    venueId: '1',
    totalDiscount: 0,
    updatedAt: '2020-10-23T16:06:29.074Z',
    tableName: '1',
    phone: '+447777555555',
    name: 'Ruth Ebert',
    stripeCustomer: 'cus_IAMX99JRw4hbJa',
    containsDrinks: false,
    containsFood: true,
    orderDisplayId: '20201023_24',
    purchasedAt: '2020-10-23T16:06:29.074Z',
  },
  {
    orderId: '624b9cea-cd07-4fc0-b9c6-818519228127',
    cart: [
      {
        unitPrice: 39,
        itemId: '32',
        itemName: 'Miraval',
        quantity: 1,
        price: 39,
        itemOption: 'Bottle',
        uniqItemId: '32|BOTTLE',
        category: 'ROSE WINE',
        type: 'DRINK',
      },
    ],
    createdAt: '2020-10-23T16:05:38.185Z',
    total: 39,
    subtotal: 39,
    venueId: '1',
    totalDiscount: 0,
    updatedAt: '2020-10-23T16:06:02.158Z',
    tableName: '1',
    phone: '+447777555555',
    name: 'Ruth Ebert',
    stripeCustomer: 'cus_IAMX99JRw4hbJa',
    containsDrinks: true,
    containsFood: false,
    orderDisplayId: '20201023_23',
    purchasedAt: '2020-10-23T16:06:02.158Z',
  },
  {
    orderId: 'cd2c0d84-05aa-45f2-b842-0688e9f27145',
    cart: [
      {
        unitPrice: 4.5,
        itemId: '84',
        itemName: 'Cafe Patron Shot',
        quantity: 2,
        price: 9,
        itemOption: 'Shot',
        uniqItemId: '84|SHOT',
        category: 'SHOT',
        type: 'DRINK',
      },
      {
        unitPrice: 5.1,
        itemId: '3',
        itemName: 'Aspalls Cider',
        quantity: 2,
        price: 10.2,
        itemOption: 'Pint',
        uniqItemId: '3|PINT',
        category: 'CIDER',
        type: 'DRINK',
      },
    ],
    createdAt: '2020-10-23T16:04:59.954Z',
    total: 19.2,
    subtotal: 19.2,
    venueId: '1',
    totalDiscount: 0,
    updatedAt: '2020-10-23T16:05:35.631Z',
    tableName: '1',
    phone: '+447777555555',
    name: 'Ruth Ebert',
    stripeCustomer: 'cus_IAMX99JRw4hbJa',
    containsDrinks: true,
    containsFood: false,
    orderDisplayId: '20201023_22',
    purchasedAt: '2020-10-23T16:05:35.631Z',
  },
  {
    orderId: 'e20fb479-a672-4689-9c34-4652d6dfc93a',
    cart: [
      {
        unitPrice: 6,
        itemId: '183',
        itemName: "Arun's Brew Rum",
        quantity: 2,
        price: 12,
        itemOption: 'Pint',
        uniqItemId: '183|PINT',
        category: 'RUM',
        type: 'DRINK',
      },
    ],
    createdAt: '2020-10-23T15:40:06.627Z',
    total: 12,
    subtotal: 12,
    venueId: '1',
    totalDiscount: 0,
    updatedAt: '2020-10-23T15:40:36.780Z',
    tableName: '1',
    phone: '+447777555555',
    name: 'Ruth Ebert',
    stripeCustomer: 'cus_IAMX99JRw4hbJa',
    containsDrinks: true,
    containsFood: false,
    orderDisplayId: '20201023_11',
    purchasedAt: '2020-10-23T15:40:36.780Z',
  },
  {
    orderId: '146929b9-a8cc-4581-abcf-54ec7688d00e',
    cart: [
      {
        unitPrice: 9.5,
        itemId: '14',
        itemName: 'Summer Negroni',
        quantity: 1,
        price: 9.5,
        itemOption: 'Cocktail',
        uniqItemId: '14|COCKTAIL',
        category: 'COCKTAIL',
        type: 'DRINK',
      },
      {
        unitPrice: 8.5,
        itemId: '88',
        itemName: 'Margherita',
        quantity: 1,
        price: 8.5,
        itemOption: 'Pizza',
        uniqItemId: '88|PIZZA',
        category: 'PIZZA',
        type: 'FOOD',
      },
    ],
    createdAt: '2020-10-23T11:34:40.107Z',
    total: 18,
    subtotal: 18,
    venueId: '1',
    totalDiscount: 0,
    updatedAt: '2020-10-23T11:35:49.384Z',
    tableName: '10',
    phone: '+447555777555',
    name: 'Cecil Gulgowski',
    stripeCustomer: 'cus_I9VnV3qeUnSIZx',
    containsDrinks: true,
    containsFood: true,
    orderDisplayId: '20201023_10',
    purchasedAt: '2020-10-23T11:35:49.384Z',
  },
  {
    orderId: '316bac22-e36d-4498-b4b1-b3c974087628',
    cart: [
      {
        unitPrice: 7.5,
        itemId: '58',
        itemName: 'El Rayo Tequila',
        quantity: 2,
        price: 15,
        itemOption: 'Single incl mixer',
        uniqItemId: '58|SINGLE_INCL_MIXER',
        category: 'Today’s special ',
        type: 'DRINK',
      },
    ],
    createdAt: '2020-10-22T15:42:04.232Z',
    total: 15,
    subtotal: 15,
    venueId: '1',
    totalDiscount: 0,
    updatedAt: '2020-10-22T15:42:31.052Z',
    tableName: '1',
    phone: '+447777555555',
    name: 'Ruth Ebert',
    stripeCustomer: 'cus_IAMX99JRw4hbJa',
    containsDrinks: true,
    containsFood: false,
    orderDisplayId: '20201022_67',
    purchasedAt: '2020-10-22T15:42:31.052Z',
  },
  {
    orderId: '9496cc62-265a-4cf7-a642-1080cfcbbf65',
    cart: [
      {
        unitPrice: 2,
        itemId: '190',
        itemName: "Robin's IPA",
        quantity: 1,
        price: 2,
        itemOption: 'Half Pint',
        uniqItemId: '190|HALF_PINT',
        category: 'BEER',
        type: 'DRINK',
      },
    ],
    createdAt: '2020-10-22T15:29:09.165Z',
    total: 2,
    subtotal: 2,
    venueId: '1',
    totalDiscount: 0,
    updatedAt: '2020-10-22T15:29:27.998Z',
    tableName: '11',
    phone: '+447575755755',
    name: 'Joanne Larson',
    stripeCustomer: 'cus_I7dZAZHDlZewlK',
    containsDrinks: true,
    containsFood: false,
    orderDisplayId: '20201022_66',
    purchasedAt: '2020-10-22T15:29:27.998Z',
  },
  {
    orderId: '081b0973-4bdc-43f0-b604-60108933a6fe',
    cart: [
      {
        unitPrice: 3.5,
        itemId: '5',
        itemName: 'Camden Pale',
        quantity: 1,
        price: 3.5,
        itemOption: 'Half Pint',
        uniqItemId: '5|HALF_PINT',
        category: 'BEER',
        type: 'DRINK',
      },
    ],
    createdAt: '2020-10-22T15:05:21.681Z',
    total: 3.5,
    subtotal: 3.5,
    venueId: '1',
    totalDiscount: 0,
    updatedAt: '2020-10-22T15:11:14.442Z',
    tableName: '10',
    phone: '+447575755755',
    name: 'Joanne Larson',
    stripeCustomer: 'cus_I7dZAZHDlZewlK',
    containsDrinks: true,
    containsFood: false,
    orderDisplayId: '20201022_65',
    purchasedAt: '2020-10-22T15:11:14.442Z',
  },
  {
    orderId: '435141e1-dbf1-449d-9586-8c6da7f77bfe',
    cart: [
      {
        unitPrice: 32.3,
        itemId: '161',
        itemName: 'Stripe Issue Pizza',
        quantity: 1,
        price: 32.3,
        itemOption: 'Pizza',
        uniqItemId: '161|PIZZA',
        category: 'PIZZA',
        type: 'FOOD',
      },
    ],
    createdAt: '2020-10-22T13:57:00.122Z',
    total: 32.3,
    subtotal: 32.3,
    venueId: '1',
    totalDiscount: 0,
    updatedAt: '2020-10-22T13:57:21.791Z',
    tableName: '1',
    phone: '+447777555555',
    name: 'Ruth Ebert',
    stripeCustomer: 'cus_IAMX99JRw4hbJa',
    containsDrinks: false,
    containsFood: true,
    orderDisplayId: '20201022_64',
    purchasedAt: '2020-10-22T13:57:21.791Z',
  },
  {
    orderId: 'c62889dc-beb4-4845-ae1a-5f2d7791b7ab',
    cart: [
      {
        unitPrice: 39,
        itemId: '32',
        itemName: 'Miraval',
        quantity: 1,
        price: 39,
        itemOption: 'Bottle',
        uniqItemId: '32|BOTTLE',
        category: 'ROSE WINE',
        type: 'DRINK',
      },
    ],
    createdAt: '2020-10-22T13:56:36.954Z',
    total: 39,
    subtotal: 39,
    venueId: '1',
    totalDiscount: 0,
    updatedAt: '2020-10-22T13:56:57.790Z',
    tableName: '1',
    phone: '+447777555555',
    name: 'Ruth Ebert',
    stripeCustomer: 'cus_IAMX99JRw4hbJa',
    containsDrinks: true,
    containsFood: false,
    orderDisplayId: '20201022_62',
    purchasedAt: '2020-10-22T13:56:57.790Z',
  },
  {
    orderId: '213d5e1c-42e8-4386-9c04-060c7726549d',
    cart: [
      {
        unitPrice: 12,
        itemId: '96',
        itemName: 'White Capricciosa',
        quantity: 2,
        price: 24,
        itemOption: 'Pizza',
        uniqItemId: '96|PIZZA',
        category: 'PIZZA',
        type: 'FOOD',
      },
      {
        unitPrice: 8,
        itemId: '27',
        itemName: 'Gavi di Gavi',
        quantity: 2,
        price: 16,
        itemOption: '175ml',
        uniqItemId: '27|175ML',
        category: 'WHITE WINE',
        type: 'DRINK',
      },
    ],
    createdAt: '2020-10-22T13:56:01.537Z',
    total: 40,
    subtotal: 40,
    venueId: '1',
    totalDiscount: 0,
    updatedAt: '2020-10-22T13:56:35.480Z',
    tableName: '1',
    phone: '+447777555555',
    name: 'Ruth Ebert',
    stripeCustomer: 'cus_IAMX99JRw4hbJa',
    containsDrinks: true,
    containsFood: true,
    orderDisplayId: '20201022_61',
    purchasedAt: '2020-10-22T13:56:35.480Z',
  },
  {
    orderId: '1cbf1373-b770-478f-90e6-247746b7d24f',
    cart: [
      {
        unitPrice: 7,
        itemId: '52',
        itemName: 'Stoli',
        quantity: 2,
        price: 14,
        itemOption: 'Single incl mixer',
        uniqItemId: '52|SINGLE_INCL_MIXER',
        category: 'VODKA',
        type: 'DRINK',
      },
    ],
    createdAt: '2020-10-22T13:55:06.534Z',
    total: 14,
    subtotal: 14,
    venueId: '1',
    totalDiscount: 0,
    updatedAt: '2020-10-22T13:55:28.909Z',
    tableName: '1',
    phone: '+447777555555',
    name: 'Bertram Millington',
    stripeCustomer: 'cus_IAMX99JRw4hbJa',
    containsDrinks: true,
    containsFood: false,
    orderDisplayId: '20201022_60',
    purchasedAt: '2020-10-22T13:55:28.909Z',
  },
  {
    orderId: 'd52ad626-fe5f-4b9d-9edf-26fea1bf5729',
    cart: [
      {
        unitPrice: 32.3,
        itemId: '161',
        itemName: 'Stripe Issue Pizza',
        quantity: 1,
        price: 32.3,
        itemOption: 'Pizza',
        uniqItemId: '161|PIZZA',
        category: 'PIZZA',
        type: 'FOOD',
      },
    ],
    createdAt: '2020-10-22T13:53:54.719Z',
    total: 32.3,
    subtotal: 32.3,
    venueId: '1',
    totalDiscount: 0,
    updatedAt: '2020-10-22T13:54:16.965Z',
    tableName: '1',
    phone: '+447777555555',
    name: 'Bertram Millington',
    stripeCustomer: 'cus_IAMX99JRw4hbJa',
    containsDrinks: false,
    containsFood: true,
    orderDisplayId: '20201022_59',
    purchasedAt: '2020-10-22T13:54:16.965Z',
  },
  {
    orderId: '89cda439-8f2a-47f7-986f-0012f285a207',
    cart: [
      {
        unitPrice: 39,
        itemId: '32',
        itemName: 'Miraval',
        quantity: 1,
        price: 39,
        itemOption: 'Bottle',
        uniqItemId: '32|BOTTLE',
        category: 'ROSE WINE',
        type: 'DRINK',
      },
    ],
    createdAt: '2020-10-22T13:53:30.685Z',
    total: 39,
    subtotal: 39,
    venueId: '1',
    totalDiscount: 0,
    updatedAt: '2020-10-22T13:53:52.426Z',
    tableName: '1',
    phone: '+447777555555',
    name: 'Bertram Millington',
    stripeCustomer: 'cus_IAMX99JRw4hbJa',
    containsDrinks: true,
    containsFood: false,
    orderDisplayId: '20201022_58',
    purchasedAt: '2020-10-22T13:53:52.426Z',
  },
  {
    orderId: 'b8607189-8962-4e1e-ae9e-4622719eb54b',
    cart: [
      {
        unitPrice: 32.3,
        itemId: '161',
        itemName: 'Stripe Issue Pizza',
        quantity: 1,
        price: 32.3,
        itemOption: 'Pizza',
        uniqItemId: '161|PIZZA',
        category: 'PIZZA',
        type: 'FOOD',
      },
    ],
    createdAt: '2020-10-22T13:53:22.984Z',
    total: 32.3,
    subtotal: 32.3,
    venueId: '1',
    totalDiscount: 0,
    updatedAt: '2020-10-22T13:53:47.323Z',
    tableName: '1',
    phone: '+447777555555',
    name: 'Jethro Liu',
    stripeCustomer: 'cus_IAMX99JRw4hbJa',
    containsDrinks: false,
    containsFood: true,
    orderDisplayId: '20201022_57',
    purchasedAt: '2020-10-22T13:53:47.323Z',
  },
  {
    orderId: '253d334a-85fe-4e0b-958f-90f60ea8ba06',
    cart: [
      {
        unitPrice: 39,
        itemId: '32',
        itemName: 'Miraval',
        quantity: 1,
        price: 39,
        itemOption: 'Bottle',
        uniqItemId: '32|BOTTLE',
        category: 'ROSE WINE',
        type: 'DRINK',
      },
    ],
    createdAt: '2020-10-22T13:52:57.561Z',
    total: 39,
    subtotal: 39,
    venueId: '1',
    totalDiscount: 0,
    updatedAt: '2020-10-22T13:53:20.291Z',
    tableName: '1',
    phone: '+447777555555',
    name: 'Jethro Liu',
    stripeCustomer: 'cus_IAMX99JRw4hbJa',
    containsDrinks: true,
    containsFood: false,
    orderDisplayId: '20201022_54',
    purchasedAt: '2020-10-22T13:53:20.291Z',
  },
];

export const searchOrders = {
  docs,
  total: 217,
};

export default docs;
