import { Button, Card, CardContent, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { fetchAccounts } from '../../store/accounts';
import Page from '../../components/Page';
import useEndpoint from '../../hooks/useEndpoint';
import { getAccountsState, getAccountState } from '../../store/accounts/selectors';
import AccountDialog from './AccountDialog';
import AccountRow from './AccountRow';

const useStyles = makeStyles((theme) => ({
  heading: {
    marginTop: '12px',
    marginBottom: '36px',
    paddingBotton: '40px',
  },
  subheading: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  title: {
    flexGrow: 1,
    ...theme.customFonts.largeBold,
  },
  subtitle: {
    ...theme.customFonts.small,
    color: theme.customPalette.greyDark,
  },
  smallText: {
    ...theme.customFonts.small,
  },
  noChannels: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '2rem',
    marginBottom: '2rem',
  },
  noChannelsButton: {
    marginBottom: '1rem',
  },
  accountCard: {
    padding: '1rem',
    marginBottom: '1rem',
  },
  disabledHeader: {
    ...theme.customFonts.smallBold,
    color: theme.customPalette.greyDarker,
    borderBottom: `1px solid ${theme.customPalette.greyLight}`,
    marginTop: '2rem',
  },
}));

const emptyContent = { name: '', description: '' };

function Accounts() {
  const classes = useStyles();
  const { loading, error, data: accounts } = useEndpoint(getAccountsState, fetchAccounts());
  const { loading: accountLoading } = useSelector(getAccountState);

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dialogType, setDialogType] = useState('create');
  const [editingAccountId, setEditingAccountId] = useState(null);
  const editingAccount = accounts?.find((item) => item.accountId === editingAccountId);
  const isAddDialog = dialogType === 'create';

  const enabledAccounts = accounts?.filter((item) => item.enabled);
  const disabledAccounts = accounts?.filter((item) => !item.enabled);

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const addAccount = () => {
    setDialogType('create');
    setIsDialogOpen(true);
  };

  const editAccount = (accountId) => {
    setDialogType('edit');
    setEditingAccountId(accountId);
    setIsDialogOpen(true);
  };

  return (
    <>
      <Page>
        <header className={classes.heading}>
          <Typography className={classes.title} component="h1">
            <strong>Sales accounts</strong>
          </Typography>
          <div className={classes.subheading}>
            <Typography className={classes.subtitle} component="h2">
              Sales channels let you categorise orders, e.g. takeaway orders or staff lunches
            </Typography>
            <div>
              <Button variant="contained" color="primary" onClick={addAccount}>
                Add
              </Button>
            </div>
          </div>
        </header>
      </Page>
      <Page loading={loading || accountLoading} error={error}>
        {accounts && accounts?.length > 0 ? (
          <Card className={classes.accountCard}>
            {enabledAccounts?.map((account) => (
              <AccountRow key={account.accountId} account={account} editAccount={editAccount} />
            ))}
            {disabledAccounts?.length > 0 && (
              <p className={classes.disabledHeader}>Disabled Accounts</p>
            )}
            {disabledAccounts?.map((account) => (
              <AccountRow key={account.accountId} account={account} editAccount={editAccount} />
            ))}
          </Card>
        ) : (
          <Card>
            <CardContent className={classes.noChannels}>
              <div className={classes.noChannelsButton}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    setIsDialogOpen(true);
                  }}
                >
                  Add
                </Button>
              </div>
              <span className={classes.smallText}>No sales channels yet</span>
            </CardContent>
          </Card>
        )}
      </Page>
      <AccountDialog
        isAddDialog={isAddDialog}
        isDialogOpen={isDialogOpen}
        handleCloseDialog={handleCloseDialog}
        initialValues={isAddDialog || !editingAccount ? emptyContent : editingAccount}
      />
    </>
  );
}

export default Accounts;
