import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, List, ListItemButton, ListItemText } from '@mui/material';

import { useHistory } from 'react-router';
import { setVenue } from '../../store/venues';
import { getVenues, getVenue } from '../../store/venues/selectors';

const VenueSelector = () => {
  const venues = useSelector(getVenues);
  const activeVenue = useSelector(getVenue);
  const dispatch = useDispatch();
  const { push } = useHistory();

  const handleListItemClick = (value) => {
    dispatch(setVenue(venues.find((venue) => value === venue.venueId)));
    push('/');
  };

  if (!venues) return null;

  return (
    <List component={Card}>
      {venues.map((venue) => (
        <ListItemButton
          key={venue.venueId}
          selected={activeVenue && activeVenue.venueId === venue.venueId}
          onClick={() => handleListItemClick(venue.venueId)}
        >
          <ListItemText primary={`${venue.name} ${venue.postcode} `} />
        </ListItemButton>
      ))}
    </List>
  );
};

export default VenueSelector;
