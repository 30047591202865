import { Box, Button, Typography } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import React, { useContext } from 'react';
import SignupContext from '../SignupContext';

const FieldWrapper = withStyles(Box, (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(2),
  },
}));

const LabelWrapper = withStyles(Box, () => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

const Label = withStyles(Typography, (theme) => ({
  root: {
    color: theme.palette.text.secondary,
    fontWeight: 'bold',
    paddingRight: theme.spacing(4),
  },
}));

const Value = withStyles(Typography, (theme) => ({
  root: {
    fontWeight: 'bold',
    paddingRight: theme.spacing(4),
  },
}));

const EditButton = withStyles(Button, () => ({
  root: {
    '& img': {
      width: '18px',
    },
  },
}));

const StepReviewField = ({ label, value, step }) => {
  const { handleGoToStep } = useContext(SignupContext);

  return (
    <FieldWrapper>
      <LabelWrapper>
        <Label>{label}:</Label>
        <EditButton onClick={() => handleGoToStep(step)}>
          <img src="/img/signup/edit-btn.svg" alt="edit" />
        </EditButton>
      </LabelWrapper>
      <Value>{value}</Value>
    </FieldWrapper>
  );
};

export default StepReviewField;
