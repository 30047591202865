import { useState, useEffect } from 'react';
import { get } from '../api/httpRequest';

function useFetchData(endpoint) {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!endpoint) return;

    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await get(endpoint);
        setData(response.data);
      } catch (err) {
        setError(err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [endpoint]);

  return { data, isLoading, error };
}

export default useFetchData;
