import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { move, without } from 'ramda';

import { makeStyles } from '@mui/styles';
import {
  Button,
  IconButton,
  List,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@mui/material';
import { FiPlus } from 'react-icons/fi';
import { TiDelete } from 'react-icons/ti';

import { DragDropContext } from 'react-beautiful-dnd';

import { useNotifications } from '../../../shared/contexts/Notifications/useNotifications';
import { getErrorMessage } from '../../../shared/utils/errors';
import { updateBrandsMenuItem } from '../../../store/brands/brandsMenuItems';
import { getBrandsAttributeValues } from '../../../store/brands/brandsMenuItems/selectors';
import { getBrandsIngredientsObj } from '../../../store/brands/brandsIngredients/selectors';

import Droppable from '../../DragAndDrop/Droppable';
import Draggable from '../../DragAndDrop/Draggable';

const useStyles = makeStyles(() => ({
  heading: {
    display: 'flex',
  },
  title: {
    flexGrow: 1,
  },
  description: {
    marginTop: '10px',
  },
  list: {
    marginTop: '10px',
    borderBottom: '1px solid #F0F4F8',
  },
  listItem: {
    paddingLeft: 0,
    borderTop: '1px solid #F0F4F8',
  },
  listItemButton: {
    paddingLeft: '35px',
  },
  dragIcon: {
    top: 0,
    bottom: 0,
    margin: 'auto',
  },
}));

const BrandsIngredientsList = ({ menuItemId, readonly }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { showErrorNotification, showSuccessNotification } = useNotifications();

  const attributeValues = useSelector((state) =>
    getBrandsAttributeValues(state, 'itemIngredients'),
  );
  const ingredients = useSelector(getBrandsIngredientsObj);

  const update = async (values) => {
    try {
      await dispatch(updateBrandsMenuItem({ menuItemId, values: { itemIngredients: values } }));

      showSuccessNotification('Item has been successfully updated');
    } catch (error) {
      showErrorNotification(getErrorMessage(error));
    }
  };

  const handleDelete = (value) => () => {
    update(without([value], attributeValues));
  };

  const onDragEnd = async ({ source, destination }) => {
    if (!destination) {
      return;
    }
    update(move(source.index, destination.index, attributeValues));
  };

  return (
    <article>
      <header className={classes.heading}>
        <Typography className={classes.title} variant="h4">
          Product ingredients
        </Typography>
        <aside>
          <Button
            variant="contained"
            color="primary"
            startIcon={<FiPlus />}
            component={Link}
            to={`/brand-menu-management/item/${menuItemId}/ingredients`}
            disabled={readonly}
          >
            Add
          </Button>
        </aside>
      </header>
      {!!attributeValues.length && (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable
            droppableId="attributes"
            type="droppable_attributes"
            isDropDisabled={readonly || attributeValues.length < 2}
          >
            <List className={classes.list}>
              {attributeValues.map(
                (value, index) =>
                  ingredients[value] && (
                    <Draggable
                      className={classes.listItem}
                      key={value}
                      draggableId={`attribute${value}`}
                      index={index}
                      isDragDisabled={attributeValues.length < 2}
                      dragIconClassName={classes.dragIcon}
                    >
                      <ListItemButton
                        className={classes.listItemButton}
                        component={Link}
                        to={`/brand-menu-management/items/ingredients/${value}`}
                        key={value}
                      >
                        <ListItemText
                          primary={
                            <>
                              {ingredients[value].ingredientName}
                              {!ingredients[value].available && <strong> NOT AVAILABLE</strong>}
                            </>
                          }
                          secondary={ingredients[value].label || ingredients[value].ingredientName}
                          secondaryTypographyProps={{ variant: 'caption' }}
                        />
                        <ListItemSecondaryAction>
                          <IconButton
                            edge="end"
                            aria-label="delete"
                            onClick={handleDelete(value)}
                            disabled={readonly}
                            size="large"
                          >
                            <TiDelete style={{ fontSize: 20 }} />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItemButton>
                    </Draggable>
                  ),
              )}
            </List>
          </Droppable>
        </DragDropContext>
      )}
      <Typography className={classes.description} variant="h4">
        &apos;e.g. pizza toppings, cocktail ingredients. The customer will see this list if no item
        description is provided&apos;
      </Typography>
    </article>
  );
};

BrandsIngredientsList.propTypes = {
  menuItemId: PropTypes.string.isRequired,
};

export default BrandsIngredientsList;
