import { createSelector } from '@reduxjs/toolkit';

export const getOrdersState = (state) => state.orders;
export const getOrdersLiveState = (state) => state.ordersLive;
export const getOrdersTakeawayState = (state) => state.ordersTakeaway;
export const getOrderState = (state) => state.order;
export const getPolarisOrdersState = (state) => state.polarisOrders;

export const getOrders = createSelector(getOrdersState, (ordersState) => ordersState.data);

export const getOrdersLive = createSelector(
  getOrdersLiveState,
  (ordersLiveState) => ordersLiveState.data,
);

export const getOrdersTakeaway = createSelector(
  getOrdersTakeawayState,
  (ordersTakeawayState) => ordersTakeawayState.data,
);

export const getOrdersObj = createSelector(getOrdersState, (orders) => {
  if (!orders.data) return null;
  return orders.data;
});
