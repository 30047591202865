import React from 'react';
import * as Yup from 'yup';
import { Card, CardContent, ListItem, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Field, Form, Formik } from 'formik';
import { Switch } from 'formik-mui';
import { useDispatch } from 'react-redux';
import BackArrow from '../../../components/BackArrow';
import Page from '../../../components/Page';
import UniversalSave from '../../../components/UniversalSave';
import withSettings from '../../../hoc/withSettings';
import withVenue from '../../../hoc/withVenue';
import { useNotifications } from '../../../shared/contexts/Notifications/useNotifications';
import { getErrorMessage } from '../../../shared/utils/errors';
import { fetchVenueSettings, updateVenueSettings } from '../../../store/venues';
import useRoles from '../../../hooks/useRoles';

const useStyles = makeStyles((theme) => ({
  title: {
    ...theme.customFonts.largeBold,
  },
  secondaryTitle: {
    ...theme.customFonts.small,
    color: theme.customPalette.greyDarker,
    marginBottom: '16px',
  },
  card: {
    borderRadius: '8px',
    marginBottom: '8px',
  },
  cardTitle: {
    ...theme.customFonts.smallBold,
  },
  listItem: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 0,
  },
  listTitle: {
    ...theme.customFonts.small,
    color: theme.customPalette.greyDarker,
    alignItems: 'center',
  },
}));

const UpsellsSchema = Yup.object().shape({
  item: Yup.boolean().required(),
  checkout: Yup.boolean().required(),
  epos: Yup.boolean().required(),
});

const UpsellsSettings = ({ redirect, setting }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { showErrorNotification, showSuccessNotification } = useNotifications();
  const { isAdmin } = useRoles();
  const { value } = setting;
  const initialValues = value;

  const handleOnSubmit = async (values) => {
    try {
      await dispatch(
        updateVenueSettings({
          settingName: 'UPSELLS',
          settingValue: values,
        }),
      );
      showSuccessNotification('Successfully updated settings');
      dispatch(fetchVenueSettings());
    } catch (error) {
      showErrorNotification(getErrorMessage(error));
    }
  };

  return (
    <Page>
      <BackArrow redirect={redirect} text="Settings" />
      <Typography className={classes.title}>Upsells</Typography>
      <Typography className={classes.secondaryTitle}>
        Manage upsells for Epos &amp; QR menu
      </Typography>
      <Formik initialValues={initialValues} enableReinitialize validationSchema={UpsellsSchema}>
        {({ values, setFieldValue, resetForm, errors, dirty, isValid }) => (
          <>
            <UniversalSave
              isValid={isValid}
              dirty={dirty}
              onSave={() => {
                handleOnSubmit(values);
              }}
              onDiscard={resetForm}
              errors={errors}
            />
            <Form>
              <Card className={classes.card}>
                <CardContent style={{ paddingBottom: '16px' }}>
                  <Typography className={classes.cardTitle}>QR menu</Typography>
                  <ListItem className={classes.listItem}>
                    <Typography className={classes.listTitle}>Item upsells</Typography>
                    <Field
                      component={Switch}
                      name="item"
                      type="checkbox"
                      size="medium"
                      color="primary"
                      onChange={() => {
                        setFieldValue('item', !values.item);
                      }}
                    />
                  </ListItem>
                  <ListItem className={classes.listItem}>
                    <Typography className={classes.listTitle}>Checkout upsells</Typography>
                    <Field
                      component={Switch}
                      name="checkout"
                      type="checkbox"
                      size="medium"
                      color="primary"
                      onChange={() => {
                        setFieldValue('checkout', !values.checkout);
                      }}
                    />
                  </ListItem>
                </CardContent>
              </Card>
              {isAdmin() && (
                <Card className={classes.card}>
                  <CardContent>
                    <Typography className={classes.cardTitle}>Epos</Typography>
                    <ListItem className={classes.listItem}>
                      <Typography className={classes.listTitle}>Item upsells</Typography>
                      <Field
                        component={Switch}
                        name="epos"
                        type="checkbox"
                        size="medium"
                        color="primary"
                        onChange={() => {
                          setFieldValue('epos', !values.epos);
                        }}
                      />
                    </ListItem>
                  </CardContent>
                </Card>
              )}
            </Form>
          </>
        )}
      </Formik>
    </Page>
  );
};

const page = withVenue(UpsellsSettings, '/settings');
export default withSettings(page, 'UPSELLS');
