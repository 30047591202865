import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Box, Typography } from '@mui/material';

import { withStyles } from 'tss-react/mui';
import Page from '../../components/Page';
import withVenue from '../../hoc/withVenue';
import { getTabsState } from '../../store/tabs/selectors';
import TabsTable from '../../components/TabsTable';
import { clearTabs, fetchTabs, fetchTabsPage } from '../../store/tabs';
import usePagination from '../../hooks/usePagination';
import useESPicker from '../../hooks/useESPicker';
import { rangeEnums } from '../../shared/utils/dateRanges';
import ESPicker from '../../components/ESPicker';
import toUTC from '../../shared/utils/toUTC';

const Header = withStyles(Box, () => ({
  root: {
    marginBottom: '20px',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
}));

const Title = withStyles(Typography, () => ({
  root: {
    marginRight: '20px',
    width: '50%',
    flexGrow: 1,
  },
}));

const Tabs = () => {
  const dispatch = useDispatch();
  const { loading, data: tabs, error } = useSelector(getTabsState);

  const pagination = usePagination();
  const esPicker = useESPicker();
  const { selectedDates, searchTerm, dateRange, sortOrder, reportingType } = esPicker;
  const { first, requestNewPage, rowsPerPage, resetPagination } = pagination;

  const handleFetchTabsPage = useCallback(() => {
    dispatch(
      fetchTabsPage({
        first,
        size: rowsPerPage,
        sortOrder,
        searchTerm,
        dateRange,
        from: dateRange === rangeEnums.CUSTOM && toUTC(selectedDates.from),
        to: dateRange === rangeEnums.CUSTOM && toUTC(selectedDates.to),
        reportingType,
      }),
    );
  }, [
    dateRange,
    dispatch,
    first,
    rowsPerPage,
    searchTerm,
    selectedDates,
    sortOrder,
    reportingType,
  ]);

  useEffect(() => {
    resetPagination();
    dispatch(
      fetchTabs({
        first: 0,
        size: rowsPerPage,
        sortOrder,
        searchTerm,
        dateRange,
        from: dateRange === rangeEnums.CUSTOM && toUTC(selectedDates.from),
        to: dateRange === rangeEnums.CUSTOM && toUTC(selectedDates.to),
        reportingType,
      }),
    );
  }, [
    dispatch,
    resetPagination,
    rowsPerPage,
    sortOrder,
    searchTerm,
    dateRange,
    selectedDates,
    reportingType,
  ]);

  useEffect(() => {
    if (requestNewPage) {
      handleFetchTabsPage();
    }
  }, [requestNewPage, handleFetchTabsPage]);

  return (
    <Page loading={loading} error={error}>
      {tabs && (
        <>
          <Header>
            <Title noWrap variant="h2" component="h1">
              Tabs
            </Title>
            <ESPicker esPicker={esPicker} />
          </Header>
          <TabsTable tabs={tabs} loading={loading} pagination={pagination} />
        </>
      )}
    </Page>
  );
};

export default withVenue(Tabs, null, clearTabs);
