import { get, patch, del, post } from './httpRequest';

export const fetchUpsellGroups = async () => {
  const response = await get('/upsellGroups');
  return response.data;
};

export const createUpsellGroup = async (values) => {
  const response = await post('/upsellGroups', { data: values });
  return response.data;
};

export const fetchUpsellGroup = async (values) => {
  const { upsellGroupId } = values;
  try {
    const response = await get(`/upsellGroups/${upsellGroupId}`);
    if (response.status === 200) {
      return response.data;
    }
    return [];
  } catch (e) {
    return {
      error: `Request failed with status code ${e.response.status}: ${e.response.statusText}`,
    };
  }
};

export const fetchUpsellGroupInitiatingItems = async (values) => {
  const { upsellGroupId } = values;
  const response = await get(`/upsellGroups/${upsellGroupId}/initiatingItems`);
  return response.data;
};

export const fetchSmartUpsellGroup = async () => {
  const response = await get(`/upsellGroups/2`);
  return response.data;
};

export const updateSmartUpsellGroup = async (values) => {
  const { data } = values;
  const response = await patch(`/upsellGroups/2`, { data });
  return response.data;
};

export const updateUpsellGroup = async (values) => {
  const { upsellGroupId, data } = values;
  const response = await patch(`/upsellGroups/${upsellGroupId}`, { data });
  return response.data;
};

export const deleteUpsellGroup = async (upsellGroupId) => {
  await del(`/upsellGroups/${upsellGroupId}`);
};
