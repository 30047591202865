import React, { useContext } from 'react';
import { TextField } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { useFormikContext } from 'formik';

import SignupContext from '../SignupContext';
import { signupFields } from '../signupFields';

const CustomTextField = withStyles(TextField, (theme) => ({
  root: {
    marginBottom: theme.spacing(4),
    width: '100%',
  },
}));

const StepInput = ({ name, ...props }) => {
  const { handleChange, handleBlur, values, errors, touched } = useFormikContext();
  const { handleOnFieldChange } = useContext(SignupContext);

  return (
    <CustomTextField
      variant="outlined"
      type="text"
      name={name}
      onChange={(event) => {
        handleChange(event);
        handleOnFieldChange(signupFields[name], event.target.value);
      }}
      onBlur={handleBlur}
      value={values[name]}
      error={errors[name] && touched[name]}
      helperText={errors[name] && touched[name] ? errors[name] : ''}
      {...props}
    />
  );
};

export default StepInput;
