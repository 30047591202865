import { IconButton, Menu, MenuItem } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useState, forwardRef } from 'react';
import { MdMoreHoriz } from 'react-icons/md';

const useStyles = makeStyles((theme) => ({
  areaCard: {
    padding: theme.spacing(2),
  },
  areaCardHeader: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  menuIcon: {
    padding: '5px',
    minWidth: '39px',
    maxWidth: '39px',
    border: '1px solid',
    borderColor: theme.customPalette.grey,
    borderRadius: '8px',
  },
}));
const KebabMenu = forwardRef(({ items }, ref) => {
  const filteredItems = items.filter((item) => item);

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const { menuRef } = ref || {};

  const handleMenuClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };
  return (
    <div>
      <IconButton
        className={classes.menuIcon}
        aria-label="more"
        id="long-button"
        aria-controls={isMenuOpen ? 'long-menu' : undefined}
        aria-expanded={isMenuOpen ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleMenuClick}
        color="primary"
        size="large"
      >
        <MdMoreHoriz />
      </IconButton>
      <Menu
        ref={menuRef}
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={handleMenuClose}
      >
        {filteredItems.map(({ name, handler }) => (
          <MenuItem
            key={name}
            onClick={(event) => {
              event.stopPropagation();
              handleMenuClose(event);
              handler(event);
            }}
          >
            {name}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
});

export default KebabMenu;
