import React, { useState } from 'react';
import {
  Box,
  Button,
  Divider,
  IconButton,
  TextField as MuiTextField,
  Autocomplete,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Formik, Field } from 'formik';

import { TextField } from 'formik-mui';
import * as Yup from 'yup';
import {
  AddCircleOutline,
  DirectionsBike,
  RemoveCircleOutline,
  ShoppingBasket,
  Storefront,
} from '@mui/icons-material';

const useStyles = makeStyles((theme) => ({
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    backgroundColor: theme.customPalette.midnight,
  },
  hideVenueFormContainer: {
    display: 'none',
    marginBottom: theme.spacing(2),
  },
  addVenueContainer: {
    backgroundColor: theme.palette.grey[100],
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
    padding: `${theme.spacing(2)} 0`,
  },
  addVenueFormContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    padding: `${theme.spacing(2)} 0`,
    marginBottom: theme.spacing(2),
    '& .MuiTextField-root': {
      width: 300,
    },
    '& > *': {
      marginRight: theme.spacing(2),
      marginTop: theme.spacing(2),
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  venueFormControl: {
    margin: `0 ${theme.spacing(1)}`,
    '&:first-of-type': {
      marginLeft: 0,
    },
  },
  venueFormSelectButtonSelected: {
    backgroundColor: theme.palette.grey[800],
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.grey[700],
    },
  },
}));

const initialValues = {
  venueId: '',
  otterStoreId: '',
  otterPickupEnabled: false,
  otterDeliveryEnabled: true,
  kioskEnabled: false,
};

const AddAssociationSchema = Yup.object().shape({
  venueId: Yup.string().required('Please select a venue'),
  otterStoreId: Yup.string().required('Required'),
});

const AddAssociationForm = ({ venues, handleSubmit }) => {
  const classes = useStyles();
  const [showAddVenueForm, setShowAddVenueForm] = useState(false);

  return (
    <>
      <Box
        className={
          showAddVenueForm ? classes.addVenueFormContainer : classes.hideVenueFormContainer
        }
      >
        <Formik
          enableReinitialize
          onSubmit={handleSubmit}
          validationSchema={AddAssociationSchema}
          initialValues={initialValues}
        >
          {({ values, dirty, isValid, setFieldValue, resetForm }) => (
            <>
              {venues && (
                <Autocomplete
                  id="venue-search"
                  options={venues}
                  getOptionLabel={(option) => `${option.name}, ${option.city}`}
                  getOptionKey={(option) => option.venueId}
                  size="small"
                  filterOptions={(options, params) => {
                    const filtered = options.filter((option) => {
                      const term = params.inputValue.toLowerCase();
                      return (
                        option?.name?.toLowerCase().includes(term) ||
                        option?.venueId?.toString().toLowerCase().includes(term)
                      );
                    });
                    return filtered;
                  }}
                  onChange={(_event, value) => {
                    setFieldValue('venueId', value?.venueId || '');
                  }}
                  renderInput={(params) => (
                    <MuiTextField {...params} label="Venue" variant="outlined" />
                  )}
                  value={venues.find((option) => option.venueId === values.venueId) || null}
                />
              )}
              <Field
                component={TextField}
                name="otterStoreId"
                label="Otter Store ID"
                variant="outlined"
                required
                value={values.otterStoreId}
                size="small"
              />
              <Box>
                <Button
                  variant="outlined"
                  onClick={() => setFieldValue('otterPickupEnabled', !values.otterPickupEnabled)}
                  className={`${
                    values.otterPickupEnabled ? classes.venueFormSelectButtonSelected : ''
                  } ${classes.venueFormControl}`}
                >
                  <ShoppingBasket fontSize="medium" />
                </Button>
                <Button
                  variant="outlined"
                  onClick={() =>
                    setFieldValue('otterDeliveryEnabled', !values.otterDeliveryEnabled)
                  }
                  className={`${
                    values.otterDeliveryEnabled ? classes.venueFormSelectButtonSelected : ''
                  } ${classes.venueFormControl}`}
                >
                  <DirectionsBike fontSize="medium" />
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => setFieldValue('kioskEnabled', !values.kioskEnabled)}
                  className={`${values.kioskEnabled ? classes.venueFormSelectButtonSelected : ''} ${
                    classes.venueFormControl
                  }`}
                >
                  <Storefront fontSize="medium" />
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    handleSubmit(values);
                    resetForm(initialValues);
                  }}
                  disabled={!isValid || !dirty}
                  className={classes.venueFormControl}
                >
                  Add Association
                </Button>
              </Box>
            </>
          )}
        </Formik>
      </Box>
      <Divider variant="fullWidth" className={classes.divider} />
      <Box display="flex" justifyContent="flex-end">
        <IconButton
          variant="outlined"
          onClick={() => {
            setShowAddVenueForm(!showAddVenueForm);
          }}
          size="large"
        >
          {showAddVenueForm ? <RemoveCircleOutline /> : <AddCircleOutline />}
        </IconButton>
      </Box>
    </>
  );
};

export default AddAssociationForm;
