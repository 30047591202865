import React from 'react';
import { MoreHoriz } from '@mui/icons-material';
import { Box, Button, Popover } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useSelector } from 'react-redux';
import { getVenuesSettingsState } from '../../../store/venues/selectors';

const useStyles = makeStyles((theme) => ({
  actionBtn: {
    minWidth: 'unset',
    padding: theme.spacing(1),
    borderRadius: 12,
    borderColor: '#BFCBDA',
    background: theme.customPalette.white,
  },
  popover: {
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
  },
  popoverAction: {
    ...theme.customFonts.medium,
    padding: theme.spacing(1.5),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    borderRadius: 4,
    justifyContent: 'flex-start',
    color: '#343860',
    '&:hover': {
      backgroundColor: '#F2F2F7',
    },
  },
}));

const Actions = ({
  isSessionsMenu = false,
  setActionModal,
  actionModal,
  anchorEl,
  setAnchorEl,
}) => {
  const classes = useStyles();
  const venueSettingsState = useSelector(getVenuesSettingsState);

  const brandEnabled =
    venueSettingsState.data.find(
      (brandEnabledSetting) => brandEnabledSetting.settingName === 'SESSIONS_BRAND_ENABLED',
    ).value || false;

  const handleClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  return (
    <Box>
      <Button variant="outlined" className={classes.actionBtn} onClick={handleClick}>
        <MoreHoriz />
      </Button>
      <Popover
        open={!!anchorEl && !actionModal}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={(event) => {
          event.stopPropagation();
          setAnchorEl(null);
        }}
      >
        <Box className={classes.popover}>
          <Button
            className={classes.popoverAction}
            onClick={(event) => {
              event.stopPropagation();
              setActionModal('EDIT_MENU');
            }}
          >
            Edit menu details
          </Button>
          {(!isSessionsMenu || brandEnabled) && (
            <Button
              className={classes.popoverAction}
              onClick={(event) => {
                event.stopPropagation();
                setActionModal('DUPLICATE');
              }}
            >
              Duplicate menu
            </Button>
          )}
          {!isSessionsMenu && (
            <Button
              className={classes.popoverAction}
              onClick={(event) => {
                event.stopPropagation();
                setActionModal('DELETE');
              }}
            >
              Delete
            </Button>
          )}
        </Box>
      </Popover>
    </Box>
  );
};

export default Actions;
