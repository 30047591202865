import moment from 'moment-timezone';
import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { makeStyles } from '@mui/styles';
import { Box, Card, Grid, Typography } from '@mui/material';

import Page from '../../components/Page';

import { rangeEnums } from '../../shared/utils/dateRanges';
import withVenue from '../../hoc/withVenue';
import ESPicker from '../../components/ESPicker';
import useESPicker from '../../hooks/useESPicker';
import toUTC from '../../shared/utils/toUTC';
import { getReportOverviewState } from '../../store/reports/selectors';
import { getVenueHideGMVSettingState } from '../../store/venues/selectors';
import { fetchVenueHideGMVSetting, clearVenueHideGMVSetting } from '../../store/venues';
import { clearReportOverview, fetchReportOverview } from '../../store/reports';
import DownloadReport from '../../components/DownloadReport';
import ReportsHeader from '../../components/ReportsHeader';
import BarChart from '../../components/Charts/BarChart';
import ReportsBreakdown from '../../components/ReportsBreakdown';
import ReportsMostOrdered from '../../components/ReportsMostOrdered';
import ReportsWetDry from '../../components/ReportsWetDry';
import ReportsVouchersIssued from '../../components/ReportsVouchersIssued';
import TableLink from '../../components/TableLink';
import { useNotifications } from '../../shared/contexts/Notifications/useNotifications';
import PageHeader from '../../components/PageHeader';
import { REPORTING_TYPES } from '../../shared/constants/units';

const descriptions = {
  income: `
  GMV includes VAT, and is before refunds but after any discounts.
  It excludes service charge.
  Orders paid for using vouchers are included in this total, but purchases of vouchers are not included here.
  This is not a traditional accounting measure, and should not be used as such.
  If you wish to hide it for your venue, you can do so in Settings.`,
  grossSales: `
  Gross Sales includes VAT, and is after any discounts and refunds.
  It excludes service charge. Orders paid for using vouchers are included in this total,
  but purchases of vouchers are not included here.`,
  netSales: `
  Net Sales excludes VAT, and is after any discounts and refunds.
  It excludes service charge. Orders paid for using vouchers are included in this total,
  but purchases of vouchers are not included here.`,
};

const useStyles = makeStyles((theme) => ({
  heading: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  title: {
    flexGrow: 1,
  },
  stripeLink: {
    marginLeft: theme.spacing(3),
  },
  form: {
    width: '80%',
    flexGrow: 2,
  },
  formElement: {
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  chart: {
    padding: theme.spacing(2),
    height: '100%',
  },
  card: {
    marginTop: theme.spacing(1),
  },
}));

const getAdditionalOffset = (reportingType) => {
  switch (reportingType) {
    case REPORTING_TYPES.OPERATIONAL:
      return 5 * 60;
    case REPORTING_TYPES.TRANSACTIONAL:
      return 0;
    case REPORTING_TYPES.UTC:
      return moment().utcOffset();
    default:
      return 0;
  }
};
const ReportOverview = ({ currentVenueId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const overviewState = useSelector(getReportOverviewState);
  const { loading, data, error } = overviewState;
  const { loading: settingLoading, data: hideGmvTile } = useSelector(getVenueHideGMVSettingState);

  const esPicker = useESPicker({ useOrder: false, useSearch: false, useUnits: true });
  const { selectedDates, dateRange, units, reportingType } = esPicker;
  const { showErrorNotification } = useNotifications();

  const loadData = useCallback(() => {
    dispatch(
      fetchReportOverview({
        dateRange,
        from: dateRange === rangeEnums.CUSTOM && toUTC(selectedDates.from),
        to: dateRange === rangeEnums.CUSTOM && toUTC(selectedDates.to),
        unit: units,
        reportingType,
      }),
    );
  }, [dateRange, dispatch, selectedDates.from, selectedDates.to, units, reportingType]);

  useEffect(() => {
    loadData();
  }, [dispatch, loadData]);

  useEffect(() => {
    dispatch(fetchVenueHideGMVSetting());
  }, [dispatch]);

  useEffect(() => {
    if (error) showErrorNotification(error.response.data.message);
  }, [error, showErrorNotification]);

  return (
    <>
      <PageHeader fullWidth>
        <Box className={classes.heading}>
          <Typography className={classes.title} variant="h2" component="h1">
            Overview
          </Typography>
        </Box>
        <DownloadReport
          type="summary"
          variant="text"
          title="Export summary"
          fullWidth={false}
          offset={getAdditionalOffset(reportingType)}
        />
        <DownloadReport type="itemised" variant="text" title="Export itemised" fullWidth={false} />
        <DownloadReport
          type="breakdown"
          variant="text"
          title="Export detailed order data"
          fullWidth={false}
        />
        <ESPicker esPicker={esPicker} left />
      </PageHeader>
      <Page loading={loading || settingLoading} fullWidth>
        {currentVenueId && data ? (
          <>
            <Grid container spacing={2}>
              {!hideGmvTile && (
                <Grid item xs={12} md={4} className={classes.card}>
                  <Card className={classes.chart}>
                    <ReportsHeader
                      title="GMV"
                      prefix="£"
                      description={descriptions.income}
                      data={{
                        current: data?.current.income.total,
                        previous: data?.previous.income.total,
                      }}
                    />
                    <ReportsBreakdown data={data} field="income" />
                    <Box display="flex" justifyContent="center">
                      <TableLink to="/reports/sales">See Report</TableLink>
                    </Box>
                  </Card>
                </Grid>
              )}
              <Grid item xs={12} md={hideGmvTile ? 6 : 4} className={classes.card}>
                <Card className={classes.chart}>
                  <ReportsHeader
                    title="Gross sales"
                    prefix="£"
                    description={descriptions.grossSales}
                    data={{
                      current: data?.current.grossSales.total,
                      previous: data?.previous.grossSales.total,
                    }}
                  />
                  <ReportsBreakdown data={data} field="grossSales" />
                </Card>
              </Grid>
              <Grid item xs={12} md={hideGmvTile ? 6 : 4} className={classes.card}>
                <Card className={classes.chart}>
                  <ReportsHeader
                    title="Net revenue"
                    prefix="£"
                    description={descriptions.netSales}
                    data={{
                      current: data?.current.netSales.total,
                      previous: data?.previous.netSales.total,
                    }}
                  />
                  <ReportsBreakdown data={data} field="netSales" />
                </Card>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} className={classes.card}>
                <Card className={classes.chart}>
                  <ReportsHeader
                    title="Orders"
                    data={{
                      current: data?.current.orders.total,
                      previous: data?.previous.orders.total,
                    }}
                  />
                  <BarChart
                    id="customersOverview"
                    x="date"
                    y="ordersCount"
                    data={data.current.histograms}
                    previousData={data.previous.histograms}
                    unit={units}
                  />
                </Card>
              </Grid>{' '}
              <Grid item xs={12} md={6} className={classes.card}>
                <Card className={classes.chart}>
                  <ReportsHeader
                    title="Average spend per order"
                    prefix="£"
                    data={{
                      current: data?.current.averageSpend,
                      previous: data?.previous.averageSpend,
                    }}
                  />
                  <BarChart
                    id="customersOverview2"
                    x="date"
                    y="averageSpend"
                    data={data.current.histograms}
                    previousData={data.previous.histograms}
                    unit={units}
                  />
                </Card>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} md={3} className={classes.card}>
                <Card className={classes.chart}>
                  <ReportsHeader
                    title="Service charge"
                    prefix="£"
                    data={{
                      current: data?.current.serviceCharge.total,
                      previous: data?.previous.serviceCharge.total,
                    }}
                  />
                  <ReportsBreakdown data={data} field="serviceCharge" />
                </Card>
              </Grid>
              <Grid item xs={12} md={3} className={classes.card}>
                <Card className={classes.chart}>
                  <ReportsHeader
                    title="Vouchers Issued"
                    prefix="£"
                    data={{
                      current: data?.current.voucherOrders.total,
                      previous: data?.previous.voucherOrders.total,
                    }}
                  />
                  <ReportsVouchersIssued data={data} />
                </Card>
              </Grid>
              <Grid item xs={12} md={3} className={classes.card}>
                {data.current.mostOrdered.length > 0 && (
                  <Card className={classes.chart}>
                    <ReportsHeader title="Most ordered" />
                    <ReportsMostOrdered data={data.current.mostOrdered} />
                    <Box display="flex" justifyContent="center">
                      <TableLink to="/reports/sales/items">See Report</TableLink>
                    </Box>
                  </Card>
                )}
              </Grid>
              <Grid item xs={12} md={3} className={classes.card}>
                {data.current.foodVsDrink.food !== null ? (
                  <Card className={classes.chart}>
                    <ReportsHeader title="Wet vs. Dry sales" />
                    <ReportsWetDry
                      current={data.current.foodVsDrink}
                      previous={data.previous.foodVsDrink}
                    />
                  </Card>
                ) : null}
              </Grid>
            </Grid>
          </>
        ) : (
          `Error: ${error && error.response.data.message}`
        )}
      </Page>
    </>
  );
};

export default withVenue(ReportOverview, null, [clearReportOverview, clearVenueHideGMVSetting]);
