import { createSlice } from '@reduxjs/toolkit';
import {
  createAsyncAction,
  asyncInitialState as initialState,
  createAsyncReducerHandlers,
} from '../../utils/async';
import {
  fetchBrandsMenusApi,
  fetchBrandsMenusListApi,
  createBrandsMenuApi,
} from '../../../api/brands/brandsMenus';

export const fetchBrandsMenus = createAsyncAction('fetch-brands-menus', fetchBrandsMenusApi);

export const fetchBrandsMenusList = createAsyncAction(
  'fetch-brands-menus-list',
  fetchBrandsMenusListApi,
);

export const createBrandsMenu = createAsyncAction('create-brands-menu', createBrandsMenuApi);

const brandsMenusSlice = createSlice({
  name: 'brandsMenus',
  initialState,
  reducers: {
    clearBrandsMenus(state) {
      delete state.data;
    },
  },
  extraReducers: {
    ...createAsyncReducerHandlers(fetchBrandsMenus),
    ...createAsyncReducerHandlers(createBrandsMenu),
  },
});

const brandsMenusListSlice = createSlice({
  name: 'brandsMenusList',
  initialState,
  reducers: {
    clearBrandsMenusList(state) {
      delete state.data;
    },
  },
  extraReducers: {
    ...createAsyncReducerHandlers(fetchBrandsMenusList),
  },
});

export const { clearBrandsMenus } = brandsMenusSlice.actions;
export const { clearBrandsMenusList } = brandsMenusListSlice.actions;

export const { reducer: brandsMenusList } = brandsMenusListSlice;
export const { reducer: brandsMenus } = brandsMenusSlice;
