import development from '../config/app-config.development.json';
import production from '../config/app-config.production.json';

const config = {
  development,
  production,
};

const environmentConfig = config[process.env.REACT_APP_STAGE];

export default {
  ...environmentConfig.cognito,
  callbackUri: `${window.location.origin}/callback`,
  signoutUri: `${window.location.origin}/signout`,
  userPoolBaseUri: process.env.REACT_APP_COGNITO_URI,
};
