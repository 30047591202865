import {
  Card,
  CardContent,
  FormGroup,
  Grid,
  MenuItem,
  Checkbox,
  FormControlLabel,
  DialogActions,
  Button,
} from '@mui/material';
import { TextField } from 'formik-mui';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState, useRef } from 'react';
import { Form, Formik, Field } from 'formik';
import * as Yup from 'yup';
import { clone } from 'ramda';

import { fetchBrands } from '../../../../store/brands/brands';
import { getBrandsState } from '../../../../store/brands/brands/selectors';
import { getBrandsMenusState } from '../../../../store/brands/brandsMenus/selectors';
import withVenue from '../../../../hoc/withVenue';
import shouldLoad from '../../../../shared/utils/shouldLoad';
import {
  clearBrandsMenus,
  clearBrandsMenusList,
  createBrandsMenu,
  fetchBrandsMenus,
} from '../../../../store/brands/brandsMenus';
import { useNotifications } from '../../../../shared/contexts/Notifications/useNotifications';
import { getErrorMessage } from '../../../../shared/utils/errors';
import { clearBrandsVenuesAssociations } from '../../../../store/brands/brandsVenueAssociations';

const CreateBrandsMenu = ({ handleCloseDialog }) => {
  const formikRef = useRef();
  const dispatch = useDispatch();
  const brandsState = useSelector(getBrandsState);
  const { data: brands } = brandsState;
  const brandsMenusState = useSelector(getBrandsMenusState);
  const { data: brandsMenus } = brandsMenusState;
  const [copyExistingMenu, setCopyExistingMenu] = useState(false);
  const [brandsMenusDropdown, setBrandsMenusDropdown] = useState(brandsMenus);
  const { showErrorNotification, showSuccessNotification } = useNotifications();

  useEffect(() => {
    if (shouldLoad(brandsState)) dispatch(fetchBrands());
    if (shouldLoad(brandsMenusState)) dispatch(fetchBrandsMenus());
  }, [dispatch, brandsState, brandsMenusState]);

  const handleBrandsMenuDropdownChange = (brandId) => {
    const selectedBrandId = brandId;
    setBrandsMenusDropdown(brandsMenus.filter((menu) => menu.brandId === selectedBrandId));
  };

  const ModifierSchema = Yup.object().shape({
    brandName: Yup.string().required('Please choose a brand'),
    variation: Yup.string().min(3, 'Too Short!').required('Please enter a menu variation name'),
    description: Yup.string()
      .min(3, 'Too Short!')
      .required('Please enter a menu variation description'),
  });

  const initialValues = {
    brandName: '',
    brandId: '',
    copyBrandMenuId: '',
    variation: '',
    description: '',
  };

  const handleSubmit = async (values) => {
    const submitValues = clone(values);
    if (submitValues.copyBrandMenuId === '') {
      delete submitValues.copyBrandMenuId;
    }
    try {
      await dispatch(createBrandsMenu(submitValues));
      handleCloseDialog();
      showSuccessNotification(`${values.variation} Created Successfully`);
    } catch (e) {
      showErrorNotification(getErrorMessage(e));
    }
  };

  return (
    <Card>
      <Formik
        innerRef={formikRef}
        validationSchema={ModifierSchema}
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        {({ dirty, values, setFieldValue, isValid, resetForm }) => (
          <>
            <Form>
              <CardContent>
                <FormGroup>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      {brands && (
                        <Field
                          component={TextField}
                          select
                          fullWidth
                          name="brandName"
                          label="Select Brand"
                          variant="outlined"
                          margin="normal"
                          required
                          value={values.brandName || ''}
                          onChange={({ target }) => {
                            const brand = brands.find((b) => b.name === target.value);
                            setFieldValue('brandName', brand.name);
                            setFieldValue('brandId', brand.brandId);
                            setFieldValue('copyBrandMenuId', '');
                            handleBrandsMenuDropdownChange(brand.brandId);
                          }}
                        >
                          {brands.map((brand) => (
                            <MenuItem value={brand.name} key={brand.brandId}>
                              {brand.name}
                            </MenuItem>
                          ))}
                        </Field>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={<Checkbox name="copyProductNameToLabel" color="primary" />}
                        checked={copyExistingMenu}
                        onChange={() => setCopyExistingMenu(!copyExistingMenu)}
                        label="Copy Existing Menu"
                      />
                      {brandsMenus && (
                        <Field
                          component={TextField}
                          select
                          fullWidth
                          name="copyBrandMenuId"
                          label="Select Menu to Copy"
                          variant="outlined"
                          margin="normal"
                          values={values.menuToCopyId || ''}
                          disabled={
                            !copyExistingMenu ||
                            (!values.brandId && copyExistingMenu) ||
                            brandsMenusDropdown.length === 0
                          }
                          onChange={({ target }) => {
                            setFieldValue('copyBrandMenuId', target.value);
                          }}
                        >
                          {brandsMenusDropdown.map((menu) => (
                            <MenuItem value={menu.brandMenuId} key={menu.brandMenuId}>
                              {menu.variation}
                            </MenuItem>
                          ))}
                        </Field>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        component={TextField}
                        name="variation"
                        label="Menu Variation Name"
                        variant="outlined"
                        fullWidth
                        required
                        margin="normal"
                      />
                      <Field
                        component={TextField}
                        name="description"
                        label="Menu Variation Description"
                        variant="outlined"
                        fullWidth
                        required
                        margin="normal"
                      />
                    </Grid>
                  </Grid>
                </FormGroup>
              </CardContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    resetForm();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={!isValid || !dirty}
                >
                  Create
                </Button>
              </DialogActions>
            </Form>
          </>
        )}
      </Formik>
    </Card>
  );
};

export default withVenue(CreateBrandsMenu, '/brand-menu-management', [
  clearBrandsMenus,
  clearBrandsMenusList,
  clearBrandsVenuesAssociations,
]);
