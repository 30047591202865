import React from 'react';
import { Grid, Paper, Typography, FormControl, RadioGroup, Box } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { chunk } from 'lodash';
import Review from './Review';
import RatingsFilter from './RatingsFilter';
import RatingsPagination from './RatingsPagination';
import PageLoader from '../../components/PageLoader';

const ReiewsListContainer = withStyles(Grid, (theme) => ({
  root: {
    maxWidth: theme.spacing(160),
  },
}));

const ReviewsList = ({
  loading,
  reviewsData,
  selectedRating,
  handleOnSelectedRating,
  pagination,
  totalRatings,
}) => {
  const { page, rowsPerPage, handleChangePage } = pagination;
  const { docs: reviews } = reviewsData;
  const reviewsPages = chunk(reviews, rowsPerPage);
  const reviewsCurrentPage = reviewsPages[page] || [];
  const hasReviews = !!reviewsData.docs?.length;

  return (
    <Paper>
      <Box p={3}>
        <Typography variant="h2" component="h2">
          Feedback
        </Typography>
        <Box p={1.5} mt={2} overflow="auto">
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="rating-filter"
              name="ratingFilter"
              onChange={handleOnSelectedRating}
            >
              <RatingsFilter selectedFilter={selectedRating} />
            </RadioGroup>
          </FormControl>
        </Box>
        {!hasReviews && !loading && (
          <Box mt={4} mb={12}>
            Sorry, no reviews matching your filters.
          </Box>
        )}
        {loading && <PageLoader copy="Loading reviews..." />}
        {!loading && (
          <ReiewsListContainer container spacing={8}>
            {reviewsCurrentPage.map((reviewData) => (
              <Grid item xs={12} sm={6} key={reviewData.orderId}>
                <Review reviewData={reviewData} />
              </Grid>
            ))}
          </ReiewsListContainer>
        )}
        <RatingsPagination
          total={totalRatings || 0}
          page={page}
          rowsPerPage={rowsPerPage}
          handleChangePage={handleChangePage}
        />
      </Box>
    </Paper>
  );
};

export default ReviewsList;
