import React from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';

import { getErrorMessage } from '../../shared/utils/errors';
import { useNotifications } from '../../shared/contexts/Notifications/useNotifications';

import withVenue from '../../hoc/withVenue';
import { createExternalPayout, updateExternalPayout } from '../../store/payouts-external';

import Page from '../Page';
import PayoutForm from './PayoutForm';

import payoutTypes from './payoutsType';

const AddPayout = ({ payout, modalClose, onSubmit }) => {
  const { showErrorNotification, showSuccessNotification } = useNotifications();
  const isEditMode = !!payout;
  const dispatch = useDispatch();
  const { recordId } = payout || {};
  const initialValues = {
    amount: isEditMode ? payout.amount : 0,
    type: isEditMode ? payout.type : payoutTypes[0],
    reference: isEditMode ? payout.reference : '',
    day: isEditMode ? payout.day : moment(),
  };

  const handleOnSubmitCreate = async (values) => {
    try {
      const transformedValues = {
        ...values,
        day: moment(values.day).format(),
      };

      await dispatch(createExternalPayout(transformedValues));

      await onSubmit();
      showSuccessNotification('Transaction has been added successfully');
      modalClose();
    } catch (error) {
      showErrorNotification(getErrorMessage(error));
    }
  };

  const handleOnSubmitEdit = async (values) => {
    try {
      const transformedValues = {
        ...values,
        day: moment(values.day).format(),
      };

      await dispatch(updateExternalPayout(recordId, transformedValues));

      await onSubmit();
      showSuccessNotification('Transaction has been edited successfully');
      modalClose();
    } catch (error) {
      showErrorNotification(getErrorMessage(error));
    }
  };

  return (
    <Page>
      <PayoutForm
        formAction={isEditMode ? 'update' : 'create'}
        initialValues={initialValues}
        onSubmit={isEditMode ? handleOnSubmitEdit : handleOnSubmitCreate}
        onCancel={modalClose}
      />
    </Page>
  );
};

export default withVenue(AddPayout, '/payouts');
