import React from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormGroup,
  IconButton,
  MenuItem,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-mui';

import { Close } from '@mui/icons-material';
import menuTypes from '../../../../shared/constants/menuTypes';
import { getMenuCountsByType } from '../../../../store/venueMenus/selectors';
import { duplicateVenueMenu, fetchVenueMenus } from '../../../../store/venueMenus';
import { useNotifications } from '../../../../shared/contexts/Notifications/useNotifications';
import { getErrorMessage } from '../../../../shared/utils/errors';
import getMenuCountError from '../../../../shared/utils/getMenuCountError';

const useStyles = makeStyles((theme) => ({
  dialog: {
    borderRadius: 8,
  },
  dialogTitleWrapper: {
    justifyContent: 'space-between',
    display: 'flex',
    alignItems: 'flex-start',
  },
  dialogTitle: {
    ...theme.customFonts.largeBold,
    padding: theme.spacing(2),
    color: '#181C43',
  },
  dialogClostBtn: {
    padding: theme.spacing(2),
  },
  bodyText: {
    ...theme.customFonts.medium,
    color: '#5A7296',
  },
  btnContainer: {
    borderTop: '1px solid #E0E0EB',
    padding: theme.spacing(2),
  },
  cancelBtn: {
    ...theme.customFonts.mediumBold,
    borderRadius: 12,
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  },
  saveBtn: {
    ...theme.customFonts.mediumBold,
    borderRadius: 12,
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    background: theme.customPalette.black,
    color: theme.customPalette.white,
    '&:hover': {
      background: theme.customPalette.midnight,
    },
  },
  activeSwitch: {
    '& .MuiFormControlLabel-label': {
      ...theme.customFonts.medium,
      color: '#343860',
    },
  },
  section: {
    marginBottom: theme.spacing(2),
    '& .MuiInputBase-formControl': {
      borderColor: '#D0CFDF',
      borderRadius: 12,
    },
  },
  formLabel: {
    ...theme.customFonts.small,
    marginBottom: theme.spacing(0.25),
    color: '#343860',
  },
  textField: {
    ...theme.customFonts.medium,
    color: '#343860',
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  },
  errorText: {
    marginTop: theme.spacing(0.25),
    color: '#CA1D2A',
  },
}));

const DuplicateMenuSchema = Yup.object().shape({
  type: Yup.string()
    .oneOf(Object.values(menuTypes).map(({ value }) => value))
    .required(),
});

const DuplicateMenu = ({ open, onClose, displayMenuName, menuName, type }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { showSuccessNotification, showErrorNotification } = useNotifications();

  const menuTypeCount = useSelector((state) => getMenuCountsByType(state));

  const handleSubmit = async (values) => {
    try {
      await dispatch(duplicateVenueMenu({ menuName, type: values.type }));

      await dispatch(fetchVenueMenus());
      showSuccessNotification(`${displayMenuName} has been updated successfully`);
    } catch (error) {
      showErrorNotification(getErrorMessage(error));
    }
  };

  const getFilteredMenuItems = (menuType) => {
    const excludedValues =
      menuType === 'DELIVERY' ? ['DELIVERY', 'DELIVERY_INHOUSE', 'KIOSK'] : ['DELIVERY', 'KIOSK'];

    return Object.values(menuTypes).filter(({ value }) => !excludedValues.includes(value));
  };

  const initialValues = {
    type: type === 'DELIVERY' ? 'WEB' : type,
  };

  return (
    <Dialog open={open} onClose={onClose} className={classes.dialog} maxWidth="xs" fullWidth>
      <Formik
        initialValues={initialValues}
        onSubmit={() => handleSubmit}
        validationSchema={DuplicateMenuSchema}
      >
        {({ values }) => {
          const errorCountLabel = getMenuCountError({
            menuTypeCount,
            type: values.type,
            initialType: initialValues.type,
          });
          return (
            <>
              <Box className={classes.dialogTitleWrapper}>
                <DialogTitle className={classes.dialogTitle}>Duplicate menu</DialogTitle>
                <IconButton onClick={onClose} className={classes.dialogClostBtn}>
                  <Close />
                </IconButton>
              </Box>
              <DialogContent sx={{ padding: 2 }}>
                <Form>
                  <FormGroup className={classes.section}>
                    <Typography variant="body2" className={classes.formLabel}>
                      Menu type*
                    </Typography>
                    <Field
                      component={TextField}
                      inputProps={{ className: classes.textField }}
                      error={errorCountLabel !== null}
                      name="type"
                      required
                      select
                    >
                      {getFilteredMenuItems(type).map(({ value, label }) => (
                        <MenuItem key={value} value={value}>
                          {label}
                        </MenuItem>
                      ))}
                    </Field>
                    <Typography
                      variant="body2"
                      className={`${classes.formLabel} ${classes.errorText}`}
                    >
                      {errorCountLabel && errorCountLabel}
                    </Typography>
                  </FormGroup>
                </Form>
              </DialogContent>
              <DialogActions className={classes.btnContainer}>
                <Button variant="outlined" onClick={onClose} className={classes.cancelBtn}>
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  className={classes.saveBtn}
                  color="inherit"
                  disabled={errorCountLabel !== null}
                  onClick={() => handleSubmit(values)}
                  disableElevation
                >
                  Duplicate menu
                </Button>
              </DialogActions>
            </>
          );
        }}
      </Formik>
    </Dialog>
  );
};

export default DuplicateMenu;
