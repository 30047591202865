import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Card,
  CardContent,
  FormControlLabel,
  Typography,
  RadioGroup,
  Radio,
  Box,
  Divider,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Form, Field, Formik } from 'formik';
import { Switch, TextField } from 'formik-mui';
import * as Yup from 'yup';

import withVenue from '../../hoc/withVenue';

import { clearCampaign, fetchCampaign } from '../../store/campaign';
import { getCampaignState } from '../../store/campaign/selectors';

import PromotionLozenge from './PromotionLozenge';
import AutoComplete from '../AutoComplete';

import { customCampaignTypes } from '../PromotionsListForm/campaignTypes';

import shouldLoad from '../../shared/utils/shouldLoad';
import UniversalSave from '../UniversalSave';
import AddSchedule from '../AddSchedule';
import useItemsOnMenu from '../../hooks/useItemsOnMenu';

// regex to check for url with optional protocol
const urlRegex = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;

const useStyles = makeStyles((theme) => ({
  form: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  formHeading: {
    fontSize: 16,
  },
}));

const FormObserver = (values, setFormValues) => {
  useEffect(() => {
    setFormValues(values);
  }, [values, setFormValues]);
  return null;
};

const PromotionForm = ({
  campaignId,
  initialValues,
  formRef,
  menuItems,
  setFormValues,
  handleReset,
  handleOnSubmit,
  venue,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { checkIfItemIsOnMenu } = useItemsOnMenu();
  const campaignState = useSelector(getCampaignState);
  const PromotionSchema = Yup.object().shape({
    startgroup: Yup.string().required(),
    startsAt: Yup.string().when('startgroup', {
      is: 'now',
      then: Yup.string().nullable(),
    }),
    endgroup: Yup.string().required(),
    endsAt: Yup.string().when('endgroup', {
      is: 'never',
      then: Yup.string().nullable(),
    }),
    schedule: Yup.array().of(Yup.string()).nullable(),
    campaignName: Yup.string().trim().max(25, 'Offer name cannot exceed 25 characters').required(),
    campaignWebTitle: Yup.string()
      .notEmpty()
      .max(60, 'Main heading cannot exceed 60 characters')
      .optional(),
    campaignWebDescription: Yup.string()
      .notEmpty()
      .max(60, 'Sub heading cannot exceed 60 characters')
      .optional(),
    campaignWebButtonText: Yup.string()
      .notEmpty()
      .max(30, 'Button text cannot exceed 30 characters')
      .optional(),
    active: Yup.boolean().optional(),
    campaignBannerLink: Yup.string()
      .notEmpty()
      .matches(urlRegex, 'Please provide a valid URL eg. https://www.google.com')
      .when('campaignType', {
        is: (value) => value === customCampaignTypes.CUSTOM_EXTERNAL.value,
        then: Yup.string().required('Required'),
      }),
    campaignBanner: Yup.string().required('Please upload an image'),
    productIds: Yup.array()
      .of(Yup.string().notEmpty())
      .when('campaignType', {
        is: (value) => value === customCampaignTypes.CUSTOM_ITEM.value,
        then: Yup.array().of(Yup.string()).required('Required'),
      }),
  });

  useEffect(() => {
    if (shouldLoad(campaignState) && campaignId) {
      dispatch(fetchCampaign(campaignId));
    }
  }, [dispatch, campaignState, campaignId]);

  return (
    <Formik
      validationSchema={PromotionSchema}
      initialValues={initialValues}
      validateOnMount={false}
      innerRef={formRef}
      enableReinitialize
    >
      {({ setFieldValue, values, handleChange, isValid, dirty, errors, resetForm }) => {
        const isCustomExternal = values.campaignType === customCampaignTypes.CUSTOM_EXTERNAL.value;
        const isCustomItem = values.campaignType === customCampaignTypes.CUSTOM_ITEM.value;
        const status = values.active ? 'Active' : 'Hidden';

        return (
          <>
            <UniversalSave
              isValid={isValid}
              dirty={dirty}
              onSave={() => handleOnSubmit(values)}
              onDiscard={() => {
                handleReset();
                resetForm();
              }}
              errors={errors}
            />
            <Card className={classes.form}>
              <Form>
                {FormObserver(values, setFormValues)}
                <CardContent style={{ padding: 0 }}>
                  <section>
                    <Box p={2} display="flex" justifyContent="space-between">
                      <PromotionLozenge status={status} />
                      <FormControlLabel
                        control={
                          <Field name="active" component={Switch} type="checkbox" color="primary" />
                        }
                      />
                    </Box>
                    <Divider />
                    <Box p={2}>
                      <Typography
                        variant="h2"
                        component="p"
                        gutterBottom
                        className={classes.formHeading}
                      >
                        Customise your promotion
                      </Typography>

                      <Field
                        component={TextField}
                        name="campaignWebTitle"
                        label="Include main heading (1 line)"
                        variant="outlined"
                        fullWidth
                        required
                        margin="normal"
                      />
                      <Field
                        component={TextField}
                        name="campaignWebDescription"
                        label="Include sub heading (1 line)"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                      />
                      <Field
                        component={TextField}
                        name="campaignWebButtonText"
                        label="Add button text (max 30 characters)"
                        variant="outlined"
                        fullWidth
                        required
                        margin="normal"
                      />
                      <Typography style={{ fontSize: 14, fontWeight: 600, marginTop: 16 }}>
                        What should this advert link to?
                      </Typography>

                      <RadioGroup
                        name="campaignType"
                        onChange={() => {
                          setFieldValue('campaignBannerLink', '');
                          setFieldValue('productIds', []);
                        }}
                      >
                        {Object.values(customCampaignTypes).map(({ value, text }) => (
                          <FormControlLabel
                            key={value}
                            control={
                              <Field
                                component={Radio}
                                value={value}
                                checked={values.campaignType === value}
                                id="campaignType"
                                color="primary"
                                onChange={handleChange}
                              />
                            }
                            label={text}
                          />
                        ))}
                      </RadioGroup>
                      {isCustomExternal && (
                        <Field
                          component={TextField}
                          name="campaignBannerLink"
                          label="Add a URL"
                          variant="outlined"
                          fullWidth
                          required
                          margin="normal"
                        />
                      )}
                      {isCustomItem && !!menuItems?.length && (
                        <AutoComplete
                          addUnknownOff
                          checkIfItemIsOnMenu={checkIfItemIsOnMenu}
                          key={menuItems}
                          label="Select Item"
                          options={menuItems}
                          value={
                            menuItems.find(({ itemId }) => itemId === values.productIds[0]) || []
                          }
                          optionLabelKey="itemName"
                          optionValueKey="itemId"
                          onSelect={(itemId) => {
                            setFieldValue('productIds', [itemId]);
                          }}
                        />
                      )}
                      <Field
                        component={TextField}
                        name="campaignName"
                        label="Offer name (internal)"
                        variant="outlined"
                        fullWidth
                        required
                        margin="normal"
                      />
                    </Box>
                  </section>
                  <section>
                    <AddSchedule values={values} setFieldValue={setFieldValue} venue={venue} />
                  </section>
                </CardContent>
              </Form>
            </Card>
          </>
        );
      }}
    </Formik>
  );
};

export default withVenue(PromotionForm, null, clearCampaign);
