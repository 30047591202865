import React from 'react';
import { Button, Card, CardActions, CardContent } from '@mui/material';
import CustomDialog from '../CustomDialog/index';
import sendEnquiry from '../../api/brandup';
import withVenue from '../../hoc/withVenue';

const UpgradeDialog = ({ onClose, open }) => {
  const handleSubmit = () => {
    sendEnquiry();
    onClose();
  };

  return (
    <CustomDialog title="Request a chat to upgrade" isDialogOpen={open} handleCloseDialog={onClose}>
      <Card>
        <CardContent>A member of staff will reach out to you</CardContent>
        <CardActions>
          <Button variant="outlined" onClick={onClose}>
            Close
          </Button>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Request a call
          </Button>
        </CardActions>
      </Card>
    </CustomDialog>
  );
};
export default withVenue(UpgradeDialog);
