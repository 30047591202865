import React, { useContext } from 'react';
import { useFormikContext } from 'formik';
import { FormControl, Select, InputLabel, FormHelperText } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { signupFields } from '../signupFields';
import SignupContext from '../SignupContext';

const CustomFormControl = withStyles(FormControl, (theme) => ({
  root: {
    marginBottom: theme.spacing(3),
  },
}));

const CustomSelect = withStyles(Select, () => ({
  root: {
    backgroundColor: '#fff',
  },
}));

const StepDropdown = ({ name, label, children, ...props }) => {
  const { handleChange, handleBlur, values, errors, touched } = useFormikContext();
  const { handleOnFieldChange } = useContext(SignupContext);

  return (
    <CustomFormControl fullWidth variant="filled" error={errors[name] && touched[name]}>
      {label && <InputLabel id={name}>{label}</InputLabel>}
      <CustomSelect
        name={name}
        labelId={name}
        id={name}
        value={values[name]}
        onChange={(event) => {
          handleChange(event);
          handleOnFieldChange(signupFields[name], event.target.value);
        }}
        onBlur={handleBlur}
        {...props}
      >
        {children}
      </CustomSelect>
      {errors[name] && touched[name] && <FormHelperText>{errors[name]}</FormHelperText>}
    </CustomFormControl>
  );
};

export default StepDropdown;
