import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Card,
  CardContent,
  FormGroup,
  MenuItem,
  TableContainer,
  Table,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  FormControlLabel,
  Box,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Formik, Form, Field, FieldArray, useFormikContext } from 'formik';
import { Switch, TextField } from 'formik-mui';
import { nanoid } from 'nanoid';
import * as Yup from 'yup';
import { APIProvider, Map, Marker } from '@vis.gl/react-google-maps';

import { useNotifications } from '../../shared/contexts/Notifications/useNotifications';
import { getErrorMessage } from '../../shared/utils/errors';
import { fetchAvailableDeviceCapabilities, updateDevice } from '../../store/devices';
import { getAvailableDeviceCapabilitiesState } from '../../store/devices/selectors';
import useRoles from '../../hooks/useRoles';
import UniversalSave from '../UniversalSave';

const useStyles = makeStyles(() => ({
  heading: {
    margin: '15px 0',
  },
  card: {
    marginTop: '20px',
    marginBottom: '20px',
  },
}));

const FormObserver = ({ setIsDirty }) => {
  const { dirty } = useFormikContext();
  useEffect(() => {
    setIsDirty(dirty);
  }, [dirty, setIsDirty]);
  return null;
};

const EditDeviceForm = ({
  deviceData: { deviceId, name, model, destination, capabilities },
  deviceData,
  setIsDirty,
}) => {
  const { wifiInfo, dataConnectionInfo, device, locationInfo } = deviceData;
  const { signal: wifiSignal, ssid: wifiSsid, status: wifiStatus } = wifiInfo || {};
  const {
    dataEnabled,
    provider,
    roamingEnabled,
    signal: dataSignal,
    status: dataStatus,
  } = dataConnectionInfo || {};
  const { nodeId, stage, build } = device || {};
  const { debug, flavour, gitBranch, gitCommit, type, version } = build || {};
  const destinationTypes = ['DRINKS', 'FOOD', 'ALL'];
  const dispatch = useDispatch();
  const { isAdmin } = useRoles();
  const classes = useStyles();
  const { showErrorNotification, showSuccessNotification } = useNotifications();
  const { data: deviceCapabilities } = useSelector(getAvailableDeviceCapabilitiesState);
  const capabilityArray =
    deviceCapabilities &&
    deviceCapabilities?.reduce((obj, cap) => ({ ...obj, [cap]: capabilities?.includes(cap) }), {});
  const initialValues = { deviceId, name, model, destination, capabilityArray };

  const onSubmit = async (updatedDevice) => {
    const newCapabilities = Object.entries(updatedDevice.capabilityArray).reduce(
      (newCapabilityList, capability) =>
        capability[1] ? [...newCapabilityList, capability[0]] : newCapabilityList,
      [],
    );
    const transformedUpdatedDevice = {
      ...updatedDevice,
      capabilities: newCapabilities,
    };
    try {
      await dispatch(updateDevice(transformedUpdatedDevice));

      showSuccessNotification('Device has been updated successfully');
    } catch (localError) {
      showErrorNotification(getErrorMessage(localError));
    }
  };

  const EditDeviceSchema = Yup.object().shape({
    deviceId: Yup.string().oneOf([deviceId]).required(),
    name: Yup.string().oneOf([name]).required(),
    model: Yup.string().oneOf([model]).required(),
    destination: Yup.string().oneOf(destinationTypes).required('Please choose a valid destination'),
  });

  useEffect(() => {
    dispatch(fetchAvailableDeviceCapabilities('V2'));
  }, [dispatch]);

  return (
    <>
      <Card>
        <Formik
          enableReinitialize
          onSubmit={onSubmit}
          validationSchema={EditDeviceSchema}
          initialValues={initialValues}
        >
          {({ setFieldValue, values, dirty, isValid, resetForm, errors }) => (
            <>
              <UniversalSave
                isValid={isValid}
                errors={errors}
                dirty={dirty}
                onSave={() => onSubmit(values)}
                onDiscard={resetForm}
              />
              <Form>
                <FormObserver setIsDirty={setIsDirty} />
                <CardContent>
                  <section>
                    <Field
                      component={TextField}
                      name="deviceId"
                      label="Device Id"
                      variant="outlined"
                      fullWidth
                      required
                      disabled
                      margin="normal"
                    />
                    <Field
                      component={TextField}
                      name="name"
                      label="Name"
                      variant="outlined"
                      fullWidth
                      required
                      disabled
                      margin="normal"
                    />
                    <Field
                      component={TextField}
                      name="model"
                      label="Model"
                      variant="outlined"
                      fullWidth
                      required
                      disabled
                      margin="normal"
                    />
                  </section>
                  <section>
                    <FormGroup className={classes.section}>
                      <Field
                        component={TextField}
                        select
                        fullWidth
                        name="destination"
                        label="Select Destination"
                        variant="outlined"
                        margin="normal"
                        required
                        onChange={({ target }) => {
                          setFieldValue('destination', target.value);
                        }}
                      >
                        {destinationTypes.map((destinationType) => {
                          const key = nanoid();

                          return (
                            <MenuItem value={destinationType} key={key}>
                              {destinationType}
                            </MenuItem>
                          );
                        })}
                      </Field>
                      <FormGroup>
                        <Typography className={classes.heading} variant="h4" component="h4">
                          Device Capabilities
                        </Typography>
                        <FieldArray
                          render={() =>
                            values.capabilityArray && (
                              <Box display="flex" flexDirection="row" flexWrap="wrap">
                                {Object.entries(values.capabilityArray).map((val) => (
                                  <FormControlLabel
                                    key={val[0]}
                                    control={
                                      <Field
                                        component={Switch}
                                        color="primary"
                                        type="checkbox"
                                        name={`capabilityArray.${val[0]}`}
                                        checked={val[1]}
                                        value={val[1]}
                                        onChange={(_event, newValue) => {
                                          setFieldValue(`capabilityArray.${val[0]}`, newValue);
                                        }}
                                      />
                                    }
                                    label={val[0]}
                                  />
                                ))}
                              </Box>
                            )
                          }
                        />
                      </FormGroup>
                    </FormGroup>
                  </section>
                </CardContent>
              </Form>
            </>
          )}
        </Formik>
      </Card>
      {isAdmin() && deviceData && (
        <>
          {wifiInfo && (
            <>
              <Typography className={classes.heading} variant="h4" component="h4">
                Wifi Info
              </Typography>
              <TableContainer component={Card} className={classes.card}>
                <Table>
                  <TableBody>
                    {wifiSignal && (
                      <TableRow>
                        <TableCell>Signal:</TableCell>
                        <TableCell align="right">{wifiSignal}</TableCell>
                      </TableRow>
                    )}
                    {wifiStatus && (
                      <TableRow>
                        <TableCell>Status:</TableCell>
                        <TableCell align="right">{wifiStatus}</TableCell>
                      </TableRow>
                    )}
                    {wifiSsid && (
                      <TableRow>
                        <TableCell>SSID:</TableCell>
                        <TableCell align="right">{wifiSsid}</TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}

          {dataConnectionInfo && (
            <>
              <Typography className={classes.heading} variant="h4" component="h4">
                Data Connection Info
              </Typography>
              <TableContainer component={Card} className={classes.card}>
                <Table>
                  <TableBody>
                    {dataSignal && (
                      <TableRow>
                        <TableCell>Signal:</TableCell>
                        <TableCell align="right">{dataSignal}</TableCell>
                      </TableRow>
                    )}
                    {dataStatus && (
                      <TableRow>
                        <TableCell>Status:</TableCell>
                        <TableCell align="right">{dataStatus}</TableCell>
                      </TableRow>
                    )}
                    {dataEnabled && (
                      <TableRow>
                        <TableCell>Data Enabled:</TableCell>
                        <TableCell align="right">{dataEnabled.toString()}</TableCell>
                      </TableRow>
                    )}
                    {roamingEnabled && (
                      <TableRow>
                        <TableCell>Roaming Enabled:</TableCell>
                        <TableCell align="right">{roamingEnabled}</TableCell>
                      </TableRow>
                    )}
                    {provider && (
                      <TableRow>
                        <TableCell>Provider:</TableCell>
                        <TableCell align="right">{provider}</TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}

          {locationInfo && (
            <>
              <Typography className={classes.heading} variant="h4" component="h4">
                Location Info
              </Typography>
              <TableContainer component={Card} className={classes.card}>
                <Table>
                  <TableBody>
                    {locationInfo?.latitude && (
                      <TableRow>
                        <TableCell>Latitude:</TableCell>
                        <TableCell align="right">{locationInfo.latitude}</TableCell>
                      </TableRow>
                    )}
                    {locationInfo?.longitude && (
                      <TableRow>
                        <TableCell>Longitude:</TableCell>
                        <TableCell align="right">{locationInfo.longitude}</TableCell>
                      </TableRow>
                    )}
                    {locationInfo?.accuracy && (
                      <TableRow>
                        <TableCell>Accuracy:</TableCell>
                        <TableCell align="right">{locationInfo.accuracy}%</TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              {locationInfo?.latitude && locationInfo?.longitude && (
                <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
                  <Map
                    mapId={deviceId}
                    style={{ height: '500px' }}
                    defaultZoom={17}
                    defaultCenter={{ lat: locationInfo.latitude, lng: locationInfo.longitude }}
                    gestureHandling="greedy"
                    reuseMaps
                  >
                    <Marker
                      position={{ lat: locationInfo.latitude, lng: locationInfo.longitude }}
                    />
                  </Map>
                </APIProvider>
              )}
            </>
          )}

          {device && (
            <>
              <Typography className={classes.heading} variant="h4" component="h4">
                Device Info
              </Typography>
              <TableContainer component={Card} className={classes.card}>
                <Table>
                  <TableBody>
                    {nodeId && (
                      <TableRow>
                        <TableCell>NodeId:</TableCell>
                        <TableCell align="right">{nodeId}</TableCell>
                      </TableRow>
                    )}
                    {stage && (
                      <TableRow>
                        <TableCell>Stage:</TableCell>
                        <TableCell align="right">{stage}</TableCell>
                      </TableRow>
                    )}
                    {debug && (
                      <TableRow>
                        <TableCell>Debug:</TableCell>
                        <TableCell align="right">{debug.toString()}</TableCell>
                      </TableRow>
                    )}
                    {flavour && (
                      <TableRow>
                        <TableCell>Flavour:</TableCell>
                        <TableCell align="right">{flavour}</TableCell>
                      </TableRow>
                    )}
                    {gitBranch && (
                      <TableRow>
                        <TableCell>GitBranch:</TableCell>
                        <TableCell align="right">{gitBranch}</TableCell>
                      </TableRow>
                    )}
                    {gitCommit && (
                      <TableRow>
                        <TableCell>GitCommit:</TableCell>
                        <TableCell align="right">{gitCommit}</TableCell>
                      </TableRow>
                    )}
                    {type && (
                      <TableRow>
                        <TableCell>Type:</TableCell>
                        <TableCell align="right">{type}</TableCell>
                      </TableRow>
                    )}
                    {version && (
                      <TableRow>
                        <TableCell>Version:</TableCell>
                        <TableCell align="right">{version}</TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        </>
      )}
    </>
  );
};

export default EditDeviceForm;
