import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { Draggable as DndDraggable } from 'react-beautiful-dnd';
import clsx from 'clsx';
import { getDraggableStyle } from './helpers';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  dragIcon: {
    position: 'absolute',
    top: 0,
    margin: 'auto',
    color: '#333333',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(2.5),
    marginLeft: theme.spacing(1),
  },
}));

const Draggable = ({
  children,
  draggableId,
  index,
  isDragDisabled,
  dragIconClassName = '',
  className,
}) => {
  const classes = useStyles();
  return (
    <DndDraggable draggableId={draggableId} index={index} isDragDisabled={isDragDisabled}>
      {(provided, snapshot) => (
        <div
          className={clsx(classes.root, className)}
          ref={provided.innerRef}
          {...provided.draggableProps}
          style={getDraggableStyle(snapshot.isDragging, provided.draggableProps.style)}
        >
          {children}
          <span
            className={`${classes.dragIcon} ${dragIconClassName}`}
            {...provided.dragHandleProps}
          >
            <DragIndicatorIcon style={{ fontSize: '25' }} />
          </span>
        </div>
      )}
    </DndDraggable>
  );
};

Draggable.propTypes = {
  children: PropTypes.element.isRequired,
  draggableId: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  isDragDisabled: PropTypes.bool,
};

Draggable.defaultProps = {
  isDragDisabled: false,
};

export default Draggable;
