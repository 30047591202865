import Fuse from 'fuse.js';
import { Box, Button, Collapse, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Field, Form, Formik, useFormikContext } from 'formik';
import { uniq } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import getItemsByCategory from './getItemsByCategory';
import isNotThisDiscount from './isNotThisDiscount';
import DiscountItem from './DiscountItem';
import UniversalSave from '../../components/UniversalSave';

const FormObserver = ({ itemsByCategory, discountId }) => {
  const { values, setFieldValue, setFieldTouched } = useFormikContext();

  useEffect(() => {
    if (!itemsByCategory) return;
    Object.keys(itemsByCategory).forEach((category) => {
      const itemsWithoutDiscount = itemsByCategory[category].filter(
        (item) => !item.discount || item.discount.discountId === discountId,
      );
      const checkingItems = itemsWithoutDiscount.map((item) => item.itemId);
      const intersection = values.itemsChecked.filter((value) => checkingItems.includes(value));
      const fullfiled = intersection.length === checkingItems.length && checkingItems.length > 0;
      setFieldValue(category, fullfiled);
    });
    setFieldValue('itemsChecked', values.itemsChecked.sort());
    setFieldTouched('itemsChecked', true);
  }, [discountId, itemsByCategory, setFieldValue, setFieldTouched, values]);
  return null;
};

const useStyles = makeStyles((theme) => ({
  category: {
    backgroundColor: '#F0F4F8',
    borderRadius: '4px',
    padding: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
  },
  item: {
    padding: theme.spacing(1),
  },
  itemCategory: {
    color: '#647F99',
  },
  checkbox: {
    paddingTop: 0,
    marginRight: theme.spacing(1),
  },
  expandButton: {
    border: '1px solid #657E98',
    borderRadius: '5px',
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
}));

const DiscountAddItemForm = ({ items, discountId, onSubmit, showOthers, searchTerm }) => {
  const classes = useStyles();
  const [categoriesOpen, setCategoriesOpen] = useState({});
  const [filteredItems, setFilteredItems] = useState(items);
  const showSearch = searchTerm.length > 0;

  const isCategoryOpen = (category) => {
    if (categoriesOpen[category] === undefined) {
      return true;
    }
    return categoriesOpen[category];
  };
  const setCategoryOpen = useCallback(
    (category) => {
      if (categoriesOpen[category] === undefined) {
        setCategoriesOpen({
          ...categoriesOpen,
          [category]: false,
        });
      } else {
        setCategoriesOpen({
          ...categoriesOpen,
          [category]: !categoriesOpen[category],
        });
      }
    },
    [categoriesOpen],
  );

  const shouldRenderItem = useCallback(
    (itemDiscount, onlyThisDiscount) => {
      if (!itemDiscount) return true;
      if (!isNotThisDiscount(itemDiscount, discountId)) return true;
      if (isNotThisDiscount(itemDiscount, discountId) && showOthers && !onlyThisDiscount)
        return true;
      return false;
    },
    [discountId, showOthers],
  );

  const itemsByCategory = getItemsByCategory(items, discountId);

  const itemsChecked = items
    ?.filter((item) => item.discount?.discountId === discountId)
    .map((item) => item.itemId);
  const categoryValues = {};
  if (itemsByCategory)
    Object.keys(itemsByCategory).forEach((category) => {
      const itemsWithoutDiscount = itemsByCategory[category].filter(
        (item) => !item.discount || item.discount.discountId === discountId,
      );
      const checkingItems = itemsWithoutDiscount.map((item) => item.itemId);
      const intersection = itemsChecked.filter((value) => checkingItems.includes(value));
      const fullfiled = intersection.length === checkingItems.length && checkingItems.length > 0;
      categoryValues[category] = fullfiled;
    });

  const initialValues = {
    ...categoryValues,
    itemsChecked: items
      ?.filter((item) => item.discount?.discountId === discountId)
      .map((item) => item.itemId)
      .sort(),
  };

  const handleCategorySelectAll = (category, setFieldValue, values) => {
    const checkingItems = itemsByCategory[category].map((item) => item.itemId);
    const removingItems = values.itemsChecked.filter((item) => !checkingItems.includes(item));
    const checking = !values[category];
    setFieldValue(category, checking);
    if (checking) {
      const updatingItems = uniq([...values.itemsChecked, ...checkingItems]);
      const updatingWithoutDiscounts = updatingItems.filter(
        (value) =>
          !isNotThisDiscount(
            itemsByCategory[category].find((item) => item.itemId === value)?.discount,
            discountId,
          ),
      );

      setFieldValue('itemsChecked', updatingWithoutDiscounts);
    } else {
      const removingWithoutDiscounts = removingItems.filter(
        (value) =>
          !isNotThisDiscount(
            itemsByCategory[category].find((item) => item.itemId === value)?.discount,
            discountId,
          ),
      );
      setFieldValue('itemsChecked', removingWithoutDiscounts);
    }
  };

  useEffect(() => {
    if (!items) return;
    const fuse = new Fuse(items, {
      keys: ['itemName', 'category'],
    });
    const result = fuse.search(searchTerm);
    const filtered = result.map((item) => item.item);
    setFilteredItems(filtered);
  }, [items, searchTerm]);

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validateOnChange={false}>
      {({ dirty, setFieldValue, values, resetForm, errors }) => (
        <>
          <UniversalSave
            dirty={dirty}
            onSave={() => onSubmit(values)}
            onDiscard={resetForm}
            errors={errors}
          />
          <Form>
            <FormObserver itemsByCategory={itemsByCategory} discountId={discountId} />
            {itemsByCategory &&
              Object.keys(itemsByCategory).map((category) => (
                <div key={category}>
                  {itemsByCategory[category].length &&
                    itemsByCategory[category].some(({ discount: itemDiscount }) =>
                      shouldRenderItem(itemDiscount),
                    ) && (
                      <div key={category}>
                        {!showSearch && (
                          <div className={classes.category}>
                            <Button
                              className={classes.expandButton}
                              onClick={() => setCategoryOpen(category)}
                            >
                              {isCategoryOpen(category) ? <ExpandLess /> : <ExpandMore />}
                            </Button>
                            <Typography variant="h2">
                              <Field
                                type="checkbox"
                                disabled={
                                  !itemsByCategory[category].some(({ discount: itemDiscount }) =>
                                    shouldRenderItem(itemDiscount, true),
                                  )
                                }
                                className={classes.checkbox}
                                name={category}
                                onChange={() =>
                                  handleCategorySelectAll(category, setFieldValue, values)
                                }
                              />
                              {category}
                            </Typography>
                          </div>
                        )}
                        <Collapse in={isCategoryOpen(category)} timeout="auto">
                          {itemsByCategory[category].map((item) => {
                            const { itemId, category: itemCategory, discount: itemDiscount } = item;
                            return (
                              <div key={itemId}>
                                {shouldRenderItem(itemDiscount) &&
                                  (!showSearch ||
                                    filteredItems.find(
                                      (filteredItem) => itemId === filteredItem.itemId,
                                    )) && (
                                    <div key={itemId} className={classes.item}>
                                      <Box display="flex" alignItems="flex-start">
                                        <Field
                                          type="checkbox"
                                          className={classes.checkbox}
                                          name="itemsChecked"
                                          value={itemId}
                                          disabled={isNotThisDiscount(itemDiscount, discountId)}
                                          style={{
                                            visibility: isNotThisDiscount(itemDiscount, discountId)
                                              ? 'hidden'
                                              : 'visible',
                                          }}
                                        />
                                        <DiscountItem
                                          item={item}
                                          hasIcon={isNotThisDiscount(itemDiscount, discountId)}
                                          subtitleText={
                                            isNotThisDiscount(itemDiscount, discountId)
                                              ? itemDiscount.name
                                              : itemCategory
                                          }
                                        />
                                      </Box>
                                    </div>
                                  )}
                              </div>
                            );
                          })}
                        </Collapse>
                      </div>
                    )}
                </div>
              ))}
          </Form>
        </>
      )}
    </Formik>
  );
};
export default DiscountAddItemForm;
