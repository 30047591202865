import React from 'react';
import { Button } from '@mui/material';

const UploadQrCodes = ({ handleFileInput }) => (
  <>
    <Button variant="contained" color="primary" component="label">
      Upload QR Codes
      <input type="file" hidden accept="text/csv" onChange={handleFileInput} />
    </Button>
  </>
);

export default UploadQrCodes;
