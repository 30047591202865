import { Button, Typography } from '@mui/material';
import React from 'react';
import CustomDialog from '../../components/CustomDialog';

import PhotoUploadCropper from '../../components/PhotoUploader/PhotoUploadCropper';

const PromotionPhotoUpload = ({
  file,
  handleCapture,
  handleSetImage,
  isCropModalVisibile,
  handleCloseDialog,
  error,
}) => (
  <>
    <CustomDialog
      title="Offer photo"
      isDialogOpen={isCropModalVisibile}
      handleCloseDialog={handleCloseDialog}
    >
      <PhotoUploadCropper
        file={file}
        handleUpload={handleSetImage}
        handleCloseDialog={handleCloseDialog}
        aspectRatio={224 / 115}
      />
    </CustomDialog>
    <Button variant="outlined" color="primary" component="label">
      Upload image
      <input type="file" hidden onChange={handleCapture} accept="image/jpeg, image/png" />
    </Button>
    {error && (
      <Typography color="error" variant="subtitle1">
        {error}
      </Typography>
    )}
  </>
);

export default PromotionPhotoUpload;
