import React from 'react';
import { useClearCache } from 'react-clear-cache';

const CacheBuster = process.env.REACT_APP_LOCAL_NO_CACHE_BUSTER
  ? ({ children }) => children
  : ({ children }) => {
      const { isLatestVersion, emptyCacheStorage } = useClearCache({ auto: true });
      return !isLatestVersion ? (
        <div style={{ textAlign: 'center' }}>
          <p>A new version of the application is available.</p>
          <p>
            <button
              type="button"
              style={{ fontSize: '20px', padding: '20px 50px' }}
              onClick={() => {
                emptyCacheStorage();
              }}
            >
              Update version
            </button>
          </p>
        </div>
      ) : (
        children
      );
    };

export default CacheBuster;
