import { pick } from 'ramda';
import { get, post, patch, del } from './httpRequest';

export const fetchIngredients = async () => {
  const response = await get('/ingredients');
  return response.data;
};

export const fetchIngredient = async (ingredientId) => {
  const response = await get(`/ingredients/${ingredientId}`);
  return response.data;
};

export const deleteIngredient = async (ingredientId) => {
  const response = await del(`/ingredients/${ingredientId}`);
  return response.data;
};

export const createIngredient = async (data) => {
  const response = await post('/ingredients', { data });

  return response.data;
};

export const updateIngredient = async ({ ingredientId, values }) => {
  const data = pick(['isAlcoholic', 'available', 'type', 'ingredientName', 'label'], values);
  await patch(`/ingredients/${ingredientId}`, { data });
};

export const updateIngredientsAvailability = async ({ values }) => {
  const data = { ingredients: [] };
  values.forEach((ingredient) => {
    data.ingredients.push(pick(['ingredientId', 'available'], ingredient));
  });
  await patch(`/ingredients/availability`, { data });
};
