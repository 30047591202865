import { Button, IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import {
  BarChartOutlined,
  Close,
  EmojiEmotionsOutlined,
  ReceiptLongOutlined,
} from '@mui/icons-material';
import CustomDialog from '../../components/CustomDialog';

const useStyles = makeStyles((theme) => ({
  content: {
    padding: '16px 16px 0 16px',
    display: 'flex',
    flexDirection: 'column',
    borderBottom: `1px solid ${theme.sessionsScorePalette.nonCompliantLightGrey}`,
    '& p': {
      margin: '0',
    },
    '& h2': {
      margin: '0',
    },
  },
  dialog: {
    paddingTop: '24px',
  },
  dialogItem: {
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
    paddingBottom: '24px',
  },
  icons: {
    display: 'flex',
    backgroundColor: theme.sessionsScorePalette.nonCompliantLightGrey,
    borderRadius: '24px',
    padding: '8px',
  },
  buttonContainer: {
    padding: '16px',
  },
  button: {
    borderRadius: '12px',
    padding: '8px 16px',
  },
  logoImg: {
    position: 'absolute',
    left: '50%',
    top: '0',
    transform: 'translateX(-50%)',
    maxWidth: '100%',
    height: 'auto',
  },
  dialogClostBtn: {
    position: 'absolute',
    left: '90%',
    top: '1%',
    transform: 'translateX(-50%)',
    maxWidth: '100%',
    height: 'auto',
    backgroundColor: theme.customPalette.white,
    borderRadius: '28px',
    padding: '8px',
    [theme.breakpoints.up('sm')]: {
      left: '95%',
    },
  },
  img: {
    width: '100%',
    height: '200px',
  },
}));

const WelcomeDialog = ({ onClose, open }) => {
  const classes = useStyles();
  return (
    <CustomDialog isDialogOpen={open} handleCloseDialog={onClose}>
      <img src="/img/signup/welcome-img.png" alt="Locked" className={classes.img} />

      <img
        src="/img/sessions_symbol.svg"
        alt="Logo"
        className={classes.logoImg}
        style={{ zIndex: '1', width: '52px', height: '52px' }}
      />
      <img src="/img/signup/yellow-vector.png" alt="" className={classes.logoImg} />
      <IconButton disableRipple onClick={onClose} className={classes.dialogClostBtn}>
        <Close style={{ color: '#343860' }} />
      </IconButton>
      <div className={classes.content}>
        <h2>Welcome to Sessions Serve</h2>
        <div className={classes.dialog}>
          <div className={classes.dialogItem}>
            <div className={classes.icons}>
              <ReceiptLongOutlined />
            </div>
            <p>Manage your orders, menus &amp; devices</p>
          </div>
          <div className={classes.dialogItem}>
            <div className={classes.icons}>
              <BarChartOutlined />
            </div>
            <p>View &amp; export detailed reports</p>
          </div>
          <div className={classes.dialogItem}>
            <div className={classes.icons}>
              <EmojiEmotionsOutlined />
            </div>
            <p>See customer reviews &amp; more</p>
          </div>
        </div>
      </div>
      <div className={classes.buttonContainer}>
        <Button variant="contained" color="primary" onClick={onClose} className={classes.button}>
          Check it out
        </Button>
      </div>
    </CustomDialog>
  );
};
export default WelcomeDialog;
