import React, { useEffect, useState, useMemo } from 'react';
import { Buffer } from 'buffer';
import moment from 'moment-timezone';
import { Button, CircularProgress } from '@mui/material';
import fileDownload from 'js-file-download';
import { useSelector } from 'react-redux';
import GetAppIcon from '@mui/icons-material/GetApp';
import { startCase } from 'lodash';
import { getIdToken } from '../../cognito/sessionTokens';
import { useNotifications } from '../../shared/contexts/Notifications/useNotifications';
import { getVenueState } from '../../store/venues/selectors';
import useRoles from '../../hooks/useRoles';
import { dateRanges, rangeEnums } from '../../shared/utils/dateRanges';
import toUTC from '../../shared/utils/toUTC';
import formattedTimezoneOffset from '../../shared/utils/formattedTimezoneOffset';
import { getSearchState } from '../../store/search';
import TableLink from '../TableLink';

const DownloadReport = ({
  type,
  includeVouchers = false,
  fullWidth = true,
  tillId,
  title,
  variant = 'contained',
  path,
  offset,
  isButton = true,
  payoutFromDate,
  payoutToDate,
}) => {
  const { showErrorNotification, showSuccessNotification } = useNotifications();
  const { data: venue } = useSelector(getVenueState);
  const [loading, setLoading] = useState(false);
  const idToken = getIdToken();
  const { isAdmin } = useRoles();
  const apiUrl = process.env.REACT_APP_API_URI;
  const {
    dateRange,
    fromDate: fromDateState,
    toDate: toDateState,
    reportingType,
  } = useSelector(getSearchState);

  const [url, setUrl] = useState(`${apiUrl}/venues/${venue.venueId}${path}`);

  const fromDate = useMemo(
    () =>
      toUTC(
        dateRange === rangeEnums.CUSTOM ? fromDateState : dateRanges[dateRange].from(),
        reportingType,
      ),
    [dateRange, reportingType, fromDateState],
  );
  const toDate = useMemo(
    () =>
      toUTC(
        dateRange === rangeEnums.CUSTOM ? toDateState : dateRanges[dateRange].to(),
        reportingType,
      ),
    [dateRange, reportingType, toDateState],
  );
  const disabled = isAdmin() ? false : moment(toDate).diff(moment(fromDate), 'months') > 3;

  useEffect(() => {
    switch (type) {
      case 'itemised':
        setUrl(`${apiUrl}/venues/${venue.venueId}/adminCSV/itemised?from=${fromDate}&to=${toDate}`);
        break;
      case 'summary':
        setUrl(
          `${apiUrl}/venues/${
            venue.venueId
          }/summary?from=${fromDate}&to=${toDate}&type=${type}&offset=${formattedTimezoneOffset(
            offset,
          )}`,
        );
        break;
      case 'vouchers':
        setUrl(
          `${apiUrl}/venues/${venue.venueId}/vouchersReport?from=${fromDate}&to=${toDate}&type=${type}`,
        );
        break;
      case 'floats':
        setUrl(
          `${apiUrl}/venues/${venue.venueId}/floats/${tillId}/export?from=${fromDate}&to=${toDate}`,
        );
        break;
      case 'breakdown':
        setUrl(
          `${apiUrl}/venues/${venue.venueId}/adminCSV?from=${fromDate}` +
            `&to=${toDate}&type=${type}&includeVouchers=${includeVouchers}`,
        );
        break;
      case 'payoutsBreakdown':
        setUrl(
          `${apiUrl}/venues/${venue.venueId}/adminCSV?from=${payoutFromDate}T00:00:00.000Z` +
            `&to=${payoutToDate}T00:00:00.000Z&type=breakdown&includeVouchers=false&includeDelivery=false`,
        );
        break;
      default:
        // eslint-disable-next-line max-len
        setUrl(`${apiUrl}/venues/${venue.venueId}${path}`);
    }
  }, [
    apiUrl,
    fromDate,
    includeVouchers,
    path,
    tillId,
    toDate,
    type,
    venue.venueId,
    reportingType,
    offset,
    payoutFromDate,
    payoutToDate,
  ]);

  const { name } = venue;

  const buttonTitle = title || `Download ${startCase(type)} Report`;

  const download = async () => {
    setLoading(true);
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: idToken,
        Accept: 'text/csv',
      },
    })
      .then(async (res) => {
        setLoading(false);
        if (res.status === 404) {
          showErrorNotification('No orders found for this date range');
          return null;
        }
        const contentType = res.headers.get('content-type');
        const isCSV = contentType.indexOf('text/csv') !== -1;
        const isBase64 = contentType.indexOf('text/base64') !== -1;

        if (contentType && isCSV) {
          const contents = await res.text();
          showSuccessNotification('Report downloaded');
          const fromFormatted = moment(fromDate).format('yyyy-MM-DD');
          const toFormatted = moment(toDate).format('yyyy-MM-DD');
          return fileDownload(
            contents,
            `${encodeURIComponent(name)}_${fromFormatted}-to-${toFormatted}_${type}-report.csv`,
          );
        }
        if (contentType && isBase64) {
          const contents = await res.text();
          const decoded = Buffer.from(contents, 'base64');

          const fileBlob = new Blob([decoded]);
          const fromFormatted = moment(fromDate).format('yyyy-MM-DD');
          const toFormatted = moment(toDate).format('yyyy-MM-DD');
          showSuccessNotification('Report downloaded');
          return fileDownload(
            fileBlob,
            `${encodeURIComponent(
              name.replace(' ', '-'),
            )}_${fromFormatted}-to-${toFormatted}_${type}-report.xlsx`,
          );
        }
        return res.json().then((data) => {
          if (data.errorCode) {
            showErrorNotification(data.message);
          }
        });
      })
      .catch((err) => {
        setLoading(false);
        showErrorNotification(err.message);
      });
    return response;
  };

  return isButton ? (
    <Button
      variant={variant}
      disabled={disabled}
      color="primary"
      startIcon={
        loading ? <CircularProgress size="1.2rem" style={{ color: '#E1E9F2' }} /> : <GetAppIcon />
      }
      onClick={download}
      fullWidth={fullWidth}
    >
      {buttonTitle}
    </Button>
  ) : (
    <TableLink onClick={download} disabled={disabled}>
      {buttonTitle}
    </TableLink>
  );
};

export default DownloadReport;
