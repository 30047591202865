import { deleteBare, get, getBare, patchBare, post } from './httpRequest';

export const fetchQrcodes = async () => {
  const response = await get(`/qrcodes`);
  return response.data;
};

export const fetchAvailableQrcodes = async () => {
  const venueId = localStorage.getItem('venue_id');
  const response = await getBare(`qrcodes?venueId=${venueId}`);
  return response.data;
};

export const updateQrcode = async (values) => {
  const venueId = localStorage.getItem('venue_id');
  const { qrCodeId } = values;
  await patchBare(`qrcodes/${qrCodeId}`, { data: { venueId } });
};

export const deleteQrcode = async (values) => {
  const { qrCodeId } = values;
  await deleteBare(`qrcodes/${qrCodeId}`);
};

export const uploadBatchQrCodes = async (spreadsheet) => {
  await post(`/qrcodes/import`, { data: { spreadsheet } });
};
