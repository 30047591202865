import React, { forwardRef } from 'react';
import { Card, CardActions, Button } from '@mui/material';
import ErrorButton from '../../components/Overrides/ErrorButton';
import CustomDialog from '../../components/CustomDialog';

const DeleteDiscountDialog = forwardRef(
  ({ handleCloseDialog, dialogIsOpen = false, submitDialog, discountName = '' }, ref) => {
    const handleSubmit = (event) => {
      event.stopPropagation();
      handleCloseDialog();
      submitDialog(discountName);
    };
    return (
      <CustomDialog
        title={`Delete discount: '${discountName}'?`}
        isDialogOpen={dialogIsOpen}
        handleCloseDialog={handleCloseDialog}
        ref={ref}
      >
        <Card>
          <CardActions>
            <Button onClick={handleCloseDialog}>Cancel</Button>
            <ErrorButton onClick={handleSubmit} variant="outlined">
              Delete
            </ErrorButton>
          </CardActions>
        </Card>
      </CustomDialog>
    );
  },
);

export default DeleteDiscountDialog;
