import React from 'react';
import PropTypes from 'prop-types';

import { Card, CardActions, CardContent, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CustomDialog from '../CustomDialog';

const useStyles = makeStyles(() => ({
  heading: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

const PaymentSystemDialog = ({ handleCloseDialog, handleDialogOpen, submitDialog, adminData }) => {
  const classes = useStyles();

  return (
    <CustomDialog
      title="Are you sure?"
      handleCloseDialog={handleCloseDialog}
      isDialogOpen={handleDialogOpen}
    >
      <Card className={classes.card}>
        <CardContent>
          This will tell the backend to stop using{' '}
          {adminData?.defaultPayment === 'STRIPE' ? 'Stripe' : 'Checkout.com'} universally and
          revert to {adminData?.defaultPayment === 'STRIPE' ? 'Checkout.com' : 'Stripe'}!
        </CardContent>
        <CardActions className={classes.cardActions}>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={submitDialog} variant="contained" color="primary">
            Change to {adminData?.defaultPayment === 'STRIPE' ? 'Checkout.com' : 'Stripe'}
          </Button>
        </CardActions>
      </Card>
    </CustomDialog>
  );
};

PaymentSystemDialog.propTypes = {
  handleCloseDialog: PropTypes.func.isRequired,
  handleDialogOpen: PropTypes.bool.isRequired,
  submitDialog: PropTypes.func.isRequired,
};

export default PaymentSystemDialog;
