import { createSlice } from '@reduxjs/toolkit';
import {
  asyncInitialState as initialState,
  createAsyncAction,
  createAsyncReducerHandlers,
} from '../utils/async';
import fetchReportTipsByStaffApi from '../../api/reports/tipsByStaff';

export const fetchReportTipsByStaff = createAsyncAction(
  'fetch-tips-by-staff',
  fetchReportTipsByStaffApi,
);
const reportTipsByStaffSlice = createSlice({
  name: 'tips-by-staff',
  initialState,
  reducers: {
    clearReportTipsByStaff(state) {
      delete state.data;
    },
  },
  extraReducers: createAsyncReducerHandlers(fetchReportTipsByStaff),
});

export const { clearReportTipsByStaff } = reportTipsByStaffSlice.actions;
export const { reducer: reportTipsByStaff } = reportTipsByStaffSlice;
