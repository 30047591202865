import React, { useEffect, useState } from 'react';

import { makeStyles } from '@mui/styles';
import { Box, Button, Card, FormControlLabel, IconButton, Switch, Typography } from '@mui/material';
import { MdDelete } from 'react-icons/md';

import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import withVenue from '../../hoc/withVenue';
import { getSalesAreasState, getSalesAreaState } from '../../store/salesAreas/selectors';
import {
  clearSalesArea,
  clearSalesAreas,
  fetchSalesArea,
  fetchSalesAreas,
  updateSalesArea,
} from '../../store/salesAreas';
import { useNotifications } from '../../shared/contexts/Notifications/useNotifications';
import { getErrorMessage } from '../../shared/utils/errors';
import AreaMenu from './AreaMenu';
import { getTablesState } from '../../store/tables/selectors';
import { clearTables, fetchTables } from '../../store/tables';
import AssignTablesDialog from './AssignTablesDialog';
import sortAlphaNum from '../../shared/utils/sortAlphaNum';

import Page from '../../components/Page';
import BackArrow from '../../components/BackArrow';
import shouldLoad from '../../shared/utils/shouldLoad';

const useStyles = makeStyles((theme) => ({
  page: {
    maxWidth: '1052px',
    margin: '0 auto',
  },
  heading: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: theme.spacing(1),
    padding: 0,
  },
  arrowButton: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  buttons: {
    gap: theme.spacing(1),
  },
  title: {
    fontFamily: 'Sen',
    flexGrow: 1,
  },
  description: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  emptyContent: {
    gap: theme.spacing(2),
    '& *': {
      alignSelf: 'center',
    },
  },
  areas: {
    gap: theme.spacing(2),
  },
  smallButton: {
    padding: '5px',
    minWidth: '39px',
  },
  fakeButton: {
    padding: '5px',
    minWidth: '39px',
    maxHeight: '39px',
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: '4px',
  },
  tables: {
    padding: theme.spacing(2),
    paddingTop: theme.spacing(3),
    marginTop: theme.spacing(2),
  },
  table: {
    marginBottom: theme.spacing(1),
  },
}));

const SalesArea = ({ redirect }) => {
  const { salesAreaId } = useParams();

  const classes = useStyles();
  const dispatch = useDispatch();
  const { showErrorNotification, showSuccessNotification } = useNotifications();
  const [assignDialogIsOpen, setAssignDialogIsOpen] = useState(false);
  const handleOpenAssignDialog = () => setAssignDialogIsOpen(true);
  const handleCloseAssignDialog = () => setAssignDialogIsOpen(false);
  const salesAreasState = useSelector(getSalesAreasState);
  const salesAreaState = useSelector(getSalesAreaState);
  const { data: salesAreas } = salesAreasState;
  const { loading, data: salesArea, error } = salesAreaState;
  const { enabled, salesAreaName, tables } = salesArea || {};
  const tablesState = useSelector(getTablesState);
  const { data: allTables } = tablesState;
  const sortedTables = tables ? [...tables].sort(sortAlphaNum) : [];

  const handleEnableSwitch = ({ target }) => {
    const { checked } = target;
    const patch = async () => {
      try {
        await dispatch(
          updateSalesArea({
            salesAreaId,
            values: { enabled: checked },
          }),
        );
        dispatch(fetchSalesArea(salesAreaId));
        showSuccessNotification('Sales area has been updated successfully');
      } catch (localError) {
        showErrorNotification(`Error: ${getErrorMessage(localError)}`);
      }
    };
    patch();
  };

  const handleAssignTables = (assignedTables, useRemove) => {
    const updaingTables = useRemove ? assignedTables : [...tables, ...assignedTables];
    const patch = async () => {
      try {
        await dispatch(
          updateSalesArea({
            salesAreaId,
            values: { tables: updaingTables },
          }),
        );
        dispatch(fetchSalesAreas());
        dispatch(fetchSalesArea(salesAreaId));
        showSuccessNotification('Sales area has been updated successfully');
      } catch (localError) {
        showErrorNotification(`Error: ${getErrorMessage(localError)}`);
      }
    };
    patch();
  };

  const handleDeleteTable = (tableName) => {
    const updateTables = tables.slice();
    updateTables.splice(updateTables.indexOf(tableName), 1);
    handleAssignTables(updateTables, true);
  };

  const handleDeleteAll = () => {
    const updateTables = tables.slice();
    tables.forEach((table) => {
      updateTables.splice(updateTables.indexOf(table), 1);
    });
    handleAssignTables(updateTables, true);
  };

  useEffect(() => {
    if (shouldLoad(salesAreasState)) dispatch(fetchSalesAreas());
    if (shouldLoad(salesAreaState)) dispatch(fetchSalesArea(salesAreaId));

    if (shouldLoad(tablesState)) dispatch(fetchTables());
  }, [dispatch, salesAreaId, salesAreaState, salesAreasState, tablesState]);

  return (
    <>
      <Page loading={loading} error={error}>
        <div className={classes.page}>
          <header className={classes.heading}>
            <div className={classes.arrowButton}>
              <BackArrow redirect={redirect} text="Areas" />
              <Typography className={classes.title} variant="h1" component="h1">
                {salesAreaName}
              </Typography>
            </div>
            <AreaMenu salesAreaId={salesAreaId} />
          </header>
          <Box display="flex" justifyContent="space-between">
            <Box display="flex" justifyContent="flex-start" className={classes.buttons}>
              <Button variant="contained" color="primary" onClick={handleOpenAssignDialog}>
                Assign tables
              </Button>
              {tables && tables.length > 0 && (
                <Button variant="outlined" color="primary" onClick={handleDeleteAll}>
                  Remove all tables
                </Button>
              )}
            </Box>
            <FormControlLabel
              labelPlacement="start"
              control={
                <Switch
                  color="primary"
                  type="checkbox"
                  size="small"
                  checked={enabled}
                  onChange={handleEnableSwitch}
                />
              }
              label={enabled ? 'Open' : 'Closed'}
            />
          </Box>
          {allTables && salesAreas && (
            <AssignTablesDialog
              areaName={salesAreaName}
              tables={allTables}
              salesAreas={salesAreas}
              handleCloseDialog={handleCloseAssignDialog}
              dialogIsOpen={assignDialogIsOpen}
              submitDialog={handleAssignTables}
            />
          )}
          {tables && tables.length > 0 && (
            <Card className={classes.tables}>
              {sortedTables.map((tableName) => (
                <Box
                  display="flex"
                  justifyContent="space-between"
                  key={tableName}
                  className={classes.table}
                >
                  {tableName}
                  <IconButton edge="end" size="small" onClick={() => handleDeleteTable(tableName)}>
                    <MdDelete />
                  </IconButton>
                </Box>
              ))}
            </Card>
          )}
        </div>
      </Page>
    </>
  );
};

export default withVenue(SalesArea, '/sales-areas', [clearSalesArea, clearSalesAreas, clearTables]);
