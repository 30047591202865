import React, { forwardRef, useEffect, useState } from 'react';
import {
  Card,
  CardActions,
  Button,
  CardContent,
  List,
  ListItem,
  ListItemIcon,
  FormControlLabel,
  Checkbox,
  Typography,
  Box,
  ListItemButton,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Form, Formik } from 'formik';
import CustomDialog from '../../components/CustomDialog';

const useStyles = makeStyles((theme) => ({
  heading: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  title: {
    flexGrow: 1,
  },
  closeButton: {
    position: 'absolute',
    right: '10px',
    top: '10px',
  },
  list: {
    // width: 200,
    height: 230,
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
  },
  checkbox: {
    padding: '2px',
  },
  disabled: {
    marginTop: '5px',
  },
}));

const toggleArrayItem = (a, v) => {
  const i = a.indexOf(v);
  if (i === -1) a.push(v);
  else a.splice(i, 1);
};

const AssignTablesDialog = forwardRef(
  (
    { handleCloseDialog, dialogIsOpen, submitDialog, tables = [], salesAreas = [], areaName = '' },
    ref,
  ) => {
    const classes = useStyles();

    const [selectedTables, setSelectedTables] = useState([]);
    const [internalTables, setInternalTables] = useState(null);

    useEffect(() => {
      const buildInternalTables = { unassigned: [] };
      const buildSelectedTables = [];
      const flatTables = [];
      salesAreas.forEach((area) => {
        if (area.tables.length > 0) {
          buildInternalTables[area.salesAreaName] = area.tables.map((table) =>
            tables.find((t) => t.tableName === table),
          );

          area.tables.forEach((table) => {
            flatTables.push(table);
          });
        }
      });
      tables.forEach((table) => {
        const isAssigned = flatTables.includes(table.tableName);
        if (!isAssigned && table.tableName !== 'Takeaway') {
          buildInternalTables.unassigned.push(table);
        }
      });

      setInternalTables(buildInternalTables);
      setSelectedTables(buildSelectedTables);
    }, [areaName, salesAreas, tables]);

    const handleSubmit = (event) => {
      event.stopPropagation();
      handleCloseDialog();
      submitDialog(selectedTables);
    };
    const handleToggle = (tableName) => {
      const newSelectedTables = [...selectedTables];
      toggleArrayItem(newSelectedTables, tableName);
      setSelectedTables(newSelectedTables);
    };

    const toggleArea = (area) => {
      const newSelectedTables = [...selectedTables];
      internalTables[area].forEach((table) => {
        toggleArrayItem(newSelectedTables, table.tableName);
      });
      setSelectedTables(newSelectedTables);
    };

    return (
      <CustomDialog
        title={`Assign tables to '${areaName}'?`}
        isDialogOpen={dialogIsOpen}
        handleCloseDialog={handleCloseDialog}
        ref={ref}
      >
        <Card className={classes.card}>
          <CardContent>
            <Formik>
              <Form>
                <List className={classes.list} dense component="div" role="list">
                  {internalTables &&
                    Object.entries(internalTables).map(
                      ([areaAreaName, areaTables]) =>
                        areaAreaName !== areaName &&
                        areaTables.length > 0 && (
                          <div key={areaAreaName}>
                            <Box display="flex" alignItems="center" style={{ gap: '2rem' }}>
                              <Typography variant="h4" color="primary" className={classes.heading}>
                                {areaAreaName}
                              </Typography>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    className={classes.checkbox}
                                    onChange={() => toggleArea(areaAreaName)}
                                  />
                                }
                                label={
                                  <Typography variant="body2" color="primary">
                                    Assign all
                                  </Typography>
                                }
                              />
                            </Box>
                            {areaTables?.map(({ tableName, enabled }) => (
                              <ListItemButton
                                key={tableName}
                                role="listitem"
                                onClick={() => handleToggle(tableName)}
                              >
                                <Box display="flex" justifyContent="space-between" width="100%">
                                  <ListItemIcon>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          className={classes.checkbox}
                                          checked={selectedTables.includes(tableName)}
                                        />
                                      }
                                      label={
                                        <Typography variant="body2" color="primary">
                                          {tableName}
                                        </Typography>
                                      }
                                    />
                                  </ListItemIcon>
                                  {!enabled && (
                                    <Typography variant="body2" className={classes.disabled}>
                                      disabled
                                    </Typography>
                                  )}
                                </Box>
                              </ListItemButton>
                            ))}
                          </div>
                        ),
                    )}

                  <ListItem />
                </List>
              </Form>
            </Formik>
          </CardContent>
          <CardActions className={classes.cardActions}>
            <Button variant="outlined" onClick={handleCloseDialog}>
              Cancel
            </Button>
            <Button variant="contained" onClick={handleSubmit} color="primary">
              Assign
            </Button>
          </CardActions>
        </Card>
      </CustomDialog>
    );
  },
);

export default AssignTablesDialog;
