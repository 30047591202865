import { createSlice } from '@reduxjs/toolkit';
import {
  asyncInitialState as initialState,
  createAsyncAction,
  createAsyncReducerHandlers,
} from '../utils/async';
import fetchReportSalesOverviewApi from '../../api/reports/salesOverview';

export const fetchReportSalesOverview = createAsyncAction(
  'fetch-sales-overview',
  fetchReportSalesOverviewApi,
);

const reportSalesOverviewSlice = createSlice({
  name: 'sales-overview',
  initialState,
  reducers: {
    clearReportSalesOverview(state) {
      delete state.data;
    },
  },
  extraReducers: createAsyncReducerHandlers(fetchReportSalesOverview),
});

export const { clearReportSalesOverview } = reportSalesOverviewSlice.actions;

export const { reducer: reportSalesOverview } = reportSalesOverviewSlice;
