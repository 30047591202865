import { createSelector } from '@reduxjs/toolkit';
import { ascend, path, prop, sortWith } from 'ramda';
import { getIngredientsState } from '../ingredients/selectors';

const menuItemSort = sortWith([
  ascend(prop('type')),
  ascend(prop('category')),
  ascend(prop('itemName')),
]);

export const getMenuItemsState = (state) => state.menuItems;
export const getMenuItemState = (state) => state.menuItem;

const getMenuItemId = (_, menuItemId) => menuItemId;

export const getMenuItems = createSelector(
  getMenuItemsState,
  (menuItemsState) => menuItemsState.data && menuItemSort(menuItemsState.data),
);

export const getMenuItem = createSelector(
  getMenuItems,
  getMenuItemId,
  (menuItems, menuItemId) =>
    menuItems && menuItems.find((menuItem) => menuItem.itemId === menuItemId),
);

const getItemType = createSelector(getMenuItemState, (menuItem) => menuItem && menuItem.data.type);

const getAttribute = (_, attribute) => attribute;

export const getAttributeValues = createSelector(
  getMenuItemState,
  getAttribute,
  (menuItem, attribute) => path(['data', attribute], menuItem) || [],
);

export const getIngredientsForItemType = createSelector(
  getItemType,
  getIngredientsState,
  (itemType, ingredients) => {
    if (!itemType || !ingredients.data) return null;
    return ingredients.data.reduce((acc, ingredient) => {
      const { ingredientId, type: ingredientType } = ingredient;
      if (ingredientType === itemType) {
        acc.push(ingredientId);
      }
      return acc;
    }, []);
  },
);
