import React from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import WeekTimeRangeSelector from '../../../components/WeekTimeRangeSelector/WeekTimeRangeSelector';
import withVenue from '../../../hoc/withVenue';
import Page from '../../../components/Page';
import BackArrow from '../../../components/BackArrow';
import { fetchVenue, updateVenue } from '../../../store/venues';
import { getErrorMessage } from '../../../shared/utils/errors';
import { useNotifications } from '../../../shared/contexts/Notifications/useNotifications';

const OpeningSchema = Yup.object().shape({
  openings: Yup.array().of(Yup.string()).required('Please enter some opening hours'),
});

const OpeningHours = ({ venue, redirect }) => {
  const { openings: openingTimes, timezone } = venue;
  const dispatch = useDispatch();
  const { showErrorNotification } = useNotifications();

  const handleOnSubmit = async (openings) => {
    try {
      await dispatch(updateVenue(openings));
    } catch (error) {
      showErrorNotification(getErrorMessage(error));
      dispatch(fetchVenue());
    }
  };

  return (
    <Page>
      <header>
        <BackArrow redirect={redirect} text="Settings" />
      </header>
      <Formik
        initialValues={openingTimes}
        validationSchema={OpeningSchema}
        onSubmit={handleOnSubmit}
      >
        {({ values, errors }) => (
          <Form>
            <WeekTimeRangeSelector
              onSubmit={(value) => {
                handleOnSubmit({ openings: value });
              }}
              label="Opening hours"
              name="openings"
              subjectText="opening times"
              validTimes={openingTimes}
              timezone={timezone}
              errors={errors}
              value={values.openings}
            />
          </Form>
        )}
      </Formik>
    </Page>
  );
};

export default withVenue(OpeningHours, '/settings/venue');
