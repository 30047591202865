import { get } from './httpRequest';
import { dateRanges, rangeEnums } from '../shared/utils/dateRanges';
import { sortOrderEnums } from '../shared/utils/sortOrder';
import toUTC from '../shared/utils/toUTC';

export const fetchReviews = async ({
  first = 0,
  size = 50,
  sortOrder = sortOrderEnums.DESC,
  searchTerm = '',
  dateRange = rangeEnums.TODAY,
  from,
  to,
  rating,
  reportingType,
  isDelivery,
}) => {
  const customRange = from &&
    to && {
      label: rangeEnums.CUSTOM,
      from: () => from,
      to: () => to,
    };

  const range = dateRange === rangeEnums.CUSTOM ? customRange : dateRanges[dateRange];
  const params = range
    ? `from=${toUTC(range.from(), reportingType)}&to=${toUTC(
        range.to(),
        reportingType,
      )}&order=${sortOrder}
    &first=${first}&size=${size}&terms=${searchTerm}&rating=${rating}&delivery=${isDelivery}`
    : '';

  const response = await get(`/ratings?${params}`);
  return response.data;
};

export const a = () => {};
