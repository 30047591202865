import { createSelector } from '@reduxjs/toolkit';
import { ascend, prop, sortWith } from 'ramda';

const kioskSort = sortWith([ascend(prop('kioskId'))]);

export const getKiosksState = (state) => state.kiosks;
export const getKiosks = createSelector(
  getKiosksState,
  (kiosksState) => kiosksState.data && kioskSort(kiosksState.data),
);

export const getKioskOnboardingKeyState = (state) => state.kioskOnboardingKey;
