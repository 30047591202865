import moment from 'moment-timezone';

import { REPORTING_TYPES } from '../constants/units';

const toUTC = (date, reportingType) => {
  switch (reportingType) {
    case REPORTING_TYPES.OPERATIONAL:
      return moment(date).add(5, 'hours').utc().toISOString();
    case REPORTING_TYPES.TRANSACTIONAL:
      return moment(date).utc().toISOString();
    case REPORTING_TYPES.UTC:
      return moment(date).add(moment().utcOffset(), 'minutes').utc().toISOString();
    default:
      return moment(date).utc().toISOString();
  }
};

export default toUTC;
