const getItemsByCategory = (items) =>
  items?.reduce((acc, item) => {
    const { category } = item;
    // fix a bug with formik treating dots as nested objects.
    // In theory formik should be able to handle this, but it doesn't.
    // Perhaps I'm not doing it right, but this works.
    // https://formik.org/docs/guides/arrays#avoid-nesting
    const cat = category.split('.').join('·');
    if (!acc[cat]) {
      // eslint-disable-next-line no-param-reassign
      acc[cat] = [];
    }
    acc[cat].push(item);
    return acc;
  }, {});

export default getItemsByCategory;
