import { Box, Button, Card, CardContent, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import moment from 'moment-timezone';
import { isEmpty } from 'ramda';
import WeekTimeRangeDay from '../../components/WeekTimeRangeSelector/WeekTimeRangeDay';
import { fromOpeningTimesToFormValues } from '../../components/WeekTimeRangeSelector/EditWeekTimeRangeDialog/mappers';
import DiscountLozenge from './DiscountLozenge';

const useStyles = makeStyles(() => ({
  discountCard: {
    margin: '15px 0',
    fontSize: '0.875em',
    '& p': {
      margin: '0.5rem',
    },
  },
  column: {
    flexBasis: '50%',
  },
  button: {
    marginTop: '2rem',
  },
  content: {
    borderTop: '1px solid #F0F4F8',
  },
  range: {
    margin: '-0.5rem 0',
  },
  greyTitle: {
    color: '#647F99',
  },
  greyDarkerTitle: {
    color: '#334E68',
  },
  activeSection: {
    marginTop: '0.75rem',
  },
}));

function DiscountSummary({ discount, onEdit, onEnableToggle }) {
  const { name, label, enabled, status, startsAt, endsAt, schedule, createdAt } = discount || {};
  const localOpenings = schedule ? fromOpeningTimesToFormValues(discount.schedule).openings : null;
  const classes = useStyles();

  const pauseButtonText = enabled ? 'Pause' : 'Unpause';
  const startsAtDate = startsAt ? moment(startsAt) : null;
  const endsAtDate = endsAt ? moment(endsAt) : null;
  const createdAtDate = createdAt ? moment(createdAt) : null;
  const hasValidTimes = schedule && !isEmpty(schedule);

  return (
    <Card className={classes.discountCard}>
      <CardContent>
        <Box display="flex" justifyContent="space-between">
          <div>
            <Typography variant="h2">{label}</Typography>
            <Typography variant="h3" className={classes.greyDarkerTitle}>
              {name}
            </Typography>
            <Button
              variant="outlined"
              color="primary"
              className={classes.button}
              onClick={onEnableToggle}
            >
              {pauseButtonText}
            </Button>
          </div>
          <div>
            <DiscountLozenge status={status} />
          </div>
        </Box>
      </CardContent>
      <CardContent className={classes.content}>
        <Box display="flex">
          {startsAt && (
            <div className={classes.column}>
              <p className={classes.greyTitle}>STARTS</p>
              <p>{startsAtDate.format('HH:mm')}</p>
              <p>{startsAtDate.format('DD/MM/YYYY')}</p>
            </div>
          )}
          {endsAt && (
            <div className={classes.column}>
              <p className={classes.greyTitle}>ENDS</p>
              <p>{endsAtDate.format('HH:mm')}</p>
              <p>{endsAtDate.format('DD/MM/YYYY')}</p>
            </div>
          )}
        </Box>
        <div className={classes.activeSection}>
          <p className={classes.greyTitle}>ACTIVE</p>
          {hasValidTimes && (
            <Grid container>
              {localOpenings.map(
                (validTime) =>
                  validTime.isOpen && (
                    <WeekTimeRangeDay
                      validTime={validTime}
                      key={validTime.day}
                      className={classes.range}
                    />
                  ),
              )}
            </Grid>
          )}
        </div>
        <Button variant="outlined" fullWidth className={classes.button} onClick={onEdit}>
          Edit
        </Button>
        {createdAtDate && (
          <p className={classes.greyTitle}>Created {createdAtDate.format('DD/MM/YYYY')}</p>
        )}
      </CardContent>
    </Card>
  );
}

export default DiscountSummary;
