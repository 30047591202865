import React, { useState } from 'react';
import { Button, CircularProgress } from '@mui/material';
import fileDownload from 'js-file-download';
import { useDispatch, useSelector } from 'react-redux';
import GridOnIcon from '@mui/icons-material/GridOn';
import { startCase } from 'lodash';
import { Buffer } from 'buffer';
import { getIdToken } from '../../cognito/sessionTokens';
import { useNotifications } from '../../shared/contexts/Notifications/useNotifications';
import { getVenueState } from '../../store/venues/selectors';
import { getErrorMessage } from '../../shared/utils/errors';
import { importVenueMenu, fetchVenueMenu } from '../../store/venueMenus';
import { uploadSpreadsheet } from '../../api/venueMenu';
import { fetchMenuItems } from '../../store/menuItems';

const MenuExportImport = ({ type, fullWidth = true, menuName, uploadUrl, disabled = false }) => {
  const dispatch = useDispatch();
  const { showErrorNotification, showSuccessNotification } = useNotifications();
  const { data: venue } = useSelector(getVenueState);
  const [loading, setLoading] = useState(false);
  const idToken = getIdToken();
  const isImport = type === 'import';
  const isExport = !isImport;

  const apiUrl = process.env.REACT_APP_API_URI;
  const exportUrl = `${apiUrl}/venues/${venue.venueId}/menus/${menuName}/export`;

  const { name } = venue;

  const buttonTitle = `${startCase(type)} menu`;

  const handleCapture = async ({ target }) => {
    const file = target.files[0];
    if (!uploadUrl) return;
    setLoading(true);
    try {
      await uploadSpreadsheet(uploadUrl, file);
      await dispatch(importVenueMenu());
      dispatch(fetchMenuItems());
      dispatch(fetchVenueMenu(menuName));
      showSuccessNotification(`${file?.name} has been uploaded successfully`);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      showErrorNotification(getErrorMessage(error));
    }
  };

  const download = async () => {
    setLoading(true);
    const response = await fetch(exportUrl, {
      method: 'GET',
      headers: {
        Authorization: idToken,
        Accept: 'text/base64',
      },
    })
      .then(async (res) => {
        setLoading(false);
        if (res.status === 404) {
          showErrorNotification('No orders found for this date range');
          return null;
        }
        const contentType = res.headers.get('content-type');
        const isJson = contentType.indexOf('application/json') !== -1;
        const isBase64 = contentType.indexOf('text/base64') !== -1;
        if (contentType && isJson) {
          return res.json().then((data) => {
            if (data.errorCode) {
              showErrorNotification(data.message);
            }
          });
        }
        if (contentType && isBase64) {
          const contents = await res.text();
          const decoded = Buffer.from(contents, 'base64');
          const fileBlob = new Blob([decoded]);
          showSuccessNotification('Menu downloaded');
          return fileDownload(
            fileBlob,
            `${encodeURIComponent(name.replace(' ', '-'))}_${menuName}.xlsx`,
          );
        }
        showErrorNotification("Couldn't download menu");
        return null;
      })
      .catch((err) => {
        setLoading(false);
        showErrorNotification(err.message);
      });
    return response;
  };

  return (
    <Button
      variant="contained"
      color="primary"
      disabled={disabled}
      component="label"
      startIcon={
        loading ? <CircularProgress size="1.2rem" style={{ color: '#E1E9F2' }} /> : <GridOnIcon />
      }
      onClick={isExport ? download : null}
      fullWidth={fullWidth}
    >
      {buttonTitle}
      {isImport && (
        <input
          type="file"
          hidden
          onChange={handleCapture}
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        />
      )}
    </Button>
  );
};

export default MenuExportImport;
