import { makeStyles } from '@mui/styles';
import React, { useContext } from 'react';

import SignupContext from '../SignupContext';

const useStyles = makeStyles((theme) => ({
  button: {
    cursor: 'pointer',
    padding: 0,
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    borderColor: 'transparent',
    backgroundColor: 'transparent',
    color: theme.palette.secondary.main,
  },
  img: {
    width: '25',
    height: 'auto',
  },
}));

const StepBackButton = () => {
  const { handlePreviousStep } = useContext(SignupContext);
  const classes = useStyles();

  return (
    <button type="button" className={classes.button} onClick={handlePreviousStep}>
      <img alt="Back" className={classes.img} src="/img/signup/back-btn.svg" />
    </button>
  );
};

export default StepBackButton;
