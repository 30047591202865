import React from 'react';
import { Grid, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  color: {
    height: `${theme.spacing(5)}`,
    width: `${theme.spacing(5)}`,
    minHeight: `${theme.spacing(5)}`,
    minWidth: `${theme.spacing(5)}`,
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: '#fff',
    boxShadow: `0 0 0 0 transparent`,
    borderRadius: '100%',
    transition: `box-shadow 0.3s`,
  },
}));

const ColorPaletteColor = ({ color, handleSetColor, isSelected = false }) => {
  const classes = useStyles();

  return (
    <Grid item>
      <Button
        className={classes.color}
        style={{
          backgroundColor: color,
          boxShadow: isSelected ? `0 0 0 2px ${color}` : 'inherit',
          borderColor: '#fff',
        }}
        onClick={() => handleSetColor(color)}
      />
    </Grid>
  );
};

export default ColorPaletteColor;
