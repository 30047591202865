import { Formik } from 'formik';
import React, { useContext } from 'react';
import isEmpty from 'lodash.isempty';
import { MenuItem } from '@mui/material';

import SignupContext from '../SignupContext';
import StepQuestion from '../StepQuestion';
import StepButton from '../StepButton';
import { signupCountries, signupFields } from '../signupFields';
import StepInput from '../StepInput';
import validationSchema from './validationSchema';
import StepDropdown from '../StepDropdown';
import StepAccordion from '../StepAccordion';

const StepVenueDetailsTwo = () => {
  const { formValues } = useContext(SignupContext);
  const initialValues = {
    [signupFields.businessName]: formValues.businessName,
    [signupFields.address]: formValues.address,
    [signupFields.city]: formValues.city,
    [signupFields.country]: formValues.country,
    [signupFields.postcode]: formValues.postcode,
  };

  return (
    <>
      <StepQuestion
        question="What is the venue address?"
        description="Use your home address if your venue doesn’t have a specific address.
          This will be shown on your receipts (and can be changed later in your settings)."
      />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnMount
        enableReinitialize
      >
        {({ errors, isValid }) => {
          const isFormValid = isValid && isEmpty(errors);

          return (
            <>
              <StepInput name={signupFields.businessName} placeholder="Business name" />
              <StepInput name={signupFields.address} placeholder="Building name or number" />
              <StepInput name={signupFields.city} placeholder="City" />
              <StepDropdown label="Country" name={signupFields.country}>
                {signupCountries.map((country) => (
                  <MenuItem value={country} key={country}>
                    {country}
                  </MenuItem>
                ))}
              </StepDropdown>
              <StepInput name={signupFields.postcode} placeholder="Postcode" />

              <StepButton disabled={!isFormValid} />
            </>
          );
        }}
      </Formik>
      <StepAccordion
        title="Why do I have to have an address on my receipts?"
        description="If you sell a product or service the receipt you provide to your customer should
        contain the following: your company's details including name, address, phone number and/or email address,
        the date of transaction showing date, month and year."
      />
    </>
  );
};

export default StepVenueDetailsTwo;
