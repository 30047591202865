import { createSlice } from '@reduxjs/toolkit';
import {
  asyncInitialState as initialState,
  createAsyncAction,
  createAsyncReducerHandlers,
} from '../utils/async';
import {
  fetchTills as fetchTillsApi,
  fetchTill as fetchTillApi,
  createTill as createTillApi,
  updateTill as updateTillApi,
  deleteTill as deleteTillApi,
} from '../../api/tills';

export const fetchTills = createAsyncAction('fetch-tills', fetchTillsApi);

export const deleteTill = createAsyncAction('delete-till', deleteTillApi);

export const fetchTill = createAsyncAction('fetch-till', fetchTillApi);

export const createTill = createAsyncAction('create-till', createTillApi);

export const updateTill = createAsyncAction('update-till', updateTillApi);

const tillsSlice = createSlice({
  name: 'tills',
  initialState,
  reducers: {
    clearTills(state) {
      delete state.data;
    },
  },
  extraReducers: createAsyncReducerHandlers(fetchTills),
});

const tillSlice = createSlice({
  name: 'till',
  initialState,
  reducers: {
    clearTill(state) {
      delete state.data;
    },
  },
  extraReducers: {
    ...createAsyncReducerHandlers(fetchTill),
    ...createAsyncReducerHandlers(createTill),
    ...createAsyncReducerHandlers(updateTill),
  },
});
export const { clearTills } = tillsSlice.actions;
export const { clearTill } = tillSlice.actions;

export const { reducer: tills } = tillsSlice;
export const { reducer: till } = tillSlice;
