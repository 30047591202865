import React from 'react';
import {
  CardContent,
  MenuItem,
  Typography,
  Box,
  Slider,
  Switch,
  DialogActions,
  Button,
  CardActions,
  FormControlLabel,
} from '@mui/material';
import { Formik, Form, Field } from 'formik';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { TextField } from 'formik-mui';
import moment from 'moment-timezone';
import * as Yup from 'yup';

import WeekTimeRangeSelector from '../WeekTimeRangeSelector/WeekTimeRangeSelector';

const PromoCodeForm = ({ promoCodeData, venue, formAction, timeFrame, onSubmit, onCancel }) => {
  const { timezone } = venue || {};
  const submitButtonLabel = formAction === 'create' ? 'Create' : 'Update';

  const {
    promoCode,
    label,
    expiresAt,
    validTimes,
    type,
    usageCount,
    amount,
    maxUsageCount,
    limitTimeFrame,
    limitAmount,
    hasLimit,
    isEpos,
  } = promoCodeData || {};

  const initialValues = {
    promoCode: promoCode || '',
    label: label || '',
    expiresAt: expiresAt || moment().add(1, 'days').toISOString(),
    validTimes: validTimes || [],
    type: type || 'GIFT',
    amount: amount || 0,
    usageCount: usageCount || '',
    limitTimeFrame: limitTimeFrame || '',
    limitAmount: limitAmount || '',
    hasLimit: hasLimit || false,
    maxUsageCount: maxUsageCount || 1,
    isEpos: isEpos || false,
  };

  const promoCodeTypes = {
    GIFT: 'Free item (ie. 1 free Beer)',
    AMOUNT_DISCOUNT: 'Price discount (ie. £5 off)',
    PERCENTAGE_DISCOUNT: 'Percentage discount (ie. 50% off)',
  };

  const EditPromoCodeSchema = Yup.object().shape({
    promoCode: Yup.string()
      .max(30)
      .required('Promo code is required')
      .matches(/^[^%]+$/, 'Must not include %'),
    label: Yup.string().required('Promotion title is required'),
    expiresAt: Yup.date().required('Expiry date is required'),
    validTimes: Yup.array().of(Yup.string()),
    hasLimit: Yup.boolean().required(),
    isEpos: Yup.boolean().required(),
    limitTimeFrame: Yup.string().when('hasLimit', {
      is: true,
      then: Yup.string().required(),
      otherwise: Yup.string().optional(),
    }),
    /* By setting field to initialValues, the empty string can pass validation here as Yup does not invalidate
    initialValues even if the number type is used and the initialValue is a string */
    limitAmount: Yup.number().when('hasLimit', {
      is: true,
      then: Yup.number().min(1).required(),
      otherwise: Yup.number().optional(),
    }),
    type: Yup.string().oneOf(Object.keys(promoCodeTypes)).required(`Promo code's type is required`),
    amount: Yup.number().min(0).required('Amount is required'),
    usageCount: Yup.number.optional,
    maxUsageCount: Yup.number('Max usage cannot be 0')
      .min(1)
      .required(`Promo code's max usage count is required`),
  });

  const handleOnExpiryDateChange = (date, setFieldValue) => {
    setFieldValue('expiresAt', moment(date).format());
  };

  const handleOnAmountSliderChange = (_event, newValue, setFieldValue) => {
    setFieldValue('amount', newValue);
  };

  const handleIfLimitInUse = (setFieldValue, values) => {
    if (values.hasLimit === true) {
      setFieldValue('limitAmount', initialValues.limitAmount);
      setFieldValue('limitTimeFrame', initialValues.limitTimeFrame);
      setFieldValue('hasLimit', false);
    } else {
      setFieldValue('hasLimit', true);
    }
  };

  return (
    <>
      <Formik
        enableReinitialize
        onSubmit={onSubmit}
        validationSchema={EditPromoCodeSchema}
        initialValues={initialValues}
        validateOnChange
      >
        {({ setFieldValue, values, isValid, isSubmitting, dirty, resetForm }) => {
          const isAmountDiscount = values.type === 'AMOUNT_DISCOUNT';
          const isPercentageDiscount = values.type === 'PERCENTAGE_DISCOUNT';

          return (
            <Form>
              <CardContent>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <section>
                    <Field
                      component={TextField}
                      name="promoCode"
                      label="Promo code (max 30 characters)"
                      variant="outlined"
                      fullWidth
                      required
                      margin="normal"
                      disabled={!!promoCode}
                    />
                    <Field
                      component={TextField}
                      name="type"
                      label="Type"
                      variant="outlined"
                      fullWidth
                      required
                      select
                      margin="normal"
                      disabled={!!type}
                    >
                      {Object.keys(promoCodeTypes).map((typeOfCode) => (
                        <MenuItem key={typeOfCode} value={typeOfCode}>
                          {promoCodeTypes[typeOfCode]}
                        </MenuItem>
                      ))}
                    </Field>
                    <Field
                      component={TextField}
                      name="label"
                      label="Promotion Title"
                      variant="outlined"
                      fullWidth
                      required
                      margin="normal"
                    />
                    <section>
                      <FormControlLabel
                        label="Show as button on EPOS"
                        control={
                          <Field
                            component={Switch}
                            name="isEpos"
                            checked={values.isEpos}
                            type="checkbox"
                            size="medium"
                            color="primary"
                            label="Show as button on EPOS"
                            onChange={() => {
                              setFieldValue('isEpos', !values.isEpos);
                            }}
                          />
                        }
                      />
                    </section>
                    <Field
                      component={DateTimePicker}
                      variant="inline"
                      timezone="system"
                      format="HH:mm - DD/MM/YYYY"
                      margin="normal"
                      id="expiresAt"
                      label="Expiry"
                      value={moment(values.expiresAt)}
                      onChange={(date) => handleOnExpiryDateChange(date, setFieldValue)}
                      KeyboardButtonProps={{
                        'aria-label': 'Change expiry date',
                      }}
                      autoOk
                      disablePast
                      disabled={isSubmitting}
                    />
                    {isAmountDiscount && (
                      <Field
                        component={TextField}
                        name="amount"
                        label="Amount"
                        variant="outlined"
                        type="number"
                        fullWidth
                        InputProps={{ inputProps: { min: 0, step: '0.01' } }}
                        required
                        margin="normal"
                        disabled={!!amount}
                      />
                    )}
                    {isPercentageDiscount && (
                      <>
                        <Box display="flex" flexDirection="column" mt={2} mb={6}>
                          <Typography id="discount-percentage" variant="subtitle1" component="p">
                            Percentage Discount
                          </Typography>
                        </Box>
                        <Box
                          style={{
                            marginLeft: 16,
                            maxWidth: '50%',
                          }}
                        >
                          <Slider
                            aria-labelledby="discount-percentage"
                            aria-label="Percentage Discount"
                            defaultValue={0}
                            getAriaValueText={(value) => `${value}%`}
                            valueLabelDisplay="on"
                            valueLabelFormat={(value) => `${value}%`}
                            step={1}
                            marks
                            min={0}
                            max={100}
                            label="Amount"
                            name="amount"
                            value={values.amount}
                            onChange={(_event, newValue) =>
                              handleOnAmountSliderChange(_event, newValue, setFieldValue)
                            }
                            disabled={!!amount}
                          />
                        </Box>
                      </>
                    )}
                    <Field
                      component={TextField}
                      name="maxUsageCount"
                      label="Max Usage"
                      variant="outlined"
                      type="number"
                      InputProps={{ inputProps: { min: 1 } }}
                      fullWidth
                      required
                      margin="normal"
                    />

                    {isPercentageDiscount && (
                      <>
                        <Typography variant="subtitle2">Max Value (optional)</Typography>
                        <Switch
                          name="hasLimit"
                          checked={values.hasLimit}
                          onClick={() => {
                            handleIfLimitInUse(setFieldValue, values);
                          }}
                        />
                      </>
                    )}
                    {values.hasLimit === true && (
                      <>
                        <Field
                          component={TextField}
                          name="limitAmount"
                          label="Maximum Value (£)"
                          variant="outlined"
                          fullWidth
                          margin="normal"
                        />
                        <Field
                          component={TextField}
                          name="limitTimeFrame"
                          label="Time frame"
                          variant="outlined"
                          fullWidth
                          select
                          margin="normal"
                        >
                          {Object.values(timeFrame).map((time) => (
                            <MenuItem key={time} value={time}>
                              {time}
                            </MenuItem>
                          ))}
                        </Field>
                      </>
                    )}

                    <Box display="flex" flexDirection="column" mt={4} mb={1}>
                      <Typography id="validTimes" variant="subtitle2" component="label">
                        Schedule (optional)
                      </Typography>
                      <Typography variant="subtitle1" component="p">
                        Set here what day and time you want your promo code to be active.
                      </Typography>
                    </Box>
                    <Field
                      name="validTimes"
                      ariaLabelledBy="validTimes"
                      label="Valid Times"
                      validTimes={initialValues.validTimes}
                      onSubmit={(value) => {
                        setFieldValue('validTimes', value);
                      }}
                      timezone={timezone}
                      component={WeekTimeRangeSelector}
                      variant="outlined"
                      margin="normal"
                      required
                    />
                  </section>
                </LocalizationProvider>
              </CardContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    onCancel();
                    resetForm();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={!isValid || !dirty || isSubmitting}
                >
                  {submitButtonLabel}
                </Button>
              </DialogActions>
            </Form>
          );
        }}
      </Formik>
      <CardActions>
        <Typography variant="subtitle2">*You’ll cover the cost of the discount</Typography>
      </CardActions>
    </>
  );
};

export default PromoCodeForm;
