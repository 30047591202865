import React from 'react';
import { Box, Card, CardContent, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Link, useHistory } from 'react-router-dom';
import PageHeader from '../../components/PageHeader';
import Page from '../../components/Page';
import ItemUpsellsBanner from './img/ItemUpsellsBanner.svg';
import CheckoutUpsellsBanner from './img/CheckoutUpsellsBanner.svg';

const useStyles = makeStyles((theme) => ({
  title: {
    ...theme.customFonts.largeBold,
  },
  description: {
    ...theme.customFonts.small,
    color: theme.customPalette.greyDark,
    marginBottom: '16px',
  },
  container: {
    display: 'flex',
    gap: '1.5rem',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  card: {
    flex: 1,
    cursor: 'pointer',
    borderRadius: '6px',
    maxWidth: '513px',
    padding: 0,
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '& img': {
      width: '100%',
      height: 'auto',
    },
    padding: 0,
    '&:last-child': {
      padding: 0,
    },
  },
  cardText: {
    margin: '16px',
  },
  cardTitle: {
    ...theme.customFonts.mediumBold,
    color: theme.customPalette.trueBlue,
    marginBottom: '8px',
  },
  cardDescription: {
    ...theme.customFonts.small,
    color: theme.customPalette.greyDarker,
  },
}));

const UpsellsPage = () => {
  const classes = useStyles();
  const history = useHistory();

  const gotoItemUpsells = () => {
    history.push('/upsells/item-upsells');
  };
  const gotoCheckoutUpsells = () => {
    history.push('/upsells/checkout-upsells');
  };

  return (
    <>
      <PageHeader>
        <Typography className={classes.title}>Upsells</Typography>
        <Typography className={classes.description}>
          Boost customer spend with our powerful upselling features
        </Typography>
      </PageHeader>
      <Page>
        <Box className={classes.container}>
          <Card className={classes.card} onClick={gotoItemUpsells}>
            <CardContent className={classes.cardContent}>
              <img src={ItemUpsellsBanner} alt="item upsells banner" />
              <div className={classes.cardText}>
                <Link className={classes.cardTitle} to="/upsells/item-upsells">
                  Item upsells
                </Link>
                <Typography className={classes.cardDescription}>
                  Suggest additional items when customers are browsing the menu.{' '}
                </Typography>
              </div>
            </CardContent>
          </Card>
          <Card className={classes.card} onClick={gotoCheckoutUpsells}>
            <CardContent className={classes.cardContent}>
              <img src={CheckoutUpsellsBanner} alt="checkout upsells banner" />
              <div className={classes.cardText}>
                <Link className={classes.cardTitle} to="/upsells/checkout-upsells">
                  Checkout upsells
                </Link>
                <Typography className={classes.cardDescription}>
                  Promote certain items on the basket page.
                </Typography>
              </div>
            </CardContent>
          </Card>
        </Box>
      </Page>
    </>
  );
};

export default UpsellsPage;
