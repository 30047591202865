import React from 'react';
import PropTypes from 'prop-types';

import { Card, CardActions, CardContent, Button, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CustomDialog from '../CustomDialog';
import useRoles from '../../hooks/useRoles';

const useStyles = makeStyles(() => ({
  heading: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

const ResetPasswordDialog = ({
  handleCloseDialog,
  handleDialogOpen,
  submitDialog,
  email,
  temporaryPassword,
}) => {
  const { isSuperAdmin } = useRoles();
  const classes = useStyles();

  return (
    <CustomDialog
      title="Are you sure?"
      isDialogOpen={handleDialogOpen}
      handleCloseDialog={handleCloseDialog}
    >
      <Card className={classes.card}>
        <CardContent>
          <Typography>Email: {email}</Typography>
          {isSuperAdmin() && <Typography>Temporary Password: {temporaryPassword}</Typography>}
          <Typography variant="caption" sx={{ mt: 2, display: 'block' }}>
            Click Force Password Reset to send this user an email with their temporary password
          </Typography>
        </CardContent>
        <CardActions className={classes.cardActions}>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button
            onClick={() => submitDialog(email, temporaryPassword)}
            variant="contained"
            color="primary"
          >
            Force Password Reset
          </Button>
        </CardActions>
      </Card>
    </CustomDialog>
  );
};

ResetPasswordDialog.propTypes = {
  handleCloseDialog: PropTypes.func.isRequired,
  handleDialogOpen: PropTypes.bool.isRequired,
  submitDialog: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  temporaryPassword: PropTypes.string.isRequired,
};

export default ResetPasswordDialog;
