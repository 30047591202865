import React from 'react';
import { Button, CardContent, Divider, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { sentenceCase } from 'change-case';
import CustomDialog from '../CustomDialog';
import CustomWarningBanner from '../CustomWarningBanner';
import WithVenue from '../../hoc/withVenue';

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.primary.main,
  },
  costBreakdownVariables: {
    ...theme.customFonts.small,
    color: theme.customPalette.greyDarker,
  },
  closeButton: {
    borderRadius: '8px',
  },
  dashedDivider: {
    border: '1px #BCCCDC',
    borderStyle: 'dashed',
    backgroundClip: 'content-box',
    borderSpacing: '6px',
    marginTop: '24px',
    marginBottom: '19px',
  },
  totalPrice: {
    ...theme.customFonts.mediumBold,
    color: theme.customPalette.midnight,
    marginBottom: '19px',
  },
  warning: {
    color: theme.customPalette.greyDarker,
    ...theme.customFonts.small,
  },
  customWarningBanner: {
    marginTop: '32px',
  },
  cardContent: {
    marginLeft: '28px',
    marginRight: '28px',
  },
  strikeThrough: {
    color: theme.customPalette.greyDark,
    textDecoration: 'line-through',
    marginRight: '8px',
  },
  venueName: {
    ...theme.customFonts.mediumBold,
    color: theme.customPalette.greyDarker,
  },
  venueAddress: {
    ...theme.customFonts.small,
    color: theme.customPalette.greyDark,
  },
}));

const CostBreakdown = ({
  isDialogOpen,
  handleCloseDialog,
  fixedCosts,
  variableCosts,
  hardware,
  monthlyFee,
  total,
  discount,
  subtotal,
  subscriptionType,
  venue,
}) => {
  const classes = useStyles();

  return (
    <CustomDialog
      handleCloseDialog={handleCloseDialog}
      isDialogOpen={isDialogOpen}
      title="Cost breakdown"
      actions={
        <Button className={classes.closeButton} variant="outlined" onClick={handleCloseDialog}>
          Close
        </Button>
      }
    >
      <CardContent className={classes.cardContent}>
        <Typography className={classes.venueName}>{venue.name}</Typography>
        <Typography className={classes.venueAddress}>{venue.address}</Typography>
        <Divider
          variant="fullWidth"
          style={{ background: '#333333', height: '2px', marginBottom: '18px', marginTop: '8px' }}
        />
        {monthlyFee && (
          <Grid container spacing={2} key={`${monthlyFee}.monthly.fee`}>
            <Grid item xs={8}>
              <Typography className={classes.costBreakdownVariables} variant="body1">
                {sentenceCase(subscriptionType)} plan
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography className={classes.costBreakdownVariables} variant="body1" align="right">
                x1
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography className={classes.costBreakdownVariables} variant="body1" align="right">
                £{monthlyFee}
              </Typography>
            </Grid>
          </Grid>
        )}
        {hardware &&
          hardware.map(
            (item) =>
              item.quantity > 0 && (
                <Grid container spacing={2} key={item.label}>
                  <Grid item xs={8}>
                    <Typography className={classes.costBreakdownVariables} variant="body1">
                      {item.label}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography
                      className={classes.costBreakdownVariables}
                      variant="body1"
                      align="right"
                    >
                      x{item.quantity}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography
                      className={classes.costBreakdownVariables}
                      variant="body1"
                      align="right"
                    >
                      {item.discountedPrice ? (
                        <>
                          <span className={classes.strikeThrough}>{`£${item.unitPrice}`}</span>£
                          {item.discountedPrice}
                        </>
                      ) : (
                        <>£{item.unitPrice}</>
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              ),
          )}
        {fixedCosts &&
          fixedCosts.map(
            (item) =>
              item.enabled && (
                <Grid container spacing={2} key={`${item.label}.item.label`}>
                  <Grid item xs={10}>
                    <Typography className={classes.costBreakdownVariables} variant="body1">
                      {item.label}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography
                      className={classes.costBreakdownVariables}
                      variant="body1"
                      align="right"
                    >
                      {item.discountedPrice ? (
                        <>
                          <span className={classes.strikeThrough}>{`£${item.unitPrice}`}</span>£
                          {item.discountedPrice}
                        </>
                      ) : (
                        <>£{item.unitPrice}</>
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              ),
          )}

        <Divider className={classes.dashedDivider} />
        {subtotal && (
          <Grid container spacing={2} key={subtotal} alignItems="baseline">
            <Grid item xs={10}>
              <Typography className={classes.costBreakdownVariables} variant="body1">
                Subtotal
              </Typography>
            </Grid>
            <Grid item xs={2} container justifyContent="flex-end">
              <Typography className={classes.costBreakdownVariables} variant="body1">
                £{subtotal}
              </Typography>
            </Grid>
          </Grid>
        )}
        {discount && (
          <Grid container spacing={2} key={discount} alignItems="baseline">
            <Grid item xs={10}>
              <Typography className={classes.costBreakdownVariables} variant="body1">
                Additional overall discount
              </Typography>
            </Grid>
            <Grid item xs={2} container justifyContent="flex-end">
              <Typography className={classes.costBreakdownVariables} variant="body1">
                -£{discount}
              </Typography>
            </Grid>
          </Grid>
        )}

        {total && (
          <Grid container spacing={2} key={total} alignItems="baseline">
            <Grid item xs={10}>
              <Typography className={classes.costBreakdownVariables} variant="body1">
                Total
              </Typography>
            </Grid>
            <Grid item xs={2} container justifyContent="flex-end">
              <div className={classes.totalPrice}>£{total}</div>
            </Grid>
          </Grid>
        )}
        {variableCosts.some((item) => item.enabled) && (
          <CustomWarningBanner
            noIcon
            title="Variable costs"
            backgroundColor="greyLightest"
            titleFont="smallBold"
            titleColor="greyDarker"
          >
            {variableCosts &&
              variableCosts.map(
                (item) =>
                  item.enabled && (
                    <Grid container spacing={2} key={`${item.label}.item.variable.costs.label`}>
                      <Grid item xs={10}>
                        <Typography className={classes.costBreakdownVariables} variant="body1">
                          {item.label}
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography
                          className={classes.costBreakdownVariables}
                          variant="body1"
                          align="right"
                        >
                          £{item.unitPrice}
                        </Typography>
                      </Grid>
                    </Grid>
                  ),
              )}
          </CustomWarningBanner>
        )}

        <div className={classes.customWarningBanner}>
          <CustomWarningBanner
            noIcon
            title="Something not right?"
            backgroundColor="greyLightest"
            titleFont="smallBold"
            titleColor="greyDarker"
          >
            <Typography className={classes.warning} variant="body2" color="textSecondary">
              Got a question or need to make a change. Please contact customer care either using our
              chat in the bottom right hand corner of the screen.
            </Typography>
          </CustomWarningBanner>
        </div>
      </CardContent>
    </CustomDialog>
  );
};

export default WithVenue(CostBreakdown);
