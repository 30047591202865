import React, { createContext } from 'react';
import { SnackbarProvider } from 'notistack';

import useNotificationsManager from './useNotificationsManager';

export const NotificationsContext = createContext({});

const NotificationsProvider = (props) => {
  const { children } = props;
  const { showErrorNotification, showSuccessNotification } = useNotificationsManager();

  return (
    <NotificationsContext.Provider value={{ showErrorNotification, showSuccessNotification }}>
      {children}
    </NotificationsContext.Provider>
  );
};

export const Notifications = ({ children }) => (
  <SnackbarProvider>
    <NotificationsProvider>{children}</NotificationsProvider>
  </SnackbarProvider>
);

export default Notifications;
