import { createSlice } from '@reduxjs/toolkit';
import {
  asyncInitialState as initialState,
  createAsyncAction,
  createAsyncReducerHandlers,
} from '../utils/async';
import { fetchDietaryRequirements as fetchDietaryRequirementsApi } from '../../api/dietaryRequirements';

export const fetchDietaryRequirements = createAsyncAction(
  'fetch-dietary-requirements',
  fetchDietaryRequirementsApi,
);

const dietaryRequirementsSlice = createSlice({
  name: 'dietary-requirements',
  initialState,
  reducers: {},
  extraReducers: createAsyncReducerHandlers(fetchDietaryRequirements),
});

export const { reducer: dietaryRequirements } = dietaryRequirementsSlice;
