import { pick } from 'ramda';
import { get, post, patch, del } from './httpRequest';

export const fetchTables = async () => {
  const response = await get(`/tables`);
  return response.data;
};

export const fetchTable = async (tableName) => {
  const response = await get(`/tables/${tableName}`);
  return response.data;
};

export const createTable = async (table) => {
  const response = await post(`/tables`, {
    data: table,
  });
  return response.data;
};

export const deleteTable = async (tableName) => {
  await del(`/tables/${tableName}`);
};

export const updateTable = async ({ tableName, values }) => {
  const trimmedValues = {
    ...pick(['description', 'tableName', 'enabled'], values),
  };
  await patch(`/tables/${tableName}`, {
    data: trimmedValues,
  });
};

export const updateTableAvailability = async ({ values }) => {
  const data = { tables: [] };
  values.tables.forEach((table) => {
    data.tables.push(pick(['tableName', 'enabled'], table));
  });
  await patch(`/tables/availability`, { data });
};
