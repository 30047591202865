import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import getCroppedImgBlob from '../../shared/utils/getCroppedImgBlob';

const useStyles = makeStyles((theme) => ({
  heading: {
    fontFamily: 'Sen',
    display: 'flex',
    justifyContent: 'space-between',
  },
  subtitleText: {
    fontFamily: 'Sen',
    fontSize: '1.2rem',
  },
  blueText: {
    fontFamily: 'Sen',
    color: '#333333',
    fontWeight: 600,
    marginTop: '2rem',
  },
  greyText: {
    fontFamily: 'Sen',
    color: '#656565',
    fontWeight: 600,
    marginTop: '1rem',
  },
  cropHolder: {
    marginLeft: theme.spacing(8),
    marginRight: theme.spacing(8),
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(4),
      marginRight: theme.spacing(4),
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  },
}));

const scaleCrop = (crop, ratio) => ({
  ...crop,
  x: crop.x * ratio,
  y: crop.y * ratio,
  width: crop.width * ratio,
  height: crop.height * ratio,
});

const PhotoUploadCropper = ({
  handleCloseDialog,
  file,
  handleUpload,
  aspectRatio = 4 / 3,
  isCategoryImage = false,
  isBrandLogo = false,
}) => {
  const imageType = isCategoryImage || isBrandLogo ? 'image/png' : 'image/jpeg';
  const [src, setSrc] = useState(null);
  const [cropImageElement, setCropImageElement] = useState(null);
  const [crop, setCrop] = useState({
    unit: 'px',
    x: 13,
    y: 5,
    width: 112,
    height: 112 / aspectRatio,
    aspect: aspectRatio,
  });

  useEffect(() => {
    if (!file) return;
    const reader = new FileReader();
    reader.addEventListener('load', () => setSrc(reader.result));
    reader.readAsDataURL(file);
  }, [file]);

  const upload = useCallback(() => {
    const tempImg = new Image();
    tempImg.onload = async () => {
      const croppedWidth = cropImageElement.offsetWidth;
      const originalWidth = tempImg.width;
      const scaledCrop = scaleCrop(crop, originalWidth / croppedWidth);
      const croppedImg = await getCroppedImgBlob(tempImg, scaledCrop, file.name, imageType);
      handleUpload(croppedImg);
    };
    tempImg.src = URL.createObjectURL(file);
  }, [crop, cropImageElement, file, handleUpload, imageType]);

  const classes = useStyles();
  return (
    <>
      <Box className={classes.cropHolder} display="flex" justifyContent="center">
        {src && (
          <ReactCrop
            src={src}
            crop={crop}
            ruleOfThirds
            onImageLoaded={(image) => {
              setCropImageElement(image);
            }}
            onChange={setCrop}
          />
        )}
      </Box>
      <Button onClick={upload}>Confirm Cropping</Button>
      <Button onClick={handleCloseDialog}>Back</Button>
    </>
  );
};

export default PhotoUploadCropper;
