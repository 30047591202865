import { Formik } from 'formik';
import React, { useContext } from 'react';
import isEmpty from 'lodash.isempty';

import SignupContext from '../SignupContext';
import StepQuestion from '../StepQuestion';
import StepButton from '../StepButton';
import { signupFields } from '../signupFields';
import StepInput from '../StepInput';
import validationSchema from './validationSchema';

const StepVenueDetailsOne = () => {
  const { formValues } = useContext(SignupContext);
  const initialValues = {
    [signupFields.name]: formValues.name,
  };

  return (
    <>
      <StepQuestion
        question="What is your venue name?"
        description="If your venue doesn’t have an official name, 
          simply use your own name. This will be shown on your receipts (and can be changed later in your settings)"
      />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnMount
        enableReinitialize
      >
        {({ errors, isValid }) => {
          const isFormValid = isValid && isEmpty(errors);

          return (
            <>
              <StepInput name={signupFields.name} placeholder="Venue name" />
              <StepButton disabled={!isFormValid} />
            </>
          );
        }}
      </Formik>
    </>
  );
};

export default StepVenueDetailsOne;
