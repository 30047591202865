import React, { useCallback } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import PromotionsList from '../PromotionsList';
import UniversalSave from '../UniversalSave';

import { reorder } from '../DragAndDrop/helpers';

import { customCampaignTypes } from './campaignTypes';

const PromotionsListForm = ({ campaigns, onSubmit, initialValues, handleOpenOfferDialog }) => {
  const PromotionsSchema = Yup.object().shape({
    camapigns: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().optional(),
        order: Yup.number().optional(),
        priorityId: Yup.string().optional(),
        schedule: Yup.array().of(Yup.string()).optional(),
        campaignName: Yup.string().optional(),
        campaignWebTitle: Yup.string().optional(),
        campaignWebDescription: Yup.string().optional(),
        campaignWebButtonText: Yup.string().optional(),
        active: Yup.boolean().optional(),
        campaignType: Yup.string()
          .oneOf(Object.values(customCampaignTypes).map(({ value }) => value))
          .optional(),
        campaignBannerLink: Yup.string().url().optional(),
      }),
    ),
  });

  const handleCampaignsReorder = useCallback((event, setFieldValue, currentCampaigns) => {
    if (!event.destination) {
      return;
    }

    const reorderedList = reorder(currentCampaigns, event.source.index, event.destination.index);

    setFieldValue('campaigns', reorderedList);
  }, []);

  return (
    <Formik
      initialValues={{ ...initialValues, campaigns }}
      onSubmit={onSubmit}
      validationSchema={PromotionsSchema}
    >
      {({ setFieldValue, values, isValid, dirty, submitForm, resetForm, errors }) => (
        <>
          <UniversalSave
            isValid={isValid}
            dirty={dirty}
            onSave={submitForm}
            onDiscard={resetForm}
            errors={errors}
          />
          <Form>
            <PromotionsList
              campaigns={values.campaigns}
              handleCampaignsReorder={handleCampaignsReorder}
              handleOpenOfferDialog={handleOpenOfferDialog}
              setFieldValue={setFieldValue}
            />
          </Form>
        </>
      )}
    </Formik>
  );
};

export default PromotionsListForm;
