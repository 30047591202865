import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { DragDropContext } from 'react-beautiful-dnd';

import { makeStyles } from '@mui/styles';

import { useNotifications } from '../../../../shared/contexts/Notifications/useNotifications';
import { getErrorMessage } from '../../../../shared/utils/errors';

import Droppable from '../../../DragAndDrop/Droppable';
import Draggable from '../../../DragAndDrop/Draggable';
import MenuGroup from '../MenuGroup';
import useRoles from '../../../../hooks/useRoles';
import {
  getBuilderMenuGroupsList,
  getBuilderMenuName,
} from '../../../../store/brands/builderMenu/builderMenuItems/selectors';
import {
  createBuilderMenuGroupItem,
  moveBuilderMenuGroup,
  moveBuilderMenuGroupItem,
  updateBuilderMenuItems,
} from '../../../../store/brands/builderMenu/builderMenuItems';
import { fetchBrandsMenus } from '../../../../store/brands/brandsMenus';

const useStyles = makeStyles((theme) => ({
  group: {
    backgroundColor: theme.palette.grey[300],
    borderRadius: theme.shape.borderRadius,
    padding: '0',
    marginBottom: '10px',
    overflow: 'hidden',
  },
}));

const MenuGroups = ({ expandAll, isReadonly }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { isRoleAtLeastManager } = useRoles();
  const { showErrorNotification, showSuccessNotification } = useNotifications();
  const menuGroupsList = useSelector(getBuilderMenuGroupsList);
  const menuName = useSelector(getBuilderMenuName);

  const onDragEnd = async ({ type, source, destination }) => {
    if (!destination) {
      return;
    }
    try {
      if (type === 'droppable_menu_groups') {
        dispatch(moveBuilderMenuGroup({ from: source.index, to: destination.index }));
      } else {
        dispatch(
          moveBuilderMenuGroupItem({
            from: source.index,
            to: destination.index,
            groupId: type,
          }),
        );
      }
      await dispatch(updateBuilderMenuItems());
      await dispatch(fetchBrandsMenus());
      showSuccessNotification('The menu order has been updated');
    } catch (error) {
      showErrorNotification(getErrorMessage(error));
    }
  };

  const handleAddMenuGroupItem = async ({ groupId, itemId }) => {
    try {
      await dispatch(createBuilderMenuGroupItem({ groupId, itemId }));
      await dispatch(updateBuilderMenuItems());
      await dispatch(fetchBrandsMenus());
      showSuccessNotification('The menu has been updated');
    } catch (error) {
      showErrorNotification(getErrorMessage(error));
    }
  };

  return (
    <article>
      {!!menuGroupsList.length && (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable" type="droppable_menu_groups">
            <>
              {menuGroupsList.map((menuGroup, index) => (
                <Draggable
                  isDragDisabled={isReadonly || !isRoleAtLeastManager()}
                  className={classes.group}
                  key={menuGroup}
                  draggableId={`${menuGroup}`}
                  index={index}
                >
                  <MenuGroup
                    menuName={menuName}
                    groupIndex={index}
                    groupId={menuGroup}
                    onAddItem={handleAddMenuGroupItem}
                    expandAll={expandAll}
                    isReadonly={isReadonly}
                  />
                </Draggable>
              ))}
            </>
          </Droppable>
        </DragDropContext>
      )}
    </article>
  );
};

export default MenuGroups;
