import React, { useEffect } from 'react';
import { Box, Button, Paper, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import PageHeader from '../../../components/PageHeader';
import BackArrow from '../../../components/BackArrow';
import { fetchAdjustments } from '../../../store/adjustments';
import { getAdjustmentsState } from '../../../store/adjustments/selectors';
import AdjustmentsTable from '../AdjustmentsTable';

const useStyles = makeStyles((theme) => ({
  sectionTitle: {
    ...theme.customFonts.mediumBold,
  },
}));

const DeliveryAdjustments = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { loading, data: adjustments } = useSelector(getAdjustmentsState);

  useEffect(() => {
    dispatch(fetchAdjustments());
  }, [dispatch]);

  return (
    <>
      <PageHeader fullWidth>
        <BackArrow to="/admin" text="Admin" />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            mt: 2,
          }}
        >
          <Typography variant="h1">Delivery Adjustments</Typography>
          <Button
            variant="outlined"
            sx={{ backgroundColor: '#FFF' }}
            LinkComponent={Link}
            to="/admin/delivery-adjustments/create"
          >
            New adjustment
          </Button>
        </Box>
      </PageHeader>
      <Box>
        <Paper sx={{ mt: 3, p: 2 }}>
          <Typography variant="h2" className={classes.sectionTitle}>
            Active &amp; scheduled
          </Typography>
          <AdjustmentsTable
            adjustments={adjustments?.filter((adjustment) => adjustment.status === 'ACTIVE') || []}
            isLoading={loading}
          />
        </Paper>
        <Paper sx={{ mt: 2, p: 2 }}>
          <Typography variant="h2" className={classes.sectionTitle}>
            Past adjustments
          </Typography>
          <AdjustmentsTable
            adjustments={adjustments?.filter((adjustment) => adjustment.status !== 'ACTIVE') || []}
            isLoading={loading}
          />
        </Paper>
      </Box>
    </>
  );
};

export default DeliveryAdjustments;
