import React from 'react';
import { makeStyles } from '@mui/styles';
import PageLoader from '../PageLoader';
import PageError from '../PageError';

const useStyles = makeStyles(() => ({
  container: {
    maxWidth: '1052px',
    margin: '0 auto',
  },
}));

const Page = ({ loading, error, children, small, fullWidth }) => {
  const classes = useStyles();
  if (error) {
    return <PageError error={error} />;
  }

  if (loading) {
    return <PageLoader small={small} />;
  }

  return <div className={fullWidth ? null : classes.container}>{children || null}</div>;
};

export default Page;
