const initialSignupFields = {
  recaptcha: '',
  address: '',
  postcode: '',
  city: '',
  country: '',
  phone: '',
  email: '',
  name: '',
  businessName: '',
  businessType: '',
  isBusinessAddressSameAsAddress: '',
  businessAddress: '',
  businessCity: '',
  businessPostcode: '',
  hubspotCompanyId: '',
  termsOfService: '',
};

const signupFields = {
  recaptcha: 'recaptcha',
  address: 'address',
  postcode: 'postcode',
  city: 'city',
  country: 'country',
  phone: 'phone',
  email: 'email',
  name: 'name',
  businessName: 'businessName',
  businessType: 'businessType',
  isBusinessAddressSameAsAddress: 'isBusinessAddressSameAsAddress',
  businessAddress: 'businessAddress',
  businessCity: 'businessCity',
  businessPostcode: 'businessPostcode',
  hubspotCompanyId: 'hubspotCompanyId',
  termsOfService: 'termsOfService',
};

const signupCountries = ['England', 'Scotland', 'Wales', 'Northern Ireland'];

const signupBusinessTypes = [
  {
    value: 'BUSINESS',
    label: 'Ltd Company',
  },
  {
    value: 'INDIVIDUAL',
    label: 'Sole Trader',
  },
  {
    value: 'OTHER',
    label: 'Other',
  },
];

export { initialSignupFields, signupFields, signupCountries, signupBusinessTypes };
