import { createSlice } from '@reduxjs/toolkit';
import {
  asyncInitialState as initialState,
  createAsyncAction,
  createAsyncReducerHandlers,
  fetchPageAsyncReducerHandler,
} from '../utils/async';
import {
  fetchPromoCodes as fetchPromoCodesApi,
  fetchPromoCode as fetchPromoCodeApi,
  updatePromoCode as updatePromoCodeApi,
  deletePromoCode as deletePromoCodeApi,
  createPromoCode as createPromoCodeApi,
} from '../../api/promoCodes';

export const fetchPromoCodes = createAsyncAction('fetch-promoCodes', fetchPromoCodesApi);
export const fetchPromoCodesPage = createAsyncAction('fetch-promoCodes-page', fetchPromoCodesApi);
export const fetchPromoCode = createAsyncAction('fetch-promoCode', fetchPromoCodeApi);
export const updatePromoCode = createAsyncAction('update-promoCode', updatePromoCodeApi);
export const deletePromoCode = createAsyncAction('delete-promoCode', deletePromoCodeApi);
export const createPromoCode = createAsyncAction('create-promoCode', createPromoCodeApi);

const promoCodesSlice = createSlice({
  name: 'promoCodes',
  initialState,
  reducers: {
    clearPromoCodes(state) {
      delete state.data;
    },
  },
  extraReducers: {
    ...createAsyncReducerHandlers(fetchPromoCodes),
    ...fetchPageAsyncReducerHandler(fetchPromoCodesPage),
  },
});

const promoCodeSlice = createSlice({
  name: 'promoCode',
  initialState,
  reducers: {
    clearPromoCode(state) {
      delete state.data;
    },
  },
  extraReducers: {
    ...createAsyncReducerHandlers(fetchPromoCode),
    ...createAsyncReducerHandlers(updatePromoCode),
    ...createAsyncReducerHandlers(deletePromoCode),
    ...createAsyncReducerHandlers(createPromoCode),
  },
});
export const { clearPromoCodes } = promoCodesSlice.actions;
export const { clearPromoCode } = promoCodeSlice.actions;

export const { reducer: promoCodes } = promoCodesSlice;
export const { reducer: promoCode } = promoCodeSlice;
