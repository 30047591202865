import React from 'react';
import { Card } from '@mui/material';
import CustomDialog from '../CustomDialog/index';
import VenuePriorityForm from './VenuePriorityForm';

const VenueEditPriorityDialog = ({ handleCloseDialog, dialogOpen, initialValues, onSubmit }) => {
  const dialogTitle = initialValues ? 'Rename' : 'Create';

  return (
    <CustomDialog
      title={`${dialogTitle} course`}
      isDialogOpen={dialogOpen}
      handleCloseDialog={handleCloseDialog}
    >
      <Card>
        <VenuePriorityForm
          initialValues={initialValues}
          handleCloseDialog={handleCloseDialog}
          onSubmit={onSubmit}
        />
      </Card>
    </CustomDialog>
  );
};

export default VenueEditPriorityDialog;
