import { Switch } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import KebabMenu from '../../components/KebabMenu';
import useEndpoint from '../../hooks/useEndpoint';
import useESPicker from '../../hooks/useESPicker';
import { useNotifications } from '../../shared/contexts/Notifications/useNotifications';
import { getErrorMessage } from '../../shared/utils/errors';
import { fetchAccounts, updateAccount } from '../../store/accounts';
import { getAccountsState } from '../../store/accounts/selectors';

const useStyles = makeStyles((theme) => ({
  accountRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
  },
  accountName: {
    ...theme.customFonts.medium,
    color: theme.customPalette.greyDarker,
    marginBottom: 0,
  },
  accountDescription: {
    ...theme.customFonts.xSmall,
    color: theme.customPalette.greyDark,
  },

  viewOrders: {
    ...theme.customFonts.small,
    color: theme.customPalette.greyDarker,
    textDecoration: 'underline',
    textAlign: 'right',
  },
  accountActions: {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
  },
  switch: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));

function AccountRow({ account, editAccount }) {
  const { accountId, name, description, enabled } = account;
  const { redispatch } = useEndpoint(getAccountsState, fetchAccounts());
  const { showErrorNotification, showSuccessNotification } = useNotifications();

  const { handleAccountChange } = useESPicker();
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const viewOrders = () => {
    handleAccountChange({ target: { value: accountId } });
    history.push(`/accounts/${accountId}/orders`);
  };

  const handleDisableAccount = async () => {
    try {
      await dispatch(updateAccount(accountId, { ...account, enabled: false }));
      showSuccessNotification('Account has been disabled successfully');
    } catch (newError) {
      showErrorNotification(getErrorMessage(newError));
    }
    redispatch();
  };

  const handleEnableAccount = async () => {
    try {
      await dispatch(updateAccount(accountId, { ...account, enabled: true }));
      showSuccessNotification('Account has been enabled successfully');
    } catch (newError) {
      showErrorNotification(getErrorMessage(newError));
    }
    redispatch();
  };

  const menuItems = [
    {
      name: 'View Orders',
      handler: () => {
        viewOrders();
      },
    },
    {
      name: 'Edit',
      handler: () => {
        editAccount(accountId);
      },
    },
  ];
  const enableItem = {
    name: 'Enable Account',
    handler: () => {
      handleEnableAccount();
    },
  };

  const disableItem = {
    name: 'Disable Account',
    handler: () => {
      handleDisableAccount();
    },
  };

  const toggleEnable = () => {
    if (enabled) {
      handleDisableAccount();
    } else {
      handleEnableAccount();
    }
  };
  const menu = enabled ? [...menuItems, disableItem] : [...menuItems, enableItem];

  return (
    <div
      className={classes.accountRow}
      key={accountId}
      tabIndex={0}
      role="button"
      onKeyUp={() => viewOrders(accountId)}
      onClick={() => viewOrders(accountId)}
    >
      <div>
        <p className={classes.accountName}>{name}</p>
        <span className={classes.accountDescription}>{description}</span>
      </div>
      <div className={classes.accountActions}>
        <div className={classes.viewOrders}>View orders</div>
        <Switch
          color="primary"
          className={classes.switch}
          type="checkbox"
          checked={enabled}
          onClick={(event) => event.stopPropagation()}
          onChange={toggleEnable}
        />
        <KebabMenu items={menu} />
      </div>
    </div>
  );
}

export default AccountRow;
