import { makeStyles } from '@mui/styles';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  link: {
    textDecoration: 'underline',
    cursor: 'pointer',
    color: theme.palette.secondary.main,
    '&:hover': {
      textDecoration: 'none',
      backgroundColor: 'transparent',
    },
    '&:active': {
      color: '#0E55BA',
    },
    '&:visited': {
      color: '#333333',
    },
  },
}));

const ButtonLink = ({ children, handleOnClick }) => {
  const classes = useStyles();
  return (
    <span
      role="button"
      tabIndex={0}
      onKeyUp={handleOnClick}
      onClick={handleOnClick}
      className={classes.link}
    >
      {children}
    </span>
  );
};

export default ButtonLink;
