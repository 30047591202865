import { createSelector } from '@reduxjs/toolkit';
import { ascend, prop, sortWith } from 'ramda';

const categorySort = sortWith([ascend(prop('type')), ascend(prop('categoryName'))]);
export const getBrandsCategoriesState = (state) => state.brandsCategories;

export const getBrandsCategoryState = (state) => state.brandsCategory;

export const getCategories = createSelector(
  getBrandsCategoriesState,
  (categoriesState) => categoriesState.data && categorySort(categoriesState.data),
);

export const getCategoriesObj = createSelector(getBrandsCategoriesState, (categories) => {
  if (!categories.data) return null;
  return categories.data.reduce((acc, category) => {
    const { categoryId, categoryName, type } = category;
    return { [categoryId]: { categoryId, categoryName, type }, ...acc };
  }, {});
});
