import { rem } from 'polished';

const valuesToRem = (values) => values.map((value) => rem(value));

const breakpoints = [rem(600), rem(960), rem(1280), rem(1920)];
const space = [0, 4, 8, 12, 16, 20, 24, 32, 40, 48, 64, 96, 128, 192, 256];
const fontSizes = [12, 14, 16, 18, 20, 24, 30, 36, 48, 60, 72];
const borderWidths = [0, 1, 2, 4, 8];

const fonts = {
  sans: `-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
    "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"`,
  serif: 'Georgia, Cambria, "Times New Roman", Times, serif',
  mono: 'Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace',
};

const lineHeights = {
  none: 1,
  tight: 1.25,
  snug: 1.375,
  normal: 1.5,
  relaxed: 1.625,
  loose: 2,
};

const letterSpacings = {
  tighter: '-0.05em',
  tight: '-0.025em',
  normal: 0,
  wide: '0.025em',
  wider: '0.05em',
  widest: '0.1em',
};

const radii = {
  none: 0,
  sm: '0.125rem',
  md: '0.25rem',
  lg: '0.5rem',
  full: '9999px',
};

const zIndices = [0, 10, 20, 30, 40, 50];

const shadows = {
  none: 'none',
  sm: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
  md: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
  lg: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
  xl: '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)',
  xl2: '0 25px 50px -12px rgba(0, 0, 0, 0.25)',
  inner: 'inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)',
  outline: '0 0 0 3px rgba(66, 153, 225, 0.5)',
};

const grey = [
  '#F0F4F8',
  '#DBE2EB',
  '#BCCCDC',
  '#9FB3C8',
  '#829AB1',
  '#627D98', // base
  '#486581',
  '#333333',
  '#243B53',
  '#102A43',
];

const red = [
  '#FFE3E3',
  '#FFBDBD',
  '#FF9B9B',
  '#F86A6A',
  '#EF4E4E',
  '#E12D39', // base
  '#CF1124',
  '#AB091E',
  '#8A041A',
  '#610316',
];

const orange = [
  '#FFE8D9',
  '#FFD0B5',
  '#FFB088',
  '#FF9466',
  '#F9703E',
  '#F35627', // base
  '#DE3A11',
  '#C52707',
  '#AD1D07',
  '#841003',
];

const yellow = [
  '#FFFBEA',
  '#FFF3C4',
  '#FCE588',
  '#FADB5F',
  '#F7C948',
  '#F0B429', // base
  '#DE911D',
  '#CB6E17',
  '#B44D12',
  '#8D2B0B',
];

const lime = [
  '#F8FFED',
  '#E6FFBF',
  '#CAFF84',
  '#AFF75C',
  '#8DED2D',
  '#6CD410', // base
  '#5CB70B',
  '#399709',
  '#2E7B06',
  '#1E5303',
];

const green = [
  '#E3F9E5',
  '#C1F2C7',
  '#91E697',
  '#51CA58', // base
  '#31B237',
  '#18981D',
  '#0F8613',
  '#0E7817',
  '#07600E',
  '#014807',
];

const teal = [
  '#F0FCF9',
  '#C6F7E9',
  '#8EEDD1',
  '#5FE3C0',
  '#2DCCA7', // base
  '#17B897',
  '#079A82',
  '#048271',
  '#016457',
  '#004440',
];

const cyan = [
  '#E1FCF8',
  '#C1FEF6',
  '#92FDF2',
  '#62F4EB',
  '#3AE7E1',
  '#1CD4D4', // base
  '#0FB5BA',
  '#099AA4',
  '#07818F',
  '#05606E',
];

const lightBlue = [
  '#E3F8FF',
  '#B3ECFF',
  '#81DEFD',
  '#5ED0FA',
  '#40C3F7',
  '#2BB0ED',
  '#1992D4', // base
  '#127FBF',
  '#0B69A3',
  '#035388',
];

const blue = [
  '#E6F6FF',
  '#BAE3FF',
  '#7CC4FA',
  '#47A3F3',
  '#2186EB', // base
  '#0967D2',
  '#0552B5',
  '#03449E',
  '#01337D',
  '#002159',
];

const indigo = [
  '#D9E8FF',
  '#B0D0FF',
  '#88B1FC',
  '#5E8AEE',
  '#3A66DB',
  '#2251CC', // base
  '#1D3DBF',
  '#132DAD',
  '#0B1D96',
  '#061178',
];

const purple = [
  '#F2EBFE',
  '#DAC4FF',
  '#B990FF',
  '#A368FC',
  '#9446ED',
  '#8719E0', // base
  '#7A0ECC',
  '#690CB0',
  '#580A94',
  '#44056E',
];

const magenta = [
  '#FDEBFF',
  '#F8C4FE',
  '#F48FFF',
  '#F368FC',
  '#ED47ED',
  '#E019D0', // base
  '#CB10B8',
  '#B30BA3',
  '#960888',
  '#6E0560',
];

const pink = [
  '#FFE3EC',
  '#FFB8D2',
  '#FF8CBA',
  '#F364A2',
  '#E8368F',
  '#DA127D', // base
  '#BC0A6F',
  '#A30664',
  '#870557',
  '#620042',
];

const colors = {
  grey,
  red,
  orange,
  yellow,
  lime,
  green,
  teal,
  cyan,
  lightBlue,
  blue,
  indigo,
  purple,
  magenta,
  pink,

  text: {
    primary: grey[9],
    secondary: grey[7],
    tertiary: grey[5],
  },
};

export default {
  breakpoints,
  space: valuesToRem(space),
  fontSizes: valuesToRem(fontSizes),
  fonts,
  lineHeights,
  letterSpacings,
  borderWidths: valuesToRem(borderWidths),
  radii,
  zIndices,
  shadows,
  colors,
};
