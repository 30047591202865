import sendReportRequest from './sendReportRequest';

const fetchDeliveryMetricsApi = async ({
  dateRange,
  from,
  to,
  unit,
  reportingType,
  brands,
  platforms,
}) => {
  const responseData = await sendReportRequest({
    url: '/reports/delivery',
    dateRange,
    from,
    to,
    unit,
    reportingType,
    brands,
    platforms,
  });

  return responseData;
};

export default fetchDeliveryMetricsApi;
