import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Box, Button } from '@mui/material';
import { FiPlus } from 'react-icons/fi';

import Page from '../../components/Page';
import withVenue from '../../hoc/withVenue';
import { getVouchersState } from '../../store/vouchers/selectors';
import VouchersTable from '../../components/VouchersTable';
import { clearVouchers, fetchVouchers, fetchVouchersPage } from '../../store/vouchers';
import usePagination from '../../hooks/usePagination';
import useESPicker from '../../hooks/useESPicker';
import { rangeEnums } from '../../shared/utils/dateRanges';
import ESPicker from '../../components/ESPicker';
import DownloadReport from '../../components/DownloadReport';
import toUTC from '../../shared/utils/toUTC';
import VoucherForm from '../../components/VoucherForm';
import CustomDialog from '../../components/CustomDialog';
import PageHeader from '../../components/PageHeader';

const Vouchers = () => {
  const dispatch = useDispatch();
  const { loading, data: vouchers, error } = useSelector(getVouchersState);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const pagination = usePagination();
  const esPicker = useESPicker();
  const { selectedDates, searchTerm, dateRange, sortOrder } = esPicker;
  const { first, requestNewPage, rowsPerPage, resetPagination } = pagination;

  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };
  const handleFetchVouchersPage = useCallback(() => {
    dispatch(
      fetchVouchersPage({
        first,
        size: rowsPerPage,
        sortOrder,
        searchTerm,
        dateRange,
        from: dateRange === rangeEnums.CUSTOM && toUTC(selectedDates.from),
        to: dateRange === rangeEnums.CUSTOM && toUTC(selectedDates.to),
      }),
    );
  }, [dateRange, dispatch, first, rowsPerPage, searchTerm, selectedDates, sortOrder]);

  useEffect(() => {
    resetPagination();
    dispatch(
      fetchVouchers({
        first: 0,
        size: rowsPerPage,
        sortOrder,
        searchTerm,
        dateRange,
        from: dateRange === rangeEnums.CUSTOM && toUTC(selectedDates.from),
        to: dateRange === rangeEnums.CUSTOM && toUTC(selectedDates.to),
      }),
    );
  }, [dispatch, resetPagination, rowsPerPage, sortOrder, searchTerm, dateRange, selectedDates]);

  useEffect(() => {
    if (requestNewPage) {
      handleFetchVouchersPage();
    }
  }, [requestNewPage, handleFetchVouchersPage]);

  return (
    <>
      <PageHeader fullWidth>
        <Box>
          <ESPicker esPicker={esPicker}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<FiPlus />}
              onClick={handleOpenDialog}
            >
              Add Voucher
            </Button>
            <CustomDialog
              title="Add a voucher"
              handleCloseDialog={handleCloseDialog}
              isDialogOpen={isDialogOpen}
            >
              <VoucherForm handleCloseDialog={handleCloseDialog} />
            </CustomDialog>
            <DownloadReport type="vouchers" fullWidth={false} />
          </ESPicker>
        </Box>
      </PageHeader>
      <Page loading={loading} error={error} fullWidth>
        {vouchers && (
          <>
            <VouchersTable vouchers={vouchers} loading={loading} pagination={pagination} />
          </>
        )}
      </Page>
    </>
  );
};

export default withVenue(Vouchers, null, clearVouchers);
