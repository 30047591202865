import { createSlice } from '@reduxjs/toolkit';
import {
  createAsyncAction,
  asyncInitialState as initialState,
  createAsyncReducerHandlers,
} from '../utils/async';
import {
  fetchVenueFees as fetchVenueFeesApi,
  updateVenueFees as updateVenueFeesApi,
} from '../../api/venueFees';

export const fetchVenueFees = createAsyncAction('fetch-venue-fees', fetchVenueFeesApi);

export const updateVenueFees = createAsyncAction('update-venue-fees', updateVenueFeesApi);

const venueFeesSlice = createSlice({
  name: 'venueFees',
  initialState,
  reducers: {
    clearVenueFees(state) {
      delete state.data;
    },
  },
  extraReducers: {
    ...createAsyncReducerHandlers(fetchVenueFees),
    ...createAsyncReducerHandlers(updateVenueFees),
  },
});

export const { clearVenueFees } = venueFeesSlice.actions;
export const { reducer: venueFees } = venueFeesSlice;
