import React from 'react';
import { useSelector } from 'react-redux';
import { useIntercom } from 'react-use-intercom';

import { makeStyles } from '@mui/styles';
import { Button, Card, CardContent, Typography } from '@mui/material';

import NoAccessImage from './no_access.png';
import { isStandard } from '../../store/venues/selectors';

const useStyles = makeStyles(() => ({
  subtitle: {
    fontFamily: 'Sen',
    fontWeight: 'bold',
    marginBottom: '0.5rem',
  },
  image: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '2rem',
  },
  column: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  body: {
    textAlign: 'center',
  },
}));

const AccessLockedCard = () => {
  const classes = useStyles();
  const accountIsStandard = useSelector(isStandard);
  const { show } = useIntercom();

  return (
    <>
      <Card>
        <CardContent className={classes.image}>
          <img src={NoAccessImage} alt="Locked" />
        </CardContent>
        <CardContent className={classes.column}>
          <Typography variant="subtitle2" className={classes.subtitle}>
            You can&apos;t use this page
          </Typography>
          <Typography variant="body2" className={classes.body}>
            Your role level or account type is restricting you from viewing this page.
          </Typography>
          {accountIsStandard && (
            <>
              <Typography variant="body2" className={classes.body}>
                Please contact your account administrator to upgrade your account.
              </Typography>
              <Typography variant="body2" className={classes.body}>
                Drive traffic and increase spend per head by running promotions, offering pre-paid
                vouchers, and more when you upgrade to PRO.
              </Typography>
              <p>
                <Button variant="contained" color="primary" onClick={show}>
                  Ask support for an upgrade
                </Button>
              </p>
            </>
          )}
        </CardContent>
      </Card>
    </>
  );
};

export default AccessLockedCard;
