import React from 'react';
import PropTypes from 'prop-types';

import { Card, CardActions, CardContent, Button } from '@mui/material';
import CustomDialog from '../CustomDialog';

const VenueOpenDialog = ({ handleCloseDialog, dialogOpen, message }) => (
  <CustomDialog
    title="Venue is open"
    isDialogOpen={dialogOpen}
    handleCloseDialog={handleCloseDialog}
  >
    <Card>
      <CardContent>{message}</CardContent>
      <CardActions>
        <Button variant="contained" color="primary" onClick={handleCloseDialog}>
          OK
        </Button>
      </CardActions>
    </Card>
  </CustomDialog>
);

VenueOpenDialog.propTypes = {
  handleCloseDialog: PropTypes.func.isRequired,
  dialogOpen: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
};

export default VenueOpenDialog;
