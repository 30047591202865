import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import {
  Chip,
  Collapse,
  Grid,
  List,
  ListItemButton,
  ListItemIcon,
  Tooltip,
  Typography,
} from '@mui/material';

import {
  ExpandLess as LessIcon,
  ExpandMore as MoreIcon,
  Image,
  ImageNotSupported,
} from '@mui/icons-material';
import { MdSchedule as ScheduleIcon, MdVisibilityOff as VisibilityOffIcon } from 'react-icons/md';

import { getHasSchedule, getMenuGroup } from '../../store/venueMenuItems/selectors';
import { getMenuItems } from '../../store/menuItems/selectors';

import Droppable from '../DragAndDrop/Droppable';
import Draggable from '../DragAndDrop/Draggable';
import MenuGroupItem from '../MenuGroupItem';
import AutoComplete from '../AutoComplete';
import EditMenuGroupButton from '../EditMenuGroupButton';
import ScheduleMenuGroupButton from '../ScheduleMenuGroupButton';
import InlineChip from '../InlineChip';
import MenuGroupColor from './MenuGroupColor';
import useRoles from '../../hooks/useRoles';
import { getVenuesSettingsState } from '../../store/venues/selectors';
import menuDeliveryTypes from '../MenuDeliveryPreview/menuDeliveryTypes';

const useStyles = makeStyles((theme) => ({
  groupHeadingContainer: {},
  groupHeadingSelected: {},
  groupHeadingRoot: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    overflow: 'hidden',
    '$groupHeadingSelected &': {
      backgroundColor: 'inherit',
    },
  },
  groupHeadingWithSecondary: { paddingRight: '140px' },
  menuItems: { padding: '0 16px 16px 62px' },
  menuItem: { paddingLeft: '0' },
  groupPriorityLabel: {
    backgroundColor: theme.palette.grey[200],
    height: '24px',
  },
  groupName: {
    fontSize: '1rem',
    textTransform: 'capitalize',
  },
  groupImageIcon: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    color: '#0000008a',
  },
}));

const MenuGroup = ({
  menuName,
  groupIndex,
  groupId,
  onAddItem,
  expandAll,
  venuePriorities,
  isReadonly,
  checkIfItemIsOnMenu,
}) => {
  const classes = useStyles();
  const { push } = useHistory();
  const { isRoleAtLeastManager } = useRoles();

  const menuGroup = useSelector((state) => getMenuGroup(state, groupId));
  let menuItems = useSelector((state) => getMenuItems(state));
  const hasSchedule = useSelector((state) => getHasSchedule(state, groupId));
  const { data: venueMenu } = useSelector((state) => state.venueMenu);
  const venueSettingsState = useSelector(getVenuesSettingsState);
  const menuTypeIsDelivery =
    venueMenu?.type === menuDeliveryTypes.DELIVERY_INHOUSE.value ||
    venueMenu?.type === menuDeliveryTypes.DELIVERY.value;

  const { data: venueSettings } = venueSettingsState;
  const { enabled: venuePrioritiesEnabled } = venuePriorities || {};
  const {
    groupName,
    groupItems,
    groupColor,
    available = true,
    description,
    priorityId,
    priority,
    groupImageUrl,
  } = menuGroup;

  const isPriorityLabelVisible = priority && venuePrioritiesEnabled;
  const isSessionsBrandsEnabled = !!venueSettings?.find(
    (setting) => setting.settingName === 'SESSIONS_BRAND_ENABLED' && setting.value === true,
  );

  const [isGroupCollapsed, setIsGroupCollapsed] = useState(true);

  const handleHeaderClick = () => {
    setIsGroupCollapsed(!isGroupCollapsed);
  };

  const handleAddMenuItem = (itemId, itemName) => {
    if (!itemId) {
      push(`/menu-items/add?menuName=${menuName}&groupId=${groupId}&itemName=${itemName}`);
    } else {
      onAddItem({ groupId, itemId });
    }
  };

  useEffect(() => {
    setIsGroupCollapsed(!expandAll);
  }, [expandAll]);

  if (!isSessionsBrandsEnabled || venueMenu?.type === menuDeliveryTypes.DELIVERY_INHOUSE.value) {
    menuItems = menuItems.filter((item) => !item.brandId);
  }

  return (
    <>
      <List disablePadding>
        <ListItemButton
          disableGutters
          classes={{
            container: clsx(
              classes.groupHeadingContainer,
              !isGroupCollapsed && classes.groupHeadingSelected,
            ),
            root: classes.groupHeadingRoot,
            secondaryAction: classes.groupHeadingWithSecondary,
          }}
          disableRipple
          onClick={handleHeaderClick}
        >
          <ListItemIcon>{isGroupCollapsed ? <MoreIcon /> : <LessIcon />}</ListItemIcon>

          <Grid container>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item xs={6} sm="auto">
                <Typography noWrap className={classes.groupName}>
                  {groupName}
                </Typography>
              </Grid>
              {isRoleAtLeastManager() && (
                <Grid item xs="auto">
                  <EditMenuGroupButton
                    menuName={menuName}
                    groupColor={groupColor}
                    groupName={groupName}
                    menuGroupPriorityId={priorityId}
                    groupId={groupId}
                    available={available != null ? available : true}
                    description={description}
                    venuePriorities={venuePriorities}
                    isReadonly={isReadonly}
                  />
                  {!menuTypeIsDelivery && (
                    <ScheduleMenuGroupButton
                      groupName={groupName}
                      groupId={groupId}
                      isReadonly={isReadonly}
                    />
                  )}
                </Grid>
              )}
              <Grid container alignItems="center" spacing={1}>
                {isPriorityLabelVisible && (
                  <Grid item>
                    <Chip label={priority} className={classes.groupPriorityLabel} />
                  </Grid>
                )}
                {!available && (
                  <Grid item>
                    <InlineChip label="Not on the menu" icon={<VisibilityOffIcon />} />
                  </Grid>
                )}
                {hasSchedule && (
                  <Grid item>
                    <InlineChip label="Scheduled" icon={<ScheduleIcon />} />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
          {isReadonly &&
            (groupImageUrl ? (
              <Tooltip title="Section image found" aria-label="Section image found">
                <Image fontSize="small" className={classes.groupImageIcon} />
              </Tooltip>
            ) : (
              <Tooltip title="No section image found" aria-label="No section image found">
                <ImageNotSupported fontSize="small" className={classes.groupImageIcon} />
              </Tooltip>
            ))}

          <MenuGroupColor color={groupColor} />
        </ListItemButton>
      </List>
      <Collapse className={classes.menuItems} in={!isGroupCollapsed} timeout="auto" unmountOnExit>
        {description && (
          <Typography variant="h4" paragraph>
            {description}
          </Typography>
        )}
        {groupItems && (
          <Droppable droppableId={`droppable_${groupId}`} type={`${groupId}`}>
            <List disablePadding>
              {groupItems.map((groupItem, index) => (
                <Draggable
                  isDragDisabled={isReadonly || !isRoleAtLeastManager()}
                  className={classes.menuItem}
                  key={`group_item_${groupId}_${groupIndex}_${groupItem}`}
                  draggableId={`group_item_${groupId}_${groupIndex}_${groupItem}`}
                  index={index}
                >
                  <MenuGroupItem
                    groupId={groupId}
                    itemId={groupItem}
                    itemIndex={index}
                    isReadonly={isReadonly}
                  />
                </Draggable>
              ))}
            </List>
          </Droppable>
        )}
        {!isReadonly && (
          <AutoComplete
            checkIfItemIsOnMenu={checkIfItemIsOnMenu}
            key={groupItems ? groupItems.length : 0}
            label="+ Add New Item"
            options={menuItems}
            optionLabelKey="itemName"
            optionValueKey="itemId"
            onSelect={handleAddMenuItem}
            groupBy={menuItems}
          />
        )}
      </Collapse>
    </>
  );
};

MenuGroup.propTypes = {
  menuName: PropTypes.string.isRequired,
  groupIndex: PropTypes.number.isRequired,
  groupId: PropTypes.string.isRequired,
  onAddItem: PropTypes.func.isRequired,
};

export default MenuGroup;
