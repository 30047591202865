import { createSlice } from '@reduxjs/toolkit';
import {
  asyncInitialState as initialState,
  createAsyncAction,
  createAsyncReducerHandlers,
} from '../utils/async';
import fetchReportTopCustomersApi from '../../api/reports/topCustomers';

export const fetchReportTopCustomers = createAsyncAction(
  'fetch-top-customers',
  fetchReportTopCustomersApi,
);

const reportTopCustomersSlice = createSlice({
  name: 'top-customers',
  initialState,
  reducers: {
    clearReportTopCustomers(state) {
      delete state.data;
    },
  },
  extraReducers: createAsyncReducerHandlers(fetchReportTopCustomers),
});

export const { clearReportTopCustomers } = reportTopCustomersSlice.actions;

export const { reducer: reportTopCustomers } = reportTopCustomersSlice;
