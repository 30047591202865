import { makeStyles } from '@mui/styles';
import React from 'react';
import { Box } from '@mui/material';
import menuTypes from '../../../shared/constants/menuTypes';

const useStyles = makeStyles((theme) => ({
  menuTypeIcon: {
    display: 'flex',
    padding: theme.spacing(0.5),
    marginRight: theme.spacing(1),
    borderRadius: 2,
  },
}));

const MenuTypeIcon = ({ type, isActive = false }) => {
  const classes = useStyles();
  const iconBgColor = isActive ? menuTypes[type].iconColor : '#F2F2F7';
  const iconColor = isActive ? '#000000' : '#5A7296';

  return (
    <Box className={classes.menuTypeIcon} sx={{ backgroundColor: iconBgColor, color: iconColor }}>
      {menuTypes[type].icon}
    </Box>
  );
};

export default MenuTypeIcon;
