import { createSlice } from '@reduxjs/toolkit';
import {
  asyncInitialState as initialState,
  createAsyncAction,
  createAsyncReducerHandlers,
} from '../utils/async';
import fetchReportRepeatCustomersApi from '../../api/reports/repeatCustomers';

export const fetchReportRepeatCustomers = createAsyncAction(
  'fetch-repeat-customers',
  fetchReportRepeatCustomersApi,
);

const reportRepeatCustomersSlice = createSlice({
  name: 'repeat-customers',
  initialState,
  reducers: {
    clearReportRepeatCustomers(state) {
      delete state.data;
    },
  },
  extraReducers: createAsyncReducerHandlers(fetchReportRepeatCustomers),
});

export const { clearReportRepeatCustomers } = reportRepeatCustomersSlice.actions;

export const { reducer: reportRepeatCustomers } = reportRepeatCustomersSlice;
