import React from 'react';
import { makeStyles } from '@mui/styles';
import lozengeLogic from '../../shared/utils/lozengeLogic';
import StatusLozenge, { statuscode } from '../StatusLozenge';

const useStyles = makeStyles((theme) => ({
  lozenge: {
    padding: '2px 8px',
    marginLeft: theme.spacing(1),
  },
}));

const PercentageStatusLozenge = ({ current, previous, negativePercentIsPositive = false }) => {
  const classes = useStyles();

  const { change, isChangePositive, showLozenge } = lozengeLogic(current, previous);

  let statusCodeColour = isChangePositive ? statuscode.success : statuscode.fail;

  if (negativePercentIsPositive) {
    statusCodeColour = isChangePositive ? statuscode.fail : statuscode.success;
  }

  return showLozenge ? (
    <StatusLozenge text={change} status={statusCodeColour} className={classes.lozenge} />
  ) : null;
};
export default PercentageStatusLozenge;
