import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';

import { getErrorMessage } from '../../shared/utils/errors';
import { useNotifications } from '../../shared/contexts/Notifications/useNotifications';
import DeviceForm from '../../components/DeviceForm';
import { fetchAllAvailableDevices, updateDevice } from '../../store/devices';
import withVenue from '../../hoc/withVenue';
import BackArrow from '../../components/BackArrow';
import PageHeader from '../../components/PageHeader';
import Page from '../../components/Page';

const useStyles = makeStyles(() => ({
  heading: {
    margin: '15px 0',
  },
}));

const AddDevice = ({ redirect }) => {
  const { showErrorNotification, showSuccessNotification } = useNotifications();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAllAvailableDevices());
  }, [dispatch]);

  const handleOnSubmit = async (values) => {
    try {
      await dispatch(updateDevice(values));
      showSuccessNotification('Device has been added successfully');
      redirect();
    } catch (error) {
      showErrorNotification(getErrorMessage(error));
    }
  };

  const classes = useStyles();

  return (
    <>
      <PageHeader>
        <BackArrow redirect={redirect} text="Devices" />
        <Typography className={classes.heading} variant="h2" component="h1">
          Add Device
        </Typography>
      </PageHeader>
      <Page>
        <DeviceForm
          initialValues={{
            deviceId: '',
            destination: '',
          }}
          onSubmit={handleOnSubmit}
        />
      </Page>
    </>
  );
};

export default withVenue(AddDevice, '/devices');
