import React from 'react';
import { FormControlLabel, Radio } from '@mui/material';
import { withStyles } from 'tss-react/mui';

const CustomFormControlLabel = withStyles(FormControlLabel, (theme) => ({
  root: {
    justifyContent: 'space-between',
    borderColor: theme.palette.grey[400],
    borderWidth: '1px',
    borderRadius: '5px',
    borderStyle: 'solid',
    padding: theme.spacing(1),
    marginBottom: theme.spacing(2),
    backgroundColor: '#fff',
    marginLeft: 0,
    marginRight: 0,
  },
}));

const StepRadio = (props) => (
  <CustomFormControlLabel control={<Radio color="primary" />} labelPlacement="start" {...props} />
);

export default StepRadio;
