import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { TextField } from 'formik-mui';
import { Field, Form, Formik } from 'formik';
import { nanoid } from 'nanoid';
import copy from 'clipboard-copy';
import QRCode from 'qrcode.react';
import {
  Box,
  Button,
  Card,
  CardContent,
  FormGroup,
  IconButton,
  InputAdornment,
  Typography,
  Grid,
  Link,
} from '@mui/material';
import { FiCopy } from 'react-icons/fi';
import { makeStyles } from '@mui/styles';
import { isValid } from 'postcode';
import { getErrorMessage } from '../../../shared/utils/errors';
import { useNotifications } from '../../../shared/contexts/Notifications/useNotifications';
import { fetchVenue, updateVenue } from '../../../store/venues';
import Page from '../../../components/Page';
import withVenue from '../../../hoc/withVenue';
import useRoles from '../../../hooks/useRoles';
import UniversalSave from '../../../components/UniversalSave';
import ChangeVenueNameDialog from '../../../components/ChangeVenueNameDialog';
import BackArrow from '../../../components/BackArrow';
import CustomDialog from '../../../components/CustomDialog';

const useStyles = makeStyles((theme) => ({
  warningCard: {
    backgroundColor: '#FFB800',
    marginBottom: theme.spacing(5),
  },
  ok: {
    color: '#00FF00',
  },
  stripeAction: {
    marginLeft: theme.spacing(2),
  },
  lozenge: {
    marginLeft: theme.spacing(2),
  },
  formsection: {
    marginTop: theme.spacing(2),
  },
  bottomUpdate: {
    marginTop: theme.spacing(2),
    align: 'right',
  },
  extraButton: {
    marginLeft: theme.spacing(2),
  },
  qrCode: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: '24px',
  },
  linkTitle: {
    padding: 0,
    margin: 0,
    ...theme.customFonts.small,
    color: theme.customPalette.greyDarker,
  },
}));

const VenueDetails = ({ venue, venueLoading, venueError, redirect }) => {
  const dispatch = useDispatch();
  const { isAdmin, isRoleAtleastOwner } = useRoles();
  const { showErrorNotification, showSuccessNotification } = useNotifications();
  const classes = useStyles();
  const [qrDialogOpen, setQrDialogOpen] = useState(false);
  const [confirmChangeNameDialogOpen, setConfirmChangeNameDialogOpen] = useState(false);
  const [temporaryValues, setTemporaryValues] = useState();
  const venuePath =
    venue && !!venue.children
      ? `${process.env.REACT_APP_WEBAPP_URI}/vendors/${venue.venueId}`
      : `${process.env.REACT_APP_WEBAPP_URI}/${venue.venuePath}`;
  const viewMenu = `${venuePath}/menu-view`;
  const initialValues = {
    ...venue,
    googlePlaceId: venue.googlePlaceId || '',
    deliverectLocationId: venue.deliverectLocationId || '',
    polarisId: venue.polarisId || '',
    airshipAccountId: venue.airshipAccountId || '',
    airshipAccountToken: venue.airshipAccountToken || '',
    airshipUnitId: venue.airshipUnitId || '',
    venuePath,
  };
  const VenueSchema = Yup.object().shape({
    name: Yup.string().min(3, 'Too Short!').required('Please enter your venue name'),
    phone: Yup.string()
      .min(3, 'Too Short!')
      .matches(
        // eslint-disable-next-line max-len
        /^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?#(\d{4}|\d{3}))?$/,
        'Invalid mobile number.',
      )
      .required('Please enter a valid phone number'),
    address: Yup.string()
      .min(3, 'Too Short!')
      .required('Please enter a valid address including house number'),
    city: Yup.string().min(3, 'Too Short!').required('Please enter a valid city name'),
    postcode: Yup.string()
      .min(3, 'Too Short!')
      .test('validPostCode', 'Please enter a valid postcode', (value) => value && isValid(value))
      .required('Please enter a valid postcode'),
    hubspotCompanyId: Yup.string().optional(),
    googlePlaceId: Yup.string().optional(),
    deliverectLocationId: Yup.string().optional(),
    polarisId: Yup.string().optional(),
    airshipAccountId: Yup.string().optional(),
    airshipAccountToken: Yup.string().optional(),
    airshipUnitId: Yup.string().optional(),
  });

  const handleCloseChangeNameDialog = () => setConfirmChangeNameDialogOpen(false);

  const handleOnSubmit = async (values, formikBag) => {
    const venueData = (({
      name,
      phone,
      hubspotCompanyId,
      googlePlaceId,
      deliverectLocationId,
      polarisId,
      airshipAccountId,
      airshipAccountToken,
      airshipUnitId,
    }) => ({
      name,
      phone,
      hubspotCompanyId,
      googlePlaceId,
      deliverectLocationId,
      polarisId,
      airshipAccountId,
      airshipAccountToken,
      airshipUnitId,
    }))(values);
    try {
      await dispatch(updateVenue(venueData));
      if (formikBag) {
        dispatch(fetchVenue());
        showSuccessNotification('Venue has been edited successfully');
      }
    } catch (newError) {
      showErrorNotification(getErrorMessage(newError));
      dispatch(fetchVenue());
    }
  };

  const checkSubmit = async (values) => {
    if (values.name !== initialValues.name) {
      await setTemporaryValues(values);
      setConfirmChangeNameDialogOpen(true);
    } else {
      handleOnSubmit(values, true);
    }
  };

  const submitChangeNameDialog = (values) => {
    setConfirmChangeNameDialogOpen(false);
    handleOnSubmit(values, true);
  };

  return (
    <Page loading={venueLoading} error={venueError}>
      <Formik initialValues={initialValues} validationSchema={VenueSchema}>
        {({ dirty, isValid: isFormValid, values, resetForm, errors }) => (
          <>
            <UniversalSave
              isValid={isFormValid}
              errors={errors}
              dirty={dirty}
              onSave={() => {
                checkSubmit(values);
              }}
              onDiscard={resetForm}
            />
            <Form autoComplete={`disable-${nanoid()}`}>
              <Box component="section" mb={6}>
                <BackArrow redirect={redirect} text="Settings" />
                <Box component="header" display="flex" justifyContent="space-between" mb={2}>
                  <Typography variant="h2" component="h1">
                    Venue Details
                  </Typography>
                  {/* <StatusLabel outlined status={status.SUCCESS} text={customStatusText} /> */}
                </Box>
                <Card className={classes.card}>
                  <CardContent>
                    <FormGroup className={classes.section}>
                      <Field
                        component={TextField}
                        name="name"
                        label="Venue Name"
                        variant="outlined"
                        fullWidth
                        required
                        margin="normal"
                        autoComplete={`disable-${nanoid()}`}
                      />
                      <Grid container justifyContent="space-between" alignItems="center">
                        {isAdmin() && (
                          <Grid item xs={12} sm={8}>
                            <Field
                              component={TextField}
                              name="venueId"
                              label="Venue Id"
                              variant="outlined"
                              fullWidth
                              required
                              disabled
                              margin="normal"
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      onClick={() => {
                                        copy(venue.venueId);
                                      }}
                                      size="large"
                                    >
                                      <FiCopy />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Grid>
                        )}
                        <Grid item xs={12} sm={8}>
                          <Field
                            component={TextField}
                            name="venuePath"
                            label="Live Venue Customer Menu URL"
                            variant="outlined"
                            fullWidth
                            required
                            disabled
                            margin="normal"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={() => {
                                      copy(venuePath);
                                    }}
                                    size="large"
                                  >
                                    <FiCopy />
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={4} align="right">
                          <Button
                            variant="contained"
                            color="primary"
                            align="right"
                            href={venuePath}
                            target="_blank"
                          >
                            View Live Menu
                          </Button>
                          <Button
                            className={classes.extraButton}
                            variant="contained"
                            color="primary"
                            align="right"
                            onClick={() => setQrDialogOpen(!qrDialogOpen)}
                          >
                            View QR
                          </Button>
                        </Grid>
                      </Grid>
                      <Field
                        component={TextField}
                        name="email"
                        label="Email"
                        variant="outlined"
                        fullWidth
                        required
                        disabled
                        margin="normal"
                      />
                      <Field
                        component={TextField}
                        name="phone"
                        type="tel"
                        label="Phone"
                        variant="outlined"
                        fullWidth
                        required
                        margin="normal"
                        autoComplete={`disable-${nanoid()}`}
                      />

                      {isAdmin() && (
                        <Field
                          component={TextField}
                          name="hubspotCompanyId"
                          label="Hubspot Company Id"
                          variant="outlined"
                          fullWidth
                          margin="normal"
                          autoComplete={`disable-${nanoid()}`}
                        />
                      )}

                      {isRoleAtleastOwner() && (
                        <>
                          <Field
                            component={TextField}
                            name="googlePlaceId"
                            label="Google Place Id"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                          />
                          <Link
                            // eslint-disable-next-line max-len
                            href="https://developers.google.com/maps/documentation/javascript/examples/places-placeid-finder"
                            rel="noopener"
                            target="_blank"
                            className={classes.linkTitle}
                          >
                            Get Google Place Id
                          </Link>
                        </>
                      )}

                      {isAdmin() && (
                        <>
                          <Field
                            component={TextField}
                            name="deliverectLocationId"
                            label="Deliverect Location Id"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                          />
                          <Field
                            component={TextField}
                            name="polarisId"
                            label="Polaris Id"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                          />
                          <Field
                            component={TextField}
                            name="airshipAccountId"
                            label="Airship Account Id"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                          />
                          <Field
                            component={TextField}
                            name="airshipAccountToken"
                            label="Airship Account Token"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                          />
                          <Field
                            component={TextField}
                            name="airshipUnitId"
                            label="Airship Unit Id"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                          />
                        </>
                      )}

                      <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item xs={12} sm={8}>
                          <Field
                            component={TextField}
                            name="viewMenu"
                            label="View Only Customer Menu URL"
                            variant="outlined"
                            fullWidth
                            required
                            disabled
                            margin="normal"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={() => {
                                      copy(viewMenu);
                                    }}
                                    size="large"
                                  >
                                    <FiCopy />
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={4} align="right">
                          <Button
                            className={classes.extraButton}
                            variant="contained"
                            color="primary"
                            align="right"
                            href={viewMenu}
                            target="_blank"
                          >
                            View Only Menu
                          </Button>
                        </Grid>
                      </Grid>
                    </FormGroup>
                  </CardContent>
                </Card>
              </Box>
            </Form>
          </>
        )}
      </Formik>
      <CustomDialog isDialogOpen={qrDialogOpen} handleCloseDialog={() => setQrDialogOpen(false)}>
        <Box className={classes.qrCode}>
          <QRCode value={venuePath} size={256} />
        </Box>
      </CustomDialog>
      <ChangeVenueNameDialog
        handleCloseDialog={handleCloseChangeNameDialog}
        submitDialog={submitChangeNameDialog}
        handleDialogOpen={confirmChangeNameDialogOpen}
        initialValues={initialValues}
        newValues={temporaryValues}
      />
    </Page>
  );
};

export default withVenue(VenueDetails, '/settings');
