import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { clone } from 'lodash';

import { makeStyles } from '@mui/styles';
import { Box, Button } from '@mui/material';

import { useHistory, useParams } from 'react-router';
import { MdAdd } from 'react-icons/md';
import BrandsModifierForm from '../../../components/Brands/BrandsModifierForm';
import { createBrandsModifier, fetchBrandsModifiers } from '../../../store/brands/brandsModifiers';

import { useNotifications } from '../../../shared/contexts/Notifications/useNotifications';
import { getErrorMessage } from '../../../shared/utils/errors';
import withVenue from '../../../hoc/withVenue';
import CustomDialog from '../../../components/CustomDialog';
import useRoles from '../../../hooks/useRoles';

const useStyles = makeStyles((theme) => ({
  button: {
    textWrap: 'nowrap',
    paddingLeft: '10px',
    marginLeft: 'auto',
    [theme.breakpoints.up('xl')]: {
      marginLeft: 'unset',
    },
  },
}));

const CreateModifierButton = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { itemId: modifierParamId } = useParams() || {};
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [modifierData, setModifierData] = useState(null);
  const [formAction, setFormAction] = useState('');
  const { showErrorNotification, showSuccessNotification } = useNotifications();
  const { isRoleAtLeastManager } = useRoles();

  const handleOnSubmit = async (values) => {
    const submitValues = clone(values);
    submitValues.modifierItems = submitValues.modifierItems.map((item) => ({
      itemId: item.itemId,
      itemPrice: item.itemPrice,
      multiMax: item.multiMax,
    }));
    try {
      await dispatch(createBrandsModifier(submitValues));
      showSuccessNotification('Modifier has been added successfully');
      setIsDialogOpen(false);
      dispatch(fetchBrandsModifiers());
    } catch (e) {
      showErrorNotification(getErrorMessage(e));
      setIsDialogOpen(false);
    }
  };

  const handleOpenDialog = () => {
    setModifierData(null);
    setFormAction('create');
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    if (modifierParamId) {
      history.goBack();
    }
  };

  return (
    <>
      <Box>
        <aside>
          <CustomDialog
            isDialogOpen={isDialogOpen}
            handleCloseDialog={handleCloseDialog}
            title="Create new modifier"
          >
            <BrandsModifierForm
              formAction={formAction}
              modifierData={modifierData}
              onSubmit={handleOnSubmit}
              onCancel={handleCloseDialog}
            />
          </CustomDialog>
        </aside>
      </Box>
      <Box className={classes.button}>
        <Button
          variant="contained"
          color="primary"
          disabled={!isRoleAtLeastManager()}
          onClick={handleOpenDialog}
          startIcon={<MdAdd />}
        >
          Create Modifier
        </Button>
      </Box>
    </>
  );
};

export default withVenue(CreateModifierButton);
