import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { Switch } from 'formik-mui';
import { makeStyles } from '@mui/styles';
import {
  Card,
  CardContent,
  FormGroup,
  Typography,
  Box,
  Button,
  FormControlLabel,
  Divider,
  Link,
  Grid,
} from '@mui/material';

import { reorder } from '../DragAndDrop/helpers';
import PrioritiesList from './PrioritiesList';

import receiptPreview from './receipt-preview.svg';
import UniversalSave from '../UniversalSave';
import BackArrow from '../BackArrow';
import PageHeader from '../PageHeader';
import Page from '../Page';

const useStyles = makeStyles((theme) => ({
  header: {
    marginBottom: '10px',
  },
  cards: {
    marginTop: '15px',
    marginBottom: '15px',
  },
  bottomUpdate: {
    marginTop: theme.spacing(2),
    align: 'right',
  },
  receiptPreview: {
    backgroundColor: '#d9f4ea',
    marginTop: theme.spacing(2),
  },
  receiptPreviewWrapper: {
    maxWidth: theme.spacing(30),
  },
  receiptPreviewImage: {
    width: '100%',
  },
}));

const VenuePrioritiesForm = ({
  initialValues,
  onSubmit,
  handleOpenEditDialog,
  handleOpenDeleteDialog,
}) => {
  const classes = useStyles();

  const VenueSchema = Yup.object().shape({
    enabled: Yup.boolean().required(),
    priorities: Yup.array().of(
      Yup.object().shape({
        name: Yup.string(),
        order: Yup.number(),
        priorityId: Yup.string(),
      }),
    ),
  });

  const handlePrioritiesReorder = (event, prioritiesList, setFieldValue) => {
    if (!event.destination) {
      return;
    }

    const reorderedList = reorder(prioritiesList, event.source.index, event.destination.index);

    setFieldValue('priorities', reorderedList);
  };

  return (
    <>
      <PageHeader>
        <div className={classes.header}>
          <BackArrow to="/settings" text="Settings" />
          <Typography className={classes.heading} variant="h2" component="h1">
            Courses
          </Typography>
          <Typography className={classes.heading} variant="h3" component="h3">
            Deliver food in seperate courses such as Starters, Mains and Desserts.
          </Typography>
        </div>
      </PageHeader>
      <Page>
        <Formik initialValues={initialValues} validationSchema={VenueSchema}>
          {({ setFieldValue, values, isValid, dirty, resetForm, errors }) => (
            <>
              <UniversalSave
                isValid={isValid}
                dirty={dirty}
                onSave={() => onSubmit(values)}
                onDiscard={resetForm}
                errors={errors}
              />
              <Card className={classes.cards}>
                <Form>
                  <CardContent>
                    <Box mb={2}>
                      <FormGroup>
                        <FormControlLabel
                          checked={values.check}
                          onChange={() => {
                            setFieldValue('enabled', !values.enabled);
                          }}
                          label={values.enabled ? 'Enabled' : 'Disabled'}
                          control={
                            <Field
                              component={Switch}
                              name="enabled"
                              type="checkbox"
                              size="small"
                              color="primary"
                            />
                          }
                        />
                      </FormGroup>
                    </Box>
                    <Typography variant="subtitle2" color="textSecondary">
                      Once enabled you can tag menu sections with a course on the{' '}
                      <Link href="/menus" underline="always">
                        menu page
                      </Link>
                      .
                    </Typography>
                    <Typography variant="subtitle2" color="textSecondary">
                      E.g. A section named &quot;Burgers&quot; would be tagged with Main course.
                    </Typography>
                    <Box my={2}>
                      <Divider />
                    </Box>
                    <Grid container spacing={2}>
                      {values.enabled && (
                        <Grid item sm={12} md={6}>
                          <>
                            <Typography variant="subtitle2">Priority order of courses</Typography>
                            <Box mt={2} mb={1}>
                              <Button variant="outlined" onClick={() => handleOpenEditDialog(null)}>
                                New course
                              </Button>
                            </Box>
                            <PrioritiesList
                              priorities={values.priorities}
                              handlePrioritiesReorder={handlePrioritiesReorder}
                              setFieldValue={setFieldValue}
                              handleOpenEditDialog={handleOpenEditDialog}
                              handleOpenDeleteDialog={handleOpenDeleteDialog}
                            />
                          </>
                        </Grid>
                      )}
                      <Grid item sm={12} md={values.enabled ? 6 : 8}>
                        <Box
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          className={classes.receiptPreview}
                          py={2}
                        >
                          <Box
                            display="flex"
                            flexDirection="column"
                            className={classes.receiptPreviewWrapper}
                          >
                            <Typography variant="h1" component="h2">
                              Chefs can easily prepare food courses
                            </Typography>
                            <img
                              className={classes.receiptPreview}
                              src={receiptPreview}
                              alt="Courses receipt preview"
                            />
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Form>
              </Card>
            </>
          )}
        </Formik>
      </Page>
    </>
  );
};

VenuePrioritiesForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  initialValues: PropTypes.object.isRequired,
};

export default VenuePrioritiesForm;
