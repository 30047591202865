import { pick } from 'ramda';
import { del, get, patch } from './httpRequest';

export const fetchMobiles = async () => {
  const response = await get(`/mobiles`);
  return response.data;
};

export const updateMobileDevice = async (values) => {
  const { mobileDeviceId, description, capabilities, enabled, terminalId, settings, printerId } =
    values;
  const sanitisedTerminalId = terminalId === '' ? null : terminalId;
  const sanitisedPrinterId = printerId === '' || printerId === 'NONE' ? null : printerId;

  const transformedSettings = {
    ...settings,
    serviceChargeOptionalTicked: settings.serviceChargeOptionalTicked,
  };
  // This is so that if the settings object is empty we do not send it to the BE
  if (Object.keys(transformedSettings).length > 0) {
    const response = await patch(`/mobiles/${mobileDeviceId}`, {
      data: {
        description,
        capabilities,
        enabled,
        settings: transformedSettings,
        terminalId: sanitisedTerminalId,
        printerId: sanitisedPrinterId,
      },
    });
    return response.data;
  }
  const response = await patch(`/mobiles/${mobileDeviceId}`, {
    data: {
      description,
      capabilities,
      enabled,
      terminalId: sanitisedTerminalId,
      printerId: sanitisedPrinterId,
    },
  });
  return response.data;
};

export const updateMobileDevicesAvailability = async ({ values }) => {
  const data = { mobileDevices: [] };
  values.mobileDevices.forEach((mobileDevice) => {
    data.mobileDevices.push(pick(['mobileDeviceId', 'enabled'], mobileDevice));
  });
  await patch(`/mobiles/availability`, { data });
};

export const deleteMobileDevice = async (values) => {
  const { mobileDeviceId } = values;
  const response = await del(`/mobiles/${mobileDeviceId}`);
  return response.data;
};

export const fetchMobileDevice = async (values) => {
  const { mobileDeviceId } = values;
  const response = await get(`/mobiles/${mobileDeviceId}`);
  return response.data;
};

export const fetchOnboardingDeviceKey = async () => {
  const response = await get(`/mobiles/onboarding/key`);
  return response.data;
};
