import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Field, FieldArray } from 'formik';
import { TextField } from 'formik-mui';
import { DragDropContext } from 'react-beautiful-dnd';
import RegularSwitch from '@mui/material/Switch';

import { makeStyles } from '@mui/styles';
import { MenuItem, Grid, Button, Typography, IconButton } from '@mui/material';

import { MdAddCircleOutline } from 'react-icons/md';
import { TiDelete } from 'react-icons/ti';

import { move } from 'ramda';
import Droppable from '../../DragAndDrop/Droppable';
import Draggable from '../../DragAndDrop/Draggable';
import AddBrandsOption from '../../../pages/Brands/BrandsOptions/AddBrandsOption';
import { getBrandsOptionsState } from '../../../store/brands/brandsOptions/selectors';
import { isBrandUp } from '../../../store/venues/selectors';
import CustomDialog from '../../CustomDialog';
import { fetchBrandsOptions } from '../../../store/brands/brandsOptions';

const useStyles = makeStyles((theme) => ({
  title: { marginBottom: '15px' },
  optionField: {
    paddingLeft: theme.spacing(6),
  },
  dragIcon: {
    left: 0,
  },
}));

const isOptionAlreadyShown = (testOption, itemOptions) =>
  itemOptions.some((opt) => opt.optionName === testOption.optionName);

const BrandsOptions = ({ itemOptions, allowReordering, onReorder, setFieldValue, readonly }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isBrandup = useSelector(isBrandUp);
  const { data: options } = useSelector(getBrandsOptionsState);
  const [isSizeDialogOpen, setIsSizeDialogOpen] = useState(false);
  const [updatingOption, setUpdatingOption] = useState(null);
  const [showCalories, setShowCalories] = useState(
    itemOptions.some((opt) => opt.optionCalories !== undefined),
  );
  const [newSize, setNewSize] = useState(null);
  const onDragEnd = async ({ destination, source }) => {
    if (!destination) {
      return;
    }
    setFieldValue('itemOptions', move(source.index, destination.index, itemOptions));
    onReorder({
      itemOptions: move(source.index, destination.index, itemOptions),
    });
  };

  const isOptionUsingSameKey = (form) => {
    if (form.values.itemOptions.some((opt) => opt.optionName === '')) {
      return true;
    }
    return false;
  };

  const handleCloseSizeDialog = async (localNewSize) => {
    await dispatch(fetchBrandsOptions());
    setNewSize(localNewSize);
    setIsSizeDialogOpen(false);
  };

  useEffect(() => {
    const optionIsThere = options.findIndex((e) => e.optionName === newSize?.optionName);
    if (!isSizeDialogOpen && newSize?.optionName && optionIsThere !== -1) {
      if (updatingOption) {
        setFieldValue(updatingOption, newSize?.optionName);
      }
    }
  }, [newSize, options, isSizeDialogOpen, setFieldValue, updatingOption]);

  return (
    <>
      <FieldArray name="itemOptions">
        {({ push, remove, form }) => (
          <>
            <Typography className={classes.title} variant="h4">
              Product Sizes
            </Typography>
            <Typography className={classes.title} variant="h4">
              Include Calories (kcal)
              <RegularSwitch
                disabled={readonly}
                color="primary"
                checked={showCalories}
                type="checkbox"
                onChange={(e) => setShowCalories(e.target.checked)}
              />
            </Typography>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable
                droppableId="droppable"
                type="droppable_options"
                isDropDisabled={!allowReordering}
              >
                <>
                  {itemOptions.map((itemOption, index) => (
                    <Draggable
                      key={itemOption.optionName}
                      draggableId={`option${itemOption.optionName}`}
                      index={index}
                      isDragDisabled={!allowReordering}
                      className={classes.optionField}
                      dragIconClassName={classes.dragIcon}
                    >
                      <Grid container spacing={1}>
                        <Grid item xs={12} lg={showCalories ? 4 : 5}>
                          <Field
                            disabled={readonly}
                            component={TextField}
                            select
                            fullWidth
                            name={`itemOptions.${index}.optionName`}
                            label="Size"
                            value={
                              updatingOption &&
                              newSize &&
                              updatingOption === `itemOptions.${index}.optionName`
                                ? newSize.optionName
                                : itemOption.optionName
                            }
                            variant="outlined"
                            margin="normal"
                            onChange={({ target }) => {
                              if (target.value === 'create') {
                                setIsSizeDialogOpen(true);
                                setUpdatingOption(target.name);
                              } else {
                                form.setFieldValue(`itemOptions.${index}.optionName`, target.value);
                              }
                            }}
                          >
                            {options.map((option) => (
                              <MenuItem
                                value={option.optionName}
                                disabled={readonly || isOptionAlreadyShown(option, itemOptions)}
                                key={`${option.optionName}_${option.optionId}`}
                              >
                                {option.optionName}
                              </MenuItem>
                            ))}
                            {!isBrandup && (
                              <MenuItem value="create" key="New Size">
                                + New Size
                              </MenuItem>
                            )}
                          </Field>
                        </Grid>
                        <Grid item xs={12} lg={showCalories ? 3 : 5}>
                          <Field
                            type="number"
                            component={TextField}
                            fullWidth
                            inputProps={{
                              step: '0.01',
                              min: 0,
                              onWheel: (e) => {
                                e.currentTarget.blur();
                              },
                            }}
                            name={`itemOptions.${index}.optionPrice`}
                            label="Price (£)"
                            variant="outlined"
                            margin="normal"
                            disabled={readonly}
                          />
                        </Grid>
                        {showCalories && (
                          <Grid item xs={12} lg={3}>
                            <Field
                              disabled={readonly}
                              type="number"
                              component={TextField}
                              fullWidth
                              inputProps={{ step: '1', min: 0 }}
                              name={`itemOptions.${index}.optionCalories`}
                              label="Calories (kcal)"
                              variant="outlined"
                              margin="normal"
                              onChange={({ target }) => {
                                const sanitisedValue =
                                  target.value === '' ? undefined : target.valueAsNumber;
                                form.setFieldValue(
                                  `itemOptions.${index}.optionCalories`,
                                  sanitisedValue,
                                );
                              }}
                            />
                          </Grid>
                        )}
                        <Grid
                          item
                          xs={2}
                          style={{
                            paddingTop: '25px',
                            textAlign: 'center',
                          }}
                        >
                          {itemOptions.length > 1 && (
                            <IconButton
                              disabled={readonly}
                              aria-label="delete"
                              onClick={() => {
                                remove(index);
                              }}
                              size="large"
                            >
                              <TiDelete />
                            </IconButton>
                          )}
                        </Grid>
                      </Grid>
                    </Draggable>
                  ))}
                </>
              </Droppable>
            </DragDropContext>

            {!isBrandup && (
              <Button
                variant="outlined"
                disabled={readonly || isOptionUsingSameKey(form)}
                startIcon={<MdAddCircleOutline style={{ fontSize: 30 }} />}
                onClick={() => push({ optionName: '', optionPrice: '', optionCalories: undefined })}
              >
                Add another size
              </Button>
            )}
          </>
        )}
      </FieldArray>
      <CustomDialog
        title="Add a new size"
        handleCloseDialog={handleCloseSizeDialog}
        isDialogOpen={isSizeDialogOpen}
        fullWidth
      >
        <AddBrandsOption modalSubmit={handleCloseSizeDialog} modalClose={handleCloseSizeDialog} />
      </CustomDialog>
    </>
  );
};

BrandsOptions.defaultProps = {
  allowReordering: true,
  onReorder: null,
};

BrandsOptions.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  itemOptions: PropTypes.array.isRequired,
  allowReordering: PropTypes.bool,
  onReorder: PropTypes.func,
};

export default BrandsOptions;
