import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { makeStyles } from '@mui/styles';
import { Box, Button } from '@mui/material';

import { createCategory, fetchCategories } from '../../store/categories';
import { isDeliveryOnly } from '../../store/venues/selectors';

import { useNotifications } from '../../shared/contexts/Notifications/useNotifications';
import { getErrorMessage } from '../../shared/utils/errors';
import withVenue from '../../hoc/withVenue';
import CustomDialog from '../../components/CustomDialog';
import CategoryForm from '../../components/CategoryForm';
import useRoles from '../../hooks/useRoles';

const useStyles = makeStyles(() => ({
  buttonClass: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginBottom: '10px',
  },
}));

const NewCategoryButton = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [categoryData, setCategoryData] = useState(null);
  const [formAction, setFormAction] = useState('');
  const { showErrorNotification, showSuccessNotification } = useNotifications();
  const { isRoleAtLeastManager, isAdmin } = useRoles();
  const typeDeliveryOnly = useSelector(isDeliveryOnly);

  const permissionReadOnly = !isAdmin() && typeDeliveryOnly;

  const handleOnSubmit = async (values) => {
    try {
      await dispatch(createCategory(values));
      showSuccessNotification('Category has been added successfully');
      setIsDialogOpen(false);
      dispatch(fetchCategories());
    } catch (e) {
      showErrorNotification(getErrorMessage(e));
      setIsDialogOpen(false);
    }
  };

  const handleOpenDialog = () => {
    setCategoryData(null);
    setFormAction('create');
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const CategoryOptionForm = () => (
    <CategoryForm
      formAction={formAction}
      categoryData={categoryData}
      onSubmit={handleOnSubmit}
      onCancel={handleCloseDialog}
    />
  );

  return (
    <>
      <Box className={classes.buttonClass}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleOpenDialog}
          disabled={!isRoleAtLeastManager() || permissionReadOnly}
        >
          New Category
        </Button>
      </Box>
      <Box>
        <aside>
          <CustomDialog
            isDialogOpen={isDialogOpen}
            handleCloseDialog={handleCloseDialog}
            title="Create new category"
          >
            <CategoryOptionForm />
          </CustomDialog>
        </aside>
      </Box>
    </>
  );
};

export default withVenue(NewCategoryButton);
