import { pickBy } from 'lodash';

const sanitiseUpdateMenuGroupsPayload = (payload, currentMenuGroup) => {
  const { values } = payload;
  const sanitisedCurrentMenuGroup = { ...currentMenuGroup };
  const sanitisedValues = pickBy(values, (value) => value !== undefined);

  if (!values.groupColor) {
    delete sanitisedCurrentMenuGroup.groupColor;
  }

  const finalPayload = {
    ...sanitisedCurrentMenuGroup,
    ...sanitisedValues,
  };

  return finalPayload;
};

export default sanitiseUpdateMenuGroupsPayload;
