import React, { useRef } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@mui/material';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { sendOrderEmail } from '../../api/orders';

const EmailReceiptDialog = ({ open, onClose, orderId, orderDisplayId }) => {
  const formikRef = useRef();
  const SendEmailSchema = Yup.object().shape({
    email: Yup.string().email('Email must be valid').required('This field is required'),
  });

  const handleSendEmail = async (values) => {
    const { email } = values;
    try {
      await sendOrderEmail({ orderId, email });
      onClose();
    } catch (error) {
      formikRef.current.setFieldError('email', 'Failed to send email');
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <Formik
        initialValues={{ email: '' }}
        validationSchema={SendEmailSchema}
        onSubmit={(values) => handleSendEmail(values)}
        innerRef={formikRef}
      >
        {({ errors, touched, handleBlur, handleChange, values }) => (
          <Form>
            <DialogTitle>Email Receipt</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Send the receipt for order <b>{orderDisplayId}</b> to the following email address:
              </DialogContentText>

              <TextField
                autoFocus
                required
                margin="dense"
                id="name"
                name="email"
                label="Email Address"
                type="email"
                fullWidth
                variant="standard"
                helperText={errors.email && touched.email && errors.email}
                error={errors.email && touched.email}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.email}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose}>Cancel</Button>
              <Button type="submit">Send</Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default EmailReceiptDialog;
