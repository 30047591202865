import React from 'react';
import { useSelector } from 'react-redux';
import { Button } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { getQrcodes } from '../../store/qrcodes/selectors';
import useRoles from '../../hooks/useRoles';

const QrcodesTable = ({ handlePrintQrCode, handleRemoveQrCode }) => {
  const { isAdmin } = useRoles();
  const qrcodes = useSelector(getQrcodes);
  if (!qrcodes) return null;
  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Id</TableCell>
            <TableCell>Url</TableCell>
            <TableCell>Created At</TableCell>
            <TableCell>Print</TableCell>
            <TableCell>Hi-Res</TableCell>
            {isAdmin() && <TableCell> </TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {qrcodes.map((qrcode) => (
            <TableRow key={qrcode.qrCodeId}>
              <TableCell component="th" scope="row">
                {qrcode.qrCodeId}
              </TableCell>
              <TableCell>{qrcode.url}</TableCell>
              <TableCell>{qrcode.createdAt}</TableCell>
              <TableCell>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handlePrintQrCode(qrcode.qrCodeId, false)}
                >
                  Download QR Code
                </Button>
              </TableCell>
              <TableCell>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handlePrintQrCode(qrcode.qrCodeId, true)}
                >
                  Download QR Code HI-Res
                </Button>
              </TableCell>

              {isAdmin() && (
                <TableCell>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleRemoveQrCode(qrcode.qrCodeId)}
                  >
                    Remove
                  </Button>
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default QrcodesTable;
