import React from 'react';
import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';
import Page from '../../components/Page';
import PageHeader from '../../components/PageHeader';
import BackArrow from '../../components/BackArrow';
import DeliveryStatementsTable from '../../components/DeliveryStatementsTable';

const useStyles = makeStyles((theme) => ({
  title: {
    ...theme.customFonts.largeBold,
    marginBottom: '16px',
  },
}));

const DeliveryStatements = () => {
  const classes = useStyles();

  return (
    <>
      <PageHeader>
        <BackArrow text="Business" path="/settings/business" />
        <Typography className={classes.title}>Sessions delivery proceeds reports</Typography>
      </PageHeader>
      <Page>
        <DeliveryStatementsTable />
      </Page>
    </>
  );
};

export default DeliveryStatements;
