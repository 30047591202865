import React from 'react';
import { useSelector } from 'react-redux';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Typography } from '@mui/material';
import { nanoid } from 'nanoid';

import { getDebitsState } from '../../store/debits/selectors';
import TableCellStatus, { tableCellStatus } from '../TableCellStatus';
import TableSkeletonLoader from '../TableSkeletonLoader';

const DebitsTable = () => {
  const { loading, data: debits } = useSelector(getDebitsState);

  if (!debits) return null;

  const diplayOrders = debits?.docs || debits;
  const hasDebits = diplayOrders.length > 0;

  const DebitStatus = {
    true: <TableCellStatus status={tableCellStatus.SUCCESS} statusText="Charged" />,
    false: <TableCellStatus status={tableCellStatus.IDLE} statusText="Pending" />,
  };

  if (!debits) return null;

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell size="small">
              <Typography variant="h3">Id</Typography>
            </TableCell>
            <TableCell size="small">
              <Typography variant="h3">Amount</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="h3">Reason</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="h3">Status</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        {loading && <TableSkeletonLoader rows={10} cells={3} cellsSize={['small']} />}
        {!loading && hasDebits && (
          <>
            <TableBody>
              {diplayOrders.map(({ amount, exhausted, reason, id: debitId }) => {
                const key = nanoid();
                const displayAmount = amount.toLocaleString();

                return (
                  <TableRow key={key}>
                    <TableCell>{debitId}</TableCell>
                    <TableCell>£{displayAmount}</TableCell>
                    <TableCell>{reason}</TableCell>
                    <TableCell>{DebitStatus[exhausted]}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </>
        )}
      </Table>
    </TableContainer>
  );
};

export default DebitsTable;
