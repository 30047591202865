import { createSlice } from '@reduxjs/toolkit';
import {
  createAdjustmentApi,
  deleteAdjustmentApi,
  fetchAdjustmentsApi,
  fetchAdjustmentApi,
  patchAdjustmentApi,
  fetchAdjustmentLogApi,
  deactivateAdjustmentApi,
} from '../../api/adjustments';
import {
  createAsyncAction,
  createAsyncReducerHandlers,
  asyncInitialState as initialState,
} from '../utils/async';

export const fetchAdjustments = createAsyncAction('fetch-adjustments', fetchAdjustmentsApi);

const adjustmentsSlice = createSlice({
  name: 'adjustments',
  initialState,
  reducers: {
    clearAdjustments(state) {
      delete state.data;
    },
  },
  extraReducers: {
    ...createAsyncReducerHandlers(fetchAdjustments),
  },
});

export const { clearAdjustments } = adjustmentsSlice.actions;
export const { reducer: adjustments } = adjustmentsSlice;

export const fetchAdjustment = createAsyncAction('fetch-adjustment', fetchAdjustmentApi);

export const createAdjustment = createAsyncAction('create-adjustment', createAdjustmentApi);

export const patchAdjustment = createAsyncAction('patch-adjustment', patchAdjustmentApi);

export const deleteAdjustment = createAsyncAction('delete-adjustment', deleteAdjustmentApi);

export const deactivateAdjustment = createAsyncAction(
  'deactivate-adjustment',
  deactivateAdjustmentApi,
);

const adjustmentSlice = createSlice({
  name: 'adjustment',
  initialState,
  reducers: {
    clearAdjustment(state) {
      delete state.data;
    },
  },
  extraReducers: {
    ...createAsyncReducerHandlers(fetchAdjustment),
    ...createAsyncReducerHandlers(createAdjustment),
    ...createAsyncReducerHandlers(deleteAdjustment),
    ...createAsyncReducerHandlers(patchAdjustment),
    ...createAsyncReducerHandlers(deactivateAdjustment),
  },
});

export const { clearAdjustment } = adjustmentSlice.actions;
export const { reducer: adjustment } = adjustmentSlice;

export const fetchAdjustmentLog = createAsyncAction('fetch-adjustment-log', fetchAdjustmentLogApi);

const adjustmentLogSlice = createSlice({
  name: 'adjustmentLog',
  initialState,
  reducers: {
    clearAdjustmentLog(state) {
      delete state.data;
    },
  },
  extraReducers: {
    ...createAsyncReducerHandlers(fetchAdjustmentLog),
  },
});

export const { clearAdjustmentLog } = adjustmentLogSlice.actions;
export const { reducer: adjustmentLog } = adjustmentLogSlice;
