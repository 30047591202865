import React, { useEffect, useState } from 'react';
import { Box, Typography, Button, Alert } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { CheckCircleOutline } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import PageHeader from '../../../components/PageHeader';
import BackArrow from '../../../components/BackArrow';
import Page from '../../../components/Page';
import {
  clearAdjustment,
  clearAdjustmentLog,
  deactivateAdjustment,
  deleteAdjustment,
  fetchAdjustment,
  patchAdjustment,
} from '../../../store/adjustments';
import { useNotifications } from '../../../shared/contexts/Notifications/useNotifications';
import { getErrorMessage } from '../../../shared/utils/errors';
import { getAdjustmentState } from '../../../store/adjustments/selectors';
import shouldLoad from '../../../shared/utils/shouldLoad';
import AdjustmentForm from './AdjustmentForm';
import AdjustmentLog from './AdjustmentLog';
import withVenue from '../../../hoc/withVenue';
import useRoles from '../../../hooks/useRoles';

const calculateAmount = ({ amount, vatIncluded }) => {
  if (vatIncluded) {
    // Amount field needs to display the amount pre-VAT
    return Number(amount / 1.2).toFixed(2);
  }
  return amount;
};

const useStyles = makeStyles((theme) => ({
  successBanner: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 24,
    backgroundColor: '#87EB80',
    borderRadius: 8,
    padding: 16,
    color: '#000',
  },
  successBannerText: {
    ...theme.customFonts.small,
    marginLeft: 12,
  },
}));

const Adjustment = () => {
  const { isSuperAdmin } = useRoles();
  const classes = useStyles();
  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const { showErrorNotification, showSuccessNotification } = useNotifications();

  const { loading, data: adjustment } = useSelector(getAdjustmentState);
  const [displaySuccessBanner, setDisplaySuccessBanner] = useState(null);

  useEffect(() => {
    if (shouldLoad(getAdjustmentState) && params.adjustmentId) {
      dispatch(fetchAdjustment(params.adjustmentId));
    }
  }, [dispatch, params.adjustmentId]);

  const handleDelete = async () => {
    try {
      await dispatch(deleteAdjustment(params.adjustmentId));
      showSuccessNotification('Adjustment was deleted successfully');
      history.push('/admin/delivery-adjustments');
    } catch (e) {
      showErrorNotification(getErrorMessage(e));
    }
  };

  const handleDeactivate = async () => {
    try {
      await dispatch(deactivateAdjustment(params.adjustmentId));
      showSuccessNotification('Adjustment was deactivated successfully');
      await dispatch(fetchAdjustment(params.adjustmentId));
    } catch (e) {
      showErrorNotification(getErrorMessage(e));
    }
  };

  const handleSubmit = async (values) => {
    try {
      await dispatch(patchAdjustment(params.adjustmentId, values));
      await dispatch(fetchAdjustment(params.adjustmentId));
      setDisplaySuccessBanner('Adjustment was updated successfully');
    } catch (e) {
      showErrorNotification(getErrorMessage(e));
    }
  };

  if (loading || !adjustment) return null;

  return (
    <>
      <PageHeader>
        <BackArrow to="/admin/delivery-adjustments" text="Adjustments" />
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <Typography variant="h1">{adjustment?.name || adjustment?.option}</Typography>
          {adjustment.isDeletable && (
            <Button
              variant="outlined"
              onClick={() => handleDelete()}
              disabled={adjustment?.type === 'PAYMENT' && !isSuperAdmin()}
            >
              Delete
            </Button>
          )}
          {adjustment?.isDeactivatable && (
            <Button
              variant="outlined"
              onClick={() => handleDeactivate()}
              disabled={adjustment?.type === 'PAYMENT' && !isSuperAdmin()}
            >
              Deactivate
            </Button>
          )}
        </Box>
        {displaySuccessBanner && (
          <Box className={classes.successBanner}>
            <CheckCircleOutline />
            <Typography variant="body1" className={classes.successBannerText}>
              {displaySuccessBanner}
            </Typography>
          </Box>
        )}
        {adjustment?.type === 'PAYMENT' && !isSuperAdmin() && (
          <Alert severity="info" sx={{ mt: 2 }}>
            Only SuperAdmins can make changes
          </Alert>
        )}
      </PageHeader>
      <Page>
        {adjustment.isEditable ? (
          <AdjustmentForm
            initialValues={{
              option: adjustment.option,
              name: adjustment.name,
              type: adjustment.type,
              schedule: adjustment.schedule,
              startDate: adjustment.startDate,
              vatIncluded: adjustment.vatIncluded,
              timesDue: adjustment.timesDue,
              description: adjustment.description || null,
              amount: calculateAmount({
                amount: adjustment.amount,
                vatIncluded: adjustment.vatIncluded,
              }),
            }}
            adjustmentCreated={{
              createdAt: adjustment.createdAt,
              createdBy: adjustment.createdBy,
            }}
            handleSubmit={handleSubmit}
            mode="EDIT"
          />
        ) : (
          <AdjustmentLog adjustmentId={params.adjustmentId} />
        )}
      </Page>
    </>
  );
};

export default withVenue(Adjustment, null, [clearAdjustment, clearAdjustmentLog]);
