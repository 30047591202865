import { createSelector } from '@reduxjs/toolkit';

export const getCardPayoutsState = (state) => state.cardPayouts;
export const getReportsState = (state) => state.reports;

export const getCardPayouts = createSelector(
  getCardPayoutsState,
  (payoutsState) => payoutsState.data,
);

export const getReports = createSelector(getReportsState, (reportsState) => reportsState.data);
