/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { move } from 'ramda';
import { nanoid } from 'nanoid';

import { denormalizeMenuItems, normalizeMenuItems } from '../utils/normalize';
import {
  createAsyncAction,
  asyncInitialState as initialState,
  createAsyncReducerHandlers,
} from '../utils/async';
import {
  fetchVenueMenu as fetchVenueMenuApi,
  updateVenueMenuItems as updateVenueMenuItemsApi,
} from '../../api/venueMenu';
import sanitiseUpdateMenuGroupsPayload from './sanitiseUpdateMenuGroupsPayload';
import sanitiseCreateMenuGroupsPayload from './sanitiseCreateMenuGroupsPayload';

export const fetchVenueMenuItems = createAsyncAction('fetch-venue-menu-items', async (menuName) => {
  const response = await fetchVenueMenuApi(menuName);

  return normalizeMenuItems(response);
});

export const updateVenueMenuItems = createAsyncAction(
  'update-venue-menu-items',
  async (_, getState) => {
    const venueMenuItems = getState().venueMenuItems.data;

    const response = await updateVenueMenuItemsApi(
      venueMenuItems.name,
      denormalizeMenuItems(venueMenuItems),
    );
    return normalizeMenuItems(response);
  },
);

const venueMenuItemsSlice = createSlice({
  name: 'venue-menu-items',
  initialState,
  reducers: {
    clearVenueMenuItems(state) {
      delete state.data;
    },
    setVenueMenuItems: (state, { payload }) => {
      state.data = normalizeMenuItems(payload);
    },
    createMenuGroup(state, { payload }) {
      if (state.data) {
        const groupId = nanoid();
        const sanitisedPayload = sanitiseCreateMenuGroupsPayload(payload);

        state.data.menuGroups[groupId] = {
          ...sanitisedPayload,
          groupItems: [],
        };
        state.data.menuGroupsList.push(groupId);
      }
    },
    updateMenuGroup(state, { payload }) {
      if (state.data) {
        const currentMenuGroup = state.data.menuGroups[payload.groupId];
        const sanitisedPayload = sanitiseUpdateMenuGroupsPayload(payload, currentMenuGroup);

        state.data.menuGroups[payload.groupId] = sanitisedPayload;
      }
    },
    deleteMenuGroup(state, { payload }) {
      if (state.data) {
        const delIndex = state.data.menuGroupsList.findIndex((elem) => elem === payload.groupId);
        state.data.menuGroupsList.splice(delIndex, 1);
      }
    },
    moveMenuGroup(state, { payload }) {
      if (state.data) {
        state.data.menuGroupsList = move(payload.from, payload.to, state.data.menuGroupsList);
      }
    },
    createMenuGroupItem(state, { payload }) {
      if (state.data) {
        state.data.menuGroups[payload.groupId].groupItems.push(payload.itemId);
      }
    },
    deleteMenuGroupItem(state, { payload }) {
      if (state.data) {
        state.data.menuGroups[payload.groupId].groupItems.splice(payload.itemIndex, 1);
      }
    },
    updateMenuGroupItems(state, { payload }) {
      if (state.data) {
        state.data.menuGroups[payload.groupId].groupItems = payload.items;
      }
    },
    moveMenuGroupItem(state, { payload }) {
      if (state.data) {
        state.data.menuGroups[payload.groupId].groupItems = move(
          payload.from,
          payload.to,
          state.data.menuGroups[payload.groupId].groupItems,
        );
      }
    },
    updateMenuSchedule(state, { payload }) {
      if (state.data) {
        state.data.schedules[payload.groupId] = payload.days;
      }
    },
  },
  extraReducers: {
    ...createAsyncReducerHandlers(fetchVenueMenuItems),
    ...createAsyncReducerHandlers(updateVenueMenuItems),
  },
});

export const {
  createMenuGroup,
  updateMenuGroup,
  moveMenuGroup,
  createMenuGroupItem,
  deleteMenuGroupItem,
  deleteMenuGroup,
  updateMenuGroupItems,
  moveMenuGroupItem,
  updateMenuSchedule,
  setVenueMenuItems,
  clearVenueMenuItems,
} = venueMenuItemsSlice.actions;

export const { reducer: venueMenuItems } = venueMenuItemsSlice;
