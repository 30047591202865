import React, { useEffect, useState } from 'react';
import { Button, Card, CardActions, CardContent } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { fetchVenue, setVenue } from '../../store/venues';
import { getVenuesState } from '../../store/venues/selectors';
import CustomDialog from '../CustomDialog';

const useStyles = makeStyles(() => ({
  cardActions: {
    justifyContent: 'flex-end',
  },
}));

const VenueWatcher = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [isChangedDialogOpen, setIsChangedDialogOpen] = useState(false);
  const [venueChangeMessage, setVenueChangeMessage] = useState('');
  const { data: venues } = useSelector(getVenuesState);

  const handleCloseDialog = () => {
    setIsChangedDialogOpen(false);
  };

  useEffect(() => {
    const handleExternalVenueChange = async (storageEvent) => {
      if (storageEvent.key === 'venue_id') {
        const { newValue: newVenueId, oldValue: oldVenueId } = storageEvent;
        const oldVenue = venues.find((ven) => ven.venueId === oldVenueId);
        const newVenue = venues.find((ven) => ven.venueId === newVenueId);
        await dispatch(setVenue(newVenue));
        dispatch(fetchVenue());
        setVenueChangeMessage(`Venue has changed from "${oldVenue?.name}" to "${newVenue?.name}"`);
        setIsChangedDialogOpen(true);
      }
    };
    window.addEventListener('storage', handleExternalVenueChange, false);
    return () => {
      window.removeEventListener('storage', handleExternalVenueChange);
    };
  }, [dispatch, venues]);

  return (
    <CustomDialog
      title="Venue Changed"
      isDialogOpen={isChangedDialogOpen}
      handleCloseDialog={handleCloseDialog}
    >
      <Card>
        <CardContent>{venueChangeMessage}</CardContent>
        <CardActions className={classes.cardActions}>
          <Button variant="contained" color="primary" onClick={handleCloseDialog}>
            OK
          </Button>
        </CardActions>
      </Card>
    </CustomDialog>
  );
};
export default VenueWatcher;
