import { Dialog } from '@mui/material';
import React from 'react';
import MenuItem from './MenuItem';
import BrandsMenuItem from '../Brands/BrandsMenuItems/BrandsMenuItem';

const MenuItemDialog = ({ handleOpen, isOpen, itemId, isBrandsBuilder = false }) => (
  <Dialog open={isOpen} onClose={handleOpen} fullWidth maxWidth="xl">
    {isBrandsBuilder ? (
      <BrandsMenuItem itemId={itemId} showCloseButton handleDialogOpen={handleOpen} />
    ) : (
      <MenuItem itemId={itemId} showCloseButton handleDialogOpen={handleOpen} />
    )}
  </Dialog>
);
export default MenuItemDialog;
