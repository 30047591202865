import React from 'react';
import { Grid } from '@mui/material';

import { withStyles } from 'tss-react/mui';

import ratingsFilters from './ratingsFilters';
import { LozengeRadioLabel, LozengeRadio } from '../../components/LozengeRadio';

const RatingsFilterGrid = withStyles(Grid, (theme) => ({
  root: {
    marginBottom: theme.spacing(2),
    flexWrap: 'noWrap',
  },
}));

const RatingsFilter = ({ selectedFilter }) => (
  <RatingsFilterGrid container spacing={1}>
    {ratingsFilters.map(({ value, label, icon }) => {
      const selected = `${value}` === selectedFilter;

      return (
        <Grid item key={value}>
          <LozengeRadioLabel
            selected={selected}
            value={value}
            control={<LozengeRadio icon={icon} />}
            label={label}
          />
        </Grid>
      );
    })}
  </RatingsFilterGrid>
);

export default RatingsFilter;
