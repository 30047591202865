import { createSlice } from '@reduxjs/toolkit';
import {
  asyncInitialState as initialState,
  createAsyncAction,
  createAsyncReducerHandlers,
} from '../utils/async';
import fetchReportsSalesByAreaApi from '../../api/reports/salesByArea';

export const fetchReportSalesByArea = createAsyncAction(
  'fetch-sales-areas',
  fetchReportsSalesByAreaApi,
);

const reportSalesByAreaSlice = createSlice({
  name: 'sales-areas',
  initialState,
  reducers: {
    clearReportSalesByArea(state) {
      delete state.data;
    },
  },
  extraReducers: createAsyncReducerHandlers(fetchReportSalesByArea),
});

export const { clearReportSalesByArea } = reportSalesByAreaSlice.actions;

export const { reducer: reportSalesByArea } = reportSalesByAreaSlice;
