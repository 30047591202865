import React from 'react';
import { Button, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { union } from 'lodash';

const TableWarningMultiple = ({ missing, classes, isAdmin, showDevicesLink }) => (
  <>
    <Typography className={classes.title} variant="h2" component="h1">
      {isAdmin
        ? ` Multiple table assignments:
            One or more tables are assigned to more than one device,
            please ensure 1-1 pairings for both food and drinks.
            [${union(missing.multipleFoods, missing.multipleDrinks).toString()}]`
        : ` Multiple table assignments.
            One or more tables are assigned to more than one device and will not be available to customers.
            Please contact support to ensure each table only has one destination for food and drinks.`}
    </Typography>

    {isAdmin && showDevicesLink && (
      <aside>
        <Button
          color="primary"
          size="small"
          variant="contained"
          rel="noopener noopener noreferrer"
          component={Link}
          to="/devices"
        >
          Devices
        </Button>
      </aside>
    )}
  </>
);

export default TableWarningMultiple;
