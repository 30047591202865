import React from 'react';
import PropTypes from 'prop-types';

import { Card, CardActions, CardContent, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import useRoles from '../../hooks/useRoles';
import CustomDialog from '../CustomDialog';

const useStyles = makeStyles(() => ({
  heading: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

const DeleteVenueManagerDialog = ({
  handleCloseDialog,
  handleDialogOpen,
  submitDialog,
  managerEmail,
}) => {
  const classes = useStyles();
  const { isSuperAdmin } = useRoles();

  return (
    <CustomDialog
      title="Are you sure?"
      isDialogOpen={handleDialogOpen}
      handleCloseDialog={handleCloseDialog}
    >
      <Card className={classes.card}>
        <CardContent>
          <b>{managerEmail}</b> is going to be deleted from the{' '}
          {isSuperAdmin() ? 'the system; ALL VENUES' : 'venue'}!
          <br />
          This action is irreversible!
        </CardContent>
        <CardActions className={classes.cardActions}>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={() => submitDialog(managerEmail)} variant="contained" color="primary">
            Delete Manager
          </Button>
        </CardActions>
      </Card>
    </CustomDialog>
  );
};

DeleteVenueManagerDialog.propTypes = {
  handleCloseDialog: PropTypes.func.isRequired,
  handleDialogOpen: PropTypes.bool.isRequired,
  submitDialog: PropTypes.func.isRequired,
  managerEmail: PropTypes.string.isRequired,
};

export default DeleteVenueManagerDialog;
