import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import Page from '../../components/Page';
import withVenue from '../../hoc/withVenue';
import { getStaffState } from '../../store/staff/selectors';
import { clearStaff, fetchStaff, updateStaff, deleteStaff, fetchAllStaff } from '../../store/staff';
import EditStaffForm from '../../components/EditStaffForm';
import { useNotifications } from '../../shared/contexts/Notifications/useNotifications';
import { getErrorMessage } from '../../shared/utils/errors';
import shouldLoad from '../../shared/utils/shouldLoad';
import BackArrow from '../../components/BackArrow';
import TableLink from '../../components/TableLink';
import CustomDialog from '../../components/CustomDialog';

const useStyles = makeStyles((theme) => ({
  heading: {
    margin: '15px 0',
  },
  id: {
    color: theme.customPalette.greyDark,
  },
  dialogContent: {
    margin: '1rem',
  },
  deleteButton: {
    color: theme.palette.getContrastText(theme.customPalette.destructiveRed),
    backgroundColor: theme.customPalette.destructiveRed,
  },
}));

const EditStaff = ({ redirect }) => {
  const { staffId } = useParams();
  const dispatch = useDispatch();
  const classes = useStyles();
  const staffState = useSelector(getStaffState);
  const { data, loading, error } = staffState;
  const { showErrorNotification, showSuccessNotification } = useNotifications();
  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false);
  const { name } = data || {};

  const handleCloseDialog = () => {
    setShowDeleteDialog(false);
  };

  useEffect(() => {
    if (shouldLoad(staffState)) dispatch(fetchStaff({ staffId }));
  }, [dispatch, staffId, staffState]);

  const onSubmit = async (updatedStaff) => {
    const newCapabilities = Object.entries(updatedStaff.capabilityArray).reduce(
      (newCapabilityList, capability) =>
        capability[1] ? [...newCapabilityList, capability[0]] : newCapabilityList,
      [],
    );
    const transformedUpdatedStaff = {
      ...updatedStaff,
      capabilities: newCapabilities,
    };
    try {
      if (transformedUpdatedStaff.description === '') {
        delete transformedUpdatedStaff.description;
      }
      await dispatch(updateStaff(transformedUpdatedStaff));
      showSuccessNotification('Staff member has been updated successfully');
    } catch (localError) {
      showErrorNotification(getErrorMessage(localError));
    }
  };
  const removeCurrentStaff = async () => {
    try {
      await dispatch(deleteStaff({ staffId }));
      dispatch(fetchAllStaff());
      showSuccessNotification('Staff member has been removed successfully');
      await dispatch(fetchAllStaff());
      redirect();
    } catch (err) {
      dispatch(fetchAllStaff());
      showErrorNotification(getErrorMessage(err));
    }
  };

  return (
    <>
      <Page loading={loading} error={error}>
        <header>
          <BackArrow to="/staff" text="Team" />
          <Typography className={classes.heading} variant="h2" component="h1">
            {name}
          </Typography>
          <p className={classes.id}>Member ID: {staffId}</p>
        </header>
        {data && <EditStaffForm staffData={data} onSubmit={onSubmit} />}
        <br />
        <TableLink onClick={() => setShowDeleteDialog(true)}>Delete member</TableLink>
      </Page>
      <CustomDialog
        title="Delete this member?"
        isDialogOpen={showDeleteDialog}
        handleCloseDialog={handleCloseDialog}
        actions={
          <>
            <Button
              className={classes.dialogButton}
              variant="outlined"
              onClick={() => handleCloseDialog()}
            >
              Cancel
            </Button>
            <Button
              onClick={() => removeCurrentStaff()}
              className={classes.deleteButton}
              variant="contained"
            >
              Delete member
            </Button>
          </>
        }
      >
        <div className={classes.dialogContent}>
          <p>This will permanently remove this member.</p>{' '}
          <p>
            If you want to temporarily remove this member, you can deactivate them on the Team page.
          </p>
        </div>
      </CustomDialog>
    </>
  );
};

export default withVenue(EditStaff, '/staff', clearStaff);
