const customFonts = {
  label: {
    fontFamily: 'Sen',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '18px',
  },
  xSmall: {
    fontFamily: 'Sen',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '13px',
    lineHeight: '20px',
  },
  small: {
    fontFamily: 'Sen',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '21px',
  },
  medium: {
    fontFamily: 'Sen',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '24px',
  },
  large: {
    fontFamily: 'Sen',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '20px',
    lineHeight: '30px',
  },
  xLarge: {
    fontFamily: 'Sen',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '28px',
    lineHeight: '42px',
  },
  xxLarge: {
    fontFamily: 'Sen',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '40px',
    lineHeight: '60px',
  },
  xSmallBold: {
    fontFamily: 'Sen',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '13px',
    lineHeight: '20px',
  },
  smallBold: {
    fontFamily: 'Sen',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '21px',
  },
  mediumBold: {
    fontFamily: 'Sen',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '24px',
  },
  largeBold: {
    fontFamily: 'Sen',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '20px',
    lineHeight: '30px',
  },
  xLargeBold: {
    fontFamily: 'Sen',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '28px',
    lineHeight: '42px',
  },
  xxLargeBold: {
    fontFamily: 'Sen',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '40px',
    lineHeight: '60px',
  },
};

export default customFonts;
