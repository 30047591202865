import { createSlice } from '@reduxjs/toolkit';
import {
  asyncInitialState as initialState,
  createAsyncAction,
  createAsyncReducerHandlers,
} from '../../utils/async';
import {
  fetchBrandsCategoriesApi,
  fetchBrandsCategoryApi,
  deleteBrandsCategoryApi,
  createBrandsCategoryApi,
  updateBrandsCategoryApi,
} from '../../../api/brands/brandsCategories';

export const fetchBrandsCategories = createAsyncAction(
  'fetch-brands-categories',
  fetchBrandsCategoriesApi,
);

export const fetchBrandsCategory = createAsyncAction(
  'fetch-brands-category',
  fetchBrandsCategoryApi,
);

export const deleteBrandsCategory = createAsyncAction(
  'delete-brands-category',
  deleteBrandsCategoryApi,
);

export const createBrandsCategory = createAsyncAction(
  'create-brands-category',
  createBrandsCategoryApi,
);

export const updateBrandsCategory = createAsyncAction(
  'update-brands-category',
  updateBrandsCategoryApi,
);

const categoriesSlice = createSlice({
  name: 'brandsCategories',
  initialState,
  reducers: {},
  extraReducers: {
    ...createAsyncReducerHandlers(fetchBrandsCategories),
    ...createAsyncReducerHandlers(deleteBrandsCategory),
  },
});

const categorySlice = createSlice({
  name: 'brandsCategory',
  initialState,
  reducers: {},
  extraReducers: {
    ...createAsyncReducerHandlers(fetchBrandsCategory),
    ...createAsyncReducerHandlers(createBrandsCategory),
    ...createAsyncReducerHandlers(updateBrandsCategory),
  },
});

export const { reducer: brandsCategories } = categoriesSlice;
export const { reducer: brandsCategory } = categorySlice;
