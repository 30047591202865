import { get, patch } from './httpRequest';
import { convertPoundsToPence } from '../shared/utils/moneyConversion';

export const fetchVenueFees = async () => {
  const response = await get('/venue-fees');

  return response.data;
};

export const updateVenueFees = async (items, brandPrefixes) => {
  const transactionsFees = {
    card: {
      fixed: convertPoundsToPence(items.transactionsCardFixed),
      percentFee: items.transactionsCardPercent,
    },
    amexInternational: {
      fixed: convertPoundsToPence(items.transactionsAmexInternationalFixed),
      percentFee: items.transactionsAmexInternationalPercent,
    },
    cashAndExternal: {
      fixed: convertPoundsToPence(items.transactionsCashAndExternalFixed),
      percentFee: items.transactionsCashAndExternalPercent,
    },
  };

  const generateFees = (prefixes, feeType) => {
    const fees = {};
    prefixes.forEach((prefix) => {
      fees[prefix] = {
        deliveroo: items[`${feeType}${prefix}Deliveroo`],
        ubereats: items[`${feeType}${prefix}UberEats`],
        justeat: items[`${feeType}${prefix}JustEat`],
      };
    });
    return fees;
  };

  const deliveryFees = {
    defaultFee: {
      deliveroo: items.deliveryFeesDefaultDeliveroo,
      justeat: items.deliveryFeesDefaultJustEat,
      ubereats: items.deliveryFeesDefaultUberEats,
    },
    ...generateFees(brandPrefixes, 'deliveryFees'),
  };

  const deliveryPickupFees = {
    defaultFee: {
      deliveroo: items.deliveryPickupFeesDefaultDeliveroo,
      justeat: items.deliveryPickupFeesDefaultJustEat,
      ubereats: items.deliveryPickupFeesDefaultUberEats,
    },
    ...generateFees(brandPrefixes, 'deliveryPickupFees'),
  };

  const inhouseSessionsVenuesFees = {
    active: items.inhouseVenueFeeActive,
    food: items.inhouseVenueFeeFood,
    drink: items.inhouseVenueFeeDrink,
  };

  const inhouseSessionsBrandsFees = {
    defaultFee: {
      food: items.inhouseBrandDefaultFood,
      drink: items.inhouseBrandDefaultDrink,
    },
  };
  brandPrefixes.forEach((prefix) => {
    inhouseSessionsBrandsFees[prefix] = {
      food: items[`inhouseBrand${prefix}Food`],
      drink: items[`inhouseBrand${prefix}Drink`],
    };
  });

  const franchiseMarketingFee = {
    allProducts: items.franchiseMarketingAllProducts,
    defaultFee: items.franchiseMarketingItemFee,
  };

  const menuItems = await patch(`/venue-fees`, {
    data: {
      transactionsFees,
      deliveryFees,
      deliveryPickupFees,
      inhouseSessionsBrandsFees,
      inhouseSessionsVenuesFees,
      franchiseMarketingFee,
    },
  });

  return menuItems.data;
};
