import React, { useState } from 'react';
import { Box, Button, TextField } from '@mui/material';

const EditDescription = ({ description, handleSave, classes, handleCancelEdit }) => {
  const [descriptionValue, setDescriptionValue] = useState(description);
  const disableSave = !descriptionValue || descriptionValue === '';

  return (
    <Box className={classes.editDescriptionContainer}>
      <TextField
        fullWidth
        label="Menu description"
        variant="outlined"
        value={descriptionValue}
        onChange={({ target }) => {
          setDescriptionValue(target.value);
        }}
        error={disableSave}
        helperText={disableSave ? 'Description is required' : ''}
      />
      <Box className={classes.editDescriptionActions}>
        <Button variant="outlined" color="primary" onClick={handleCancelEdit}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          style={{ marginLeft: 10 }}
          disabled={disableSave}
          onClick={() => handleSave(descriptionValue)}
        >
          Save
        </Button>
      </Box>
    </Box>
  );
};

export default EditDescription;
