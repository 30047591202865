export default (image, crop, fileName, imageType = 'image/jpeg') => {
  const canvas = document.createElement('canvas');
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  canvas.width = crop.width;
  canvas.height = crop.height;
  const ctx = canvas.getContext('2d');

  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width,
    crop.height,
  );

  const needsResizing = image.width > 1024;
  const resizeCanvas = document.createElement('canvas');
  const resizeCtx = resizeCanvas.getContext('2d');
  resizeCanvas.width = 1024;
  resizeCanvas.height = canvas.height * (1024 / canvas.width);

  if (needsResizing) {
    resizeCtx.drawImage(
      canvas,
      0,
      0,
      canvas.width,
      canvas.height,
      0,
      0,
      1024,
      canvas.height * (1024 / canvas.width),
    );
  }

  const finalCanvas = needsResizing ? resizeCanvas : canvas;

  return new Promise((resolve) => {
    finalCanvas.toBlob(
      (blob) => {
        // eslint-disable-next-line no-param-reassign
        blob.name = fileName;
        resolve(blob);
      },
      imageType,
      1,
    );
  });
};
