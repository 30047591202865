import React from 'react';
import { useDispatch } from 'react-redux';

import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';

import { getErrorMessage } from '../../shared/utils/errors';
import { useNotifications } from '../../shared/contexts/Notifications/useNotifications';
import Page from '../../components/Page';
import withVenue from '../../hoc/withVenue';
import DiscountForm from './DiscountForm';
import { createDiscount, updateDiscount, clearDiscount } from '../../store/itemDiscounts';
import toUTC from '../../shared/utils/toUTC';
import {
  fromOpeningTimesToFormValues,
  toOpeningTimesFromValues,
} from '../../components/WeekTimeRangeSelector/EditWeekTimeRangeDialog/mappers';

const useStyles = makeStyles(() => ({
  heading: {
    margin: '15px 0',
  },
}));

const AddDiscount = ({ discount, modalSubmit, modalClose, venue, redirect }) => {
  const { showErrorNotification, showSuccessNotification } = useNotifications();
  const isEditMode = !!discount;
  const title = isEditMode ? 'Edit Discount' : 'Add Discount';
  const dispatch = useDispatch();
  const { discountId } = discount || {};

  const handleOnSubmitCreate = async (values) => {
    const transformedValues = {
      ...values,
      schedule: values.schedulegroup !== 'scheduleNull' ? toOpeningTimesFromValues(values) : null,
      label: `${values.amount}% off`,
      type: 'PERCENTAGE',
      enabled: true,
      startsAt: toUTC(values.startsAt),
      endsAt: toUTC(values.endsAt),
    };
    delete transformedValues.openings;
    delete transformedValues.startgroup;
    delete transformedValues.endgroup;
    delete transformedValues.schedulegroup;
    try {
      const newDiscount = await dispatch(createDiscount(transformedValues));
      showSuccessNotification('Discount has been added successfully');
      modalClose();
      if (modalSubmit) {
        modalSubmit(newDiscount);
      } else {
        redirect();
      }
    } catch (error) {
      showErrorNotification(getErrorMessage(error));
    }
    if (modalClose) {
      modalClose();
    } else {
      redirect();
    }
  };

  const handleOnSubmitEdit = async (values) => {
    const transformedValues = {
      ...values,
      schedule: values.schedulegroup !== 'scheduleNull' ? toOpeningTimesFromValues(values) : null,
      label: `${values.amount}% off`,
      type: 'PERCENTAGE',
      startsAt: toUTC(values.startsAt),
      endsAt: toUTC(values.endsAt),
    };

    delete transformedValues.openings;
    delete transformedValues.startgroup;
    delete transformedValues.endgroup;
    delete transformedValues.schedulegroup;
    if (transformedValues.schedule === null || !transformedValues.schedule) {
      transformedValues.schedule = null;
    }

    try {
      const newDiscount = await dispatch(updateDiscount(discountId, transformedValues));
      showSuccessNotification('Discount has been edited successfully');
      modalClose();
      if (modalSubmit) {
        modalSubmit(newDiscount);
      }
    } catch (error) {
      showErrorNotification(getErrorMessage(error));
    }
    if (modalClose) modalClose();
  };

  const classes = useStyles();
  const { openings } = fromOpeningTimesToFormValues(discount?.schedule || venue.openings);

  return (
    <Page>
      <header>
        {!modalSubmit && (
          <Typography className={classes.heading} variant="h2" component="h1">
            {title}
          </Typography>
        )}
      </header>
      <DiscountForm
        formAction={isEditMode ? 'update' : 'create'}
        initialValues={{
          name: isEditMode ? discount.name : '',
          amount: isEditMode ? discount.amount : 10,
          startsAt: isEditMode ? discount.startsAt : 'now',
          endsAt: isEditMode ? discount.endsAt : 'never',
          schedule: isEditMode
            ? discount?.schedule && fromOpeningTimesToFormValues(discount?.schedule)
            : openings,
          openings,
          schedulegroup: discount?.schedule?.length > 0 ? 'schedulePicker' : 'scheduleNull',
          startgroup:
            discount?.startsAt === 'now' || discount?.startsAt === null || !discount?.startsAt
              ? 'now'
              : 'startDate',
          endgroup:
            discount?.endsAt === 'never' || discount?.endsAt === null || !discount?.endsAt
              ? 'never'
              : 'endDate',
        }}
        onSubmit={isEditMode ? handleOnSubmitEdit : handleOnSubmitCreate}
        onCancel={modalClose}
      />
    </Page>
  );
};

export default withVenue(AddDiscount, '/item-discounts', clearDiscount);
