import React from 'react';
import { Typography, Box } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import StepButton from '../StepButton';

const infoSteps = [
  {
    icon: '/img/signup/venue-details-icon.svg',
    text: 'Venue details',
    description: 'Venue name & address',
  },
  {
    icon: '/img/signup/personal-details-icon.svg',
    text: 'Your personal details',
    description: 'Name, email & phone number',
  },
  {
    icon: '/img/signup/company-details-icon.svg',
    text: 'Basic business details',
    description: 'Company type, name & address',
  },
];

const StepTitle = withStyles(Box, (theme) => ({
  root: {
    marginBottom: theme.spacing(12),
  },
}));

const InfoStepsWrapper = withStyles(Box, (theme) => ({
  root: {
    maxWidth: 320,
    margin: 'auto',
    marginBottom: theme.spacing(8),
  },
}));

const InfoStepWrapper = withStyles(Box, (theme) => ({
  root: {
    display: 'flex',
    width: '100%',
    marginBottom: theme.spacing(6),
  },
}));

const InfoStepWrapperText = withStyles(Box, (theme) => ({
  root: {
    marginLeft: theme.spacing(4),
  },
}));

const InfoStepWrapperImage = withStyles(Box, () => ({
  root: {
    '& img': {
      width: '45px',
      height: '45px',
    },
  },
}));

const StepIntro = () => (
  <>
    <StepTitle>
      <Typography variant="h1" component="h1">
        To continue, please make sure to have the following information on hand:
      </Typography>
    </StepTitle>
    <InfoStepsWrapper>
      {infoSteps.map(({ icon, text, description }) => (
        <InfoStepWrapper key={text}>
          <InfoStepWrapperImage>
            <img src={icon} alt="icon" />
          </InfoStepWrapperImage>
          <InfoStepWrapperText marginLeft={4}>
            <Typography variant="h2" component="h2">
              {text}
            </Typography>
            <Typography variant="body1" component="p">
              {description}
            </Typography>
          </InfoStepWrapperText>
        </InfoStepWrapper>
      ))}
    </InfoStepsWrapper>
    <Box mb={10}>
      <Typography variant="body1" component="p">
        We need a few details before you can take payments. This is to follow the law and make sure
        that nobody opens an account in your name.
      </Typography>
    </Box>
    <StepButton />
  </>
);

export default StepIntro;
