import React, { useEffect, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';

import PageHeader from '../../components/PageHeader';
import { getKioskOnboardingKeyState, getKiosksState } from '../../store/kiosks/selectors';
import shouldLoad from '../../shared/utils/shouldLoad';
import { fetchKioskOnboardingKey, fetchKiosks } from '../../store/kiosks';
import Page from '../../components/Page';
import DeviceTable from '../../components/Kiosk/DeviceTable';
import QrContainer from '../../components/QrContainer';

const useStyles = makeStyles((theme) => ({
  onboardingKeyContainer: {
    marginBottom: theme.spacing(2),
  },
}));

const Kiosks = () => {
  const [showKioskOnboardingKey, setShowKioskOnboardingKey] = useState(false);
  const dispatch = useDispatch();
  const classes = useStyles();

  const kiosksState = useSelector(getKiosksState);
  const kioskOnboardingKeyState = useSelector(getKioskOnboardingKeyState);

  const { loading, data: kiosks, error } = kiosksState;
  const { data: kioskOnboardingKey } = kioskOnboardingKeyState;

  useEffect(() => {
    if (shouldLoad(kiosksState)) dispatch(fetchKiosks());
  }, [dispatch, kiosksState]);

  useEffect(() => {
    if (shouldLoad(kioskOnboardingKeyState)) dispatch(fetchKioskOnboardingKey());
  }, [dispatch, kioskOnboardingKeyState]);

  return (
    <>
      <PageHeader fullWidth>
        <Typography variant="h2" component="h2">
          Kiosks
        </Typography>
      </PageHeader>
      <Page loading={loading} error={error} fullWidth>
        {kiosks && (
          <>
            <Typography paragraph>
              This page allows you to view the kiosks registered to your venue.
            </Typography>
            {kioskOnboardingKey && (
              <Box className={classes.onboardingKeyContainer}>
                <Button
                  variant="outlined"
                  onClick={() => setShowKioskOnboardingKey(!showKioskOnboardingKey)}
                  endIcon={showKioskOnboardingKey ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                >
                  Onboard Kiosk
                </Button>
                {showKioskOnboardingKey && (
                  <QrContainer
                    title="Onboard Kiosk"
                    value={kioskOnboardingKey.onboardingDeviceKey}
                  />
                )}
              </Box>
            )}
            {kiosks.length > 0 ? (
              <DeviceTable />
            ) : (
              <Typography variant="body1" color="textSecondary">
                No kiosks found
              </Typography>
            )}
          </>
        )}
      </Page>
    </>
  );
};

export default Kiosks;
