import { createSlice } from '@reduxjs/toolkit';
import {
  asyncInitialState as initialState,
  createAsyncAction,
  createAsyncReducerHandlers,
} from '../../utils/async';
import {
  fetchBrandsIngredientsApi,
  fetchBrandsIngredientApi,
  deleteBrandsIngredientApi,
  createBrandsIngredientApi,
  updateBrandsIngredientApi,
} from '../../../api/brands/brandsIngredients';

export const fetchBrandsIngredients = createAsyncAction(
  'fetch-brands-ingredients',
  fetchBrandsIngredientsApi,
);

export const deleteBrandsIngredient = createAsyncAction(
  'delete-brands-ingredient',
  deleteBrandsIngredientApi,
);

export const fetchBrandsIngredient = createAsyncAction(
  'fetch-brands-ingredient',
  fetchBrandsIngredientApi,
);

export const createBrandsIngredient = createAsyncAction(
  'create-brands-ingredient',
  createBrandsIngredientApi,
);

export const updateBrandsIngredient = createAsyncAction(
  'update-brands-ingredient',
  updateBrandsIngredientApi,
);

// export const updateIngredientsAvailability = createAsyncAction(
//   'update-ingredients-availability',
//   async ({ values }) => {
//     await updateIngredientsAvailabilityApi({ values });
//   },
// );

const ingredientsSlice = createSlice({
  name: 'brandsIngredients',
  initialState,
  reducers: {
    clearIngredients(state) {
      delete state.data;
    },
  },
  extraReducers: {
    ...createAsyncReducerHandlers(fetchBrandsIngredients),
  },
});

const ingredientSlice = createSlice({
  name: 'brandsIngredient',
  initialState,
  reducers: {
    clearIngredient(state) {
      delete state.data;
    },
  },
  extraReducers: {
    ...createAsyncReducerHandlers(fetchBrandsIngredient),
    ...createAsyncReducerHandlers(createBrandsIngredient),
    ...createAsyncReducerHandlers(updateBrandsIngredient),
  },
});
export const { clearIngredient } = ingredientSlice.actions;
export const { clearIngredients } = ingredientsSlice.actions;

export const { reducer: brandsIngredients } = ingredientsSlice;
export const { reducer: brandsIngredient } = ingredientSlice;
