import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { TableFooter, Typography } from '@mui/material';

import { getOrdersState } from '../../store/orders/selectors';
import TableSkeletonLoader from '../TableSkeletonLoader';
import TablePaginationActions from '../TablePaginationActions';
import TableLink from '../TableLink';

export default ({ pagination, orders }) => {
  const { page, handleChangePage, rowsPerPage } = pagination;
  const { loading } = useSelector(getOrdersState);

  if (!orders) return null;

  const diplayOrders = orders?.docs || orders;
  const ordersNumber = orders?.total;
  const hasOrders = diplayOrders.length > 0;

  const hasTables = hasOrders && diplayOrders?.some((order) => order.tableName);

  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography variant="h3">Date</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="h3">Time</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="h3">Order ID</Typography>
            </TableCell>
            {hasTables && (
              <TableCell>
                <Typography variant="h3">Table #</Typography>
              </TableCell>
            )}
            <TableCell>
              <Typography variant="h3">Name</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="h3">Staff Member</Typography>
            </TableCell>
            {hasTables && (
              <TableCell>
                <Typography variant="h3">Status</Typography>
              </TableCell>
            )}
            <TableCell>
              <Typography variant="h3">Payment Service</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="h3">Payment Source</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="h3">Payment Method</Typography>
            </TableCell>

            <TableCell>
              <Typography variant="h3" align="right">
                Total Paid
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        {loading && <TableSkeletonLoader rows={10} cells={8} />}
        {!loading && hasOrders && (
          <>
            <TableBody>
              {diplayOrders
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((order) => {
                  const {
                    orderId,
                    acceptedAt,
                    orderDisplayId,
                    tableName,
                    name,
                    staffName,
                    status,
                    paymentMethod,
                    paymentService,
                    paymentSource,
                    total,
                    updatedAt,
                  } = order;
                  const valDate = acceptedAt || updatedAt;
                  return (
                    <TableRow key={orderId}>
                      <TableCell>
                        <Typography variant="body2">
                          {moment(valDate).local().format('DD/MM')}
                        </Typography>
                      </TableCell>
                      <TableCell>{moment(valDate).local().format('HH:mm')}</TableCell>
                      <TableCell>
                        <TableLink to={`/orders/${orderId}`}>{orderDisplayId}</TableLink>{' '}
                      </TableCell>

                      {hasTables && <TableCell>{tableName}</TableCell>}
                      <TableCell>{name}</TableCell>
                      <TableCell>{staffName || '-'}</TableCell>
                      {hasTables && <TableCell>{status}</TableCell>}
                      <TableCell>{paymentService}</TableCell>
                      <TableCell>{paymentSource}</TableCell>
                      <TableCell>{paymentMethod}</TableCell>
                      <TableCell align="right">£{parseFloat(total).toFixed(2)}</TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </>
        )}
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[]}
              count={ordersNumber}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};
