import { Button } from '@mui/material';
import React from 'react';
import DoneIcon from '@mui/icons-material/Done';

const FontButton = ({ font, clickHandler, currentFont }) => {
  const spanstyle = {
    fontFamily: font,
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '22px',
    paddingLeft: font !== currentFont ? '28px' : '4px',
  };
  return (
    <div style={{ display: 'flex' }}>
      {font === currentFont && (
        <div style={{ marginTop: '9px' }}>
          <DoneIcon />
        </div>
      )}
      <Button sx={{ justifyContent: 'start', width: '100%' }} onClick={() => clickHandler(font)}>
        <span style={spanstyle}>{font}</span>
      </Button>
    </div>
  );
};
export default FontButton;
