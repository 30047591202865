import React from 'react';
import { Button, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { union } from 'lodash';

const TableWarningMissing = ({ missing, classes, isAdmin, showDevicesLink }) => (
  <>
    <Typography className={classes.title} variant="h2" component="h1">
      {isAdmin
        ? `Unassigned Tables: These tables should deleted, or assigned to a device.
        [${union(missing.ALL, missing.FOOD, missing.DRINKS).toString()}]`
        : `These tables are not assigned to a device and will not appear available for customers.
            Please contact support to associate with a device, or delete the table.`}
    </Typography>

    {isAdmin && showDevicesLink && (
      <aside>
        <Button
          color="primary"
          size="small"
          variant="contained"
          rel="noopener noopener noreferrer"
          component={Link}
          to="/devices"
        >
          Devices
        </Button>
      </aside>
    )}
  </>
);

export default TableWarningMissing;
