const cySelectors = {
  // DASHBOARD
  NOTAUTHENTICATED: 'NOTAUTHENTICATED',
  AUTHENTICATED: 'AUTHENTICATED',
  NAVIGATION: 'NAVIGATION',
  MAIN: 'MAIN',
  UNIVERSAL_SAVE: 'UNIVERSAL_SAVE',
  UNIVERSAL_DISCARD: 'UNIVERSAL_DISCARD',
  // CWA
  SUBMIT_BTN: 'SUBMIT_BTN',
  OVERLAY_MODAL_BTN: 'OVERLAY_MODAL_BTN',
  MENU_CATEGORY_ITEM: 'MENU_CATEGORY_ITEM',
  MODIFIERS_SUBMIT_BTN: 'MODIFIERS_SUBMIT_BTN',
  DETAILS_FORM_NAME: 'DETAILS_FORM_NAME',
  DETAILS_FORM_EMAIL: 'DETAILS_FORM_EMAIL',
  DETAILS_FORM_PHONE: 'DETAILS_FORM_PHONE',
  DETAILS_FORM_TABLE: 'DETAILS_FORM_TABLE',
  CHECKOUT_REVEAL_CARD: 'CHECKOUT_REVEAL_CARD',
  CHECKOUT_STRIPE_IFRAME: '[data-cy="CHECKOUT_STRIPE_IFRAME"] iframe',
  CHECKOUT_STRIPE_CARD_NUMBER: '[name="cardnumber"]',
  CHECKOUT_STRIPE_EXPIRY_DATE: '[name="exp-date"]',
  CHECKOUT_STRIPE_CVC: '[name="cvc"]',
};
export default cySelectors;
