import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import { Switch } from 'formik-mui';
import { makeStyles } from '@mui/styles';
import {
  FormControlLabel,
  Button,
  Typography,
  Box,
  Grid,
  DialogContent,
  DialogActions,
} from '@mui/material';
import TimePickerField from '../TimePickerField/TimePickerFieldDateFns';

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    height: '400px',
    overflow: 'auto',
  },
  timeWrapper: {
    backgroundColor: theme.palette.grey[100],
    borderRadius: theme.shape.borderRadius,
  },
  sameAsOpeningTimes: {
    [theme.breakpoints.up('sm')]: {
      textAlign: 'right',
    },
  },
}));

const MenuGroupScheduleForm = ({ initialValues, onSubmit, onCancel }) => {
  const classes = useStyles();

  const ScheduleSchema = Yup.object().shape({
    days: Yup.array().of(
      Yup.object().shape({
        day: Yup.string().isRequired,
        start: Yup.string().isRequired,
        end: Yup.string().isRequired,
      }),
    ),
  });

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={ScheduleSchema}>
      {({ values, dirty }) => (
        <Form>
          <DialogContent dividers className={classes.dialogContent}>
            {values.days.map((day, index) => (
              <Box key={day.day} mb={2} p={2} className={classes.timeWrapper}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={4}>
                    <Typography variant="h2">{day.day}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={8} className={classes.sameAsOpeningTimes}>
                    <FormControlLabel
                      control={
                        <Field
                          component={Switch}
                          color="primary"
                          type="checkbox"
                          name={`days.${index}.sameAsOpeningTimes`}
                        />
                      }
                      label="Same as opening times"
                    />
                  </Grid>
                </Grid>
                {!values.days[index].sameAsOpeningTimes && (
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                      <FormControlLabel
                        control={
                          <Field
                            component={Switch}
                            color="primary"
                            type="checkbox"
                            name={`days.${index}.available`}
                          />
                        }
                        label="Open"
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Field
                        disabled={!values.days[index].available}
                        name={`days.${index}.start`}
                        component={TimePickerField}
                        fullWidth
                        label="Start"
                        inputVariant="outlined"
                        size="small"
                        inputProps={{
                          'data-test-id': 'start-time-input',
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Field
                        disabled={!values.days[index].available}
                        name={`days.${index}.end`}
                        component={TimePickerField}
                        fullWidth
                        label="End"
                        inputVariant="outlined"
                        size="small"
                        inputProps={{
                          'data-test-id': 'end-time-input',
                        }}
                      />
                    </Grid>
                  </Grid>
                )}
              </Box>
            ))}
          </DialogContent>
          <DialogActions>
            <Button variant="text" onClick={onCancel}>
              Cancel
            </Button>
            <Button type="submit" variant="contained" color="primary" disabled={!dirty}>
              Update
            </Button>
          </DialogActions>
        </Form>
      )}
    </Formik>
  );
};

MenuGroupScheduleForm.propTypes = {
  // TODO: We should have interfaces or classes for this rule
  // eslint-disable-next-line react/forbid-prop-types
  initialValues: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default MenuGroupScheduleForm;
