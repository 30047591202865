import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { Button } from '@mui/material';
import { FiPlus } from 'react-icons/fi';

import { createMenuGroup, updateVenueMenuItems } from '../../store/venueMenuItems';

import { getErrorMessage } from '../../shared/utils/errors';
import { useNotifications } from '../../shared/contexts/Notifications/useNotifications';

import MenuGroupForm from '../MenuGroupForm';
import CustomDialog from '../CustomDialog';

const CreateMenuGroupButton = ({ disabled, venuePriorities }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { showErrorNotification, showSuccessNotification } = useNotifications();
  const dispatch = useDispatch();
  const { priorities } = venuePriorities || {};
  const initialValues = {
    groupColor: '',
    groupName: '',
    available: true,
    description: '',
    priorityId: priorities?.[0]?.priorityId || '',
  };

  const handleCreate = () => {
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const handleOnSubmit = async (values) => {
    try {
      const payload = { ...values };
      const { priorityId } = values;

      if (!priorityId) {
        delete payload.priorityId;
      }

      dispatch(createMenuGroup(payload));
      await dispatch(updateVenueMenuItems());

      setIsDialogOpen(false);
      showSuccessNotification(`${payload.groupName} has been created successfully`);
    } catch (error) {
      showErrorNotification(getErrorMessage(error));
    }
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        disabled={disabled}
        startIcon={<FiPlus />}
        onClick={handleCreate}
        fullWidth
      >
        Add a section
      </Button>
      <CustomDialog
        title="Add a new section to this menu"
        isDialogOpen={isDialogOpen}
        handleCloseDialog={handleCloseDialog}
      >
        <MenuGroupForm
          formAction="create"
          initialValues={initialValues}
          onSubmit={handleOnSubmit}
          onCancel={handleCloseDialog}
          venuePriorities={venuePriorities}
        />
      </CustomDialog>
    </>
  );
};

export default CreateMenuGroupButton;
