import React, { useEffect, useState, useMemo, useCallback } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
  TableFooter,
  FormControl,
  Select,
  InputBase,
  MenuItem,
  useTheme,
  ThemeProvider,
  StyledEngineProvider,
  Button,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { withStyles } from 'tss-react/mui';
import { useSelector } from 'react-redux';
import { nanoid } from 'nanoid';
import formatValue from '../../shared/utils/formatValue';
import usePagination from '../../hooks/usePagination';
import ReportsTablePaginationActions from '../ReportsTablePaginationActions';

import { ReactComponent as ArrowIcon } from './arrow.svg';
import orderTable from '../../shared/utils/orderReportsTable';
import { getSearchState } from '../../store/search';
import { customFontsClasses } from '../../theme/mui';

const useStyles = makeStyles((theme) => ({
  table: {
    overflowY: 'auto',
    margin: theme.spacing(2),
    paddingRight: theme.spacing(4),
  },
  paginationTableCell: {
    borderBottom: 'none',
    padding: 0,
    '&:last-child': {
      padding: 0,
    },
  },
  header: {
    backgroundColor: theme.palette.background.default,
  },
  totalsRow: {
    borderBottom: `1px solid ${theme.customPalette.greyDark}`,
  },
  tableRow: {
    '&:hover': {
      backgroundColor: theme.palette.background.default,
    },
  },
  descending: {
    marginTop: -2,
    marginLeft: 4,
    transform: 'rotate(180deg)',
  },
  ascending: {
    marginTop: -2,
    marginLeft: 4,
    transform: 'rotate(0deg)',
  },
  columnSelected: {
    fontWeight: 600,
    fontSize: '12px',
  },
  columnUnselected: {
    fontSize: '12px',
  },

  disabledColumnTitles: {
    fontSize: '12px',
    color: theme.palette.primary.main,
    cursor: 'default',
  },
  footerTable: {
    marginTop: theme.spacing(1),
  },
}));

const SelectInput = withStyles(InputBase, () => ({
  input: { border: '1px solid #ced4da', padding: '10px 26px 10px 12px' },
}));

const TotalTableCell = withStyles(TableCell, () => ({
  root: {
    borderBottom: 'none',
  },
}));

const OrderableTable = ({
  tableData,
  zeroCheckTitle,
  rowTitle,
  titles,
  showTotals,
  excludeForTotal = [],
  excludeFields = [],
  disableColumnTitles = [],
  valueFormatter = formatValue,
  excludeTableFooter,
  orderBy = null,
}) => {
  const classes = useStyles();
  const customClasses = customFontsClasses();
  const internalExcludeFields = useMemo(() => [...excludeFields, 'tableRowId'], [excludeFields]);

  const shouldShowColumn = useCallback(
    (title) => !internalExcludeFields.includes(title),
    [internalExcludeFields],
  );
  // Use internal row ids to avoid problems with duplicate ids
  const internalTableData = useMemo(
    () => tableData.map((row) => ({ ...row, ...{ tableRowId: nanoid() } })),
    [tableData],
  );

  const pagination = usePagination(100, false);
  const { page, handleChangePage, handleChangeRowsPerPage, rowsPerPage, first } = pagination;
  const { units } = useSelector(getSearchState);
  const [orderField, setOrderField] = useState(orderBy);
  const [orderAscending, setOrderAscending] = useState(true);
  const existingTheme = useTheme();
  const hybridTheme = {
    ...existingTheme,
    ...{
      palette: {
        ...existingTheme.palette,
        text: {
          primary: '#384E66',
          secondary: '#384E66',
        },
      },
    },
  };
  const dataTitles = useMemo(
    () =>
      (internalTableData && internalTableData.length && Object.keys(internalTableData[0])) || [],
    [internalTableData],
  );
  const columnTitles =
    titles || (internalTableData && internalTableData.length && Object.keys(internalTableData[0]));

  const [strippedData, setStrippedData] = useState(
    internalTableData?.filter((row) => row[zeroCheckTitle] !== 0),
  );
  const [pageData, setPageData] = useState(strippedData?.slice(first, first + rowsPerPage));

  const totals =
    showTotals &&
    internalTableData?.reduce((acc, row) => {
      Object.keys(row).forEach((key) => {
        if (key !== rowTitle) {
          // eslint-disable-next-line no-param-reassign
          acc[key] = acc[key] ? acc[key] + row[key] : row[key];
          // eslint-disable-next-line no-param-reassign
          if (excludeForTotal.includes(key)) acc[key] = null;
        }
      });
      return acc;
    }, {});

  useEffect(() => {
    setStrippedData(internalTableData?.filter((row) => row[zeroCheckTitle] !== 0));
  }, [internalTableData, zeroCheckTitle]);

  useEffect(() => {
    if (dataTitles.length > 0 && !orderField) setOrderField(dataTitles[0]);
  }, [dataTitles, orderField]);

  useEffect(() => {
    const newPageData = orderField
      ? orderTable(strippedData, orderField, orderAscending)
      : strippedData;

    setPageData(newPageData?.slice(first, first + rowsPerPage));
  }, [strippedData, first, rowsPerPage, orderField, orderAscending]);

  if (!internalTableData || internalTableData.length === 0) return null;
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={hybridTheme}>
        <TableContainer className={classes.table}>
          <Table size="small">
            <TableHead>
              <TableRow className={classes.header}>
                {columnTitles.map((title, index) =>
                  !disableColumnTitles.includes(title) ? (
                    <TableCell
                      key={title}
                      align={index === 0 ? 'left' : 'right'}
                      onClick={() => {
                        setOrderAscending(
                          dataTitles[index] === orderField ? !orderAscending : true,
                        );
                        setOrderField(dataTitles[index]);
                      }}
                    >
                      <Button
                        endIcon={
                          <ArrowIcon
                            className={
                              orderField === dataTitles[index] && !orderAscending
                                ? classes.descending
                                : classes.ascending
                            }
                          />
                        }
                      >
                        <Typography
                          variant="subtitle2"
                          className={`${customFontsClasses.small} ${
                            dataTitles[index] === orderField
                              ? classes.columnSelected
                              : classes.columnUnselected
                          }`}
                        >
                          {title}
                        </Typography>
                      </Button>
                    </TableCell>
                  ) : (
                    <TableCell key={title} align={index === 0 ? 'left' : 'right'}>
                      <Typography variant="subtitle2" className={classes.disabledColumnTitles}>
                        {title}
                      </Typography>
                    </TableCell>
                  ),
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {showTotals && totals && (
                <TableRow className={classes.totalsRow}>
                  <TotalTableCell align="left">
                    <Typography variant="body2" className={customClasses.small}>
                      <strong>Total</strong>
                    </Typography>
                  </TotalTableCell>
                  {Object.keys(totals).map(
                    (title) =>
                      shouldShowColumn(title) && (
                        <TotalTableCell key={title} align="right">
                          <Typography variant="body2" className={customClasses.small}>
                            <strong>
                              {valueFormatter({ value: totals[title], title, units })}
                            </strong>
                          </Typography>
                        </TotalTableCell>
                      ),
                  )}
                </TableRow>
              )}
              {pageData.map((row) => (
                <TableRow key={row.tableRowId} className={classes.tableRow}>
                  {Object.keys(row).map(
                    (title, index) =>
                      shouldShowColumn(title) && (
                        <TableCell key={title} align={index === 0 ? 'left' : 'right'}>
                          <div className={customClasses.small}>
                            {valueFormatter({ value: row[title], valueName: title, units, row })}
                          </div>
                        </TableCell>
                      ),
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Table size="small" className={classes.footerTable}>
            {!excludeTableFooter && (
              <TableFooter>
                <TableRow>
                  <TableCell colSpan={columnTitles.length} className={classes.paginationTableCell}>
                    <FormControl>
                      <Select
                        value={rowsPerPage}
                        variant="outlined"
                        size="small"
                        onChange={handleChangeRowsPerPage}
                        input={<SelectInput className={customClasses.small} />}
                      >
                        <MenuItem value={20}>20 items</MenuItem>
                        <MenuItem value={100}>100 items</MenuItem>
                      </Select>
                    </FormControl>
                  </TableCell>
                  <TablePagination
                    rowsPerPageOptions={[]}
                    labelRowsPerPage=""
                    className={classes.paginationTableCell}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={ReportsTablePaginationActions}
                    count={strippedData.length}
                    SelectProps={{ className: classes.select, variant: 'outlined', size: 'small' }}
                    rowsPerPage={rowsPerPage}
                  />
                </TableRow>
              </TableFooter>
            )}
          </Table>
        </TableContainer>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default OrderableTable;
