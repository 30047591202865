import { createSlice } from '@reduxjs/toolkit';
import {
  asyncInitialState as initialState,
  createAsyncAction,
  createAsyncReducerHandlers,
} from '../utils/async';
import {
  fetchUpsellGroups as fetchUpsellGroupsApi,
  fetchUpsellGroup as fetchUpsellGroupApi,
  createUpsellGroup as createUpsellGroupApi,
  fetchUpsellGroupInitiatingItems as fetchUpsellGroupInitiatingItemsApi,
  fetchSmartUpsellGroup as fetchSmartUpsellGroupApi,
  updateUpsellGroup as updateUpsellGroupApi,
  updateSmartUpsellGroup as updateSmartUpsellGroupApi,
  deleteUpsellGroup as deleteUpsellGroupApi,
} from '../../api/upsellGroups';

export const fetchUpsellGroups = createAsyncAction('fetch-upsell-groups', fetchUpsellGroupsApi);
export const fetchUpsellGroup = createAsyncAction('fetch-upsell-group', fetchUpsellGroupApi);
export const fetchSmartUpsellGroup = createAsyncAction(
  'fetch-smart-upsell-group',
  fetchSmartUpsellGroupApi,
);
export const fetchUpsellGroupInitiatingItems = createAsyncAction(
  'fetch-upsell-group-initiating-items',
  fetchUpsellGroupInitiatingItemsApi,
);

export const fetchSmartUpsellGroupInitiatingItems = createAsyncAction(
  'fetch-smart-upsell-group-initiating-items',
  fetchUpsellGroupInitiatingItemsApi,
);
export const createUpsellGroup = createAsyncAction('create-upsell-group', createUpsellGroupApi);
export const updateUpsellGroup = createAsyncAction('update-upsell-group', updateUpsellGroupApi);
export const updateSmartUpsellGroup = createAsyncAction(
  'update-smart-upsell-group',
  updateSmartUpsellGroupApi,
);
export const deleteUpsellGroup = createAsyncAction('delete-upsell-group', deleteUpsellGroupApi);

const upsellGroupsSlice = createSlice({
  name: 'upsellGroups',
  initialState,
  reducers: {
    clearUpsellGroups(state) {
      delete state.data;
    },
  },
  extraReducers: createAsyncReducerHandlers(fetchUpsellGroups),
});

const upsellGroupSlice = createSlice({
  name: 'upsellGroup',
  initialState,
  reducers: {
    clearUpsellGroup(state) {
      delete state.data;
    },
  },
  extraReducers: {
    ...createAsyncReducerHandlers(fetchUpsellGroup),
    ...createAsyncReducerHandlers(createUpsellGroup),
    ...createAsyncReducerHandlers(updateUpsellGroup),
    ...createAsyncReducerHandlers(deleteUpsellGroup),
  },
});

const smartUpsellGroupSlice = createSlice({
  name: 'smartUpsellGroup',
  initialState,
  reducers: {
    clearSmartUpsellGroup(state) {
      delete state.data;
    },
  },
  extraReducers: {
    ...createAsyncReducerHandlers(fetchSmartUpsellGroup),
    ...createAsyncReducerHandlers(createUpsellGroup),
    ...createAsyncReducerHandlers(updateSmartUpsellGroup),
    ...createAsyncReducerHandlers(deleteUpsellGroup),
  },
});

const upsellGroupInitiatingItemsSlice = createSlice({
  name: 'upsellGroupInitiatingItems',
  initialState,
  reducers: {
    clearUpsellGroupInitiatingItems(state) {
      delete state.data;
    },
  },
  extraReducers: createAsyncReducerHandlers(fetchUpsellGroupInitiatingItems),
});

const smartUpsellGroupInitiatingItemsSlice = createSlice({
  name: 'smartUpsellGroupInitiatingItems',
  initialState,
  reducers: {
    clearSmartUpsellGroupInitiatingItems(state) {
      delete state.data;
    },
  },
  extraReducers: createAsyncReducerHandlers(fetchSmartUpsellGroupInitiatingItems),
});

export const { clearUpsellGroups } = upsellGroupsSlice.actions;
export const { clearUpsellGroup } = upsellGroupSlice.actions;
export const { clearUpsellGroupInitiatingItems } = upsellGroupInitiatingItemsSlice.actions;
export const { clearSmartUpsellGroupInitiatingItems } =
  smartUpsellGroupInitiatingItemsSlice.actions;
export const { clearSmartUpsellGroup } = smartUpsellGroupSlice.actions;

export const { reducer: upsellGroups } = upsellGroupsSlice;
export const { reducer: upsellGroup } = upsellGroupSlice;
export const { reducer: smartUpsellGroup } = smartUpsellGroupSlice;
export const { reducer: upsellGroupInitiatingItems } = upsellGroupInitiatingItemsSlice;
export const { reducer: smartUpsellGroupInitiatingItems } = smartUpsellGroupInitiatingItemsSlice;
