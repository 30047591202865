import React from 'react';
import PropTypes from 'prop-types';

import { Card, CardActions, CardContent, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CustomDialog from '../CustomDialog';

const useStyles = makeStyles(() => ({
  heading: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

const DeleteVenueDialog = ({
  handleCloseDialog,
  handleDialogOpen,
  submitDialog,
  venueId,
  venueName,
}) => {
  const classes = useStyles();

  return (
    <CustomDialog
      title="Are you sure?"
      handleCloseDialog={handleCloseDialog}
      isDialogOpen={handleDialogOpen}
    >
      <Card className={classes.card}>
        <CardContent>
          <b>{venueName}</b> is going to be deleted!
          <br />
          This action is irreversible!
        </CardContent>
        <CardActions className={classes.cardActions}>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={() => submitDialog(venueId)} variant="contained" color="primary">
            Delete Venue
          </Button>
        </CardActions>
      </Card>
    </CustomDialog>
  );
};

DeleteVenueDialog.propTypes = {
  handleCloseDialog: PropTypes.func.isRequired,
  handleDialogOpen: PropTypes.bool.isRequired,
  submitDialog: PropTypes.func.isRequired,
  venueId: PropTypes.string.isRequired,
  venueName: PropTypes.string.isRequired,
};

export default DeleteVenueDialog;
