import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import shouldLoad from '../shared/utils/shouldLoad';

const useEndpoint = (selector, fetchData) => {
  const state = useSelector(selector);
  const { loading, data, error } = state;
  const dispatch = useDispatch();
  const redispatch = useCallback(() => dispatch(fetchData), [dispatch, fetchData]);
  useEffect(() => {
    if (shouldLoad(state)) {
      dispatch(fetchData);
    }
  }, [dispatch, state, fetchData]);
  return { loading, data, error, redispatch };
};

export default useEndpoint;
