import { useCallback, useEffect, useState } from 'react';

const usePagination = (size = 50, useRequestNewPage = true) => {
  const [requestNewPage, setRequestNewPage] = useState(false);
  const [farthestPageRequested, setFarthestPageRequested] = useState(0);
  const [first, setFirst] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(size);

  const handleChangeRowsPerPage = ({ target }) => {
    setFarthestPageRequested(0);
    setPage(0);
    setRowsPerPage(target.value);
  };

  const handleChangePage = useCallback(
    (newPage) => {
      const isNewPageRequest = newPage > farthestPageRequested;

      if (isNewPageRequest || !useRequestNewPage) {
        const newfirst = newPage * rowsPerPage;
        setFarthestPageRequested(newPage);
        setFirst(newfirst);
        setRequestNewPage(true);
      }
      setPage(newPage);
    },
    [farthestPageRequested, rowsPerPage, useRequestNewPage],
  );

  useEffect(() => {
    // request new page is only a trigger to dispatch in the parent
    // so reset it internally
    if (requestNewPage) {
      setRequestNewPage(false);
    }
  }, [requestNewPage]);

  const resetPagination = useCallback(() => {
    // Reset page results caching tracker on new form submission
    setFarthestPageRequested(0);
    // Reset page count
    setPage(0);
  }, []);

  return {
    first,
    page,
    rowsPerPage,
    requestNewPage,
    handleChangePage,
    handleChangeRowsPerPage,
    resetPagination,
    setPage,
  };
};

export default usePagination;
