import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  row: {
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  title: {
    ...theme.customFonts.small,
    color: theme.customPalette.greyDarker,
    flex: '1',
  },
  total: {
    ...theme.customFonts.smallBold,
    color: theme.customPalette.greyDarker,
  },
  rightSide: {
    textAlign: 'right',
  },
  quantity: {
    ...theme.customFonts.small,
    color: theme.customPalette.greyDark,
  },
}));

const ReportsMostOrdered = ({ data }) => {
  const classes = useStyles();

  return (
    <>
      {data.map(({ itemName, quantity, unitPrice, value }) => (
        <Box key={itemName} display="flex" className={classes.row} justifyContent="space-between">
          <Box display="flex" flexDirection="column">
            <Typography className={classes.title}>{itemName}</Typography>
            <Typography className={classes.title}>£{unitPrice}</Typography>
          </Box>
          <Box display="flex" flexDirection="column" className={classes.rightSide}>
            <Typography className={classes.total}>£{value}</Typography>
            <Typography className={classes.quantity}>{quantity} sold</Typography>
          </Box>
        </Box>
      ))}
    </>
  );
};
export default ReportsMostOrdered;
