import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';

export const statuscode = {
  success: 'success',
  pending: 'pending',
  scheduled: 'scheduled',
  fail: 'fail',
  inactive: 'inactive',
};

const useStyles = makeStyles((theme) => ({
  lozenge: {
    borderRadius: '24px',
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    display: 'inline-block',
    fontWeight: 'bold',
  },
  thin: {
    padding: theme.spacing(0.5),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  fail: {
    color: '#D2222D',
    backgroundColor: '#D2222D0F',
  },
  success: {
    color: '#32B842',
    backgroundColor: '#32B8420F',
  },
  pending: {
    color: '#FFB800',
    backgroundColor: '#FFB8000F',
  },
  scheduled: {
    color: '#8C1DF1',
    backgroundColor: '#8C1DF10F',
  },
  inactive: {
    color: '#647F99',
    backgroundColor: '#F0F4F8',
  },
  info: {
    color: '#FFFFFF',
    backgroundColor: 'rgba(21, 21, 21, 0.55)',
  },
}));

const StatusLozenge = ({ status, text, className: classNameinherit, thin }) => {
  const classes = useStyles();
  return (
    <div
      className={`${classes.lozenge} ${classes[status]} ${
        thin && classes.thin
      } ${classNameinherit}`}
    >
      <small>{text}</small>
    </div>
  );
};

StatusLozenge.defaultProps = {
  className: '',
};

StatusLozenge.propTypes = {
  status: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
};
export default StatusLozenge;
