import { pick, omit } from 'ramda';
import { get, patch, post, del } from './httpRequest';

const splitOptionsAndPrices = (options) =>
  options.reduce(
    (acc, option) => {
      acc.itemOptions.push(option.optionName);
      acc.itemPrices.push(option.optionPrice);
      acc.itemCalories.push(option?.optionCalories);
      return acc;
    },
    { itemOptions: [], itemPrices: [], itemCalories: [] },
  );

const combineOptionsAndPrices = (options, prices, calories = []) =>
  options.reduce((acc, option, index) => {
    acc.push({
      optionName: option,
      optionPrice: prices[index],
      optionCalories: calories[index] || undefined,
    });
    return acc;
  }, []);

export const fetchMenuItems = async () => {
  const response = await get('/items');
  return response.data;
};

export const fetchMenuItem = async (menuItemId) => {
  const response = await get(`/items/${menuItemId}`);
  return response.data.itemOptions
    ? {
        ...omit(['itemPrices', 'itemCalories'], response.data),
        itemOptions: combineOptionsAndPrices(
          response.data.itemOptions,
          response.data.itemPrices,
          response.data?.itemCalories,
        ),
      }
    : response.data;
};

export const deleteMenuItem = async (menuItemId) => {
  await del(`/items/${menuItemId}`);
};

export const createMenuItem = async (menuItem) => {
  const val = {
    ...omit(['available'], menuItem),
    ...splitOptionsAndPrices(menuItem.itemOptions),
  };

  const vatRate = parseFloat(menuItem.vatRate);
  const data = { ...val, vatRate };
  const response = await post('/items', { data });

  return response.data;
};

export const updateMenuItem = async ({ menuItemId, values }) => {
  const itemOptions = values.itemOptions && splitOptionsAndPrices(values.itemOptions);
  // const vatRate = parseFloat(values.vatRate);

  const update = {
    ...itemOptions,
    ...pick(
      [
        'available',
        'hidden',
        'category',
        'discountId',
        'type',
        'description',
        'itemIngredients',
        'itemName',
        'label',
        'itemLabel',
        'modifiers',
        'isAlcoholic',
        'vatRate',
        'dietaryRequirements',
      ],
      values,
    ),
  };
  // const data = { ...update, vatRate };
  const data = { ...update };
  await patch(`/items/${menuItemId}`, { data });
};

export const updateMenuItemsAvailability = async ({ values }) => {
  const data = { items: [] };
  values.forEach((item) => {
    data.items.push(pick(['itemId', 'available'], item));
  });
  await patch(`/items/availability`, { data });
};
