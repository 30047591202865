import React from 'react';

import { useParams } from 'react-router';
import { Box, Typography } from '@mui/material';
import withVenue from '../../../hoc/withVenue';
import TabContainer from '../../../components/TabContainer';
import PageHeader from '../../../components/PageHeader';
import BrandsMenuItems from '../BrandsMenuItems';
import BrandsCategories from '../BrandsCategories';
import BrandIngredients from '../BrandsIngredients';
import BrandsModifiers from '../BrandsModifiers';
import BrandsOptions from '../BrandsOptions';
import { clearBrandsMenuItem, clearBrandsMenuItems } from '../../../store/brands/brandsMenuItems';
import { clearBrandsModifiers } from '../../../store/brands/brandsModifiers';
import { clearBrandsOptions } from '../../../store/brands/brandsOptions';
import CreateItemButton from './CreateItemButton';
import CreateIngredientButton from './CreateIngredientButton';
import CreateModifierButton from './CreateModifierButton';
import CreateSizeButton from './CreateSizeButton';
import CreateCategoryButton from './CreateCategoryButton';

const BrandItems = () => {
  const { tabname } = useParams();

  return (
    <PageHeader fullWidth>
      <Box sx={{ display: 'flex' }}>
        <Typography style={{ flexGrow: 1 }} variant="h2" component="h1">
          Brand Items
        </Typography>
        {tabname === 'menu-items' && <CreateItemButton />}
        {tabname === 'ingredients' && <CreateIngredientButton />}
        {tabname === 'modifiers' && <CreateModifierButton />}
        {tabname === 'sizes' && <CreateSizeButton />}
        {tabname === 'reporting-categories' && <CreateCategoryButton />}
      </Box>
      <TabContainer
        titles={['Menu items', 'Ingredients', 'Modifiers', 'Sizes', 'Reporting categories']}
        defaultTab={tabname}
        path="/brand-menu-management/items/"
      >
        <BrandsMenuItems />
        <BrandIngredients />
        <BrandsModifiers />
        <BrandsOptions />
        <BrandsCategories />
      </TabContainer>
    </PageHeader>
  );
};

export default withVenue(BrandItems, null, [
  clearBrandsMenuItems,
  clearBrandsMenuItem,
  clearBrandsModifiers,
  clearBrandsOptions,
]);
