const orderTable = (data, field, ascending = true) => {
  const sortedData = data.sort((a, b) => {
    if (Number.isNaN(a[field])) {
      if (ascending) {
        return a[field] - b[field];
      }
      return b[field] - a[field];
    }

    const valueA = typeof a[field] === 'string' ? a[field].toLowerCase() : a[field];
    const valueB = typeof b[field] === 'string' ? b[field].toLowerCase() : b[field];
    if (valueA < valueB) {
      return ascending ? -1 : 1;
    }
    if (valueA > valueB) {
      return ascending ? 1 : -1;
    }
    return 0;
  });
  return sortedData;
};

export default orderTable;
