import React, { useContext } from 'react';
import { useFormikContext } from 'formik';
import { RadioGroup } from '@mui/material';
import { signupFields } from '../signupFields';
import SignupContext from '../SignupContext';

const StepRadioGroup = ({ name, children, ...props }) => {
  const { handleChange, handleBlur, values, errors, touched } = useFormikContext();
  const { handleOnFieldChange } = useContext(SignupContext);

  return (
    <RadioGroup
      aria-label={name}
      name={name}
      onChange={(event) => {
        handleChange(event);
        handleOnFieldChange(signupFields[name], event.target.value);
      }}
      onBlur={handleBlur}
      value={values[name]}
      error={errors[name] && touched[name]}
      {...props}
    >
      {children}
    </RadioGroup>
  );
};

export default StepRadioGroup;
