import { createSlice } from '@reduxjs/toolkit';
import {
  asyncInitialState as initialState,
  createAsyncAction,
  createAsyncReducerHandlers,
} from '../utils/async';
import {
  fetchServiceStatus as fetchServiceStatusApi,
  updateServiceStatus as updateServiceStatusApi,
} from '../../api/serviceStatus';

export const fetchServiceStatus = createAsyncAction('fetch-serviceStatus', fetchServiceStatusApi);
export const updateServiceStatus = createAsyncAction(
  'update-serviceStatus',
  updateServiceStatusApi,
);

const serviceStatusSlice = createSlice({
  name: 'serviceStatus',
  initialState,
  reducers: {},
  extraReducers: {
    ...createAsyncReducerHandlers(fetchServiceStatus),
    ...createAsyncReducerHandlers(updateServiceStatus),
  },
});

export const { reducer: serviceStatus } = serviceStatusSlice;
