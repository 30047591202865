import React from 'react';
import { Card, CardActions, CardContent, Button } from '@mui/material';
import ErrorButton from '../Overrides/ErrorButton';
import CustomDialog from '../CustomDialog';

const ConfirmDialog = ({ handleCloseDialog, leavePage, isOpen }) =>
  isOpen && (
    <CustomDialog
      title="Unsaved changes"
      isDialogOpen={isOpen}
      handleCloseDialog={handleCloseDialog}
    >
      <Card>
        <CardContent>If you leave this page, any unsaved changes will be lost.</CardContent>
        <CardActions>
          <Button onClick={handleCloseDialog}>Close</Button>
          <ErrorButton onClick={leavePage} variant="outlined">
            Leave page
          </ErrorButton>
        </CardActions>
      </Card>
    </CustomDialog>
  );

export default ConfirmDialog;
