import React from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { Field } from 'formik';

const TimePickerField = (props) => {
  const { field, form, ...inputProps } = props;

  const onChange = (value) => {
    // eslint-disable-next-line no-underscore-dangle
    if (value && value._i.charAt(2) === ':') {
      value.set('date', 24);
      value.set('month', 11);
      value.set('year', 2020);
    }
    const isovalue = value instanceof moment ? value.toISOString() : value;
    form.setFieldValue(field.name, moment(isovalue).format());
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Field
        {...field}
        {...inputProps}
        component={TimePicker}
        value={moment.utc(field.value)}
        onChange={onChange}
      />
    </LocalizationProvider>
  );
};

TimePickerField.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  field: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  form: PropTypes.object.isRequired,
};
export default TimePickerField;
