import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import Page from '../../components/Page';
import withVenue from '../../hoc/withVenue';
import { getMobileDeviceState } from '../../store/mobiles/selectors';
import { getPrintersState } from '../../store/printers/selectors';
import EditMobileDeviceForm from '../../components/EditMobileDeviceForm';
import { clearMobileDevice, fetchMobileDevice } from '../../store/mobiles';
import { fetchTerminalDevices } from '../../store/terminals';
import { getTerminalDevicesState } from '../../store/terminals/selectors';
import { fetchPrinters } from '../../store/printers';
import shouldLoad from '../../shared/utils/shouldLoad';
import BackArrow from '../../components/BackArrow';

const useStyles = makeStyles(() => ({
  heading: {
    margin: '15px 0',
  },
}));

const EditMobile = ({ redirect }) => {
  const { mobileDeviceId } = useParams();
  const dispatch = useDispatch();
  const classes = useStyles();
  const mobileDeviceState = useSelector(getMobileDeviceState);
  const printerDeviceState = useSelector(getPrintersState);
  const terminalDevicesState = useSelector(getTerminalDevicesState);
  const { loading, data, error } = mobileDeviceState;
  const { data: printerData } = printerDeviceState;

  useEffect(() => {
    if (shouldLoad(terminalDevicesState)) dispatch(fetchTerminalDevices());
  }, [dispatch, terminalDevicesState]);

  useEffect(() => {
    if (shouldLoad(mobileDeviceState)) dispatch(fetchMobileDevice({ mobileDeviceId }));
  }, [dispatch, mobileDeviceId, mobileDeviceState]);

  useEffect(() => {
    if (shouldLoad(printerDeviceState)) dispatch(fetchPrinters());
  }, [dispatch, printerDeviceState]);

  return (
    <Page loading={loading} error={error}>
      <header>
        <BackArrow to="/mobiles" text="Mobile Devices" />
        <Typography className={classes.heading} variant="h2" component="h1">
          Edit Mobile
        </Typography>
      </header>
      {data && (
        <EditMobileDeviceForm deviceData={data} redirect={redirect} printerData={printerData} />
      )}
    </Page>
  );
};

export default withVenue(EditMobile, null, clearMobileDevice);
