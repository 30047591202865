import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { getPrinterState } from '../../store/printers/selectors';
import { fetchPrinter } from '../../store/printers';
import EditPrinterForm from '../../components/EditPrinterForm';
import Page from '../../components/Page';
import withVenue from '../../hoc/withVenue';
import shouldLoad from '../../shared/utils/shouldLoad';
import BackArrow from '../../components/BackArrow';

const useStyles = makeStyles(() => ({
  heading: {
    margin: '15px 0',
  },
}));

const EditPrinter = ({ redirect }) => {
  const { printerId } = useParams();
  const dispatch = useDispatch();
  const classes = useStyles();
  const printerState = useSelector(getPrinterState);
  const { data, loading, error } = printerState;
  const [isDirty, setIsDirty] = useState(false);

  useEffect(() => {
    const refreshInterval = setInterval(() => {
      if (!isDirty) dispatch(fetchPrinter({ printerId }));
    }, 60000);
    return () => {
      clearInterval(refreshInterval);
    };
  }, [dispatch, printerId, isDirty]);

  useEffect(() => {
    if (
      shouldLoad(printerState) ||
      (!printerState.loading && printerState?.data?.printerId !== printerId)
    )
      dispatch(fetchPrinter({ printerId }));
  }, [dispatch, printerId, printerState]);

  return (
    <Page loading={loading} error={error}>
      <header>
        <BackArrow redirect={redirect} text="Devices" />
        <Typography className={classes.heading} variant="h2" component="h1">
          Edit Printer
        </Typography>
      </header>
      {data && <EditPrinterForm printerData={data} setIsDirty={setIsDirty} />}
    </Page>
  );
};

export default withVenue(EditPrinter, '/devices');
