import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import Paper from '@mui/material/Paper';
import { Box, Button, FormControl, InputBase, MenuItem, Select, Tooltip } from '@mui/material';
import { PedalBikeRounded, Storefront } from '@mui/icons-material';
import { nanoid } from 'nanoid';
import moment from 'moment-timezone';
import { makeStyles } from '@mui/styles';
import { withStyles } from 'tss-react/mui';
import { getCardPayoutsState } from '../../store/payouts-card/selectors';
import { fetchBankHolidayDates } from '../../api/payouts-card';
import { useNotifications } from '../../shared/contexts/Notifications/useNotifications';
import { getVenueState } from '../../store/venues/selectors';
import TableCellStatus, { tableCellStatus } from '../TableCellStatus';
import TableSkeletonLoader from '../TableSkeletonLoader';
import ReportsTablePaginationActions from '../ReportsTablePaginationActions';
import useRoles from '../../hooks/useRoles';
import DownloadReport from '../DownloadReport';
import TableLink from '../TableLink';
import { customFontsClasses } from '../../theme/mui';

const showRepay = (status) => status === 'FAILED' || status === 'RETURNED';

const useStyles = makeStyles(() => ({
  body: {
    maxWidth: '1052px',
  },
  rightAlignCell: {
    textAlign: 'right',
  },
  paginationTableCell: {
    borderBottom: 'none',
    padding: '16px !important',
  },
}));

const SelectInput = withStyles(InputBase, () => ({
  input: { border: '1px solid #ced4da', padding: '10px 26px 10px 12px' },
}));

const CardPayoutsTable = ({ pagination, handleRetryPayout, payoutType }) => {
  const classes = useStyles();
  const customClasses = customFontsClasses();
  const { showErrorNotification } = useNotifications();
  const { data: venue } = useSelector(getVenueState);
  const { page, handleChangePage, handleChangeRowsPerPage, rowsPerPage } = pagination;
  const { loading, data: payouts } = useSelector(getCardPayoutsState);
  const { isSuperAdmin } = useRoles();
  const { docs } = payouts || {};
  const hasPayouts = !!payouts?.docs.length;
  const [bankHolidays, setBankHolidays] = useState();

  const filterPayoutsByType = (payout) =>
    payoutType === 'All types' ||
    (payout.type && payout.type === payoutType.toLowerCase().replace('-', ''));

  const payoutsLength = docs?.filter(filterPayoutsByType).length;

  const fetchPayoutTypeIcon = (type) => {
    switch (type) {
      case 'delivery':
        return (
          <>
            <PedalBikeRounded /> Delivery
          </>
        );
      case 'inhouse':
      default:
        return (
          <>
            <Storefront /> In-house
          </>
        );
    }
  };

  const calculatePayoutDate = (startDate, bankHolidaysArray, type, deliveryPayoutDate) => {
    const getAdjustedPayoutDate = (startDay) => {
      const payoutAdjustment = {
        1: 3, // Monday -> Thursday
        2: 3, // Tuesday -> Friday
        3: 5, // Wednesday -> Monday (skip weekend)
        4: 4, // Thursday -> Monday (skip weekend)
        5: 4, // Friday -> Tuesday (skip weekend)
        6: 3, // Saturday -> Tuesday (skip weekend)
        0: 3, // Sunday -> Wednesday
      };

      const adjustmentDays = payoutAdjustment[startDay] || 3;
      return moment(startDate).add(adjustmentDays, 'd').local();
    };

    const isBankHoliday = (payDate) =>
      payDate !== null && bankHolidaysArray?.includes(payDate.format('YYYY-MM-DD'));

    let payoutDate =
      type === 'delivery'
        ? moment(deliveryPayoutDate).local() || null
        : getAdjustedPayoutDate(moment(startDate).day()) || null;

    if (payoutDate !== null) {
      while (isBankHoliday(payoutDate)) {
        payoutDate = payoutDate.add(1, 'd');
      }
    }

    return payoutDate;
  };

  const formatPayoutDate = (payoutDate, status) => {
    if (status === 'FAILED') return '-';
    if (payoutDate === null) return 'No payout date available';

    const currentDate = moment().startOf('day');
    const daysDifference = payoutDate.diff(currentDate, 'days');

    if (daysDifference === 1) return 'Due tomorrow';
    if (daysDifference >= 2 && daysDifference <= 7) return `Due in ${daysDifference} days`;

    return payoutDate.format('DD/MM/YY');
  };

  useEffect(() => {
    const getBankHolidays = async () => {
      try {
        if (venue.state) {
          const data = await fetchBankHolidayDates(venue.state);
          setBankHolidays(data);
        }
      } catch (err) {
        showErrorNotification(err);
      }
    };

    getBankHolidays();
  }, [showErrorNotification, venue]);

  if (!payouts) return null;

  const PayoutStatus = {
    COMPLETED: <TableCellStatus status={tableCellStatus.SUCCESS} statusText="Paid" />,
    IN_PROGRESS: <TableCellStatus status={tableCellStatus.IDLE} statusText="In progress" />,
    IN_TRANSIT: <TableCellStatus status={tableCellStatus.IDLE} statusText="In transit" />,
    NOT_STARTED: <TableCellStatus status={tableCellStatus.STALL} statusText="Not started" />,
    FAILED: <TableCellStatus status={tableCellStatus.FAIL} statusText="Failed" />,
    RETURNED: <TableCellStatus status={tableCellStatus.IDLE} statusText="Returned" />,
  };

  if (!payouts) return null;

  return (
    <TableContainer className={classes.body} component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>PERIOD COVERED</TableCell>
            <TableCell className={classes.rightAlignCell}>TOTAL</TableCell>
            <TableCell>STATUS</TableCell>
            <TableCell>CHARGE</TableCell>
            <TableCell className={classes.rightAlignCell}>PAYOUT DATE</TableCell>
            <TableCell>TYPE</TableCell>
            <TableCell className={classes.rightAlignCell}>REPORTS</TableCell>
            {isSuperAdmin() && <TableCell> </TableCell>}
          </TableRow>
        </TableHead>
        {loading && <TableSkeletonLoader rows={10} cells={3} cellsSize={['small']} />}
        {!loading && hasPayouts && (
          <>
            <TableBody>
              {docs
                .filter(filterPayoutsByType)
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((payout) => {
                  const { debitId, type, pdfLink, payoutId, isStripeConnect } = payout;
                  const key = nanoid();
                  const amount = payout.amount.toLocaleString();
                  const reportAmount = payout.reportAmount.toLocaleString();
                  const debit =
                    payout.debit && payout.debit !== '0.00' ? `£${payout.debit}` : 'n/a';
                  const date =
                    type === 'delivery'
                      ? `${moment(payout.date).subtract(6, 'd').local().format('D MMM')} - ${moment(
                          payout.date,
                        )
                          .local()
                          .format('D MMM YYYY')}`
                      : moment(payout.date).local().format('D MMM YYYY');

                  const payoutDate = calculatePayoutDate(
                    payout.date,
                    bankHolidays,
                    type,
                    payout.payoutDate || moment(payout.date).add(10, 'd').toISOString(),
                  );
                  const formattedPayoutDate = formatPayoutDate(payoutDate, payout.status);

                  return (
                    <TableRow key={key}>
                      <TableCell>{date}</TableCell>
                      <TableCell className={classes.rightAlignCell}>
                        <Tooltip
                          title={`Original Report Amount: ${reportAmount} `}
                          aria-label={`Original Report Amount: ${reportAmount} `}
                        >
                          <span>£{amount}</span>
                        </Tooltip>
                      </TableCell>
                      <TableCell>{PayoutStatus[payout.status]}</TableCell>
                      <TableCell>
                        {debitId ? (
                          <Tooltip title={`Debit: ${debitId} `} aria-label={`Debit: ${debitId}`}>
                            <span>{debit}</span>
                          </Tooltip>
                        ) : (
                          debit
                        )}
                      </TableCell>
                      <TableCell className={classes.rightAlignCell}>
                        {formattedPayoutDate}
                      </TableCell>
                      <TableCell>
                        <Box
                          sx={{
                            display: 'flex',
                            textWrap: 'nowrap',
                            border: '1px solid #E0E0EB',
                            borderRadius: '2px',
                            padding: '2px 4px',
                            gap: '4px',
                            width: 'fit-content',
                          }}
                        >
                          {fetchPayoutTypeIcon(type)}
                        </Box>
                      </TableCell>
                      <TableCell className={classes.rightAlignCell}>
                        {type === 'delivery' ? (
                          <TableLink
                            onClick={() =>
                              pdfLink && window.open(pdfLink, '_blank', 'noopener,noreferrer')
                            }
                          >
                            {pdfLink && 'download.pdf'}
                          </TableLink>
                        ) : (
                          <DownloadReport
                            type="payoutsBreakdown"
                            isButton={false}
                            title="download.xlsx"
                            payoutFromDate={payout.date}
                            payoutToDate={moment(payout.date)
                              .add(1, 'd')
                              .local()
                              .format('YYYY-MM-DD')}
                          />
                        )}
                      </TableCell>
                      {isSuperAdmin() && (
                        <TableCell>
                          {showRepay(payout.status) && (
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => {
                                handleRetryPayout({ day: payout.date, payoutId, isStripeConnect });
                              }}
                            >
                              Retry
                            </Button>
                          )}
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}
            </TableBody>
          </>
        )}
        <TableFooter>
          <TableRow>
            <TableCell className={classes.paginationTableCell}>
              <FormControl>
                <Select
                  value={rowsPerPage}
                  variant="outlined"
                  size="small"
                  onChange={handleChangeRowsPerPage}
                  input={<SelectInput className={customClasses.small} />}
                >
                  <MenuItem value={10}>10 items</MenuItem>
                  <MenuItem value={100}>100 items</MenuItem>
                </Select>
              </FormControl>
            </TableCell>
            <TablePagination
              rowsPerPageOptions={[]}
              className={classes.paginationTableCell}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={ReportsTablePaginationActions}
              count={payoutsLength}
              rowsPerPage={rowsPerPage}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

export default CardPayoutsTable;
