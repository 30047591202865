import React from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import { TextField } from 'formik-mui';

const CreateCampaignForm = ({ onSubmit, initialValues }) => {
  const VenueSignupSchema = Yup.object().shape({});

  return (
    <Formik onSubmit={onSubmit} validationSchema={VenueSignupSchema} initialValues={initialValues}>
      {() => (
        <Form>
          <section>
            <Field
              component={TextField}
              name="brand"
              label="Brand"
              variant="outlined"
              fullWidth
              required
              margin="normal"
            />
            <Field
              component={TextField}
              name="brandLogo"
              label="Brand Logo"
              variant="outlined"
              fullWidth
              required
              margin="normal"
            />
            <Field
              component={TextField}
              name="campaignBanner"
              label="Campaign Banner"
              variant="outlined"
              fullWidth
              required
              margin="normal"
            />
            <Field
              component={TextField}
              name="campaignConditions"
              label="campaignConditions"
              variant="outlined"
              fullWidth
              required
              margin="normal"
            />
            <Field
              component={TextField}
              name="campaignDescription"
              label="campaignDescription"
              variant="outlined"
              fullWidth
              required
              margin="normal"
            />
            <Field
              component={TextField}
              name="campaignId"
              label="campaignId"
              variant="outlined"
              fullWidth
              disabled
              required
              margin="normal"
            />
            <Field
              component={TextField}
              name="campaignLink"
              label="campaignLink"
              variant="outlined"
              fullWidth
              required
              margin="normal"
            />
            <Field
              component={TextField}
              name="campaignName"
              label="campaignName"
              variant="outlined"
              fullWidth
              required
              margin="normal"
            />
            <Field
              component={TextField}
              name="campaignType"
              label="campaignType"
              variant="outlined"
              fullWidth
              required
              margin="normal"
            />
            <Field
              component={TextField}
              name="campaignWebDescription"
              label="campaignWebDescription"
              variant="outlined"
              fullWidth
              required
              margin="normal"
            />
            <Field
              component={TextField}
              name="campaignWebTitle"
              label="campaignWebTitle"
              variant="outlined"
              fullWidth
              required
              margin="normal"
            />
            <Field
              component={TextField}
              name="confirmationImage"
              label="confirmationImage"
              variant="outlined"
              fullWidth
              required
              margin="normal"
            />
            <Field
              component={TextField}
              name="createdAt"
              label="createdAt"
              variant="outlined"
              fullWidth
              disabled
              required
              margin="normal"
            />
            <Field
              component={TextField}
              name="expiresAt"
              label="expiresAt"
              variant="outlined"
              fullWidth
              required
              margin="normal"
            />
            <Field
              component={TextField}
              name="images"
              label="images"
              variant="outlined"
              fullWidth
              required
              margin="normal"
            />
            <Field
              component={TextField}
              name="reward"
              label="reward"
              variant="outlined"
              fullWidth
              required
              margin="normal"
            />
            <Field
              component={TextField}
              name="runningTimes"
              label="runningTimes"
              variant="outlined"
              fullWidth
              required
              margin="normal"
            />
            <Field
              component={TextField}
              name="runningTimesLabel"
              label="runningTimesLabel"
              variant="outlined"
              fullWidth
              required
              margin="normal"
            />
            <Field
              component={TextField}
              name="startAt"
              label="startAt"
              variant="outlined"
              fullWidth
              required
              margin="normal"
            />
            <Field
              component={TextField}
              name="stockLink"
              label="stockLink"
              variant="outlined"
              fullWidth
              required
              margin="normal"
            />
            <Field
              component={TextField}
              name="termsLink"
              label="termsLink"
              variant="outlined"
              fullWidth
              required
              margin="normal"
            />
            <Field
              component={TextField}
              name="updatedAt"
              label="updatedAt"
              variant="outlined"
              fullWidth
              disabled
              required
              margin="normal"
            />
          </section>
        </Form>
      )}
    </Formik>
  );
};

export default CreateCampaignForm;
