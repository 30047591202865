import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import { TextField } from 'formik-mui';
import { makeStyles } from '@mui/styles';
import { Card, CardActions, CardContent, FormGroup, Button } from '@mui/material';
import AddSchedule from '../../components/AddSchedule';
import withVenue from '../../hoc/withVenue';

const useStyles = makeStyles(() => ({
  cardActions: {
    justifyContent: 'flex-end',
  },
  card: {
    overflowY: 'auto',
  },
  cardContainer: {
    padding: '10px',
  },
  subtitle: {
    color: '#647F99',
  },
}));

const DiscountForm = ({ initialValues, onSubmit, onCancel, venue }) => {
  const classes = useStyles();
  const submitButtonLabel = 'Save';

  const DiscountsSchema = Yup.object().shape({
    name: Yup.string().min(3, 'Too Short!').required('Please enter a name'),
    amount: Yup.number().required('Please choose an amount'),
    startgroup: Yup.string().required(),
    startsAt: Yup.string().when('startgroup', {
      is: 'now',
      then: Yup.string().nullable(),
    }),
    endgroup: Yup.string().required(),
    endsAt: Yup.string().when('endgroup', {
      is: 'never',
      then: Yup.string().nullable(),
    }),
  });

  return (
    <Card className={classes.card}>
      <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={DiscountsSchema}>
        {({ dirty, setFieldValue, values, isValid }) => (
          <Form>
            <div className={classes.cardContainer}>
              <CardContent>
                <FormGroup className={classes.section}>
                  <Field
                    component={TextField}
                    type="number"
                    inputProps={{
                      min: 0,
                      step: 1,
                      max: 100,
                    }}
                    name="amount"
                    label="Discount percentage (e.g. 25%)"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    required
                  />
                </FormGroup>
                <FormGroup className={classes.section}>
                  <Field
                    component={TextField}
                    name="name"
                    label="Add a name to help you identify this discount"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    required
                  />
                </FormGroup>
              </CardContent>
              <AddSchedule values={values} setFieldValue={setFieldValue} venue={venue} />
            </div>
            <CardActions className={classes.cardActions}>
              <Button onClick={onCancel}>Cancel</Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={!isValid || !dirty}
              >
                {submitButtonLabel}
              </Button>
            </CardActions>
          </Form>
        )}
      </Formik>
    </Card>
  );
};

DiscountForm.propTypes = {
  // TODO: We should have interfaces or classes for this rule
  // eslint-disable-next-line react/forbid-prop-types
  initialValues: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default withVenue(DiscountForm);
