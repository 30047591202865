import React, { useEffect } from 'react';
import BarChartD3 from './BarChartD3';

const BarChart = ({ data, previousData, x, y, id, unit }) => {
  useEffect(() => {
    if (data && data.length > 2 && x && y && id) BarChartD3(data, previousData, x, y, id, unit);
  }, [data, previousData, x, y, id, unit]);

  return <>{data.length > 2 ? <div id={`id-${id}`} /> : null}</>;
};
export default BarChart;
