import { pick } from 'lodash';

const getCategoryGroups = (menuItems) => {
  const arrayOfGroups = [];
  if (menuItems) {
    menuItems.forEach((item) => {
      let group = arrayOfGroups.find((groups) => groups.category === item.category);
      if (!group) {
        group = { category: item.category, items: [] };
        arrayOfGroups.push(group);
      }

      group.items.push(pick(item, ['itemName', 'itemId', 'available', 'brandName']));
    });
  }
  return arrayOfGroups;
};

export default getCategoryGroups;
