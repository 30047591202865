import { pick } from 'ramda';
import { get, post, patch, del } from './httpRequest';

export const fetchDiscounts = async () => {
  const response = await get(`/discounts`);
  return response.data;
};

export const fetchDiscount = async (discountId) => {
  const response = await get(`/discounts/${discountId}`);
  return response.data;
};

export const deleteDiscount = async (discountId) => {
  const response = await del(`/discounts/${discountId}`);
  return response.data;
};

export const createDiscount = async (discount) => {
  const response = await post(`/discounts`, {
    data: discount,
  });

  return response.data;
};

export const updateDiscount = async (discountId, values) => {
  const trimmedValues = {
    ...pick(['name', 'amount', 'label', 'enabled', 'startsAt', 'endsAt', 'schedule'], values),
  };

  const response = await patch(`/discounts/${discountId}`, {
    data: { ...trimmedValues },
  });
  return response.data;
};

export const addDiscountItems = async (discountId, values) => {
  const trimmedValues = {
    ...pick(['items'], values),
  };
  const response = await patch(`/discounts/${discountId}/items`, {
    data: { ...trimmedValues, action: 'SET' },
  });
  return response.data;
};

export const removeDiscountItems = async (discountId, values) => {
  const trimmedValues = {
    ...pick(['items'], values),
  };
  const response = await patch(`/discounts/${discountId}/items`, {
    data: { ...trimmedValues, action: 'REMOVE' },
  });
  return response.data;
};
