import React from 'react';
import { Collapse, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

const useStyles = makeStyles((theme) => ({
  descriptionContainer: {
    marginBottom: theme.spacing(2),
  },
  descriptionBlue: {
    ...theme.customFonts.small,
    display: 'flex',
    alignItems: 'center',
    color: theme.customPalette.trueBlue,
    cursor: 'pointer',
  },
  description: {
    ...theme.customFonts.small,
    color: theme.customPalette.greyDarker,
    background: theme.customPalette.greyLightest,
    padding: theme.spacing(1),
    borderRadius: '8px',
  },
  chevron: {
    transform: 'scale(0.8)',
    cursor: 'pointer',
  },
}));

const CollapsableDescription = ({ title = 'Description', description }) => {
  const classes = useStyles();
  const [showDescription, setShowDescription] = React.useState(false);

  return (
    <div className={classes.descriptionContainer}>
      {description && (
        <>
          <Typography
            className={classes.descriptionBlue}
            onClick={() => setShowDescription(!showDescription)}
          >
            {title}
            {showDescription ? (
              <ExpandLess className={classes.chevron} />
            ) : (
              <ExpandMore className={classes.chevron} />
            )}
          </Typography>
          <Collapse in={showDescription} timeout="auto" unmountOnExit>
            <Typography variant="body2" className={classes.description}>
              {description}
            </Typography>
          </Collapse>
        </>
      )}
    </div>
  );
};
export default CollapsableDescription;
