import React, { useRef, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import { TextField, Switch } from 'formik-mui';
import {
  Card,
  CardContent,
  FormControlLabel,
  FormGroup,
  FormControl,
  MenuItem,
  DialogActions,
  Button,
  Checkbox as MuiCheckbox,
} from '@mui/material';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  section: {
    paddingBottom: '15px',
    marginBottom: '15px',
    borderBottom: '1px solid #f0f4f8',
  },
}));

const IngredientForm = ({ onSubmit, onCancel, formAction, ingredientData }) => {
  const classes = useStyles();
  const formikRef = useRef();
  const ingredientTypes = ['DRINK', 'FOOD'];
  const submitButtonLabel = formAction === 'create' ? 'Create' : 'Update';
  const { ingredientName, isAlcoholic, available, type, readonly, label } = ingredientData || {};
  const [useNameAsLabel, setUseNameAsLabel] = useState(
    formAction === 'create' ? label === ingredientName : true,
  );

  const initialValues = {
    ingredientName: ingredientName || '',
    isAlcoholic: isAlcoholic || false,
    available: available || true,
    type: type || 'DRINK',
    label: label || '',
  };

  const IngredientSchema = Yup.object().shape({
    ingredientName: Yup.string().min(3, 'Too Short!').required('Please enter a name'),
    type: Yup.string().required('Please choose a type'),
  });

  const handleUseNameAsLabelCheck = () => {
    setUseNameAsLabel(!useNameAsLabel);
    if (!useNameAsLabel) {
      formikRef.current.setFieldValue('label', formikRef.current.values.ingredientName);
    }
  };

  return (
    <Card>
      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        enableReinitialize
        onSubmit={onSubmit}
        validationSchema={IngredientSchema}
      >
        {({ values, dirty, isValid, resetForm, setFieldValue }) => (
          <>
            <Form>
              <CardContent>
                <section>
                  <FormGroup className={classes.section}>
                    <FormControlLabel
                      control={
                        <Field
                          component={Switch}
                          color="primary"
                          type="checkbox"
                          name="available"
                          id="ingredient_available"
                          checked={values.available}
                        />
                      }
                      label="Available"
                    />
                    <FormControlLabel
                      control={
                        <Field
                          component={Switch}
                          color="primary"
                          type="checkbox"
                          name="isAlcoholic"
                          id="ingredient_isAlcoholic"
                          disabled={readonly}
                        />
                      }
                      label="Alcoholic"
                    />
                  </FormGroup>
                  <section>
                    <FormGroup>
                      <FormControl variant="outlined">
                        <Field
                          component={TextField}
                          select
                          label="Type"
                          variant="outlined"
                          fullWidth
                          name="type"
                          disabled={readonly}
                          inputProps={{
                            id: 'ingredient_type',
                          }}
                        >
                          {ingredientTypes.map((ingredientType) => (
                            <MenuItem
                              data-testid={`type:${ingredientType}`}
                              value={ingredientType}
                              key={ingredientType}
                            >
                              {ingredientType}
                            </MenuItem>
                          ))}
                        </Field>
                      </FormControl>
                    </FormGroup>
                  </section>
                </section>
                <FormGroup>
                  <Field
                    component={TextField}
                    name="ingredientName"
                    id="ingredient_name"
                    label="Internal Name"
                    variant="outlined"
                    fullWidth
                    required
                    disabled={readonly}
                    margin="normal"
                    onChange={({ target }) => {
                      if (useNameAsLabel) {
                        setFieldValue('label', target.value);
                      }
                      setFieldValue('ingredientName', target.value);
                    }}
                  />
                  <FormControlLabel
                    control={<MuiCheckbox name="copyProductNameToLabel" color="primary" />}
                    checked={useNameAsLabel}
                    onChange={handleUseNameAsLabelCheck}
                    label="Use Name as Label"
                  />
                  <Field
                    component={TextField}
                    name="label"
                    label="External Label"
                    variant="outlined"
                    fullWidth
                    required
                    disabled={initialValues.readonly || useNameAsLabel}
                    margin="normal"
                    value={values.label}
                  />
                </FormGroup>
              </CardContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    resetForm();
                    onCancel();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={!isValid || !dirty}
                >
                  {submitButtonLabel}
                </Button>
              </DialogActions>
            </Form>
          </>
        )}
      </Formik>
    </Card>
  );
};

export default IngredientForm;
