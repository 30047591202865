export default [
  {
    postcode: 'W12 8DF',
    openings: [
      'Monday: 5:00 AM – 10:30 PM',
      'Tuesday: 5:00 AM – 10:30 PM',
      'Wednesday: 5:00 AM – 10:30 PM',
      'Thursday: 5:00 AM – 10:30 PM',
      'Friday: 5:00 AM – 12:00 AM',
      'Saturday: 12:00 PM – 12:00 AM',
      'Sunday: 12:00 – 9:00 PM',
    ],
    venuePath: 'the-hawks-nest-w12',
    address: "Goldhawk Rd, Shepherd's Bush, London W12 8DF, UK",
    country: 'GB',
    name: "The Mock's Test",
    city: 'London',
    venueId: '1',
    chain: 'NONE',
    description: 'Nice Pub',
    campaign: 'NONE',
    latitude: 51.5024189,
    phone: '02085555555',
    type: 'BAR',
  },
  {
    postcode: 'W12 8DF',
    openings: [
      'Monday: 5:00 AM – 10:30 PM',
      'Tuesday: 5:00 AM – 10:30 PM',
      'Wednesday: 5:00 AM – 10:30 PM',
      'Thursday: 5:00 AM – 10:30 PM',
      'Friday: 5:00 AM – 12:00 AM',
      'Saturday: 12:00 PM – 12:00 AM',
      'Sunday: 12:00 – 9:00 PM',
    ],
    venuePath: 'the-hawks-nest-w12',
    address: "Goldhawk Rd, Shepherd's Bush, London W12 8DF, UK",
    country: 'GB',
    name: "The King's Mock",
    city: 'London',
    venueId: '2',
    chain: 'NONE',
    description: 'Nice Pub',
    campaign: 'NONE',
    stripeAccount: '123456',
    latitude: 51.5024189,
    phone: '02085555555',
    type: 'BAR',
  },
];
