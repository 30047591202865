import React from 'react';
import { Formik, Form, Field } from 'formik';
import { Button, Box, InputAdornment } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { TextField } from 'formik-mui';
import * as Yup from 'yup';

const useStyles = makeStyles((theme) => ({
  grid: {
    gap: theme.spacing(2),
  },
}));

const CustomPaymentForm = ({ onSubmit }) => {
  const classes = useStyles();

  const CustomPaymentSchema = Yup.object().shape({
    amount: Yup.number()
      .min(1, 'Amount mnust be at least 1')
      .required('Amount is required')
      .max(1000, 'Amount cannot be greater than 1000'),
    reference: Yup.string().required('Reference is required'),
    description: Yup.string().required('Description is required'),
  });

  return (
    <>
      <Formik
        initialValues={{ amount: 1, reference: '', description: '' }}
        validationSchema={CustomPaymentSchema}
        enableReinitialize
        validateOnMount
        onSubmit={onSubmit}
      >
        {({ isValid }) => (
          <Form>
            <Box display="flex" justifyContent="space-between" alignItems="flex-start" ml={2}>
              <Box
                mr={2}
                m={-2}
                display="flex"
                justifyContent="flex-start"
                className={classes.grid}
              >
                <Field
                  component={TextField}
                  type="number"
                  name="amount"
                  size="small"
                  label="Amount"
                  variant="outlined"
                  placeholder="0"
                  InputProps={{
                    min: 1,
                    max: 1000,
                    step: 1,
                    startAdornment: <InputAdornment position="start">£</InputAdornment>,
                  }}
                  required
                  margin="normal"
                />
                <Field
                  component={TextField}
                  name="reference"
                  size="small"
                  label="Payment reference"
                  variant="outlined"
                  placeholder=""
                  required
                  margin="normal"
                />
                <Field
                  component={TextField}
                  name="description"
                  size="small"
                  label="Payment description"
                  variant="outlined"
                  placeholder=""
                  required
                  margin="normal"
                />
              </Box>
              <Button disabled={!isValid} variant="contained" color="primary" type="submit">
                Make payment
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default CustomPaymentForm;
