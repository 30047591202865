import { Button, Card, CardContent, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { NavLink } from 'react-router-dom';
import DiscountItem from './DiscountItem';

const useStyles = makeStyles((theme) => ({
  discountCard: {
    margin: '15px 0',
    fontSize: '0.875em',
    '& p': {
      margin: '0.5rem',
    },
  },
  column: {
    flexBasis: '50%',
  },
  button: {
    margin: '1.5rem 0',
  },
  itemContainer: {
    margin: theme.spacing(1),
  },
  itemCategory: {
    color: '#647F99',
  },
  item: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(1),
  },
  itemRight: {
    display: 'flex',
    gap: theme.spacing(1),
    alignItems: 'center',
  },
  smallButton: {
    padding: '5px',
    minWidth: '39px',
  },
}));

function DiscountItems({ discount, items, onRemove }) {
  const { discountId } = discount;
  const classes = useStyles();

  return (
    <Card className={classes.discountCard}>
      <CardContent>
        <Typography variant="h4">Items</Typography>
        <Button
          variant="contained"
          color="primary"
          component={NavLink}
          className={classes.button}
          to={`/item-discount/${discountId}/addItems`}
        >
          Add items
        </Button>
        {items?.map((item) => {
          const { discount: itemDiscount, itemId } = item || {};
          return (
            itemDiscount?.discountId === discount.discountId && (
              <div className={classes.itemContainer} key={itemId}>
                <DiscountItem item={item}>
                  <Button
                    variant="outlined"
                    className={classes.smallButton}
                    onClick={() => onRemove(itemId)}
                  >
                    -
                  </Button>
                </DiscountItem>
              </div>
            )
          );
        })}
      </CardContent>
    </Card>
  );
}

export default DiscountItems;
