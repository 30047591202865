import React from 'react';
import { useSelector } from 'react-redux';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Hidden, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment from 'moment-timezone';

import { Link } from 'react-router-dom';
import TableSkeletonLoader from '../../components/TableSkeletonLoader';
import { getDiscountsState } from '../../store/itemDiscounts/selectors';
import DiscountLozenge from './DiscountLozenge';

const useStyles = makeStyles((theme) => ({
  offer: {
    marginBottom: '20px',
    display: 'flex',
    flexDirection: 'column',
  },
  label: {
    color: theme.palette.grey['800'],
  },
}));

const DiscountsTable = () => {
  const classes = useStyles();
  const { loading, data: discounts } = useSelector(getDiscountsState);

  if (!discounts) return null;

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell size="small">
              <Typography variant="h3">Offer</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="h3">Status</Typography>
            </TableCell>
            <Hidden mdDown>
              <TableCell>
                <Typography variant="h3">Starts</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h3">Ends</Typography>
              </TableCell>
            </Hidden>
          </TableRow>
        </TableHead>
        {loading && <TableSkeletonLoader rows={10} cells={3} cellsSize={['small']} />}
        {!loading && discounts && (
          <>
            <TableBody>
              {discounts.map(({ name, discountId, label, status, startsAt, endsAt }) => {
                const startsAtDate = moment(startsAt).local();
                const endsAtDate = moment(endsAt).local();
                const startsAtDateDisplay = startsAtDate.isValid()
                  ? startsAtDate.format('DD/MM/YY')
                  : '-';
                const endsAtDateDisplay = endsAtDate.isValid()
                  ? endsAtDate.format('DD/MM/YY')
                  : 'Never';

                return (
                  <TableRow key={discountId}>
                    <TableCell>
                      <div className={classes.offer}>
                        <Link to={`/item-discount/${discountId}`}>
                          <Typography variant="body2">{name}</Typography>
                        </Link>
                        <Typography variant="body2" className={classes.label}>
                          {label}
                        </Typography>
                      </div>
                    </TableCell>
                    <TableCell>
                      <DiscountLozenge status={status} />
                    </TableCell>
                    <Hidden mdDown>
                      <TableCell>{startsAtDateDisplay}</TableCell>
                      <TableCell>{endsAtDateDisplay}</TableCell>
                    </Hidden>
                  </TableRow>
                );
              })}
            </TableBody>
          </>
        )}
      </Table>
    </TableContainer>
  );
};

export default DiscountsTable;
