import React, { useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { TextField } from 'formik-mui';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardContent, FormGroup, MenuItem } from '@mui/material';
import { fetchAvailableQrcodes } from '../../store/qrcodes';
import { getAvailableQrcodesState } from '../../store/qrcodes/selectors';
import UniversalSave from '../UniversalSave';

const QrcodeForm = ({ onSubmit }) => {
  const QrcodeSchema = Yup.object().shape({
    qrCodeId: Yup.string().min(3, 'Too Short!').required('Please choose a qrcode'),
  });
  const dispatch = useDispatch();

  const { data: availableQrcodes } = useSelector(getAvailableQrcodesState);

  const initialValues = {
    qrCodeId: '',
  };

  useEffect(() => {
    dispatch(fetchAvailableQrcodes());
  }, [dispatch]);

  return (
    <Card>
      <Formik onSubmit={onSubmit} validationSchema={QrcodeSchema} initialValues={initialValues}>
        {({ values, dirty, isValid, resetForm, setFieldValue, errors }) => (
          <>
            <UniversalSave
              isValid={isValid}
              errors={errors}
              dirty={dirty}
              onSave={() => onSubmit(values)}
              onDiscard={resetForm}
            />
            <Form>
              <CardContent>
                <section>
                  <FormGroup>
                    {availableQrcodes && (
                      <Field
                        component={TextField}
                        select
                        fullWidth
                        name="qrCodeId"
                        label="Select Qrcode"
                        variant="outlined"
                        margin="normal"
                        required
                        onChange={({ target }) => setFieldValue('qrCodeId', target.value)}
                      >
                        {availableQrcodes.map((qrCode) => (
                          <MenuItem value={`${qrCode.qrCodeId}`} key={qrCode.qrCodeId}>
                            {qrCode.qrCodeId}
                          </MenuItem>
                        ))}
                      </Field>
                    )}
                  </FormGroup>
                </section>
              </CardContent>
            </Form>
          </>
        )}
      </Formik>
    </Card>
  );
};

export default QrcodeForm;
