import React from 'react';
import { Card, CardContent, Typography, MenuItem } from '@mui/material';
import * as Yup from 'yup';
import { Form, Formik, Field } from 'formik';
import { TextField } from 'formik-mui';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@mui/styles';
import UniversalSave from '../../../components/UniversalSave';
import withVenue from '../../../hoc/withVenue';
import { useNotifications } from '../../../shared/contexts/Notifications/useNotifications';
import { getErrorMessage } from '../../../shared/utils/errors';
import { fetchVenueSettings, updateVenueAdditionalCharges } from '../../../store/venues';
import withSettings from '../../../hoc/withSettings';
import BackArrow from '../../../components/BackArrow';
import Page from '../../../components/Page';
import PageHeader from '../../../components/PageHeader';

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: '8px',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    '& > :nth-child(2), & > :nth-child(4), & > :nth-child(6)': {
      marginBottom: '10px',
    },
  },
  field: {
    width: '100%',
    margin: 0,
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.customPalette.grey,
    },
    '& .MuiSelect-icon': {
      color: theme.customPalette.greyDark,
    },
  },
  title: {
    ...theme.customFonts.largeBold,
    marginBottom: '16px',
  },
  subtitle: {
    ...theme.customFonts.label,
    color: theme.customPalette.greyDarker,
  },
}));

const additionalChargeSchema = Yup.object().shape({
  additionalChargesValue: Yup.string()
    .oneOf(['OPTIONAL', 'MANDATORY', 'DISABLED'])
    .required('Please choose if the additional charge is optional or mandatory'),
  additionalChargesType: Yup.string()
    .oneOf(['AMOUNT', 'PERCENTAGE'])
    .required('Please choose between fixed and percentage'),
  additionalChargesAmount: Yup.number().required('Please choose additional charge rate'),
  additionalChargesLabel: Yup.string().min(3).required('Please use at least 3 characters'),
});

// This is to ensure that when fields are disabled, we do not have Universal Save hanging because the
// BE restructures data and confuses it
const ensureValidationSafety = (setFieldValue) => {
  setFieldValue('additionalChargesType', 'AMOUNT');
  setFieldValue('additionalChargesAmount', 0);
  setFieldValue('additionalChargesLabel', 'NONE');
};

const getChargeSymbol = (value) => (value === 'AMOUNT' ? '£' : '%');

const AdditionalChargeSettings = ({ setting, redirect }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { showErrorNotification, showSuccessNotification } = useNotifications();
  const {
    value: additionalChargesValue,
    amount: additionalChargesAmount,
    type: additionalChargesType,
    label: additionalChargesLabel,
  } = setting;
  const initialValues = {
    additionalChargesValue,
    additionalChargesAmount,
    additionalChargesType,
    additionalChargesLabel,
  };
  const handleOnSubmit = async (values) => {
    try {
      const {
        additionalChargesValue: chargeValue,
        additionalChargesAmount: chargeAmount,
        additionalChargesLabel: chargeLabel,
        additionalChargesType: chargeType,
      } = values;
      if (additionalChargesValue !== undefined) {
        await dispatch(
          updateVenueAdditionalCharges({
            values: {
              value: chargeValue !== 'DISABLED' ? chargeValue : 'DISABLED',
              amount: chargeValue !== 'DISABLED' ? chargeAmount : 0,
              label: chargeValue !== 'DISABLED' ? chargeLabel : 'NONE',
              type: chargeValue !== 'DISABLED' ? chargeType : 'AMOUNT',
            },
          }),
        );
      }
      dispatch(fetchVenueSettings());
      showSuccessNotification('Successfully updated additional charge settings');
    } catch (error) {
      showErrorNotification(getErrorMessage(error));
    }
  };

  return (
    <>
      <PageHeader>
        <BackArrow redirect={redirect} text="Settings" />
        <Typography className={classes.title}>Additional Charge</Typography>
      </PageHeader>
      <Page>
        <Formik
          initialValues={initialValues}
          enableReinitialize
          validationSchema={additionalChargeSchema}
        >
          {({ setFieldValue, values, resetForm, errors, dirty, isValid }) => (
            <>
              <UniversalSave
                isValid={isValid}
                dirty={dirty}
                onSave={() => {
                  handleOnSubmit(values);
                }}
                onDiscard={resetForm}
                errors={errors}
              />
              <Form>
                <Card className={classes.card}>
                  <CardContent className={classes.container}>
                    <Typography className={classes.subtitle}>Additional charge</Typography>
                    <Field
                      className={classes.field}
                      name="additionalChargesValue"
                      select
                      component={TextField}
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                    >
                      <MenuItem value="OPTIONAL" key="OPTIONAL">
                        OPTIONAL
                      </MenuItem>
                      <MenuItem value="MANDATORY" key="MANDATORY">
                        MANDATORY
                      </MenuItem>
                      <MenuItem
                        value="DISABLED"
                        key="DISABLED"
                        onClick={() => {
                          ensureValidationSafety(setFieldValue);
                        }}
                      >
                        DISABLED
                      </MenuItem>
                    </Field>
                    <Typography className={classes.subtitle}>Type</Typography>
                    <Field
                      className={classes.field}
                      name="additionalChargesType"
                      select
                      component={TextField}
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      disabled={values.additionalChargesValue === 'DISABLED'}
                    >
                      <MenuItem value="AMOUNT" key="AMOUNT">
                        FIXED AMOUNT
                      </MenuItem>
                      <MenuItem value="PERCENTAGE" key="PERCENTAGE">
                        PERCENTAGE
                      </MenuItem>
                    </Field>
                    <Typography className={classes.subtitle}>
                      Rate {getChargeSymbol(values.additionalChargesType)}
                    </Typography>
                    <Field
                      className={classes.field}
                      name="additionalChargesAmount"
                      variant="outlined"
                      type="number"
                      value={values.additionalChargesAmount}
                      component={TextField}
                      disabled={values.additionalChargesValue === 'DISABLED'}
                    />
                    <Typography className={classes.subtitle}>
                      Additional charge label (appears at checkout)
                    </Typography>
                    <Field
                      className={classes.field}
                      name="additionalChargeLabel"
                      variant="outlined"
                      value={values.additionalChargesLabel}
                      component={TextField}
                      onChange={(event) =>
                        setFieldValue('additionalChargesLabel', event.target.value)
                      }
                      disabled={values.additionalChargesValue === 'DISABLED'}
                    />
                  </CardContent>
                </Card>
              </Form>
            </>
          )}
        </Formik>
      </Page>
    </>
  );
};

const additionalChargeWithVenue = withVenue(AdditionalChargeSettings, '/settings');
export default withSettings(additionalChargeWithVenue, 'ADDITIONAL_CHARGES');
