import { formatTimeString } from './deliveryOpeningHours';

export const generateTimeOptions = ({ currH, currM, prevH = 5, prevM = 0 }) => {
  const timeOptions = [];
  let h = prevH;
  let m = prevM;
  let firstIteration = true;
  while (firstIteration || h !== 5 || (h === 5 && m !== 0)) {
    let isNextDay = false;
    if ((h < 5 && h > 0) || (h === 0 && m >= 15)) {
      isNextDay = true;
    }
    if (currH !== null && currM !== null && h === currH && m === currM) {
      timeOptions.push({ time: formatTimeString(h, m), isNextDay });
    } else if (h !== null && m !== null && m % 15 === 0) {
      timeOptions.push({ time: formatTimeString(h, m), isNextDay });
    }
    m += 1;
    if (m >= 60) {
      m = 0;
      h = (h + 1) % 24;
    }
    firstIteration = false;
  }
  timeOptions.push({ time: formatTimeString(4, 59), isNextDay: true });
  return timeOptions;
};

export default generateTimeOptions;
