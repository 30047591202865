import React from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import { TextField } from 'formik-mui';
import { makeStyles } from '@mui/styles';
import { Button, Card, CardContent, DialogActions, FormGroup, Grid } from '@mui/material';
import ModifierOptions from '../ModifierOptions';

const useStyles = makeStyles(() => ({
  cardActions: {
    justifyContent: 'flex-end',
  },
}));

const ModifierForm = ({ modifierData, onSubmit, onReorderOptions, formAction, onCancel }) => {
  const classes = useStyles();
  const submitButtonLabel = formAction === 'create' ? 'Create' : 'Update';

  const {
    modifierName,
    label,
    modifierItems,
    minSelections,
    maxSelections,
    readonly = false,
  } = modifierData || {};

  const initialValues = {
    modifierName: modifierName || '',
    label: label || '',
    modifierItems:
      modifierItems?.map((modItem) => ({
        ...modItem,
        multiMax: modItem.multiMax || 1,
      })) || [],
    minSelections: minSelections || 0,
    maxSelections: maxSelections || 1,
  };

  const ModifierSchema = Yup.object().shape({
    modifierName: Yup.string().min(3, 'Too Short!').required('Please enter a name'),
    label: Yup.string().min(3, 'Too Short!').required('Please enter a label'),
    maxSelections: Yup.number()
      .when(['minSelections'], {
        is: 0,
        then: Yup.number().min(1),
        otherwise: Yup.number().min(Yup.ref('minSelections')),
      })
      .lessThanItems(),

    minSelections: Yup.number().min(0).lessThanItems().required('Please enter a minimum'),
    modifierItems: Yup.array()
      .of(
        Yup.object().shape({
          itemId: Yup.string(),
          itemName: Yup.string().required('Please choose an item'),
          itemPrice: Yup.number()
            .min(0, 'Price must be greater than 0')
            .typeError('Price must be a number')
            .required('Please choose a price for this item'),
          multiMax: Yup.number().lessThanMaxSelections(),
        }),
      )
      .min(1, 'Please choose at least one item'),
  });

  return (
    <Card className={classes.card}>
      <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={ModifierSchema}>
        {({ dirty, values, setFieldValue, isValid, resetForm }) => (
          <>
            <Form>
              <CardContent>
                <FormGroup>
                  <section>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6}>
                        <Field
                          component={TextField}
                          name="modifierName"
                          label="Internal Name"
                          variant="outlined"
                          fullWidth
                          required
                          margin="normal"
                          disabled={readonly}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Field
                          component={TextField}
                          name="label"
                          label="External Label"
                          variant="outlined"
                          fullWidth
                          required
                          margin="normal"
                          disabled={readonly}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Field
                          component={TextField}
                          type="number"
                          name="minSelections"
                          label="Minimum number of selections"
                          variant="outlined"
                          fullWidth
                          required
                          margin="normal"
                          disabled={readonly}
                          inputProps={{ step: '1', min: 0 }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Field
                          component={TextField}
                          type="number"
                          name="maxSelections"
                          label="Maximum number of selections"
                          variant="outlined"
                          fullWidth
                          required
                          margin="normal"
                          disabled={readonly}
                          inputProps={{ step: '1', min: 1 }}
                        />
                      </Grid>
                    </Grid>
                  </section>
                </FormGroup>
                <FormGroup className={classes.section}>
                  <ModifierOptions
                    readonly={readonly}
                    setFieldValue={setFieldValue}
                    itemOptions={values.modifierItems}
                    allowReordering={!readonly && values.modifierItems.length > 1}
                    onReorder={onReorderOptions}
                  />
                </FormGroup>
              </CardContent>
              <DialogActions className={classes.dialogActions}>
                <Button
                  onClick={() => {
                    resetForm();
                    onCancel();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={readonly || !isValid || !dirty}
                >
                  {submitButtonLabel}
                </Button>
              </DialogActions>
            </Form>
          </>
        )}
      </Formik>
    </Card>
  );
};

export default ModifierForm;
