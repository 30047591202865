import { createSelector } from '@reduxjs/toolkit';
import { ascend, prop, sortWith } from 'ramda';

const qrcodeSort = sortWith([ascend(prop('qrCodeId'))]);
export const getQrcodesState = (state) => state.qrcodes;
export const getAvailableQrcodesState = (state) => state.availableQrcodes;

export const getQrcodeState = (state) => state.qrcode;

export const getQrcodes = createSelector(
  getQrcodesState,
  (qrcodesState) => qrcodesState.data && qrcodeSort(qrcodesState.data),
);

export const getAvailableQrcodes = createSelector(
  getAvailableQrcodesState,
  (availableQrcodesState) => availableQrcodesState.data && qrcodeSort(availableQrcodesState.data),
);

export const getQrcodesObj = createSelector(getQrcodesState, (qrcodes) => {
  if (!qrcodes.data) return null;
  return qrcodes.data.reduce((acc, qrcode) => {
    const { qrCodeId, name, iccid, destination, imei } = qrcode;
    return { [qrCodeId]: { qrCodeId, name, destination, iccid, imei }, ...acc };
  }, {});
});
