import React from 'react';

import StepGraphic from '../StepGraphic';
import StepButton from '../StepButton';
import StepQuestion from '../StepQuestion';

const StepBusinessDetailsThree = () => (
  <>
    <StepQuestion
      question="Almost done!"
      description="Review your details and complete in the next step."
      center
    />
    <StepGraphic fileName="business-details-complete" />
    <StepButton text="Finish" />
  </>
);

export default StepBusinessDetailsThree;
