const colorPalette = [
  '#54ad84',
  '#f030d0',
  '#c787de',
  '#03b25e',
  '#c8bffa',
  '#e4a910',
  '#ec181c',
  '#9987aa',
  '#521a08',
  '#3fb533',
  '#4d95c8',
  '#174a81',
  '#e6cec7',
  '#d96f01',
  '#1f8787',
  '#e8cdcd',
  '#7e9df4',
  '#d6d047',
  '#e0baf8',
];

export default colorPalette;
