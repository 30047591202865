import React from 'react';
import TableCellStatus, { tableCellStatus } from '../../components/TableCellStatus';

export default {
  ENABLED: <TableCellStatus status={tableCellStatus.SUCCESS} statusText="Enabled" />,
  DISABLED: <TableCellStatus status={tableCellStatus.FAIL} statusText="Disabled" />,
  PAID: <TableCellStatus status={tableCellStatus.IDLE} statusText="Paid" />,
  OPEN: <TableCellStatus status={tableCellStatus.SUCCESS} statusText="Open" />,
  SETTLED: <TableCellStatus status={tableCellStatus.STALL} statusText="Settled" />,
  EXPIRED: <TableCellStatus status={tableCellStatus.FAIL} statusText="Expired" />,
  UNSETTLED: <TableCellStatus status={tableCellStatus.FAIL} statusText="Unsettled" />,
};
