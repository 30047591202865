import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  iconButton: {
    position: 'relative',
    padding: 0,
    '&:disabled': {
      opacity: 0.5,
    },
  },
  circleIcon: {
    backgroundColor: 'white',
    position: 'absolute',
    top: 19,
    right: 0,
    padding: 0,
    color: '#181C43',
    '&.Mui-disabled': {
      color: '#A9A9A9',
    },
  },
}));

const ResetCircle = ({
  brandFieldValue,
  initialDefaultValue,
  setFieldValue,
  ariaLabel = 'Reset',
  altText = 'Reset',
  disabled = false,
  tooltipTitle,
}) => {
  const classes = useStyles();
  return (
    <IconButton
      onClick={() => setFieldValue(brandFieldValue, initialDefaultValue)}
      aria-label={ariaLabel}
      className={classes.iconButton}
      disabled={disabled}
    >
      <Tooltip title={tooltipTitle}>
        <ChangeCircleIcon className={classes.circleIcon} alt={altText} />
      </Tooltip>
    </IconButton>
  );
};

export default ResetCircle;
