import { sortOrderEnums } from '../shared/utils/sortOrder';
import { get, post, patch } from './httpRequest';

const fetchExternalPayouts = async ({
  from = 0,
  size = 50,
  gte,
  lte,
  order = sortOrderEnums.DESC,
}) => {
  const dateFrom = gte || new Date('12 April 2021').toISOString();
  const dateTo = lte || new Date().toISOString(); // Start of Payouts integration
  const params = `gte=${dateFrom}&lte=${dateTo}&order=${order}&from=${from}&size=${size}`;
  const response = await get(`/banking-slips?${params}`);

  return response.data;
};

const createExternalPayout = async (payout) => {
  const response = await post(`/banking-slips`, { data: payout });

  return response.data;
};

const updateExternalPayout = async (recordId, values) => {
  const response = await patch(`/banking-slips/${recordId}`, {
    data: values,
  });
  return response.data;
};

export { fetchExternalPayouts, createExternalPayout, updateExternalPayout };
