import React from 'react';
import { Box, Typography, Divider } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import moment from 'moment-timezone';

import { ReactComponent as ExternalLinkIcon } from './external-link.svg';

import ratingsFilters from './ratingsFilters';
import TableLink from '../../components/TableLink';

const RatingFooter = withStyles(Box, (theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    color: theme.palette.grey[600],
  },
}));

const Review = ({ reviewData }) => {
  const { rating, review, name, createdAt, orderId } = reviewData;

  const { icon, label } = ratingsFilters.find(({ value }) => value === rating) || {};
  const finalDate = moment(createdAt).format('DD/MM/YYYY');

  return (
    <>
      <Box pb={4}>
        <Box display="flex" mb={1}>
          <Box flexGrow={1}>
            <Typography variant="body2" component="b">
              {icon} {label}
            </Typography>
          </Box>
          {name !== 'Delivery' && (
            <Box>
              <TableLink to={`orders/${orderId}`}>
                order details <ExternalLinkIcon />
              </TableLink>
            </Box>
          )}
        </Box>
        <Box mb={2}>{review !== 'NONE' && <Typography variant="body2">{review}</Typography>}</Box>
        <RatingFooter>
          <Typography variant="subtitle2" component="span">
            {name}
          </Typography>
          <Typography variant="subtitle2" component="span">
            {finalDate}
          </Typography>
        </RatingFooter>
      </Box>
      <Divider />
    </>
  );
};

export default Review;
