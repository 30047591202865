import { createSlice } from '@reduxjs/toolkit';
import {
  asyncInitialState as initialState,
  createAsyncAction,
  createAsyncReducerHandlers,
} from '../utils/async';
import {
  fetchDevices as fetchDevicesApi,
  fetchAvailableDevices as fetchAvailableDevicesApi,
  fetchAvailableDeviceCapabilities as fetchAvailableDeviceCapabilitiesApi,
  updateDevice as updateDeviceApi,
  fetchDevice as fetchDeviceApi,
  deleteDevice as deleteDeviceApi,
  fetchAllAvailableDevicesApi,
} from '../../api/devices';

export const fetchDevices = createAsyncAction('fetch-devices', fetchDevicesApi);
export const fetchAvailableDevices = createAsyncAction(
  'fetch-available-devices',
  fetchAvailableDevicesApi,
);
export const fetchAllAvailableDevices = createAsyncAction(
  'fetch-all-available-devices',
  fetchAllAvailableDevicesApi,
);

export const fetchAvailableDeviceCapabilities = createAsyncAction(
  'fetch-available-device-capabilities',
  fetchAvailableDeviceCapabilitiesApi,
);
export const updateDevice = createAsyncAction('update-device', updateDeviceApi);
export const fetchDevice = createAsyncAction('get-device', fetchDeviceApi);
export const deleteDevice = createAsyncAction('delete-device', deleteDeviceApi);

const devicesSlice = createSlice({
  name: 'devices',
  initialState,
  reducers: {
    clearDevices(state) {
      delete state.data;
    },
  },
  extraReducers: createAsyncReducerHandlers(fetchDevices),
});

const availableDevicesSlice = createSlice({
  name: 'availableDevices',
  initialState,
  reducers: {},
  extraReducers: {
    ...createAsyncReducerHandlers(fetchAvailableDevices),
    ...createAsyncReducerHandlers(fetchAllAvailableDevices),
  },
});

const availableDeviceCapabilitiesSlice = createSlice({
  name: 'availableDeviceCapabilities',
  initialState,
  reducers: {},
  extraReducers: createAsyncReducerHandlers(fetchAvailableDeviceCapabilities),
});

const deviceSlice = createSlice({
  name: 'device',
  initialState,
  reducers: {
    clearDevice(state) {
      delete state.data;
    },
  },
  extraReducers: {
    ...createAsyncReducerHandlers(updateDevice),
    ...createAsyncReducerHandlers(fetchDevice),
    ...createAsyncReducerHandlers(deleteDevice),
  },
});
export const { clearDevices } = devicesSlice.actions;
export const { clearDevice } = deviceSlice.actions;

export const { reducer: availableDeviceCapabilities } = availableDeviceCapabilitiesSlice;
export const { reducer: availableDevices } = availableDevicesSlice;
export const { reducer: devices } = devicesSlice;
export const { reducer: device } = deviceSlice;
