import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';

import { getErrorMessage } from '../../shared/utils/errors';
import { useNotifications } from '../../shared/contexts/Notifications/useNotifications';
import { clearAvailableQrCodes, fetchAvailableQrcodes, updateQrcode } from '../../store/qrcodes';
import QrcodeForm from '../../components/QrcodeForm';
import withVenue from '../../hoc/withVenue';
import BackArrow from '../../components/BackArrow';

const useStyles = makeStyles(() => ({
  heading: {
    margin: '15px 0',
  },
}));

const AddQrcode = ({ redirect }) => {
  const { showErrorNotification, showSuccessNotification } = useNotifications();
  const dispatch = useDispatch();
  const { push } = useHistory();

  const goToPage = useCallback(
    (to) => {
      push(to || '/');
    },
    [push],
  );

  useEffect(() => {
    dispatch(fetchAvailableQrcodes());
  }, [dispatch]);

  const handleOnSubmit = async (values) => {
    try {
      await dispatch(updateQrcode(values));
      showSuccessNotification('Qrcode has been added successfully');
      goToPage('/qrcodes');
    } catch (error) {
      showErrorNotification(getErrorMessage(error));
    }
  };

  const classes = useStyles();

  return (
    <>
      <header>
        <BackArrow redirect={redirect} text="Qr codes" />
        <Typography className={classes.heading} variant="h2" component="h1">
          Add Qrcode
        </Typography>
      </header>
      <QrcodeForm
        formAction="update"
        initialValues={{
          qrCodeId: '',
        }}
        onSubmit={handleOnSubmit}
      />
    </>
  );
};

export default withVenue(AddQrcode, '/qrcodes', clearAvailableQrCodes);
