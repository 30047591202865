import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { clearLiveOrders, fetchOrders, fetchOrdersPage } from '../../store/orders';
import { getOrders, getOrdersState } from '../../store/orders/selectors';

import Page from '../../components/Page';
import PageHeader from '../../components/PageHeader';
import OrdersTable from '../../components/OrdersTable';
import { rangeEnums } from '../../shared/utils/dateRanges';
import DownloadReport from '../../components/DownloadReport';
import withVenue from '../../hoc/withVenue';
import usePagination from '../../hooks/usePagination';
import ESPicker from '../../components/ESPicker';
import useESPicker from '../../hooks/useESPicker';
import toUTC from '../../shared/utils/toUTC';
import shouldLoad from '../../shared/utils/shouldLoad';

const VoucherOrders = () => {
  // const classes = useStyles();
  const dispatch = useDispatch();
  const ordersState = useSelector(getOrdersState);
  const { loading, error } = ordersState;
  const raworders = useSelector(getOrders) || [];
  const orders = {
    docs: raworders.docs || [],
    total: raworders.total || 0,
  };
  const pagination = usePagination();
  const esPicker = useESPicker();
  const { selectedDates, searchTerm, dateRange, sortOrder } = esPicker;
  const { first, requestNewPage, rowsPerPage, resetPagination } = pagination;

  const handleFetchOrdersPage = useCallback(() => {
    dispatch(
      fetchOrdersPage({
        first,
        size: rowsPerPage,
        sortOrder,
        searchTerm,
        dateRange,
        from: dateRange === rangeEnums.CUSTOM && toUTC(selectedDates.from),
        to: dateRange === rangeEnums.CUSTOM && toUTC(selectedDates.to),
        includeStatus: 'VOUCHER_ORDER_ACCEPTED',
      }),
    );
  }, [first, sortOrder, dateRange, dispatch, searchTerm, selectedDates, rowsPerPage]);

  const getData = useCallback(() => {
    resetPagination();
    dispatch(
      fetchOrders({
        first: 0,
        size: rowsPerPage,
        sortOrder,
        searchTerm,
        dateRange,
        from: dateRange === rangeEnums.CUSTOM && toUTC(selectedDates.from),
        to: dateRange === rangeEnums.CUSTOM && toUTC(selectedDates.to),
        includeStatus: 'VOUCHER_ORDER_ACCEPTED',
      }),
    );
  }, [dispatch, sortOrder, searchTerm, dateRange, selectedDates, rowsPerPage, resetPagination]);

  useEffect(() => {
    if (shouldLoad(ordersState)) getData();
  }, [getData, ordersState]);

  useEffect(() => {
    dispatch(clearLiveOrders());
    getData();
  }, [
    sortOrder,
    searchTerm,
    dateRange,
    selectedDates,
    rowsPerPage,
    resetPagination,
    getData,
    dispatch,
  ]);

  useEffect(() => {
    if (requestNewPage) {
      handleFetchOrdersPage();
    }
  }, [requestNewPage, handleFetchOrdersPage]);

  const isOrdersLoading = loading && !orders;

  return (
    <>
      <PageHeader fullWidth>
        <ESPicker esPicker={esPicker}>
          <DownloadReport type="breakdown" includeVouchers="include" />
        </ESPicker>
      </PageHeader>
      <Page loading={isOrdersLoading} error={error} fullWidth>
        {orders && <OrdersTable pagination={pagination} orders={orders} />}
      </Page>
    </>
  );
};

export default withVenue(VoucherOrders, null, clearLiveOrders);
