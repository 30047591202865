import React, { useContext } from 'react';
import { withStyles } from 'tss-react/mui';

import NewBlueButton from '../../../components/Overrides/NewBlueButton';

import SignupContext from '../SignupContext';

const StyledNewBlueButton = withStyles(NewBlueButton, (theme) => ({
  root: {
    fontSize: '1.15rem',
    position: 'fixed',
    left: 0,
    right: 0,
    bottom: 0,
    margin: 'auto',
    marginBottom: theme.spacing(4),
    width: 250,
    zIndex: 10,
    [theme.breakpoints.up('md')]: {
      width: `calc(40% - calc(${theme.spacing(9)} * 2))`,
      marginLeft: theme.spacing(8),
      marginBottom: theme.spacing(8),
    },
  },
}));

const StepButton = ({ text = 'Next', ...props }) => {
  const { handleNextStep, handleGAEventCompleteStep } = useContext(SignupContext);

  const handleOnClick = () => {
    handleNextStep();
    handleGAEventCompleteStep();
  };

  return (
    <StyledNewBlueButton variant="contained" color="primary" onClick={handleOnClick} {...props}>
      {text}
    </StyledNewBlueButton>
  );
};

export default StepButton;
