import { pick } from 'ramda';
import { del, get, patch, post } from './httpRequest';

export const fetchAllStaff = async () => {
  const response = await get(`/staff`);
  return response.data;
};

export const updateStaff = async (values) => {
  const { staffId, description, enabled, name, capabilities, isStaffCapabilitiesOn } = values;
  const response = await patch(`/staff/${staffId}`, {
    data: { description, enabled, name, capabilities, isStaffCapabilitiesOn },
  });
  return response.data;
};

export const updateStaffAvailability = async ({ values }) => {
  const data = { staff: [] };
  values.staff.forEach((staffMember) => {
    data.staff.push(pick(['staffId', 'enabled'], staffMember));
  });
  await patch(`/staff/availability`, { data });
};

export const createStaff = async (values) => {
  const { description, name, pin, capabilities, isStaffCapabilitiesOn } = values;
  const response = await post(`/staff/`, {
    data: { description, name, pin, capabilities, isStaffCapabilitiesOn },
  });
  return response.data;
};

export const deleteStaff = async (values) => {
  const { staffId } = values;
  const response = await del(`/staff/${staffId}`);
  return response.data;
};

export const fetchStaff = async (values) => {
  const { staffId } = values;
  const response = await get(`/staff/${staffId}`);
  return response.data;
};
