import { createSlice } from '@reduxjs/toolkit';
import {
  asyncInitialState as initialState,
  createAsyncAction,
  createAsyncReducerHandlers,
} from '../utils/async';
import {
  fetchImageGallery as fetchImageGalleryApi,
  deleteImageGallery as deleteImageGalleryApi,
  associateGalleryImage as associateGalleryImageApi,
  associateBrandsGalleryImage as associateBrandsGalleryImageApi,
  deleteBrandImageGalleryApi,
  deleteBrandCategoryImageApi,
  deleteBrandLogoApi,
  deleteBrandCarouselImageApi,
} from '../../api/imageGallery';

export const fetchImageGallery = createAsyncAction('fetch-image-gallery', fetchImageGalleryApi);

export const deleteImageGallery = createAsyncAction('delete-image-gallery', deleteImageGalleryApi);
export const deleteBrandImageGallery = createAsyncAction(
  'delete-brand-image-gallery',
  deleteBrandImageGalleryApi,
);
export const deleteBrandCategoryImage = createAsyncAction(
  'delete-brand-category-image',
  deleteBrandCategoryImageApi,
);

export const deleteBrandLogo = createAsyncAction('delete-brand-logo', deleteBrandLogoApi);

export const deleteBrandCarouselImage = createAsyncAction(
  'delete-brand-carousel-image',
  deleteBrandCarouselImageApi,
);

export const associateGalleryImage = createAsyncAction(
  'associate-image-gallery',
  associateGalleryImageApi,
);

export const associateBrandsGalleryImage = createAsyncAction(
  'associate-brands-image-gallery',
  associateBrandsGalleryImageApi,
);

const imageGallerySlice = createSlice({
  name: 'imageGallery',
  initialState,
  reducers: {},
  extraReducers: {
    ...createAsyncReducerHandlers(fetchImageGallery),
    ...createAsyncReducerHandlers(deleteImageGallery),
    ...createAsyncReducerHandlers(deleteBrandImageGallery),
    ...createAsyncReducerHandlers(deleteBrandCategoryImage),
    ...createAsyncReducerHandlers(associateGalleryImage),
    ...createAsyncReducerHandlers(associateBrandsGalleryImage),
    ...createAsyncReducerHandlers(deleteBrandLogo),
    ...createAsyncReducerHandlers(deleteBrandCarouselImage),
  },
});

export const { reducer: imageGallery } = imageGallerySlice;
