import React, { useEffect, useRef, useState } from 'react';

import { makeStyles } from '@mui/styles';
import { Box, Button, Typography } from '@mui/material';

import { useDispatch, useSelector } from 'react-redux';
import Page from '../../components/Page';
import withVenue from '../../hoc/withVenue';
import AreaImage from './area.png';
import { getSalesAreasState } from '../../store/salesAreas/selectors';
import { clearSalesAreas, createSalesArea, fetchSalesAreas } from '../../store/salesAreas';
import SalesAreasCard from './SalesAreaCard';
import { useNotifications } from '../../shared/contexts/Notifications/useNotifications';
import { getErrorMessage } from '../../shared/utils/errors';
import AreaNameDialog from './AreaNameDialog';
import shouldLoad from '../../shared/utils/shouldLoad';
import PageHeader from '../../components/PageHeader';

const useStyles = makeStyles((theme) => ({
  page: {
    maxWidth: '1052px',
    margin: '0 auto',
  },
  heading: {
    marginBottom: '20px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  title: {
    fontFamily: 'Sen',
    flexGrow: 1,
  },
  description: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  emptyContent: {
    gap: theme.spacing(2),
    '& *': {
      alignSelf: 'center',
    },
  },
  areas: {
    gap: theme.spacing(2),
  },
}));

const SalesAreas = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [createDialogIsOpen, setCreateDialogIsOpen] = useState(false);
  const handleOpenCreateDialog = () => setCreateDialogIsOpen(true);
  const handleCloseCreateDialog = () => setCreateDialogIsOpen(false);

  const salesAreasState = useSelector(getSalesAreasState);
  const { loading, data: salesAreas, error } = salesAreasState;
  const { showErrorNotification, showSuccessNotification } = useNotifications();

  const createRef = useRef();

  const createArea = (areaName, tables = [], description) => {
    handleCloseCreateDialog();

    const post = async () => {
      try {
        await dispatch(
          createSalesArea({
            salesAreaName: areaName,
            tables,
            description: description || areaName,
          }),
        );
        dispatch(fetchSalesAreas());
        showSuccessNotification('Sales area has been created successfully');
      } catch (localError) {
        showErrorNotification(`Error: ${getErrorMessage(localError)}`);
      }
    };
    post();
  };

  useEffect(() => {
    if (shouldLoad(salesAreasState)) dispatch(fetchSalesAreas());
  }, [dispatch, salesAreasState]);

  return (
    <>
      <PageHeader>
        <div className={classes.heading}>
          <Typography className={classes.title} variant="h1" component="h1">
            Areas
          </Typography>
          <Button variant="outlined" color="primary" onClick={handleOpenCreateDialog}>
            Create area
          </Button>
        </div>
      </PageHeader>
      <Page loading={loading} error={error}>
        <div className={classes.page}>
          <p className={classes.description}>
            Areas represent the layout of your venues, such as ‘Outdoor’, or ‘First Floor’. They
            will appear on the ‘Venue’ tab on EPOS devices.
          </p>
          {salesAreas && salesAreas?.length > 0 ? (
            <Box display="flex" flexDirection="column" className={classes.areas}>
              {salesAreas.map((salesArea) => (
                <SalesAreasCard key={salesArea?.salesAreaId} salesArea={salesArea} />
              ))}
            </Box>
          ) : (
            <Box
              display="flex"
              justifyContent="center"
              flexDirection="column"
              className={classes.emptyContent}
            >
              <img src={AreaImage} alt="Area" />
              <Box>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={handleOpenCreateDialog}
                >
                  Get started
                </Button>
              </Box>
            </Box>
          )}
        </div>
      </Page>
      <AreaNameDialog
        ref={createRef}
        handleCloseDialog={handleCloseCreateDialog}
        dialogIsOpen={createDialogIsOpen}
        submitDialog={createArea}
        areaName=""
      />
    </>
  );
};

export default withVenue(SalesAreas, null, clearSalesAreas);
