import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { FormControlLabel, FormGroup, FormHelperText, Switch } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { signupFields } from '../signupFields';
import SignupContext from '../SignupContext';

const CustomFormGroup = withStyles(FormGroup, (theme) => ({
  root: {
    margin: `${theme.spacing(2)} 0`,
  },
}));

const StepSwitch = ({ name, label, defaultChecked = false, ...props }) => {
  const [checked, setChecked] = useState(defaultChecked);
  const { handleChange, handleBlur, values, errors, touched } = useFormikContext();
  const { handleOnFieldChange } = useContext(SignupContext);

  const checkedValue = values[name];

  const handleCheckedChange = useCallback(() => {
    setChecked(!checked);
  }, [checked]);

  useEffect(() => {
    handleOnFieldChange(signupFields[name], checked);
  }, [checked, handleOnFieldChange, name]);

  useEffect(() => {
    if (checkedValue) {
      setChecked(checkedValue);
      handleOnFieldChange(signupFields[name], checkedValue);
    }
  }, [checkedValue, handleOnFieldChange, name]);

  return (
    <CustomFormGroup row>
      <FormControlLabel
        control={
          <Switch
            checked={checked}
            onBlur={handleBlur}
            onChange={(event) => {
              handleCheckedChange();
              handleChange(event);
            }}
            name={name}
            color="primary"
            value={values[name]}
            {...props}
          />
        }
        label={label}
      />
      {errors[name] && touched[name] && <FormHelperText error>{errors[name]}</FormHelperText>}
    </CustomFormGroup>
  );
};

export default StepSwitch;
