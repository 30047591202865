import React from 'react';
import { TableBody, TableRow, TableCell } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { nanoid } from 'nanoid';

const TableSkeletonLoader = ({ rows, cells, cellsSize = [] }) => (
  <TableBody>
    {Array(rows)
      .fill(null)
      .map(() => (
        <TableRow key={nanoid()}>
          {Array(cells)
            .fill(null)
            .map((_cell, i) => {
              const size = cellsSize[i] || 'medium';

              return (
                <TableCell size={size} key={nanoid()}>
                  <Skeleton animation="wave" />
                </TableCell>
              );
            })}
        </TableRow>
      ))}
  </TableBody>
);

export default TableSkeletonLoader;
