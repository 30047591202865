const isItemUnavailable = (item) => {
  if (!item) {
    return false;
  }
  if (item.available) {
    return false;
  }
  return true;
};

export default isItemUnavailable;
