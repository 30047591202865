import { createSlice } from '@reduxjs/toolkit';
import {
  asyncInitialState as initialState,
  createAsyncAction,
  createAsyncReducerHandlers,
} from '../../utils/async';
import {
  createBrandsOptionApi,
  fetchBrandsOptionsApi,
  updateBrandsOptionApi,
  fetchBrandsOptionApi,
} from '../../../api/brands/brandOptions';

export const fetchBrandsOptions = createAsyncAction('fetch-brands-options', fetchBrandsOptionsApi);

export const fetchBrandsOption = createAsyncAction('fetch-brands-option', fetchBrandsOptionApi);

export const createBrandsOption = createAsyncAction('create-brands-option', createBrandsOptionApi);

export const updateBrandsOption = createAsyncAction('update-brands-option', updateBrandsOptionApi);

const brandsOptionsSlice = createSlice({
  name: 'brandsOptions',
  initialState,
  reducers: {
    clearBrandsOptions(state) {
      delete state.data;
    },
  },
  extraReducers: {
    ...createAsyncReducerHandlers(fetchBrandsOptions),
  },
});

const brandOptionSlice = createSlice({
  name: 'brandsOption',
  initialState,
  reducers: {
    clearBrandsOption(state) {
      delete state.data;
    },
  },
  extraReducers: {
    ...createAsyncReducerHandlers(fetchBrandsOption),
    ...createAsyncReducerHandlers(createBrandsOption),
    ...createAsyncReducerHandlers(updateBrandsOption),
  },
});
export const { clearBrandsOptions } = brandsOptionsSlice.actions;
export const { clearBrandsOption } = brandOptionSlice.actions;

export const { reducer: brandsOptions } = brandsOptionsSlice;
export const { reducer: brandsOption } = brandOptionSlice;
