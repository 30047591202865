import React, { PureComponent } from 'react';
import * as Sentry from '@sentry/browser';
import PageError from '../PageError';

class PageErrorHandler extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
    };
  }

  static getDerivedStateFromError(error) {
    return {
      hasError: true,
      error,
    };
  }

  componentDidCatch(error, info) {
    Sentry.withScope((scope) => {
      scope.setExtras(info);
      Sentry.captureException(error);
    });
  }

  render() {
    const { hasError, error } = this.state;
    const { children } = this.props;
    return hasError ? <PageError error={error} /> : children;
  }
}

export default PageErrorHandler;
