import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { makeStyles } from '@mui/styles';
import { Button, Typography } from '@mui/material';
import { FiPlus } from 'react-icons/fi';

import { getErrorMessage } from '../../shared/utils/errors';
import { useNotifications } from '../../shared/contexts/Notifications/useNotifications';
import Page from '../../components/Page';
import withVenue from '../../hoc/withVenue';
import StaffTable from '../../components/StaffTable';
import { clearAllStaff, fetchAllStaff, updateStaffAvailability } from '../../store/staff';
import { getAllStaffState } from '../../store/staff/selectors';
import shouldLoad from '../../shared/utils/shouldLoad';

const useStyles = makeStyles((theme) => ({
  heading: {
    marginBottom: '20px',
    display: 'flex',
  },
  title: {
    flexGrow: 1,
  },
  storeAnchor: {
    display: 'inlineBlock',
    overflow: 'hidden',
    borderRadius: '13px',
  },
  storeImage: {
    borderRadius: '13px',
    display: 'block',
    marginRight: theme.spacing(2),
  },
}));

const Staff = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const allStaffState = useSelector(getAllStaffState);
  const { loading, data, error } = allStaffState;
  const { showErrorNotification, showSuccessNotification } = useNotifications();

  const handleEnableSwitch = async (values) => {
    try {
      await dispatch(updateStaffAvailability({ values }));
      showSuccessNotification(`Staff availability has been successfully updated`);
      await dispatch(fetchAllStaff());
    } catch (err) {
      await dispatch(fetchAllStaff());
      showErrorNotification(getErrorMessage(err));
    }
  };

  useEffect(() => {
    if (shouldLoad(allStaffState)) dispatch(fetchAllStaff());
  }, [dispatch, allStaffState]);

  return (
    <Page loading={loading} error={error}>
      {data && (
        <>
          <header className={classes.heading}>
            <Typography className={classes.title} variant="h2" component="h1">
              Team
            </Typography>
            <aside>
              <Button
                className={classes.button}
                variant="contained"
                color="primary"
                startIcon={<FiPlus />}
                component={Link}
                to="/staff/add"
              >
                New member
              </Button>
            </aside>
          </header>
          <StaffTable onSubmit={handleEnableSwitch} />
        </>
      )}
    </Page>
  );
};

export default withVenue(Staff, null, clearAllStaff);
