import React, { useEffect } from 'react';

import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import ManagersTableTeam from './ManagersTableTeam';
import { getManagersState } from '../../../store/managers/selectors';
import { clearManagers, fetchManagers } from '../../../store/managers';
import withVenue from '../../../hoc/withVenue';
import Page from '../../../components/Page';

const useStyles = makeStyles((theme) => ({
  page: {
    maxWidth: '1052px',
    margin: '0 auto',
  },
  heading: {
    marginBottom: '20px',
    display: 'flex',
    justifyContent: 'space-between',
    gap: theme.spacing(1),
  },
  title: {
    fontFamily: 'Sen',
    flexGrow: 1,
  },
}));

const Team = ({ venueChanged }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { loading, data: managers, error } = useSelector(getManagersState);

  useEffect(() => {
    if (!managers) dispatch(fetchManagers());
  }, [dispatch, managers]);

  useEffect(() => {
    if (venueChanged) dispatch(fetchManagers());
  }, [dispatch, venueChanged]);
  return (
    <Page loading={loading} error={error}>
      <div className={classes.page}>
        <ManagersTableTeam managers={managers} />
      </div>
    </Page>
  );
};

export default withVenue(Team, '/settings/team', clearManagers);
