import React from 'react';

import { makeStyles } from '@mui/styles';
import { Button, Card, CardContent, Grid, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import Bell from './bell.svg';

const useStyles = makeStyles(() => ({
  subtitle: {
    fontFamily: 'Sen',
    fontWeight: 'bold',
    marginBottom: '0.5rem',
  },
  body: {
    fontSize: '0.8rem',
    textAlign: 'left',
    marginBottom: '2rem',
  },
  image: {
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    width: '45px',
    height: '45px',
  },
  details: {
    paddingLeft: '1rem',
  },
  offer: {
    borderBottom: '1px solid #BCCCDC',
    paddingTop: '0.7rem',
    paddingBottom: '0.7rem',
    display: 'flex',
    justifyContent: 'flex-start',
    '&:last-of-type': {
      borderBottom: 'none',
      marginBottom: '0.75rem',
    },
  },
  offerbody: {
    fontSize: '0.8rem',
    textAlign: 'left',
  },
  nooffers: {
    fontSize: '0.8rem',
    textAlign: 'left',
    marginBottom: '1rem',
    marginTop: '0.5rem',
  },
  button: {
    flexGrow: 0,
  },
  centeredColumn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  bell: {
    width: '35px',
    height: 'auto',
  },
}));

const Offer = ({ campaignName, brand, campaignBanner }) => {
  const classes = useStyles();
  return (
    <div className={classes.offer}>
      <div className={classes.image} style={{ backgroundImage: `url("${campaignBanner}")` }} />
      <div className={classes.details}>
        <Typography className={classes.subtitle} variant="h4">
          {brand}
        </Typography>
        <Typography variant="body2" color="primary" className={classes.offerbody}>
          {campaignName}
        </Typography>
      </div>
    </div>
  );
};

const OffersCard = ({ campaigns }) => {
  const classes = useStyles();

  const accepted = campaigns?.accepted || [];
  const noAcceptedOffers = accepted?.length === 0;

  return (
    <Grid item xs={12}>
      <Card>
        <CardContent>
          <Typography variant="subtitle2" className={classes.subtitle}>
            Active promotions
          </Typography>
          <Typography variant="body2" color="primary" className={classes.body}>
            Promotions you are currently running
          </Typography>

          {noAcceptedOffers ? (
            <div className={classes.centeredColumn}>
              <img src={Bell} alt="Bell" className={classes.bell} />
              <Typography variant="body2" color="primary" className={classes.nooffers}>
                No active promotions
              </Typography>
              <Button
                variant="contained"
                color="primary"
                component={Link}
                to="/offers"
                className={classes.button}
              >
                Explore promotions
              </Button>
            </div>
          ) : (
            <>
              {accepted.map(({ campaignName, brand, campaignBanner, campaignId }) => (
                <Offer
                  key={campaignId}
                  campaignName={campaignName}
                  brand={brand}
                  campaignBanner={campaignBanner}
                />
              ))}
              <Link to="/offers">Go to promotions</Link>
            </>
          )}
        </CardContent>
      </Card>
    </Grid>
  );
};

export default OffersCard;
