import { createSlice } from '@reduxjs/toolkit';
import {
  asyncInitialState as initialState,
  createAsyncAction,
  createAsyncReducerHandlers,
} from '../utils/async';
import fetchReportCustomersOverviewApi from '../../api/reports/customersOverview';

export const fetchReportCustomersOverview = createAsyncAction(
  'fetch-customers-overview',
  fetchReportCustomersOverviewApi,
);

const reportCustomersOverviewSlice = createSlice({
  name: 'customers-overview',
  initialState,
  reducers: {
    clearReportCustomersOverview(state) {
      delete state.data;
    },
  },
  extraReducers: createAsyncReducerHandlers(fetchReportCustomersOverview),
});

export const { clearReportCustomersOverview } = reportCustomersOverviewSlice.actions;

export const { reducer: reportCustomersOverview } = reportCustomersOverviewSlice;
