import { pick } from 'ramda';
import { get, post, patch, del } from './httpRequest';

export const fetchModifiers = async () => {
  const response = await get(`/modifiers`);
  return response.data;
};

export const fetchModifier = async (modifierId) => {
  const response = await get(`/modifiers/${modifierId}`);
  return response.data;
};

export const deleteModifier = async (modifierId) => {
  const response = await del(`/modifiers/${modifierId}`);
  return response.data;
};

export const createModifier = async (modifier) => {
  const response = await post(`/modifiers`, {
    data: modifier,
  });
  return response.data;
};

export const updateModifier = async (modifierId, values) => {
  const trimmedValues = {
    ...pick(
      ['modifierId', 'modifierName', 'label', 'modifierItems', 'minSelections', 'maxSelections'],
      values,
    ),
  };

  await patch(`/modifiers/${modifierId}`, {
    data: trimmedValues,
  });
};
