import React from 'react';
import { makeStyles } from '@mui/styles';
import { rem } from 'polished';
import { ReactComponent as LogoImage } from './serve.svg';

const useStyles = makeStyles({
  root: {
    height: '90%',
    width: '90%',
    maxWidth: rem(120),
    display: 'block',
  },
});

const Logo = ({ extraClasses }) => {
  const classes = useStyles();
  const classNames = extraClasses ? `${classes.root} ${extraClasses}` : classes.root;
  return <LogoImage alt="logo" className={classNames} src={LogoImage} />;
};

export default Logo;
