import { createSlice } from '@reduxjs/toolkit';
import {
  asyncInitialState as initialState,
  createAsyncAction,
  createAsyncReducerHandlers,
} from '../utils/async';
import {
  fetchIngredients as fetchIngredientsApi,
  fetchIngredient as fetchIngredientApi,
  createIngredient as createIngredientApi,
  updateIngredient as updateIngredientApi,
  updateIngredientsAvailability as updateIngredientsAvailabilityApi,
  deleteIngredient as deleteIngredientApi,
} from '../../api/ingredients';

export const fetchIngredients = createAsyncAction('fetch-ingredients', fetchIngredientsApi);

export const deleteIngredient = createAsyncAction('delete-ingredient', deleteIngredientApi);

export const fetchIngredient = createAsyncAction('fetch-ingredient', fetchIngredientApi);

export const createIngredient = createAsyncAction('create-ingredient', createIngredientApi);

export const updateIngredient = createAsyncAction('update-ingredient', updateIngredientApi);

export const updateIngredientsAvailability = createAsyncAction(
  'update-ingredients-availability',
  async ({ values }) => {
    await updateIngredientsAvailabilityApi({ values });
  },
);

const ingredientsSlice = createSlice({
  name: 'ingredients',
  initialState,
  reducers: {
    clearIngredients(state) {
      delete state.data;
    },
  },
  extraReducers: {
    ...createAsyncReducerHandlers(fetchIngredients),
    ...createAsyncReducerHandlers(updateIngredientsAvailability),
  },
});

const ingredientSlice = createSlice({
  name: 'ingredient',
  initialState,
  reducers: {
    clearIngredient(state) {
      delete state.data;
    },
  },
  extraReducers: {
    ...createAsyncReducerHandlers(fetchIngredient),
    ...createAsyncReducerHandlers(createIngredient),
    ...createAsyncReducerHandlers(updateIngredient),
  },
});
export const { clearIngredient } = ingredientSlice.actions;
export const { clearIngredients } = ingredientsSlice.actions;

export const { reducer: ingredients } = ingredientsSlice;
export const { reducer: ingredient } = ingredientSlice;
