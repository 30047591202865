import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { rem } from 'polished';
import { makeStyles } from '@mui/styles';
import AppHeader from '../AppHeader';

export const navigationWidth = rem(260);
export const mobileNavigationWidth = '60%';

const useStyles = makeStyles((theme) => ({
  page: {
    display: 'flex',
  },

  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },

  drawer: {
    flexShrink: 0,
    width: navigationWidth,

    [theme.breakpoints.down('md')]: {
      width: mobileNavigationWidth,
    },
  },

  drawerPaper: {
    width: navigationWidth,

    [theme.breakpoints.down('md')]: {
      width: mobileNavigationWidth,
    },
  },

  main: {
    flex: 1,
  },

  toolbar: theme.mixins.toolbar,
}));

const LayoutNotAuthenticated = ({ children }) => {
  const classes = useStyles();
  const [setIsNavigationOpen] = useState(false);

  const openNavigation = () => {
    setIsNavigationOpen(true);
  };
  return (
    <div className={classes.page}>
      <AppHeader authenticated={false} onNavigationOpen={openNavigation} />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {children}
      </main>
    </div>
  );
};

LayoutNotAuthenticated.propTypes = {
  children: PropTypes.element.isRequired,
};

export default LayoutNotAuthenticated;
