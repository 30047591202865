import { dateRanges, rangeEnums } from '../shared/utils/dateRanges';
import { sortOrderEnums } from '../shared/utils/sortOrder';
import { get, patch, del, post } from './httpRequest';

export const fetchPromoCodes = async ({
  first = 0,
  size = 50,
  sortOrder = sortOrderEnums.DESC,
  searchTerm = '',
  dateRange = rangeEnums.TODAY,
  from,
  to,
}) => {
  const customRange = from &&
    to && {
      label: rangeEnums.CUSTOM,
      from: () => from,
      to: () => to,
    };

  const range = dateRange === rangeEnums.CUSTOM ? customRange : dateRanges[dateRange];
  const params = range
    ? `from=${range.from()}&to=${range.to()}&order=${sortOrder}&first=${first}&size=${size}&terms=${searchTerm}`
    : '';
  const response = await get(`/promoCodes?${params}`);

  return response.data;
};

export const fetchPromoCode = async (promoCodeId) => {
  const response = await get(`/promoCodes/${promoCodeId}`);

  return response.data;
};

export const updatePromoCode = async (promoCode, payload) => {
  const {
    expiresAt,
    maxUsageCount,
    label,
    validTimes,
    limitAmount,
    hasLimit,
    limitTimeFrame,
    isEpos,
  } = payload;
  const finalPayload = {
    expiresAt,
    maxUsageCount,
    label,
    validTimes,
    hasLimit,
    limitAmount,
    limitTimeFrame,
    isEpos,
  };
  await patch(`/promoCodes/${promoCode}`, { data: finalPayload });
};

export const deletePromoCode = async (promoCode) => {
  await del(`/promoCodes/${promoCode}`);
};

export const createPromoCode = async (payload) => {
  await post(`/promoCodes`, {
    data: payload,
  });
};
