import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

const useStyles = makeStyles((theme) => ({
  thumbnail: {
    width: 200,
    borderRadius: 5,
    margin: theme.spacing(2),
    marginLeft: 0,
  },
  title: {
    fontFamily: 'Sen',
    flexGrow: 1,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    borderTop: '0.5px #f0f4f8 solid',
  },
  visible: {
    display: 'block',
  },
  invisible: {
    display: 'none',
  },
}));
const PhotoUploadGalleryItem = ({ productName, categoryName, images, handleSelect }) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const handleOpen = (e) => {
    e.stopPropagation();
    setIsOpen(!isOpen);
  };
  return (
    <Box>
      <Box
        className={classes.title}
        onClick={handleOpen}
        display="flex"
        justifyContent="space-between"
      >
        <Typography variant="h2">{productName}</Typography>
        {isOpen ? <ExpandLess /> : <ExpandMore />}
      </Box>
      <Box display="flex" flexDirection="row" flexWrap="wrap">
        {images?.map(({ imageName, url }) => (
          <div
            role="button"
            className={isOpen ? classes.visible : classes.invisible}
            onClick={() => handleSelect({ productName, imageName, categoryName })}
            onKeyUp={() => handleSelect({ productName, imageName, categoryName })}
            key={url}
            tabIndex={0}
          >
            <img src={url} alt={imageName} className={classes.thumbnail} loading="lazy" />
          </div>
        ))}
      </Box>
    </Box>
  );
};
export default PhotoUploadGalleryItem;
