import { pick } from 'ramda';
import { patch, getBare, deleteBare, postBare, patchBare } from '../httpRequest';

export const fetchBrandsIngredientsApi = async () => {
  const response = await getBare('brands/ingredients');
  return response.data;
};

export const fetchBrandsIngredientApi = async (ingredientId) => {
  const response = await getBare(`brands/ingredients/${ingredientId}`);
  return response.data;
};

export const deleteBrandsIngredientApi = async (ingredientId) => {
  const response = await deleteBare(`brands/ingredients/${ingredientId}`);
  return response.data;
};

export const createBrandsIngredientApi = async (data) => {
  const response = await postBare('brands/ingredients', { data });

  return response.data;
};

export const updateBrandsIngredientApi = async ({ ingredientId, values }) => {
  const data = pick(['isAlcoholic', 'available', 'type', 'ingredientName', 'label'], values);
  await patchBare(`brands/ingredients/${ingredientId}`, { data });
};

export const updateIngredientsAvailability = async ({ values }) => {
  const data = { ingredients: [] };
  values.forEach((ingredient) => {
    data.ingredients.push(pick(['ingredientId', 'available'], ingredient));
  });
  await patch(`/ingredients/availability`, { data });
};
