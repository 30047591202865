import React from 'react';

import StatusLozenge, { statuscode } from '../../components/StatusLozenge';

const statusTypes = {
  Scheduled: statuscode.scheduled,
  Ended: statuscode.inactive,
  Active: statuscode.success,
  Paused: statuscode.inactive,
};

const DiscountLozenge = ({ status }) => {
  const statusType = statusTypes[status];
  return <StatusLozenge status={statusType} text={status} />;
};
export default DiscountLozenge;
