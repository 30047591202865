import React from 'react';
import { Tooltip } from '@mui/material';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  arrow: {
    '&:before': {
      border: `1px solid ${theme.customPalette.midnight}`,
    },
    color: theme.customPalette.midnight,
  },
  tooltip: {
    backgroundColor: theme.customPalette.midnight,
    color: theme.customPalette.white,
    boxShadow: theme.shadows[1],
    fontSize: 12,
    fontWeight: 'bold',
  },
}));

const StatusTooltip = ({ title, children }) => {
  const classes = useStyles();

  return (
    <Tooltip
      title={title}
      placement="bottom"
      arrow
      disableFocusListener
      classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
    >
      {children}
    </Tooltip>
  );
};

export default StatusTooltip;
