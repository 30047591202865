import sendReportRequest from './sendReportRequest';

const fetchReportSalesByStaff = async ({ dateRange, from, to, reportingType }) => {
  const reponseData = await sendReportRequest({
    url: '/reports/salesByStaffMember',
    dateRange,
    from,
    to,
    reportingType,
  });

  return reponseData;
};

export default fetchReportSalesByStaff;
