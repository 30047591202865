import { useMemo } from 'react';
import { fetchVenueMenus } from '../store/venueMenus';
import { getVenueMenusState } from '../store/venueMenus/selectors';
import useEndpoint from './useEndpoint';

const useItemsOnMenu = () => {
  const { data: menuGroup } = useEndpoint(getVenueMenusState, fetchVenueMenus());

  const itemsOnMenu = useMemo(
    () =>
      menuGroup
        ?.filter((group) => group?.active && group?.type === 'WEB')
        .map((group) => group.items)
        .map((group) => group.map((item) => item.groupItems))
        .flat(2),
    [menuGroup],
  );

  const checkIfItemIsOnMenu = (itemId) => {
    const isItemOnMenu = itemsOnMenu?.includes(itemId);
    return isItemOnMenu;
  };

  return { checkIfItemIsOnMenu };
};

export default useItemsOnMenu;
