import { Box, Grid, Card, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { groupBy } from 'lodash';
import React, { useEffect, useCallback, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import Page from '../../components/Page';
import withVenue from '../../hoc/withVenue';
import useRoles from '../../hooks/useRoles';
import shouldLoad from '../../shared/utils/shouldLoad';
import { fetchMenuItems } from '../../store/menuItems';
import { getMenuItemsState } from '../../store/menuItems/selectors';
import ItemPhoto from './ItemPhoto';

const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: 'Sen',
    flexGrow: 1,
    paddingBottom: theme.spacing(2),
    borderBottom: '1.5px solid #DBE2EB',
  },
}));

const ItemPhotos = () => {
  const classes = useStyles();
  const menuItemsState = useSelector(getMenuItemsState);
  const { loading, data, error } = menuItemsState;
  const groupedByCategory = groupBy(data, 'category');
  const [loadRefresh, setLoadRefresh] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);

  const dispatch = useDispatch();
  const { isRoleAtLeastManager } = useRoles();

  const refresh = useCallback(async () => {
    if (loading) return;
    await dispatch(fetchMenuItems());
    setLoadRefresh(false);
    setTimeout(
      () => window.scrollBy({ top: scrollPosition || 0, left: 0, behavior: 'smooth' }),
      1500,
    );
  }, [dispatch, loading, scrollPosition]);

  const loadMenuItems = useCallback(() => {
    setTimeout(refresh, 2000);
  }, [refresh]);

  useEffect(() => {
    if (shouldLoad(menuItemsState) && refresh) {
      refresh();
      setLoadRefresh(true);
    }
  }, [refresh, menuItemsState]);

  return (
    <Page loading={loading || loadRefresh} error={error} fullWidth>
      {data && (
        <Card>
          {Object.entries(groupedByCategory).map(([groupName, groupItems]) => (
            <div key={groupName}>
              <Box className={classes.title} p={2}>
                <Typography variant="h2">{groupName}</Typography>
              </Box>
              <Box m={6}>
                <Grid container spacing={4}>
                  {groupItems.map((groupItem) => (
                    <ItemPhoto
                      setScrollPosition={setScrollPosition}
                      disabled={!isRoleAtLeastManager() || groupItem.readonly}
                      item={groupItem}
                      key={groupItem.itemId}
                      uploadSuccess={loadMenuItems}
                    />
                  ))}
                </Grid>
              </Box>
            </div>
          ))}
        </Card>
      )}
    </Page>
  );
};

export default withVenue(ItemPhotos);
