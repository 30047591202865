import { FormGroup, InputLabel } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import ColorPalettePickerSelector from './ColorPalettePickerSelector';

const CustomInputLabel = withStyles(InputLabel, (theme) => ({
  root: {
    paddingBottom: theme.spacing(1),
  },
}));

const ColorPalettePicker = ({ name, value = null }) => {
  const { setFieldValue } = useFormikContext();
  const [currentColor, setCurrentColor] = useState(undefined);

  const handleSetColor = (pickedColor) => {
    setCurrentColor(pickedColor);
  };

  useEffect(() => {
    if (value) {
      setCurrentColor(value);
    }
  }, [value]);

  useEffect(() => {
    setFieldValue(name, currentColor);
  }, [setCurrentColor, name, setFieldValue, currentColor]);

  return (
    <FormGroup>
      <CustomInputLabel htmlFor={`${name}-color-palette-picker`}>Color</CustomInputLabel>
      <ColorPalettePickerSelector currentColor={currentColor} handleSetColor={handleSetColor} />
    </FormGroup>
  );
};

export default ColorPalettePicker;
