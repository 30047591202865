import React, { useEffect, useCallback, useState } from 'react';
import Fuse from 'fuse.js';
import { DialogTitle, Box, IconButton, TextField, MenuItem, InputAdornment } from '@mui/material';
import { makeStyles } from '@mui/styles';
import 'react-image-crop/dist/ReactCrop.css';
import { MdClose, MdSearch } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import {
  associateBrandsGalleryImage,
  associateGalleryImage,
  fetchImageGallery,
} from '../../store/imageGallery';
import { getImageGalleryState } from '../../store/imageGallery/selectors';
import PageLoader from '../PageLoader';
import PhotoUploadGalleryItem from './PhotoUploadGalleryItem';
import { useNotifications } from '../../shared/contexts/Notifications/useNotifications';
import { getErrorMessage } from '../../shared/utils/errors';

const useStyles = makeStyles((theme) => ({
  heading: {
    fontFamily: 'Sen',
    display: 'flex',
    justifyContent: 'space-between',
  },
  title: {
    fontFamily: 'Sen',
    flexGrow: 1,
    borderBottom: '0.5px #f0f4f8 solid',
  },
  closeButton: {
    position: 'absolute',
    right: '10px',
    top: '10px',
  },
  subtitleText: {
    fontFamily: 'Sen',
    fontSize: '1.2rem',
  },
  blueText: {
    fontFamily: 'Sen',
    color: '#333333',
    fontWeight: 600,
    marginTop: '2rem',
  },
  greyText: {
    fontFamily: 'Sen',
    color: '#656565',
    fontWeight: 600,
    marginTop: '1rem',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 220,
  },
}));

const PhotoUploadGallery = ({ handleCloseDialog, itemId, isBrands = false }) => {
  const dispatch = useDispatch();
  const { showErrorNotification, showSuccessNotification } = useNotifications();
  const { loading, data: galleryData } = useSelector(getImageGalleryState);
  const [filtered, setFiltered] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('ALL');
  const [compiledList, setCompiledList] = useState([]);

  useEffect(() => {
    dispatch(fetchImageGallery());
  }, [dispatch]);

  const classes = useStyles();

  const handleSelect = useCallback(
    async ({ categoryName, productName, imageName }) => {
      try {
        if (isBrands) {
          await dispatch(
            associateBrandsGalleryImage({ itemId, categoryName, productName, imageName }),
          );
        } else {
          await dispatch(associateGalleryImage({ itemId, categoryName, productName, imageName }));
        }
      } catch (error) {
        showErrorNotification(getErrorMessage(error));
      }
      showSuccessNotification(`${imageName} has been associated successfully`);
      handleCloseDialog(true);
    },
    [dispatch, itemId, handleCloseDialog, showErrorNotification, showSuccessNotification, isBrands],
  );

  const handleCategoryChange = useCallback(
    ({ target }) => {
      setSelectedCategory(target.value);
      const fuse = new Fuse(compiledList, {
        keys: ['categoryName'],
        threshold: 0,
      });
      const results = fuse.search(target.value);
      const newFiltered =
        results.length > 0 ? results.reduce((a, c) => [...a, c.item], []) : compiledList;
      setFiltered(newFiltered);
    },
    [compiledList],
  );

  const compileListOfGalleryData = (rawGallery) => {
    const sourceGalleryData = rawGallery || [];
    const compiled = sourceGalleryData?.reduce((a, { products, categoryName }) => {
      const compiledProducts = products.reduce(
        (a2, product) => [...a2, { ...product, categoryName }],
        [],
      );
      return [...a, ...compiledProducts];
    }, []);
    setCompiledList(compiled);
  };

  useEffect(() => {
    compileListOfGalleryData(galleryData);
  }, [galleryData]);

  const filterCompiledListBySearch = useCallback(() => {
    handleCategoryChange({ target: { value: 'ALL' } });
    const fuse = new Fuse(compiledList, {
      keys: ['productName'],
      threshold: 0.2,
    });
    const results = fuse.search(searchTerm);
    const newFiltered =
      results.length > 0 ? results.reduce((a, c) => [...a, c.item], []) : compiledList;
    setFiltered(newFiltered);
  }, [searchTerm, compiledList, handleCategoryChange]);

  useEffect(() => {
    filterCompiledListBySearch();
  }, [filterCompiledListBySearch]);

  return (
    <>
      <DialogTitle onClose={handleCloseDialog} className={classes.title}>
        Photo Gallery
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleCloseDialog}
          size="large"
        >
          <MdClose />
        </IconButton>
      </DialogTitle>
      {loading || !galleryData ? (
        <PageLoader />
      ) : (
        <>
          <Box mt={4} mb={4} display="flex" flexDirection="column">
            <Box display="flex" justifyContent="space-evenly" width="100%" flexWrap="wrap">
              <div>
                <TextField
                  select
                  className={classes.formControl}
                  name="SelectCategory"
                  label="Select Category"
                  value={selectedCategory}
                  size="small"
                  variant="outlined"
                  onChange={handleCategoryChange}
                >
                  <MenuItem value="ALL" key="ALL">
                    ALL
                  </MenuItem>
                  {galleryData?.map(({ categoryName }) => (
                    <MenuItem value={categoryName} key={categoryName}>
                      {categoryName}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
              <div>
                <TextField
                  className={classes.formControl}
                  color="primary"
                  id="searchTerm"
                  label="search"
                  size="small"
                  value={searchTerm}
                  variant="outlined"
                  disabled={false}
                  onChange={(event) => {
                    setSearchTerm(event.target.value);
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton type="submit" size="large">
                          <MdSearch />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </Box>
          </Box>

          <Box ml={4} mr={4} mt={4} mb={4} display="flex" flexDirection="column">
            {filtered?.map(({ productName, images, categoryName }) => (
              <PhotoUploadGalleryItem
                key={`${productName}_${categoryName}`}
                productName={productName}
                categoryName={categoryName}
                images={images}
                handleSelect={handleSelect}
              />
            ))}
          </Box>
        </>
      )}
    </>
  );
};

export default PhotoUploadGallery;
