import { createSlice } from '@reduxjs/toolkit';
import {
  asyncInitialState as initialState,
  createAsyncAction,
  createAsyncReducerHandlers,
} from '../utils/async';
import {
  fetchDiscounts as fetchDiscountsApi,
  fetchDiscount as fetchDiscountApi,
  createDiscount as createDiscountApi,
  updateDiscount as updateDiscountApi,
  deleteDiscount as deleteDiscountApi,
  addDiscountItems as addDiscountItemsApi,
  removeDiscountItems as removeDiscountItemsApi,
} from '../../api/itemDiscounts';

export const fetchDiscounts = createAsyncAction('fetch-discounts', fetchDiscountsApi);

export const fetchDiscount = createAsyncAction('fetch-discount', fetchDiscountApi);

export const deleteDiscount = createAsyncAction('delete-discount', deleteDiscountApi);

export const createDiscount = createAsyncAction('create-discount', createDiscountApi);

export const updateDiscount = createAsyncAction('update-discount', updateDiscountApi);

export const addDiscountItems = createAsyncAction('add-discount-items', addDiscountItemsApi);
export const removeDiscountItems = createAsyncAction(
  'remove-discount-items',
  removeDiscountItemsApi,
);

export const resetDiscount = createAsyncAction('reset-discount', () => null);

const discountsSlice = createSlice({
  name: 'discounts',
  initialState,
  reducers: {
    clearDiscounts(state) {
      delete state.data;
    },
  },
  extraReducers: {
    ...createAsyncReducerHandlers(fetchDiscounts),
    ...createAsyncReducerHandlers(deleteDiscount),
  },
});

const discountSlice = createSlice({
  name: 'discount',
  initialState,
  reducers: {
    clearDiscount(state) {
      delete state.data;
    },
  },
  extraReducers: {
    ...createAsyncReducerHandlers(fetchDiscount),
    ...createAsyncReducerHandlers(createDiscount),
    ...createAsyncReducerHandlers(updateDiscount),
    ...createAsyncReducerHandlers(resetDiscount),
    ...createAsyncReducerHandlers(addDiscountItems),
    ...createAsyncReducerHandlers(removeDiscountItems),
  },
});
export const { clearDiscount } = discountSlice.actions;
export const { clearDiscounts } = discountsSlice.actions;

export const { reducer: discounts } = discountsSlice;
export const { reducer: discount } = discountSlice;
