import { get, patch } from './httpRequest';

export const fetchIntroTasks = async () => {
  try {
    const response = await get('/introTasks');
    return response.data;
  } catch (e) {
    if (e.response) {
      return {
        error: `Request failed with status code ${e.response.status}: ${e.response.statusText}`,
      };
    }
    return {
      error: `Request failed: ${e.message || 'Unknown error'}`,
    };
  }
};

export const updateIntroTask = async (id) => {
  const response = await patch(`/introTasks/${id}`);
  return response.data;
};

export const updateIntroTasks = async (values) => {
  const response = await patch(`/introTasks`, { data: values });
  return response.data;
};
