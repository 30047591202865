import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import {
  IconButton,
  Tooltip,
  DialogContent,
  Typography,
  DialogActions,
  Button,
  Box,
} from '@mui/material';
import { MdDelete, MdEdit } from 'react-icons/md';

import {
  deleteBuilderMenuGroup,
  updateBuilderMenuGroup,
  updateBuilderMenuItems,
} from '../../../../store/brands/builderMenu/builderMenuItems';

import { getErrorMessage } from '../../../../shared/utils/errors';
import { useNotifications } from '../../../../shared/contexts/Notifications/useNotifications';

import MenuGroupForm from '../MenuGroupForm';
import CustomDialog from '../../../CustomDialog';

const EditMenuGroupButton = ({
  groupName,
  groupColor,
  groupId,
  available,
  description,
  isReadonly,
  groupImgUploadUrl,
  groupImageUrl,
  menuId,
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const { showErrorNotification, showSuccessNotification } = useNotifications();
  const dispatch = useDispatch();
  const initialValues = {
    groupColor,
    groupName,
    available,
    description,
  };

  const handleEdit = () => {
    setIsDialogOpen(true);
  };

  const handleDelete = () => {
    setIsDeleteDialogOpen(true);
  };

  const handleConfirmDelete = async () => {
    try {
      dispatch(deleteBuilderMenuGroup({ groupId }));
      await dispatch(updateBuilderMenuItems());
      showSuccessNotification(`${groupName} has been deleted successfully`);
    } catch (error) {
      showErrorNotification(getErrorMessage(error));
    }
  };
  const handleCloseDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
  };
  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const handleOnSubmit = async (values) => {
    try {
      const { priorityId } = values;
      const sanitisedPriorityId = priorityId === 'NONE' ? null : priorityId;
      const sanitisedPayload = { ...values, priorityId: sanitisedPriorityId };
      const finalPayload = priorityId ? sanitisedPayload : values;

      dispatch(updateBuilderMenuGroup({ groupId, values: finalPayload }));
      await dispatch(updateBuilderMenuItems());

      setIsDialogOpen(false);
      showSuccessNotification(`${values.groupName} has been updated successfully`);
    } catch (error) {
      showErrorNotification(getErrorMessage(error));
    }
  };

  return (
    <>
      <Tooltip title={`Edit ${groupName} section`} aria-label={`Edit ${groupName} section`}>
        <IconButton onClick={handleEdit} disabled={isReadonly} size="large">
          <MdEdit style={{ fontSize: 20 }} />
        </IconButton>
      </Tooltip>
      <Tooltip title={`Delete ${groupName} section`} aria-label={`Delete ${groupName} section`}>
        <IconButton onClick={handleDelete} disabled={isReadonly} size="large">
          <MdDelete style={{ fontSize: 20 }} />
        </IconButton>
      </Tooltip>
      <CustomDialog
        title={`Edit ${groupName}`}
        isDialogOpen={isDialogOpen}
        handleCloseDialog={handleCloseDialog}
        // This fixes the propagation to its parent's <ListemItem />, issue #302.
        onClick={(event) => event.stopPropagation()}
      >
        <MenuGroupForm
          formAction="update"
          initialValues={initialValues}
          onSubmit={handleOnSubmit}
          onCancel={handleCloseDialog}
          groupImgUploadUrl={groupImgUploadUrl}
          groupImageUrl={groupImageUrl}
          groupId={groupId}
          menuId={menuId}
        />
      </CustomDialog>
      <CustomDialog
        title="Are you sure?"
        isDialogOpen={isDeleteDialogOpen}
        handleCloseDialog={handleCloseDeleteDialog}
        // This fixes the propagation to its parent's <ListemItem />, issue #302.
        onClick={(event) => event.stopPropagation()}
      >
        <DialogContent dividers>
          <Typography gutterBottom component="div">
            Are you sure you want to remove the group{' '}
            <Box fontWeight="fontWeightBold" display="inline">
              {groupName}
            </Box>{' '}
            from the menu?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" autoFocus onClick={handleCloseDeleteDialog}>
            Cancel
          </Button>
          <Button variant="contained" onClick={handleConfirmDelete} color="primary">
            Remove
          </Button>
        </DialogActions>
      </CustomDialog>
    </>
  );
};

EditMenuGroupButton.propTypes = {
  groupName: PropTypes.string.isRequired,
  groupId: PropTypes.string.isRequired,
  available: PropTypes.bool.isRequired,
  description: PropTypes.string,
};

EditMenuGroupButton.defaultProps = {
  description: '',
};

export default EditMenuGroupButton;
