import React from 'react';

import { Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  arrow: {
    '&:before': {
      border: `1px solid ${theme.customPalette.midnight}`,
    },
    color: theme.customPalette.midnight,
  },
  tooltip: {
    ...theme.customFonts.xSmall,
    backgroundColor: theme.customPalette.midnight,
    color: theme.customPalette.white,
    boxShadow: theme.shadows[1],
  },
}));

const InfoTooltip = ({ text, children }) => {
  const classes = useStyles();

  return (
    <Tooltip
      title={text}
      placement="bottom"
      arrow
      disableFocusListener
      classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
    >
      {children}
    </Tooltip>
  );
};

export default InfoTooltip;
