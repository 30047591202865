import React from 'react';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import RemoveIcon from '@mui/icons-material/Remove';
import { Chip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import theme from '../../theme/default';

const useStyles = makeStyles({
  root: ({ statusColor, outlined, fullWidth }) => ({
    textAlign: fullWidth && 'center',
    width: fullWidth && '100%',
    backgroundColor: '#fff',
    overflow: 'hidden',
    border: outlined ? '1px solid' : 'none',
    borderColor: outlined && statusColor,
    color: statusColor,
    boxShadow: outlined && '0 4px 6px -1px rgba(0,0,0,0.1),0 2px 4px -1px rgba(0,0,0,0.06)',
    marginTop: '8px',
    marginBottom: 0,
  }),
  iconColor: ({ statusColor }) => ({
    color: statusColor,
  }),
});

const StatusLabel = ({ status, text, outlined, fullWidth, backgroundColor }) => {
  const getStatusLabelColor = (statusLabel) => {
    switch (statusLabel) {
      case 'success':
        return theme.colors.green[3];
      case 'fail':
        return theme.colors.red[3];
      case 'progress':
        return theme.colors.indigo[6];
      case 'idle':
        return theme.colors.grey[3];
      case 'REQUIRED':
        return '#D2222D';
      case 'UNDER_REVIEW':
        return '#8C1DF1';
      case 'VERIFIED':
        return '#32B842';
      case 'foodDelay':
      case 'drinksDelay':
      case 'foodAndDrinksDelay':
        return '#D2222D';
      default:
        return theme.colors.grey[3];
    }
  };

  const getStatusLabelIcon = (statusLabel) => {
    switch (statusLabel) {
      case 'success':
        return CheckCircleIcon;
      case 'fail':
        return CancelIcon;
      case 'progress':
        return HourglassEmptyIcon;
      case 'idle':
        return RemoveIcon;
      default:
        return null;
    }
  };

  const getStatusLabelText = (statusLabel, statusTexts) => {
    switch (statusLabel) {
      case 'success':
        return statusTexts?.success || 'Success';
      case 'fail':
        return statusTexts?.fail || 'Failed';
      case 'progress':
        return statusTexts?.progress || 'In progress';
      case 'idle':
        return statusTexts?.idle || 'Idle';
      case 'REQUIRED':
        return 'Requires verification';
      case 'NOT_REQUIRED':
        return statusLabel;
      case 'VERIFIED':
        return 'Payout verified';
      case 'UNDER_REVIEW':
        return 'Verification pending';
      case 'foodDelay':
        return 'Food delays';
      case 'drinksDelay':
        return 'Drinks delays';
      case 'foodAndDrinksDelay':
        return 'Food & drinks delays';
      default:
        return '';
    }
  };

  const getBackgroundColor = (statusBackgroundColor) => {
    switch (statusBackgroundColor) {
      case 'REQUIRED':
        return '#FFEDEB';
      case 'NOT_REQUIRED':
        return 'white';
      case 'UNDER_REVIEW':
        return '#F1E3FE';
      case 'VERIFIED':
        return '#E3F8E5';
      case 'foodDelay':
      case 'drinksDelay':
      case 'foodAndDrinksDelay':
        return '#FFEDEB';
      default:
        return 'white';
    }
  };

  const statusColor = getStatusLabelColor(status);
  const StatusIcon = getStatusLabelIcon(status);
  const customBackgroundColor = getBackgroundColor(status);
  const classes = useStyles({ statusColor, outlined, fullWidth });
  const statusText = getStatusLabelText(status, text);
  const variant = outlined ? 'outlined' : 'default';

  return (
    <Chip
      style={backgroundColor ? { backgroundColor: customBackgroundColor } : null}
      variant={variant}
      icon={StatusIcon ? <StatusIcon className={classes.iconColor} /> : null}
      label={statusText}
      className={classes.root}
    />
  );
};

export default StatusLabel;
