import { createSlice } from '@reduxjs/toolkit';
import {
  createAsyncAction,
  asyncInitialState as initialState,
  createAsyncReducerHandlers,
} from '../../utils/async';
import { getBare } from '../../../api/httpRequest';

const fetchBrandsApi = async () => {
  const response = await getBare('brands');
  return response.data;
};

export const fetchBrands = createAsyncAction('fetch-brands', fetchBrandsApi);

const brandsSlice = createSlice({
  name: 'brands',
  initialState,
  reducers: {
    clearBrands(state) {
      delete state.data;
    },
  },
  extraReducers: {
    ...createAsyncReducerHandlers(fetchBrands),
  },
});

export const { clearBrands } = brandsSlice.actions;
export const { reducer: brands } = brandsSlice;
