import moment from 'moment-timezone';
import { rangeEnums } from './dateRanges';

const customFormat = 'D MMM YYYY HH:mm';
const formatDisplayDates = ({ from, to }, options) => {
  const { format = false, range } = options || {};
  const fromMoment = moment(typeof from === 'function' ? from() : from);
  const toMoment = moment(typeof to === 'function' ? to() : to);

  const formatter =
    toMoment.diff(fromMoment, 'years', true) > 0.9 ? `D MMM YYYY HH:mm` : 'D MMM HH:mm';
  const finalFormatter = range === rangeEnums.CUSTOM ? customFormat : formatter;

  const fromDisplay = fromMoment.format(finalFormatter);
  const toDisplay = toMoment.format(finalFormatter);

  return format
    ? { fromDisplay: finalFormatter, toDisplay: finalFormatter }
    : { fromDisplay, toDisplay };
};

export default formatDisplayDates;
