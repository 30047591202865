import { createSlice } from '@reduxjs/toolkit';
import {
  asyncInitialState as initialState,
  createAsyncAction,
  createAsyncReducerHandlers,
} from '../utils/async';
import fetchReportOverviewApi from '../../api/reports/overview';

export const fetchReportOverview = createAsyncAction('fetch-overview', fetchReportOverviewApi);

const reportOverviewSlice = createSlice({
  name: 'overview',
  initialState,
  reducers: {
    clearReportOverview(state) {
      delete state.data;
    },
  },
  extraReducers: createAsyncReducerHandlers(fetchReportOverview),
});

export const { clearReportOverview } = reportOverviewSlice.actions;

export const { reducer: reportOverview } = reportOverviewSlice;
