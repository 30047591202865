import React, { useCallback, useState } from 'react';
import { Button, Card, IconButton, Typography } from '@mui/material';
import { MdDelete } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@mui/styles';
import OrderableTable from '../../../components/OrderableTable';
import withVenue from '../../../hoc/withVenue';
import { useNotifications } from '../../../shared/contexts/Notifications/useNotifications';
import { getErrorMessage } from '../../../shared/utils/errors';
import { clearVenue, fetchVenue, updateVenue } from '../../../store/venues';
import CustomDialog from '../../../components/CustomDialog';
import AddReportEmailDialog from '../../Venues/AddReportEmailDialog';
import ConfirmDeleteReportEmailDialog from '../../Venues/ConfirmDeleteReportEmailDialog';
import BackArrow from '../../../components/BackArrow';
import PageHeader from '../../../components/PageHeader';
import Page from '../../../components/Page';

const useStyles = makeStyles((theme) => ({
  header: {
    marginBottom: '16px',
  },
  title: {
    ...theme.customFonts.largeBold,
  },
  caption: {
    ...theme.customFonts.small,
    color: theme.customPalette.greyDarker,
    marginBottom: '16px',
  },
  card: {
    borderRadius: '8px',
  },
  button: {
    padding: '8px 16px',
    borderRadius: '8px',
    ...theme.customFonts.medium,
  },
  delete: {
    color: theme.customPalette.grey,
  },
}));

const ReportEmails = ({ venue, redirect }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [addEmailDialogOpen, setAddEmailDialogOpen] = useState(false);
  const [deleteReportEmailDialogOpen, setDeleteReportEmailDialogOpen] = useState(false);
  const [temporaryReportEmail, setTemporaryReportEmail] = useState();
  const { showErrorNotification, showSuccessNotification } = useNotifications();
  const { reportEmails } = venue || {};

  // Modifies data structure so OrderableTable can accept it. Has to be array of objects.
  const modifiedTableData = reportEmails.map((email) => ({
    email,
    delete: '',
  }));

  const handleAddEmail = () => {
    setAddEmailDialogOpen(true);
  };

  const handleDeleteEmail = async (val) => {
    try {
      const filteredReportEmails = reportEmails.filter((reportEmail) => reportEmail !== val);
      const payload = {
        reportEmails: filteredReportEmails,
      };
      await dispatch(updateVenue(payload));
      showSuccessNotification('Email successfully removed');
    } catch (newError) {
      showErrorNotification(getErrorMessage(newError));
    }
  };

  const handleCloseEmailDialog = () => {
    setAddEmailDialogOpen(false);
  };

  const handleCloseDeleteReportEmailDialog = () => {
    setDeleteReportEmailDialogOpen(false);
  };

  const handleOpenDeleteReportEmailDialog = useCallback(
    (reportEmail) => {
      setTemporaryReportEmail(reportEmail);
      setDeleteReportEmailDialogOpen(!deleteReportEmailDialogOpen);
    },
    [deleteReportEmailDialogOpen],
  );

  const submitDeleteReportEmailDialog = () => {
    setDeleteReportEmailDialogOpen(false);
    setTemporaryReportEmail(null);
    handleDeleteEmail(temporaryReportEmail);
  };

  const handleOnSubmit = async ({ reportEmail }) => {
    try {
      const payload = {
        reportEmails: [...reportEmails, reportEmail],
      };
      await dispatch(updateVenue(payload));
      handleCloseEmailDialog();
      showSuccessNotification('Email has been added successfully');
    } catch (newError) {
      showErrorNotification(getErrorMessage(newError));
      dispatch(fetchVenue());
    }
  };

  const valueFormatter = useCallback(
    ({ value, valueName, row }) => {
      switch (valueName) {
        case 'delete':
          return (
            <IconButton
              edge="end"
              size="small"
              onClick={() => handleOpenDeleteReportEmailDialog(row.email)}
            >
              <MdDelete className={classes.delete} />
            </IconButton>
          );
        default:
          return value;
      }
    },
    [classes.delete, handleOpenDeleteReportEmailDialog],
  );

  return (
    <>
      <PageHeader>
        <div style={{ marginBottom: '20px' }}>
          <BackArrow redirect={redirect} text="Settings" />
          <Typography className={classes.title}>Report emails</Typography>
          <Typography className={classes.caption}>
            Manage who receives daily email reports in .csv format
          </Typography>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={handleAddEmail}
          >
            Add
          </Button>
        </div>
      </PageHeader>
      <Page>
        <CustomDialog
          isDialogOpen={addEmailDialogOpen}
          handleCloseDialog={handleCloseEmailDialog}
          title="Add new email"
        >
          <AddReportEmailDialog
            handleCloseDialog={handleCloseEmailDialog}
            submitDialog={handleOnSubmit}
          />
        </CustomDialog>
        <Card className={classes.card}>
          <OrderableTable
            tableData={[...modifiedTableData]}
            titles={['Email', '']}
            excludeTableFooter
            valueFormatter={valueFormatter}
            disableColumnTitles={['']}
          />
        </Card>

        {temporaryReportEmail && (
          <CustomDialog
            handleCloseDialog={handleCloseDeleteReportEmailDialog}
            isDialogOpen={deleteReportEmailDialogOpen}
            title="Are you sure you want to delete this email?"
          >
            <ConfirmDeleteReportEmailDialog
              submitDialog={submitDeleteReportEmailDialog}
              temporaryReportEmail={temporaryReportEmail}
              handleCloseDialog={handleCloseDeleteReportEmailDialog}
            />
          </CustomDialog>
        )}
      </Page>
    </>
  );
};

export default withVenue(ReportEmails, '/settings/business', clearVenue);
