import { pick } from 'ramda';
import { getBare, postBare, patchBare } from '../httpRequest';

export const fetchBrandsOptionsApi = async () => {
  const response = await getBare(`brands/itemOptions`);
  return response.data;
};

export const fetchBrandsOptionApi = async (optionId) => {
  const response = await getBare(`brands/itemOptions/${optionId}`);
  return response.data;
};

export const createBrandsOptionApi = async (option) => {
  const response = await postBare(`brands/itemOptions`, {
    data: option,
  });

  return response.data;
};

export const updateBrandsOptionApi = async ({ optionId, values }) => {
  const trimmedValues = {
    ...pick(['type', 'optionName'], values),
  };

  await patchBare(`brands/itemOptions/${optionId}`, {
    data: trimmedValues,
  });
};
