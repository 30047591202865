import React from 'react';
import { useSelector } from 'react-redux';

import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';

import { getVenuesState } from '../../store/venues/selectors';

import Page from '../../components/Page';
import VenueSelector from '../../components/VenueSelector';

const useStyles = makeStyles(() => ({
  heading: {
    marginBottom: '20px',
    display: 'flex',
  },
  title: {
    flexGrow: 1,
  },
}));

const Venues = () => {
  const classes = useStyles();
  const { loading, data, error } = useSelector(getVenuesState);

  return (
    <Page loading={loading} error={error}>
      {data && (
        <>
          <header className={classes.heading}>
            <Typography className={classes.title} variant="h2" component="h1">
              Venues
            </Typography>
          </header>
          <VenueSelector />
        </>
      )}
    </Page>
  );
};

export default Venues;
