import React from 'react';
import { makeStyles } from '@mui/styles';
import { MdCheckCircle } from 'react-icons/md';
import { Card, Typography } from '@mui/material';
import Page from '../../../components/Page';
import SubscriptionCard from '../../../components/Subscriptions/SubscriptionCard';
import useEndpoint from '../../../hooks/useEndpoint';
import {
  clearBilling,
  clearDirectDebitMandate,
  fetchBilling,
  fetchDirectDebitMandateUrl,
  fetchInvoices,
} from '../../../store/billing';
import {
  getBillingState,
  getDirectDebitMandateState,
  getInvoiceState,
} from '../../../store/billing/selectors';
import CustomWarningBanner from '../../../components/CustomWarningBanner';
import SettingsListItem from '../../../components/SettingsListItem';
import withVenue from '../../../hoc/withVenue';

const useStyles = makeStyles((theme) => ({
  heading: {
    color: theme.customPalette.greyDarker,
  },
  warningBannerText: {
    ...theme.customFonts.small,
    color: theme.customPalette.greyDarker,
  },
  warningBanner: {
    marginBottom: '16px',
  },
  settingsList: {
    borderRadius: '8px',
    marginTop: '16px',
  },
}));
const BillingPage = () => {
  const classes = useStyles();
  const { data: invoiceData } = useEndpoint(getInvoiceState, fetchInvoices());
  const { data: subscriptionData } = useEndpoint(getBillingState, fetchBilling());
  const { data: directDebitUrl } = useEndpoint(
    getDirectDebitMandateState,
    fetchDirectDebitMandateUrl(),
  );
  const { mandateStatus } = subscriptionData?.customer || {};
  return (
    <>
      <Page>
        {mandateStatus === 'pending' && (
          <div className={classes.warningBcanner}>
            <CustomWarningBanner
              title="Your payment details have been submitted"
              customIcon={<MdCheckCircle style={{ color: 'green' }} />}
              backgroundColor="successLightGreen"
              titleColor="greyDarker"
              titleFont="smallBold"
            >
              <Typography className={classes.warningBannerText}>
                We’ll get your direct debit setup, this may take up to 3 days.
              </Typography>
            </CustomWarningBanner>
          </div>
        )}
        {!!subscriptionData?.customer && (
          <SubscriptionCard
            subscriptionData={subscriptionData}
            directDebitUrl={directDebitUrl?.url}
          />
        )}
        {invoiceData && invoiceData?.length > 0 && (
          <Card className={classes.settingsList}>
            <SettingsListItem
              title="Invoices"
              subtitle="View price breakdowns, download, invoice emails"
              to="/invoices"
            />
          </Card>
        )}
      </Page>
    </>
  );
};

export default withVenue(BillingPage, '/settings', [clearBilling, clearDirectDebitMandate]);
