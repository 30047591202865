import React from 'react';

import StepGraphic from '../StepGraphic';
import StepButton from '../StepButton';
import StepQuestion from '../StepQuestion';

const StepCompanyDetailsTwo = () => (
  <>
    <StepQuestion question="Company details complete!" center />
    <StepGraphic fileName="company-details-complete" />
    <StepButton />
  </>
);

export default StepCompanyDetailsTwo;
