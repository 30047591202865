import { createSlice } from '@reduxjs/toolkit';
import {
  asyncInitialState as initialState,
  createAsyncAction,
  createAsyncReducerHandlers,
} from '../utils/async';
import fetchReportSalesByStaffApi from '../../api/reports/salesByStaff';

export const fetchReportSalesByStaff = createAsyncAction(
  'fetch-sales-by-staff',
  fetchReportSalesByStaffApi,
);
const reportSalesByStaffSlice = createSlice({
  name: 'sales-by-staff',
  initialState,
  reducers: {
    clearReportSalesByStaff(state) {
      delete state.data;
    },
  },
  extraReducers: createAsyncReducerHandlers(fetchReportSalesByStaff),
});

export const { clearReportSalesByStaff } = reportSalesByStaffSlice.actions;
export const { reducer: reportSalesByStaff } = reportSalesByStaffSlice;
