import React from 'react';
import PropTypes from 'prop-types';

import OpeningTimeField from './EditWeekTimeRangeDialog/OpeningTimeField';

const WeekTimeRangeSelectorInline = ({ validTimes: passedValidTimes }) => (
  <div style={{ marginTop: '24px' }}>
    {passedValidTimes.map((opening, index) => (
      <OpeningTimeField
        key={`${opening.day}`}
        name={`openings.${index}`}
        opening={opening}
        openLabel=""
      />
    ))}
  </div>
);

WeekTimeRangeSelectorInline.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  validTimes: PropTypes.array.isRequired,
};
export default WeekTimeRangeSelectorInline;
