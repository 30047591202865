import React from 'react';
import { useDispatch } from 'react-redux';

import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';
import { clearOption, createOption } from '../../store/options';

import { getErrorMessage } from '../../shared/utils/errors';
import { useNotifications } from '../../shared/contexts/Notifications/useNotifications';
import OptionForm from '../../components/OptionForm';
import Page from '../../components/Page';
import withVenue from '../../hoc/withVenue';

const useStyles = makeStyles(() => ({
  heading: {
    margin: '15px 0',
  },
}));

const AddOption = ({ modalSubmit, modalClose, redirect }) => {
  const { showErrorNotification, showSuccessNotification } = useNotifications();
  const dispatch = useDispatch();

  const handleOnSubmit = async (values) => {
    try {
      await dispatch(createOption(values));
      showSuccessNotification('Size has been added successfully');
      if (modalSubmit) {
        modalSubmit(values);
      } else {
        redirect();
      }
    } catch (error) {
      showErrorNotification(getErrorMessage(error));
      if (modalClose) {
        modalClose();
      } else {
        redirect();
      }
    }
  };

  const classes = useStyles();

  return (
    <Page>
      <header>
        {!modalSubmit && (
          <Typography className={classes.heading} variant="h2" component="h1">
            Add Size
          </Typography>
        )}
      </header>
      <OptionForm
        formAction="create"
        initialValues={{
          optionName: '',
          type: 'DRINK',
        }}
        onSubmit={handleOnSubmit}
        onCancel={modalClose}
      />
    </Page>
  );
};

export default withVenue(AddOption, '/options', clearOption);
