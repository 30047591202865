import axios from 'axios';
import moment from 'moment-timezone';
import { dateRanges, dateRangesPrevious, rangeEnums } from '../../shared/utils/dateRanges';
import { REQUEST_SUPERCEDED } from '../../store/utils/async';
import { get } from '../httpRequest';
import toUTC from '../../shared/utils/toUTC';
import { REPORTING_TYPES } from '../../shared/constants/units';
import formattedTimezoneOffset from '../../shared/utils/formattedTimezoneOffset';

const cancelTokens = {};

const getAdditionalOffset = (reportingType) => {
  switch (reportingType) {
    case REPORTING_TYPES.OPERATIONAL:
      return 5 * 60;
    case REPORTING_TYPES.TRANSACTIONAL:
      return 0;
    case REPORTING_TYPES.UTC:
      return moment().utcOffset();
    default:
      return 0;
  }
};

const sendReportRequest = async ({
  url,
  dateRange,
  from,
  to,
  unit,
  reportingType,
  brands,
  platforms,
}) => {
  if (cancelTokens[url]) cancelTokens[url].cancel(REQUEST_SUPERCEDED);
  const additionalOffset = getAdditionalOffset(reportingType);

  const customRange = from &&
    to && {
      label: rangeEnums.CUSTOM,
      from: () => from,
      to: () => to,
    };

  const range = dateRange === rangeEnums.CUSTOM ? customRange : dateRanges[dateRange];
  const unitParam = unit ? `&unit=${unit}` : '';
  const params = range
    ? `gte=${toUTC(range.from(), reportingType)}&lte=${toUTC(
        range.to(),
        reportingType,
      )}${unitParam}&offset=${formattedTimezoneOffset(additionalOffset)}`
    : '';

  const previousDateRange = dateRange !== rangeEnums.CUSTOM ? dateRangesPrevious[dateRange] : null;
  const previousDateRangeParams = previousDateRange
    ? `&previousGte=${toUTC(previousDateRange.from(), reportingType)}&previousLte=${toUTC(
        previousDateRange.to(),
        reportingType,
      )}`
    : '';

  const brandParams = brands ? `&brands=${JSON.stringify(brands.map((brand) => brand))}` : '';

  const platformParams = platforms
    ? `&platforms=${JSON.stringify(platforms.map((platform) => platform))}`
    : '';
  const requestUrl = `${url}?${params}${previousDateRangeParams}${brandParams}${platformParams}`;

  cancelTokens[url] = await axios.CancelToken.source();
  const response = await get(requestUrl, {
    cancelToken: cancelTokens[url].token,
  });

  return response.data;
};
export default sendReportRequest;
