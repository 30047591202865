// TODO REFACTOR THIS TO MAKE IT GENERIC AND USE ACROSS THE DASHBOARD IN PLACES LIKE DELETE ASSOCIATION

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import React from 'react';

const ConfirmRemoveDialog = ({ title, handleClose, handleConfirm, showDialog }) => (
  <Dialog open={showDialog}>
    <DialogTitle sx={{ fontWeight: 'bold' }}>Are you sure?</DialogTitle>
    <DialogContent>
      <DialogContentText>{title}</DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose} color="primary">
        Cancel
      </Button>
      <Button onClick={handleConfirm} color="primary" variant="contained">
        Confirm
      </Button>
    </DialogActions>
  </Dialog>
);

export default ConfirmRemoveDialog;
