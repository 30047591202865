import React from 'react';
import { Typography, Box } from '@mui/material';

const StepQuestion = ({ question, description, center }) => (
  <Box mb={2} textAlign={center ? 'center' : 'left'}>
    <Typography variant="h2" component="h1">
      {question}
    </Typography>
    {description && (
      <Typography variant="body1" component="p">
        {description}
      </Typography>
    )}
  </Box>
);

export default StepQuestion;
