import { createSelector } from '@reduxjs/toolkit';

export const getTablesState = (state) => state.tables;

export const getTableState = (state) => state.table;

export const getTables = createSelector(getTablesState, (tablesState) => tablesState.data);

export const getMissingTablesState = createSelector(getTables, (tables) => {
  const unassignedFoodTables = [];
  const unassignedDrinksTables = [];
  const unassignedAllTables = [];
  const multipleFoods = [];
  const multipleDrinks = [];
  if (tables)
    tables.forEach((table) => {
      if (!table?.isDrinkHandled) unassignedDrinksTables.push(table.tableName);
      if (!table?.isFoodHandled) unassignedFoodTables.push(table.tableName);
      if (!table?.isDrinkHandled && !table?.isFoodHandled)
        unassignedAllTables.push(table.tableName);
      if (table?.isDrinkHandledMultiple) multipleDrinks.push(table.tableName);
      if (table?.isFoodHandledMultiple) multipleFoods.push(table.tableName);
    });
  return {
    unassignedFoodTables,
    unassignedDrinksTables,
    unassignedAllTables,
    multipleFoods,
    multipleDrinks,
  };
});

export const getTablesObj = createSelector(getTablesState, (tables) => {
  if (tables.data) return null;
  return tables.data.reduce((acc, table) => {
    const { tableName, description } = table;
    return { [tableName]: { tableName, description }, ...acc };
  }, {});
});
