import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { makeStyles } from '@mui/styles';
import { Button, Typography } from '@mui/material';
import { FiPlus } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import FileDownload from 'js-file-download';

import { clearQrCodes, deleteQrcode, fetchQrcodes } from '../../store/qrcodes';
import { getQrcodesState } from '../../store/qrcodes/selectors';

import Page from '../../components/Page';
import QrcodeTable from '../../components/QrcodeTable';
import { printVenueQrcode } from '../../store/venues';
import withVenue from '../../hoc/withVenue';
import { useNotifications } from '../../shared/contexts/Notifications/useNotifications';
import { getErrorMessage } from '../../shared/utils/errors';
import shouldLoad from '../../shared/utils/shouldLoad';
import useRoles from '../../hooks/useRoles';
import PageHeader from '../../components/PageHeader';

const useStyles = makeStyles(() => ({
  heading: {
    marginBottom: '20px',
    display: 'flex',
  },
  title: {
    flexGrow: 1,
  },
}));

const Qrcodes = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { isAdmin } = useRoles();
  const qrCodesState = useSelector(getQrcodesState);
  const { loading, data, error } = qrCodesState;
  const { showErrorNotification, showSuccessNotification } = useNotifications();

  useEffect(() => {
    if (shouldLoad(qrCodesState)) dispatch(fetchQrcodes());
  }, [dispatch, qrCodesState]);

  const handlePrintQrCode = async (qrCodeId, isHiRes) => {
    const result = await dispatch(printVenueQrcode(qrCodeId, isHiRes));
    FileDownload(result, `SessionsServe-QR-Code-${isHiRes ? 'hi' : 'low'}.pdf`);
  };
  const handleRemoveQrCode = async (qrCodeId) => {
    try {
      await dispatch(deleteQrcode({ qrCodeId }));
      dispatch(fetchQrcodes());
      showSuccessNotification('QR code has been removed successfully');
    } catch (err) {
      dispatch(fetchQrcodes());
      showErrorNotification(getErrorMessage(err));
    }
  };

  return (
    <>
      <PageHeader fullWidth>
        <div className={classes.heading}>
          <Typography className={classes.title} variant="h2" component="h1">
            QR Codes
          </Typography>
        </div>

        {isAdmin() && (
          <aside>
            <Button
              variant="contained"
              color="primary"
              startIcon={<FiPlus />}
              component={Link}
              to="/qrcodes/add"
            >
              Add Qrcode
            </Button>
          </aside>
        )}
      </PageHeader>
      <Page loading={loading} error={error} fullWidth>
        {data && (
          <>
            <Typography paragraph>Please order additional QR codes via customer support</Typography>
            <QrcodeTable
              handlePrintQrCode={handlePrintQrCode}
              handleRemoveQrCode={handleRemoveQrCode}
            />
          </>
        )}
      </Page>
    </>
  );
};

export default withVenue(Qrcodes, null, clearQrCodes);
