import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import Page from '../components/Page';
import shouldLoad from '../shared/utils/shouldLoad';
import { resetVenue } from '../store';
import { fetchVenue } from '../store/venues';
import { getVenueState } from '../store/venues/selectors';

/**
 * Wraps components with venue and some housekeeping. All pages should use this to deal with venue changes.
 * @param {Component} Component - A React Component
 * @param {string} redirectPath - A path to redirect to when changing venue, or completing edit / delete
 * @param {function} clearDataAction - An optional redux action to clear the page's current data state on unmount.
 * @example
 *
 * withVenue(Discount, '/item-discounts', clearDiscount);
 */
const withVenue = (Component, redirectPath, clearDataAction) => (props) => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const venueState = useSelector(getVenueState);
  const { loading: venueLoading, data: venue, error: venueError } = venueState;
  const [venueChanged, setVenueChanged] = useState(false);
  const [currentVenueId, setCurrentVenueId] = useState(localStorage.getItem('venue_id'));

  const goToPage = useCallback(
    (to) => {
      push(to || '/');
    },
    [push],
  );

  useEffect(
    () => () => {
      if (Array.isArray(clearDataAction)) {
        clearDataAction.forEach((action) => dispatch(action()));
      } else if (clearDataAction) {
        dispatch(clearDataAction());
      }
    },
    [dispatch],
  );

  useEffect(() => {
    if (shouldLoad(venueState)) dispatch(fetchVenue());
  }, [dispatch, venueState]);

  useEffect(() => {
    if (venueChanged) {
      dispatch(resetVenue());
      if (redirectPath) goToPage(redirectPath);
    }
  }, [dispatch, venueChanged, goToPage]);

  const redirect = useCallback(() => {
    if (redirectPath) goToPage(redirectPath);
  }, [goToPage]);

  useEffect(() => {
    if (venue && venue?.venueId !== currentVenueId) {
      setCurrentVenueId(venue.venueId);
      setVenueChanged(true);
    } else {
      setVenueChanged(false);
    }
  }, [venue, venueChanged, currentVenueId]);

  return venueChanged || !venue ? (
    <Page loading={venueLoading} error={venueError} />
  ) : (
    <Component
      {...props}
      venueChanged={venueChanged}
      currentVenueId={currentVenueId}
      venue={venue}
      venueLoading={venueLoading}
      venueError={venueError}
      redirect={redirect}
    />
  );
};

export default withVenue;
