import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { Chip } from '@mui/material';

const useStyles = makeStyles(() => ({
  root: { verticalAlign: 'top' },
  customColor: {
    backgroundColor: (props) => props.backgroundColor,
    color: (props) => props.fontColor,
  },
}));

const InlineChip = ({ label, icon, backgroundColor, fontColor }) => {
  const classes = useStyles({ backgroundColor, fontColor });
  return (
    <Chip
      classes={{ root: classes.root }}
      className={backgroundColor && classes.customColor}
      label={label}
      icon={icon}
      size="small"
      color="primary"
    />
  );
};

InlineChip.propTypes = {
  label: PropTypes.string.isRequired,
};

export default InlineChip;
