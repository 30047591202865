/* eslint-disable max-len */
import React from 'react';
import { useHistory } from 'react-router';
import Carousel from '../Carousel';

import PromotionItem from '../PromotionItem';
import { customCampaignTypes } from '../PromotionsListForm/campaignTypes';

const PromotionsSlider = ({ campaigns, handleOpenOfferDialog }) => {
  const history = useHistory();

  const handleOnClick = (campaign) => {
    const { campaignType, campaignId } = campaign;
    const isBrandupCampaign = !Object.values(customCampaignTypes)
      .map(({ value }) => value)
      .includes(campaignType);
    if (isBrandupCampaign) {
      handleOpenOfferDialog(campaign);
    } else {
      history.push(`/offers/${campaignId}`);
    }
  };

  return <Carousel items={campaigns} ItemComponent={PromotionItem} handleClick={handleOnClick} />;
};

export default PromotionsSlider;
