import React, { useCallback, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { FiPlus } from 'react-icons/fi';

import { makeStyles } from '@mui/styles';
import { Button, Typography } from '@mui/material';

import { clearTables, fetchTables, updateTableAvailability } from '../../store/tables';
import { getTablesState } from '../../store/tables/selectors';

import Page from '../../components/Page';
import TablesTable from '../../components/TablesTable';
import TableWarning from '../../components/TableWarning';
import withVenue from '../../hoc/withVenue';
import shouldLoad from '../../shared/utils/shouldLoad';
import { useNotifications } from '../../shared/contexts/Notifications/useNotifications';
import { getErrorMessage } from '../../shared/utils/errors';
import PageHeader from '../../components/PageHeader';

const useStyles = makeStyles((theme) => ({
  warningCard: {
    backgroundColor: '#FFB800',
    marginBottom: theme.spacing(5),
  },
  heading: {
    display: 'flex',
    margin: '15px 0',
  },
  title: {
    flexGrow: 1,
  },
  button: {
    marginLeft: '20px',
  },
}));

const Tables = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const tablesState = useSelector(getTablesState);
  const { loading, data, error } = tablesState;
  const { showErrorNotification, showSuccessNotification } = useNotifications();

  const handleEnableSwitch = async (values) => {
    try {
      await dispatch(updateTableAvailability({ values }));
      showSuccessNotification(`Table availability has been successfully updated`);
    } catch (err) {
      showErrorNotification(getErrorMessage(err));
      await dispatch(fetchTables());
    }
  };

  const fetchAll = useCallback(async () => {
    if (shouldLoad(tablesState)) dispatch(fetchTables());
  }, [dispatch, tablesState]);

  useEffect(() => {
    fetchAll();
  }, [dispatch, fetchAll]);

  return (
    <>
      <PageHeader>
        <div className={classes.heading}>
          <Typography className={classes.title} variant="h2" component="h1">
            Tables
          </Typography>
          <aside>
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              startIcon={<FiPlus />}
              component={Link}
              to="/tables/add"
            >
              Add Table
            </Button>
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              startIcon={<FiPlus />}
              component={Link}
              to="/tables/addMultiple"
            >
              Add Range of Tables
            </Button>
          </aside>
        </div>
      </PageHeader>
      <Page loading={loading} error={error}>
        <TableWarning showDevicesLink />
        {data && (
          <>
            <TablesTable onSubmit={handleEnableSwitch} />
          </>
        )}
      </Page>
    </>
  );
};

export default withVenue(Tables, null, clearTables);
