import moment from 'moment-timezone';

import { REPORTING_TYPES } from '../constants/units';

const fromUTC = (date, reportingType) => {
  switch (reportingType) {
    case REPORTING_TYPES.OPERATIONAL:
      return moment(date).subtract(5, 'hours').utc().toISOString();
    case REPORTING_TYPES.TRANSACTIONAL:
      return moment(date).utc().toISOString();
    case REPORTING_TYPES.UTC:
      return moment(date).subtract(moment().utcOffset(), 'minutes').toISOString();
    default:
      return moment(date).utc().toISOString();
  }
};

export default fromUTC;
