import React, { useState } from 'react';
import { TableCell, TableRow, Typography, Button, Dialog } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { MdChevronRight } from 'react-icons/md';

import TableLink from '../../../../components/TableLink';
import VenueAssociation from '../../../../components/Brands/VenueAssociation';

const useStyles = makeStyles((theme) => ({
  table: {
    overflowY: 'auto',
    marginTop: theme.spacing(2),
  },
  tableHead: {
    backgroundColor: theme.customPalette.midnight,
    '& .MuiTableCell-head': {
      color: theme.customPalette.white,
      fontWeight: 'bold',
      textTransform: 'uppercase',
    },
  },
  venueAssociationBtn: {
    color: theme.customPalette.midnight,
    '&:visited': {
      color: theme.customPalette.midnight,
    },
  },
}));

const formatDateString = (date) => {
  const dateObj = new Date(date);
  return dateObj.toLocaleString('en-GB', {
    hour: '2-digit',
    minute: '2-digit',
    day: '2-digit',
    month: '2-digit',
    year: '2-digit',
  });
};

const mostRecentMenuUpdate = (menus) =>
  formatDateString(
    menus.reduce((latestDate, currentMenu) => {
      const currentDate = new Date(currentMenu.updatedAt);
      return currentDate > latestDate ? currentDate : latestDate;
    }, new Date(0)),
  );

const truncateDescription = (description) => {
  if (description.length > 35) {
    return `${description.slice(0, 35)}...`;
  }
  return description;
};

const BrandsMenuTableRow = ({ brand }) => {
  const [showVenueAssociationDialog, setShowVenueAssociationDialog] = useState(false);
  const [variationId, setVariationId] = useState(null);
  const [venueAssociationTitle, setVenueAssociationTitle] = useState('');
  const [showVariantsRow, setShowVariantsRow] = useState(false);
  const classes = useStyles();

  return (
    <>
      <TableRow key={brand.brandId}>
        <TableCell>
          <TableLink to={`/brand-menu-management/${brand.brandId}/settings`}>
            {brand.brandName}
          </TableLink>
        </TableCell>
        <TableCell align="right">
          <Button
            variant="text"
            className={classes.variantBtn}
            onClick={() => setShowVariantsRow(!showVariantsRow)}
          >
            <Typography variant="body2">
              {brand.menus.length} {brand.menus.length > 1 ? 'variations' : 'variation'}
            </Typography>
            <MdChevronRight
              style={{
                transform: showVariantsRow ? 'rotate(270deg)' : 'rotate(90deg)',
                fontSize: 24,
                marginLeft: 4,
              }}
            />
          </Button>
        </TableCell>
        <TableCell align="right" width="450px">
          -
        </TableCell>
        <TableCell align="right">
          <TableLink
            onClick={() => {
              setVenueAssociationTitle(`Venues Associated with ${brand.brandName}`);
              setVariationId(null);
              setShowVenueAssociationDialog(true);
            }}
            underline="always"
            className={classes.venueAssociationBtn}
          >
            {brand.venues} venues
          </TableLink>
        </TableCell>
        <TableCell align="right">{mostRecentMenuUpdate(brand.menus)}</TableCell>
      </TableRow>
      {showVariantsRow &&
        brand.menus &&
        brand.menus.map((menu) => (
          <TableRow key={menu.brandMenuId}>
            <TableCell align="right" colSpan={2}>
              <TableLink to={`/brand-menu-management/menus/${menu.brandMenuId}`} underline="always">
                {menu.variation}
              </TableLink>
            </TableCell>
            <TableCell align="right">{truncateDescription(menu.description)}</TableCell>
            <TableCell align="right">
              <TableLink
                onClick={() => {
                  setVenueAssociationTitle(
                    `Venues Associated with ${brand.brandName} - ${menu.variation}`,
                  );
                  setVariationId(menu.brandMenuId);
                  setShowVenueAssociationDialog(true);
                }}
                underline="always"
                className={classes.venueAssociationBtn}
              >
                {menu.venues} venues
              </TableLink>
            </TableCell>
            <TableCell align="right">{formatDateString(menu.updatedAt)}</TableCell>
          </TableRow>
        ))}
      <Dialog
        open={showVenueAssociationDialog}
        onClose={() => {
          setShowVenueAssociationDialog(false);
        }}
        maxWidth="xl"
      >
        <VenueAssociation
          handleCloseDialog={() => setShowVenueAssociationDialog(false)}
          title={venueAssociationTitle}
          brandId={brand.brandId}
          brandMenuId={variationId}
          isBrandLevel={!variationId}
        />
      </Dialog>
    </>
  );
};

export default BrandsMenuTableRow;
