import { createSelector } from '@reduxjs/toolkit';

export const getBrandsMenusState = (state) => state.brandsMenus;
export const getBrandsMenusListState = (state) => state.brandsMenusList;

export const getBrandsMenus = createSelector(
  getBrandsMenusState,
  (brandsMenusState) => brandsMenusState.data,
);

export const getBrandsMenusList = createSelector(
  getBrandsMenusListState,
  (brandsMenusListState) => brandsMenusListState.data,
);
