import { pick } from 'ramda';
import { get, post, patch, del } from './httpRequest';

export const fetchCategories = async () => {
  const response = await get(`/categories`);
  return response.data;
};

export const fetchCategory = async (categoryId) => {
  const response = await get(`/categories/${categoryId}`);
  return response.data;
};

export const deleteCategory = async (categoryId) => {
  const response = await del(`/categories/${categoryId}`);
  return response.data;
};

export const createCategory = async (category) => {
  const response = await post(`/categories`, {
    data: category,
  });

  return response.data;
};

export const updateCategory = async ({ categoryId, values }) => {
  const trimmedValues = {
    ...pick(['type', 'categoryName'], values),
  };

  await patch(`/categories/${categoryId}`, {
    data: trimmedValues,
  });
};
