import { Card, Checkbox, FormControlLabel, TextField, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { useNotifications } from '../../shared/contexts/Notifications/useNotifications';
import { getErrorMessage } from '../../shared/utils/errors';
import {
  fetchDiscount,
  addDiscountItems,
  removeDiscountItems,
  clearDiscount,
} from '../../store/itemDiscounts';
import { getDiscountState } from '../../store/itemDiscounts/selectors';
import { fetchMenuItems } from '../../store/menuItems';
import { getMenuItemsState } from '../../store/menuItems/selectors';
import DiscountAddItemForm from './DiscountAddItemForm';

import Page from '../../components/Page';
import BackArrow from '../../components/BackArrow';

import withVenue from '../../hoc/withVenue';
import shouldLoad from '../../shared/utils/shouldLoad';

const useStyles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(2),
  },
  category: {
    backgroundColor: '#F0F4F8',
    borderRadius: '4px',
    padding: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
  },
  item: {
    padding: theme.spacing(1),
  },
  itemCategory: {
    color: '#647F99',
  },
  checkbox: {
    paddingTop: 0,
    marginRight: theme.spacing(1),
  },
  expandButton: {
    border: '1px solid #657E98',
    borderRadius: '5px',
    marginRight: theme.spacing(1),
  },
  smallButton: {
    padding: '5px',
    minWidth: '39px',
  },
  heading: {
    display: 'flex',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    alignItems: 'center',
    gap: theme.spacing(1),
  },
}));

const DiscountAddItem = () => {
  const classes = useStyles();
  const history = useHistory();
  const { discountId } = useParams();
  const { showErrorNotification, showSuccessNotification } = useNotifications();
  const discountState = useSelector(getDiscountState);
  const menuItemsState = useSelector(getMenuItemsState);
  const { loading: loadingDiscount, data: discount, error: errorDiscount } = discountState;
  const { loading: loadingItems, data: items, error: errorItems } = menuItemsState;
  const dispatch = useDispatch();
  const { name } = discount || {};
  const [showOthers, setShowOthers] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');

  const refresh = async () => {
    history.push(`/item-discount/${discountId}`);
  };

  const onSubmit = async (updatedValues) => {
    setSearchTerm('');
    const initialItemsChecked = items
      ?.filter((item) => item.discount?.discountId === discountId)
      .map((item) => item.itemId);
    const itemsToRemove = initialItemsChecked.filter(
      (item) => !updatedValues?.itemsChecked.includes(item),
    );
    try {
      dispatch(addDiscountItems(discountId, { items: updatedValues.itemsChecked }));
      await dispatch(removeDiscountItems(discountId, { items: itemsToRemove }));
      await dispatch(fetchMenuItems());
      showSuccessNotification('Discount items updated successfully');
      refresh();
    } catch (err) {
      showErrorNotification(getErrorMessage(err));
    }
  };

  const handleSearchFieldChanged = ({ target: { value } }) => {
    setSearchTerm(value);
  };

  useEffect(() => {
    if (shouldLoad(discountState)) dispatch(fetchDiscount(discountId));
  }, [dispatch, discountId, discountState]);

  useEffect(() => {
    if (shouldLoad(menuItemsState)) dispatch(fetchMenuItems());
  }, [dispatch, menuItemsState]);

  return (
    <Page loading={loadingItems || loadingDiscount} error={errorDiscount || errorItems}>
      <div className={classes.heading}>
        <BackArrow to={`item-discount/${discountId}`} />
        <Typography variant="h2">Add items to {name}</Typography>
      </div>
      <div className={classes.heading}>
        <TextField
          label="Search items"
          variant="outlined"
          size="small"
          onChange={handleSearchFieldChanged}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={showOthers}
              onChange={() => setShowOthers(!showOthers)}
              name="showOtherDiscounts"
            />
          }
          label={
            <Typography variant="body2" className={classes.greyText}>
              Show items that are already discounted
            </Typography>
          }
        />
      </div>
      <Card className={classes.card}>
        <DiscountAddItemForm
          searchTerm={searchTerm}
          items={items}
          discountId={discountId}
          onSubmit={onSubmit}
          showOthers={showOthers}
        />
      </Card>
    </Page>
  );
};
export default withVenue(DiscountAddItem, '/item-discounts', clearDiscount);
