import React from 'react';
import { useField } from 'formik';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment-timezone';

const TimePickerField = ({ name, ...props }) => {
  const [field, meta, helpers] = useField(props.field.name);

  const onChange = (date) => {
    if (date) {
      const mval = moment(date);
      helpers.setValue(mval);
    } else {
      helpers.setValue(null);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <TimePicker
        {...field}
        {...props}
        value={field.value || null}
        onChange={onChange}
        error={meta.touched && Boolean(meta.error)}
        helperText={meta.touched ? meta.error : ''}
        slotProps={{
          textField: {
            inputProps: {
              'data-test-id': props.dataTestId,
            },
          },
        }}
      />
    </LocalizationProvider>
  );
};

export default TimePickerField;
