import { Provider } from 'react-redux';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import StylesProvider from '@mui/styles/StylesProvider';
import CssBaseline from '@mui/material/CssBaseline';
import { IntercomProvider } from 'react-use-intercom';
import moment from 'moment-timezone';
import { HelmetProvider } from 'react-helmet-async';

import muiTheme from './theme/mui';
import store from './store';
import Root from './Root';
import NotificationsProvider from './shared/contexts/Notifications';
import mirageServer from './mirageServer';
import CacheBuster from './components/CacheBuster/CacheBuster';
import AppHead from './components/AppHead';

import 'swiper/swiper-bundle.min.css';
import ThemeSwitcher from './theme/ThemeSwitcher';

moment.tz.setDefault(moment.tz.guess());

const { REACT_APP_INTERCOM_APP_ID: INTERCOM_APP_ID } = process.env;

if (process.env.REACT_APP_MOCK_API) {
  mirageServer();
}

if (process.env.REACT_APP_SENTRY_DISABLED !== 'true') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_KEY,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

const root = ReactDOM.createRoot(document.getElementById('react-root'));

root.render(
  <HelmetProvider>
    <AppHead />
    <CacheBuster>
      <IntercomProvider appId={INTERCOM_APP_ID}>
        <Provider store={store}>
          <ThemeSwitcher theme={muiTheme}>
            <StylesProvider injectFirst>
              <CssBaseline />
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <NotificationsProvider>
                  <BrowserRouter
                    getUserConfirmation={() => {
                      /* Empty callback to block the default browser prompt
                      Required to have a custom prompt in UniversalSave */
                    }}
                  >
                    <Route component={Root} />
                  </BrowserRouter>
                </NotificationsProvider>
              </LocalizationProvider>
            </StylesProvider>
          </ThemeSwitcher>
        </Provider>
      </IntercomProvider>
    </CacheBuster>
  </HelmetProvider>,
);

if (module.hot) {
  module.hot.accept();
}
