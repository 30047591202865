import { get, getBare, patch } from './httpRequest';

export const fetchPersonalisation = async () => {
  const response = await get('/personalisation');
  return response.data;
};

export const fetchBannersGallery = async () => {
  const response = await getBare('banners/images');
  return response.data;
};

export const updatePersonalisation = async ({ values }) => {
  await patch('/personalisation', {
    data: values,
  });
};
