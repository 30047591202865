import { createSlice } from '@reduxjs/toolkit';
import {
  asyncInitialState as initialState,
  createAsyncAction,
  createAsyncReducerHandlers,
} from '../utils/async';
import { createSignupApi } from '../../api/signup';

export const createSignup = createAsyncAction('create-signup', createSignupApi);

const signupSlice = createSlice({
  name: 'signup',
  initialState,
  reducers: {},
  extraReducers: createAsyncReducerHandlers(createSignup),
});

export const { reducer: signup } = signupSlice;
