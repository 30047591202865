import React from 'react';

import { useParams } from 'react-router';
import withVenue from '../../hoc/withVenue';
import TabContainer from '../../components/TabContainer';
import ReportStaffPerformance from './ReportStaffPerformance';
import ReportStaffTips from './ReportStaffTips';

const ReportStaff = () => {
  const { tabname } = useParams();
  return (
    <TabContainer titles={['Performance', 'Tips']} defaultTab={tabname} path="/reports/staff/">
      <ReportStaffPerformance />
      <ReportStaffTips />
    </TabContainer>
  );
};

export default withVenue(ReportStaff);
