import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  phone: Yup.string()
    .min(3, 'Too Short!')
    .matches(
      // eslint-disable-next-line max-len
      /^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?#(\d{4}|\d{3}))?$/,
      'Invalid mobile number.',
    )
    .required('Please enter a valid phone number'),
  email: Yup.string().email().min(3, 'Too Short!').required('Please enter a valid email'),
});

export default validationSchema;
