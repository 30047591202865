import React from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import { TextField } from 'formik-mui';
import { Button, Card, CardContent, DialogActions, FormGroup, MenuItem } from '@mui/material';

const OptionForm = ({ onSubmit, formAction, onCancel, optionData }) => {
  const optionTypes = ['DRINK', 'FOOD'];
  const submitButtonLabel = formAction === 'create' ? 'Create' : 'Update';
  const { optionName, type } = optionData || {};

  const initialValues = {
    optionName: optionName || '',
    type: type || 'DRINK',
  };

  const OptionSchema = Yup.object().shape({
    optionName: Yup.string().min(3, 'Too Short!').required('Please enter a name'),
    type: Yup.string().required('Please choose a type'),
  });

  return (
    <Card>
      <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={OptionSchema}>
        {({ dirty, resetForm }) => (
          <>
            <Form>
              <CardContent>
                <section>
                  <section>
                    <FormGroup>
                      <Field
                        component={TextField}
                        select
                        fullWidth
                        name="type"
                        label="Type"
                        variant="outlined"
                        margin="normal"
                        required
                      >
                        {optionTypes.map((optionType) => (
                          <MenuItem value={optionType} key={optionType}>
                            {optionType}
                          </MenuItem>
                        ))}
                      </Field>
                    </FormGroup>
                  </section>
                </section>
                <FormGroup>
                  <Field
                    component={TextField}
                    name="optionName"
                    label="Size Name e.g. half pint"
                    variant="outlined"
                    fullWidth
                    required
                    margin="normal"
                  />
                </FormGroup>
              </CardContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    onCancel();
                    resetForm();
                  }}
                >
                  Cancel
                </Button>
                {formAction === 'create' ? (
                  <Button type="submit" variant="contained" color="primary" disabled={!dirty}>
                    {submitButtonLabel}
                  </Button>
                ) : null}
              </DialogActions>
            </Form>
          </>
        )}
      </Formik>
    </Card>
  );
};

export default OptionForm;
