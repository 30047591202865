import React from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Button, CardContent, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import { Form, Formik } from 'formik';
import CustomDialog from '../CustomDialog';
import AutoComplete from '../AutoComplete';
import { fetchUpsellGroup, updateUpsellGroup } from '../../store/upsellGroups';
import { getErrorMessage } from '../../shared/utils/errors';
import { useNotifications } from '../../shared/contexts/Notifications/useNotifications';
import useItemsOnMenu from '../../hooks/useItemsOnMenu';

const useStyles = makeStyles((theme) => ({
  title: {
    ...theme.customFonts.largeBold,
  },
  label: {
    ...theme.customFonts.label,
    color: theme.customPalette.greyDarker,
    marginTop: '8px',
  },
}));

const EditCustomUpsellGroupSuggestedItems = ({
  isDialogOpen,
  handleCloseDialog,
  menuItems,
  preSelectedItems,
  upsellGroupId,
  name,
  enabled,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const sortedMenuItems = _.sortBy(menuItems, 'itemName');
  const { checkIfItemIsOnMenu } = useItemsOnMenu();
  const { showSuccessNotification, showErrorNotification } = useNotifications();

  const menuItemsUpdated = (values) =>
    sortedMenuItems?.filter((item) => !values?.includes(item?.itemId));

  const getPreSelectedItemName = (itemId) => {
    if (itemId) {
      const item = sortedMenuItems.find((itemToFind) => itemToFind.itemId === itemId);
      return item;
    }
    return [];
  };

  const handleOnSubmit = async (values) => {
    const transformedValues = values.filter((value) => value !== '');
    try {
      await dispatch(
        updateUpsellGroup({
          upsellGroupId,
          data: {
            type: 'CUSTOM_UPSELLS',
            suggestedItemIds: transformedValues,
            name,
            enabled,
          },
        }),
      );
      await dispatch(fetchUpsellGroup({ upsellGroupId }));
      handleCloseDialog();
      showSuccessNotification('Successfully updated suggested items');
    } catch (error) {
      showErrorNotification(getErrorMessage(error));
    }
  };

  return (
    <Formik initialValues={preSelectedItems} enableReinitialize>
      {({ values, setFieldValue, resetForm, dirty }) => (
        <Form>
          <CustomDialog
            title="Choose items to promote"
            isDialogOpen={isDialogOpen}
            handleCloseDialog={() => {
              handleCloseDialog();
              resetForm();
            }}
            actions={
              <>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleOnSubmit(values)}
                  disabled={!dirty}
                >
                  Save
                </Button>
                <Button
                  onClick={() => {
                    handleCloseDialog();
                    resetForm();
                  }}
                >
                  Cancel
                </Button>
              </>
            }
          >
            <CardContent>
              <Box>
                <Typography className={classes.label}>Select an item to promote</Typography>
                <AutoComplete
                  checkIfItemIsOnMenu={checkIfItemIsOnMenu}
                  addUnknownOff
                  label=""
                  options={menuItemsUpdated(values)}
                  optionLabelKey="itemName"
                  optionValueKey="itemId"
                  onSelect={(e) => setFieldValue('[0]', e)}
                  onInputChange={(e, value) => {
                    if (value === '') {
                      setFieldValue('[0]', '', false);
                    }
                  }}
                  value={getPreSelectedItemName(values[0])}
                />
                <Typography className={classes.label}>Select an item to promote</Typography>
                <AutoComplete
                  checkIfItemIsOnMenu={checkIfItemIsOnMenu}
                  className={classes.autoComplete}
                  addUnknownOff
                  label=""
                  options={menuItemsUpdated(values)}
                  optionLabelKey="itemName"
                  optionValueKey="itemId"
                  onSelect={(e) => setFieldValue('[1]', e)}
                  onInputChange={(e, value) => {
                    if (value === '') {
                      setFieldValue('[1]', '', false);
                    }
                  }}
                  value={getPreSelectedItemName(values[1])}
                />
                <Typography className={classes.label}>Select an item to promote</Typography>

                <AutoComplete
                  checkIfItemIsOnMenu={checkIfItemIsOnMenu}
                  className={classes.autoComplete}
                  addUnknownOff
                  label=""
                  options={menuItemsUpdated(values)}
                  optionLabelKey="itemName"
                  optionValueKey="itemId"
                  onSelect={(e) => setFieldValue('[2]', e)}
                  onInputChange={(e, value) => {
                    if (value === '') {
                      setFieldValue('[2]', '', false);
                    }
                  }}
                  value={getPreSelectedItemName(values[2])}
                />
              </Box>
            </CardContent>
          </CustomDialog>
        </Form>
      )}
    </Formik>
  );
};

export default EditCustomUpsellGroupSuggestedItems;
