import { pick } from 'ramda';
import { getBare, postBare, deleteBare, patchBare } from '../httpRequest';

export const fetchBrandsCategoriesApi = async () => {
  const response = await getBare(`brands/categories`);
  return response.data;
};

export const fetchBrandsCategoryApi = async (categoryId) => {
  const response = await getBare(`brands/categories/${categoryId}`);
  return response.data;
};

export const deleteBrandsCategoryApi = async (categoryId) => {
  const response = await deleteBare(`brands/categories/${categoryId}`);
  return response.data;
};

export const createBrandsCategoryApi = async (category) => {
  const response = await postBare(`brands/categories`, {
    data: category,
  });

  return response.data;
};

export const updateBrandsCategoryApi = async ({ categoryId, values }) => {
  const trimmedValues = {
    ...pick(['type', 'categoryName'], values),
  };

  await patchBare(`brands/categories/${categoryId}`, {
    data: trimmedValues,
  });
};
