import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import formatNumber from '../../shared/utils/formatNumber';
import StatusLozenge from '../StatusLozenge';

const useStyles = makeStyles((theme) => ({
  row: {
    height: '80%',
    marginBottom: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  mainPct: {
    ...theme.customFonts.xxLarge,
    color: theme.customPalette.greyDarker,
    textAlign: 'center',
  },
  mainAmount: {
    color: theme.customPalette.greyDarker,
    textAlign: 'center',
  },
  amountBox: {
    marginBottom: theme.spacing(1),
  },
  type: {
    ...theme.customFonts.small,
    color: theme.customPalette.greyDark,
  },
  divider: {
    borderLeft: '1px solid #D9E2EC',
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
  },
  lozenge: {
    padding: '2px 8px',
    marginLeft: theme.spacing(1),
  },
}));

const ReportsWetDry = ({ current, previous }) => {
  const classes = useStyles();

  const drinkChange = ((current.drinkSales - previous.drinkSales) / previous.drinkSales) * 100;
  const drinkColour = drinkChange > 0 ? 'success' : 'fail';
  const drinkChangeLabel = drinkChange === Infinity ? 'N/A' : `${drinkChange.toFixed(2)}%`;

  const foodChange = ((current.foodSales - previous.foodSales) / previous.foodSales) * 100 || 0;
  const foodColour = foodChange > 0 ? 'success' : 'fail';
  const foodChangeLabel = foodChange === Infinity ? 'N/A' : `${foodChange.toFixed(2)}%`;

  return (
    <>
      <Box display="flex" className={classes.row} justifyContent="space-evenly">
        <Box display="flex" flexDirection="column" justifyContent="center">
          <Typography className={classes.mainPct}>{current.drink}%</Typography>
          {current.drinkSales !== null ? (
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              className={classes.amountBox}
            >
              <Typography className={classes.mainAmount}>
                £{formatNumber(current.drinkSales, 2)}
              </Typography>
              <StatusLozenge
                text={drinkChangeLabel}
                status={drinkChangeLabel === 'N/A' ? 'inactive' : drinkColour}
                className={classes.lozenge}
              />
            </Box>
          ) : null}
          <Box display="flex" alignItems="center" justifyContent="center">
            <Typography className={classes.type}>Drink Gross Sales</Typography>
          </Box>
        </Box>
        <div className={classes.divider} />
        <Box display="flex" flexDirection="column" justifyContent="center">
          <Typography className={classes.mainPct}>{current.food}%</Typography>
          {current.foodSales !== null ? (
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              className={classes.amountBox}
            >
              <Typography className={classes.mainAmount}>
                £{formatNumber(current.foodSales, 2)}
              </Typography>
              <StatusLozenge
                text={foodChangeLabel}
                status={foodChangeLabel === 'N/A' ? 'inactive' : foodColour}
                className={classes.lozenge}
              />
            </Box>
          ) : null}
          <Box display="flex" alignItems="center" justifyContent="center">
            <Typography className={classes.type}>Food Gross Sales</Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default ReportsWetDry;
