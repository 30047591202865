import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { makeStyles } from '@mui/styles';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import ListItemButton from '@mui/material/ListItemButton';

import { without } from 'ramda';
import { getBrandsIngredientsObj } from '../../../store/brands/brandsIngredients/selectors';
import {
  getBrandsAttributeValues,
  getBrandsIngredientsForItemType,
} from '../../../store/brands/brandsMenuItems/selectors';

const useStyles = makeStyles(() => ({
  card: { marginBottom: '20px' },
  cardContent: {
    height: '400px',
    overflow: 'auto',
  },
  cardActions: {
    justifyContent: 'flex-end',
  },
  listItemText: {
    paddingTop: '6px',
  },
}));

const BrandsAddAttributes = ({ attribute, onSave }) => {
  const classes = useStyles();
  const picked = useSelector((state) => getBrandsAttributeValues(state, attribute));
  const available = useSelector(getBrandsIngredientsForItemType);
  const ingredients = useSelector(getBrandsIngredientsObj);
  const history = useHistory();

  const availableWithoutPicked = picked ? without(picked, available) : available;

  const [checked, setChecked] = useState([]);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleSave = () => {
    onSave(picked.concat(checked));
  };

  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        <List dense role="list">
          {availableWithoutPicked.map((ingredient) => (
            <ListItemButton key={ingredient} onClick={handleToggle(ingredient)}>
              <ListItemIcon>
                <Checkbox checked={checked.includes(ingredient)} tabIndex={-1} disableRipple />
                <ListItemText
                  className={classes.listItemText}
                  primary={ingredients[ingredient].ingredientName}
                />
              </ListItemIcon>
            </ListItemButton>
          ))}
          <ListItem />
        </List>
      </CardContent>
      <CardActions className={classes.cardActions}>
        <Button onClick={() => history.goBack()}>Cancel</Button>
        <Button type="submit" variant="contained" color="primary" onClick={handleSave}>
          Add
        </Button>
      </CardActions>
    </Card>
  );
};

BrandsAddAttributes.defaultProps = {
  attribute: 'modifiers',
};

BrandsAddAttributes.propTypes = {
  attribute: PropTypes.oneOf(['itemIngredients', 'modifiers']),
  onSave: PropTypes.func.isRequired,
};

export default BrandsAddAttributes;
