import { createSlice } from '@reduxjs/toolkit';
import {
  asyncInitialState as initialState,
  createAsyncAction,
  createAsyncReducerHandlers,
} from '../utils/async';
import {
  acceptCampaign as acceptCampaignApi,
  updateCampaign as updateCampaignApi,
  updateBrandupCampaign as updateBrandupCampaignApi,
  createCampaign as createCampaignApi,
  deleteCampaign as deleteCampaignApi,
  fetchCampaign as fetchCampaignApi,
} from '../../api/campaigns';

export const acceptCampaign = createAsyncAction('accept-campaign', acceptCampaignApi);
export const updateCampaign = createAsyncAction('update-campaign', updateCampaignApi);
export const updateBrandupCampaign = createAsyncAction(
  'update-brandup-campaign',
  updateBrandupCampaignApi,
);
export const createCampaign = createAsyncAction('create-campaign', createCampaignApi);
export const deleteCampaign = createAsyncAction('delete-campaign', deleteCampaignApi);
export const fetchCampaign = createAsyncAction('fetch-campaign', fetchCampaignApi);

const campaignsSlice = createSlice({
  name: 'campaign',
  initialState,
  reducers: {
    clearCampaign(state) {
      delete state.data;
    },
  },
  extraReducers: {
    ...createAsyncReducerHandlers(fetchCampaign),
    ...createAsyncReducerHandlers(acceptCampaign),
    ...createAsyncReducerHandlers(deleteCampaign),
  },
});

export const { reducer: campaign } = campaignsSlice;
export const { clearCampaign } = campaignsSlice.actions;
