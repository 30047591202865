const orderObject = (obj, order) => {
  const ordered = {};
  order.forEach((key) => {
    if (obj[key] !== undefined) {
      ordered[key] = obj[key];
    }
  });
  return ordered;
};

export default orderObject;
