import { createSlice } from '@reduxjs/toolkit';
import {
  asyncInitialState as initialState,
  createAsyncAction,
  createAsyncReducerHandlers,
} from '../utils/async';
import {
  fetchAllStaff as fetchAllStaffApi,
  updateStaff as updateStaffApi,
  updateStaffAvailability as updateStaffAvailabilityApi,
  fetchStaff as fetchStaffApi,
  deleteStaff as deleteStaffApi,
  createStaff as createStaffApi,
} from '../../api/staff';

export const fetchAllStaff = createAsyncAction('fetch-all-staff', fetchAllStaffApi);

export const updateStaff = createAsyncAction('update-staff', updateStaffApi);
export const updateStaffAvailability = createAsyncAction(
  'update-staff-availability',
  updateStaffAvailabilityApi,
);
export const fetchStaff = createAsyncAction('fetch-staff', fetchStaffApi);
export const deleteStaff = createAsyncAction('delete-staff', deleteStaffApi);
export const createStaff = createAsyncAction('create-staff', createStaffApi);

const allStaffSlice = createSlice({
  name: 'staffAll',
  initialState,
  reducers: {
    clearAllStaff(state) {
      delete state.data;
    },
  },
  extraReducers: createAsyncReducerHandlers(fetchAllStaff),
});

const staffSlice = createSlice({
  name: 'staff',
  initialState,
  reducers: {
    clearStaff(state) {
      delete state.data;
    },
  },
  extraReducers: {
    ...createAsyncReducerHandlers(updateStaff),
    ...createAsyncReducerHandlers(fetchStaff),
    ...createAsyncReducerHandlers(deleteStaff),
    ...createAsyncReducerHandlers(createStaff),
  },
});
export const { clearAllStaff } = allStaffSlice.actions;
export const { clearStaff } = staffSlice.actions;

export const { reducer: staffAll } = allStaffSlice;
export const { reducer: staff } = staffSlice;
