import React from 'react';
import { styled, Tab } from '@mui/material';

import { withStyles } from 'tss-react/mui';

const ConfiguredTab = styled((props) => <Tab disableRipple {...props} />)(() => ({
  textTransform: 'none',
  minWidth: 0,
}));

const ToolTab = withStyles(ConfiguredTab, (theme) => ({
  root: {
    padding: 0,
    minWidth: 'auto',
    marginRight: theme.spacing(2),
  },
}));

export default ToolTab;
