import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { TableFooter, TablePagination, Typography } from '@mui/material';
import moment from 'moment-timezone';

import TableSkeletonLoader from '../TableSkeletonLoader';
import TablePaginationActions from '../TablePaginationActions';
import decideVoucherStatus from '../../pages/Vouchers/decideVoucherStatus';
import VoucherStatuses from '../../pages/Vouchers/VoucherStatuses';
import TableLink from '../TableLink';

const VouchersTable = ({ vouchers, loading, pagination }) => {
  const { page, handleChangePage, rowsPerPage } = pagination;
  const displayVouchers = vouchers?.docs || vouchers;
  const vouchersNumber = vouchers?.total;
  const hasVouchers = displayVouchers.length > 0;

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography variant="h3">Name</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="h3">Code</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="h3">Email</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="h3">Enabled</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="h3">Status</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="h3">Start</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="h3">End</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="h3">Amount</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="h3">Balance</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        {loading && <TableSkeletonLoader rows={10} cells={3} cellsSize={['small']} />}
        {!loading && hasVouchers && (
          <>
            <TableBody>
              {displayVouchers
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((voucher) => {
                  const amount = voucher.amount.toFixed(2).toLocaleString();
                  const { balance: rawbalance } = voucher;
                  const balance = voucher.balance.toFixed(2).toLocaleString();
                  const { startDate, endDate, voucherCode, enabled, name, email } = voucher;
                  const dateStart = moment(startDate).local().format('DD/MM/YY');
                  const dateEnd = moment(endDate).format('DD/MM/YY');

                  const status = decideVoucherStatus(rawbalance, enabled, endDate);

                  return (
                    <TableRow key={voucher.voucherCode}>
                      <TableCell>{name}</TableCell>
                      <TableCell>
                        <TableLink to={`/voucher/${voucher.voucherCode}`}>
                          {voucherCode || 'no name given'}
                        </TableLink>
                      </TableCell>
                      <TableCell>{email}</TableCell>
                      <TableCell>{VoucherStatuses[enabled ? 'ENABLED' : 'DISABLED']}</TableCell>
                      <TableCell>{VoucherStatuses[status]}</TableCell>
                      <TableCell>{dateStart}</TableCell>
                      <TableCell>{dateEnd}</TableCell>
                      <TableCell>£{amount}</TableCell>
                      <TableCell>£{balance}</TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </>
        )}
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[]}
              count={vouchersNumber}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

export default VouchersTable;
