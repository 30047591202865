import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Button, Card, CardContent, Divider, Grid, Typography } from '@mui/material';

import { makeStyles } from '@mui/styles';

import withVenue from '../../../hoc/withVenue';
import Page from '../../../components/Page';
import BackArrow from '../../../components/BackArrow';
import {
  formatTimeString,
  parseOpeningHoursforUi,
} from '../../../shared/utils/deliveryOpeningHours';
import PageHeader from '../../../components/PageHeader';
import DeliveryHoursDialog from '../../../components/DeliveryHoursDialog';
import { updateVenue, fetchVenue } from '../../../store/venues';
import { getErrorMessage } from '../../../shared/utils/errors';
import { useNotifications } from '../../../shared/contexts/Notifications/useNotifications';

const useStyles = makeStyles(() => ({
  pageHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  openingHoursContainer: {
    '& .MuiDivider-root:last-of-type': {
      display: 'none',
    },
  },
  openingHoursDay: {
    padding: '8px 0',
    fontWeight: 'bold',
  },
  openingHours: {
    padding: '8px 0',
  },
}));

const DeliveryOpeningHours = ({ venue, redirect }) => {
  const { showErrorNotification, showSuccessNotification } = useNotifications();
  const dispatch = useDispatch();
  const [showEditDialog, setShowEditDialog] = useState(false);
  const classes = useStyles();
  const { deliveryOpenings } = venue;
  const [hoursAreUpdating, setHoursAreUpdating] = useState(false);
  const [deliveryOpeningHours, setDeliveryOpeningHours] = useState(deliveryOpenings);

  let formattedOpeningHours = parseOpeningHoursforUi({ openingHours: deliveryOpenings });

  useEffect(() => {
    setDeliveryOpeningHours(deliveryOpenings);
  }, [deliveryOpenings]);

  if (!deliveryOpenings) {
    formattedOpeningHours = [];
  }

  const handleUpdateOpeningHours = async (newOpeningHours) => {
    setHoursAreUpdating(true);
    try {
      await dispatch(updateVenue({ deliveryOpenings: newOpeningHours }));
      await dispatch(fetchVenue());
      setShowEditDialog(false);
      showSuccessNotification('Delivery opening hours updated');
    } catch (error) {
      showErrorNotification(getErrorMessage(error));
      dispatch(fetchVenue());
    }
  };

  return (
    <Page>
      <PageHeader className={classes.pageHeader}>
        <Box>
          <BackArrow redirect={redirect} text="Settings" />
          <Typography variant="h1">Delivery Opening Hours</Typography>
        </Box>
        <Box>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              setShowEditDialog(true);
              setHoursAreUpdating(false);
            }}
          >
            Edit
          </Button>
        </Box>
      </PageHeader>
      <Card style={{ marginTop: 16 }}>
        <CardContent>
          <Grid container direction="column" className={classes.openingHoursContainer}>
            {!formattedOpeningHours ||
              (formattedOpeningHours?.length === 0 && (
                <Typography variant="h2">No delivery opening hours set</Typography>
              ))}
            {formattedOpeningHours &&
              formattedOpeningHours.map(({ day, openingHours, closed }) => (
                <React.Fragment key={`${day}-edit-table`}>
                  <Grid item>
                    <Grid container direction="row" className={classes.openingHoursDayContainer}>
                      <Grid item xs={6} sm={2}>
                        <Typography variant="body2" className={classes.openingHoursDay}>
                          {day}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={10}>
                        <Grid container direction="column" spacing={2}>
                          <Grid item>
                            {closed ? (
                              <Typography variant="body2" className={classes.openingHours}>
                                Closed
                              </Typography>
                            ) : (
                              openingHours.map(
                                ({ fromHour, fromMinute, toHour, toMinute, nextDay }) => {
                                  const nextDayLabel =
                                    nextDay && nextDay.charAt(0) + nextDay.toLowerCase().slice(1);
                                  const formatFromTime = formatTimeString(fromHour, fromMinute);
                                  const formatToTime = formatTimeString(toHour, toMinute);
                                  return (
                                    <Typography
                                      variant="body2"
                                      className={classes.openingHours}
                                      key={`${day}-${fromHour}-table`}
                                    >
                                      {formatFromTime} - {formatToTime}
                                      {nextDay && ` (${nextDayLabel} morning)`}
                                    </Typography>
                                  );
                                },
                              )
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Divider />
                </React.Fragment>
              ))}
          </Grid>
        </CardContent>
      </Card>
      <DeliveryHoursDialog
        open={showEditDialog}
        onClose={() => setShowEditDialog(false)}
        openingHours={deliveryOpeningHours}
        handleUpdateOpeningHours={handleUpdateOpeningHours}
        hoursAreUpdating={hoursAreUpdating}
      />
    </Page>
  );
};

export default withVenue(DeliveryOpeningHours, '/settings/venue');
