import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Card } from '@mui/material';
import { pick } from 'lodash';

import { getBrandsOptionsState } from '../../../store/brands/brandsOptions/selectors';

import Page from '../../../components/Page';
import withVenue from '../../../hoc/withVenue';
import shouldLoad from '../../../shared/utils/shouldLoad';
import OrderableTable from '../../../components/OrderableTable';
import { fetchBrandsOptions, clearBrandsOptions } from '../../../store/brands/brandsOptions';

const BrandsOptions = () => {
  const dispatch = useDispatch();
  const optionsState = useSelector(getBrandsOptionsState);
  const { loading, data, error } = optionsState;
  const [valueData, setValueData] = useState(data);

  const newData = useCallback(() => {
    const pickedData = [];
    if (data) {
      data.forEach((item) => {
        pickedData.push(pick(item, ['optionName', 'type', 'optionId']));
      });
    }
    return pickedData;
  }, [data]);

  useEffect(() => {
    if (shouldLoad(optionsState)) dispatch(fetchBrandsOptions());
    setValueData(data);
  }, [data, dispatch, optionsState]);

  return (
    <Page loading={loading} error={error} fullWidth>
      {data && (
        <>
          {valueData && data && (
            <Card>
              <>
                <OrderableTable
                  tableData={[...newData()]}
                  titles={['NAME', 'TYPE']}
                  keys={['optionId']}
                  excludeFields={['optionId']}
                />
              </>
            </Card>
          )}
        </>
      )}
    </Page>
  );
};

export default withVenue(BrandsOptions, 'brand-menu-management/items/sizes', clearBrandsOptions);
