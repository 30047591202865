import * as Yup from 'yup';

import { signupBusinessTypes } from '../signupFields';

const businessTypesValues = signupBusinessTypes.map(({ value }) => value);

const validationSchema = Yup.object().shape({
  businessType: Yup.string()
    .oneOf(businessTypesValues)
    .required('Please choose a business type from the list'),
});

export default validationSchema;
