import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { makeStyles } from '@mui/styles';
import { Grid, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getErrorMessage } from '../../shared/utils/errors';
import { useNotifications } from '../../shared/contexts/Notifications/useNotifications';

import withVenue from '../../hoc/withVenue';
import {
  fetchDiscount,
  removeDiscountItems,
  updateDiscount,
  deleteDiscount,
  clearDiscount,
} from '../../store/itemDiscounts';
import { getDiscountState } from '../../store/itemDiscounts/selectors';
import Page from '../../components/Page';
import DiscountSummary from './DiscountSummary';
import DiscountItems from './DiscountItems';
import DiscountDialogue from './DiscountDialogue';
import { getMenuItemsState } from '../../store/menuItems/selectors';
import { fetchMenuItems } from '../../store/menuItems';
import ErrorButton from '../../components/Overrides/ErrorButton';
import BackArrow from '../../components/BackArrow';
import DeleteDiscountDialog from './DeleteDiscountDialog';
import shouldLoad from '../../shared/utils/shouldLoad';
import PageHeader from '../../components/PageHeader';

const useStyles = makeStyles(() => ({
  smallButton: {
    padding: '5px',
    minWidth: '39px',
  },
  heading: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
}));

const Discount = ({ redirect }) => {
  const { discountId } = useParams();
  const discountState = useSelector(getDiscountState);
  const menuItemsState = useSelector(getMenuItemsState);
  const { loading, data: discount, error } = discountState;
  const { loading: loadingItems, data: items, error: errorItems } = menuItemsState;
  const dispatch = useDispatch();
  const { showErrorNotification, showSuccessNotification } = useNotifications();
  const { name } = discount || {};

  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  useEffect(() => {
    if (shouldLoad(discountState)) dispatch(fetchDiscount(discountId));
  }, [discountId, discountState, dispatch]);

  useEffect(() => {
    if (shouldLoad(menuItemsState)) dispatch(fetchMenuItems());
  }, [menuItemsState, dispatch]);

  const classes = useStyles();

  const onEdit = () => {
    setIsEditDialogOpen(true);
  };

  const refreshDiscount = async () => {
    await dispatch(fetchDiscount(discountId));
    await dispatch(fetchMenuItems());
  };

  const handleEnableToggle = async () => {
    const transformedValues = {
      ...discount,
      enabled: !discount.enabled,
    };
    try {
      await dispatch(updateDiscount(discountId, transformedValues));
      await dispatch(fetchDiscount(discountId));
      showSuccessNotification(
        `Discount has been ${discount.enabled ? 'paused' : 'unpaused'} successfully`,
      );
    } catch (err) {
      showErrorNotification(getErrorMessage(err));
    }
  };

  const handleRemove = async (itemId) => {
    try {
      await dispatch(removeDiscountItems(discountId, { items: [itemId] }));
      await dispatch(fetchMenuItems());
      showSuccessNotification(`Discount has been removed successfully`);
    } catch (err) {
      showErrorNotification(getErrorMessage(err));
    }
  };

  const showDeleteDialog = () => {
    setIsDeleteDialogOpen(true);
  };

  const handleDelete = async () => {
    try {
      await dispatch(deleteDiscount(discountId));

      showSuccessNotification(`Discount ${name} has been deleted successfully`);
      redirect();
    } catch (err) {
      showErrorNotification('An error occurred while deleting your discount');
    }
  };

  return (
    <>
      <PageHeader>
        <div className={classes.heading}>
          <BackArrow redirect={redirect} text="Discounts" />
          <Typography variant="h1" component="h1">
            {name}
          </Typography>
        </div>
      </PageHeader>
      <Page loading={loadingItems || loading || !discount} error={error || errorItems}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <DiscountSummary
              discount={discount}
              onEdit={onEdit}
              onEnableToggle={handleEnableToggle}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DiscountItems discount={discount} items={items} onRemove={handleRemove} />
          </Grid>
        </Grid>
        <ErrorButton onClick={showDeleteDialog} variant="outlined">
          Delete
        </ErrorButton>
      </Page>
      <DiscountDialogue
        discount={discount}
        isOpen={isEditDialogOpen}
        setIsOpen={setIsEditDialogOpen}
        onSubmit={refreshDiscount}
      />
      <DeleteDiscountDialog
        dialogIsOpen={isDeleteDialogOpen}
        handleCloseDialog={() => setIsDeleteDialogOpen(false)}
        submitDialog={handleDelete}
        discountName={name}
      />
    </>
  );
};

export default withVenue(Discount, '/item-discounts', clearDiscount);
