import React from 'react';

import { useParams } from 'react-router';
import withVenue from '../../hoc/withVenue';
import TabContainer from '../../components/TabContainer';
import ReportSalesOverview from './ReportSalesOverview';
import ReportSalesByArea from './ReportSalesByArea';
import ReportSalesByTable from './ReportSalesByTable';
import ReportSalesByCategory from './ReportSalesByCategory';
import ReportSalesByItem from './ReportSalesByItem';
import PageHeader from '../../components/PageHeader';

const ReportSales = () => {
  const { tabname } = useParams();
  return (
    <PageHeader fullWidth>
      <TabContainer
        titles={['Overview', 'Areas', 'Tables', 'Categories', 'Items']}
        defaultTab={tabname}
        path="/reports/sales/"
      >
        <ReportSalesOverview />
        <ReportSalesByArea />
        <ReportSalesByTable />
        <ReportSalesByCategory />
        <ReportSalesByItem />
      </TabContainer>
    </PageHeader>
  );
};

export default withVenue(ReportSales);
