import { createAction, createSlice } from '@reduxjs/toolkit';
import {
  createAsyncAction,
  asyncInitialState as initialState,
  createAsyncReducerHandlers,
} from '../../utils/async';
import {
  fetchBrandsMenuItemsApi,
  createBrandsMenuItemApi,
  deleteBrandsMenuItemApi,
  fetchBrandsMenuItemApi,
  updateBrandsMenuItemApi,
} from '../../../api/brands/brandsItems';

export const fetchBrandsMenuItems = createAsyncAction(
  'fetch-brands-menu-items',
  fetchBrandsMenuItemsApi,
);

export const fetchBrandsMenuItem = createAsyncAction(
  'fetch-brands-menu-item',
  fetchBrandsMenuItemApi,
);

export const deleteBrandsMenuItem = createAsyncAction(
  'delete-brands-menu-item',
  deleteBrandsMenuItemApi,
);

export const createBrandsMenuItem = createAsyncAction(
  'create-brands-menu-item',
  createBrandsMenuItemApi,
);

const assumeBrandsMenuItem = createAction('assume-brands-menu-item');

export const updateBrandsMenuItem = createAsyncAction(
  'update-brands-menu-item',
  async ({ menuItemId, values }, dispatch) => {
    await updateBrandsMenuItemApi({ menuItemId, values });
    dispatch(assumeBrandsMenuItem(values));
  },
);

const brandsMenuItemsSlice = createSlice({
  name: 'brandsMenuItems',
  initialState,
  reducers: {
    clearBrandsMenuItems(state) {
      delete state.data;
    },
  },
  extraReducers: {
    ...createAsyncReducerHandlers(fetchBrandsMenuItems),
  },
});

const brandsMenuItemSlice = createSlice({
  name: 'brandsMenuItem',
  initialState,
  reducers: {
    clearBrandsMenuItem(state) {
      delete state.data;
    },
  },
  extraReducers: {
    ...createAsyncReducerHandlers(fetchBrandsMenuItem),

    [assumeBrandsMenuItem]: (state, action) => {
      if (state.data) {
        // eslint-disable-next-line no-param-reassign
        state.data = {
          ...state.data,
          ...action.payload,
        };
      }
    },
  },
});

export const { clearBrandsMenuItems } = brandsMenuItemsSlice.actions;
export const { clearBrandsMenuItem } = brandsMenuItemSlice.actions;

export const { reducer: brandsMenuItems } = brandsMenuItemsSlice;
export const { reducer: brandsMenuItem } = brandsMenuItemSlice;
