import { pick, omit } from 'ramda';
import { getBare, postBare, deleteBare, patchBare } from '../httpRequest';

const splitOptionsAndPrices = (options) =>
  options.reduce(
    (acc, option) => {
      acc.itemOptions.push(option.optionName);
      acc.itemPrices.push(option.optionPrice);
      acc.itemCalories.push(option?.optionCalories);
      return acc;
    },
    { itemOptions: [], itemPrices: [], itemCalories: [] },
  );

const combineOptionsAndPrices = (options, prices, calories = []) =>
  options.reduce((acc, option, index) => {
    acc.push({
      optionName: option,
      optionPrice: prices[index],
      optionCalories: calories[index] || undefined,
    });
    return acc;
  }, []);

export const fetchBrandsMenuItemsApi = async () => {
  const response = await getBare('brands/items');
  return response.data;
};

export const fetchBrandsMenuItemApi = async (menuItemId) => {
  const response = await getBare(`brands/items/${menuItemId}`);
  return response.data.itemOptions
    ? {
        ...omit(['itemPrices', 'itemCalories'], response.data),
        itemOptions: combineOptionsAndPrices(
          response.data.itemOptions,
          response.data.itemPrices,
          response.data?.itemCalories,
        ),
      }
    : response.data;
};

export const deleteBrandsMenuItemApi = async (menuItemId) => {
  await deleteBare(`brands/items/${menuItemId}`);
};

export const createBrandsMenuItemApi = async (menuItem) => {
  const val = {
    ...omit(['available'], menuItem),
    ...splitOptionsAndPrices(menuItem.itemOptions),
  };

  const vatRate = parseFloat(menuItem.vatRate);
  const data = { ...val, vatRate };
  const response = await postBare('brands/items', { data });

  return response.data;
};

export const updateBrandsMenuItemApi = async ({ menuItemId, values }) => {
  const itemOptions = values.itemOptions && splitOptionsAndPrices(values.itemOptions);

  const update = {
    ...itemOptions,
    ...pick(
      [
        'available',
        'hidden',
        'category',
        'discountId',
        'type',
        'description',
        'itemIngredients',
        'itemName',
        'modifiers',
        'isAlcoholic',
        'vatRate',
        'dietaryRequirements',
        'label',
        'isMealDeal',
        'inhouseOnly',
      ],
      values,
    ),
  };
  const data = { ...update };
  await patchBare(`brands/items/${menuItemId}`, { data });
};
