import React from 'react';
import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import TillDialog from './TillDialog';
import TillsTable from './TillsTable';

const useStyles = makeStyles((theme) => ({
  table: {
    marginTop: theme.spacing(2),
  },
}));
const defaultInitialValues = { name: '', defaultFloatAmount: 150, mobileDeviceId: '' };
const ManageTills = ({ tills, addTill, mobiles, editTill, deleteTill }) => {
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const [dialogAction, setDialogAction] = React.useState('add');
  const [initialValues, setInitialValues] = React.useState(defaultInitialValues);
  const openDialog = () => setIsDialogOpen(true);
  const closeDialog = () => setIsDialogOpen(false);
  const classes = useStyles();

  const handleAddTill = () => {
    setInitialValues(defaultInitialValues);
    setDialogAction('add');
    openDialog();
  };
  const handleEditTill = ({ name, mobileDeviceId, tillId, defaultFloatAmount }) => {
    setInitialValues({ name, defaultFloatAmount, mobileDeviceId, tillId });
    setDialogAction('edit');
    openDialog();
  };
  return (
    <div>
      <p>
        <strong>My Tills</strong>
      </p>
      <Button variant="outlined" color="primary" onClick={handleAddTill}>
        Setup a till
      </Button>
      <p>Give your till a name and link it to an ipad</p>
      <TillDialog
        closeDialog={closeDialog}
        isOpen={isDialogOpen}
        submitDialog={dialogAction === 'add' ? addTill : editTill}
        initialValues={initialValues}
        mobiles={mobiles}
        action={dialogAction}
      />
      <div className={classes.table}>
        <TillsTable tills={tills} handleEdit={handleEditTill} handleDelete={deleteTill} />
      </div>
    </div>
  );
};
export default ManageTills;
