import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form } from 'formik';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { FormControlLabel, IconButton, Switch } from '@mui/material';
import { Delete, Shield } from '@mui/icons-material';

import UniversalSave from '../UniversalSave';
import TableWarning from '../TableWarning';
import { getMobileDevicesState } from '../../store/mobiles/selectors';
import { deleteMobileDevice, fetchMobileDevices } from '../../store/mobiles';

import { getErrorMessage } from '../../shared/utils/errors';
import { useNotifications } from '../../shared/contexts/Notifications/useNotifications';
import TableLink from '../TableLink';
import useRoles from '../../hooks/useRoles';

const formatCapabilities = (capabilities) => capabilities.join(', ');

const MobilesTable = ({ onSubmit }) => {
  const { loading: devicesLoading, data: devices } = useSelector(getMobileDevicesState);
  const { showErrorNotification, showSuccessNotification } = useNotifications();
  const dispatch = useDispatch();
  const { isAdmin } = useRoles();

  const hasReaders = devices?.some((device) => device.hasReader);

  if (!devices) return null;

  const removeCurrentDevice = async (device) => {
    const { mobileDeviceId } = device;
    try {
      await dispatch(deleteMobileDevice({ mobileDeviceId }));
      dispatch(fetchMobileDevices());
      showSuccessNotification('Device has been removed successfully');
    } catch (err) {
      dispatch(fetchMobileDevices());
      showErrorNotification(getErrorMessage(err));
    }
  };

  const showShield = (id) => (id && id.length > 10 ? <Shield /> : null);

  return (
    !devicesLoading && (
      <Formik enableReinitialize initialValues={{ mobileDevices: devices }} onSubmit={onSubmit}>
        {({ setFieldValue, resetForm, values, dirty, errors }) => (
          <>
            <UniversalSave
              dirty={dirty}
              errors={errors}
              onSave={() => {
                onSubmit(values);
              }}
              onDiscard={resetForm}
            />
            <Form>
              <TableWarning />
              <TableContainer component={Paper}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell />
                      <TableCell>Name</TableCell>
                      <TableCell>Mobile Device Id</TableCell>
                      <TableCell>App Version</TableCell>
                      <TableCell>Enabled</TableCell>
                      <TableCell>Capabilities</TableCell>
                      {hasReaders && <TableCell>Reader</TableCell>}
                      <TableCell>&nbsp;</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {devices.map((device, index) => (
                      <TableRow key={device.mobileDeviceId}>
                        <TableCell>{showShield(device.kandjiId)}</TableCell>
                        {isAdmin() ? (
                          <TableCell component="th" scope="row">
                            <TableLink to={`/mobiles/edit/${device.mobileDeviceId}`}>
                              {device.name}
                            </TableLink>
                          </TableCell>
                        ) : (
                          <TableCell>{device.name}</TableCell>
                        )}
                        <TableCell>{device.mobileDeviceId}</TableCell>
                        <TableCell>{device.appVersion}</TableCell>
                        <TableCell>
                          <FormControlLabel
                            control={
                              <Switch
                                color="primary"
                                type="checkbox"
                                checked={values.mobileDevices[index].enabled}
                                onChange={() =>
                                  setFieldValue(
                                    `mobileDevices[${index}].enabled`,
                                    !values.mobileDevices[index].enabled,
                                  )
                                }
                              />
                            }
                            label={device.enabled === true ? 'Enabled' : 'Disabled'}
                          />
                        </TableCell>
                        <TableCell>{formatCapabilities(device.capabilities)}</TableCell>
                        {hasReaders && <TableCell>{device.readerId || ''}</TableCell>}
                        <TableCell>
                          <IconButton
                            onClick={() => {
                              removeCurrentDevice(device);
                            }}
                          >
                            <Delete />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Form>
          </>
        )}
      </Formik>
    )
  );
};

export default MobilesTable;
