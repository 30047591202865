import * as Yup from 'yup';
import moment from 'moment-timezone';

const isValidDate = (value) => value && value?.isValid?.();

const OpeningTimesSchema = Yup.object().shape({
  openings: Yup.array().of(
    Yup.object().shape({
      day: Yup.string().oneOf(moment.weekdays()),
      time: Yup.object().shape({
        open: Yup.mixed().required().test('is-date', 'Not a valid date', isValidDate),
        close: Yup.mixed().required().test('is-date', 'Not a valid date', isValidDate),
      }),
      isOpen: Yup.boolean(),
    }),
  ),
});

export default OpeningTimesSchema;
