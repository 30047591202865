import React from 'react';
import { useLocation } from 'react-router-dom';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { Typography } from '@mui/material';
import moment from 'moment-timezone';
import PageLoader from '../PageLoader';
import TableLink from '../TableLink';

const OrdersLiveTable = ({ isOpen, orders }) => {
  const location = useLocation();
  if (!orders) return null;
  const diplayOrders = orders?.docs || orders;

  if (diplayOrders.length === 0)
    return (
      <PageLoader
        copy={`Waiting for new orders\nYou are currently ${isOpen ? 'OPEN' : 'CLOSED'}`}
      />
    );
  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography variant="h3">Date</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="h3">Time</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="h3">Order ID</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="h3">Table #</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="h3">Name</Typography>
            </TableCell>
            {diplayOrders.some((o) => o.pickupDay) && (
              <TableCell>
                <Typography variant="h3">Pickup Day</Typography>
              </TableCell>
            )}
            {diplayOrders.some((o) => o.pickupTime) && (
              <TableCell>
                <Typography variant="h3">Pickup Time</Typography>
              </TableCell>
            )}
            <TableCell>
              <Typography variant="h3">Notes</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="h3">Status</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="h3">Payment Service</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="h3">Payment Method</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="h3" align="right">
                Total Paid
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {diplayOrders?.map((order) => (
            <TableRow key={order.orderId}>
              <TableCell>
                <Typography variant="body2">
                  {moment(order.updatedAt).local().format('DD/MM')}
                </Typography>
              </TableCell>
              <TableCell>{moment(order.updatedAt).local().format('HH:mm')}</TableCell>
              <TableCell>
                <TableLink
                  to={{
                    pathname: `/orders/${order.orderId}`,
                    state: { from: location.pathname },
                  }}
                >
                  {order.orderDisplayId}
                </TableLink>
              </TableCell>
              <TableCell>{order.tableName}</TableCell>
              <TableCell>{order.name}</TableCell>
              {diplayOrders.some((o) => o.pickupDay) && (
                <TableCell>
                  {order?.pickupDay &&
                    `${order?.pickupDay} (${
                      moment(order?.pickupDay, 'YYYY-MM-DD').local()?.format('dddd') || ''
                    })`}
                </TableCell>
              )}
              {diplayOrders.some((o) => o.pickupTime) && <TableCell>{order.pickupTime}</TableCell>}
              <TableCell>{order.notes}</TableCell>
              <TableCell>{order.status}</TableCell>
              <TableCell>{order?.paymentService}</TableCell>
              <TableCell>{order?.paymentMethod}</TableCell>
              <TableCell align="right">£{parseFloat(order.total).toFixed(2)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default OrdersLiveTable;
