import { createSelector } from '@reduxjs/toolkit';
import { ascend, prop, sortWith } from 'ramda';

const modifierSort = sortWith([ascend(prop('type')), ascend(prop('modifierName'))]);
export const getModifiersState = (state) => state.modifiers;

export const getModifierState = (state) => state.modifier;

export const getModifiers = createSelector(
  getModifiersState,
  (modifiersState) => modifiersState.data && modifierSort(modifiersState.data),
);

export const getModifiersObj = createSelector(getModifiersState, (modifiers) => {
  if (!modifiers.data) return null;
  return modifiers.data.reduce((acc, modifier) => {
    const { modifierId, modifierName, type, label } = modifier;
    return { [modifierId]: { modifierId, modifierName, type, label }, ...acc };
  }, {});
});
