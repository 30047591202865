import { createSlice } from '@reduxjs/toolkit';
import {
  asyncInitialState as initialState,
  createAsyncAction,
  createAsyncReducerHandlers,
} from '../utils/async';
import {
  fetchManagers as fetchManagersApi,
  createManager as createManagerApi,
  updateManager as updateManagerApi,
  deleteManager as deleteManagerApi,
} from '../../api/managers';

export const fetchManagers = createAsyncAction('fetch-managers', fetchManagersApi);
export const createManager = createAsyncAction('create-managers', createManagerApi);
export const updateManager = createAsyncAction('update-managers', updateManagerApi);
export const deleteManager = createAsyncAction('delete-managers', deleteManagerApi);

const managersSlice = createSlice({
  name: 'managers',
  initialState,
  reducers: {
    clearManagers(state) {
      delete state.data;
    },
  },
  extraReducers: {
    ...createAsyncReducerHandlers(fetchManagers),
  },
});
const managerSlice = createSlice({
  name: 'manager',
  initialState,
  reducers: {
    clearManager(state) {
      delete state.data;
    },
  },
  extraReducers: {
    ...createAsyncReducerHandlers(createManager),
    ...createAsyncReducerHandlers(updateManager),
    ...createAsyncReducerHandlers(deleteManager),
  },
});
export const { clearManagers } = managersSlice.actions;
export const { clearManager } = managerSlice.actions;

export const { reducer: managers } = managersSlice;
export const { reducer: manager } = managerSlice;
