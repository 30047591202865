import menuTypes from '../constants/menuTypes';

const getMenuCountError = ({ menuTypeCount, type, initialType, isDuplicate = false }) => {
  if (type === initialType && !!isDuplicate) return null;

  const menuTypeKeys = [
    menuTypes.EPOS.value,
    menuTypes.WEB.value,
    menuTypes.DELIVERY_INHOUSE.value,
  ];

  const updatedTypeCount = menuTypeCount[type] + 1;

  if (menuTypeKeys.includes(type) && updatedTypeCount > 2) {
    return `You have reached the maximum of 2 ${menuTypes[type].label} menus`;
  }

  return null;
};

export default getMenuCountError;
