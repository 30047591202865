import React from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { Switch } from 'formik-mui';
import { makeStyles } from '@mui/styles';
import { Typography, ListItem, ListItemText, Divider } from '@mui/material';
import { useDispatch } from 'react-redux';
import withSettings from '../../hoc/withSettings';
import UniversalSave from '../UniversalSave';
import { updateSeveralSettings, updateVenue } from '../../store/venues';
import { useNotifications } from '../../shared/contexts/Notifications/useNotifications';
import { getErrorMessage } from '../../shared/utils/errors';
import withVenue from '../../hoc/withVenue';

const useStyles = makeStyles((theme) => ({
  listItem: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: '16px',
    paddingBottom: '16px',
  },
  title: {
    alignItems: 'center',
  },
  chevron: {
    color: theme.customPalette.trueBlue,
  },
}));

const VenueSchema = Yup.object().shape({
  isTakeawayEnabled: Yup.boolean().required(),
  RATINGS_ENABLED: Yup.boolean().required(),
  QR_ENABLED: Yup.boolean().required(),
  HARDWARELESS_COLLECTION: Yup.boolean().required(),
  AMEX_ENABLED: Yup.boolean().required(),
  EPOS_BASKET_SAVE: Yup.boolean().required(),
});

const VenueSettingsForm = ({ setting, venue }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { showErrorNotification, showSuccessNotification } = useNotifications();

  const {
    RATINGS_ENABLED,
    QR_ENABLED,
    HARDWARELESS_COLLECTION,
    AMEX_ENABLED,
    EPOS_BASKET_SAVE,
    TIPS_ENABLED,
    HIDE_GMV_REPORTS,
  } = setting || {};
  const { isTakeawayEnabled } = venue || {};
  const initialValues = {
    RATINGS_ENABLED: RATINGS_ENABLED?.value,
    QR_ENABLED: QR_ENABLED?.value,
    isTakeawayEnabled,
    HARDWARELESS_COLLECTION: HARDWARELESS_COLLECTION?.value,
    AMEX_ENABLED: AMEX_ENABLED?.value,
    EPOS_BASKET_SAVE: EPOS_BASKET_SAVE?.value,
    TIPS_ENABLED: TIPS_ENABLED?.value,
    HIDE_GMV_REPORTS: HIDE_GMV_REPORTS?.value,
  };

  const handleOnSubmit = async (values) => {
    try {
      const transformedData = [];
      Object.keys(values).forEach((key) => {
        if (key !== 'isTakeawayEnabled') {
          transformedData.push({
            settingName: key,
            value: values[key],
          });
        }
      });
      await dispatch(updateSeveralSettings(transformedData));
      if (values.isTakeawayEnabled !== initialValues.isTakeawayEnabled) {
        await dispatch(updateVenue({ isTakeawayEnabled: values.isTakeawayEnabled }));
      }
      showSuccessNotification('Successfully updated settings');
    } catch (error) {
      showErrorNotification(getErrorMessage(error));
    }
  };

  return (
    <Formik initialValues={initialValues} validationSchema={VenueSchema} enableReinitialize>
      {({ setFieldValue, values, resetForm, dirty, isValid, errors }) => (
        <>
          <UniversalSave
            isValid={isValid}
            dirty={dirty}
            onSave={() => handleOnSubmit(values)}
            onDiscard={resetForm}
            errors={errors}
          />
          <Form>
            <ListItem className={classes.listItem}>
              <div className={classes.title}>
                <ListItemText primary="Tips" />
                <Typography variant="subtitle1" component="p">
                  Enable tips on Payments PRO devices
                </Typography>
              </div>
              <Field
                component={Switch}
                name="TIPS_ENABLED"
                type="checkbox"
                size="medium"
                color="primary"
                onChange={() => {
                  setFieldValue('TIPS_ENABLED', !values.TIPS_ENABLED);
                }}
              />
            </ListItem>
            <Divider variant="middle" />
            <ListItem className={classes.listItem}>
              <div className={classes.title}>
                <ListItemText primary="Customer feedback" />
                <Typography variant="subtitle1" component="p">
                  Allow customers to leave feedback via the QR menu
                </Typography>
              </div>
              <Field
                component={Switch}
                name="RATINGS_ENABLED"
                type="checkbox"
                size="medium"
                color="primary"
                onChange={() => {
                  setFieldValue('RATINGS_ENABLED', !values.RATINGS_ENABLED);
                }}
              />
            </ListItem>
            <Divider variant="middle" />
            <ListItem className={classes.listItem}>
              <div className={classes.title}>
                <ListItemText primary="QR Menu ordering" />
                <Typography variant="subtitle1" component="p">
                  Allow customers to order to their table with a QR code
                </Typography>
              </div>
              <Field
                component={Switch}
                name="QR_ENABLED"
                type="checkbox"
                size="medium"
                color="primary"
                onChange={() => {
                  setFieldValue('QR_ENABLED', !values.QR_ENABLED);
                }}
              />
            </ListItem>
            <Divider variant="middle" />
            <ListItem className={classes.listItem}>
              <div className={classes.title}>
                <ListItemText primary="Takeaway service" />
                <Typography variant="subtitle1" component="p">
                  Offer a takeaway option on your QR menu
                </Typography>
              </div>
              <Field
                component={Switch}
                name="isTakeawayEnabled"
                type="checkbox"
                size="medium"
                color="primary"
                onChange={() => {
                  setFieldValue('isTakeawayEnabled', !values.isTakeawayEnabled);
                }}
              />
            </ListItem>
            <Divider variant="middle" />
            <ListItem className={classes.listItem}>
              <div className={classes.title}>
                <ListItemText primary="Hardwareless collection" />
                <Typography variant="subtitle1" component="p">
                  Allow customers to collect orders using their mobile phone
                </Typography>
              </div>
              <Field
                component={Switch}
                name="HARDWARELESS_COLLECTION"
                type="checkbox"
                size="medium"
                color="primary"
                onChange={() => {
                  setFieldValue('HARDWARELESS_COLLECTION', !values.HARDWARELESS_COLLECTION);
                }}
              />
            </ListItem>
            <Divider variant="middle" />
            <ListItem className={classes.listItem}>
              <div className={classes.title}>
                <ListItemText primary="EPOS basket save" />
                <Typography variant="subtitle1" component="p">
                  Allow epos to store orders at staff level
                </Typography>
              </div>
              <Field
                component={Switch}
                name="EPOS_BASKET_SAVE"
                type="checkbox"
                size="medium"
                color="primary"
                onChange={() => {
                  setFieldValue('EPOS_BASKET_SAVE', !values.EPOS_BASKET_SAVE);
                }}
              />
            </ListItem>
            <Divider variant="middle" />
            <ListItem className={classes.listItem}>
              <div className={classes.title}>
                <ListItemText primary="Card Payments" />
                <Typography variant="subtitle1" component="p">
                  Allow customers to pay using International Cards/Amex
                </Typography>
              </div>
              <Field
                component={Switch}
                name="AMEX_ENABLED"
                type="checkbox"
                size="medium"
                color="primary"
                onChange={() => {
                  setFieldValue('AMEX_ENABLED', !values.AMEX_ENABLED);
                }}
              />
            </ListItem>
            <Divider variant="middle" />
            <ListItem className={classes.listItem}>
              <div className={classes.title}>
                <ListItemText primary="Hide Reports GMV" />
                <Typography variant="subtitle1" component="p">
                  Hides the GMV tile in the Overiew Reports page
                </Typography>
              </div>
              <Field
                component={Switch}
                name="HIDE_GMV_REPORTS"
                type="checkbox"
                size="medium"
                color="primary"
                onChange={() => {
                  setFieldValue('HIDE_GMV_REPORTS', !values.HIDE_GMV_REPORTS);
                }}
              />
            </ListItem>
          </Form>
        </>
      )}
    </Formik>
  );
};

const page = withVenue(VenueSettingsForm);
export default withSettings(page, [
  'RATINGS_ENABLED',
  'QR_ENABLED',
  'HARDWARELESS_COLLECTION',
  'AMEX_ENABLED',
  'EPOS_BASKET_SAVE',
  'TIPS_ENABLED',
  'HIDE_GMV_REPORTS',
]);
